import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';

interface UsageDurationTextProps {
  allowedDuration: number;
  usedDuration: number;
}

const UsageDurationText: React.FC<UsageDurationTextProps> = ({
  allowedDuration,
  usedDuration,
}) => {
  const { t } = useTranslation('client', { keyPrefix: 'common' });

  const remainDuration =
    allowedDuration - usedDuration < 0 ? 0 : allowedDuration - usedDuration;

  const allowed = getHMS(allowedDuration);
  const remain = getHMS(remainDuration);

  const getTimeString = ({ hours, minutes, seconds }: HMS) => {
    const ret: string[] = [];

    if (hours > 0) {
      ret.push(t('membership.timeFormat.hour', { time: hours }));
    }

    if (minutes > 0) {
      ret.push(t('membership.timeFormat.minute', { time: minutes }));
    }

    if (seconds > 0) {
      ret.push(t('membership.timeFormat.second', { time: seconds }));
    }

    if (ret.length === 0) {
      ret.push(t('membership.timeFormat.second', { time: seconds }));
    }

    return ret.join(' ');
  };

  const isDanger = remainDuration < 10 * 60 * 1000;

  return (
    <Text>
      <Trans
        t={t}
        i18nKey={'membership.usage'}
        values={{
          allowed: getTimeString(allowed),
          remain: getTimeString(remain),
        }}
        components={[<Remain isDanger={isDanger} />]}
      />
    </Text>
  );
};

export default UsageDurationText;

interface HMS {
  hours: number;
  minutes: number;
  seconds: number;
}

const getHMS = (ms: number): HMS => {
  const hours = Math.floor(ms / (60 * 60 * 1000));
  const minutes = Math.floor((ms / (60 * 1000)) % 60);
  const seconds = Math.floor((ms / 1000) % 60);

  return {
    hours,
    minutes,
    seconds,
  };
};

const Text = styled.span`
  word-break: keep-all;
  color: var(--color-black);
  ${typography.body.medium}
`;

const Remain = styled.span<{ isDanger: boolean }>`
  color: ${({ isDanger }) => (isDanger ? 'var(--color-danger)' : 'inherit')};
`;
