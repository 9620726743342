import { PERSIST_KEY } from './persistConstants';
import type { AnyAction, Middleware } from '@reduxjs/toolkit';

const persistMiddleware: (key: string) => Middleware =
  (key) => (api) => (next) => (action: AnyAction) => {
    let result = next(action);

    if (window === undefined || window.localStorage === undefined)
      return result;
    if (action.type.indexOf(key) === -1) return result;

    const state = api.getState();
    if (state[key] === undefined) return result;

    window.localStorage.setItem(
      PERSIST_KEY,
      JSON.stringify({
        [key]: state[key],
      })
    );

    return result;
  };

export default persistMiddleware;
