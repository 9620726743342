import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';

const CONTACT_EMAIL =
  process.env.ONAIR_PLATFORM_TYPE === 'hunet'
    ? 'edusol@hunet.co.kr'
    : 'contact@onairstudio.ai';

const PaymentPolicy: React.FC = () => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.paymentPolicy',
  });
  return (
    <Base>
      <Text>
        <Trans
          t={t}
          i18nKey={'renewal'}
          components={{ bold: <strong /> }}
        ></Trans>
      </Text>
      <Text>{t(`refresh`)}</Text>
      <Text>{t(`useService`)}</Text>
      <Text>{t(`refund`)}</Text>
      <Text>{t(`contact.text`)}</Text>
      <List>
        <BulletText>
          <Trans
            t={t}
            i18nKey={'contact.email'}
            values={{ email: CONTACT_EMAIL }}
            components={[<Anchor href={`mailto:${CONTACT_EMAIL}`} />]}
          />
        </BulletText>
      </List>
    </Base>
  );
};

export default PaymentPolicy;

const Base = styled.div`
  width: 100%;
`;

const Text = styled.div`
  color: var(--color-grey-700);
  margin-top: 8px;
  ${typography.body.medium}
`;

const List = styled.div`
  margin-top: 4px;
  padding-left: 12px;
`;

const BulletText = styled.div`
  display: flex;
  align-items: center;
  ${typography.body.medium}
  color: var(--color-grey-700);
  white-space: pre;
  word-break: keep-all;

  &:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--color-grey-700);
    margin-right: 5px;
    display: inline-block;
  }
`;

const Anchor = styled.a`
  ${typography.body.medium}
  color: var(--color-blue);
  text-decoration: none;
`;
