export default function debounce<T extends unknown[], U>(
  cb: (...args: T) => PromiseLike<U> | U,
  wait: number
) {
  let timer: number;

  return (...args: T): Promise<U> => {
    if (timer !== undefined) {
      window.clearTimeout(timer);
    }

    return new Promise((resolve) => {
      timer = window.setTimeout(() => resolve(cb(...args)), wait);
    });
  };
}
