import * as React from 'react';
import styled from '@emotion/styled';
import TimelineGuide from './TimelineGuide';
import TimelineIndicator from './TimelineIndicator';
import EditableBoundary from './EditableBoundary';
import TimelineCells from './TimelineCells';

interface TimelineProps {
  isInitialized: boolean;
}

const Timeline: React.FC<TimelineProps> = ({ isInitialized }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const getScrollLeft = React.useCallback(() => {
    const el = ref.current;
    if (el === null) return 0;

    return el.scrollLeft;
  }, []);

  return (
    <Base ref={ref}>
      <TimelineGuide />
      <TimelineIndicator />
      <EditableBoundary />
      {isInitialized && <TimelineCells getScrollLeft={getScrollLeft} />}
    </Base>
  );
};

export default Timeline;

const Base = styled.div`
  flex: 1;
  position: relative;
  overflow-x: auto;
`;
