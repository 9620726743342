import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';
import GradeCard from './GradeCard';
import PermissionCard from './PermissionCard';
import { grades } from './constants';

const FAQ_URL =
  'https://onairstudio.zendesk.com/hc/ko/categories/4411545995673-%EC%9E%90%EC%A3%BC-%EB%AC%BB%EB%8A%94-%EC%A7%88%EB%AC%B8';
const PERMISSION_URL = 'https://opportunities-project-396861.framer.app/';

const ProductInfoCards: React.FC = () => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.productInfo',
  });

  return (
    <>
      <QuestionLink href={FAQ_URL} target={'_blank'} rel={'noreferrer'}>
        {t('questionLink')}
      </QuestionLink>
      <ItemBox>
        {grades.map((grade) => (
          <GradeCard cardGrade={grade} />
        ))}
      </ItemBox>
      <PermissionTitle>
        <span>{t('permission.title')}</span>
        <PermissionLink
          href={PERMISSION_URL}
          target={'_blank'}
          rel={'noreferrer'}
        >
          {t('permission.link')}
        </PermissionLink>
      </PermissionTitle>
      <ItemBox>
        {grades.map((grade) => (
          <PermissionCard cardGrade={grade} />
        ))}
      </ItemBox>
    </>
  );
};

export default ProductInfoCards;

const ItemBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-bottom: 16px;

  @media (min-width: 520px) {
    grid-template-columns: repeat(auto-fill, minmax(auto, 330px));
    grid-column-gap: 16px;
    grid-row-gap: 24px;
    margin-bottom: 24px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 32px;
  }
`;

const PermissionTitle = styled.div`
  width: 100%;
  margin-bottom: 8px;

  span {
    ${typography.title.medium}
    color: var(--color-black);
  }

  @media (min-width: 520px) {
    margin-bottom: 16px;
  }
`;

const QuestionLink = styled.a`
  width: fit-content;
  height: fit-content;
  padding: 4px 8px;
  margin-bottom: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${typography.body.large}
  text-decoration: none;
  color: var(--color-blue);
  &:visited {
    color: var(--color-blue);
  }
`;

const PermissionLink = styled.a`
  width: fit-content;
  height: fit-content;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${typography.body.large}
  text-decoration: none;
  color: var(--color-blue);
  &:visited {
    color: var(--color-blue);
  }
`;
