import * as React from 'react';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { useMatchMedia } from 'lib/hooks';
import { VideoModelSelect as Desktop } from './VideoModel.Desktop';
import { VideoModelSelect as Mobile } from './VideoModel.Mobile';
import MembershipGuide from './MembershipGuide';

const VideoModelSelect: React.FC = () => {
  const isMatch = useMatchMedia('(min-width: 1440px)');

  const { data } = useGetUserInfoQuery();

  if (data?.billingName === 'Free' || data?.billingName === 'Free_beta')
    return <MembershipGuide />;
  if (isMatch) return <Desktop />;
  return <Mobile />;
};

export default VideoModelSelect;
