import * as React from 'react';
import type { ChildrenProps } from './types';

const empty = Symbol('videoPreview empty');
type EmptyContext = typeof empty;

interface VideoControls {
  play: () => Promise<void> | undefined;
  pause: () => void;
  seek: (time: number) => void;
  volume: (volume: number) => void;
  mute: () => void;
  unmute: () => void;
}

const ControlsContext = React.createContext<
  VideoControls | null | EmptyContext
>(empty);
const SetControlsContext = React.createContext<
  React.Dispatch<React.SetStateAction<VideoControls | null>> | EmptyContext
>(empty);

export const VideoPreviewProvider = ({ children }: ChildrenProps) => {
  const [controls, setControls] = React.useState<VideoControls | null>(null);

  return (
    <ControlsContext.Provider value={controls}>
      <SetControlsContext.Provider value={setControls}>
        {children}
      </SetControlsContext.Provider>
    </ControlsContext.Provider>
  );
};

export const useSetVideoPreviewControls = () => {
  const context = React.useContext(SetControlsContext);
  if (context === empty) throw new Error('SetControlsContext is empty');
  return context;
};

export const useVideoPreviewControls = () => {
  const context = React.useContext(ControlsContext);
  if (context === empty) throw new Error('SetControlsContext is empty');
  return context;
};
