import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ClientTemplate } from 'components/client';
import { Profile, UpdatePassword, Withdrawal } from 'components/userProfile';

const ProfilePage: React.FC = () => {
  return (
    <ClientTemplate>
      <Routes>
        <Route index element={<Profile />} />
        <Route path={'update-password'} element={<UpdatePassword />} />
        <Route path={'withdrawal'} element={<Withdrawal />} />
        <Route path={'*'} element={<Navigate to={''} replace />} />
      </Routes>
    </ClientTemplate>
  );
};

export default ProfilePage;
