import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import styled from '@emotion/styled';
import { debounce } from 'lib/utils';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectCellCC, modifyCellData } from 'features/editor/editorSlice';

interface CellCCInputBoxProps {
  pageKey: string;
  cellKey: string;
}

const CellCCInputBox: React.FC<CellCCInputBoxProps> = ({
  pageKey,
  cellKey,
}) => {
  const MAX_LINE = 10;
  const MAX_LENGTH = 300;
  const DEBOUNCE_TIME = 300;

  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const cc = useAppSelector(selectCellCC(cellKey));

  const dispatch = useAppDispatch();

  const ref = React.useRef<HTMLTextAreaElement | null>(null);

  const getLimitedText = (text: string | undefined) => {
    if (text === undefined) return '';
    const lines = text.replace(/\r\n/g, '\n').split('\n');
    return lines.slice(0, MAX_LINE).join('\n').slice(0, MAX_LENGTH);
  };

  const updateCC = debounce((text: string) => {
    dispatch(modifyCellData({ pageKey, cellKey, displayText: text }));
  }, DEBOUNCE_TIME);

  const handleChangeTextarea: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    if (e.target === null) return;
    const displayText = getLimitedText(e.target.value);
    e.target.value = displayText;
    updateCC(displayText);
  };

  const handleKeydownTextArea: React.KeyboardEventHandler<HTMLTextAreaElement> =
    (e) => {
      if (e.target === null) return;

      const el = ref.current;
      if (el === null) return;

      const lines = el.value.replace(/\r\n/g, '\n').split('\n');
      if (lines.length >= MAX_LINE && e.key === 'Enter') {
        e.preventDefault();
      }
    };

  if (cc === undefined) return null;
  return (
    <Base>
      <TextareaAutosize
        cacheMeasurements
        placeholder={t('placeholder.cellDisplayText')}
        defaultValue={cc}
        ref={ref}
        // maxRows={MAX_LINE}
        // maxLength={MAX_LENGTH}
        onChange={handleChangeTextarea}
        onKeyDown={handleKeydownTextArea}
      />
      <LimitBox>
        {ref.current !== null && (
          <TextLength isError={ref.current.value?.length > MAX_LENGTH}>
            {ref.current?.value?.length}/{MAX_LENGTH}
          </TextLength>
        )}
      </LimitBox>
    </Base>
  );
};

export default CellCCInputBox;

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  border-radius: var(--cell-cc-input-radius);
  padding: 8px 4px 8px 8px;
  overflow: hidden;

  & textarea {
    width: 100%;
    padding: 0;
    padding-right: 8px;
    margin: 0;
    border: none;
    outline: none;
    color: var(--color-black);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    resize: none;
    overflow: hidden;

    &::placeholder {
      color: var(--color-grey-700);
    }
  }
`;

const LimitBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

const TextLength = styled.span<{ isError: boolean | undefined }>`
  display: inline-flex;
  margin-left: auto;
  font-size: 12px;
  color: ${({ isError }) =>
    isError ? 'var(--color-danger)' : 'var(--color-grey-600)'};
  line-height: 1.4;
  user-select: none;
`;
