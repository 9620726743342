import * as React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from 'assets/svg';

const GoBackButton: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/client', { replace: true });
  };

  return (
    <Button onClick={handleClick}>
      <ArrowLeftIcon size={24} weight={'bold'} />
    </Button>
  );
};

export default GoBackButton;

const Button = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
