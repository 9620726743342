export default function loadScript(src: string) {
  if (document.querySelector(`[src='${src}']`)) return;

  return new Promise<void>((resolve, reject) => {
    if (document === null) reject(new Error('Document is null!'));
    if (document.head === null) reject(new Error('Document head is null!'));

    const element: HTMLElement = document.getElementsByTagName('script')[0];

    const script = document.createElement('script');
    script.src = src;

    script.onload = () => {
      resolve();
      script.onload = null;
      script.onerror = null;
    };

    script.onerror = () => {
      reject(new Error('Fail to append script!'));
      script.onload = null;
      script.onerror = null;
    };

    if (element && element.parentNode) {
      element.parentNode.insertBefore(script, element);
    } else {
      document.head.appendChild(script);
    }
  });
}
