import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';
import PartnersBox from './PartnersBox';

const PartnersSection: React.FC = () => {
  const { t, i18n } = useTranslation('main', { keyPrefix: 'partners' });

  return (
    <Base>
      <Box>
        <Title data-lang={i18n.language}>{t('title')}</Title>
        <PartnersBox />
      </Box>
    </Base>
  );
};

export default PartnersSection;

const Base = styled.section`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: var(--color-white);
`;

const Box = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);

  @media (min-width: 1152px) {
    width: 1120px;
  }
`;

const Title = styled.span`
  ${typography.title.large}
  margin-bottom: 8px;
  text-align: center;
  white-space: normal;
  width: 100%;
  display: inline-block;

  &[data-lang='kr'] {
    white-space: pre;

    @media (min-width: 820px) {
      white-space: normal;
    }
  }

  @media (min-width: 820px) {
    margin-bottom: 16px;
  }
`;
