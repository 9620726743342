import * as React from 'react';
import { useVideo, useAudio } from 'react-use';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  setTimelineCurrentTime,
  selectCurrnetPageTextDatas,
  fetchMergeAudios,
  selectTimelineCurrentTime,
} from 'features/editor/editorSlice';
import { useEditorAudioControlsPause } from 'features/editor/providers';
import { useSetVideoPreviewControls } from 'features/editor/providers/videoPreview';

const useVideoPreview = (
  videoSrc: string,
  videoPoster: string,
  ref: React.ForwardedRef<HTMLElement>
) => {
  const setVideoPreviewControls = useSetVideoPreviewControls();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [video, videoState, videoControls, videoRef] = useVideo({
    src: videoSrc,
    style: {
      width: '100%',
      aspectRatio: '16 / 9',
      height: 'calc(100% - 40px)',
      flex: 1,
    },
    onLoadedMetadata() {
      setVideoPreviewControls(videoControls);
    },
    onCanPlayThrough() {
      setIsLoading(false);
    },
    onLoadStart() {
      setIsLoading(true);
    },
    onPlay() {
      audioControls.play();
    },
    onPause() {
      audioControls.pause();
    },
    onSeeked() {
      audioControls.seek(videoState.time);
    },
    onEnded() {
      audioControls.pause();
      videoControls.seek(0);
      audioControls.seek(0);
    },
  });

  React.useEffect(() => {
    if (typeof ref === 'function') {
      ref(videoRef.current);
    } else {
      if (ref) ref.current = videoRef.current;
    }

    return () => {
      if (typeof ref === 'function') {
        ref(null);
      } else {
        if (ref) ref.current = null;
      }
    };
  }, [ref, videoRef]);

  const [audio, , audioControls, audioRef] = useAudio({
    src: '',
  });

  const [isMerging, setIsMerging] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setTimelineCurrentTime(Math.floor(videoState.time * 1000)));
  }, [videoState.time, dispatch]);

  const prevNodes = React.useRef<string>('');
  const textDatas = useAppSelector(selectCurrnetPageTextDatas);

  const mergeAudio = async () => {
    const audioEl = audioRef.current;
    if (audioEl === null) return;
    if (textDatas.length === 0) return;

    if (prevNodes.current === JSON.stringify(textDatas)) return;

    try {
      setIsMerging(true);
      prevNodes.current = JSON.stringify(textDatas);
      const url = await dispatch(fetchMergeAudios(textDatas)).unwrap();
      audioEl.src = url;
    } catch (error) {
      console.error(error);
    } finally {
      setIsMerging(false);
    }
  };

  const currentTime = useAppSelector(selectTimelineCurrentTime);

  const editorAudioPause = useEditorAudioControlsPause();

  const play = async () => {
    if (!videoState.paused) return;

    await mergeAudio();
    editorAudioPause();
    audioControls.seek(currentTime / 1000);
    videoControls.play();
  };

  const pause = () => {
    videoControls.pause();
  };

  const seek = (newValue: number) => {
    videoControls.seek(newValue);
  };

  return [
    video,
    audio,
    play,
    pause,
    seek,
    isMerging,
    videoState,
    isLoading,
  ] as const;
};

export default useVideoPreview;
