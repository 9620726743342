interface FetchParameters {
  uuid: string;
  token: string;
  signal?: AbortSignal;
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/projects`;

export default async function fetchProjectSubtitleTXT({
  uuid,
  token,
  signal,
}: FetchParameters) {
  const response = await fetch(`${URL}/${uuid}/script`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

  if (!response.ok) {
    throw new Error(JSON.stringify(await response.text()));
  }

  const data = await response.blob();
  return data;
}
