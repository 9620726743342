import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import GradeCardOptionsItem from './GradeCardOptionsItem';
import type { GradeOptions } from './constants';

type GradeCardOptionsProps = GradeOptions;

const GradeCardOptions: React.FC<GradeCardOptionsProps> = ({
  download,
  project,
  voice,
  stv,
  removeWatermark,
  commercialUse,
  video,
  customizing,
}) => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.productInfo.card',
  });

  return (
    <Box>
      {download && (
        <GradeCardOptionsItem>
          {t('options.download', {
            download: formattedTime(download),
          })}
        </GradeCardOptionsItem>
      )}
      {project && (
        <GradeCardOptionsItem>
          {t('options.project', { project })}
        </GradeCardOptionsItem>
      )}
      {voice && (
        <GradeCardOptionsItem>
          {t('options.voice', { voice })}
        </GradeCardOptionsItem>
      )}
      {stv && <GradeCardOptionsItem>{t('options.stv')}</GradeCardOptionsItem>}
      {removeWatermark && (
        <GradeCardOptionsItem>
          {t('options.removeWatermark')}
        </GradeCardOptionsItem>
      )}
      {video && (
        <GradeCardOptionsItem>
          {t('options.videoUse', { max: video.maxSize })}
        </GradeCardOptionsItem>
      )}
      {commercialUse && (
        <GradeCardOptionsItem>
          {t('options.commercialUse')}
        </GradeCardOptionsItem>
      )}
      {customizing && (
        <GradeCardOptionsItem>{t('options.customizing')}</GradeCardOptionsItem>
      )}
    </Box>
  );
};

export default GradeCardOptions;

const formattedTime = (hour: number) => {
  const minute = hour * 60;

  const date = new Date(0, 0, 0, 0, minute, 0, 0);

  const newHour = date.getHours();
  const newMinute = date.getMinutes();

  const newTime =
    (newHour > 0 ? `${newHour}시간` : '') +
    (newMinute > 0 ? `${newMinute}분` : '');

  return newTime;
};

const Box = styled.div`
  flex: 1 0 auto;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 8px;
  margin-top: 24px;
  margin-bottom: 50px;
`;
