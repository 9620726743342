import * as React from 'react';
import styled from '@emotion/styled';
import { EditorContent, EditorToolbar } from 'features/editor';
import CommonToasts from './CommonToasts';

const EditorMain: React.FC = () => {
  return (
    <Base>
      <EditorContent />
      <EditorToolbar />
      <CommonToasts />
    </Base>
  );
};

export default EditorMain;

const Base = styled.main`
  width: 100%;
  height: calc(100% - 144px);
  display: flex;
  flex-direction: column;
`;
