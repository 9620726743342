import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { Toast } from 'components/common';
import {
  selectTimelineDuplicateToast,
  setTimelineDuplicateToast,
} from 'features/editor/editorSlice';

const DuplicateErrorToast: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'timeline' });

  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(selectTimelineDuplicateToast);

  return (
    <Toast
      in={isOpen}
      onClose={() => dispatch(setTimelineDuplicateToast(false))}
      timeout={2500}
      severity={'error'}
      message={t('toast.duplicate')}
    />
  );
};

export default DuplicateErrorToast;
