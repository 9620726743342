import * as React from 'react';
import styled from '@emotion/styled';
import { useGetWatermarkQuery } from 'app/services/watermark';

interface WatermarkImageProps {
  imageWidth: number;
}

const WatermarkImage: React.FC<WatermarkImageProps> = ({ imageWidth }) => {
  const { image } = useGetWatermarkQuery(undefined, {
    selectFromResult: ({ data }) => ({
      image: data?.[0],
    }),
  });

  const setStyle = React.useCallback(
    (instance: HTMLImageElement) => {
      const ratio = imageWidth / 1920;
      const watermarkWidth = 326 * ratio;
      const watermarkHeight = 88 * ratio;
      const position = 50 * ratio;

      instance.width = watermarkWidth;
      instance.height = watermarkHeight;
      instance.style.cssText = `
			  --top: ${position}px;
				--right: ${position}px;
			`;
    },
    [imageWidth]
  );

  const refCallback: React.RefCallback<HTMLImageElement> = (instance) => {
    if (instance === null) return;

    setStyle(instance);
  };

  if (image === undefined) return null;
  return (
    <Base
      src={image.downloadUrl}
      alt={image.displayName}
      width={50}
      height={13}
      ref={refCallback}
    />
  );
};

export default WatermarkImage;

const Base = styled.img`
  position: absolute;
  top: var(--top);
  right: var(--right);
  opacity: 0.5;
  z-index: 2;
`;
