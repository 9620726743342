import * as React from 'react';
import styled from '@emotion/styled';

interface SearchBarProps {
  value: string;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  value,
  placeholder = '',
  onChange,
}) => {
  return (
    <Base>
      <SearchBox>
        <SearchInput
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </SearchBox>
    </Base>
  );
};

export default SearchBar;

const Base = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 16px;
`;

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  background: var(--color-grey-50);
  border: none;
  border-radius: 10px;

  &:hover {
    background: var(--color-grey-100);
  }

  &:focus-within {
    padding: 6px;
    border-style: solid;
    border-width: 2px;
    border-color: var(--color-blue);
    background: var(--color-grey-100);
  }
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-black);

  &::placeholder {
    color: var(--color-grey-700);
  }
`;
