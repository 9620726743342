import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ContentsTitle } from 'components/client';
import { isMembershipTabType } from './utils';
import MembershipTabs from './MembershipTabs';
import ProductInfo from './ProductInfo';
import PaymentInfo from './PaymentInfo';
import PaymentLog from './PaymentLog';
import type { MembershipTabType } from './constants';

const Membership: React.FC = () => {
  const [searchParams] = useSearchParams();

  const paramTab = searchParams.get('tab');
  const tab: MembershipTabType = isMembershipTabType(paramTab)
    ? paramTab
    : 'product';

  const { t } = useTranslation('client', { keyPrefix: 'membership' });

  return (
    <Base>
      <ContentsTitle title={t(`title`)} />
      <MembershipTabs tab={tab} />
      {tab === 'product' && <ProductInfo />}
      {tab === 'payment' && <PaymentInfo />}
      {tab === 'purchase' && <PaymentLog />}
    </Base>
  );
};

export default Membership;

const Base = styled.div`
  width: 100%;
`;
