import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { setErrors, selectRefreshError } from 'features/auth/authSlice';
import { typography } from 'styles';

interface RefreshErrorDialogProps {
  onMounted: () => void;
  saving?: boolean;
}

const RefreshErrorDialog: React.FC<RefreshErrorDialogProps> = ({
  onMounted,
  saving,
}) => {
  const { t } = useTranslation('auth', { keyPrefix: 'refresh' });
  const error = useAppSelector(selectRefreshError);

  const mounted = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (!error) return;
    if (mounted.current) return;

    onMounted();
    mounted.current = true;
  }, [error, onMounted]);

  React.useEffect(() => {
    if (error) return;
    if (!mounted.current) return;

    mounted.current = false;
  }, [error]);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const handleClickGoLogin = () => {
    navigate('/auth/login', {
      state: { from: location },
    });
    dispatch(setErrors({ refresh: false }));
  };

  if (!error) return null;
  return (
    <Dialog as={DialogBase} open={error} onClose={() => {}}>
      <Dialog.Overlay as={DialogOverlay} />
      <Base>
        <Content>
          <Title>{t('error.title')}</Title>
          <Body>{t('error.body')}</Body>
        </Content>
        <Actions>
          <GoLoginButton onClick={handleClickGoLogin} disabled={saving}>
            {saving ? t('error.button.saving') : t('error.button.default')}
          </GoLoginButton>
        </Actions>
      </Base>
    </Dialog>
  );
};

export default RefreshErrorDialog;

const DialogBase = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogOverlay = styled.div`
  position: fixed;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(51, 51, 51, 0.8);
  -webkit-tap-highlight-color: transparent;
`;

const Base = styled.div`
  width: calc(100% - 32px);
  max-width: 440px;
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 24px;
`;

const Content = styled.div`
  width: 100%;
  margin-bottom: 24px;
  color: var(--color-black);
  line-height: 1.4;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  ${typography.title.large}
  margin-bottom: 8px;
`;

const Body = styled.span`
  ${typography.body.large}
  white-space: pre-wrap;
`;

const Actions = styled.div`
  width: 100%;
`;

const GoLoginButton = styled.button`
  padding: 0;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  color: var(--color-black);
`;
