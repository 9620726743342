import { createSelector } from '@reduxjs/toolkit';
import { VOICE_DEFAULT_SPEED, VOICE_DEFAULT_VOLUME } from '../constants';
import { isAvailableCC, isS3Path, getTrackCount, getTimeGap } from './utils';
import {
  TIMELINE_INDICATOR_MIN_POSITION,
  TIMELINE_MULTIPLIERS,
} from './constants';
import type { RootState } from 'app/store';

// UI Selectors
export const selectPresentUIState = createSelector(
  (state: RootState) => state.editor.present,
  (state) => state.ui
);

export const selectCurrentToolbarItem = createSelector(
  selectPresentUIState,
  (state) => state.toolbarItem
);

export const selectCurrentCharacterIndex = createSelector(
  selectPresentUIState,
  (state) => state.characterToolIndex
);

export const selectCurrentUploadIndex = createSelector(
  selectPresentUIState,
  (state) => state.uploadToolIndex
);

export const selectCurrentAudioIndex = createSelector(
  selectPresentUIState,
  (state) => state.audioToolIndex
);

export const selectCurrentAudioToolState = createSelector(
  selectPresentUIState,
  (state) => state.audioToolState
);

export const selectCurrentClosedCaptionState = createSelector(
  selectPresentUIState,
  (state) => state.closedCaptionState
);

export const selectCurrentPageKey = createSelector(
  selectPresentUIState,
  (state) => state.selectedPageKey
);

export const selectCurrentCellKey = createSelector(
  selectPresentUIState,
  (state) => state.selectedCellKey
);

export const selectUseCC = createSelector(
  selectPresentUIState,
  (state) => state.closedCaptionState.use
);

export const selectCCOptions = createSelector(selectPresentUIState, (state) => {
  if (!isAvailableCC(state.closedCaptionState)) return undefined;
  return state.closedCaptionState;
});

export const selectInputSelection = (key: string) =>
  createSelector(selectPresentUIState, (state) => {
    if (state.selectedCellKey !== key) return undefined;
    return state.inputSelection;
  });

export const selectCanEditSTV = createSelector(
  selectPresentUIState,
  (state) => {
    if (state.toolbarItem !== 'character') return false;
    if (state.characterToolIndex !== 1) return false;
    return true;
  }
);

export const selectWatermarkType = createSelector(
  selectPresentUIState,
  (state) => state.watermarkType
);

// Data Selectors
export const selectPresentDataState = createSelector(
  (state: RootState) => state.editor.present,
  (state) => state.data
);

export const selectProjectName = createSelector(
  selectPresentDataState,
  (state) => state.name
);

export const selectPageCards = createSelector(
  selectPresentDataState,
  (state) => {
    return state.pages.map((page) => ({
      key: page.key,
      uuid: page.uuid,
      num: page.num,
      thumbnailUrl: page.thumbnailUrl,
    }));
  }
);

// for drag and drop selector
export const selectPageKeys = createSelector(selectPresentDataState, (state) =>
  state.pages.map((page) => page.key)
);

export const selectCurrentPage = createSelector(
  (state: RootState) => state.editor.present,
  (state) => {
    const currentPageKey = state.ui.selectedPageKey;
    if (currentPageKey === undefined) return undefined;

    const currentPage = state.data.pages.find(
      (page) => page.key === currentPageKey
    );
    if (currentPage === undefined) return undefined;

    return currentPage;
  }
);

export const selectCurrentPageNum = createSelector(
  (state: RootState) => state.editor.present,
  (state) => {
    const currentPageKey = state.ui.selectedPageKey;
    if (currentPageKey === undefined) return undefined;

    const currentPage = state.data.pages.find(
      (page) => page.key === currentPageKey
    );
    if (currentPage === undefined) return undefined;

    return currentPage.num;
  }
);

export const selectCurrentPageImage = createSelector(
  (state: RootState) => state.editor.present,
  (state) => {
    const currentPageKey = state.ui.selectedPageKey;
    if (currentPageKey === undefined) return undefined;

    const currentPage = state.data.pages.find(
      (page) => page.key === currentPageKey
    );
    if (currentPage === undefined) return undefined;

    return currentPage.imageUrl;
  }
);

type PreviewContent =
  | {
      type: 'video';
      url: string;
      thumbnail: string;
    }
  | {
      type: 'image';
      url: string;
    }
  | {
      type: 'placeholder';
      url: undefined;
    };

export const selectCurrentPagePreview = createSelector(
  (state: RootState) => state.editor.present,
  (state): PreviewContent => {
    let content: PreviewContent = {
      type: 'placeholder',
      url: undefined,
    };

    const currentPageKey = state.ui.selectedPageKey;
    if (currentPageKey === undefined) return content;

    const currentPage = state.data.pages.find(
      (page) => page.key === currentPageKey
    );
    if (currentPage === undefined) return content;

    if (currentPage.videoUrl) {
      content = {
        type: 'video',
        url: currentPage.videoUrl,
        thumbnail: currentPage.thumbnailUrl ?? '',
      };
      return content;
    }

    if (currentPage.imageUrl) {
      content = {
        type: 'image',
        url: currentPage.imageUrl,
      };
      return content;
    }

    return content;
  }
);

export const selectPagePreview = (key: string) =>
  createSelector(
    (state: RootState) => state.editor.present,
    (state): PreviewContent => {
      let content: PreviewContent = {
        type: 'placeholder',
        url: undefined,
      };

      const page = state.data.pages.find((page) => page.key === key);
      if (page === undefined) return content;

      if (page.videoUrl) {
        content = {
          type: 'video',
          url: page.videoUrl,
          thumbnail: page.thumbnailUrl ?? '',
        };
        return content;
      }

      if (page.imageUrl) {
        content = {
          type: 'image',
          url: page.imageUrl,
        };
        return content;
      }

      return content;
    }
  );

export const selectCurrentPageSTV = createSelector(
  (state: RootState) => state.editor.present,
  (state) => {
    const currentPageKey = state.ui.selectedPageKey;
    if (currentPageKey === undefined) return undefined;

    const currentPage = state.data.pages.find(
      (page) => page.key === currentPageKey
    );
    if (currentPage === undefined) return undefined;

    return currentPage.stv;
  }
);

export const selectPagesContent = createSelector(
  selectPresentDataState,
  (state) => {
    return state.pages.map((page) => ({
      isVideo: isS3Path({ path: page.videoPath, url: page.videoUrl }),
      videoPath: page.videoPath,
      videoUrl: page.videoUrl,
      key: page.key,
      num: page.num,
      duration: page.duration,
      cells: page.cells,
    }));
  }
);

export const selectPageContent = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const findPage = state.pages.find((el) => el.key === key);

    return findPage;
  });

export const selectPageSTV = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const findPage = state.pages.find((el) => el.key === key);

    return findPage?.stv;
  });

export const selectPageCells = (key: string) =>
  createSelector(selectPageContent(key), (page) => {
    if (page === undefined) return undefined;
    return page.cells;
  });

export const selectPageCellKeys = (key: string) =>
  createSelector(selectPageContent(key), (page) => {
    const ret: string[] = [];

    page?.cells.forEach((cell) => {
      ret.push(cell.key);
    });

    return ret;
  });

export const selectPageDuration = (key: string) =>
  createSelector(selectPageContent(key), (page) => {
    if (page === undefined) return undefined;
    return page.duration;
  });

export const selectCell = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const cells = state.pages.flatMap((page) => page.cells);
    const cell = cells.find((el) => el.key === key);

    return cell;
  });

export const selectCellDuration = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const cells = state.pages.flatMap((page) => page.cells);
    const cell = cells.find((el) => el.key === key);

    if (cell === undefined) return undefined;
    return cell.duration;
  });

export const selectCellModelName = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const cells = state.pages.flatMap((page) => page.cells);
    const cell = cells.find((el) => el.key === key);

    if (cell === undefined) return undefined;
    return cell.mlModelName;
  });

export const selectCellText = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const cells = state.pages.flatMap((page) => page.cells);
    const cell = cells.find((el) => el.key === key);

    if (cell === undefined) return undefined;
    return cell.text;
  });

export const selectCellVolume = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const cells = state.pages.flatMap((page) => page.cells);
    const cell = cells.find((el) => el.key === key);

    if (cell === undefined) return undefined;
    if (cell.volume === undefined) return VOICE_DEFAULT_VOLUME;
    return cell.volume;
  });

export const selectCellSpeed = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const cells = state.pages.flatMap((page) => page.cells);
    const cell = cells.find((el) => el.key === key);

    if (cell === undefined) return undefined;
    if (cell.speed === undefined) return VOICE_DEFAULT_SPEED;
    return cell.speed;
  });

export const selectCellCC = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const cells = state.pages.flatMap((page) => page.cells);
    const cell = cells.find((el) => el.key === key);

    if (cell === undefined) return undefined;
    return cell.displayText;
  });

export const selectCurrentCell = createSelector(
  (state: RootState) => state.editor.present,
  (state) => {
    const currentCellKey = state.ui.selectedCellKey;
    if (currentCellKey === undefined) return undefined;

    const currentCell = state.data.pages
      .flatMap((el) => el.cells)
      .find((el) => el.key === currentCellKey);

    if (currentCell === undefined) return undefined;
    return currentCell;
  }
);

export const selectCurrentCellText = createSelector(
  (state: RootState) => state.editor.present,
  (state) => {
    const currentCellKey = state.ui.selectedCellKey;
    if (currentCellKey === undefined) return undefined;

    const currentCell = state.data.pages
      .flatMap((el) => el.cells)
      .find((el) => el.key === currentCellKey);

    if (currentCell === undefined) return undefined;
    return currentCell.text;
  }
);

export const selectCurrentCellCC = createSelector(
  (state: RootState) => state.editor.present,
  (state) => {
    const currentCellKey = state.ui.selectedCellKey;
    if (currentCellKey === undefined) return undefined;

    const currentCell = state.data.pages
      .flatMap((el) => el.cells)
      .find((el) => el.key === currentCellKey);

    if (currentCell === undefined) return undefined;
    return currentCell.displayText;
  }
);

interface MergeNode {
  type: 'audio-node' | 'padding-node';
  text: string;
  volume: number;
  speed: number;
  name: string;
  padding: number;
}

export const selectFullMergeData = createSelector(
  selectPresentDataState,
  (state) => {
    const nodes = state.pages.reduce<MergeNode[]>((acc, item) => {
      item.cells.forEach((cell) => {
        if (cell.text === '') {
          acc.push({
            type: 'padding-node',
            text: '',
            volume: VOICE_DEFAULT_VOLUME,
            speed: VOICE_DEFAULT_SPEED,
            name: '',
            padding: cell.duration,
          });
        } else {
          acc.push({
            type: 'audio-node',
            text: cell.text,
            volume: cell.volume ?? VOICE_DEFAULT_VOLUME,
            speed: cell.speed ?? VOICE_DEFAULT_SPEED,
            name: cell.mlModelName,
            padding: cell.duration,
          });
        }
      });

      acc.push({
        type: 'padding-node',
        text: '',
        volume: VOICE_DEFAULT_VOLUME,
        speed: VOICE_DEFAULT_SPEED,
        name: '',
        padding: item.duration,
      });
      return acc;
    }, []);

    return nodes;
  }
);

export const selectPageMergeData = (key: string) =>
  createSelector(selectPresentDataState, (state) => {
    const page = state.pages.find((page) => page.key === key);
    if (page === undefined) return;

    const nodes = page.cells.reduce<MergeNode[]>((acc, item) => {
      if (item.text === '') {
        acc.push({
          type: 'padding-node',
          text: '',
          volume: VOICE_DEFAULT_VOLUME,
          speed: VOICE_DEFAULT_SPEED,
          name: '',
          padding: item.duration,
        });
      } else {
        acc.push({
          type: 'audio-node',
          text: item.text,
          volume: item.volume ?? VOICE_DEFAULT_VOLUME,
          speed: item.speed ?? VOICE_DEFAULT_SPEED,
          name: item.mlModelName,
          padding: item.duration,
        });
      }

      return acc;
    }, []);

    return nodes;
  });

export const selectUpdateTime = createSelector(
  selectPresentDataState,
  (state) => state.updateDate
);

export const selectProjectIsExist = createSelector(
  selectPresentDataState,
  (state) => state.uuid !== undefined
);

export const selectPageThumbnails = createSelector(
  selectPresentDataState,
  (state) =>
    state.pages
      .filter((el) => el.thumbnailUrl !== undefined)
      .map((el) => el.thumbnailUrl)
);

export const selectProjectUuid = createSelector(
  selectPresentDataState,
  (state) => state.uuid
);

export const selectProjectUseAnySTV = createSelector(
  selectPresentDataState,
  (state) =>
    state.pages.filter((el) => el.stv !== undefined).length > 0 ? true : false
);

export const selectModified = createSelector(
  selectPresentDataState,
  (state) => state._modified
);

// Common Selectors
export const selectPastState = createSelector(
  (state: RootState) => state.editor,
  (state) => state.past
);

export const selectFutureState = createSelector(
  (state: RootState) => state.editor,
  (state) => state.future
);

export const selectPresentState = createSelector(
  (state: RootState) => state.editor,
  (state) => state.present
);

export const selectUndoable = createSelector(
  selectPastState,
  (past) => past.length > 0
);

export const selectRedoable = createSelector(
  selectFutureState,
  (future) => future.length > 0
);

export const selectNetworkState = createSelector(
  selectPresentState,
  (state) => state.network
);

export const selectOpenPageLimitToast = createSelector(
  selectPresentState,
  (state) => state.toast.pageLimit
);

export const selectPageLimit = createSelector(
  selectPresentDataState,
  (state) => state.pageLimit
);

export const selectOpenCellLimitToast = createSelector(
  selectPresentState,
  (state) => state.toast.cellLimit
);

export const selectCellLimit = createSelector(
  selectPresentDataState,
  (state) => state.cellLimit
);

export const selectOpenMergeLimitToast = createSelector(
  selectPresentState,
  (state) => state.toast.mergeLimit
);

export const selectChannelId = createSelector(
  selectPresentState,
  (state) => state.channelId
);

export const selectCurrentCells = createSelector(selectCurrentPage, (page) => {
  if (page === undefined) return undefined;
  return page.cells;
});

export const selectPageLastCellModelName = createSelector(
  selectCurrentPage,
  (page) => {
    if (page === undefined) return undefined;
    const { cells } = page;
    if (cells.length > 0) {
      return cells[cells.length - 1].mlModelName;
    }
    return undefined;
  }
);

export const selectIsVideoEdit = createSelector(
  selectPresentDataState,
  (state) => state.type === 'desktop'
);

export const selectIsLoadingScreen = createSelector(
  selectPresentState,
  (state) => state.loadingScreen
);

export const selectPageTextDatas = (pageKey: string) =>
  createSelector(selectPresentDataState, (state) => {
    const page = state.pages.find((el) => el.key === pageKey);
    if (page === undefined) return [];

    return [
      {
        duration: page.duration,
        textData: page.cells.map((el) => ({
          mlModel: el.mlModelName,
          text: el.text,
          duration: el.duration,
          speed: el.speed,
          volume: el.volume,
        })),
      },
    ];
  });

export const selectCurrnetPageTextDatas = createSelector(
  selectPresentState,
  (state) => {
    const pageKey = state.ui.selectedPageKey;

    const page = state.data.pages.find((el) => el.key === pageKey);
    if (page === undefined) return [];

    return [
      {
        duration: page.duration,
        textData: page.cells.map((el) => ({
          mlModel: el.mlModelName,
          text: el.text,
          duration: el.duration,
          speed: el.speed,
          volume: el.volume,
        })),
      },
    ];
  }
);

export const selectAllTextDatas = createSelector(
  selectPresentDataState,
  (state) => {
    return state.pages.map((el) => ({
      duration: el.duration,
      textData: el.cells.map((el) => ({
        mlModel: el.mlModelName,
        text: el.text,
        duration: el.duration,
        speed: el.speed,
        volume: el.volume,
      })),
    }));
  }
);

export const selectPresentTimelineState = createSelector(
  selectPresentState,
  (state) => state.timeline
);

export const selectTimelineMultiplier = createSelector(
  selectPresentTimelineState,
  (state) => {
    return TIMELINE_MULTIPLIERS[state.multiplierIndex];
  }
);

export const selectTimelineTrackCount = createSelector(
  selectPresentTimelineState,
  selectTimelineMultiplier,
  (state, multiplier) => getTrackCount(state.maxTime, multiplier)
);

export const selectTimelineTimeGap = createSelector(
  selectTimelineMultiplier,
  (multiplier) => getTimeGap(multiplier)
);

export const selectTimelineCurrentTime = createSelector(
  selectPresentTimelineState,
  (state) => state.currentTime
);

export const selectTimelineMaxTime = createSelector(
  selectPresentTimelineState,
  (state) => state.maxTime
);

export const selectTimelineRulerWidth = createSelector(
  selectPresentTimelineState,
  (state) => state.rulerWidth
);

export const selectTimelineContentWidth = createSelector(
  selectTimelineRulerWidth,
  (width) => width - TIMELINE_INDICATOR_MIN_POSITION * 2
);

export const selectTimelineRulerGap = createSelector(
  selectTimelineTrackCount,
  selectTimelineContentWidth,
  (trackCount, contentWidth) => contentWidth / Math.max(trackCount, 1)
);

export const selectTimelineRulerPerSecWidth = createSelector(
  selectTimelineTrackCount,
  selectTimelineContentWidth,
  selectTimelineMultiplier,
  (trackCount, contentWidth, multiplier) =>
    contentWidth / trackCount / multiplier
);

export const selectTimelineCellsWidth = createSelector(
  selectTimelineTrackCount,
  selectTimelineContentWidth,
  selectTimelineMultiplier,
  selectTimelineMaxTime,
  (trackCount, contentWidth, multiplier, maxTime) => {
    const ret = (maxTime * contentWidth) / (trackCount * multiplier * 1000);

    return Number.isNaN(ret) ? 0 : ret;
  }
);

export const selectTimelineIndicatorTransform = createSelector(
  selectPresentTimelineState,
  (state) =>
    `translateX(${state.indicatorPosition + TIMELINE_INDICATOR_MIN_POSITION}px)`
);

export const selectTimelineMultiplierIndex = createSelector(
  selectPresentTimelineState,
  (state) => state.multiplierIndex
);

export const selectTimelineDuplicateToast = createSelector(
  selectPresentState,
  (state) => state.toast.timelineDuplicate
);

export const selectTimelineOverflowToast = createSelector(
  selectPresentState,
  (state) => state.toast.timelineOverflow
);

export const selectPreviewVideoErrorToast = createSelector(
  selectPresentState,
  (state) => state.toast.previewVideoError
);

export const selectTimelineEditableCell = createSelector(
  selectPresentTimelineState,
  (state) => state.editableCell
);

export const selectTimelineEditableDisplayText = createSelector(
  selectTimelineEditableCell,
  (state) => state.displayText
);

export const selectTimelineIsLoading = createSelector(
  selectPresentTimelineState,
  (state) => state.isLoading
);

export const selectTimelineChanged = createSelector(
  selectPresentTimelineState,
  (state) => state.isChanged
);
