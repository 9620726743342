import * as React from 'react';
import { useMatchMedia } from 'lib/hooks';
import { VideoPreviewProvider } from 'features/editor/providers/videoPreview';
import Desktop from './Content.Desktop';
import Mobile from './Content.Mobile';

const Content: React.FC = () => {
  const match = useMatchMedia('(min-width: 1024px)');

  return (
    <VideoPreviewProvider>
      {match ? <Desktop /> : <Mobile />}
    </VideoPreviewProvider>
  );
};

export default Content;
