import * as React from 'react';
import styled from '@emotion/styled';
import { useGetVideoModelQuery } from 'app/services/videoModel';
import { ModalV2 } from 'components/common';
import PoseSelectItem from './PoseSelectItem';

interface PoseSelectProps {
  selectedModelName: string;
  selectedPoseName?: string;
  onSelectPose: (newPoseName: string) => void;
}

const PoseSelect: React.FC<PoseSelectProps> = ({
  selectedModelName,
  selectedPoseName,
  onSelectPose,
}) => {
  const { model } = useGetVideoModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      model: data?.find((el) => el.name === selectedModelName),
    }),
  });

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const toggleOpenPreview = () => setOpenPreview((prev) => !prev);

  const [previewSrc, setPreviewSrc] = React.useState<string>('');

  const handleClickPreview = (src: string, index: number) => {
    if (src === undefined) return;
    setPreviewSrc(src);
    toggleOpenPreview();
  };

  return (
    <Base>
      <List>
        {model?.resources.map((poseInfo, index) => (
          <PoseSelectItem
            isSelected={selectedPoseName === poseInfo.name}
            editThumbUrl={poseInfo.editThumbUrl}
            onSelect={onSelectPose.bind(null, poseInfo.name)}
            onClick={handleClickPreview.bind(null, poseInfo.thumbUrl, index)}
            key={`${selectedModelName}-${poseInfo.name}`}
          />
        ))}
      </List>
      <ModalV2 open={openPreview} onClose={toggleOpenPreview}>
        <video
          src={previewSrc}
          autoPlay
          muted
          controlsList={'nodownload noremoteplayback'}
          style={{ minHeight: '25%', maxHeight: '50%' }}
          disablePictureInPicture
        />
      </ModalV2>
    </Base>
  );
};

export default PoseSelect;

const Base = styled.div`
  width: 100%;
  height: 100%;
`;

const List = styled.div`
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(168px, 2fr));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  list-style: none;
  overflow-y: auto;
  height: fit-content;
  max-height: 100%;
  padding-bottom: 16px;
`;
