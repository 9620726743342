import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectUseCC,
  setSelectCellKey,
  selectCellText,
} from 'features/editor/editorSlice';
import CellControlBox from './CellControlBox';
import CellCCInputBox from './CellCCInputBox';
import CellModelName from './CellModelName';
import CellTextInputBox from './CellTextInputBox';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';

export interface EditableCellProps {
  pageKey: string;
  cellKey: string;
  isSelected: boolean;
  listeners: DraggableSyntheticListeners;
}

const EditableCell: React.FC<EditableCellProps> = ({
  pageKey,
  cellKey,
  isSelected,
  listeners,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const useCC = useAppSelector(selectUseCC);

  const defaultText = useAppSelector(selectCellText(cellKey));

  const dispatch = useAppDispatch();

  if (defaultText === undefined) return null;
  return (
    <Base
      onClick={() => {
        if (isSelected) return;

        dispatch(setSelectCellKey(cellKey));
      }}
      isSelected={useCC && isSelected}
    >
      <CellControlBox cellKey={cellKey} listeners={listeners} />
      <InputBox isSelected={isSelected}>
        <CellModelName pageKey={pageKey} cellKey={cellKey} />
        <CellTextInputBox
          pageKey={pageKey}
          cellKey={cellKey}
          defaultValue={defaultText}
        />
        {useCC && isSelected && (
          <>
            <CCLabel>
              <span>{t('label.cellDisplayText')}</span>
            </CCLabel>
            <CellCCInputBox pageKey={pageKey} cellKey={cellKey} />
          </>
        )}
      </InputBox>
    </Base>
  );
};

export default EditableCell;

const Base = styled.div<{ isSelected: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 4px;

  ${(props) =>
    props.isSelected
      ? `
	  --cell-model-name-radius: 10px 0 0 0;
		--cell-text-input-radius: 0 10px 0 0;
		--cell-cc-input-radius: 0 0 10px 0;
		--cell-cc-label-radius: 0 0 0 10px;
	`
      : `
	  --cell-model-name-radius: 10px 0 0 10px;
		--cell-text-input-radius: 0 10px 10px 0;
	`}
`;

const InputBox = styled.div<{ isSelected: boolean }>`
  --input-border-width: ${(props) => (props.isSelected ? '2px' : '1px')};
  --input-border-color: ${(props) =>
    props.isSelected ? 'var(--color-blue)' : 'var(--color-grey-200)'};

  flex: 1;
  height: 100%;
  border-width: var(--input-border-width);
  border-style: solid;
  border-color: var(--input-border-color);
  border-radius: 10px;

  display: grid;
  grid-template-columns: 120px 1fr;
  background: var(--color-grey-200);
  gap: 1px;
`;

const CCLabel = styled.div`
  min-height: 40px;
  height: 100%;
  border-radius: var(--cell-cc-label-radius);
  background: var(--color-white);
  color: var(--color-black);
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  align-items: start;
  justify-content: end;

  span {
    height: 40px;
    padding: 8px;
  }
`;
