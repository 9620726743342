import * as React from 'react';
import styled from '@emotion/styled';
import { useMatchMedia } from 'lib/hooks';
import Desktop from './Header.Desktop';
import Mobile from './Header.Mobile';

const Header: React.FC = () => {
  const isTablet = useMatchMedia('(min-width: 820px)');

  const ref = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    const el = ref.current;
    if (el === null) return;

    const headerOffset = el.offsetTop;

    let raf: number;

    const scrollHandler = () => {
      if (raf) {
        window.cancelAnimationFrame(raf);
      }

      raf = window.requestAnimationFrame(() => {
        if (window.pageYOffset > headerOffset) {
          el.classList.add('sticky');
        } else {
          el.classList.remove('sticky');
        }
      });
    };

    window.addEventListener('scroll', scrollHandler, false);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return <Base ref={ref}>{isTablet ? <Desktop /> : <Mobile />}</Base>;
};

export default Header;

const Base = styled.header`
  height: 64px;
  z-index: var(--header-index);
  background: var(--color-white);
  transition: box-shadow 225ms;

  &.sticky {
    position: sticky;
    top: 0;
    box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.08);
  }
`;
