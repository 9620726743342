import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFloating, offset } from '@floating-ui/react-dom';
import styled from '@emotion/styled';
import { useGetPaymentInfoQuery } from 'app/services/membership';
import { typography } from 'styles';
import { useMatchMedia } from 'lib/hooks';
import type { GradeType } from './constants';

interface ProductUseButtonProps {
  cardGrade: GradeType;
  isUsed: boolean;
}

const ProductUseButton: React.FC<ProductUseButtonProps> = ({
  cardGrade,
  isUsed,
}) => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.productInfo.card',
  });

  const navigate = useNavigate();

  const isMatch = useMatchMedia('(hover: none)');
  const [isOpenTooltip, setIsOpenTooltip] = React.useState<boolean>(false);

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'top',
    middleware: [offset(8)],
  });

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    navigate(`/client/payment?grade=${cardGrade}`);
  };

  const handleClickFree: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
  };

  const { redeem } = useGetPaymentInfoQuery(undefined, {
    selectFromResult: ({ data }) =>
      data?.active
        ? { redeem: data.nextBillingRedeemDate }
        : { redeem: undefined },
  });

  return (
    <>
      {cardGrade !== 'Free' && (
        <Button
          ref={reference}
          onClick={handleClick}
          onMouseEnter={() => {
            if (isMatch) return;
            setIsOpenTooltip(true);
          }}
          onMouseLeave={() => {
            if (isMatch) return;
            setIsOpenTooltip(false);
          }}
        >
          <span>
            {isUsed
              ? redeem !== undefined
                ? t('button.inUseWithRedeem', { redeem })
                : t('button.inUse')
              : t('button.toUse')}
          </span>
        </Button>
      )}
      {cardGrade === 'Free' && (
        <ButtonFree
          ref={reference}
          onClick={handleClickFree}
          onMouseEnter={() => {
            if (isMatch) return;
            setIsOpenTooltip(true);
          }}
          onMouseLeave={() => {
            if (isMatch) return;
            setIsOpenTooltip(false);
          }}
        >
          <span>{isUsed ? t('button.inUse') : t('button.toUse')}</span>
        </ButtonFree>
      )}
      {(isMatch ||
        (cardGrade === 'Free' ? isOpenTooltip : isUsed && isOpenTooltip)) && (
        <TooltipBox
          ref={floating}
          style={{
            position: strategy,
            top: y ?? '',
            left: x ?? '',
          }}
        >
          {cardGrade === 'Free'
            ? t('tooltip.productFree')
            : t('tooltip.productRebuy')}
        </TooltipBox>
      )}
    </>
  );
};

export default ProductUseButton;

const Button = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  flex: none;
  width: 100%;
  padding: 16px 13px;
  margin-top: 16px;

  border: none;
  border-radius: 10px;
  cursor: pointer;

  span {
    ${typography.title.medium}
    color: var(--color-white);
  }

  background: var(--color-pink);

  &:hover {
    background: var(--hover-gradient), var(--color-pink);
    @media (hover: none) {
      background: var(--active-gradient), var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }

  &:disabled {
    background: var(--color-grey-300);
  }
`;

const ButtonFree = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  flex: none;
  width: 100%;
  padding: 16px 13px;
  margin-top: 16px;

  border: none;
  border-radius: 10px;
  cursor: pointer;

  span {
    ${typography.title.medium}
    color: var(--color-white);
  }

  background: var(--color-grey-300);
`;

const TooltipBox = styled.div`
  background: var(--color-black);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  width: calc(100% - 32px);
  padding: 4px;
  color: var(--color-white);
  ${typography.body.medium}

  &.strong {
    font-weight: 700;
  }
`;
