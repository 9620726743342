import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';

interface NextBillingTextProps {
  datetime: string;
}

const NextBillingText: React.FC<NextBillingTextProps> = ({ datetime }) => {
  const { t } = useTranslation('client', { keyPrefix: 'common' });

  return <Text>{t('membership.nextBilling', { day: getDay(datetime) })}</Text>;
};

export default NextBillingText;

const getDay = (datetime: string): number => {
  const time = new Date(datetime).getTime();
  const now = Date.now();

  const deltaTime = (time - now) / 24 / 60 / 60 / 1000;

  return Math.ceil(deltaTime);
};

const Text = styled.span`
  ${typography.body.medium}
  color: var(--color-grey-500);
`;
