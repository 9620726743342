import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import { DragAndDropUpload } from 'components/editor';
import { Toast } from 'components/common';
import {
  fetchExtractFileAdd,
  overrideExtractFileSlide,
  appendExtractFileSlide,
  selectCurrentPageKey,
  fetchUploadTempImage,
  overrideImageFileSlide,
  appendImageFileSlide,
  setOpenPageLimitToast,
  setOpenCellLimitToast,
} from 'features/editor/editorSlice';
import {
  usePusherChannelId,
  useCommonModalOpen,
} from 'features/editor/providers';
import { sort, voiceModelCompare } from 'features/editor/utils';
import IncompleteModal from './SlideUpload.Incomplete';
import Wordbook from './SlideUpload.Wordbook';

type ModalState = 'none' | 'incomplete' | 'wordbook';
interface ParsedFilePageInfo {
  image: {
    tmpPath: string;
    url: string;
  };
  thumb: {
    tmpPath: string;
    url: string;
  };
  cells: {
    text: string;
    displayText: string;
    duration: number;
  }[];
}

const validateFileName = (file: File): boolean => {
  if (
    !(
      file.name.endsWith('.pptx') ||
      file.name.endsWith('.pdf') ||
      file.type.indexOf('image') > -1
    )
  ) {
    return false;
  }
  return true;
};

const validateFileSize = (file: File): boolean => {
  if (file.size > 200 * 1024 * 1024) {
    return false;
  }

  return true;
};

const isUploadError = (error: any): boolean => {
  if (typeof error.message === 'undefined') return false;
  if (typeof error.message !== 'string') return false;
  if (error.message === 'default') return true;
  return false;
};

const isPageLimitError = (error: any): boolean => {
  if (typeof error.message === 'undefined') return false;
  if (typeof error.message !== 'string') return false;
  if (error.message === 'pageLimit') return true;
  return false;
};

const isCellLimitError = (error: any): boolean => {
  if (typeof error.message === 'undefined') return false;
  if (typeof error.message !== 'string') return false;
  if (error.message === 'cellLimit') return true;
  return false;
};

const SlideUpload: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'slideUpload' });

  const dispatch = useAppDispatch();

  const channelId = usePusherChannelId();

  const [modalState, setModalState] = React.useState<ModalState>('none');

  const [wordbook, setWordbook] = React.useState<Record<string, string | null>>(
    {}
  );

  const parsedDatas = React.useRef<ParsedFilePageInfo[]>([]);

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const toastMessage = React.useRef<string>('');

  const handleAddImage = (data: {
    imageUrl: string;
    imagePath: string;
    thumbnailUrl: string;
    thumbnailPath: string;
  }) => {
    openAddPositionModal({
      content: t('override.text'),
      submitText: t('override.yes'),
      secondSubmitText: t('override.no'),
      submit() {
        if (selectedPageKey === undefined) return;

        dispatch(
          overrideImageFileSlide({
            pageKey: selectedPageKey,
            ...data,
          })
        );
      },
      secondSubmit() {
        if (selectedPageKey === undefined) return;

        dispatch(
          appendImageFileSlide({
            pageKey: selectedPageKey,
            ...data,
          })
        );
      },
    });
  };

  const handleChangeFile = async (newFile: File) => {
    if (channelId === undefined) return;

    if (!validateFileName(newFile)) {
      toastMessage.current = t('error.extension');
      toggleOpenToast();
      return;
    }

    if (!validateFileSize(newFile)) {
      toastMessage.current = t('error.fileSize');
      toggleOpenToast();
      return;
    }

    // for mixpanel
    try {
      const file = { name: newFile.name, exp: '', size: newFile.size };
      if (newFile.name.indexOf('.') >= 0) {
        file.name = newFile.name.substring(0, newFile.name.lastIndexOf('.'));
        file.exp = newFile.name.substring(
          newFile.name.lastIndexOf('.') + 1,
          newFile.name.length
        );
      }
    } catch {}

    if (newFile.type.indexOf('image') > -1) {
      const ret = await dispatch(
        fetchUploadTempImage({ file: newFile })
      ).unwrap();
      handleAddImage(ret);
      return;
    }

    if (newFile.name.endsWith('.pptx') || newFile.name.endsWith('.pdf')) {
      try {
        const ret = await dispatch(
          fetchExtractFileAdd({
            file: newFile,
            type: 'video',
            channelId,
          })
        ).unwrap();

        parsedDatas.current = ret.info;

        if (Object.keys(ret.wordBook).length > 0) {
          setWordbook(ret.wordBook);
        }

        if (ret.incomplete) {
          setModalState('incomplete');
          return;
        }

        if (Object.keys(ret.wordBook).length > 0) {
          setModalState('wordbook');
        } else {
          handleSubmitWordbook(ret.wordBook);
        }
      } catch (error) {
        if (isUploadError(error)) {
          toastMessage.current = t('error.default');
          toggleOpenToast();
          return;
        }

        if (isPageLimitError(error)) {
          dispatch(setOpenPageLimitToast(true));
          return;
        }

        if (isCellLimitError(error)) {
          dispatch(setOpenCellLimitToast(true));
          return;
        }

        console.error(error);
      }
    }
  };

  const openAddPositionModal = useCommonModalOpen();

  const selectedPageKey = useAppSelector(selectCurrentPageKey);

  const { initialMlModelName } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      initialMlModelName: sort(data, voiceModelCompare)?.[0].name,
    }),
  });

  const handleClickIncompleteContinue = () => {
    setModalState('wordbook');
  };

  const handleClickIncompleteCancel = () => {
    setModalState('none');
  };

  const handleClose = () => {
    setModalState('none');
  };

  const handleSubmitWordbook = (item: Record<string, string | null>) => {
    setWordbook(item);
    setModalState('none');
    openAddPositionModal({
      content: t('override.text'),
      submitText: t('override.yes'),
      secondSubmitText: t('override.no'),
      submit() {
        if (selectedPageKey === undefined) return;
        if (initialMlModelName === undefined) return;

        dispatch(
          overrideExtractFileSlide({
            startPageKey: selectedPageKey,
            info: parsedDatas.current,
            wordBook: wordbook,
            modelName: initialMlModelName,
          })
        );
      },
      secondSubmit() {
        if (selectedPageKey === undefined) return;
        if (initialMlModelName === undefined) return;

        dispatch(
          appendExtractFileSlide({
            startPageKey: selectedPageKey,
            info: parsedDatas.current,
            wordBook: wordbook,
            modelName: initialMlModelName,
          })
        );
      },
    });
  };

  return (
    <>
      <Base>
        <Title>{t('title.default')}</Title>
        <Subtitle>{t('subtitle.default')}</Subtitle>
        <DragAndDropZone>
          <DragAndDropUpload
            accept={'.pptx,.pdf,.png'}
            helperText={t('helperText')}
            onChangeFile={handleChangeFile}
          />
        </DragAndDropZone>
        <Toast
          in={openToast}
          message={toastMessage.current}
          onClose={toggleOpenToast}
          timeout={1500}
          severity={'error'}
        />
      </Base>

      <IncompleteModal
        open={modalState === 'incomplete'}
        onClose={handleClose}
        title={t('title.incomplete')}
        description={t('subtitle.incomplete')}
        buttonContinue={t('button.incompleteContinue')}
        onClickContinue={handleClickIncompleteContinue}
        buttonCancel={t('button.incompleteCancel')}
        onClickCancel={handleClickIncompleteCancel}
      />

      {modalState === 'wordbook' && (
        <Wordbook datas={wordbook} onSubmit={handleSubmitWordbook} />
      )}
    </>
  );
};

export default SlideUpload;

const Base = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  padding: 0 16px;
`;

const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-grey-700);
  margin-top: 8px;
  padding: 0 16px;
`;

const DragAndDropZone = styled.div`
  margin: 16px;
  width: calc(100% - 32px);
`;
