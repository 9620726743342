import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CommaIcon, MinusIcon, PlusIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { Slider } from 'components/editor';
import {
  SLIDE_DEFAULT_DURATION,
  SLIDE_MIN_DURATION,
  SLIDE_MAX_DURATION,
} from 'features/editor/constants';
import {
  selectCurrentPageKey,
  modifyManyDuration,
  modifyManyPageData,
  selectCurrentPagePreview,
} from 'features/editor/editorSlice';

const ToolbarChangeDurationButton: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  const [duration, setDuration] = React.useState<number>(
    SLIDE_DEFAULT_DURATION
  );

  const [baseEl, setBaseEl] = React.useState<HTMLDivElement | null>(null);
  const targetRef = React.useRef<HTMLDivElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const [dropdownEl, setDropdownEl] = React.useState<HTMLDivElement | null>(
    null
  );

  React.useEffect(() => {
    const baseEl = buttonRef.current;
    if (baseEl === null) return;
    if (dropdownEl === null) return;

    const baseRect = baseEl.getBoundingClientRect();

    dropdownEl.style.cssText = `
      top: ${baseRect.bottom}px;
      left: ${baseRect.left + (baseRect.width - dropdownEl.offsetWidth) / 2}px;
    `;
  }, [dropdownEl]);

  React.useEffect(() => {
    const targetEl = targetRef.current;
    if (targetEl === null) return;

    if (baseEl === null) {
      targetEl.style.cssText = '';
      setDuration(SLIDE_DEFAULT_DURATION);
      return;
    }

    const baseRect = baseEl.getBoundingClientRect();

    targetEl.style.cssText = `
      display: flex;
      top: ${baseRect.bottom}px;
      left: ${baseRect.left - baseRect.width / 2}px;
    `;
  }, [baseEl]);

  React.useEffect(() => {
    const buttonEl = buttonRef.current;
    if (buttonEl === null) return;

    const targetEl = targetRef.current;
    if (targetEl === null) return;

    const listener = (e: MouseEvent) => {
      if (!(e.target instanceof HTMLElement)) return;

      if (targetEl.contains(e.target)) {
      } else if (buttonEl.contains(e.target)) {
        setBaseEl(null);
      } else {
        setOpen(false);
        setBaseEl(null);
        setDuration(SLIDE_DEFAULT_DURATION);
      }
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, []);

  const [modifyType, setModifyType] = React.useState<
    'currentPageCells' | 'allCells' | 'allPages' | null
  >(null);

  const currentPageKey = useAppSelector(selectCurrentPageKey);

  const dispatch = useAppDispatch();

  const handleClickMinus = () => {
    const minValue = modifyType === 'allPages' ? SLIDE_MIN_DURATION : 0;
    const newValue = Math.max(duration - 100, minValue);

    setDuration(newValue);
    handleChange(newValue);
  };

  const handleClickPlus = () => {
    const newValue = Math.min(duration + 100, SLIDE_MAX_DURATION);

    setDuration(newValue);
    handleChange(newValue);
  };

  const handleChange = (newValue: number) => {
    if (modifyType === null) return;
    if (modifyType === 'currentPageCells') {
      dispatch(
        modifyManyDuration({
          pageKey: currentPageKey,
          duration: newValue,
        })
      );
    }
    if (modifyType === 'allCells') {
      dispatch(
        modifyManyDuration({
          pageKey: undefined,
          duration: newValue,
        })
      );
    }

    if (modifyType === 'allPages') {
      dispatch(
        modifyManyPageData({
          pageKeys: undefined,
          duration: newValue,
        })
      );
    }
  };

  const pagePreview = useAppSelector(selectCurrentPagePreview);

  return (
    <>
      <Button onClick={toggleOpen} ref={buttonRef}>
        <CommaIcon width={16} height={16} />
        <span>{t('toolbar.changeDuration')}</span>
      </Button>
      {open && (
        <Dropdown ref={setDropdownEl}>
          {pagePreview.type !== 'video' && (
            <>
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setBaseEl(
                    baseEl === e.currentTarget ? null : e.currentTarget
                  );
                  setModifyType('currentPageCells');
                }}
              >
                <span>{t('toolbar.changeDurationCurrentPageCells')}</span>
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setBaseEl(
                    baseEl === e.currentTarget ? null : e.currentTarget
                  );
                  setModifyType('allCells');
                }}
              >
                <span>{t('toolbar.changeDurationAllCells')}</span>
              </DropdownItem>
            </>
          )}
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              setBaseEl(baseEl === e.currentTarget ? null : e.currentTarget);
              setModifyType('allPages');
            }}
          >
            <span>{t('toolbar.changeDurationAllPages')}</span>
          </DropdownItem>
        </Dropdown>
      )}
      <SliderBox ref={targetRef}>
        <SliderValue>
          {(modifyType === 'currentPageCells' || modifyType === 'allCells') && (
            <span>{t('label.cellDuration')}</span>
          )}
          {modifyType === 'allPages' && <span>{t('label.pageDuration')}</span>}
          <SliderValueDivider />
          <span>
            {t('text.durationValue', {
              duration: (duration / 1000).toFixed(1),
            })}
          </span>
        </SliderValue>
        <IconButton size={24} onClick={handleClickMinus}>
          <MinusIcon size={20} weight={'bold'} />
        </IconButton>
        <Slider
          width={144}
          value={duration}
          onChange={(newValue) => setDuration(newValue)}
          onChangeCommit={handleChange}
          min={modifyType === 'allPages' ? SLIDE_MIN_DURATION : 0}
          max={SLIDE_MAX_DURATION}
        />
        <IconButton size={24} onClick={handleClickPlus}>
          <PlusIcon size={20} weight={'bold'} />
        </IconButton>
      </SliderBox>
    </>
  );
};

export default ToolbarChangeDurationButton;

const Button = styled.button`
  outline: none;
  border: none;
  padding: 0 8px;
  width: fit-content;
  height: 56px;
  background: var(--color-white);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 1.4;
  color: var(--color-grey-700);
  cursor: pointer;

  position: relative;

  span {
    white-space: nowrap;
    min-width: 96px;
  }
`;

const Dropdown = styled.div`
  position: fixed;
  height: fit-content;
  border-radius: 10px;
  background: var(--color-white);
  border: 1px solid var(--color-grey-50);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DropdownItem = styled.div`
  width: 100%;
  height: 32px;
  background: var(--color-white);
  color: var(--color-black);
  line-height: 1.4;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 8px;

  &:hover {
    background: linear-gradient(rgba(51, 51, 51, 0.08), rgba(51, 51, 51, 0.08)),
      var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }
`;

const SliderBox = styled.div`
  z-index: 1;
  position: fixed;
  width: 320px;
  height: 32px;
  display: none;
  align-items: center;
  gap: 10px;
  padding: 0 8px;
  border: 1px solid var(--color-grey-100);
  background: var(--color-white);
  box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
    0px 16px 32px rgba(22, 34, 51, 0.16);
  border-radius: 10px;

  svg {
    color: var(--color-black);
  }
`;

const SliderValue = styled.div`
  color: var(--color-grey-500);
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
`;

const SliderValueDivider = styled.hr`
  border: none;
  width: 1px;
  height: 12px;
  background: var(--color-grey-100);
  margin: 0 4px;
`;
