import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
// import { scrollIntoView } from 'lib/utils';
import {
  selectPageContent,
  setSelectPageKey,
} from 'features/editor/editorSlice';
import PageTitle from './PageTitle';
import CellList from './CellList';

interface PageContentProps {
  pageKey: string;
  isSelected: boolean;
}

const PageContent: React.FC<PageContentProps> = ({ pageKey, isSelected }) => {
  const entity = useAppSelector(selectPageContent(pageKey));

  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (isSelected) return;

    dispatch(setSelectPageKey(pageKey));
  };

  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!isSelected) return;

    const el = ref.current;
    if (el === null) return;

    const parentEl = el.parentElement;
    if (parentEl === null) return;

    el.scrollIntoView(true);
  }, [isSelected]);

  if (entity === undefined) return null;
  return (
    <Base onClick={handleClick} ref={ref}>
      <PageTitle
        isSelected={isSelected}
        pageNum={entity.num + 1}
        pageKey={entity.key}
      />
      {entity.videoPath === undefined && entity.videoUrl === undefined && (
        <CellList pageKey={pageKey} />
      )}
    </Base>
  );
};

export default PageContent;

const Base = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
