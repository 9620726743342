import * as React from 'react';

const GoogleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      d='M23.494 12.281c0-.813-.065-1.63-.206-2.43H12.004v4.606h6.462a5.537 5.537 0 01-2.391 3.635v2.99h3.855c2.263-2.084 3.564-5.161 3.564-8.8z'
      fill='#4285F4'
    />
    <path
      d='M12.004 23.97c3.227 0 5.948-1.06 7.93-2.889l-3.855-2.989c-1.072.73-2.457 1.143-4.07 1.143-3.121 0-5.767-2.105-6.717-4.936H1.314v3.081a11.964 11.964 0 0010.69 6.59z'
      fill='#34A853'
    />
    <path
      d='M5.288 14.299a7.165 7.165 0 010-4.58V6.637H1.314a11.973 11.973 0 000 10.743L5.288 14.3z'
      fill='#FBBC04'
    />
    <path
      d='M12.004 4.778a6.5 6.5 0 014.59 1.793l3.415-3.415A11.497 11.497 0 0012.004.044a11.96 11.96 0 00-10.69 6.593L5.288 9.72c.945-2.835 3.595-4.941 6.716-4.941z'
      fill='#EA4335'
    />
  </svg>
);

export default GoogleIcon;
