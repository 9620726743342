import * as React from 'react';
import styled from '@emotion/styled';
import { useRequestRedeemEventCodeMutation } from 'app/services/userInfo';
import { Toast } from 'components/common';
import { typography } from 'styles';
import TextField from './TextField';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const EventAiExpo: React.FC = () => {
  const [eventCode, setEventCode] = React.useState<string>('');

  const [requestRedeemEventCode, { isSuccess, error }] =
    useRequestRedeemEventCodeMutation();

  const [isOpenSuccess, setIsOpenSuccess] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isSuccess) {
      setIsOpenSuccess(true);
    }
  }, [isSuccess]);

  const [isOpenInvalidCode, setIsOpenInvalidCode] =
    React.useState<boolean>(false);
  const [isOpenOutdateCode, setIsOpenOutdateCode] =
    React.useState<boolean>(false);
  const [isOpenAlreadyUse, setIsOpenAlreadyUse] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (error === undefined) return;
    if (!isFetchBaseQueryError(error)) return;

    if (error.status === 400) {
      setIsOpenInvalidCode(true);
    }

    if (error.status === 403) {
      setIsOpenOutdateCode(true);
    }

    if (error.status === 406) {
      setIsOpenAlreadyUse(true);
    }
  }, [error]);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    requestRedeemEventCode(eventCode);
  };

  return (
    <Base>
      <EventBox>
        <TextField
          label={'이벤트 코드'}
          value={eventCode}
          onChange={(e) => setEventCode(e.target.value)}
        />
        <RedeemButton onClick={handleClick}>적용</RedeemButton>
      </EventBox>
      <Toast
        in={isOpenSuccess}
        message={'이벤트코드 적용이 완료되었습니다.'}
        severity={'success'}
        timeout={1500}
        onClose={() => setIsOpenSuccess(false)}
      />
      <Toast
        in={isOpenInvalidCode}
        message={'잘못된 이벤트코드입니다. 확인 후 다시 적용해주세요.'}
        severity={'error'}
        timeout={1500}
        onClose={() => setIsOpenInvalidCode(false)}
      />
      <Toast
        in={isOpenOutdateCode}
        message={'만료된 이벤트코드입니다. 확인 후 다시 적용해주세요.'}
        severity={'error'}
        timeout={1500}
        onClose={() => setIsOpenOutdateCode(false)}
      />
      <Toast
        in={isOpenAlreadyUse}
        message={'이미 이벤트코드 적용이 완료되었거나, 멤버십을 사용중입니다.'}
        severity={'error'}
        timeout={1500}
        onClose={() => setIsOpenAlreadyUse(false)}
      />
    </Base>
  );
};

export default EventAiExpo;

const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
  if (typeof error.status === undefined) return false;
  if (error.status === 'FETCH_ERROR') return true;
  if (error.status === 'PARSING_ERROR') return true;
  if (error.status === 'CUSTOM_ERROR') return true;
  if (typeof error.status === 'number') return true;
  return false;
};

const Base = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`;

const EventBox = styled.div`
  display: flex;
  align-items: flex-start;
`;

const RedeemButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  text-decoration: none;
  border-radius: 10px;
  color: var(--color-black);
  border: 1px solid var(--color-grey-500);
  min-width: 56px;
  height: 48px;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  padding: 0px 8px;
  cursor: pointer;
  margin-left: 8px;
  ${typography.body.large}

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
