import * as React from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  RegisterTemplate,
  RegisterSection,
  RegisterVerification,
  RegisterVerificationSuccess,
} from 'components/auth';
import { Register, useLoginAfterNavigate } from 'features/auth';

const RegisterPage: React.FC = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.hash);
  const type = search.get('#type');

  useLoginAfterNavigate();

  return (
    <GoogleOAuthProvider clientId={process.env.ONAIR_GOOGLE_CLIENT_ID}>
      <RegisterTemplate>
        <Routes>
          <Route
            index
            element={
              type === 'vresss' ? (
                <Register />
              ) : process.env.ONAIR_PLATFORM_TYPE === 'onair' ? (
                <RegisterSection />
              ) : (
                <Navigate to={'/auth/register/verification'} replace />
              )
            }
          />
          <Route path='verification' element={<RegisterVerification />} />
          <Route
            path='verification/success'
            element={<RegisterVerificationSuccess />}
          />
          <Route
            path={'*'}
            element={<Navigate to={'/auth/register'} replace />}
          />
        </Routes>
      </RegisterTemplate>
    </GoogleOAuthProvider>
  );
};

export default RegisterPage;
