import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import history from 'pages/history';

const useHistoryPrompt = (when: boolean = false, message: string = '') => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;
    if (!when) return;

    const unblock = history.block((tx) => {
      const confirm = window.confirm(message);

      if (!confirm) return;

      if (tx.action === 'POP') {
        unblock();
        navigate(tx.location.pathname, { replace: true });
        return;
      }

      unblock();
      tx.retry();
    });

    return () => {
      unblock();
    };
  }, [when, message, navigate]);
};

export default useHistoryPrompt;
