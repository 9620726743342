import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useVideo } from 'react-use';
import styled from '@emotion/styled';
import { useMatchMedia } from 'lib/hooks';
import { SpeakerSimpleHighIcon, SpeakerSimpleSlashIcon } from 'assets/svg';
import EditorImage from 'assets/img/main-editor@desktop.png';
import EditorCropImage from 'assets/img/main-editor-crop.png';
import MetapersonaCompressedVideo from 'assets/video/main-metapersona-compressed.mp4';
import { Toast } from 'components/common';
import { typography } from 'styles';
import GoRegisterButton from './GoRegisterButton';

const IntroSection: React.FC = () => {
  const { t } = useTranslation('main', { keyPrefix: 'intro' });

  const cropBreakpoint = useMatchMedia('(min-width: 736px)');

  const [element, state, controls] = useVideo({
    src: MetapersonaCompressedVideo,
    muted: true,
    autoPlay: true,
    loop: true,
    playsInline: true,
  });

  const handleClickVideo = () => {
    if (state.muted) {
      controls.unmute();
    } else {
      controls.mute();
    }
  };

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  return (
    <Base>
      <Container>
        <Column>
          <Box>
            <Title>{t('title')}</Title>
            <Body>{t('body')}</Body>
            <GoRegisterButton
              onClick={(e) => {
              }}
            >
              {t('register')}
            </GoRegisterButton>
            <Toast
              in={openToast}
              onClose={toggleOpenToast}
              timeout={1500}
              message={'Coming soon'}
              severity={'normal'}
            />
          </Box>
        </Column>
        <Column>
          <ImageBox>
            {cropBreakpoint ? (
              <Image src={EditorImage} alt={'onairstudio editor preview'} />
            ) : (
              <CropImage
                src={EditorCropImage}
                alt={'onairstudio editor preview'}
              />
            )}
            <VideoBox>
              <>
                {element}
                <MuteButton onClick={handleClickVideo}>
                  {state.muted ? (
                    <SpeakerSimpleSlashIcon
                      size={16}
                      color={'currentColor'}
                      weight={'fill'}
                    />
                  ) : (
                    <SpeakerSimpleHighIcon
                      size={16}
                      color={'currentColor'}
                      weight={'fill'}
                    />
                  )}
                  <span>{state.muted ? t('unmute') : t('mute')}</span>
                </MuteButton>
              </>
            </VideoBox>
          </ImageBox>
        </Column>
      </Container>
    </Base>
  );
};

export default IntroSection;

const Base = styled.section`
  @media (min-width: 1440px) {
    width: 100%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;

  flex-direction: column;

  width: 100%;

  @media (min-width: 1440px) {
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
  }
`;

const Column = styled.div`
  flex: 1;
  width: 100%;
`;

const Box = styled.div`
  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 1440px) {
    align-items: flex-start;
    margin-left: 120px;
    margin-bottom: 0;
  }
`;

const Title = styled.h2`
  white-space: pre;
  margin: 0 0 16px 0;

  color: transparent;
  background-clip: text;
  background-color: transparent;
  background-image: var(--color-gradient);

  ${typography.title.large}
  text-align: center;

  @media (min-width: 820px) {
    ${typography.display.medium}
  }

  @media (min-width: 1440px) {
    text-align: left;
  }
`;

const Body = styled.span`
  margin-bottom: 16px;
  display: block;
  white-space: pre;
  color: var(--color-grey-800);

  ${typography.body.medium}
  text-align: center;

  @media (min-width: 820px) {
    ${typography.body.large}
  }

  @media (min-width: 1440px) {
    text-align: left;
  }
`;

const ImageBox = styled.div`
  position: relative;
  background: var(--color-grey-100);
  box-shadow: 0px 4px 12px rgba(51, 51, 51, 0.16);
  border-radius: 10px;

  width: 100%;
  height: 503px;
  max-height: 480px;
  overflow: hidden;
  aspect-ratio: 160 / 219;

  user-select: none;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 736px) {
    width: 704px;
    height: 503px;
    aspect-ratio: 704 / 503;
    max-height: 503px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const CropImage = styled.img`
  -webkit-touch-callout: none;
  width: 100%;
  min-height: 480px;
`;

const Image = styled.img`
  -webkit-touch-callout: none;
  width: 704px;
  height: 503px;

  margin-left: 32px;

  @media (min-width: 704px) {
    margin-left: 0;
  }
`;

const VideoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10%;

  @media (min-width: 400px) {
    top: 12%;
  }

  @media (min-width: 476px) {
    top: 14%;
  }

  @media (min-width: 736px) {
    top: 45px;
    left: 316px;
  }

  & > video {
    border-radius: 6px;
    width: calc(100vw - 50%);

    @media (min-width: 736px) {
      width: 296px;
      height: 166.5px;
    }
  }
`;

const MuteButton = styled.button`
  outline: none;
  border: none;
  background: rgba(51, 51, 51, 0.6);
  border-radius: 12px;
  width: 96px;
  height: 24px;
  margin-top: 8px;
  color: var(--color-white);
  display: flex;
  align-items: center;
  padding: 3.5px 4px;

  ${typography.body.small}

  span {
    flex: 1;
  }
`;
