import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import { useFloating } from '@floating-ui/react-dom';
import styled from '@emotion/styled';
import { DotsThreeOutlineVerticalIcon } from 'assets/svg';

interface VideoListItemMenuProps {
  onClickModify: () => void;
  onClickDelete: () => void;
}

const VideoListItemMenu: React.FC<VideoListItemMenuProps> = ({
  onClickModify,
  onClickDelete,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'video' });

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom',
    strategy: 'fixed',
  });

  const handlePointerDown: React.PointerEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <Menu as={React.Fragment}>
      {({ open }) => (
        <>
          <Menu.Button
            as={Button}
            ref={reference}
            onPointerDown={handlePointerDown}
          >
            <DotsThreeOutlineVerticalIcon
              size={16}
              weight={'fill'}
              color={'currentColor'}
            />
          </Menu.Button>
          {open && (
            <Menu.Items
              as={Items}
              ref={floating}
              style={{
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Menu.Item
                as={Item}
                fontColor={'var(--color-black)'}
                onClick={onClickModify}
              >
                {t('text.modify')}
              </Menu.Item>
              <Menu.Item
                as={Item}
                fontColor={'var(--color-danger)'}
                onClick={onClickDelete}
              >
                {t('text.delete')}
              </Menu.Item>
            </Menu.Items>
          )}
        </>
      )}
    </Menu>
  );
};

export default VideoListItemMenu;

const Button = styled.button`
  border: none;
  background: rgba(51, 51, 51, 0.8);
  border-radius: 6px;
  color: var(--color-white);
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
`;

const Items = styled.div`
  z-index: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 88px;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border: 1px solid var(--color-grey-100);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
`;

const Item = styled.button<{ fontColor: string }>`
  outline: none;
  border: none;
  background: var(--color-white);
  width: 100%;
  height: 32px;
  color: ${({ fontColor }) => fontColor};
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
`;
