import * as React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useResizeObserver } from 'lib/hooks';
import {
  selectTimelineTrackCount,
  selectTimelineTimeGap,
  selectTimelineMultiplier,
  setTimelineRulerWidth,
  TIMELINE_BASE_RULER_GAP,
} from 'features/editor/editorSlice';
import { formattedTime } from './utils';

const useSetGapWidth = () => {
  const { width, ref } = useResizeObserver();

  const dispatch = useAppDispatch();

  const multiplierValue = useAppSelector(selectTimelineMultiplier);

  React.useEffect(() => {
    dispatch(setTimelineRulerWidth(width));
  }, [width, dispatch, multiplierValue]);

  return ref;
};

const TimelineGuide: React.FC = () => {
  const trackCount = useAppSelector(selectTimelineTrackCount);
  const timeGap = useAppSelector(selectTimelineTimeGap);

  const ref = useSetGapWidth();

  return (
    <Base ref={ref}>
      <RulerBox>
        {Array(trackCount + 1)
          .fill(undefined)
          .map((_, idx) => idx)
          .map((idx) => (
            <Track key={idx}>
              <Timestamp>{formattedTime(idx * timeGap)}</Timestamp>
              <GuideLine />
            </Track>
          ))}
      </RulerBox>
    </Base>
  );
};

export default TimelineGuide;

const Base = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  pointer-events: none;
`;

const RulerBox = styled.div`
  width: 100%;
  flex: 1;
  padding: 16px 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Track = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-width: ${TIMELINE_BASE_RULER_GAP}px;
`;

const GuideLine = styled.hr`
  padding: 0;
  margin: 0;
  background: var(--color-grey-300);
  width: 1px;
  border: none;
  flex: 1;
`;

const Timestamp = styled.span`
  width: 80px;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-500);
  padding: 2px 10px;
  background: var(--color-white);
  border-bottom: 1px solid var(--color-grey-200);
  border-radius: 6px;
`;
