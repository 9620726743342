import * as React from 'react';
import { useId } from 'lib/hooks';

const TailSpinIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const id = useId();
  const gradientId = `tail-spin-gradient-${id}`;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={38}
      height={38}
      viewBox='0 0 38 38'
      {...props}
    >
      <defs>
        <linearGradient
          id={gradientId}
          x1='8.042%'
          y1='0%'
          x2='65.682%'
          y2='23.865%'
        >
          <stop stopColor='#FF376E' stopOpacity={0} offset='0%' />
          <stop stopColor='#FF376E' stopOpacity={0.631} offset='63.146%' />
          <stop stopColor='#FF376E' offset='100%' />
        </linearGradient>
      </defs>
      <g transform='translate(1 1)' fill='none' fillRule='evenodd'>
        <path
          d='M36 18c0-9.94-8.06-18-18-18'
          stroke={`url(#${gradientId})`}
          strokeWidth={2}
        >
          <animateTransform
            attributeName='transform'
            type='rotate'
            from='0 18 18'
            to='360 18 18'
            dur='0.9s'
            repeatCount='indefinite'
          />
        </path>
        <circle fill='#FF376E' cx={36} cy={18} r={1}>
          <animateTransform
            attributeName='transform'
            type='rotate'
            from='0 18 18'
            to='360 18 18'
            dur='0.9s'
            repeatCount='indefinite'
          />
        </circle>
      </g>
    </svg>
  );
};

export default TailSpinIcon;
