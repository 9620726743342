import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useInterval } from 'lib/hooks';
import { TailSpinIcon } from 'assets/svg';
import {
  selectUpdateTime,
  selectNetworkState,
} from 'features/editor/editorSlice';

const getTimeValue = (
  updateTime: string
): {
  timestamp: string;
  timeFormat: Time;
  delay: number | null;
} => {
  const date = new Date(updateTime);
  const now = Date.now();

  let deltaTime = now - date.getTime();
  deltaTime = deltaTime < 0 ? 0 : deltaTime;

  switch (true) {
    case deltaTime > 7 * 24 * 60 * 60 * 1000: {
      return {
        timestamp: date.toLocaleDateString(),
        timeFormat: 'full',
        delay: null,
      };
    }
    case deltaTime > 24 * 60 * 60 * 1000: {
      return {
        timestamp: (deltaTime / 24 / 60 / 60 / 1000).toFixed(0),
        timeFormat: 'day',
        delay: 24 * 60 * 60 * 1000,
      };
    }
    case deltaTime > 60 * 60 * 1000: {
      return {
        timestamp: (deltaTime / 60 / 60 / 1000).toFixed(0),
        timeFormat: 'hour',
        delay: 60 * 60 * 1000,
      };
    }
    case deltaTime > 60 * 1000: {
      return {
        timestamp: (deltaTime / 60 / 1000).toFixed(0),
        timeFormat: 'minute',
        delay: 60 * 1000,
      };
    }
    default: {
      return {
        timestamp: (deltaTime / 1000).toFixed(0),
        timeFormat: 'second',
        delay: 1000,
      };
    }
  }
};

type Time = 'second' | 'minute' | 'hour' | 'day' | 'full';

const LastUpdatedTime: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'lastUpdatedTime' });

  const [timestamp, setTimestamp] = React.useState<string>('');

  const [timeFormat, setTimeFormat] = React.useState<Time>('second');
  const [delay, setDelay] = React.useState<number | null>(null);

  const updateTime = useAppSelector(selectUpdateTime);

  React.useEffect(() => {
    if (updateTime === undefined) return;

    const value = getTimeValue(updateTime);

    setTimestamp(value.timestamp);
    setTimeFormat(value.timeFormat);
    setDelay(value.delay);
  }, [updateTime]);

  useInterval(() => {
    if (updateTime === undefined) return;

    const value = getTimeValue(updateTime);

    setTimestamp(value.timestamp);
    setTimeFormat(value.timeFormat);
    setDelay(value.delay);
  }, delay);

  const networkState = useAppSelector(selectNetworkState);

  if (networkState === 'save') {
    return (
      <Base>
        <TailSpinIcon width={24} height={24} />
        {t('saving')}
      </Base>
    );
  }

  if (updateTime === undefined) return null;

  return <Base>{t(timeFormat, { timestamp })}</Base>;
};

export default LastUpdatedTime;

const Base = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-500);

  margin-right: 12px;

  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  @media (min-width: 1024px) {
    margin-right: 24px;
  }
`;
