import * as React from 'react';
import styled from '@emotion/styled';
import { CheckIcon } from 'assets/svg';
import { typography } from 'styles';

interface GradeCardOptionsItemProps {
  children?: React.ReactNode;
}

const GradeCardOptionsItem: React.FC<GradeCardOptionsItemProps> = ({
  children,
}) => {
  return (
    <Item>
      <CheckIcon size={24} weight={'bold'} color={'var(--color-pink)'} />
      <Text>{children}</Text>
    </Item>
  );
};

export default GradeCardOptionsItem;

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;

  svg {
    flex: none;
  }
`;

const Text = styled.span`
  width: 100%;
  ${typography.body.large}
  color: var(--color-black);
  margin-left: 8px;
`;
