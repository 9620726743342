import * as React from 'react';
import styled from '@emotion/styled';
import { throttle } from 'lib/utils';

interface ContentContainerProps {
  children?: React.ReactNode;
}

const ContentContainer: React.FC<ContentContainerProps> = ({ children }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const setContentSize = React.useCallback((width: number, height: number) => {
    const el = ref.current;
    if (el === null) return;

    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
  }, []);

  React.useLayoutEffect(() => {
    const listener = throttle(() => {
      const contentWidth = window.innerWidth - 72 - 250 - 401 - 72;
      const contentHeight = window.innerHeight - 64;

      setContentSize(contentWidth, contentHeight);
    });

    listener(undefined);

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [setContentSize]);

  return <Base ref={ref}>{children}</Base>;
};

export default ContentContainer;

const Base = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
