export type Code = 'ko' | 'en';

export type Language = '한국어' | 'English';

export type MergeText =
  | '온에어 스튜디오에서 편집과 녹음에서 자유로워지세요.'
  | 'Create videos more easily and quickly with On Air Studio';

const ISO639_1_PARTIAL = [
  {
    code: 'ko',
    name: '한국어',
    mergeText: '온에어 스튜디오에서 편집과 녹음에서 자유로워지세요.',
  },
  {
    code: 'en',
    name: 'English',
    mergeText: 'Create videos more easily and quickly with On Air Studio',
  },
] as const;

export const LOCALE_CODES = ISO639_1_PARTIAL.map((el) => el.code);
export const LOCALE_NAMES = ISO639_1_PARTIAL.map((el) => el.name);

export const codeToLang = (c: Code | string): Language | undefined => {
  if (!isLocaleCode(c)) return undefined;

  const f = ISO639_1_PARTIAL.filter((lang) => lang.code === c);

  if (f.length === 0) return undefined;
  return f[0].name;
};

export const codeToMergeText = (c: Code | string): MergeText | undefined => {
  if (!isLocaleCode(c)) return undefined;

  const f = ISO639_1_PARTIAL.filter((lang) => lang.code === c);

  if (f.length === 0) return undefined;
  return f[0].mergeText;
};

export const langToCode = (l: Language | string): Code | undefined => {
  if (!isLocaleName(l)) return undefined;

  const f = ISO639_1_PARTIAL.filter((lang) => lang.name === l);

  if (f.length === 0) return undefined;
  return f[0].code;
};

export const isLocaleCode = (s: string): s is Code => {
  for (let i = 0; i < LOCALE_CODES.length; i++) {
    if (LOCALE_CODES[i] === s) return true;
  }

  return false;
};

export const isLocaleName = (s: string): s is Language => {
  for (let i = 0; i < LOCALE_NAMES.length; i++) {
    if (LOCALE_NAMES[i] === s) return true;
  }

  return false;
};
