import * as React from 'react';
import ClickAwayListener from 'react-click-away-listener';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { TrashSimpleIcon } from 'assets/svg';
import {
  selectCurrentPageKey,
  removePageStv,
} from 'features/editor/editorSlice';
import { IconButton } from 'components/common';

const StvRemoveButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const pageKey = useAppSelector(selectCurrentPageKey);

  const [active, setActive] = React.useState<boolean>(false);
  const toggleActive = () => setActive((prev) => !prev);

  const handelClickBase: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setActive(true);
  };

  const handelLeaveBase: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setActive(false);
  };

  const handelClickButton: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (pageKey === undefined) return;

    dispatch(
      removePageStv({
        pageKey,
      })
    );
  };

  return (
    <Base onClick={handelClickBase} onMouseLeave={handelLeaveBase}>
      {active && (
        <ClickAwayListener onClickAway={toggleActive}>
          <Button size={36} radius={6} onClick={handelClickButton}>
            <TrashSimpleIcon
              size={24}
              color={'var(--color-grey-700)'}
              weight={'fill'}
            />
          </Button>
        </ClickAwayListener>
      )}
    </Base>
  );
};

export default StvRemoveButton;

const Base = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 6px;
  border-radius: 4px;
`;

const Button = styled(IconButton)`
  box-shadow: 0 4px 16px rgba(51, 51, 51, 0.32);
  cursor: pointer;
`;
