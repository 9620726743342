import * as React from 'react';
import styled from '@emotion/styled';

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: number;
  radius?: number;
  children?: React.ReactNode;
}

const IconButton: React.FC<IconButtonProps> = (props) => <Button {...props} />;

export default IconButton;

const Button = styled.button<{ size?: number; radius?: number }>`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  ${({ size }) =>
    size &&
    `
    width: ${size}px;
    height: ${size}px;
  `}

  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  border: none;
  outline: none;
  padding: 0;
  border-radius: ${({ radius }) =>
    radius === undefined ? '10px' : `${radius}px`};

  color: var(--color-grey-500);
  cursor: pointer;

  &:hover:not([disabled]) {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active:not([disabled]) {
    background: var(--active-gradient), var(--color-white);
  }

  &:disabled {
    color: var(--color-grey-200);
  }
`;
