import * as React from 'react';
import { useMatchMedia } from 'lib/hooks';
import Desktop from './Navigation.Desktop';
import Mobile from './Navigation.Mobile';

const Navigation: React.FC = () => {
  const match = useMatchMedia('(min-width: 1024px)');

  if (match) return <Desktop />;
  return <Mobile />;
};

export default Navigation;
