import * as React from 'react';
import styled from '@emotion/styled';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import { getHexLuminance } from 'lib/utils';

const getModelProfileViewStyle = (baseColor: string) =>
  ({
    '--model-view-color':
      getHexLuminance(baseColor) > 0.5
        ? 'var(--color-black)'
        : 'var(--color-white)',
    '--model-view-bg-color': baseColor,
  } as React.CSSProperties);

interface TimelineCellModelNameProps {
  name: string;
}

const TimelineCellModelName: React.FC<TimelineCellModelNameProps> = ({
  name,
}) => {
  const { model } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      model: data?.find((el) => el.name === name),
    }),
  });

  if (model === undefined) return null;
  return (
    <ModelProfileView style={getModelProfileViewStyle(model.defaultColor)}>
      {model.displayName[0].toUpperCase()}
    </ModelProfileView>
  );
};

export default TimelineCellModelName;

const ModelProfileView = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: var(--model-view-color);
  background-color: var(--model-view-bg-color);
  font-size: 10px;
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 20px;
`;
