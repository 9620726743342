import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export interface AutofillInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onAutoFill: (filled: boolean) => void;
}

const AutofillInput = React.forwardRef<HTMLInputElement, AutofillInputProps>(
  ({ onAutoFill, onAnimationStart, ...props }, ref) => {
    return (
      <Input
        onAnimationStart={(e) => {
          onAnimationStart?.(e);
          if (e.animationName === onAutoFillStart.name) {
            onAutoFill(true);
          } else if (e.animationName === onAutoFillCancel.name) {
            onAutoFill(false);
          }
        }}
        {...props}
        ref={ref}
      />
    );
  }
);

export default AutofillInput;

const onAutoFillStart = keyframes`from {}`;

const onAutoFillCancel = keyframes`to {}`;

const Input = styled.input`
  &:-webkit-autofill {
    animation-name: ${onAutoFillStart};
  }

  &:not(:-webkit-autofill) {
    animation-name: ${onAutoFillCancel};
  }
`;
