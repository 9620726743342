import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import VideoItemInfo from './VideoItemInfo';
import VideoList from './VideoList';

const VideoItem: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'video' });

  return (
    <Base>
      <Title>{t('title.default')}</Title>
      <VideoItemInfo />
      <VideoList />
    </Base>
  );
};

export default VideoItem;

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
`;
