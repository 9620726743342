import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useDeleteProjectMutation } from 'app/services/projects';
import { useId } from 'lib/hooks';
import { XIcon } from 'assets/svg';
import { Modal } from 'components/common';

interface ProjectDeleteDialogProps {
  uuid: string;
  onClose: () => void;
}

const ProjectDeleteDialog: React.FC<ProjectDeleteDialogProps> = ({
  uuid,
  onClose,
}) => {
  const { t } = useTranslation('client', { keyPrefix: 'home' });

  const [deleteProject] = useDeleteProjectMutation();

  const handleClickDeleteProject = () => {
    deleteProject(uuid);
    onClose();
  };

  const id = useId();

  if (uuid === '') return null;
  return (
    <Modal id={`project-delete-dialog-${id}`}>
      <Base>
        <Title>
          <CloseButton onClick={onClose}>
            <XIcon size={24} weight={'bold'} />
          </CloseButton>
        </Title>
        <Content>
          <span>{t('deleteDialog.body')}</span>
        </Content>
        <Actions>
          <SubmitButton onClick={handleClickDeleteProject}>
            {t('deleteDialog.submit')}
          </SubmitButton>
          <CancelButton onClick={onClose}>
            {t('deleteDialog.cancel')}
          </CancelButton>
        </Actions>
      </Base>
    </Modal>
  );
};

export default ProjectDeleteDialog;

const Base = styled.div`
  width: 440px;
  border-radius: 10px;
  background-color: var(--color-white);
`;

const Title = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
`;

const Content = styled.div`
  width: 100%;
  padding: 16px 24px;

  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;

  color: var(--color-black);
`;

const Actions = styled.div`
  width: 100%;
  padding: 16px 24px 32px;
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 10px;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
`;

const CancelButton = styled.button`
  margin-top: 16px;

  width: 100%;
  height: 48px;
  border-radius: 10px;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
`;
