import { loadScript } from 'lib/utils';

declare global {
  interface Window {
    ChannelIO: any;
  }
}

export interface ChannelIOBootOptions {
  pluginKey: string;
  memberId?: string;
  customLauncherSelector?: string;
  hideChannelButtonOnBoot?: boolean;
  zIndex?: number;
  language?: string;
  trackDefaultEvent?: boolean;
  trackUtmSource?: boolean;
  profile?: Record<string, any>;
  mobileMessengerMode?: string;
  unsubscribed?: boolean;
  memberHash?: string;
  hidePopup?: boolean;
}

export type ChannelIOLanguage = 'en' | 'ko' | 'ja';

export interface ChannelIOUserProfile extends Partial<Record<string, string>> {
  name?: string;
  email?: string;
  avatarUrl?: string;
  mobileNumber?: string;
}

export interface ChannelIOUpdateUserData {
  language?: ChannelIOLanguage | null;
  tags?: string[] | null;
  profile?: ChannelIOUserProfile | null;
  profileOnce?: ChannelIOUserProfile;
  unsubscribed?: boolean;
}

type ValueOf<T> = T[keyof T];

type ChannelIOState = 'isInitialized' | 'isBooted';
export interface ChannelIOEventMap {
  statechange: (evt: { type: ChannelIOState; value: boolean }) => void;
}
export type ChannelIOEvent = keyof ChannelIOEventMap;
export type ChannelIOListener = ValueOf<ChannelIOEventMap>;

const CHANNELIO_SRC = 'https://cdn.channel.io/plugin/ch-plugin-web.js';

/**
 * ChannelIO JS SDK
 *
 * reference: https://developers.channel.io/docs
 */
export default class ChannelIO {
  private static instance: ChannelIO | undefined = undefined;
  private isInitialized: boolean = false;
  private isBooted: boolean = false;
  private listener: Map<ChannelIOEvent, Set<ChannelIOListener>> = new Map();

  private constructor() {}

  static getInstance(): ChannelIO {
    if (ChannelIO.instance === undefined) {
      ChannelIO.instance = new ChannelIO();
    }

    return ChannelIO.instance;
  }

  private createPlugin(): void {
    if (window.ChannelIO) return;

    const ch = function (...args: any[]) {
      ch.c(args);
    };

    ch.q = [] as any[];
    ch.c = function (args: any[]) {
      ch.q.push(args);
    };

    window.ChannelIO = ch;
  }

  private async insertScript(): Promise<void> {
    if (this.isInitialized) return;

    try {
      await loadScript(CHANNELIO_SRC);
      this.setState('isInitialized', true);
    } catch (error) {
      console.error(error);
    }
  }

  initialize() {
    if (process.env.ONAIR_PLATFORM_TYPE !== 'onair') return;
    this.createPlugin();
    this.insertScript();
  }

  private checkInstanceState(
    isInitialized: boolean,
    isBooted: boolean
  ): boolean {
    if (this.isInitialized === isInitialized && this.isBooted === isBooted)
      return true;
    return false;
  }

  private setState(type: ChannelIOState, value: boolean) {
    this[type] = value;
    const stateListeners = this.listener.get('statechange');

    stateListeners?.forEach((listener) => {
      listener({ type, value });
    });
  }

  addListener<T extends ChannelIOEvent>(
    type: T,
    listener: ChannelIOEventMap[T]
  ) {
    let set = this.listener.get(type);

    if (set) {
      set.add(listener);
      return;
    }

    set = new Set<ChannelIOListener>();
    set.add(listener);
    this.listener.set(type, set);
  }

  removeListener<T extends ChannelIOEvent>(
    type: T,
    listener: ChannelIOEventMap[T]
  ) {
    const set = this.listener.get(type);
    if (set === undefined) return;

    set.delete(listener);

    if (set.size === 0) {
      this.listener.delete(type);
    } else {
      this.listener.set(type, set);
    }
  }

  boot(options: ChannelIOBootOptions): void {
    if (!this.checkInstanceState(true, false)) return;
    if (process.env.ONAIR_PLATFORM_TYPE !== 'onair') return;

    window.ChannelIO('boot', options, (error) => {
      if (error) {
        console.error(error);
      } else {
        this.setState('isBooted', true);
      }
    });
  }

  shutdown(): void {
    if (!this.checkInstanceState(true, true)) return;
    window.ChannelIO('shutdown');
  }

  hide(): void {
    if (!this.checkInstanceState(true, true)) return;
    window.ChannelIO('hideChannelButton');
  }

  show(): void {
    if (!this.checkInstanceState(true, true)) return;
    window.ChannelIO('showChannelButton');
  }

  showMessenger(): void {
    if (!this.checkInstanceState(true, true)) return;
    window.ChannelIO('showMessenger');
  }

  updateUser(data: ChannelIOUpdateUserData): void {
    if (!this.checkInstanceState(true, true)) return;
    window.ChannelIO('updateUser', data, (error, user) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(error, user);
      }
    });
  }
}
