import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { Toast } from 'components/common';
import {
  setOpenPageLimitToast,
  selectOpenPageLimitToast,
  setOpenCellLimitToast,
  selectOpenCellLimitToast,
  setOpenMergeLimitToast,
  selectOpenMergeLimitToast,
} from 'features/editor/editorSlice';

const CommonToasts: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'commonToast' });

  const { pageLimit, cellLimit, mergeLimit } = useGetUserInfoQuery(undefined, {
    selectFromResult: ({ data }) => ({
      pageLimit: data?.pageLimitNum ?? 0,
      cellLimit: data?.cellLimitNum ?? 0,
      mergeLimit: data?.synthLimitNum ?? 1,
    }),
  });

  const dispatch = useAppDispatch();
  const openPageLimitToast = useAppSelector(selectOpenPageLimitToast);
  const openCellLimitToast = useAppSelector(selectOpenCellLimitToast);
  const openMergeLimitToast = useAppSelector(selectOpenMergeLimitToast);

  return (
    <>
      <Toast
        in={openPageLimitToast}
        onClose={() => dispatch(setOpenPageLimitToast(false))}
        timeout={2500}
        severity={'error'}
        message={t('pageLimit', { limit: pageLimit })}
      />
      <Toast
        in={openCellLimitToast}
        onClose={() => dispatch(setOpenCellLimitToast(false))}
        timeout={2500}
        severity={'error'}
        message={t('cellLimit', { limit: cellLimit })}
      />
      <Toast
        in={openMergeLimitToast}
        onClose={() => dispatch(setOpenMergeLimitToast(false))}
        timeout={2500}
        severity={'error'}
        message={t('mergeLimit', { limit: mergeLimit })}
      />
    </>
  );
};

export default CommonToasts;
