import { createAction, createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import AudioStorage from 'lib/AudioStorage';
import PusherService from 'lib/PusherService';
import videoAssetsApi from 'app/services/videoAssets';
import {
  fetchMergeMlModel,
  fetchProject,
  fetchExtractFile,
  fetchExtractFileInfo,
  fetchModifyProject,
  fetchInitialProject,
  fetchProjectSubtitleSRT,
  fetchProjectSubtitleTXT,
  fetchProjectAudio,
  fetchProjectVideo,
  fetchProjectChromakey,
  fetchUploadImage,
  fetchUploadVideo,
  fetchMergeAudio,
  fetchGetProjectVideo,
  fetchGetProjectAudio,
  fetchGetProjectChromakey,
} from 'features/editor/networks';
import { ProjectUpdateError } from 'features/editor/utils';
import {
  SLIDE_MIN_DURATION,
  VOICE_DEFAULT_VOLUME,
  VOICE_DEFAULT_SPEED,
} from 'features/editor/constants';
import {
  editorActionTypeCreator,
  serializeProjectToDataState,
  serializeStateToProjectString,
} from './utils';
import type { RootState } from 'app/store';
import type { VideoModel } from 'app/services/videoModel';
import type { VideoAsset } from 'app/services/videoAssets';
import type {
  ToolbarItem,
  CharacterToolIndex,
  UploadToolIndex,
  AudioToolIndex,
  AudioToolState,
  ClosedCaptionState,
  CellEntity,
  FetchProjectByIdData,
  Project,
  PageEntity,
  EditableCell,
} from './types';

export const setToolbarItem = createAction<ToolbarItem>(
  editorActionTypeCreator('setToolbarItem')
);
export const setCharacterToolIndex = createAction<CharacterToolIndex>(
  editorActionTypeCreator('setCharacterToolIndex')
);
export const setUploadToolIndex = createAction<UploadToolIndex>(
  editorActionTypeCreator('setUploadToolIndex')
);
export const setAudioToolIndex = createAction<AudioToolIndex>(
  editorActionTypeCreator('setAudioToolIndex')
);
export const setAudioToolState = createAction<AudioToolState>(
  editorActionTypeCreator('setAudioToolState')
);
export const clearAudioToolState = createAction<
  keyof AudioToolState | undefined
>(editorActionTypeCreator('clearAudioToolState'));
export const setClosedCaptionState = createAction<Partial<ClosedCaptionState>>(
  editorActionTypeCreator('setClosedCaptionState')
);
export const setSelectPageKey = createAction<string | undefined>(
  editorActionTypeCreator('setSelectPageKey')
);
export const setSelectCellKey = createAction<string | undefined>(
  editorActionTypeCreator('setSelectCellKey')
);
export const setInputSelection = createAction<number | undefined>(
  editorActionTypeCreator('setInputSelection')
);
export const setNetworkState = createAction<'idle' | 'load' | 'save'>(
  editorActionTypeCreator('setNetworkState')
);
export const setProjectName = createAction<string>(
  editorActionTypeCreator('setProjectName')
);
export const setVideoHash = createAction<string | undefined | null>(
  editorActionTypeCreator('setVideoHash')
);
export const setPageLimit = createAction<number | undefined>(
  editorActionTypeCreator('setPageLimit')
);
export const setOpenPageLimitToast = createAction<boolean>(
  editorActionTypeCreator('setOpenPageLimitToast')
);
export const setCellLimit = createAction<number | undefined>(
  editorActionTypeCreator('setCellLimit')
);
export const setOpenCellLimitToast = createAction<boolean>(
  editorActionTypeCreator('setOpenCellLimitToast')
);
export const setOpenMergeLimitToast = createAction<boolean>(
  editorActionTypeCreator('setOpenMergeLimitToast')
);
export const setInitializeProjectName = createAction(
  editorActionTypeCreator('setInitializeProjectName')
);
export const setInitializeCellData = createAction(
  editorActionTypeCreator('setInitializeCellData')
);
export const setVideoPagePaddingCell = createAction<{ modelName: string }>(
  editorActionTypeCreator('setVideoPagePaddingCell')
);
interface ModifyCellDataPayload extends Partial<Omit<CellEntity, 'uuid'>> {
  pageKey: string;
  cellKey: string;
}
export const modifyCellData = createAction(
  editorActionTypeCreator('modifyCellData'),
  (payload: ModifyCellDataPayload) => {
    if (payload.duration === undefined) return { payload };

    return {
      payload: {
        ...payload,
        duration: Math.round(payload.duration),
      },
    };
  }
);

export const moveCell = createAction<{
  pageKey: string;
  from: number;
  to: number;
}>(editorActionTypeCreator('moveCell'));
export const createCell = createAction<
  {
    pageKey: string;
    index: number;
  } & Partial<CellEntity>
>(editorActionTypeCreator('createCell'));
export const removeCell = createAction<string>(
  editorActionTypeCreator('removeCell')
);
export const createLastPage = createAction(
  editorActionTypeCreator('createLastPage')
);
export const modifyManyMlModel = createAction<{
  mlModelName: string;
  pageKey?: string;
}>(editorActionTypeCreator('modifyManyMlModel'));

interface ModifyManyDurationPayload {
  duration: number;
  pageKey?: string;
}
export const modifyManyDuration = createAction(
  editorActionTypeCreator('modifyManyDuration'),
  (payload: ModifyManyDurationPayload) => {
    return {
      payload: {
        ...payload,
        duration: Math.round(payload.duration),
      },
    };
  }
);

interface ModifyManyVoiceOptionPayload {
  volume: number;
  speed: number;
  pageKey?: string;
}
export const modifyManyVoiceOption = createAction(
  editorActionTypeCreator('modifyManyVoiceOption'),
  (payload: ModifyManyVoiceOptionPayload) => {
    return {
      payload: {
        ...payload,
        volume: payload.volume,
        speed: payload.speed,
      },
    };
  }
);

export const clearPage = createAction<{ pageKey?: string }>(
  editorActionTypeCreator('clearPage')
);
export const clearData = createAction('clearData');

interface ModifyPageDataPayload extends Partial<Omit<PageEntity, 'key'>> {
  pageKey?: string;
}
export const modifyPageData = createAction(
  editorActionTypeCreator('modifyPageData'),
  (payload: ModifyPageDataPayload) => {
    if (payload.duration === undefined) return { payload };

    return {
      payload: {
        ...payload,
        duration: Math.max(Math.round(payload.duration), SLIDE_MIN_DURATION),
      },
    };
  }
);

interface ModifyManyPageDataPayload extends Partial<Omit<PageEntity, 'key'>> {
  pageKeys?: string[];
}
export const modifyManyPageData = createAction(
  editorActionTypeCreator('modifyManyPageData'),
  (payload: ModifyManyPageDataPayload) => {
    if (payload.duration === undefined) return { payload };

    return {
      payload: {
        ...payload,
        duration: Math.max(Math.round(payload.duration), SLIDE_MIN_DURATION),
      },
    };
  }
);

export const movePage = createAction<{
  from: number;
  to: number;
}>(editorActionTypeCreator('movePage'));

export const copyPage = createAction<{ pageKey: string }>(
  editorActionTypeCreator('copyPage')
);
export const modifyCurrentSTVLocation = createAction<{
  x?: number;
  y: number;
  width?: number;
  height?: number;
}>(editorActionTypeCreator('modifySTVLocation'));

export const splitCell = createAction<{
  cellKey: string;
  len: number;
}>(editorActionTypeCreator('splitCell'));
export const mergeCell = createAction<{ cellKey: string }>(
  editorActionTypeCreator('mergeCell')
);
export const removePage = createAction<{ pageKey: string }>(
  editorActionTypeCreator('removePage')
);
export const createNewCell = createAction<{
  pageKey: string;
  modelName: string;
  isLast?: boolean;
}>(editorActionTypeCreator('createNewCell'));
export const createNewCellByModel = createAction<{
  pageKey: string;
  modelName: string;
}>(editorActionTypeCreator('createNewCellByModel'));
export const removePageStv = createAction<{
  pageKey: string;
}>(editorActionTypeCreator('removePageStv'));

interface ParsedFileData {
  info: {
    image: {
      tmpPath: string;
      url: string;
    };
    thumb: {
      tmpPath: string;
      url: string;
    };
    cells: {
      text: string;
      displayText: string;
      duration: number;
    }[];
  }[];
  wordBook: Record<string, string | null>;
  incomplete?: boolean;
}

export const fetchExtractFileAdd = createAsyncThunk<
  ParsedFileData,
  {
    file: File;
    type: 'video' | 'audio_only';
    channelId: string;
  },
  { state: RootState }
>(
  editorActionTypeCreator('fetchExtractFileAdd'),
  (payload, { getState, dispatch }) => {
    return new Promise<ParsedFileData>(async (resolve, reject) => {
      const token = getState().auth.token;
      if (token === null) throw new Error('not authorization');

      try {
        dispatch(setNetworkState('load'));
        const eventId = nanoid();

        const channel = PusherService.subscribeChannel(payload.channelId);

        channel.bind(
          eventId,
          async (data: {
            type: 'extract_file';
            url?: string;
            status_code: -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4;
          }) => {
            if (data.status_code < 0) {
              dispatch(setNetworkState('idle'));
              if (data.status_code === -1) {
                reject(new Error('default'));
              }
              if (data.status_code === -2) {
                reject(new Error('pageLimit'));
              }
              if (data.status_code === -3) {
                reject(new Error('cellLimit'));
              }
              return;
            }
            if (data.status_code === 0) return;
            if (data.status_code === 1) return;
            if (data.status_code === 2) return;
            if (data.url === undefined) return;

            try {
              const datas = await fetchExtractFileInfo(data.url);
              const { info, wordBook } = datas.reduce<ParsedFileData>(
                (acc, item) => {
                  const { wordBook, ...rest } = item;
                  acc.info.push(rest);
                  acc.wordBook = { ...acc.wordBook, ...wordBook };
                  return acc;
                },
                { info: [], wordBook: {} }
              );

              dispatch(setNetworkState('idle'));

              const incomplete = data.status_code === 4;
              resolve({ info, wordBook, incomplete });
            } catch (error) {
              dispatch(setNetworkState('idle'));
              reject(error);
            }
          }
        );

        fetchExtractFile({
          file: payload.file,
          type: payload.type,
          channelId: payload.channelId,
          eventId,
          token,
        });
      } catch (error) {
        dispatch(setNetworkState('idle'));
        reject(error);
      }
    });
  }
);

export const overrideExtractFileSlide = createAction<
  { startPageKey: string; modelName: string } & ParsedFileData
>(editorActionTypeCreator('overrideExtractFileSlide'));

export const appendExtractFileSlide = createAction<
  { startPageKey: string; modelName: string } & ParsedFileData
>(editorActionTypeCreator('appendExtractFileSlide'));

export const overrideImageFileSlide = createAction<{
  pageKey: string;
  imageUrl: string;
  imagePath: string;
  thumbnailUrl: string;
  thumbnailPath: string;
}>(editorActionTypeCreator('overrideImageFileSlide'));

export const appendImageFileSlide = createAction<{
  pageKey: string;
  imageUrl: string;
  imagePath: string;
  thumbnailUrl: string;
  thumbnailPath: string;
}>(editorActionTypeCreator('appendImageFileSlide'));

export const appendExtractFileText = createAction<
  { startPageKey: string; modelName: string } & ParsedFileData
>(editorActionTypeCreator('appendExtractFileText'));

export const overrideExtractFileText = createAction<
  { startPageKey: string; modelName: string } & ParsedFileData
>(editorActionTypeCreator('overrideExtractFileText'));

export const cancelUpdateTimer = createAction(
  editorActionTypeCreator('cancelUpdateTimer')
);

export const undo = createAction(editorActionTypeCreator('undo'));
export const redo = createAction(editorActionTypeCreator('redo'));
export const clearHistory = createAction(
  editorActionTypeCreator('clearHistory')
);

export const setChannelId = createAction<string>(
  editorActionTypeCreator('setChannelId')
);

export const fetchProjectById = createAsyncThunk<
  FetchProjectByIdData,
  { uuid: string; videoModels: VideoModel[] },
  { state: RootState }
>(
  editorActionTypeCreator('fetchProjectById'),
  async ({ uuid, videoModels }, { getState }) => {
    const token = getState().auth.token;
    if (token === null) throw new Error('not authorization');

    try {
      const data = await fetchProject({ uuid, token });

      return serializeProjectToDataState(data as Project, videoModels);
    } catch (error) {
      throw error;
    }
  }
);

export const fetchTemplateById = createAsyncThunk<
  FetchProjectByIdData,
  { uuid: string; videoModels: VideoModel[] },
  { state: RootState }
>(
  editorActionTypeCreator('fetchTemplateById'),
  async ({ uuid, videoModels }, { getState }) => {
    const token = getState().auth.token;
    if (token === null) throw new Error('not authorization');

    try {
      const data = await fetchProject({ uuid, token });

      return serializeProjectToDataState(data as Project, videoModels);
    } catch (error) {
      throw error;
    }
  }
);

export const fetchMergeMlModelText = createAsyncThunk<
  Blob,
  {
    channelId: string;
    text: string;
    volume?: number;
    speed?: number;
    name: string;
    noBlob?: boolean;
  },
  { state: RootState }
>(editorActionTypeCreator('fetchMergeMlModelText'), (payload, { getState }) => {
  return new Promise<Blob>(async (resolve, reject) => {
    const token = getState().auth.token;
    if (token === null) {
      reject(new Error('not authorization'));
      return;
    }

    const eventId = nanoid();

    const cacheKey = AudioStorage.makeKey(
      payload.name,
      payload.text,
      payload.volume ?? VOICE_DEFAULT_VOLUME,
      payload.speed ?? VOICE_DEFAULT_SPEED
    );

    const channel = PusherService.subscribeChannel(payload.channelId);
    channel.bind(
      eventId,
      async (data: {
        file_name: string;
        model_name: string;
        text: string;
        volume: number;
        speed: number;
        url: string;
      }) => {
        try {
          const responseAudio = await fetch(data.url);
          const blob = await responseAudio.blob();

          AudioStorage.set(cacheKey, blob);

          resolve(blob);
        } catch (error) {
          reject(error);
        }
      }
    );

    const cacheData = AudioStorage.get(cacheKey);

    if (cacheData !== undefined) {
      resolve(cacheData);
      return;
    }

    try {
      const data = await fetchMergeMlModel({
        channelId: payload.channelId,
        eventId,
        name: payload.name,
        text: payload.text,
        volume: payload.volume,
        speed: payload.speed,
        token,
      });

      if (data.downloadUrls.length > 0) {
        const responseAudio = await fetch(data.downloadUrls[0].downloadUrl);
        const blob = await responseAudio.blob();

        AudioStorage.set(cacheKey, blob);

        resolve(blob);
      }
    } catch (error) {
      reject(error);
    }
  });
});

export const fetchUpdateProject = createAsyncThunk<
  FetchProjectByIdData,
  void,
  { state: RootState }
>(
  editorActionTypeCreator('fetchUpdateProject'),
  (_, { getState, dispatch, signal }) => {
    return new Promise<FetchProjectByIdData>(async (resolve, reject) => {
      const token = getState().auth.token;
      const dataState = getState().editor.present.data;
      const uiState = getState().editor.present.ui;
      if (token === null) {
        reject(new Error('not authorization'));
        return;
      }

      const channelId = getState().editor.present.channelId;
      if (channelId === '') {
        reject(new Error('not authorization'));
        return;
      }

      dispatch(setNetworkState('save'));

      const body = serializeStateToProjectString({ ...dataState, ...uiState });

      const eventId = nanoid();
      const channel = PusherService.subscribeChannel(channelId);

      const uuid = dataState.uuid;
      channel.bind(
        eventId,
        async (data: {
          type: 'project_update';
          status_code: -1 | 0 | 1 | 2;
        }) => {
          if (data.type !== 'project_update') {
            reject();
            return;
          }

          if (data.status_code === -1) {
            reject(
              new ProjectUpdateError(
                JSON.stringify({
                  channel_id: channelId,
                  event_id: eventId,
                  ...JSON.parse(body),
                })
              )
            );
            return;
          }

          if (data.status_code === 2 && uuid !== undefined) {
            const data = await fetchProject({ uuid, token });
            resolve(serializeProjectToDataState(data as Project));
            return;
          }
        }
      );

      try {
        await fetchModifyProject({
          token,
          channelId,
          eventId,
          body,
          signal,
        });
      } catch (error) {
        reject(error);
      }
    });
  }
);

export const fetchCreateProject = createAsyncThunk<
  FetchProjectByIdData,
  void,
  { state: RootState }
>(
  editorActionTypeCreator('fetchCreateProject'),
  async (_, { getState, dispatch, signal }) => {
    const token = getState().auth.token;
    const dataState = getState().editor.present.data;
    const uiState = getState().editor.present.ui;
    if (token === null) throw new Error('not authorization');

    dispatch(setNetworkState('save'));

    const body = serializeStateToProjectString({ ...dataState, ...uiState });

    try {
      const data = await fetchInitialProject({ token, body, signal });

      dispatch(setNetworkState('idle'));
      return serializeProjectToDataState(data as Project);
    } catch (error) {
      dispatch(setNetworkState('idle'));
      throw error;
    }
  }
);

export const fetchSubtitleSRT = createAsyncThunk<
  Blob,
  void,
  { state: RootState }
>(
  editorActionTypeCreator('fetchSubtitleSRT'),
  async (_, { getState, dispatch }) => {
    const token = getState().auth.token;
    const uuid = getState().editor.present.data.uuid;
    const videoHash = getState().editor.present.data.videoHash;
    if (token === null) throw new Error('not authorization');
    if (uuid === undefined) throw new Error('project not exist');
    if (videoHash === null || videoHash === undefined)
      throw new Error('video not existed');

    const modified = getState().editor.present.data._modified;

    if (modified) {
      await dispatch(fetchUpdateProject());
    }

    const { downloadUrl } = await fetchProjectSubtitleSRT({
      token,
      uuid,
      videoHash,
    });

    try {
      if (downloadUrl === undefined) throw new Error('srt not exist');
      const response = await fetch(downloadUrl);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSubtitleTXT = createAsyncThunk<
  Blob,
  void,
  { state: RootState }
>(
  editorActionTypeCreator('fetchSubtitleTXT'),
  async (_, { getState, dispatch }) => {
    const token = getState().auth.token;
    const uuid = getState().editor.present.data.uuid;
    if (token === null) throw new Error('not authorization');
    if (uuid === undefined) throw new Error('project not exist');

    const modified = getState().editor.present.data._modified;

    if (modified) {
      await dispatch(fetchUpdateProject());
    }

    const data = await fetchProjectSubtitleTXT({ token, uuid });

    return data;
  }
);

export const fetchAudio = createAsyncThunk<
  {
    fileName: string;
    downloadUrl: string;
  },
  {
    eventId: string;
    channelId: string;
    format: 'zip' | 'audio';
  },
  { state: RootState }
>(
  editorActionTypeCreator('fetchAudio'),
  async ({ channelId, eventId, format }, { getState, dispatch }) => {
    const token = getState().auth.token;
    const uuid = getState().editor.present.data.uuid;
    if (token === null) throw new Error('not authorization');
    if (uuid === undefined) throw new Error('project not exist');

    const modified = getState().editor.present.data._modified;

    if (modified) {
      await dispatch(fetchUpdateProject());
    }

    try {
      const data = await fetchProjectAudio({
        token,
        uuid,
        eventId,
        channelId,
        format,
      });

      return data;
    } catch (error) {
      throw error;
    }
  }
);

type Status = -1 | 0 | 1 | 2 | 3 | 4 | 5;

export const fetchVideo = createAsyncThunk<
  {
    status: Status;
    downloadUrl: string;
  },
  {
    eventId: string;
    channelId: string;
    lqMode?: boolean;
  },
  { state: RootState }
>(
  editorActionTypeCreator('fetchVideo'),
  async ({ channelId, eventId, lqMode }, { getState, dispatch }) => {
    const token = getState().auth.token;
    const uuid = getState().editor.present.data.uuid;
    if (token === null) throw new Error('not authorization');
    if (uuid === undefined) throw new Error('project not exist');

    const modified = getState().editor.present.data._modified;

    if (modified) {
      await dispatch(fetchUpdateProject());
    }

    try {
      const data = await fetchProjectVideo({
        token,
        uuid,
        eventId,
        channelId,
        lqMode,
      });

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchChromakey = createAsyncThunk<
  {
    fileName: string;
    downloadUrl: string;
  },
  {
    eventId: string;
    channelId: string;
  },
  { state: RootState }
>(
  editorActionTypeCreator('fetchChromakey'),
  async ({ channelId, eventId }, { getState, dispatch }) => {
    const token = getState().auth.token;
    const uuid = getState().editor.present.data.uuid;
    if (token === null) throw new Error('not authorization');
    if (uuid === undefined) throw new Error('project not exist');

    const modified = getState().editor.present.data._modified;

    if (modified) {
      await dispatch(fetchUpdateProject());
    }

    try {
      const data = await fetchProjectChromakey({
        token,
        uuid,
        eventId,
        channelId,
      });

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUploadVideoItem = createAsyncThunk<
  void,
  {
    file: File;
    displayName: string;
  },
  { state: RootState }
>(
  editorActionTypeCreator('fetchUploadVideoItem'),
  ({ file, displayName }, { getState, dispatch }) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().auth.token;
      if (token === null) {
        reject(new Error('not authorization'));
        return;
      }

      const channelId = getState().editor.present.channelId;
      if (channelId === '') {
        reject(new Error('not authorization'));
        return;
      }

      try {
        dispatch(setNetworkState('load'));
        const eventId = nanoid();
        const channel = PusherService.subscribeChannel(channelId);

        channel.bind(
          eventId,
          async (data: {
            type: 'video_file';
            status_code: -1 | 0 | 1 | 2 | 3;
          }) => {
            if (data.type !== 'video_file') {
              reject(new Error('asset_type'));
              return;
            }

            if (data.status_code === -1) {
              dispatch(setNetworkState('idle'));
              reject(new Error('default'));
              return;
            }
            if (data.status_code === 0) return;
            if (data.status_code === 1) return;
            if (data.status_code === 2) return;

            dispatch(
              videoAssetsApi.util.invalidateTags([{ type: 'VideoAsset' }])
            );
            dispatch(setNetworkState('idle'));
          }
        );

        try {
          await fetchUploadVideo({
            token,
            file,
            channelId,
            eventId,
            displayName,
          });
          resolve();
        } catch (error) {
          dispatch(setNetworkState('idle'));
          reject(error);
        }
      } catch (error) {
        dispatch(setNetworkState('idle'));
        reject(error);
      }
    });
  }
);

export const fetchUploadTempImage = createAsyncThunk<
  {
    imageUrl: string;
    imagePath: string;
    thumbnailUrl: string;
    thumbnailPath: string;
  },
  { file: File },
  { state: RootState }
>(
  editorActionTypeCreator('fetchUpload'),
  async ({ file }, { getState, dispatch }) => {
    const token = getState().auth.token;
    if (token === null) throw new Error('not authorization');

    try {
      dispatch(setNetworkState('load'));
      const data = await fetchUploadImage({
        token,
        file,
      });
      dispatch(setNetworkState('idle'));
      return data;
    } catch (error) {
      dispatch(setNetworkState('idle'));
      throw error;
    }
  }
);

export const fetchTimelineCells = createAsyncThunk<
  (CellEntity & { audioDuration: number })[],
  string,
  { state: RootState }
>(
  editorActionTypeCreator('fetchTimelineCells'),
  async (pageKey, { getState, dispatch }) => {
    const token = getState().auth.token;
    if (token === null) throw new Error('not authorization');
    const channelId = getState().editor.present.channelId;
    if (channelId === '') throw new Error('channelId not provided');

    const dataState = getState().editor.present.data;
    const page = dataState.pages.find((el) => el.key === pageKey);
    if (page === undefined) throw new Error('page is empty');
    if (page.videoPath === undefined || page.videoUrl === undefined) {
      throw new Error('page has not video');
    }

    try {
      const timelineCells = await Promise.all(
        page.cells.map((cell) => {
          return new Promise<CellEntity & { audioDuration: number }>(
            async (resolve, reject) => {
              try {
                if (cell.text === '') {
                  resolve({ ...cell, audioDuration: 0 });
                  return;
                }

                const blob = await dispatch(
                  fetchMergeMlModelText({
                    channelId,
                    text: cell.text,
                    name: cell.mlModelName,
                  })
                ).unwrap();

                const el = document.createElement('audio');
                el.addEventListener(
                  'loadedmetadata',
                  () => {
                    const audioDuration = Math.ceil(el.duration * 1000);
                    resolve({ ...cell, audioDuration });
                    URL.revokeObjectURL(el.src);
                  },
                  { once: true }
                );

                el.src = URL.createObjectURL(blob);
              } catch (error) {
                reject(error);
              }
            }
          );
        })
      );

      return timelineCells;
    } catch (error) {
      throw error;
    }
  }
);
export const createTimelineCell = createAction<{
  startTime: number;
  audioDuration: number;
}>(editorActionTypeCreator('createTimelineCell'));

export const createTimelinePaddingCell = createAction<{ name: string }>(
  editorActionTypeCreator('createTimelinePaddingCell')
);

export const editTimelineCell = createAction<{
  startTime: number;
  audioDuration: number;
}>(editorActionTypeCreator('editTimelineCell'));

export const newMoveTimelineCell = createAction<{
  from: number;
  to: number;
}>(editorActionTypeCreator('newMoveTimelineCell'));

export const removeTimelineCell = createAction<{ key: string; length: number }>(
  editorActionTypeCreator('removeTimelineCell')
);

export const moveTimelineCell = createAction<{
  cellKey: string;
  from: number;
  to: number;
}>(editorActionTypeCreator('moveTimelineCell'));

export const setTimelineIsLoading = createAction<{
  isLoading: boolean;
}>(editorActionTypeCreator('setTimelineIsLoading'));

export const setTimelineChange = createAction<{
  isChanged: boolean;
}>(editorActionTypeCreator('setTimelineChange'));

export const fetchMergeText = createAsyncThunk<
  Blob,
  {
    text: string;
    name: string;
    volume?: number;
    speed?: number;
  },
  { state: RootState }
>(editorActionTypeCreator('fetchMergeText'), (payload, { getState }) => {
  return new Promise<Blob>(async (resolve, reject) => {
    const state = getState();

    const token = state.auth.token;
    if (token === null) {
      reject(new Error('not authorization'));
      return;
    }
    const channelId = state.editor.present.channelId;
    if (channelId === '') {
      reject(new Error('not authorization'));
      return;
    }

    const eventId = nanoid();

    const cacheKey = AudioStorage.makeKey(
      payload.name,
      payload.text,
      payload.volume ?? VOICE_DEFAULT_VOLUME,
      payload.speed ?? VOICE_DEFAULT_SPEED
    );

    const cacheData = AudioStorage.get(cacheKey);
    if (cacheData !== undefined) {
      resolve(cacheData);
      return;
    }

    const channel = PusherService.subscribeChannel(channelId);
    channel.bind(
      eventId,
      async (data: {
        file_name: string;
        model_name: string;
        text: string;
        volume: number;
        speed: number;
        url: string;
      }) => {
        try {
          const responseAudio = await fetch(data.url);
          const blob = await responseAudio.blob();

          AudioStorage.set(cacheKey, blob);

          resolve(blob);
        } catch (error) {
          reject(error);
        }
      }
    );

    try {
      const data = await fetchMergeMlModel({
        token,
        channelId,
        eventId,
        ...payload,
      });

      if (data.downloadUrls.length > 0) {
        const responseAudio = await fetch(data.downloadUrls[0].downloadUrl);
        const blob = await responseAudio.blob();

        AudioStorage.set(cacheKey, blob);
        resolve(blob);
      }
    } catch (error) {
      reject(error);
    }
  });
});

export const fetchMergeAudios = createAsyncThunk<
  string,
  {
    duration: number;
    textData: {
      mlModel: string;
      text: string;
      volume: number;
      speed: number;
      duration: number;
    }[];
  }[],
  { state: RootState }
>(editorActionTypeCreator('fetchMergeAudios'), (payload, { getState }) => {
  return new Promise<string>(async (resolve, reject) => {
    const state = getState();

    const token = state.auth.token;
    if (token === null) {
      reject(new Error('not authorization'));
      return;
    }
    const channelId = state.editor.present.channelId;
    if (channelId === '') {
      reject(new Error('not authorization'));
      return;
    }

    const eventId = nanoid();

    const channel = PusherService.subscribeChannel(channelId);
    channel.bind(eventId, (data: { type: 'pre_audio'; url: string }) => {
      try {
        resolve(data.url);
      } catch (error) {
        reject(error);
      }
    });

    try {
      await fetchMergeAudio({
        channelId,
        eventId,
        token,
        pages: payload,
      });
    } catch (error) {
      reject(error);
    }
  });
});

export const fetchGetVideoStatus = createAsyncThunk<
  boolean,
  void,
  { state: RootState }
>(editorActionTypeCreator('fetchGetVideoStatus'), (_, { getState }) => {
  return new Promise<boolean>(async (resolve, reject) => {
    const state = getState();

    const token = state.auth.token;
    if (token === null) {
      reject(new Error('not authorization'));
      return;
    }

    const uuid = state.editor.present.data.uuid;
    if (uuid === undefined) {
      reject(new Error('project uuid is null'));
      return;
    }

    try {
      const resp = await fetchGetProjectVideo({
        token,
        uuid,
      });

      if (resp.downloadUrl !== '') {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (error) {
      reject(error);
    }
  });
});

export const fetchGetAudioStatus = createAsyncThunk<
  boolean,
  'zip' | 'audio',
  { state: RootState }
>(editorActionTypeCreator('fetchGetAudioStatus'), (format, { getState }) => {
  return new Promise<boolean>(async (resolve, reject) => {
    const state = getState();

    const token = state.auth.token;
    if (token === null) {
      reject(new Error('not authorization'));
      return;
    }

    const uuid = state.editor.present.data.uuid;
    if (uuid === undefined) {
      reject(new Error('project uuid is null'));
      return;
    }

    try {
      const resp = await fetchGetProjectAudio({
        token,
        uuid,
        format,
      });

      if (resp.downloadUrl !== '') {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (error) {
      reject(error);
    }
  });
});

export const fetchGetChromakeyStatus = createAsyncThunk<
  boolean,
  void,
  { state: RootState }
>(editorActionTypeCreator('fetchGetChromakeyStatus'), (_, { getState }) => {
  return new Promise<boolean>(async (resolve, reject) => {
    const state = getState();

    const token = state.auth.token;
    if (token === null) {
      reject(new Error('not authorization'));
      return;
    }

    const uuid = state.editor.present.data.uuid;
    if (uuid === undefined) {
      reject(new Error('project uuid is null'));
      return;
    }

    try {
      const resp = await fetchGetProjectChromakey({
        token,
        uuid,
      });

      if (resp.downloadUrl !== '') {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (error) {
      reject(error);
    }
  });
});

export const setTimelineRulerWidth = createAction<number>(
  editorActionTypeCreator('setRulerWidth')
);

export const setTimelineIndicatorPosition = createAction<number>(
  editorActionTypeCreator('setTimelineIndicatorPosition')
);

export const setTimelineMaxTime = createAction<number>(
  editorActionTypeCreator('setTimelineMaxTime')
);

export const setTimelineCurrentTime = createAction<number>(
  editorActionTypeCreator('setTimelineCurrentTime')
);

export const setTimelineMultiplierIndex = createAction<number>(
  editorActionTypeCreator('setTimelineMultiplierIndex')
);

export const setTimelineDuplicateToast = createAction<boolean>(
  editorActionTypeCreator('setTimelineDuplicateToast')
);

export const setTimelineOverflowToast = createAction<boolean>(
  editorActionTypeCreator('setTimelineOverflowToast')
);

export const setPreviewVideoErrorToast = createAction<boolean>(
  editorActionTypeCreator('setPreviewVideoErrorToast')
);

export const modifyTimelineEditableCell = createAction<Partial<EditableCell>>(
  editorActionTypeCreator('modifyTimelineEditableCell')
);

interface SetInitializeTimelineCellsPayload {
  pageKey: string;
  cells: CellEntity[];
}
export const setInitializeTimelineCells =
  createAction<SetInitializeTimelineCellsPayload>(
    editorActionTypeCreator('setInitializeTimelineCells')
  );

export const deleteVideoAsset = createAction<{ video: VideoAsset }>(
  editorActionTypeCreator('deleteVideoAsset')
);
