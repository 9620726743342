export const SLIDE_DEFAULT_DURATION = 2500;
export const SLIDE_MIN_DURATION = 1000;
export const SLIDE_MAX_DURATION = 5000;

export const VOICE_DEFAULT_SPEED = 1;
export const VOICE_MIN_SPEED = 0.5;
export const VOICE_MAX_SPEED = 1.5;

export const VOICE_DEFAULT_VOLUME = 7;
export const VOICE_MIN_VOLUME = 1;
export const VOICE_MAX_VOLUME = 23;

export const AUDIO_ITEM_DEFAULT_VOLUME = 7;
export const AUDIO_ITEM_MIN_VOLUME = 1;
export const AUDIO_ITEM_MAX_VOLUME = 23;

export const PREVIEW_ORIGIN_WIDTH = 1920;
export const PREVIEW_ORIGIN_HEIGHT = 1080;
