import { createSlice, createSelector } from '@reduxjs/toolkit';
import AuthService from 'app/services/auth';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

const EXPIRE = 12 * 60 * 60 * 1000; // 12 hours;
const EXPIRE_TIME = EXPIRE - (12 - 11.5) * 60 * 60 * 1000; // 11.5 hours;

interface AuthErrors {
  refresh: boolean;
}

export interface AuthState {
  token: string | null;
  loginTime: number | null;
  expiredTime: number | null;
  errors: AuthErrors;
}

type AuthSliceName = 'auth';
const name: AuthSliceName = 'auth';

const initialState: AuthState = {
  token: null,
  loginTime: null,
  expiredTime: null,
  errors: {
    refresh: false,
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setCredentials(
      state,
      { payload }: PayloadAction<{ token: string; loginTime: number }>
    ) {
      state.token = payload.token;
      state.loginTime = payload.loginTime;
      state.expiredTime = payload.loginTime + EXPIRE_TIME;
    },
    clearCredentials(state) {
      state.token = null;
      state.loginTime = null;
      state.expiredTime = null;
    },
    setErrors(state, { payload }: PayloadAction<Partial<AuthErrors>>) {
      state.errors = {
        ...state.errors,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      AuthService.endpoints.refresh.matchRejected,
      (state, { payload }) => {
        if (payload?.status === 400) {
          state.errors.refresh = true;
        }
      }
    );
  },
});

export const { setCredentials, clearCredentials, setErrors } = slice.actions;

export default slice.reducer;

export const selectLoginInfo = createSelector(
  (state: RootState) => state.auth,
  (auth) => {
    let ret: {
      token: string | null;
      expiredTime: number | null;
    } = {
      token: null,
      expiredTime: null,
    };

    if (auth.errors.refresh) {
      return ret;
    }

    ret.token = auth.token;
    ret.expiredTime = auth.expiredTime;
    return ret;
  }
);

export const selectRefreshError = createSelector(
  (state: RootState) => state.auth,
  (auth) => auth.errors.refresh
);
