import * as React from 'react';
import { useFloating, offset } from '@floating-ui/react-dom';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import {
  selectCurrentCellKey,
  selectTimelineRulerGap,
  selectTimelineRulerPerSecWidth,
  selectTimelineTimeGap,
} from 'features/editor/editorSlice';
import { DotsSixIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { formattedTime } from './utils';
import TimelineCellModelName from './TimelineCellModelName';
import TimelineCellTooltip from './TimelineCellTooltip';
import TimelineCellOptions from './TimelineCellOptions';

const getStyle = (
  baseWidth: number,
  audioDuration: number,
  duration: number
): React.CSSProperties => {
  const millisWidth = baseWidth / 1000;

  return {
    width: millisWidth * audioDuration,
    marginRight: millisWidth * duration,
  };
};

interface TimelineCellProps {
  cellKey: string;
  text: string;
  name: string;
  audioDuration: number;
  duration: number;
  startTime: number;
  index: number;
  onClick: (key: string) => void;
}

const TimelineCell: React.FC<TimelineCellProps> = ({
  cellKey,
  text,
  name,
  audioDuration,
  duration,
  startTime,
  index,
  onClick,
}) => {
  const currentCellKey = useAppSelector(selectCurrentCellKey);
  const isSelected = currentCellKey === cellKey;

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onClick(cellKey);
  };

  const { x, y, reference, floating, strategy, update } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    middleware: [offset(4)],
  });

  const [isHover, setIsHover] = React.useState<boolean>(false);

  const perSecWidth = useAppSelector(selectTimelineRulerPerSecWidth);

  const { setNodeRef, attributes, listeners, transform, isDragging } =
    useDraggable({ id: cellKey, disabled: index === 0 });

  React.useEffect(() => {
    if (isDragging) return;
    update();
  }, [isDragging, startTime, update]);

  const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> = () => {
    if (isDragging) return;
    setIsHover(true);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
    if (isDragging) return;
    setIsHover(false);
  };

  const rulerGap = useAppSelector(selectTimelineRulerGap);
  const timeGap = useAppSelector(selectTimelineTimeGap);

  const getDraggingTime = (deltaX?: number): string => {
    let deltaTime = ((deltaX ?? 0) / rulerGap) * timeGap;
    deltaTime = Math.floor(Math.ceil(deltaTime) / 100) * 100;

    return formattedTime(startTime + deltaTime);
  };

  const [cellBoxEl, setCellBoxEl] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    if (cellBoxEl === null) return;
    setNodeRef(cellBoxEl);
    reference(cellBoxEl);
  }, [cellBoxEl, setNodeRef, reference]);

  if (index === 0) {
    return (
      <Spacer
        ref={setNodeRef}
        style={getStyle(perSecWidth, audioDuration, duration)}
        {...attributes}
      />
    );
  }

  return (
    <>
      <CellBox
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={setCellBoxEl}
        style={{
          transform: CSS.Transform.toString(transform),
          zIndex: isDragging ? 1 : undefined,
          ...getStyle(perSecWidth, audioDuration, duration),
        }}
        {...attributes}
      >
        <CellContents isSelected={isSelected}>
          <TimelineCellModelName name={name} />
          <CellText>{text}</CellText>
        </CellContents>
        {isSelected && !isDragging && (
          <TimelineCellOptions
            cellKey={cellKey}
            length={audioDuration + duration}
          />
        )}
        <DragButton size={20} {...listeners}>
          <DotsSixIcon size={20} color={'currentColor'} weight={'bold'} />
        </DragButton>
        {isDragging && (
          <DraggingTimestamp>{getDraggingTime(transform?.x)}</DraggingTimestamp>
        )}
      </CellBox>
      {isHover && !isDragging && (
        <TimelineCellTooltip
          name={name}
          text={text}
          style={{
            position: strategy,
            top: y ?? '',
            left: x ?? '',
          }}
          ref={floating}
        />
      )}
    </>
  );
};

export default TimelineCell;

const Spacer = styled.div`
  width: 0;
  height: 32px;
`;

const CellBox = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 1px;
  user-select: none;
  position: relative;
`;

const CellContents = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 100%;
  background: var(--color-black);
  padding: 6px 4px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isSelected }) =>
    isSelected ? 'var(--color-pink-500)' : 'var(--color-black)'};
  overflow: hidden;
`;

const CellText = styled.span`
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-white);
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
`;

const DraggingTimestamp = styled.span`
  background: var(--color-black);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 14px;
  padding: 4px;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-white);
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
`;

const DragButton = styled(IconButton)`
  width: 100%;
  border: 1px solid var(--color-grey-200);
  border-radius: 0 0 4px 4px;
  cursor: grab;
`;
