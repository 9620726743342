import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from 'react-click-away-listener';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { DownloadSimpleIcon } from 'assets/svg';
import { IconButton, Toast } from 'components/common';
import {
  selectProjectIsExist,
  selectModified,
  selectProjectUseAnySTV,
  selectNetworkState,
  selectPagesContent,
} from 'features/editor/editorSlice';
import BottomSheet from './BottomSheet';
import VideoHQ from './DownloadButton.VideoHQ';
import AudioWAV from './DownloadButton.AudioWAV';
import AudioZIP from './DownloadButton.AudioZIP';
import SubtitleTXT from './DownloadButton.SubtitleTXT';
import SubtitleSRT from './DownloadButton.SubtitleSRT';
import Chromakey from './DownloadButton.Chromakey';

export interface DownloadButtonProps {
  children?: React.ReactNode;
}

const DownloadButton: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'downloadButton' });

  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  const handleClickDownload: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target instanceof HTMLButtonElement) {
      toggleOpen();
    }
  };

  const projectIsExist = useAppSelector(selectProjectIsExist);
  const projectModified = useAppSelector(selectModified);

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const toastMessage = React.useRef<string>('');

  const networkState = useAppSelector(selectNetworkState);

  const pagesContent = useAppSelector(selectPagesContent);
  let cannotMerge = pagesContent.reduce<boolean>((acc, item) => {
    if (acc) return true;
    if (item.cells.length === 0 && item.duration < 500) return true;
    if (item.cells.length > 0) {
      const noText = item.cells.filter((el) => el.text !== '').length === 0;
      if (noText) return true;
    }

    return acc;
  }, false);

  const handleClick = () => {
    if (networkState === 'save') return;

    if (cannotMerge) {
      toastMessage.current = t('toast.cannotMerge');
      toggleOpenToast();
      return;
    }

    if (!projectIsExist || projectModified) {
      toastMessage.current = t('toast.modifiedProject');
      toggleOpenToast();
      return;
    }

    toggleOpen();
  };

  const projectUseSTV = useAppSelector(selectProjectUseAnySTV);

  const { isDownloadAudioZIP } = useGetUserInfoQuery(undefined, {
    selectFromResult: ({ data }) => {
      const ret = {
        isDownloadAudioZIP: true,
      };

      if (['Free', 'Free_beta'].includes(data?.billingName ?? '')) {
        ret.isDownloadAudioZIP = false;
        return ret;
      }

      ret.isDownloadAudioZIP = true;
      return ret;
    },
  });

  return (
    <>
      <IconButton size={32} radius={6} onClick={handleClick}>
        <DownloadSimpleIcon
          size={32}
          weight={'fill'}
          color={'var(--color-grey-700)'}
        />
      </IconButton>
      {open && (
        <ClickAwayListener onClickAway={toggleOpen}>
          <BottomSheet>
            <DownloadBox onClick={handleClickDownload}>
              <DownloadTitle>{t('text.default')}</DownloadTitle>
              <DownloadList>
                <VideoHQ>{t('text.video')}</VideoHQ>
                <AudioWAV>{t('text.audioWAV')}</AudioWAV>
                {isDownloadAudioZIP && (
                  <AudioZIP>{t('text.audioZIP')}</AudioZIP>
                )}
                <SubtitleTXT>{t('text.subtitleTXT')}</SubtitleTXT>
                <SubtitleSRT>{t('text.subtitleSRT')}</SubtitleSRT>
                {projectUseSTV && <Chromakey>{t('text.chromakey')}</Chromakey>}
              </DownloadList>
            </DownloadBox>
          </BottomSheet>
        </ClickAwayListener>
      )}
      <Toast
        in={openToast}
        onClose={toggleOpenToast}
        message={toastMessage.current}
        severity={'error'}
        timeout={1500}
      />
    </>
  );
};

export default DownloadButton;

const DownloadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DownloadTitle = styled.span`
  height: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-grey-700);
  margin-right: auto;
`;

const DownloadList = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  overflow-y: auto;
  width: 100%;
  max-height: calc(50vh - 64px);
  padding: 0 8px;
`;
