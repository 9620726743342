import * as React from 'react';
import styled from '@emotion/styled';
import { ListIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { Navigation } from 'components/client';
import GoBackButton from './GoBackButton';
import EditHistory from './EditHistory';

const EditorHeader: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <HeaderMobile>
        <GoBackButton />
        <EditHistory />
        <IconButton size={32} radius={6} onClick={toggleOpen}>
          <ListIcon size={32} weight={'bold'} color={'var(--color-black)'} />
        </IconButton>
      </HeaderMobile>
      {open && <Navigation />}
    </>
  );
};

export default EditorHeader;

const HeaderMobile = styled.header`
  width: 100%;
  height: 64px;
  padding: 12px 20px;
  background: var(--color-white);
  border-bottom: 1px solid var(--color-grey-100);
  display: flex;
  align-items: center;
  position: relative;
  z-index: var(--header-index);
`;
