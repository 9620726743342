import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import {
  selectTimelineCurrentTime,
  selectTimelineMaxTime,
} from 'features/editor/editorSlice';
import { formattedTime } from './utils';

const Timestamp: React.FC = () => {
  const currentTime = useAppSelector(selectTimelineCurrentTime);
  const maxTime = useAppSelector(selectTimelineMaxTime);

  return (
    <Base>
      <CurrentTime>{formattedTime(currentTime)}</CurrentTime>
      <Divider />
      <MaxTime>{formattedTime(maxTime)}</MaxTime>
    </Base>
  );
};

export default Timestamp;

const Base = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 1.4;

  @media (min-width: 1024px) {
    margin-left: auto;
  }
`;

const CurrentTime = styled.span`
  color: var(--color-grey-700);
`;

const Divider = styled.hr`
  border: 0;
  padding: 0;
  margin: 0 4px;
  background: var(--color-grey-200);
  width: 1px;
  height: 12px;
`;

const MaxTime = styled.span`
  color: var(--color-grey-200);
`;
