import * as React from 'react';

interface PrivateWrapperProps {
  children: React.ReactNode;
  fallback?: React.ReactElement;
}

const DEV_PrivateWrapper: React.FC<PrivateWrapperProps> = ({
  children,
  fallback,
}) => {
  if (process.env.ONAIR_INTERNAL === 'Y') {
    return React.isValidElement(children) ? React.cloneElement(children) : null;
  }

  return fallback ?? null;
};

export default DEV_PrivateWrapper;
