import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { kmp } from 'lib/utils';
import { useGetVideoModelQuery } from 'app/services/videoModel';
import { sort, videoModelCompare } from 'features/editor/utils';
import ModelSelectItem from './ModelSelectItem';
import type { VideoModel } from 'app/services/videoModel';

const getSearchedModels = (
  models: VideoModel[] | undefined,
  searchWord: string
) => {
  if (models === undefined) return [];
  if (searchWord === '') return models;

  const searchedModels = models.filter((model) => {
    if (kmp(model.displayName.toUpperCase(), searchWord.toUpperCase()) > -1)
      return true;
    return false;
  });

  return searchedModels;
};

interface ModelSelectProps {
  searchModelName: string;
  selectedModelName?: string;
  onSelectModel: (newName: string) => void;
}

const ModelSelect: React.FC<ModelSelectProps> = ({
  searchModelName,
  selectedModelName,
  onSelectModel,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'videoModelSelect' });

  const { models } = useGetVideoModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      models: getSearchedModels(data, searchModelName),
    }),
  });

  return (
    <Base isFull={selectedModelName === undefined}>
      <Title>{t('title.step1')}</Title>
      <List isFull={selectedModelName === undefined}>
        {sort(models, videoModelCompare)?.map((model) => (
          <ModelSelectItem
            profile={model.profileThumb}
            displayName={model.displayName}
            audioModelName={model.audioMlModelName}
            isSelected={selectedModelName === model.name}
            onSelect={onSelectModel.bind(null, model.name)}
            language={model.language}
            key={model.key}
          />
        ))}
      </List>
    </Base>
  );
};

export default ModelSelect;

const Base = styled.div<{ isFull: boolean }>`
  width: 100%;
  height: ${({ isFull }) => (isFull ? 'calc(100% - 114px)' : '340px')};
`;

const Title = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  margin: 16px 0;
  padding-left: 16px;
`;

const List = styled.div<{ isFull: boolean }>`
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 4fr));
  grid-template-rows: repeat(auto-fit, 132px);
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  list-style: none;
  overflow-y: auto;
  height: 100%;
  ${({ isFull }) => (isFull ? 'max-height: 100%;' : 'height: 280px;')}
`;
