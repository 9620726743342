import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { CheckCircleIcon } from 'assets/svg';
import { typography } from 'styles';

const successStyle = {
  '--check-color': 'var(--color-success)',
} as React.CSSProperties;

const dangerStyle = {
  '--check-color': 'var(--color-danger)',
} as React.CSSProperties;

const validateAlphabet = (value: string): React.CSSProperties => {
  const regExp = /[a-zA-Z]/g;

  if (value === '') return {};
  if (regExp.test(value)) return successStyle;
  return dangerStyle;
};

const validateNumeric = (value: string): React.CSSProperties => {
  const regExp = /[0-9]/g;

  if (value === '') return {};
  if (regExp.test(value)) return successStyle;
  return dangerStyle;
};

const validateSpecialSymbol = (value: string): React.CSSProperties => {
  const regExp = /[!@#$%&*]/g;

  if (value === '') return {};
  if (regExp.test(value)) return successStyle;
  return dangerStyle;
};

const validateLength = (value: string): React.CSSProperties => {
  if (value === '') return {};
  if (value.length >= 8 && value.length <= 20) return successStyle;
  return dangerStyle;
};

export interface ValidateCheckProps {
  value: string;
}

const ValidateCheck: React.FC<ValidateCheckProps> = ({ value }) => {
  const { t } = useTranslation('auth', { keyPrefix: 'validate' });

  return (
    <Base>
      <CheckItem style={validateAlphabet(value)}>
        <CheckCircleIcon size={16} color={'currentColor'} weight={'fill'} />
        <span>{t('alphabet')}</span>
      </CheckItem>
      <CheckItem style={validateNumeric(value)}>
        <CheckCircleIcon size={16} color={'currentColor'} weight={'fill'} />
        <span>{t('num')}</span>
      </CheckItem>
      <CheckItem style={validateSpecialSymbol(value)}>
        <CheckCircleIcon size={16} color={'currentColor'} weight={'fill'} />
        <span>{t('special')}</span>
      </CheckItem>
      <CheckItem style={validateLength(value)}>
        <CheckCircleIcon size={16} color={'currentColor'} weight={'fill'} />
        <span>{t('limit')}</span>
      </CheckItem>
    </Base>
  );
};

export default ValidateCheck;

const Base = styled.div`
  width: calc(100% - 32px);
  min-height: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 520px) {
    width: 392px;
  }
`;

const CheckItem = styled.div`
  --check-color: var(--color-grey-500);

  margin-right: 8px;
  ${typography.label.large}
  color: var(--check-color);
  display: flex;
  align-items: center;

  span {
    margin-left: 4px;
  }
`;
