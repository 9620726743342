import * as React from 'react';
import { useSearchParams, Routes, Route, Navigate } from 'react-router-dom';
import { ClientTemplate } from 'components/client';
import {
  Request,
  Success,
  Failure,
  ChangeSuccess,
  ChangeFailure,
} from 'components/payment';
import { isGradeType } from 'components/membership/utils';

const Payments: React.FC = () => {
  const [searchParams] = useSearchParams();

  const pramGrade = searchParams.get('grade');
  const grade = isGradeType(pramGrade) ? pramGrade : undefined;

  if (grade === undefined)
    return <Navigate to={'/client/membership'} replace />;

  return (
    <ClientTemplate>
      <Routes>
        <Route index element={<Request grade={grade} />} />
        <Route path={'new/success'} element={<Success grade={grade} />} />
        <Route path={'new/failure'} element={<Failure grade={grade} />} />
        <Route
          path={'change/success'}
          element={<ChangeSuccess grade={grade} />}
        />
        <Route
          path={'change/failure'}
          element={<ChangeFailure grade={grade} />}
        />
        <Route
          path={'*'}
          element={<Navigate to={'/client/membership'} replace />}
        />
      </Routes>
    </ClientTemplate>
  );
};

export default Payments;
