import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { PlusIcon, PencilSimpleIcon } from 'assets/svg';

interface EditableCellButtonProps {
  style: React.CSSProperties;
  isCellSelected: boolean;
  onSubmit: () => void;
}

const EditableCellButton = React.forwardRef<
  HTMLButtonElement,
  EditableCellButtonProps
>(({ style, isCellSelected, onSubmit }, ref) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  return (
    <Base ref={ref} style={style} onClick={onSubmit}>
      {isCellSelected ? (
        <>
          <PencilSimpleIcon size={24} color={'currentColor'} weight={'bold'} />
          <Text>{t('text.editText')}</Text>
        </>
      ) : (
        <>
          <PlusIcon size={24} color={'currentColor'} weight={'bold'} />
          <Text>{t('text.insertTimeline')}</Text>
        </>
      )}
    </Base>
  );
});

export default EditableCellButton;

const Base = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  background: var(--color-white);
  border: 1px solid var(--color-grey-100);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  padding: 8px;
  display: flex;
  z-index: 4;
  align-items: center;
  justify-content: center;

  color: var(--color-grey-700);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;

const Text = styled.span`
  margin-left: 4px;
  font-size: 16px;
  line-height: 1.4;
`;
