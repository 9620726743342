import * as React from 'react';
import styled from '@emotion/styled';

interface TabsProps {
  items: string[];
  selectedIndex: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseOver?: React.MouseEventHandler<HTMLButtonElement>;
}

const Tabs: React.FC<TabsProps> = ({
  items,
  selectedIndex,
  onClick,
  onMouseOver,
}) => {
  return (
    <Base>
      <TabList>
        {items.map((item, idx) => (
          <Tab
            onClick={onClick}
            onMouseOver={onMouseOver}
            isSelected={idx === selectedIndex}
            data-idx={idx}
            key={item}
          >
            {item}
          </Tab>
        ))}
        <TabIndicator idx={selectedIndex} count={items.length} />
      </TabList>
    </Base>
  );
};

export default Tabs;

const Base = styled.div`
  position: relative;
  width: 100%;
`;

const TabList = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

const Tab = styled.button<{ isSelected: boolean }>`
  color: var(--color-grey-500);
  background: var(--color-white);

  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  white-space: nowrap;

  ${({ isSelected }) =>
    isSelected &&
    `
	  color: var(--color-black);
  	font-weight: 700;
	`}

  width: 100%;
  height: 48px;
  transition: font-weight 225ms, border-color 100ms;
  outline: none;
  border: none;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid var(--color-grey-500);

    @media (hover: none) {
      border-bottom: 2px solid transparent;
    }
  }
`;

const TabIndicator = styled.span<{ idx: number; count: number }>`
  position: absolute;
  width: ${({ count }) => `calc(100% / ${count})`};
  height: 2px;
  background: var(--color-black);
  transition: left 225ms linear;
  bottom: 0px;
  left: ${({ idx, count }) =>
    idx === 0 ? '0px' : `calc(100% * ${idx / count})`};
`;
