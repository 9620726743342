import * as React from 'react';
import styled from '@emotion/styled';
import { typography } from 'styles';

const OutlineButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (
  props
) => {
  return <Base {...props} />;
};

export default OutlineButton;

const Base = styled.button`
  width: 100%;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  ${typography.title.medium}
  border-radius: 10px;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  background: var(--color-white);

  &:hover {
    background: rgba(51, 51, 51, 0.08);

    @media (hover: none) {
      background: rgba(51, 51, 51, 0.16);
    }
  }

  &:active {
    background: rgba(51, 51, 51, 0.16);
  }
`;
