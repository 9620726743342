import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import { useFloating, offset } from '@floating-ui/react-dom';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { DotsThreeOutlineVerticalIcon } from 'assets/svg';
import {
  removeTimelineCell,
  selectCellText,
  selectCellModelName,
  selectCellVolume,
  selectCellSpeed,
  fetchMergeText,
} from 'features/editor/editorSlice';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
} from 'features/editor/providers';
import AudioManager from 'lib/AudioManager';
import { download } from 'lib/utils';

interface TimelineCellOptionsProps {
  cellKey: string;
  length: number;
}

const TimelineCellOptions: React.FC<TimelineCellOptionsProps> = ({
  cellKey,
  length,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'timeline' });

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    middleware: [offset(4)],
  });

  const dispatch = useAppDispatch();

  const text = useAppSelector(selectCellText(cellKey));
  const name = useAppSelector(selectCellModelName(cellKey));
  const volume = useAppSelector(selectCellVolume(cellKey));
  const speed = useAppSelector(selectCellSpeed(cellKey));

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
    },
  });

  const prevKey = React.useRef<string>('');

  const handleClickPlay = async () => {
    if (text === undefined) return;
    if (text.trim().length === 0) return;
    if (name === undefined) return;

    const el = ref.current;
    if (el === null) return;

    const key = `${name}-${text}-${volume}-${speed}`;

    if (prevKey.current === key) {
      if (state.paused) {
        editorAudioPlay(controls);
      } else {
        editorAudioPause();
      }
      return;
    }

    try {
      const blob = await dispatch(
        fetchMergeText({ text, name, volume, speed })
      ).unwrap();

      prevKey.current = key;
      const url = AudioManager.createObjectUrl(blob);
      el.src = url;
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickDownload = async () => {
    if (text === undefined) return;
    if (text.trim().length === 0) return;
    if (name === undefined) return;

    try {
      const blob = await dispatch(
        fetchMergeText({ text, name, volume, speed })
      ).unwrap();

      const url = AudioManager.createObjectUrl(blob);
      download(url, `${text}.wav`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickDelete = () => {
    dispatch(
      removeTimelineCell({
        key: cellKey,
        length,
      })
    );
  };

  return (
    <Menu as={React.Fragment}>
      <Menu.Button as={MenuButton} ref={reference}>
        <DotsThreeOutlineVerticalIcon color={'currentColor'} weight={'fill'} />
      </Menu.Button>
      {element}
      <Menu.Items
        as={MenuItems}
        ref={floating}
        onPointerDown={(e) => e.stopPropagation()}
        style={{
          position: strategy,
          top: y ?? '',
          left: x ?? '',
        }}
      >
        <Menu.Item as={MenuItem} onClick={handleClickPlay}>
          {t('cell.play')}
        </Menu.Item>
        <Menu.Item as={MenuItem} onClick={handleClickDownload}>
          {t('cell.download')}
        </Menu.Item>
        <Menu.Item as={MenuItem} onClick={handleClickDelete}>
          {t('cell.delete')}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default TimelineCellOptions;

const MenuButton = styled.button`
  background: var(--color-black);
  border: none;
  outline: none;
  border-radius: 6px;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  top: -26px;
  right: 0;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MenuItems = styled.div`
  width: 88px;
  padding: 8px 0;
  background: var(--color-white);
  border: 1px solid var(--color-grey-100);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  z-index: 1;
  cursor: pointer;
`;

const MenuItem = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-black);

  &:last-of-type {
    color: var(--color-danger);
  }

  &:hover {
    background: linear-gradient(rgba(51, 51, 51, 0.08), rgba(51, 51, 51, 0.08)),
      var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }
`;
