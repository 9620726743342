import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { Dialog, Listbox } from '@headlessui/react';
import {
  ListIcon,
  XIcon,
  ActivityIcon,
  MicrophoneIcon,
  MonitorPlayIcon,
  UserCircleIcon,
  CaretDownIcon,
} from 'assets/svg';
import { Toast } from 'components/common';
import { DEV_PRIVATEWRAPPER } from 'lib/devUtils';
import { LOCALE_CODES, codeToLang } from 'locales/utils';
import { typography } from 'styles';
import HeaderLogo from './HeaderLogo';

const TTS_URL = 'https://feature.onairstudio.ai/tts';
const VIDEO_URL = 'https://feature.onairstudio.ai/video';
const STV_URL = 'https://feature.onairstudio.ai/human';

const anchorElementGuard = (el: HTMLElement): el is HTMLAnchorElement => {
  if (el.tagName === 'A') return true;
  return false;
};

const Header: React.FC = () => {
  const { t, i18n } = useTranslation('main', { keyPrefix: 'header' });

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  const handleClickReviewAnchor: React.MouseEventHandler<HTMLAnchorElement> = (
    e
  ) => {
    e.preventDefault();
    toggleIsOpen();
    const targetEl = e.target as HTMLElement;
    if (!anchorElementGuard(targetEl)) return;

    const element = document.getElementById(targetEl.hash.slice(1));
    if (element === null) return;

    window.scrollTo({
      top: element.offsetTop - 64,
      behavior: 'smooth',
    });
  };

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  return (
    <Box>
      <HeaderLogo />
      <Button onClick={toggleIsOpen}>
        <ListIcon size={32} weight={'bold'} color={'currentColor'} />
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => {}}
        className={css`
          position: fixed;
          z-index: var(--popup-index);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `}
      >
        <DialogBase>
          <DialogTitle>
            <HeaderLogo />
            <DEV_PRIVATEWRAPPER>
              <Listbox
                as={ListboxBase}
                value={i18n.language}
                onChange={i18n.changeLanguage}
              >
                {({ open }) => (
                  <>
                    <Listbox.Button as={ListboxButton} isOpen={open}>
                      <span>{codeToLang(i18n.language)}</span>
                      <CaretDownIcon
                        size={16}
                        weight={'fill'}
                        color={'currentColor'}
                      />
                    </Listbox.Button>
                    <Listbox.Options as={ListboxOptions}>
                      {LOCALE_CODES.map((code) => (
                        <Listbox.Option
                          as={React.Fragment}
                          value={code}
                          key={code}
                        >
                          {({ selected }) => (
                            <ListboxOption isSelected={selected}>
                              {codeToLang(code)}
                            </ListboxOption>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                )}
              </Listbox>
            </DEV_PRIVATEWRAPPER>
            <Button onClick={toggleIsOpen}>
              <XIcon size={32} type={'black'} color={'currentColor'} />
            </Button>
          </DialogTitle>
          <div>
            <div>
              <Item>{t('product.name')}</Item>
              <SubAnchor href={TTS_URL} target={'_blank'} rel={'noreferrer'}>
                <MicrophoneIcon
                  size={20}
                  color={'currentColor'}
                  weight={'fill'}
                />
                <span>{t('product.items.tts')}</span>
              </SubAnchor>
              <SubAnchor href={VIDEO_URL} target={'_blank'} rel={'noreferrer'}>
                <MonitorPlayIcon
                  size={20}
                  color={'currentColor'}
                  weight={'fill'}
                />
                <span>{t('product.items.video')}</span>
              </SubAnchor>
              <SubAnchor href={STV_URL} target={'_blank'} rel={'noreferrer'}>
                <UserCircleIcon
                  size={20}
                  color={'currentColor'}
                  weight={'fill'}
                />
                <span>{t('product.items.stv')}</span>
              </SubAnchor>
              <Anchor href={'#usage'} onClick={handleClickReviewAnchor}>
                {t('usage')}
              </Anchor>
              <Anchor
                href={'https://lionrocket.ai'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                {t('company')}
              </Anchor>
            </div>
            <DialogDivider />
            <AuthAnchorBox>
              <LoginAnchor to={'/auth/login'}>
                {t('login')}
              </LoginAnchor>
              <RegisterAnchor to={'/auth/register'}>
                <ActivityIcon
                  size={24}
                  color={'currentColor'}
                  weight={'bold'}
                />
                <span>{t('register')}</span>
              </RegisterAnchor>
              <Toast
                in={openToast}
                onClose={toggleOpenToast}
                timeout={1500}
                message={'Coming soon'}
                severity={'normal'}
              />
            </AuthAnchorBox>
          </div>
        </DialogBase>
      </Dialog>
    </Box>
  );
};

export default Header;

const Box = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);

  @media (min-width: 1152px) {
    width: 1120px;
  }
`;

const Button = styled.button`
  border: none;
  padding: 0;
  background: none;
  display: inline-flex;
  color: var(--color-black);
`;

const DialogBase = styled.div`
  background: var(--color-white);
  width: 100%;
  height: 100%;
`;

const DialogTitle = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const Anchor = styled.a`
  display: block;
  text-decoration: none;
  color: var(--color-black);
  ${typography.title.medium}
  padding: 12px 16px;
`;

const SubAnchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: var(--color-black);
  ${typography.body.medium}
  padding: 12px 24px;

  svg + span {
    margin-left: 10px;
  }
`;

const Item = Anchor.withComponent('div');

const DialogDivider = styled.hr`
  margin-block-start: 24px;
  margin-block-end: 16px;
  margin-inline-start: 16px;
  margin-inline-end: 16px;
  border-style: solid;
  border-color: var(--color-grey-100);
`;

const AuthAnchorBox = styled.div`
  a + a {
    margin-top: 8px;
  }
`;

const LoginAnchor = styled(Link)`
  --hover-gradient: linear-gradient(
    0deg,
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    0deg,
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  text-decoration: none;
  background: var(--color-white);
  box-shadow: 0 4px 16px rgba(51, 51, 51, 0.16);
  border: 1px solid var(--color-grey-100);
  border-radius: 10px;
  color: var(--color-black);
  ${typography.title.medium}
  height: 48px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;

const RegisterAnchor = styled(Link)`
  --hover-gradient: linear-gradient(
    rgba(255, 255, 255, 0.16),
    rgba(255, 255, 255, 0.16)
  );
  --active-gradient: linear-gradient(
    rgba(255, 255, 255, 0.32),
    rgba(255, 255, 255, 0.32)
  );

  text-decoration: none;
  border-radius: 10px;
  background: var(--color-gradient);
  box-shadow: 0px 12px 24px rgba(172, 0, 0, 0.2);
  color: var(--color-white);
  ${typography.title.medium}
  height: 48px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--hover-gradient), var(--color-gradient);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-gradient);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-gradient);
  }

  span {
    margin-left: 8px;
  }
`;

const ListboxBase = styled.div`
  cursor: pointer;
  outline: 0;
  border: none;
  margin-right: 13px;
  width: 88px;
`;

const ListboxButton = styled.button<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  border: 0;
  padding: 0;
  margin: 0 auto;
  color: ${(props) =>
    props.isOpen ? 'var(--color-pink)' : 'var(--color-black)'};
  background: transparent;
  user-select: none;
  cursor: pointer;
  span {
    ${typography.body.medium}
  }
  svg {
    margin-top: 2px;
    margin-left: 2px;
    transform: ${(props) => props.isOpen && 'rotate(180deg)'};
  }
`;

const ListboxOptions = styled.ul`
  position: absolute;
  z-index: 2;
  margin: 0;
  padding: 8px 0;
  list-style: none;
  border: 1px solid var(--color-grey-100);
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  background: var(--color-white);
  outline: 0;
  cursor: pointer;
`;

const ListboxOption = styled.li<{ isSelected: boolean }>`
  --hover-gradient: linear-gradient(
    0deg,
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    0deg,
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  padding: 6px 21.5px;
  ${(props) =>
    props.isSelected ? typography.label.large : typography.body.medium}
  color: var(--color-black);
  background: var(--color-white);
  user-select: none;
  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
