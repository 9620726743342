import * as React from 'react';
import styled from '@emotion/styled';
import { formattedTime } from 'lib/utils';

interface VideoTimeProps {
  url?: string;
}

const VideoTime: React.FC<VideoTimeProps> = ({ url }) => {
  const [duration, setDuration] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (url === undefined) {
      setDuration(null);
      return;
    }

    const videoEl = document.createElement('video');
    videoEl.addEventListener(
      'loadedmetadata',
      () => {
        setDuration(videoEl.duration);
      },
      { once: true }
    );
    videoEl.src = url;
  }, [url]);

  if (duration === null) return null;
  return <Timestamp>{formattedTime(duration)}</Timestamp>;
};

export default VideoTime;

const Timestamp = styled.span`
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(51, 51, 51, 0.8);
  border-radius: 6px;
  font-size: 12px;
  line-height: 1.4;
  color: var(--color-white);
  position: absolute;
  bottom: 28px;
  left: 75%;
  transform: translateX(-50%);
`;
