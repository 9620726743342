import * as React from 'react';
import { useId } from 'lib/hooks';

const LogoOverlayIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const id = useId();
  const gradientId = `logo-overlay-gradient-${id}`;

  return (
    <svg
      width={64}
      height={64}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g opacity={0.08} fillRule='evenodd' clipRule='evenodd'>
        <path
          d='M20.311 19.773c-6.924 0-12.538 5.614-12.538 12.538 0 6.925 5.614 12.538 12.538 12.538 2.29 0 4.426-.61 6.267-1.675l3.139 5.426a18.735 18.735 0 01-9.406 2.518c-10.386 0-18.806-8.42-18.806-18.807 0-10.386 8.42-18.806 18.806-18.806 10.387 0 18.807 8.42 18.807 18.806h-6.269c0-6.924-5.613-12.538-12.538-12.538z'
          fill={`url(#${gradientId})`}
        />
        <path
          d='M42.593 15.244l18.913 35.874h-7.05L42.592 26.785 30.74 51.106h-7.063l18.915-35.862z'
          fill={`url(#${gradientId})`}
        />
        <path
          d='M27.25 44.335l-.672-1.161a12.472 12.472 0 01-6.267 1.675c-6.924 0-12.538-5.613-12.538-12.538 0-6.924 5.614-12.538 12.538-12.538 6.375 0 11.64 4.759 12.434 10.917.069.53.104 1.072.104 1.621h.742L27.25 44.335zm-5.239 6.707a19 19 0 01-1.7.076c-10.386 0-18.806-8.42-18.806-18.807 0-10.386 8.42-18.806 18.806-18.806 7.054 0 13.201 3.883 16.42 9.629.3.538.576 1.092.825 1.66l5.037-9.55 18.913 35.874h-7.05L42.592 26.785 30.74 51.106h-7.063l.17-.32a15.759 15.759 0 01-.585.101c-.413.066-.83.117-1.252.155zm-.824 1.562c-.29.012-.582.019-.876.019C9.094 52.623 0 43.529 0 32.31 0 21.094 9.094 12 20.311 12c7.275 0 13.656 3.824 17.243 9.572l5.04-9.553L64 52.623H53.515L42.593 30.219 31.681 52.611H21.184l.003-.007zm10.157-19.258V32.31c0-6.093-4.94-11.033-11.033-11.033-6.093 0-11.033 4.94-11.033 11.033 0 6.094 4.94 11.033 11.033 11.033a10.96 10.96 0 005.514-1.473l1.302-.753.062.107 4.155-7.88z'
          fill={`url(#${gradientId})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientId}
          x1={2.2}
          y1={17.501}
          x2={65.329}
          y2={25.811}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF376E' />
          <stop offset={1} stopColor='#6587FF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoOverlayIcon;
