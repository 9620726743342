import * as React from 'react';
import styled from '@emotion/styled';
import {
  Header,
  CarouselSection,
  IntroSection,
  PartnersSection,
  StepSection,
  FeatureSection,
  UsageSection,
  ArticleSection,
  LastSection,
} from 'components/main';
import { InfoFooter } from 'components/common';

const MainPage: React.FC = () => {
  return (
    <>
      <Header />
      <Main>
        <CarouselSection />
        <IntroSection />
        <PartnersSection />
        <StepSection />
        <FeatureSection />
        <UsageSection />
        <ArticleSection />
        <LastSection />
      </Main>
      <InfoFooter />
    </>
  );
};

export default MainPage;

const Main = styled.main`
  width: 100%;
`;
