import * as React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import BackgroundImg from 'assets/img/auth-background.png';
import Container from './Container';

interface LoginTemplateProps {
  children?: React.ReactNode;
}

const LoginTemplate: React.FC<LoginTemplateProps> = ({ children }) => {
  return (
    <>
      <Global styles={globalStyles} />
      <Main>
        <Container>{children}</Container>
      </Main>
    </>
  );
};

export default LoginTemplate;

const globalStyles = css`
  html,
  body {
    width: 100%;
    height: 100%;
  }

  #root {
    width: 100%;
    height: 100%;

    background-size: cover;
    background-image: url(${BackgroundImg});
  }
`;

const Main = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
