import { keyframes } from '@emotion/react';

export const growIn = keyframes`
  from {
		transform: scale(0.5);
		opacity: 0;
	}

	to {
		transform: scale(1);
		opacity: 1;
	}
`;

export const growOut = keyframes`
  from {
		transform: scale(1);
		opacity: 1;
	}

	to {
		transform: scale(0.5);
		opacity: 0;
	}
`;
