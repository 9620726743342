import { createApi } from '@reduxjs/toolkit/query/react';
import { recordTransformer, camelCase } from 'lib/utils';
import queryFetcher from './queryFetcher';
import type { ObjectToCamel } from 'lib/utils';

interface RawPaymentInfo {
  subscribe: boolean;
  active: boolean;
  status: number;
  card_number: string;
  card_company: string;
  next_billing_redeem_date: string;
  billing_amount: string;
  payment_status: number;
  billing_name: string;
}

export type PaymentInfo = ObjectToCamel<RawPaymentInfo>;

interface RawPaymentLog {
  approved_at: string;
  duration_date: string;
  order_info: string;
  card_number: string;
  card_company: string;
  notax_amount: number;
  total_amount: number;
  payment_key: string;
  cancel_status: number;
}

export type PaymentLog = ObjectToCamel<RawPaymentLog>;

interface RawGetPaymentLogResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

interface RequestPaymentPayload {
  customerKey: string;
  authKey: string;
  billingType: string;
}

interface RequestChangePayload {
  customerKey: string;
  authKey: string;
  billingType: string;
}

interface RequestUnsubscribePayload {
  unsubscribeReason: string;
}

export const api = createApi({
  reducerPath: 'services/membership',
  baseQuery: queryFetcher('/v1/billing'),
  tagTypes: ['Membership'],
  endpoints: (builder) => ({
    getPaymentInfo: builder.query<PaymentInfo, void>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      providesTags: [{ type: 'Membership', id: 'PaymentInfo' }],
      transformResponse: (resp: RawPaymentInfo) => {
        const data = recordTransformer(resp, camelCase) as PaymentInfo;
        return data;
      },
    }),
    getPaymentLog: builder.query<PaymentLog[], number | void>({
      query: (pageNum = 1) => ({
        url: `/log?page=${pageNum}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'Membership', id: 'PaymentLog' }],
      transformResponse: (resp: RawGetPaymentLogResponse<RawPaymentLog>) => {
        const data = recordTransformer(
          resp,
          camelCase
        ) as RawGetPaymentLogResponse<PaymentLog>;

        return data.results;
      },
    }),
    requestPayment: builder.mutation<void, RequestPaymentPayload>({
      query: ({ customerKey, authKey, billingType }) => ({
        url: '',
        method: 'POST',
        body: {
          customer_key: customerKey,
          auth_key: authKey,
          billing_type: billingType,
        },
      }),
      invalidatesTags: [{ type: 'Membership' }],
    }),
    requestChange: builder.mutation<void, RequestChangePayload>({
      query: ({ customerKey, authKey, billingType }) => ({
        url: '',
        method: 'PUT',
        body: {
          customer_key: customerKey,
          auth_key: authKey,
          billing_type: billingType,
        },
      }),
      invalidatesTags: [{ type: 'Membership' }],
    }),
    requestUnsubscribe: builder.mutation<void, RequestUnsubscribePayload>({
      query: ({ unsubscribeReason }) => ({
        url: '/unsubscribe',
        method: 'POST',
        body: {
          unsubscribe_reason: unsubscribeReason,
        },
      }),
      invalidatesTags: [{ type: 'Membership' }],
    }),
  }),
});

export const {
  useGetPaymentInfoQuery,
  useGetPaymentLogQuery,
  useRequestPaymentMutation,
  useRequestChangeMutation,
  useRequestUnsubscribeMutation,
} = api;

export default api;
