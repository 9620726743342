import { createApi } from '@reduxjs/toolkit/query/react';
import { recordTransformer, camelCase } from 'lib/utils';
import queryFetcher from './queryFetcher';
import type { ObjectToCamel } from 'lib/utils';

type ProjectType =
  // prev project type
  | 'audio_only'
  | 'video'
  | 'video_editor'
  // new project type
  | 'all'
  | 'desktop'
  | 'mobile'
  | 'template';

interface RawProjectMetadata {
  project_uuid: string;
  project_name: string;
  create_date: string;
  update_date: string;
  predicted_loading_date: string;
  loading_start_date: string;
  loading_status: number;
  rendering_status: number;
  project_thumbnail?: string;
  type: ProjectType;
  // UPDATED response for mixpanel
  audio_duration?: number;
  bg_music?: string;
  bg_music_volume?: 0;
  inter_page_sound?: string;
  inter_page_sound_volume?: number;
  intro_music?: string;
  intro_music_volume?: number;
  page_set?: string[];
  subtitle_font_bg_color?: string;
  subtitle_font_bg_opacity?: number;
  subtitle_font_color?: string;
  subtitle_font_size?: number;
  use_subtitle?: boolean;
  user?: string;
  video_duration?: number;
  video_hash?: string;
  video_s3_path?: { path: string; url: string };
  watermark_location?: string;
}

interface RawGetProjectsResponse {
  project_metadata: RawProjectMetadata[];
}

interface GetProjectResponse {
  projectMetadata: ProjectMetadata[];
}

type ModifyProjectNameResponse = RawProjectMetadata;

export type ProjectMetadata = ObjectToCamel<RawProjectMetadata>;

export const api = createApi({
  reducerPath: 'services/projects',
  baseQuery: queryFetcher('/v1/video-maker/projects'),
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectMetadata[], void>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      transformResponse: (resp: RawGetProjectsResponse) => {
        const data = recordTransformer(resp, camelCase) as GetProjectResponse;

        return data.projectMetadata;
      },
    }),
    deleteProject: builder.mutation<void, string>({
      query: (uuid) => ({
        url: `/${uuid}`,
        method: 'DELETE',
      }),
      async onQueryStarted(uuid, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getProjects', undefined, (draft) => {
            const patch = draft.filter((el) => el.projectUuid !== uuid);
            draft.splice(patch.length);
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    modifyProjectName: builder.mutation<
      ModifyProjectNameResponse,
      { uuid: string; name: string }
    >({
      query: ({ uuid, name }) => ({
        url: `/${uuid}`,
        method: 'PUT',
        body: { project_name: name },
      }),
      async onQueryStarted({ uuid, name }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getProjects', undefined, (draft) => {
            const patch = draft.reduce<ProjectMetadata[]>((acc, item) => {
              if (item.projectUuid !== uuid) {
                acc.push(item);
                return acc;
              }

              acc.push({
                ...item,
                projectName: name,
              });
              return acc;
            }, []);
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useDeleteProjectMutation,
  useModifyProjectNameMutation,
} = api;

export default api;
