import { createApi } from '@reduxjs/toolkit/query/react';
import { nanoid } from '@reduxjs/toolkit';
import { camelCase, recordTransformer } from 'lib/utils';
import queryFetcher from './queryFetcher';

interface RawVoiceModel {
  name: string;
  display_name: string;
  tags: string[];
  predicted_time: number;
  default_color: string;
  model_type: string;
  is_best: boolean;
  is_new: boolean;
  create_date: string;
}

interface RawGetVoiceModelResponse {
  models: RawVoiceModel[];
}

export interface VoiceModel {
  key: string;
  name: string;
  displayName: string;
  tags: string[];
  predictedTime: number;
  defaultColor: string;
  modelType: string;
  isBest: boolean;
  isNew: boolean;
  language: string;
  createDate: string;
}

interface GetVoiceModelResponse {
  models: VoiceModel[];
}

export const api = createApi({
  reducerPath: 'services/voiceModel',
  baseQuery: queryFetcher('/v1/video-maker'),
  endpoints: (builder) => ({
    getVoiceModel: builder.query<VoiceModel[], void>({
      query: () => ({
        url: '/ml-models',
        method: 'GET',
      }),
      transformResponse: (resp: RawGetVoiceModelResponse) => {
        const data = recordTransformer(
          resp,
          camelCase
        ) as GetVoiceModelResponse;

        return data.models.map((model) => ({ ...model, key: nanoid() }));
      },
    }),
  }),
});

export const { useGetVoiceModelQuery } = api;

export default api;
