import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { KakaoTalkAuthButton, GoogleAuthButton } from 'features/auth';
import { typography } from 'styles';

const TERMS_OF_USER =
  'https://onairstudio.zendesk.com/hc/ko/sections/4411962940569-%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80';
const PRIVACY_POLICY =
  'https://onairstudio.zendesk.com/hc/ko/sections/4411971433625-%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4-%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8';

const RegisterSection: React.FC = () => {
  const { t, i18n } = useTranslation('auth', { keyPrefix: 'register' });

  const navigate = useNavigate();

  const handleClickRegisterByEmail: React.MouseEventHandler<
    HTMLButtonElement
  > = (e) => {
    e.stopPropagation();

    navigate('/auth/register/verification');
  };

  return (
    <Content>
      <Title data-lang={i18n.language}>{t('title')}</Title>
      <AuthBox>
        <ButtonBox>
          <KakaoTalkAuthButton>{t('button.kakao')}</KakaoTalkAuthButton>
        </ButtonBox>
        <ButtonBox>
          <GoogleAuthButton>{t('button.google')}</GoogleAuthButton>
        </ButtonBox>
        <RegisterByEmailButton onClick={handleClickRegisterByEmail}>
          {t('button.onair')}
        </RegisterByEmailButton>
      </AuthBox>
      <Divider />
      <Agreement>
        <Trans
          t={t}
          i18nKey={'agreement.social'}
          components={[
            <Anchor href={TERMS_OF_USER} />,
            <Anchor href={PRIVACY_POLICY} />,
          ]}
        />
      </Agreement>
    </Content>
  );
};

export default RegisterSection;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.span`
  width: calc(100% - 32px);
  display: inline-block;
  margin: 0 16px;
  text-align: center;
  color: var(--color-black);
  word-break: keep-all;
  ${typography.display.small}

  &[data-lang='kr'] {
    white-space: pre;
  }
`;

const AuthBox = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonBox = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 16px;
`;

const RegisterByEmailButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  width: calc(100% - 32px);
  height: 48px;
  border: none;
  border-radius: 10px;
  outline: none;
  background: var(--color-white);
  ${typography.body.large}
  color: var(--color-black);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }

  @media (min-width: 520px) {
    width: 392px;
  }
`;

const Divider = styled.hr`
  width: calc(100% - 32px);
  height: 1px;
  background: #e7e7e7;
  border: none;
  margin-block: 24px;

  @media (min-width: 520px) {
    width: 392px;
  }
`;

const Agreement = styled.span`
  width: 100%;
  display: inline-block;
  ${typography.body.small}
  color: var(--color-grey-500);
  margin: 0;
  white-space: pre;
  text-align: center;
`;

const Anchor = styled.a`
  text-decoration: underline;
  color: var(--color-black);
`;
