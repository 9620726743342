import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useGetPaymentLogQuery } from 'app/services/membership';
import { typography } from 'styles';
import NoPaymentLog from './NoPaymentLog';
import {
  formattedPrice,
  formattedCardNumber,
  formattedDurationDate,
} from './utils';

const PaymentLog: React.FC = () => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.paymentLog',
  });

  const { data: logs } = useGetPaymentLogQuery();

  if (logs === undefined) return <NoPaymentLog />;
  if (logs.length === 0) return <NoPaymentLog />;

  return (
    <Base>
      <LogBox>
        <TitleGrid>
          <PaymentDate>
            <span>{t('paymentDate')}</span>
          </PaymentDate>
          <OrderInfo>
            <span>{t('orderInfo')}</span>
          </OrderInfo>
          <PeriodOfUse>
            <span>{t('periodOfUse')}</span>
          </PeriodOfUse>
          <PaymentMethod>
            <span>{t('paymentMethod')}</span>
          </PaymentMethod>
          <NotaxAmount>
            <span>{t('notaxAmount')}</span>
            <span>{t('excludeVAT')}</span>
          </NotaxAmount>
          <TaxAmount>
            <span>{t('taxAmount')}</span>
          </TaxAmount>
          <TotalAmount>
            <span>{t('totalAmount')}</span>
          </TotalAmount>
        </TitleGrid>
        <TitleDivider />
        {[...logs].map((log, index) => {
          const {
            approvedAt,
            orderInfo,
            durationDate,
            cardNumber,
            cardCompany,
            notaxAmount,
            totalAmount,
          } = log;

          const paymentDate = new Date(approvedAt).toLocaleString();
          const periodOfUse = formattedDurationDate({ date: durationDate });
          const paymentMethod = `${formattedCardNumber({
            number: cardNumber,
          })} / ${cardCompany}`;
          const notaxAmountText = t('price', {
            price: formattedPrice({ price: notaxAmount }),
          });
          const taxAmountText = t('price', {
            price: formattedPrice({ price: totalAmount - notaxAmount }),
          });
          const totalAmountText = t('price', {
            price: formattedPrice({ price: totalAmount }),
          });

          return (
            <CellGrid key={`${approvedAt}-${index}`}>
              <PaymentDate>
                <span>{paymentDate}</span>
              </PaymentDate>
              <OrderInfo>
                <span>{orderInfo}</span>
              </OrderInfo>
              <PeriodOfUse>
                <span>{periodOfUse}</span>
              </PeriodOfUse>
              <PaymentMethod>
                <span>{paymentMethod}</span>
              </PaymentMethod>
              <NotaxAmount>
                <span>{notaxAmountText}</span>
              </NotaxAmount>
              <TaxAmount>
                <span>{taxAmountText}</span>
              </TaxAmount>
              <TotalAmount>
                <span>{totalAmountText}</span>
              </TotalAmount>
              <GridDivider />
            </CellGrid>
          );
        })}
      </LogBox>
    </Base>
  );
};

export default PaymentLog;

const Base = styled.div`
  margin-top: 16px;
`;

const LogBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-areas:
    'paymentDate paymentDate orderInfo orderInfo orderInfo totalAmount totalAmount'
    'divider divider divider divider divider divider divider'
    'periodOfUse periodOfUse paymentMethod paymentMethod paymentMethod notaxAmount taxAmount ';

  span {
    ${typography.title.medium}
    color: var(--color-black);
  }

  @media (min-width: 520px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: 'paymentDate paymentDate orderInfo orderInfo orderInfo periodOfUse periodOfUse paymentMethod paymentMethod notaxAmount taxAmount totalAmount';
  }
`;

const CellGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-areas:
    'paymentDate paymentDate orderInfo orderInfo orderInfo totalAmount totalAmount'
    'divider divider divider divider divider divider divider'
    'periodOfUse periodOfUse paymentMethod paymentMethod paymentMethod notaxAmount taxAmount ';

  span {
    ${typography.body.medium}
    color: var(--color-black);
  }

  &:nth-of-type(2n) {
    background: var(--color-grey-100);
  }

  @media (min-width: 520px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: 'paymentDate paymentDate orderInfo orderInfo orderInfo periodOfUse periodOfUse paymentMethod paymentMethod notaxAmount taxAmount totalAmount';
  }
`;

const cellStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  margin: 0 4px;
`;

const PaymentDate = styled.div`
  ${cellStyle}
  grid-area: paymentDate;
`;

const OrderInfo = styled.div`
  ${cellStyle}
  grid-area: orderInfo;
`;

const PeriodOfUse = styled.div`
  ${cellStyle}
  grid-area: periodOfUse;
`;

const PaymentMethod = styled.div`
  ${cellStyle}
  grid-area: paymentMethod;
`;

const NotaxAmount = styled.div`
  ${cellStyle}
  grid-area: notaxAmount;

  span:nth-of-type(2) {
    font-size: 10px;
    display: none;
  }
  @media (min-width: 520px) {
    span:nth-of-type(2) {
      display: inline-flex;
    }
  }
`;

const TaxAmount = styled.div`
  ${cellStyle}
  grid-area: taxAmount;
`;

const TotalAmount = styled.div`
  ${cellStyle}
  grid-area: totalAmount;
`;

const GridDivider = styled.div`
  grid-area: divider;
  height: 1px;
  background: var(--color-grey-200);
`;

const TitleDivider = styled.div`
  height: 2px;
  background: var(--color-black);
`;
