import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useMatchMedia } from 'lib/hooks';
import { LogoIcon, HunetLogoIcon, ListIcon, XIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { SignOut } from 'features/auth';
import NavigationMobile from './Navigation.Mobile';

const Header: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'common' });

  const match = useMatchMedia('(min-width: 1024px)');

  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  return (
    <Base>
      <Box>
        <LogoAnchor to={'/'}>
          {process.env.ONAIR_PLATFORM_TYPE === 'hunet' && <HunetLogoIcon />}
          <LogoIcon type={'black'} />
        </LogoAnchor>
        {match ? (
          <SignOut>{t('signout')}</SignOut>
        ) : (
          <IconButton size={36} radius={6} onClick={toggleOpen}>
            {open ? (
              <XIcon size={32} weight={'bold'} color={'var(--color-black)'} />
            ) : (
              <ListIcon
                size={32}
                weight={'bold'}
                color={'var(--color-black)'}
              />
            )}
          </IconButton>
        )}
      </Box>
      {!match && open && <NavigationMobile />}
    </Base>
  );
};

export default Header;

const Base = styled.header`
  width: 100%;
  height: 64px;
  background: var(--color-white);
  position: fixed;
  top: 0;
  z-index: var(--header-index);

  @media (min-width: 1024px) {
    border-bottom: 1px solid var(--color-grey-100);
    position: static;
  }
`;

const Box = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
`;

const LogoAnchor = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 28px;

  svg + svg {
    margin-left: 8px;
  }
`;
