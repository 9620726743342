import * as React from 'react';
import { useId } from 'lib/hooks';

const GradientAddIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const id = useId();
  const gradientId = `add-icon-gradient-${id}`;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={32}
      height={32}
      viewBox='0 0 32 32'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.5 16A1.5 1.5 0 015 14.5h22a1.5 1.5 0 010 3H5A1.5 1.5 0 013.5 16z'
        fill={`url(#${gradientId})`}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 3.5A1.5 1.5 0 0117.5 5v22a1.5 1.5 0 01-3 0V5A1.5 1.5 0 0116 3.5z'
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1={4.359}
          y1={6.885}
          x2={29.273}
          y2={8.967}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF376E' />
          <stop offset={1} stopColor='#6587FF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientAddIcon;
