import * as React from 'react';
import { SlidersIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { ChangeToneModal } from 'features/editor/Modals';

interface CellChangeToneButtonProps {
  pageKey: string;
  cellKey: string;
}

const CellChangeToneButton: React.FC<CellChangeToneButtonProps> = ({
  pageKey,
  cellKey,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const toggleOpen = () => setOpen((prev) => !prev);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton size={20} radius={4} onClick={toggleOpen}>
        <SlidersIcon size={20} weight={'bold'} />
      </IconButton>
      {open && (
        <ChangeToneModal
          open={open}
          onClose={handleClose}
          modifyType={'cell'}
          pageKey={pageKey}
          cellKey={cellKey}
        />
      )}
    </>
  );
};

export default CellChangeToneButton;
