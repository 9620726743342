import * as React from 'react';
import styled from '@emotion/styled';
import { useGetVideosQuery } from 'app/services/videoAssets';
import NewVideoListItem from './NewVideoListItem';
import VideoListItem from './VideoListItem';

const VideoList: React.FC = () => {
  const { data } = useGetVideosQuery();

  return (
    <List>
      <NewVideoListItem />
      {data &&
        [...data].map(({ name }, index) => (
          <VideoListItem videoName={name} key={name} index={index} />
        ))}
    </List>
  );
};

export default VideoList;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  height: 100%;
  overflow-y: auto;
  align-content: start;
`;
