import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useGetProjectsQuery } from 'app/services/projects';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { typography } from 'styles';

const TitleBox: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'projectList' });

  const { size } = useGetProjectsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      size: data?.length,
    }),
  });

  const { max } = useGetUserInfoQuery(undefined, {
    selectFromResult: ({ data }) => ({
      max: data?.projectLimitNum,
    }),
  });

  const count =
    size === undefined || max === undefined ? undefined : `${size} / ${max}`;

  return (
    <Base>
      <Title>{t('title')}</Title>
      {count !== undefined && <CountText>{count}</CountText>}
    </Base>
  );
};

export default TitleBox;

const Base = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  @media (min-width: 520px) {
    width: 472px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 12px;
  }
`;

const Title = styled.span`
  ${typography.title.large}
`;

const CountText = styled.div`
  display: flex;
  justify-content: center;
  ${typography.body.large}
`;
