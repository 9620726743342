import * as React from 'react';
import { Global, css } from '@emotion/react';
import {
  EditorHeader,
  EditorMain,
  EditorFooter,
  AuthErrorAutosave,
  ProjectLoader,
} from 'features/editor';

const EditorPage: React.FC = () => {
  return (
    <ProjectLoader>
      <Global
        styles={css`
          html,
          body {
            height: 100%;
          }

          body {
            overflow: hidden;
            padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0)
              env(safe-area-inset-bottom, 0) env(safe-area-inset-left, 0);
            display: flex;
          }

          #root {
            width: 100vw;
            height: 100%;
          }

          @media (min-width: 1024px) {
            body {
              overflow: auto;
            }
          }
        `}
      />
      <EditorHeader />
      <EditorMain />
      <EditorFooter />
      <AuthErrorAutosave />
    </ProjectLoader>
  );
};

export default EditorPage;
