import * as React from 'react';
import constate from 'constate';
import { VideoDownloadModal } from 'features/editor/Modals';
import { usePusherChannelId } from 'features/editor/providers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import userInfoService from 'app/services/userInfo';
import PusherService from 'lib/PusherService';
import {
  fetchVideo,
  selectProjectName,
  selectProjectUuid,
  setVideoHash,
  setOpenMergeLimitToast,
} from 'features/editor/editorSlice';
import { fetchUrlToBlob } from 'features/editor/networks';
import type { ChildrenProps } from './types';

type Status = -1 | 0 | 1 | 2 | 3 | 4 | 5;

type Quaility = 'HQ' | 'LQ';

function useVideoDownload() {
  const [open, setOpen] = React.useState<boolean>(false);

  const [statusCode, setStatusCode] = React.useState<Status>(0);

  React.useEffect(() => {
    if (open) return;
    if (statusCode !== 5) return;

    setStatusCode(5);
  }, [open, statusCode]);

  const setInitialStatusCode = React.useCallback((rs: number) => {
    if (rs > 0 && rs < 5) setOpen(true);
    setStatusCode(rs as Status);
  }, []);

  const [downloadParams, setDownloadParams] = React.useState<{
    name: string;
    data: Blob;
  } | null>(null);
  const fileName = useAppSelector(selectProjectName);
  const projectUuid = useAppSelector(selectProjectUuid);

  const getDownloadParams = async (url: string, name: string) => {
    try {
      const blob = await fetchUrlToBlob(url);

      setDownloadParams({
        name: name || 'untitled',
        data: blob,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const channelId = usePusherChannelId();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (channelId === undefined) return;
    if (projectUuid === undefined) return;

    const channel = PusherService.subscribeChannel(channelId);

    const listener = async (data: {
      url: string;
      status_code: Status;
      video_hash?: string | null;
      type: 'project_video';
    }) => {
      if (data.type !== 'project_video') return;

      if (data.status_code === 5) {
        await getDownloadParams(data.url, `${fileName || 'untitled'}.mp4`);
        dispatch(userInfoService.util.invalidateTags([{ type: 'UserInfo' }]));
      }

      if (data.video_hash !== undefined && data.video_hash !== null) {
        dispatch(setVideoHash(data.video_hash));
      }

      setStatusCode(data.status_code);
    };

    channel.bind(projectUuid, listener);

    return () => {
      channel.unbind(projectUuid, listener);
    };
  }, [channelId, projectUuid, fileName, dispatch]);

  const prevQuality = React.useRef<Quaility | null>(null);

  React.useEffect(() => {
    if (open) return;
    prevQuality.current = null;
  }, [open]);

  const mergeLock = React.useRef<boolean>(false);

  const lockedMergeRequest = async (
    eventId: string,
    channelId: string,
    lqMode: boolean
  ) => {
    if (mergeLock.current) return;
    mergeLock.current = true;
    const data = await dispatch(
      fetchVideo({
        eventId,
        channelId,
        lqMode,
      })
    ).unwrap();
    mergeLock.current = false;
    return data;
  };

  const openModal = async (quality: Quaility) => {
    if (channelId === undefined) return;
    if (projectUuid === undefined) return;

    prevQuality.current = quality;

    const isLqMode = quality === 'LQ';

    try {
      const data = await lockedMergeRequest(projectUuid, channelId, isLqMode);
      if (data === undefined) return;
      setOpen(true);

      if (data.downloadUrl !== '' && data.status === 5) {
        setStatusCode(5);

        await getDownloadParams(
          data.downloadUrl,
          `${fileName || 'untitled'}.mp4`
        );
      }

      if (data.status > 0) {
        setStatusCode(data.status);
      }
    } catch (error) {
      if ((error as Error).message === 'merge limit') {
        dispatch(setOpenMergeLimitToast(true));
        return;
      }

      console.error(error);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const retry = () => {
    if (prevQuality.current === null) return;
    openModal(prevQuality.current);
  };

  return {
    open,
    statusCode: statusCode / 5,
    downloadParams: downloadParams,
    openModal,
    closeModal,
    retry,
    setInitialStatusCode,
  };
}

const [
  Provider,
  useVideoDownloadModalState,
  useVideoDownloadProgress,
  useVideoDownloadParams,
  useVideoDownloadModalOpen,
  useVideoDownloadModalClose,
  useVideoDownloadRetry,
  useVideoDownloadSetProgress,
] = constate(
  useVideoDownload,
  (state) => state.open,
  (state) => state.statusCode,
  (state) => state.downloadParams,
  (state) => state.openModal,
  (state) => state.closeModal,
  (state) => state.retry,
  (state) => state.setInitialStatusCode
);

const Modal: React.FC<ChildrenProps> = ({ children }) => {
  const open = useVideoDownloadModalState();

  return (
    <>
      {open && <VideoDownloadModal />}
      {children}
    </>
  );
};

export const VideoDownloadModalProvider = ({ children }: ChildrenProps) => (
  <Provider>
    <Modal>{children}</Modal>
  </Provider>
);

export {
  useVideoDownloadModalState,
  useVideoDownloadProgress,
  useVideoDownloadParams,
  useVideoDownloadModalOpen,
  useVideoDownloadModalClose,
  useVideoDownloadRetry,
  useVideoDownloadSetProgress,
};
