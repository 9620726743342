const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';
const KOREAN = [
  '에이',
  '비',
  '씨',
  '디',
  '이',
  '에프',
  '쥐',
  '에이치',
  '아이',
  '제이',
  '케이',
  '엘',
  '엠',
  '엔',
  '오',
  '피',
  '큐',
  '알',
  '에스',
  '티',
  '유',
  '브이',
  '더블유',
  '엑스',
  '와이',
  '지',
];

export default function alphabetToKorean(engStr: string): string {
  const strArr = engStr.split('');

  let ret: string = '';

  for (let i = 0; i < strArr.length; i++) {
    const temp = strArr[i];
    const tempIndex = ALPHABET.indexOf(temp);

    if (tempIndex === -1) continue;

    const replace = KOREAN[tempIndex];

    ret += replace;
  }

  return ret;
}
