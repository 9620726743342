export interface AudioManagerConstructorOptions {
  context?: AudioContext;
  sampleRate?: number;
}

export default class AudioManager {
  private readonly ctx: AudioContext;
  private readonly sampleRate: number;

  /**
   * @param param AudioManagerConstructorOptions
   */
  constructor({
    context,
    sampleRate = 44100,
  }: AudioManagerConstructorOptions = {}) {
    this.ctx = context ? context : this.createContext(sampleRate);
    this.sampleRate = sampleRate;
  }

  /**
   * @returns boolean
   */
  static isSupportAudioContext(): boolean {
    return 'AudioContext' in window || 'webkitAudioContext' in window;
  }

  /**
   * @returns string
   */
  static createObjectUrl(blob: Blob): string {
    const objUrl = (window.URL || window.webkitURL).createObjectURL(blob);
    return objUrl;
  }

  /**
   * @returns void
   */
  static revokeObjectUrl(url: string): void {
    (window.URL || window.webkitURL).revokeObjectURL(url);
  }

  /**
   * @returns void
   */
  close() {
    this.ctx.close();
  }

  /**
   * @param data Blob | ArrayBuffer
   * @returns Promise<AudioBuffer>
   */
  async toAudioBuffer(data: Blob): Promise<AudioBuffer>;
  async toAudioBuffer(data: ArrayBuffer): Promise<AudioBuffer>;
  async toAudioBuffer(data: Blob | ArrayBuffer): Promise<AudioBuffer> {
    let ret: AudioBuffer;

    if (data instanceof Blob) {
      const buffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          if (fileReader.result instanceof ArrayBuffer) {
            resolve(fileReader.result as ArrayBuffer);
          } else {
            reject(new Error('FileReader result is not ArrayBuffer!'));
          }
        };

        fileReader.readAsArrayBuffer(data);
      });
      ret = await this.decodeAudioBuffer(buffer);
      return ret;
    }

    if (data instanceof ArrayBuffer) {
      ret = await this.decodeAudioBuffer(data);
      return ret;
    }

    throw new Error('parameters must be Blob or ArrayBuffer');
  }

  /**
   * @param buffers AudioBuffer[]
   * @returns AudioBuffer
   */
  mergeAudio(buffers: AudioBuffer[]): AudioBuffer {
    const outputBuffer = this.ctx.createBuffer(
      this.maxNumberOfChannels(buffers),
      this.sampleRate * this.maxDuration(buffers),
      this.sampleRate
    );

    buffers.forEach((buffer) => {
      for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
        const retData = outputBuffer.getChannelData(channel);
        const bufferData = outputBuffer.getChannelData(channel);

        for (let i = 0; i < bufferData.length; i++) {
          retData[i] += bufferData[i];
        }

        outputBuffer.getChannelData(channel).set(retData);
      }
    });

    return outputBuffer;
  }

  /**
   * @param buffers AudioBuffer[]
   * @returns AudioBuffer
   */
  concatAudio(buffers: AudioBuffer[]): AudioBuffer {
    const outputBuffer = this.ctx.createBuffer(
      this.maxNumberOfChannels(buffers),
      this.totalLength(buffers),
      this.sampleRate
    );

    let offset: number = 0;
    buffers.forEach((buffer) => {
      for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
        const outputData = outputBuffer.getChannelData(channel);
        outputData.set(buffer.getChannelData(channel), offset);

        offset += buffer.length;
      }
    });

    return outputBuffer;
  }

  /**
   * @param buffer AudioBuffer
   * @returns AudioBufferSourceNode
   */
  getSource(buffer: AudioBuffer): AudioBufferSourceNode {
    const source = this.ctx.createBufferSource();
    source.buffer = buffer;
    source.connect(this.ctx.destination);

    return source;
  }

  /**
   * @param nodes
   * @returns AudioBuffer
   */
  processNodes(
    nodes: { buffer?: AudioBuffer; padding: number }[]
  ): AudioBuffer {
    const { numChannels, duration } = nodes.reduce<{
      numChannels: number[];
      duration: number;
    }>(
      (acc, item) => {
        if (item.buffer) {
          acc.numChannels.push(item.buffer?.numberOfChannels);
          acc.duration += item.buffer.duration + item.padding / 1000;
          return acc;
        }

        acc.numChannels.push(1);
        acc.duration += item.padding / 1000;
        return acc;
      },
      { numChannels: [], duration: 0 }
    );

    const numChannel = Math.max(...numChannels);

    const outputBuffer = this.ctx.createBuffer(
      numChannel,
      Math.ceil(duration * this.sampleRate),
      this.sampleRate
    );

    for (let channel = 0; channel < numChannel; channel++) {
      const channelData = outputBuffer.getChannelData(0);

      nodes.reduce<number>((acc, item) => {
        if (item.buffer) {
          channelData.set(item.buffer.getChannelData(channel), acc);
          acc += Math.floor(
            (item.buffer.duration + item.padding / 1000) * this.sampleRate
          );
          return acc;
        }

        acc += Math.floor((item.padding / 1000) * this.sampleRate);
        return acc;
      }, 0);
    }

    return outputBuffer;
  }

  /**
   * @returns Promise<void>
   */
  suspendContext(): Promise<void> {
    return this.ctx.suspend();
  }

  /**
   * @returns Promise<void>
   */
  resumeContext(): Promise<void> {
    return this.ctx.resume();
  }

  /**
   * @returns AudioContextState
   */
  getContextState(): AudioContextState {
    return this.ctx.state;
  }

  /**
   * @returns AudioTimestamp
   */
  getTimestamp(): AudioTimestamp {
    return this.ctx.getOutputTimestamp();
  }

  /**
   * @param buffer Buffer to export
   * @param type MIME type (default: `audio/wav`)
   */
  export(buffer: AudioBuffer, type: string = 'audio/wav'): string {
    const float32Array = this.interleave(buffer);
    const dataView = this.writeHeaders(float32Array);
    const blob = new Blob([dataView], { type });

    return AudioManager.createObjectUrl(blob);
  }

  /**
   * @internal
   * @returns AudioContext
   */
  private createContext(sampleRate: number): AudioContext {
    window.AudioContext =
      window.AudioContext || (window as any).webkitAudioContext;
    return new AudioContext({ sampleRate });
  }

  /**
   * @internal
   * @return AudioBuffer
   */
  private async decodeAudioBuffer(data: ArrayBuffer): Promise<AudioBuffer> {
    const audioBuffer = await this.ctx.decodeAudioData(data);
    return audioBuffer;
  }

  /**
   * @internal
   * @params buffers AudioBuffer
   * @return number
   */
  private maxNumberOfChannels(buffers: AudioBuffer[]): number {
    return Math.max(...buffers.map((buffer) => buffer.numberOfChannels));
  }

  /**
   * @internal
   * @params buffers AudioBuffer
   * @return number
   */
  private maxDuration(buffers: AudioBuffer[]): number {
    return Math.max(...buffers.map((buffer) => buffer.duration));
  }

  /**
   * @internal
   * @params buffers AudioBuffer
   * @return number;
   */
  private totalLength(buffers: AudioBuffer[]): number {
    return buffers.reduce<number>((acc, item) => acc + item.length, 0);
  }

  /**
   * @internal
   */
  private interleave(input: AudioBuffer): Float32Array {
    const buffer = input.getChannelData(0);
    const length = buffer.length * 2;
    const ret = new Float32Array(length);

    let index = 0;
    let inputIndex = 0;

    while (index < length) {
      ret[index++] = buffer[inputIndex];
      ret[index++] = buffer[inputIndex];
      inputIndex++;
    }

    return ret;
  }

  /**
   * @internal
   */
  private writeString(
    dataView: DataView,
    offset: number,
    header: string
  ): void {
    for (let i = 0; i < header.length; i++) {
      dataView.setUint8(offset + i, header.charCodeAt(i));
    }
  }

  /**
   * @internal
   */
  private floatTo16BitPCM(
    dataView: DataView,
    buffer: Float32Array,
    offset: number
  ): DataView {
    for (let i = 0; i < buffer.length; i++, offset += 2) {
      const temp = Math.max(-1, Math.min(1, buffer[i]));
      dataView.setInt16(offset, temp < 0 ? temp * 0x8000 : temp * 0x7fff, true);
    }

    return dataView;
  }

  /**
   * @internal
   */
  private writeHeaders(buffer: Float32Array): DataView {
    const arrayBuffer = new ArrayBuffer(44 + buffer.length * 2);
    const view = new DataView(arrayBuffer);

    this.writeString(view, 0, 'RIFF');
    view.setUint32(4, 32 + buffer.length * 2, true);
    this.writeString(view, 8, 'WAVE');
    this.writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 2, true);
    view.setUint32(24, this.sampleRate, true);
    view.setUint32(28, this.sampleRate * 4, true);
    view.setUint16(32, 4, true);
    view.setUint16(34, 16, true);
    this.writeString(view, 36, 'data');
    view.setUint32(40, buffer.length * 2, true);

    return this.floatTo16BitPCM(view, buffer, 44);
  }
}
