import * as React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectTimelineCurrentTime,
  selectTimelineRulerPerSecWidth,
  selectTimelineIndicatorTransform,
  setTimelineIndicatorPosition,
} from 'features/editor/editorSlice';
import { formattedTime } from './utils';

const TimelineIndicator: React.FC = () => {
  const dispatch = useAppDispatch();

  const currentTime = useAppSelector(selectTimelineCurrentTime);
  const width = useAppSelector(selectTimelineRulerPerSecWidth);

  React.useEffect(() => {
    dispatch(setTimelineIndicatorPosition((width / 1000) * currentTime));
  }, [width, currentTime, dispatch]);

  const transform = useAppSelector(selectTimelineIndicatorTransform);

  return (
    <Base style={{ transform }}>
      <Timestamp>{formattedTime(currentTime < 0 ? 0 : currentTime)}</Timestamp>
      <Guideline />
    </Base>
  );
};

export default TimelineIndicator;

const Base = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1px;
  pointer-events: none;
`;

const Timestamp = styled.span`
  user-select: none;
  background: var(--color-pink-500);
  border-radius: 12px;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-white);
  padding: 4px;
`;

const Guideline = styled.hr`
  user-select: none;
  border: none;
  margin: 0;
  padding: 0;
  background: var(--color-pink-500);
  width: 1px;
  flex: 1;
`;
