import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { LogoIcon } from 'assets/svg';
import ErrorImgMobile from 'assets/img/404@mobile.png';
import ErrorImgDesktop from 'assets/img/404@desktop.png';

const ErrorPage: React.FC = () => {
  return (
    <>
      <Global styles={globalStyles} />
      <Base>
        <LogoIcon width={320} height={180} type={'white'} />
        <p>
          <span>
            더 나은 서비스를 제공하기 위해 시스템 점검을 진행하고 있습니다!
          </span>
          <span>아래 점검시간 이후 서비스 사용이 가능합니다.</span>
          <span>
            점검시간 : 2021년 12월 21일(화) 23:00 ~ 2021년 12월 22일(수) 03:00
            (약 4시간)
          </span>
        </p>
        <GoMainButton to={'/'} replace>
          메인으로 가기
        </GoMainButton>
      </Base>
    </>
  );
};

export default ErrorPage;

const globalStyles = css`
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }

  #root {
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(${ErrorImgMobile});
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: var(--color-black);
    background-size: cover;

    @media (min-width: 820px) {
      background-image: url(${ErrorImgDesktop});
      background-position: top;
    }
  }
`;

const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  p {
    width: calc(100% - 32px);
    max-width: 392px;
    margin: 0;
    padding: 0;
  }

  span {
    display: block;
    word-break: keep-all;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.4;
    color: var(--color-white);
  }

  span + span {
    margin-top: 4px;
  }
`;

const GoMainButton = styled(Link)`
  width: calc(100% - 32px);
  max-width: 392px;
  height: 48px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-grey-700);
`;
