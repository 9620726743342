import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useGetWatermarkQuery } from 'app/services/watermark';
import { selectWatermarkType } from 'features/editor/editorSlice';
import { LazyImage } from 'features/editor/common';

interface WatermarkImageProps {
  imageWidth: number;
}

const WatermarkImage: React.FC<WatermarkImageProps> = ({ imageWidth }) => {
  const watermarkType = useAppSelector(selectWatermarkType);

  const { image } = useGetWatermarkQuery(undefined, {
    selectFromResult: ({ data }) => {
      if (data === undefined) return { image: undefined };

      const image = data.find((el) => el.name === watermarkType);

      return {
        image: image ? image : data[data.length - 1],
      };
    },
  });

  const getImageSize = React.useCallback(() => {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
      if (image?.downloadUrl === undefined) {
        reject();
        return;
      }

      const imageEl = new Image();
      imageEl.addEventListener(
        'load',
        () => {
          resolve({
            width: imageEl.width,
            height: imageEl.height,
          });
        },
        { once: true }
      );
      imageEl.src = image.downloadUrl;
    });
  }, [image]);

  const calcImageSize = ({
    baseWidth,
    imageWidth,
    imageHeight,
  }: {
    baseWidth: number;
    imageWidth: number;
    imageHeight: number;
  }) => {
    const ratio = baseWidth / 1920;
    const watermarkWidth = imageWidth * ratio;
    const watermarkHeight = imageHeight * ratio;
    const position = 50 * ratio;

    return {
      width: watermarkWidth,
      height: watermarkHeight,
      position,
    };
  };

  const setStyle = React.useCallback(
    async (instance: HTMLImageElement) => {
      try {
        const imageSize = await getImageSize();
        const { width, height, position } = calcImageSize({
          baseWidth: imageWidth,
          imageWidth: imageSize.width,
          imageHeight: imageSize.height,
        });

        instance.width = width;
        instance.height = height;
        instance.style.cssText = `
          --top: ${position}px;
          --right: ${position}px;
        `;
      } catch (error) {
        if (error instanceof Error) {
          console.error(error);
        }
      }
    },
    [getImageSize, imageWidth]
  );

  const refCallback: React.RefCallback<HTMLImageElement> = (instance) => {
    if (instance === null) return;

    setStyle(instance);
  };

  if (image === undefined) return null;
  return (
    <Base src={image.downloadUrl} alt={image.displayName} ref={refCallback} />
  );
};

export default WatermarkImage;

const Base = styled(LazyImage)`
  position: absolute;
  top: var(--top);
  right: var(--right);
  opacity: 0.5;
  z-index: 2;
`;
