import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getHexLuminance } from 'lib/utils';
import {
  PlusIcon,
  PlayCircleIcon,
  StopCircleIcon,
  TailSpinIcon,
} from 'assets/svg';
import {
  fetchMergeMlModelText,
  selectCurrentPageKey,
  selectCurrentPageSTV,
  selectCurrentPagePreview,
  createNewCellByModel,
  modifyTimelineEditableCell,
  removePageStv,
} from 'features/editor/editorSlice';
import {
  useCommonModalOpen,
  useCommonModalClose,
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
  usePusherChannelId,
} from 'features/editor/providers';
import AudioManager from 'lib/AudioManager';
import { codeToMergeText } from 'locales/utils';
import type { VoiceModelItemProps } from './VoiceModelItem';

const VoiceModelItem: React.FC<VoiceModelItemProps> = ({
  name,
  modelColor,
  displayName,
  tags,
  isBest,
  isNew,
  language,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'voiceModelSelect' });

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const dispatch = useAppDispatch();

  const channelId = usePusherChannelId();

  const handleClickPlay: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.stopPropagation();

    const el = ref.current;
    if (el === null) return;
    if (channelId === undefined) return;

    if (el.readyState >= 2) {
      if (state.paused) {
        editorAudioPlay(controls);
      } else {
        editorAudioPause();
        controls.seek(0);
      }
      return;
    }

    const previewText = codeToMergeText(language);
    if (previewText === undefined) {
      console.error('unknown language code');
      return;
    }

    try {
      setMergeLoading(true);
      const blob = await dispatch(
        fetchMergeMlModelText({
          name,
          channelId,
          text: previewText,
        })
      ).unwrap();

      const url = AudioManager.createObjectUrl(blob);
      el.src = url;
    } catch (error) {
      console.error(error);
    }
  };

  const currentPageKey = useAppSelector(selectCurrentPageKey);
  const currentPageSTV = useAppSelector(selectCurrentPageSTV);
  const previewContent = useAppSelector(selectCurrentPagePreview);

  const openCommonModal = useCommonModalOpen();
  const closeCommonModal = useCommonModalClose();

  const createCell = () => {
    if (currentPageKey === undefined) return;

    if (currentPageSTV) {
      openCommonModal({
        content: t('stvRemove.body'),
        submitText: t('stvRemove.yes'),
        cancelText: t('stvRemove.no'),
        submit() {
          dispatch(
            createNewCellByModel({
              pageKey: currentPageKey,
              modelName: name,
            })
          );
          closeCommonModal();
        },
        cancel() {
          closeCommonModal();
        },
      });
      return;
    }

    dispatch(
      createNewCellByModel({
        pageKey: currentPageKey,
        modelName: name,
      })
    );
    return;
  };

  const checkRemovePageStv = () => {
    if (currentPageKey === undefined) return;
    if (currentPageSTV === undefined) return;
    const { audioModelName } = currentPageSTV;
    if (audioModelName !== name) {
      dispatch(
        removePageStv({
          pageKey: currentPageKey,
        })
      );
    }
    return;
  };

  const changeVoice = () => {
    if (currentPageSTV) {
      openCommonModal({
        content: t('stvRemove.body'),
        submitText: t('stvRemove.yes'),
        cancelText: t('stvRemove.no'),
        submit() {
          dispatch(
            modifyTimelineEditableCell({
              modelName: name,
            })
          );

          checkRemovePageStv();

          closeCommonModal();
        },
        cancel() {
          closeCommonModal();
        },
      });
      return;
    }

    dispatch(
      modifyTimelineEditableCell({
        modelName: name,
      })
    );
    return;
  };

  const handleClick = () => {
    if (previewContent === undefined) return;

    if (previewContent.type === 'video') {
      changeVoice();
    } else {
      createCell();
    }
  };

  return (
    <Base onClick={handleClick}>
      {isNew && <NewChip>NEW</NewChip>}
      {!isNew && isBest && <BestChip>BEST</BestChip>}
      <PlusIcon size={24} weight={'bold'} />
      <SummaryBox>
        <ProfileBox>
          <ProfileView modelColor={modelColor}>
            {displayName[0].toUpperCase()}
          </ProfileView>
          <Name>{displayName}</Name>
        </ProfileBox>
        <Hashbang>{tags.map((tag) => `#${tag} `).join('')}</Hashbang>
      </SummaryBox>
      <ListenButton onClick={handleClickPlay}>
        {mergeLoading ? (
          <TailSpinIcon width={16} height={16} />
        ) : (
          <>
            {state.paused ? (
              <PlayCircleIcon size={16} weight={'fill'} />
            ) : (
              <StopCircleIcon size={16} weight={'fill'} />
            )}
          </>
        )}
        <span>{state.paused ? t('button.play') : t('button.stop')}</span>
      </ListenButton>
      {element}
    </Base>
  );
};

export default VoiceModelItem;

const Base = styled.li`
  width: 100%;
  height: 80px;
  border: 1px solid var(--color-grey-300);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 16px;
  user-select: none;
  position: relative;

  & > svg {
    color: var(--color-grey-500);
    cursor: pointer;
  }

  & + & {
    margin-top: 16px;
  }

  &:hover {
    padding: 15px;
    border-width: 2px;
    border-color: var(--color-grey-500);

    & > span {
      top: -11px;
      left: -1px;
    }
  }

  &:active {
    border-color: var(--color-pink);
  }
`;

const BestChip = styled.span`
  width: 40px;
  height: 20px;
  background: var(--color-gradient);
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  color: var(--color-white);
  position: absolute;
  left: 0;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NewChip = styled.span`
  width: 40px;
  height: 20px;
  background: var(--color-pink);
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  color: var(--color-white);
  position: absolute;
  left: 0;
  top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SummaryBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const ProfileBox = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileView = styled.span<{
  modelColor: string;
}>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${({ modelColor }) =>
    getHexLuminance(modelColor) > 0.5
      ? 'var(--color-black)'
      : 'var(--color-white)'};
  background-color: ${({ modelColor }) => modelColor};
  border: ${({ modelColor }) =>
    modelColor === '#FFFFFFF' ? '1px solid var(--color-grey-500)' : 'none'};
  font-size: 10px;
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.span`
  margin-left: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Hashbang = styled.div`
  margin-top: 4px;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-500);
  width: 212px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ListenButton = styled.button`
  width: 72px;
  height: 20px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  padding: 0;
  background: var(--color-white);
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-700);
  cursor: pointer;

  svg {
    fill: currentColor;
  }

  span {
    margin-left: 4px;
  }
`;
