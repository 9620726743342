import * as React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { download } from 'lib/utils';
import {
  fetchSubtitleSRT,
  selectProjectName,
} from 'features/editor/editorSlice';

interface SubtitleSRTProps {
  children?: React.ReactNode;
}

const SubtitleSRT: React.FC<SubtitleSRTProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const name = useAppSelector(selectProjectName);

  const handleClick = async () => {
    try {
      const blob = await dispatch(fetchSubtitleSRT()).unwrap();

      download(blob, `${name || 'untitled'}.srt`);
    } catch (error) {
      alert('비디오 다운로드를 진행한 후에 자막 다운로드를 다시 시도해주세요.');
      console.error(error);
    }
  };

  return <Base onClick={handleClick}>{children}</Base>;
};

export default SubtitleSRT;

const Base = styled.li`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-black);
  background: var(--color-white);

  &:hover {
    background: linear-gradient(rgba(51, 51, 51, 0.08), rgba(51, 51, 51, 0.08))
      var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }
`;
