import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectLoginInfo } from './authSlice';

const RequireAuth = ({
  children,
  forceRedirectExpired = true,
}: {
  children: JSX.Element;
  forceRedirectExpired?: boolean;
}) => {
  const location = useLocation();
  const { token, expiredTime } = useAppSelector(selectLoginInfo);

  if (token === null || expiredTime === null) {
    return <Navigate to={'/auth/login'} state={{ from: location }} replace />;
  }

  if (forceRedirectExpired && expiredTime < Date.now()) {
    return <Navigate to={'/auth/login'} state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
