import * as React from 'react';
import constate from 'constate';
import { AudioDownloadModal } from 'features/editor/Modals';
import { usePusherChannelId } from 'features/editor/providers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import userInfoService from 'app/services/userInfo';
import PusherService from 'lib/PusherService';
import {
  fetchAudio,
  selectProjectUuid,
  setOpenMergeLimitToast,
} from 'features/editor/editorSlice';
import { fetchUrlToBlob } from 'features/editor/networks';
import type { ChildrenProps } from './types';

function useAudioDownload() {
  const [open, setOpen] = React.useState<boolean>(false);

  const [statusCode, setStatusCode] = React.useState<0 | 1 | 2>(0);

  const [downloadParams, setDownloadParams] = React.useState<{
    name: string;
    data: Blob;
  } | null>(null);

  const getDownloadParams = async (url: string, name: string) => {
    try {
      const blob = await fetchUrlToBlob(url);

      setDownloadParams({
        name: name || 'untitled',
        data: blob,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const channelId = usePusherChannelId();
  const projectUuid = useAppSelector(selectProjectUuid);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (channelId === undefined) return;
    if (projectUuid === undefined) return;

    const channel = PusherService.subscribeChannel(channelId);

    const listener = async (data: {
      file_name: string;
      status_code: 0 | 1 | 2;
      type: 'project_audio_zipfile' | 'project_audio';
      url: string;
    }) => {
      if (!['project_audio_zipfile', 'project_audio'].includes(data.type))
        return;

      if (data.status_code === 2) {
        const name =
          data.file_name ||
          `${
            data.type === 'project_audio_zipfile'
              ? 'untitled.zip'
              : 'untitled.wav'
          }`;
        await getDownloadParams(data.url, name);
        dispatch(userInfoService.util.invalidateTags([{ type: 'UserInfo' }]));
      }

      setStatusCode(data.status_code);
    };

    channel.bind(projectUuid, listener);

    return () => {
      channel.unbind(projectUuid, listener);
    };
  }, [channelId, dispatch, projectUuid]);

  const prev = React.useRef<{ format: 'zip' | 'audio' } | null>(null);

  React.useEffect(() => {
    if (open) return;
    prev.current = null;
  }, [open]);

  const openModal = async (params: { format: 'zip' | 'audio' }) => {
    if (channelId === undefined) return;
    if (projectUuid === undefined) return;

    prev.current = params;
    const { format } = params;

    try {
      const data = await dispatch(
        fetchAudio({
          eventId: projectUuid,
          channelId: channelId,
          format,
        })
      ).unwrap();
      setOpen(true);

      if (data.downloadUrl !== '' && data.fileName !== '') {
        setStatusCode(2);
        const name =
          data.fileName ||
          `${format === 'zip' ? 'untitled.zip' : 'untitled.wav'}`;

        await getDownloadParams(data.downloadUrl, name);
        return;
      }

      setStatusCode(1);
    } catch (error) {
      if ((error as Error).message === 'merge limit') {
        dispatch(setOpenMergeLimitToast(true));
        return;
      }

      console.error(error);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const retry = () => {
    if (prev.current === null) return;
    openModal(prev.current);
  };

  return {
    open,
    statusCode: statusCode / 2,
    downloadParams: downloadParams,
    openModal,
    closeModal,
    retry,
  };
}

const [
  Provider,
  useAudioDownloadModalState,
  useAudioDownloadProgress,
  useAudioDownloadParams,
  useAudioDownloadModalOpen,
  useAudioDownloadModalClose,
  useAudioDownloadRetry,
] = constate(
  useAudioDownload,
  (state) => state.open,
  (state) => state.statusCode,
  (state) => state.downloadParams,
  (state) => state.openModal,
  (state) => state.closeModal,
  (state) => state.retry
);

const Modal: React.FC<ChildrenProps> = ({ children }) => {
  const open = useAudioDownloadModalState();

  return (
    <>
      {open && <AudioDownloadModal />}
      {children}
    </>
  );
};

export const AudioDownloadModalProvider = ({ children }: ChildrenProps) => (
  <Provider>
    <Modal>{children}</Modal>
  </Provider>
);

export {
  useAudioDownloadModalState,
  useAudioDownloadProgress,
  useAudioDownloadParams,
  useAudioDownloadModalOpen,
  useAudioDownloadModalClose,
  useAudioDownloadRetry,
};
