import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGetProjectsQuery } from 'app/services/projects';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { kmp } from 'lib/utils';
import { GradientAddIcon } from 'assets/svg';
import { IconButton, Toast } from 'components/common';
import {
  TitleBox,
  ProjectCard,
  ProjectDeleteDialog,
  SearchBox,
} from 'components/projectList';
import { useSortFn } from 'providers/projectList';

const ProjectList: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'common' });

  const [pollingInterval, setPollingInterval] = React.useState(0);

  const { data: userInfo, isSuccess } = useGetUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
    pollingInterval: pollingInterval,
  });

  const { projects } = useGetProjectsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    pollingInterval: pollingInterval,
    selectFromResult: ({ data }) => {
      if (data === undefined) return { projects: undefined };
      if (userInfo === undefined) return { projects: undefined };

      const ret = [...data]
        .sort((prev, next) => {
          const prevDate = new Date(prev.createDate);
          const nextDate = new Date(next.createDate);

          return nextDate.getTime() - prevDate.getTime();
        })
        .map((el, idx) => ({
          ...el,
          disabled: idx > userInfo.projectLimitNum - 1,
        }));

      return {
        projects: ret,
      };
    },
    skip: !isSuccess,
  });

  const isProgress = projects?.some((project): boolean => {
    const { renderingStatus } = project;
    return renderingStatus > 0 && renderingStatus < 5;
  });

  React.useEffect(() => {
    const interval = isProgress ? 3000 : 0;
    setPollingInterval(interval);
  }, [setPollingInterval, isProgress]);

  const sortFn = useSortFn();

  const navigate = useNavigate();

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const handleClickGoEditor = () => {
    if (projects === undefined) return;
    if (userInfo === undefined) return;

    if (projects.length < userInfo.projectLimitNum) {
      navigate('/client/editor');
      return;
    }

    toggleOpenToast();
  };

  const [searchWord, setSearchWord] = React.useState<string>('');

  const handleChangeSearchWord: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setSearchWord(e.target.value);
  };

  return (
    <Base>
      <TitleBox />
      <SearchBox value={searchWord} onChange={handleChangeSearchWord} />
      {projects && (
        <List>
          {[...projects]
            .sort(sortFn)
            .filter((project) => {
              if (searchWord === '') return true;
              if (
                kmp(
                  project.projectName.toUpperCase(),
                  searchWord.toUpperCase()
                ) > -1
              )
                return true;
              return false;
            })
            .map((project) => (
              <ProjectCard
                uuid={project.projectUuid}
                key={project.projectUuid}
                disabled={project.disabled}
              />
            ))}
        </List>
      )}
      <ProjectDeleteDialog />
      <GoEditorButton size={48} radius={10} onClick={handleClickGoEditor}>
        <GradientAddIcon width={32} height={32} />
      </GoEditorButton>
      <Toast
        in={openToast}
        onClose={toggleOpenToast}
        timeout={1500}
        message={t('createProjectOver')}
        severity={'error'}
      />
    </Base>
  );
};

export default ProjectList;

const Base = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: auto;

  overflow-y: none;
  @media (min-width: 1024px) {
    overflow-y: hidden;
  }
`;

const List = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 330px);
  grid-template-rows: repeat(auto-fit, 240px);
  list-style: none;

  @media (min-width: 520px) {
    justify-content: flex-start;
    grid-row-gap: 28px;
  }

  @media (min-width: 1024px) {
    grid-row-gap: 32px;
    overflow-y: auto;
  }
`;

const GoEditorButton = styled(IconButton)`
  position: fixed;
  right: 16px;
  bottom: 16px;
  border: 1px solid var(--color-grey-50);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);

  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
`;
