import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FloppyDiskIcon } from 'assets/svg';
import { IconButton, Toast } from 'components/common';
import {
  selectProjectIsExist,
  fetchUpdateProject,
  fetchCreateProject,
  selectNetworkState,
  setInitializeCellData,
  setInitializeProjectName,
} from 'features/editor/editorSlice';

const SaveButton: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'saveButton' });

  const dispatch = useAppDispatch();

  const projectIsExist = useAppSelector(selectProjectIsExist);

  const toastMessage = React.useRef<string>('');
  const toastSeverity = React.useRef<'success' | 'error'>('success');

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const networkState = useAppSelector(selectNetworkState);

  const handleClickSave = async () => {
    if (networkState === 'save') return;

    try {
      if (projectIsExist) {
        await dispatch(fetchUpdateProject());
      } else {
        dispatch(setInitializeProjectName());
        dispatch(setInitializeCellData());
        await dispatch(fetchCreateProject());
      }
      toastMessage.current = t('success');
      toastSeverity.current = 'success';
      toggleOpenToast();
    } catch (error) {
      toastMessage.current = t('failure.mobile');
      toastSeverity.current = 'error';
      toggleOpenToast();
      console.error(error);
    }
  };

  return (
    <>
      <IconButton size={32} radius={6} onClick={handleClickSave}>
        <FloppyDiskIcon
          size={32}
          weight={'bold'}
          color={'var(--color-grey-700)'}
        />
      </IconButton>
      <Toast
        in={openToast}
        onClose={toggleOpenToast}
        timeout={2500}
        severity={toastSeverity.current}
        message={toastMessage.current}
      />
    </>
  );
};

export default SaveButton;
