import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { CloudArrowUpIcon } from 'assets/svg';

interface DragAndDropUploadProps {
  accept: string;
  helperText: string;
  onChangeFile: (newFile: File) => void;
}

const DragAndDropUpload: React.FC<DragAndDropUploadProps> = ({
  accept,
  helperText,
  onChangeFile,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'dragAndDrop' });

  const [isDragging, setIsDragging] = React.useState<boolean>(false);

  const handleDragEnter: React.DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files.length === 0) return;

    setIsDragging(true);
  };

  const handleDragLeave: React.DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  };

  const handleDragOver: React.DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isDragging) return;
    if (e.dataTransfer.files.length === 0) return;

    setIsDragging(true);
  };

  const handleDrop: React.DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files.length === 0) return;

    const file = e.dataTransfer.files[0];
    onChangeFile(file);
  };

  const handleChangeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const el = e.target instanceof HTMLInputElement ? e.target : undefined;
    if (el === undefined) return;
    if (el.files === null) return;
    if (el.files.length === 0) return;

    const file = el.files[0];
    onChangeFile(file);

    el.value = '';
  };

  return (
    <Box
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <CloudArrowUpIcon size={56} weight={'bold'} />
      <TextBox>
        <Text>{helperText}</Text>
        <OpenFileBrowserText>{t('browse')}</OpenFileBrowserText>
      </TextBox>
      <input
        type={'file'}
        accept={accept}
        style={{ display: 'none' }}
        onChange={handleChangeInput}
      />
    </Box>
  );
};

export default DragAndDropUpload;

const Box = styled.label`
  width: 100%;
  height: 256px;
  border: 2px dashed var(--color-grey-500);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: var(--color-grey-500);
  }
`;

const TextBox = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.span`
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-black);
  white-space: pre;
  text-align: center;
`;

const OpenFileBrowserText = styled.span`
  margin-top: 14px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-blue);
`;
