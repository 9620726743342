import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation, Trans } from 'react-i18next';
import { XIcon } from 'assets/svg';
import { IconButton } from 'components/common';

interface DownloadErrorProps {
  onEnded: () => void;
  onRetry: () => void;
}

const DownloadError: React.FC<DownloadErrorProps> = ({ onEnded, onRetry }) => {
  const { t } = useTranslation('editor', { keyPrefix: 'downloadTemplate' });

  const handleClickClose = () => {
    onEnded();
  };

  return (
    <Base>
      <TitleBox>
        <IconButton size={24} radius={6} onClick={handleClickClose}>
          <XIcon size={20} weight={'bold'} />
        </IconButton>
      </TitleBox>
      <Title>{t('title.error')}</Title>
      <ErrorMessageBox>
        <Trans t={t} i18nKey={'body.error'} components={[<span />, <span />]} />
      </ErrorMessageBox>
      <RetryButtonBox>
        <RetryButton onClick={onRetry}>{t('button.retry')}</RetryButton>
        <CancelButton onClick={handleClickClose}>
          {t('button.close')}
        </CancelButton>
      </RetryButtonBox>
    </Base>
  );
};

export default DownloadError;

const Base = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-white);
  border-radius: 10px;
  width: 440px;
  margin: 0 16px;
`;

const TitleBox = styled.div`
  padding: 24px 20px;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    color: var(--color-black);
  }
`;

const Title = styled.span`
  padding: 0 24px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  color: var(--color-black);
`;

const ErrorMessageBox = styled.p`
  margin: 0;
  padding: 16px 24px 0px 24px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-black);
  span {
    display: block;
  }
`;

const RetryButtonBox = styled.div`
  padding: 16px 24px 24px 24px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RetryButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  outline: none;
  padding: 0 16px;
  border: none;

  flex: 1;

  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;

  position: relative;
  z-index: 1;

  color: var(--color-white);
  background: var(--color-pink);

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;

const CancelButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  outline: none;
  padding: 0 16px;
  border: 1px solid var(--color-black);

  flex: 1;

  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;

  color: var(--color-black);
  background: var(--color-white);

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
