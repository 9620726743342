import * as React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { InfoFooter } from 'components/common';
import Header from './Header';

interface ResetPasswordTemplateProps {
  children?: React.ReactNode;
}

const ResetPasswordTemplate: React.FC<ResetPasswordTemplateProps> = ({
  children,
}) => {
  return (
    <>
      <Global styles={globalStyles} />
      <Header />
      <Main>
        <Contents>{children}</Contents>
      </Main>
      <InfoFooter />
    </>
  );
};

export default ResetPasswordTemplate;

const globalStyles = css`
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }
`;

const Main = styled.main`
  width: 100%;
  min-height: calc(100% - 421px);
  display: flex;

  @media (min-width: 1052px) {
    min-height: calc(100% - 244px);
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  margin-top: 64px;
  overflow-y: auto;

  @media (min-width: 1024px) {
    margin-top: 0px;
    padding: 32px 24px;
  }
`;
