import { createListenerMiddleware } from '@reduxjs/toolkit';
import ChannelIO from 'lib/channelIO/ChannelIO';
import UserInfoService from 'app/services/userInfo';

const listeners = createListenerMiddleware();

listeners.startListening({
  matcher: UserInfoService.endpoints.getUserInfo.matchFulfilled,
  effect: async (action) => {
    const { email, socialAccountInfo, billingName } = action.payload;

    const instance = ChannelIO.getInstance();

    instance.updateUser({
      profile: {
        onairEmail: email,
        onairAccountType: socialAccountInfo,
        onairBillingName: billingName,
      },
      profileOnce: {
        onairEmail: email,
        onairAccountType: socialAccountInfo,
        onairBillingName: billingName,
      },
    });
  },
});

export default listeners;
