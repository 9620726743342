import type {
  Action,
  Reducer,
  ActionCreatorWithoutPayload,
} from '@reduxjs/toolkit';

export interface StateOfHistory<S> {
  past: string[];
  present: S;
  future: string[];
  index: number;
  limit: number;
}

const undoableInit: Action<'@@undoable/init'> = {
  type: '@@undoable/init',
};

interface UndoableOptions {
  undoAction: Action | ActionCreatorWithoutPayload;
  redoAction: Action | ActionCreatorWithoutPayload;
  clearAction: Action | ActionCreatorWithoutPayload;
  blacklistActions?: (Action | ActionCreatorWithoutPayload)[];
}

const stringify = (value: any): string => JSON.stringify(value, null, 0);
const parse = <T>(value: string): T => JSON.parse(value);

export default function undoable<State>(
  reducer: Reducer<State, Action>,
  { undoAction, redoAction, clearAction, blacklistActions }: UndoableOptions
): Reducer<StateOfHistory<State>, Action> {
  const initialState: StateOfHistory<State> = {
    past: [],
    present: reducer(undefined, undoableInit),
    future: [],
    index: 0,
    limit: 1,
  };

  return (state = initialState, action) => {
    const { past, present, future } = state;

    switch (action.type) {
      case undoAction.type: {
        const previous = parse<State>(past[past.length - 1]);
        const newPast = past.slice(0, past.length - 1);
        const newFuture = [stringify(present), ...future];

        return {
          past: newPast,
          present: previous,
          future: newFuture,
          index: newPast.length,
          limit: newPast.length + future.length + 1,
        };
      }
      case redoAction.type: {
        const next = parse<State>(future[0]);
        const newPast = [...past, stringify(present)];
        const newFuture = future.slice(1);
        return {
          past: newPast,
          present: next,
          future: newFuture,
          index: past.length + 1,
          limit: past.length + future.length + 1,
        };
      }
      case clearAction.type: {
        return initialState;
      }
      default: {
        const newPresent = reducer(present, action);
        if (present === newPresent) {
          return state;
        }

        if (blacklistActions !== undefined) {
          if (
            blacklistActions.some((blacklist) => blacklist.type === action.type)
          ) {
            return { ...state, present: newPresent };
          }
        }

        const newPast = [...past, stringify(present)];

        return {
          past: newPast,
          present: newPresent,
          future: [],
          index: past.length + 1,
          limit: past.length + 2,
        };
      }
    }
  };
}
