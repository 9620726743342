import * as React from 'react';
import styled from '@emotion/styled';
import { useMatchMedia } from 'lib/hooks';
import { useAppSelector } from 'app/hooks';
import { selectCurrentPageKey } from 'features/editor/editorSlice';
import Desktop from './PageTimeline.Desktop';
import Mobile from './PageTimeline.Mobile';

const PageTimeline: React.FC = () => {
  const isMatch = useMatchMedia('(min-width: 1024px)');

  const pageKey = useAppSelector(selectCurrentPageKey);

  return <Base key={pageKey}>{isMatch ? <Desktop /> : <Mobile />}</Base>;
};

export default PageTimeline;

const Base = styled.div`
  z-index: 1;
  flex: 1;
  background: var(--color-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
`;
