import { loadScript } from './utils';

const BUCKET_URL: string =
  process.env.ONAIR_INTERNAL === 'Y'
    ? 'https://learning-slide-test.s3.amazonaws.com/'
    : 'https://learning-slide.s3.amazonaws.com/';

const KAKAOLINK_TEMPLATE_ID: number =
  process.env.ONAIR_INTERNAL === 'Y' ? 60990 : 61118;

const KAKAOLINK_PERSIMMON_TEMPLATE_ID: number =
  process.env.ONAIR_INTERNAL === 'Y' ? 61599 : 61600;

type SignInSuccessCallback = (token: string) => void;
type SignInFailureCallback = (error: Kakao.Auth.AuthError) => void;

const isInjectError = (error: unknown): boolean => {
  if (error instanceof Error) {
    if (error.message === 'script already injected') {
      return true;
    }
  }

  return false;
};

class KakaotalkService {
  isInitialized = false;
  private isLoaded: boolean = false;
  private scriptSrc: string =
    'https://developers.kakao.com/sdk/js/kakao.min.js';

  constructor() {
    if (process.env.ONAIR_PLATFORM_TYPE !== 'onair') return;
    this.init();
  }

  private init(): void {
    if (window.Kakao) {
      console.log('Kakao SDK script include twice.');
    }

    const load = async (): Promise<void> => {
      if (this.isLoaded) return;

      try {
        await loadScript(this.scriptSrc);

        this.isLoaded = true;
        window.Kakao.init(process.env.ONAIR_KAKAO_TOKEN);
      } catch (error) {
        if (!isInjectError(error)) console.error(error);

        if (typeof window.Kakao !== 'object') return;
        if (typeof window.Kakao.isInitialized !== 'function') return;

        if (!window.Kakao.isInitialized()) {
          window.Kakao.init(process.env.ONAIR_KAKAO_TOKEN);
        }

        this.isLoaded = true;
      }
    };

    if (document.readyState === 'complete') {
      load();
    } else {
      window.addEventListener('DOMContentLoaded', load, false);
      window.addEventListener('load', load, false);
    }
  }

  boot(): void {
    this.isInitialized = true;
  }

  signIn({
    successCallback,
    failureCallback,
  }: {
    successCallback?: SignInSuccessCallback;
    failureCallback?: SignInFailureCallback;
  }): void {
    if (!this.isInitialized) return;
    if (!this.isLoaded) return;

    window.Kakao.Auth.getStatusInfo((obj) => {
      if (obj.status === 'connected') {
        const accessToken = window.Kakao.Auth.getAccessToken();
        successCallback?.(accessToken);
        return;
      }

      window.Kakao.Auth.login({
        throughTalk: true,
        persistAccessToken: false,
        success: (resp) => {
          successCallback?.(resp.access_token);
        },
        fail: (error) => {
          console.error(error);
          failureCallback?.(error);
        },
      });
    });
  }

  sendLink(link: string) {
    if (!this.isInitialized) return;
    if (!this.isLoaded) return;

    const audioUrl = link.replace(BUCKET_URL, '');

    window.Kakao.Link.sendCustom({
      templateId: KAKAOLINK_TEMPLATE_ID,
      templateArgs: {
        audioUrl,
      },
    });
  }

  sendPersimmonLink(link: string) {
    if (!this.isInitialized) return;
    if (!this.isLoaded) return;

    const audioUrl = link.replace(BUCKET_URL, '');

    window.Kakao.Link.sendCustom({
      templateId: KAKAOLINK_PERSIMMON_TEMPLATE_ID,
      templateArgs: {
        audioUrl,
      },
    });
  }
}

export default new KakaotalkService();
