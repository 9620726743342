import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Feature1Image from 'assets/img/main-feature-1@desktop.png';
import Feature2Image from 'assets/img/main-feature-2@desktop.png';
import Feature3Image from 'assets/img/main-feature-3@desktop.png';
import FeatureBox from './FeatureBox';

const items = [
  {
    stepNumber: '01',
    src: Feature1Image,
    reverse: true,
    index: 0,
  },
  {
    stepNumber: '02',
    src: Feature2Image,
    reverse: false,
    index: 1,
  },
  {
    stepNumber: '03',
    src: Feature3Image,
    reverse: true,
    index: 2,
  },
] as const;

const FeatureSection: React.FC = () => {
  const { t } = useTranslation('main', { keyPrefix: 'features' });

  return (
    <Base>
      <Container>
        {items.map((el) => (
          <FeatureBox
            tag={t(`items.${el.index}.tag`)}
            title={t(`items.${el.index}.title`)}
            body={t(`items.${el.index}.body`)}
            register={t('register')}
            stepNumber={el.stepNumber}
            imageUrl={el.src}
            reverse={el.reverse}
            key={el.stepNumber}
          />
        ))}
      </Container>
    </Base>
  );
};

export default FeatureSection;

const Base = styled.section`
  @media (min-width: 1440px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 64px 0;
  margin: 0 auto;
`;
