import {
  persistMiddleware,
  preparePersistStore,
  removePersistStore,
} from 'lib/reduxUtils';
import type { AuthState } from 'features/auth/authSlice';

interface AuthPersistState {
  auth: AuthState;
}

const isAuthState = (data: any): data is AuthPersistState => {
  if (!data.hasOwnProperty('auth')) return false;
  const tempState = data['auth'];
  if (!tempState.hasOwnProperty('token')) return false;
  if (!tempState.hasOwnProperty('loginTime')) return false;
  if (!tempState.hasOwnProperty('expiredTime')) return false;
  if (!tempState.hasOwnProperty('errors')) return false;

  const tokenValid =
    tempState.token === null || typeof tempState.token === 'string';
  const loginTimeValid =
    tempState.loginTime === null || typeof tempState.loginTime === 'number';
  const expiredTimeValid =
    tempState.expiredTime === null || typeof tempState.expiredTime === 'number';
  const errorsValid =
    tempState.errors === null || typeof tempState.errors === 'object';

  return tokenValid && loginTimeValid && expiredTimeValid && errorsValid;
};

export const authPersistMiddleware = persistMiddleware('auth');

export const authPreparePersistStore = preparePersistStore((data) => {
  if (!isAuthState(data)) return false;

  const currentTime = Date.now();

  if (data.auth.errors.refresh === true) return false;
  if (data.auth.expiredTime === null) return true;
  if (data.auth.expiredTime < currentTime) return false;
  return true;
});

export const removeAuthPersistStore = () => removePersistStore('auth');
