interface FetchParameters {
  token: string;
  file: File;
  eventId: string;
  channelId: string;
  displayName: string;
  signal?: AbortSignal;
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/video`;

export default async function fetchUploadVideo({
  token,
  file,
  eventId,
  channelId,
  displayName,
  signal,
}: FetchParameters) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('channel_id', channelId);
  formData.append('event_id', eventId);
  formData.append('display_name', displayName);

  const response = await fetch(URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
    signal,
  });

  return response.ok;
}
