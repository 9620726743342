import Pusher from 'pusher-js';

if (process.env.ONAIR_INTERNAL === 'Y') {
  Pusher.logToConsole = true;
}

class PusherService {
  private instance: Pusher;

  constructor() {
    this.instance = new Pusher(process.env.ONAIR_PUSHER_KEY, {
      cluster: 'ap3',
    });
  }

  subscribeChannel(channelId: string) {
    return this.instance.subscribe(channelId);
  }
}

export default new PusherService();
