import { createApi } from '@reduxjs/toolkit/query/react';
import queryFetcher from './queryFetcher';

export interface LoginResponse {
  access: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export type SocialProvider = 'google' | 'kakao';

interface SocialLoginRequest {
  provider: SocialProvider;
  token: string;
}

interface RegisterRequest {
  uid: string;
  token: string;
  email: string;
  password: string;
  name: string;
  marketingAccept: boolean;
}

export const api = createApi({
  reducerPath: 'services/auth',
  baseQuery: queryFetcher('/v1', {
    noToken: true,
    mode: 'cors',
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/auth',
        method: 'POST',
        body: credentials,
      }),
    }),
    socialLogin: builder.mutation<LoginResponse, SocialLoginRequest>({
      query: ({ provider, token }) => ({
        url: `/social-auth/${provider}`,
        method: 'POST',
        body: { access_token: token },
      }),
    }),
    register: builder.mutation<LoginResponse, RegisterRequest>({
      query: ({ uid, token, email, password, name, marketingAccept }) => ({
        url: '/auth/signup',
        method: 'POST',
        body: {
          uid,
          token,
          email,
          password,
          name,
          marketing_accept: marketingAccept,
          user_type: process.env.ONAIR_PLATFORM_TYPE,
        },
      }),
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: '/auth/clear',
        method: 'GET',
      }),
    }),
    refresh: builder.mutation<LoginResponse, void>({
      query: () => ({
        url: '/auth/refresh',
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation<{ message: string }, { email: string }>({
      query: ({ email }) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: { email },
      }),
    }),
    resetPasswordActive: builder.mutation<
      string,
      {
        uuid: string;
        token: string;
        newPassword: string;
      }
    >({
      query: ({ uuid, token, newPassword }) => ({
        url: `/auth/reset-password-email-auth/${uuid}/${token}`,
        method: 'POST',
        body: { new_password: newPassword },
      }),
    }),
    registerEmail: builder.mutation<{ email: string }, { email: string }>({
      query: ({ email }) => ({
        url: '/auth/signup-email-send',
        method: 'POST',
        body: { email },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSocialLoginMutation,
  useRegisterMutation,
  useSignOutMutation,
  useRefreshMutation,
  useResetPasswordMutation,
  useResetPasswordActiveMutation,
  useRegisterEmailMutation,
} = api;

export default api;
