import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import ArticleCard from './ArticleCard';
import Article1Image from 'assets/img/main-article-1.png';
import Article2Image from 'assets/img/main-article-2.jpeg';
import Article3Image from 'assets/img/main-article-3.jpeg';
import { typography } from 'styles';

const articles = [
  {
    index: 0,
    imageUrl: Article1Image,
    href: 'https://blog.naver.com/airbag1/222606116138',
  },
  {
    index: 1,
    imageUrl: Article2Image,
    href: 'https://blog.naver.com/rde0409/222606333566',
  },
  {
    index: 2,
    imageUrl: Article3Image,
    href: 'https://blog.naver.com/appie14/222607596948',
  },
] as const;

const ArticleSection: React.FC = () => {
  const { t } = useTranslation('main', { keyPrefix: 'article' });

  return (
    <Base>
      <Container>
        <Title>{t('title')}</Title>
        <Subtitle>{t('subtitle')}</Subtitle>
        <Cards>
          {articles.map((el) => (
            <ArticleCard
              imageUrl={el.imageUrl}
              title={t(`items.${el.index}.title`)}
              type={t(`items.${el.index}.type`)}
              href={el.href}
              key={el.index}
            />
          ))}
        </Cards>
      </Container>
    </Base>
  );
};

export default ArticleSection;

const Base = styled.section`
  width: 100%;
  background: var(--color-white);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 64px 0;
  margin: 0 auto;

  width: calc(100% - 32px);

  @media (min-width: 820px) {
    width: 100%;
    max-width: 1440px;
  }
`;

const Title = styled.span`
  color: var(--color-black);
  ${typography.title.large}

  @media (min-width: 820px) {
    ${typography.display.medium}
  }
`;

const Subtitle = styled.span`
  margin-top: 12px;
  color: var(--color-grey-800);
  white-space: pre;
  text-align: center;
  ${typography.body.medium}

  @media (min-width: 820px) {
    ${typography.body.large}
  }
`;

const Cards = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;
