import * as React from 'react';
import { useAtom } from 'jotai';
import constate from 'constate';
import { isOpenPredictedDurationAtom } from 'features/editor/atoms';

const usePredictedDuration = () => {
  const ref = React.useRef<(() => void) | null>(null);

  const [isOpen, setIsOpen] = useAtom(isOpenPredictedDurationAtom);

  const openPredicted = (cb: () => void) => {
    ref.current = cb;
    setIsOpen(true);
  };

  React.useEffect(() => {
    if (isOpen) return;
    if (ref.current !== null) ref.current = null;
  }, [isOpen]);

  return {
    openPredicted,
    predictedCallback: ref.current,
  };
};

export const [
  PredictedDurationProvider,
  useOpenPredicted,
  usePredictedCallback,
] = constate(
  usePredictedDuration,
  (state) => state.openPredicted,
  (state) => state.predictedCallback
);
