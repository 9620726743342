import auth from './auth';
import musicAssets from './musicAssets';
import projects from './projects';
import userInfo from './userInfo';
import videoModel from './videoModel';
import voiceModel from './voiceModel';
import watermark from './watermark';
import membership from './membership';
import templates from './templates';
import videoAssets from './videoAssets';

const reducers = {
  [auth.reducerPath]: auth.reducer,
  [musicAssets.reducerPath]: musicAssets.reducer,
  [projects.reducerPath]: projects.reducer,
  [userInfo.reducerPath]: userInfo.reducer,
  [videoModel.reducerPath]: videoModel.reducer,
  [voiceModel.reducerPath]: voiceModel.reducer,
  [watermark.reducerPath]: watermark.reducer,
  [membership.reducerPath]: membership.reducer,
  [templates.reducerPath]: templates.reducer,
  [videoAssets.reducerPath]: videoAssets.reducer,
};

const middlewares = [
  auth.middleware,
  musicAssets.middleware,
  projects.middleware,
  userInfo.middleware,
  videoModel.middleware,
  voiceModel.middleware,
  watermark.middleware,
  membership.middleware,
  templates.middleware,
  videoAssets.middleware,
];

const services = {
  reducers,
  middlewares,
};

export default services;
