import * as React from 'react';
import styled from '@emotion/styled';
import { kmp } from 'lib/utils';
import { useGetVideoModelQuery } from 'app/services/videoModel';
import { sort, videoModelCompare } from 'features/editor/utils';
import ModelSelectItem from './ModelSelectItem';

interface ModelSelectProps {
  searchModelName: string;
  selectedModelName?: string;
  onSelectModel: (newName: string) => void;
}

const ModelSelect: React.FC<ModelSelectProps> = ({
  searchModelName,
  selectedModelName = '',
  onSelectModel,
}) => {
  const { data: videoModels } = useGetVideoModelQuery();

  return (
    <Base>
      <List>
        {sort(videoModels, videoModelCompare)
          ?.filter((model) => {
            if (searchModelName === '') return true;
            if (
              kmp(
                model.displayName.toUpperCase(),
                searchModelName.toUpperCase()
              ) > -1
            )
              return true;
            return false;
          })
          .map((model) => (
            <ModelSelectItem
              profile={model.profileThumb}
              displayName={model.displayName}
              audioModelName={model.audioMlModelName}
              isSelected={selectedModelName === model.name}
              onSelect={onSelectModel.bind(null, model.name)}
              language={model.language}
              key={model.key}
            />
          ))}
      </List>
    </Base>
  );
};

export default ModelSelect;

const Base = styled.div`
  width: 100%;
  height: 100%;
`;

const List = styled.div`
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 4fr));
  grid-template-rows: repeat(auto-fit, 132px);
  grid-column-gap: 8px;
  grid-row-gap: 16px;
  list-style: none;
  overflow-y: auto;
  height: 100%;
`;
