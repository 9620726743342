import * as React from 'react';
import styled from '@emotion/styled';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import { getHexLuminance } from 'lib/utils';
import { useAppSelector } from 'app/hooks';
import { CaretDownIcon } from 'assets/svg';
import { selectCellModelName } from 'features/editor/editorSlice';
import { useVoiceModelsModalOpen } from 'features/editor/providers';

interface CellModelNameProps {
  pageKey: string;
  cellKey: string;
}

const CellModelName: React.FC<CellModelNameProps> = ({ pageKey, cellKey }) => {
  const name = useAppSelector(selectCellModelName(cellKey));

  const { model } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      model: data?.find((el) => el.name === name),
    }),
  });

  const openModal = useVoiceModelsModalOpen();

  const handleClick = openModal.bind(null, {
    key: [pageKey, cellKey],
    name,
    type: 'cell',
  });

  return (
    <>
      <Base onClick={handleClick}>
        {model !== undefined && (
          <>
            <ModelProfileView modelColor={model.defaultColor}>
              {model.displayName[0].toUpperCase()}
            </ModelProfileView>
            <ModelName>{model.displayName}</ModelName>
            <CaretDownIcon size={12} weight={'fill'} />
          </>
        )}
      </Base>
    </>
  );
};

export default CellModelName;

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;
  width: 120px;
  height: 40px;
  background: var(--color-white);
  border-radius: var(--cell-model-name-radius);
`;

const ModelProfileView = styled.span<{
  modelColor: string;
}>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: ${({ modelColor }) =>
    getHexLuminance(modelColor) > 0.5
      ? 'var(--color-black)'
      : 'var(--color-white)'};
  background-color: ${({ modelColor }) => modelColor};
  border: ${({ modelColor }) =>
    modelColor === '#FFFFFFF' ? '1px solid var(--color-grey-500)' : 'none'};
  font-size: 10px;
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModelName = styled.span`
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-black);
  width: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
