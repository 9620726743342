import * as React from 'react';
import { useAppDispatch } from 'app/hooks';
import { useRegisterMutation } from 'app/services/auth';
import { RegisterForm } from 'components/auth';
import { setCredentials } from './authSlice';
import InactiveUserDialog from './InactiveUserDialog';

interface InactiveError {
  status: 403;
  data: any;
}

const isInactiveError = (error: any): error is InactiveError => {
  if (error.hasOwnProperty('status')) {
    if (error.status === 403) return true;
    return false;
  }
  return false;
};

const Register: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const toggleLoading = () => setLoading((prev) => !prev);

  const dispatch = useAppDispatch();

  const [requestRegister] = useRegisterMutation();

  const [inactiveIsOpen, setInactiveIsOpen] = React.useState<boolean>(false);
  const toggleInactiveIsOpen = () => setInactiveIsOpen((prev) => !prev);

  return (
    <>
      <RegisterForm
        onSubmit={async (data) => {
          try {
            toggleLoading();
            const { access } = await requestRegister(data).unwrap();

            dispatch(
              setCredentials({
                token: access,
                loginTime: Date.now(),
              })
            );
          } catch (error) {
            if (isInactiveError(error)) {
              toggleLoading();
              return;
            }
            console.error(error);
          } finally {
            toggleLoading();
          }
        }}
        loading={loading}
      />
      <InactiveUserDialog
        open={inactiveIsOpen}
        onClose={toggleInactiveIsOpen}
      />
    </>
  );
};

export default Register;
