import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFloating, offset, autoUpdate } from '@floating-ui/react-dom';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AudioManager from 'lib/AudioManager';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import {
  selectUseCC,
  selectCurrentCell,
  selectCurrentPageSTV,
  selectPageLastCellModelName,
  selectTimelineEditableCell,
  modifyTimelineEditableCell,
  fetchMergeText,
  createTimelineCell,
  editTimelineCell,
  selectTimelineCurrentTime,
  setTimelineIsLoading,
} from 'features/editor/editorSlice';
import {
  VOICE_DEFAULT_VOLUME,
  VOICE_DEFAULT_SPEED,
} from 'features/editor/constants';
import { sort, voiceModelCompare } from 'features/editor/utils';
import EditableCellButton from './EditableCellButton';
import CellControlBox from './CellControlBox';
import CellModelName from './CellModelName';
import CellTextInput from './CellTextInput';
import CellCCInput from './CellCCInput';

const EditableCell: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const { x, y, reference, floating, strategy, update, refs } = useFloating({
    placement: 'top',
    strategy: 'fixed',
    middleware: [offset(8)],
  });

  React.useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) return;

    return autoUpdate(refs.reference.current, refs.floating.current, update);
  }, [refs.reference, refs.floating, update]);

  const stv = useAppSelector(selectCurrentPageSTV);
  const pageLastCellModelName = useAppSelector(selectPageLastCellModelName);
  const { firstModelName } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => {
      let ret: { firstModelName?: string } = { firstModelName: undefined };
      if (data === undefined) return ret;
      ret.firstModelName = sort(data, voiceModelCompare)?.[0].name;
      return ret;
    },
  });
  const initModelName = pageLastCellModelName ?? firstModelName;

  const prevModelName = React.useRef<string | undefined>(initModelName);

  const editableCell = useAppSelector(selectTimelineEditableCell);

  const currentCell = useAppSelector(selectCurrentCell);
  const isCellSelected = currentCell !== undefined;

  const useCC = useAppSelector(selectUseCC);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (currentCell) {
      dispatch(
        modifyTimelineEditableCell({
          text: currentCell.text,
          displayText: currentCell.displayText,
          volume: currentCell.volume,
          speed: currentCell.speed,
          modelName: currentCell.mlModelName,
        })
      );
    } else {
      dispatch(
        modifyTimelineEditableCell({
          text: '',
          displayText: '',
          volume: VOICE_DEFAULT_VOLUME,
          speed: VOICE_DEFAULT_SPEED,
          modelName: prevModelName.current,
        })
      );
    }
  }, [currentCell, dispatch]);

  React.useEffect(() => {
    if (stv === undefined) return;
    const { audioModelName } = stv;

    prevModelName.current = audioModelName;

    dispatch(
      modifyTimelineEditableCell({
        modelName: audioModelName,
      })
    );
  }, [dispatch, stv]);

  const handleChangeInput = (text: string) => {
    dispatch(
      modifyTimelineEditableCell({
        text,
      })
    );
  };

  const handleChangeCellModel = (modelName: string) => {
    prevModelName.current = modelName;
    dispatch(
      modifyTimelineEditableCell({
        modelName,
      })
    );
  };

  const currentTime = useAppSelector(selectTimelineCurrentTime);

  const calcAudioDuration = () => {
    return new Promise<number>(async (resolve, reject) => {
      if (editableCell.text.trim().length === 0) {
        reject();
        return;
      }
      if (editableCell.modelName === '') {
        reject();
        return;
      }

      try {
        const blob = await dispatch(
          fetchMergeText({
            text: editableCell.text,
            name: editableCell.modelName,
            volume: editableCell.volume,
            speed: editableCell.speed,
          })
        ).unwrap();
        const url = AudioManager.createObjectUrl(blob);

        const audioEl = new Audio();
        audioEl.addEventListener(
          'loadedmetadata',
          () => {
            resolve(Math.ceil(audioEl.duration * 1000));
            AudioManager.revokeObjectUrl(audioEl.src);
          },
          { once: true }
        );
        audioEl.src = url;
      } catch (error) {
        reject(error);
      }
    });
  };

  const editCell = (audioDuration: number) => {
    dispatch(
      editTimelineCell({
        startTime: currentTime,
        audioDuration,
      })
    );

    prevModelName.current = editableCell.modelName;
  };

  const createCell = (audioDuration: number) => {
    dispatch(
      createTimelineCell({
        startTime: currentTime,
        audioDuration,
      })
    );

    dispatch(
      modifyTimelineEditableCell({
        text: '',
        displayText: '',
      })
    );

    prevModelName.current = editableCell.modelName;
  };

  const handleSubmit = async () => {
    try {
      dispatch(setTimelineIsLoading({ isLoading: true }));
      const audioDuration = await calcAudioDuration().finally(() =>
        dispatch(setTimelineIsLoading({ isLoading: false }))
      );

      if (isCellSelected) {
        editCell(audioDuration);
      } else {
        createCell(audioDuration);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
      handleSubmit();
      return;
    }
  };

  if (editableCell.modelName === undefined) return null;

  return (
    <>
      <EditableCellButton
        ref={floating}
        style={{
          position: strategy,
          top: y ?? '',
          left: x ?? '',
        }}
        isCellSelected={isCellSelected}
        onSubmit={handleSubmit}
      />
      <Base ref={reference}>
        <CellControlBox />
        <InputBox>
          <CellModelName
            modelName={editableCell.modelName}
            onChange={handleChangeCellModel}
          />
          <CellTextInput
            text={editableCell.text}
            onChange={handleChangeInput}
            onKeyUp={handleKeyUp}
          />
          {useCC && (
            <>
              <CCLabel>
                <span>{t('label.cellDisplayText')}</span>
              </CCLabel>
              <CellCCInput />
            </>
          )}
        </InputBox>
      </Base>
    </>
  );
};

export default EditableCell;

const Base = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  padding: 16px 8px;
  box-shadow: 0px -2px 8px rgba(51, 51, 51, 0.08);
`;

const InputBox = styled.div`
  flex: 1;
  height: 100%;
  border: 1px solid var(--color-grey-200);
  border-radius: 10px;
  display: grid;
  grid-template-columns: 120px 1fr;
  background: var(--color-grey-200);
  gap: 1px;
  overflow: hidden;
`;

const CCLabel = styled.div`
  min-height: 40px;
  height: 100%;
  background: var(--color-white);
  color: var(--color-black);
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  align-items: start;
  justify-content: end;

  span {
    height: 40px;
    padding: 8px;
  }
`;
