import * as React from 'react';

interface LazyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
}

const LazyImage = React.forwardRef<HTMLImageElement | null, LazyImageProps>(
  ({ src, alt, ...rest }, ref) => {
    const imageRef = React.useRef<HTMLImageElement | null>(null);

    React.useImperativeHandle<HTMLImageElement | null, HTMLImageElement | null>(
      ref,
      () => imageRef.current
    );

    React.useEffect(() => {
      const imageEl = imageRef.current;
      if (imageEl === null) return;

      const src = imageEl.dataset.src;
      if (src === undefined) return;

      let observer: IntersectionObserver;
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            imageEl.src = src;
            observer.unobserve(imageEl);
          }
        },
        {
          threshold: [0.25],
        }
      );

      observer.observe(imageEl);

      return () => {
        if (observer) observer.disconnect();
      };
    }, []);

    React.useEffect(() => {
      if (src === undefined) return;

      const imageEl = imageRef.current;
      if (imageEl === null) return;

      if (imageEl.src === '') return;
      imageEl.src = src;
    }, [src]);

    return <img {...rest} ref={imageRef} alt={alt} data-src={src} />;
  }
);

export default LazyImage;
