import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { PlayCircleIcon, PauseCircleIcon, TailSpinIcon } from 'assets/svg';
import {
  setTimelineCurrentTime,
  selectCurrnetPageTextDatas,
  fetchMergeAudios,
} from 'features/editor/editorSlice';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
} from 'features/editor/providers';
import { useVideoPreviewControls } from 'features/editor/providers/videoPreview';

const PlayListenButton: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const dispatch = useAppDispatch();

  const prevMergeData = React.useRef<string>('');
  const textDatas = useAppSelector(selectCurrnetPageTextDatas);

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();
  const videoPreviewControls = useVideoPreviewControls();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  React.useEffect(() => {
    dispatch(setTimelineCurrentTime(Math.floor(state.time * 1000)));
  }, [state.time, dispatch]);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async () => {
    const el = ref.current;
    if (el === null) return;
    if (textDatas.length === 0) return;

    const newTextDatas = [...textDatas].map((data, index) => {
      if (textDatas.length === index + 1) return { ...data, duration: 0 };
      return data;
    });

    videoPreviewControls?.pause();

    if (!state.paused) {
      editorAudioPause();
      return;
    }

    if (prevMergeData.current === JSON.stringify(newTextDatas)) {
      editorAudioPlay(controls);
      return;
    }

    try {
      setMergeLoading(true);
      prevMergeData.current = JSON.stringify(newTextDatas);
      const url = await dispatch(fetchMergeAudios(newTextDatas)).unwrap();
      el.src = url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button onClick={handleClick}>
      {mergeLoading ? (
        <TailSpinIcon width={16} height={16} />
      ) : (
        <>
          {state.paused ? (
            <PlayCircleIcon size={16} color={'currentColor'} weight={'fill'} />
          ) : (
            <PauseCircleIcon size={16} color={'currentColor'} weight={'fill'} />
          )}
        </>
      )}
      <span>{state.paused ? t('text.play') : t('text.pause')}</span>
      <>{element}</>
    </Button>
  );
};

export default PlayListenButton;

const Button = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 1.4;
  color: var(--color-pink-700);
  cursor: pointer;

  svg + span {
    margin-left: 4px;
  }
`;
