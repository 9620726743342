import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGetProjectsQuery } from 'app/services/projects';
import { DotsThreeOutlineVerticalIcon, LogoOverlayIcon } from 'assets/svg';
import ProjectNameInput from './ProjectNameInput';
import ProjectDeleteDialog from './ProjectDeleteDialog';

export interface ProjectCardProps {
  uuid: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ uuid }) => {
  const { t } = useTranslation('client', { keyPrefix: 'home' });

  const { project } = useGetProjectsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      project: data?.find((el) => el.projectUuid === uuid),
    }),
  });

  const [isHover, setIsHover] = React.useState<boolean>(false);

  const [openOption, setOpenOption] = React.useState<boolean>(false);
  const toggleOpenOption = () => setOpenOption((prev) => !prev);

  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => {
    setIsHover(false);
    setOpenOption(false);
  };

  const [editProjectName, setEditProjectName] = React.useState<boolean>(false);
  const toggleEditProjectName = () => setEditProjectName((prev) => !prev);

  const navigate = useNavigate();

  const handleClick = () => {
    if (project === undefined) return;

    navigate(`/client/editor#uuid=${uuid}`);
  };

  const handleClickOption: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    toggleOpenOption();
  };

  const handleClickModifyName: React.MouseEventHandler<HTMLLIElement> = (e) => {
    e.stopPropagation();
    toggleOpenOption();
    toggleEditProjectName();
  };

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] =
    React.useState<boolean>(false);

  const handleClickDeleteProject: React.MouseEventHandler<HTMLLIElement> = (
    e
  ) => {
    e.stopPropagation();
    if (project === undefined) return;
    toggleOpenOption();
    setIsOpenDeleteDialog(true);
  };

  if (project === undefined) return null;
  return (
    <Base onClick={handleClick}>
      <ThumbnailBox
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {project.projectThumbnail && (
          <ThumbnailImage
            src={project.projectThumbnail}
            width={328}
            height={184}
          />
        )}
        {isHover && (
          <OptionButton onClick={handleClickOption}>
            <DotsThreeOutlineVerticalIcon
              size={16}
              color={'var(--color-white)'}
              weight={'fill'}
            />
          </OptionButton>
        )}
        {isHover && openOption && (
          <OptionDropdown>
            <ProjectNameModifyOption onClick={handleClickModifyName}>
              {t('text.modify')}
            </ProjectNameModifyOption>
            <ProjectDeleteOption onClick={handleClickDeleteProject}>
              {t('text.delete')}
            </ProjectDeleteOption>
          </OptionDropdown>
        )}
        <Overlay />
      </ThumbnailBox>
      <ProjectNameInput
        uuid={project.projectUuid}
        name={project.projectName}
        isEdit={editProjectName}
        toggleEdit={toggleEditProjectName}
      />
      <StatusBox>
        <DateText>
          {`${new Date(project.updateDate).toLocaleDateString()} ${t(
            'text.modified'
          )}`}
        </DateText>
      </StatusBox>
      {isOpenDeleteDialog && (
        <ProjectDeleteDialog
          uuid={uuid}
          onClose={() => setIsOpenDeleteDialog(false)}
        />
      )}
    </Base>
  );
};

export default ProjectCard;

const Base = styled.div`
  width: 330px;
  height: 240px;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
`;

const ThumbnailBox = styled.div`
  width: 330px;
  height: 186px;
  position: relative;
  border: 1px solid var(--color-grey-100);
  box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  user-select: none;

  &:hover,
  &:active {
    box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
      0px 16px 32px rgba(22, 34, 51, 0.16);
  }
`;

const OptionButton = styled.button`
  background-color: rgba(51, 51, 51, 0.8);
  border-radius: 6px;
  padding: 4px;
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
`;

const OptionDropdown = styled.ul`
  list-style: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-100);
  border-radius: 10px;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  padding: 8px 0;

  width: 88px;
  height: 80px;
  position: absolute;
  top: 32px;
  right: -24px;
`;

const ProjectNameModifyOption = styled.li`
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;

  color: var(--color-black);

  &:hover,
  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;
  }
`;

const ProjectDeleteOption = styled.li`
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;

  color: var(--color-danger);

  &:hover,
  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;
  }
`;

const Overlay = styled(LogoOverlayIcon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ThumbnailImage = styled.img`
  background-color: var(--color-white);
  border-radius: inherit;
`;

const StatusBox = styled.div`
  margin-top: 4px;
`;

const DateText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-grey-700);
`;
