export default function getLuminance({
  r,
  g,
  b,
}: {
  r: number;
  g: number;
  b: number;
}): number {
  const arr = [r, g, b].map((value) => {
    const v = value / 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.0055) / 1.055, 2.4);
  });

  return arr[0] * 0.2126 + arr[1] * 0.7152 + arr[2] * 0.0722;
}
