import * as React from 'react';
import styled from '@emotion/styled';
import { Toast } from 'components/common';
import { typography } from 'styles';
import GoRegisterButton from './GoRegisterButton';
import type { FeatureContentProps } from './FeatureContent';

const FeatureContent: React.FC<FeatureContentProps> = ({
  tag,
  title,
  body,
  register,
  stepNumber,
}) => {
  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  return (
    <Summary>
      <StepNumber>{stepNumber}</StepNumber>
      <FeatureTag>{tag}</FeatureTag>
      <StepTitle>{title}</StepTitle>
      <StepBody>{body}</StepBody>
      <GoRegisterButton
        onClick={(e) => {
        }}
      >
        {register}
      </GoRegisterButton>
      <Toast
        in={openToast}
        onClose={toggleOpenToast}
        timeout={1500}
        message={'Coming soon'}
        severity={'normal'}
      />
    </Summary>
  );
};

export default FeatureContent;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const StepNumber = styled.span`
  font-style: italic;
  ${typography.display.large}
  color: var(--color-black);
  margin-bottom: 8px;
`;

const FeatureTag = styled.span`
  margin-bottom: 8px;
  ${typography.title.small}
  color: var(--color-pink);
  padding: 2px 16px;
  border: 2px solid var(--color-pink);
  border-radius: 20px;
`;

const StepTitle = styled.span`
  margin-bottom: 8px;
  color: var(--color-black);
  ${typography.title.large}

  @media (min-width: 820px) {
    font-size: 48px;
  }
`;

const StepBody = styled.span`
  margin-bottom: 12px;
  color: var(--color-grey-800);
  white-space: pre;
  ${typography.body.large}
`;
