import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from 'react-click-away-listener';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { Toast } from 'components/common';
import {
  selectProjectIsExist,
  selectModified,
  selectProjectUseAnySTV,
  selectNetworkState,
  selectPagesContent,
} from 'features/editor/editorSlice';
import VideoHQ from './DownloadButton.VideoHQ';
import AudioWAV from './DownloadButton.AudioWAV';
import AudioZIP from './DownloadButton.AudioZIP';
import SubtitleTXT from './DownloadButton.SubtitleTXT';
import SubtitleSRT from './DownloadButton.SubtitleSRT';
import Chromakey from './DownloadButton.Chromakey';

export interface DownloadButtonProps {
  children?: React.ReactNode;
}

const DownloadButton: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'downloadButton' });

  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  const projectIsExist = useAppSelector(selectProjectIsExist);
  const projectModified = useAppSelector(selectModified);

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const toastMessage = React.useRef<string>('');

  const networkState = useAppSelector(selectNetworkState);

  const pagesContent = useAppSelector(selectPagesContent);
  let cannotMerge = pagesContent.reduce<boolean>((acc, item) => {
    if (item.isVideo) return acc;
    if (acc) return true;

    if (item.cells.length === 0 && item.duration < 500) return true;
    if (item.cells.length > 0) {
      const noText = item.cells.filter((el) => el.text !== '').length === 0;
      if (noText) return true;
    }

    return acc;
  }, false);

  const handleClick = () => {
    if (networkState === 'save') return;

    if (cannotMerge) {
      toastMessage.current = t('toast.cannotMerge');
      toggleOpenToast();
      return;
    }

    if (!projectIsExist || projectModified) {
      toastMessage.current = t('toast.modifiedProject');
      toggleOpenToast();
      return;
    }

    toggleOpen();
  };

  const projectUseSTV = useAppSelector(selectProjectUseAnySTV);

  const { isDownloadAudioZIP } = useGetUserInfoQuery(undefined, {
    selectFromResult: ({ data }) => {
      const ret = {
        isDownloadAudioZIP: true,
      };

      if (['Free', 'Free_beta'].includes(data?.billingName ?? '')) {
        ret.isDownloadAudioZIP = false;
        return ret;
      }

      ret.isDownloadAudioZIP = true;
      return ret;
    },
  });

  return (
    <>
      <Button onClick={handleClick}>
        {t('text.default')}
        {open && (
          <ClickAwayListener onClickAway={toggleOpen}>
            <OptionBox>
              <VideoHQ>{t('text.video')}</VideoHQ>
              <AudioWAV>{t('text.audioWAV')}</AudioWAV>
              {isDownloadAudioZIP && <AudioZIP>{t('text.audioZIP')}</AudioZIP>}
              <SubtitleTXT>{t('text.subtitleTXT')}</SubtitleTXT>
              <SubtitleSRT>{t('text.subtitleSRT')}</SubtitleSRT>
              {projectUseSTV && <Chromakey>{t('text.chromakey')}</Chromakey>}
            </OptionBox>
          </ClickAwayListener>
        )}
      </Button>
      <Toast
        in={openToast}
        message={toastMessage.current}
        severity={'error'}
        timeout={2500}
        onClose={toggleOpenToast}
      />
    </>
  );
};

export default DownloadButton;

const Button = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  outline: none;
  padding: 0 16px;
  border: none;

  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;

  position: relative;
  z-index: 1;

  color: var(--color-white);
  background: var(--color-pink);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;

const OptionBox = styled.ul`
  list-style: none;
  margin-block: 0;
  padding-inline: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 48px);
  min-width: 100px;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-50);
  padding: 8px 0;
`;
