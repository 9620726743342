import * as React from 'react';
import { useId } from 'lib/hooks';

const CheckCircleGradientIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  const id = useId();
  const gradientId = `check-circle-gradient-${id}`;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={80}
      height={80}
      viewBox='0 0 80 80'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M56.463 29.91a3.75 3.75 0 01-.124 5.303l-18.333 17.5a3.75 3.75 0 01-5.179 0l-9.166-8.75a3.75 3.75 0 015.178-5.426l6.578 6.279L51.16 29.787a3.75 3.75 0 015.302.124z'
        fill={`url(#${gradientId})`}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40 13.75c-14.497 0-26.25 11.753-26.25 26.25 0 14.498 11.753 26.25 26.25 26.25 14.498 0 26.25-11.752 26.25-26.25 0-14.497-11.752-26.25-26.25-26.25zM6.25 40C6.25 21.36 21.36 6.25 40 6.25S73.75 21.36 73.75 40 58.64 73.75 40 73.75 6.25 58.64 6.25 40z'
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1={8.57}
          y1={15.391}
          x2={75.836}
          y2={21.011}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF376E' />
          <stop offset={1} stopColor='#6587FF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CheckCircleGradientIcon;
