import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useRegisterEmailMutation } from 'app/services/auth';
import { typography } from 'styles';
import OutlineButton from './OutlineButton';

interface SuccessState {
  email: string;
}

const isSuccessState = (state: any): state is SuccessState => {
  if (state === null) return false;
  if (typeof state.email !== 'string') return false;
  return true;
};

const RegisterVerificationSuccess: React.FC = () => {
  const { t } = useTranslation('auth', { keyPrefix: 'emailRegister' });

  const location = useLocation();
  const email = isSuccessState(location.state)
    ? location.state.email
    : undefined;

  const [requestRegisterEmail] = useRegisterEmailMutation();

  const handleClickEmailResend: React.MouseEventHandler<HTMLButtonElement> =
    async () => {
      if (email === undefined) return;

      try {
        await requestRegisterEmail({ email }).unwrap();
      } catch (error) {
        console.error(error);
      }
    };

  return (
    <Base>
      <Title>{t('title')}</Title>
      <Subtitle>{t('sendSubtitle', { email })}</Subtitle>
      <OutlineButton onClick={handleClickEmailResend}>
        {t('button.resendEmail')}
      </OutlineButton>
    </Base>
  );
};

export default RegisterVerificationSuccess;

const Base = styled.div`
  width: 100%;
`;

const Title = styled.p`
  ${typography.display.small}
  text-align: center;
  margin: 0;
`;

const Subtitle = styled.span`
  width: 100%;
  display: inline-block;
  ${typography.body.large}
  margin: 0;
  padding-top: 16px;
  padding-bottom: 32px;
  text-align: center;
  white-space: pre;
`;
