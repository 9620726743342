import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';

const NoPaymentLog: React.FC = () => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.paymentLog.empty',
  });

  const navigate = useNavigate();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    navigate('/client/membership?tab=product', { replace: false });
  };

  return (
    <Base>
      <Text>{t('title')}</Text>
      <Text>{t('subTitle')}</Text>
      <PinkButton onClick={handleClick}>
        <span>{t('button')}</span>
      </PinkButton>
    </Base>
  );
};

export default NoPaymentLog;

const Base = styled.div`
  margin-top: 32px;
`;

const Text = styled.span`
  display: block;
  ${typography.body.large}
  color: var(--color-black);

  & + & {
    margin-top: 16px;
  }
`;

const PinkButton = styled.button`
  margin-top: 16px;
  border: none;
  background-color: var(--color-pink);
  border-radius: 10px;
  height: 48px;
  max-width: 368px;
  width: 100%;

  span {
    ${typography.title.medium}
    color: var(--color-white);
  }
`;
