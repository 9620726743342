import * as React from 'react';
import styled from '@emotion/styled';
import { IconButton } from 'components/common';
import { DotsSixVerticalIcon } from 'assets/svg';
import CellPlayButton from './CellPlayButton';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';

interface CellControlBoxProps {
  cellKey: string;
  listeners: DraggableSyntheticListeners;
}

const CellControlBox: React.FC<CellControlBoxProps> = ({
  cellKey,
  listeners,
}) => {
  return (
    <Base onClick={(e) => e.stopPropagation()}>
      <IconButton size={20} radius={4} {...listeners}>
        <DotsSixVerticalIcon size={20} color={'currentColor'} weight={'bold'} />
      </IconButton>
      <CellPlayButton cellKey={cellKey} />
    </Base>
  );
};

export default CellControlBox;

const Base = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    color: var(--color-grey-700);
  }

  button:first-child {
    cursor: grab;
  }
`;
