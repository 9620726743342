import * as React from 'react';
import styled from '@emotion/styled';
import { typography } from 'styles';

interface ArticleCardProps {
  imageUrl: string;
  title: string;
  type: string;
  href: string;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  imageUrl,
  title,
  type,
  href,
}) => {
  return (
    <Anchor href={href} target={'_blank'} rel={'noreferrer'}>
      <Base>
        <Image src={imageUrl} alt={title} />
        <Content>
          <Title>{title}</Title>
          <Type>{type}</Type>
        </Content>
      </Base>
    </Anchor>
  );
};

export default ArticleCard;

const Anchor = styled.a`
  text-decoration: none;
`;

const Base = styled.div`
  border: 1px solid var(--color-grey-100);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.16);

  width: 288px;
  height: 340px;

  @media (min-width: 820px) {
    width: 352px;
    height: 386px;
  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.32);

    @media (hover: none) {
      box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.16);
    }
  }
`;

const Image = styled.img`
  width: 288px;
  height: 214px;
  object-fit: cover;

  @media (min-width: 820px) {
    width: 352px;
    height: 262px;
  }
`;

const Content = styled.div`
  padding: 20px 16px 24px;
  height: 124px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.span`
  ${typography.title.medium}
  color: var(--color-black);
`;

const Type = styled.span`
  ${typography.body.large}
  color: var(--color-grey-800);
`;
