import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { Dialog } from '@headlessui/react';
import { css } from '@emotion/css';
import { XIcon } from 'assets/svg';
import { typography } from 'styles';

interface InactiveUserDialogProps {
  open: boolean;
  onClose: () => void;
}

const InactiveUserDialog: React.FC<InactiveUserDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation('auth', { keyPrefix: 'inactive' });

  return (
    <Dialog open={open} onClose={() => {}} className={dialog}>
      <Dialog.Overlay className={overlay} />
      <Base>
        <Title>
          <CloseButton onClick={onClose}>
            <XIcon size={24} weight={'bold'} color={'currentColor'} />
          </CloseButton>
        </Title>
        <Content>
          <DescriptionTitle>{t('title')}</DescriptionTitle>
          <Description>
            <Trans
              t={t}
              i18nKey={'body'}
              values={{
                contact: t('email'),
              }}
              components={[<Anchor href={`mailto:${t('email')}`} />]}
            />
          </Description>
        </Content>
        <Actions>
          <SubmitButton onClick={onClose}>{t('button')}</SubmitButton>
        </Actions>
      </Base>
    </Dialog>
  );
};

export default InactiveUserDialog;

const dialog = css`
  position: fixed;
  z-index: var(--popup-index);
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const overlay = css`
  position: fixed;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(51, 51, 51, 0.8);
  -webkit-tap-highlight-color: transparent;
`;

const Base = styled.div`
  width: 440px;
  height: 326px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 24px;
  border-radius: 10px;
  background: var(--color-white);
`;

const Title = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-black);
`;

const CloseButton = styled.button`
  outline: 0;
  border: 0;
  padding: 0;
  background: var(--color-white);
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--color-grey-200);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: var(--color-grey-400);
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DescriptionTitle = styled.span`
  ${typography.title.medium}
  color: var(--color-black);
`;

const Description = styled.span`
  ${typography.body.large}
  color: var(--color-black);
  word-break: keep-all;
`;

const Actions = styled.div`
  width: 100%;
`;

const SubmitButton = styled.button`
  padding: 0;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  color: var(--color-black);
`;

const Anchor = styled.a`
  ${typography.body.large}
  text-decoration: none;
`;
