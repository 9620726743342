import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { loadTossPayments } from '@tosspayments/sdk';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import {
  useGetPaymentInfoQuery,
  useRequestUnsubscribeMutation,
} from 'app/services/membership';
import { ArrowClockwiseIcon } from 'assets/svg';
import { Toast } from 'components/common';
import { typography } from 'styles';
import { formattedPrice, formattedCardNumber, getRemainingDay } from './utils';
import NoPaymentInfo from './NoPaymentInfo';
import PaymentPolicy from './PaymentPolicy';

const PaymentInfo: React.FC = () => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.paymentInfo',
  });

  const navigate = useNavigate();

  const { data: userInfo } = useGetUserInfoQuery();
  const { data: paymentInfo } = useGetPaymentInfoQuery();

  const [
    requestUnsubscribe,
    { isSuccess: unsubscribeSuccess, error: unsubscribeError },
  ] = useRequestUnsubscribeMutation();

  const [isOpenToast, setIsOpenToast] = React.useState<boolean>(false);
  const [toastType, setToastType] = React.useState<'success' | 'error'>(
    'error'
  );
  const [cancelStatus, setCancelStatus] = React.useState<
    'success' | 'already' | 'error'
  >('error');

  React.useEffect(() => {
    if (unsubscribeSuccess) {
      setToastType('success');
      setCancelStatus('success');
      setIsOpenToast(true);
    }
  }, [unsubscribeSuccess]);

  React.useEffect(() => {
    if (unsubscribeError === undefined) return;
    if (!isFetchBaseQueryError(unsubscribeError)) return;

    const { status } = unsubscribeError;

    if (status === 400) {
      setToastType('error');
      setCancelStatus('error');
      setIsOpenToast(true);
    }

    if (status === 403) {
      setToastType('error');
      setCancelStatus('already');
      setIsOpenToast(true);
    }
  }, [unsubscribeError]);

  if (paymentInfo === undefined) return <NoPaymentInfo />;

  const {
    subscribe,
    billingName,
    cardNumber,
    cardCompany,
    paymentStatus,
    billingAmount,
    nextBillingRedeemDate,
  } = paymentInfo;

  const handleClickChange: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.stopPropagation();

    if (userInfo === undefined) return;
    if (userInfo.email === '') return;
    if (userInfo.socialAccountInfo === '') return;

    try {
      const tossPayments = await loadTossPayments(
        process.env.ONAIR_TOSSPAYMENTS_KEY
      );

      const customerKey = btoa(
        `${userInfo.email}:${userInfo.socialAccountInfo}`
      );

      tossPayments.requestBillingAuth('카드', {
        customerKey,
        successUrl: `${window.location.origin}/client/payment/change/success?grade=${billingName}`,
        failUrl: `${window.location.origin}/client/payment/change/failure?grade=${billingName}`,
        customerEmail: userInfo.email,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickCancel: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.stopPropagation();

    try {
      await requestUnsubscribe({
        unsubscribeReason: 'Unsubscribe Reason',
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickRetry: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    navigate(`/client/membership`, { replace: false });
  };

  if (
    paymentStatus === 0 ||
    subscribe === undefined ||
    cardNumber === undefined ||
    cardCompany === undefined ||
    nextBillingRedeemDate === undefined ||
    billingAmount === undefined
  )
    return <NoPaymentInfo />;

  return (
    <Base>
      <CardInfo>
        <Category>{t('paymentMethod')}</Category>
        <Value>
          <span>{formattedCardNumber({ number: cardNumber })}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{cardCompany}</span>
        </Value>
        {paymentStatus === 1 && subscribe === true && (
          <Button onClick={handleClickChange}>
            <span>{t('button.change')}</span>
          </Button>
        )}
        <Divider />
        <Category>{t('statusInfo')}</Category>
        <Value data-status={paymentStatus}>
          {paymentStatus === 0 && t(`status.none`)}
          {paymentStatus === 1 && subscribe === true && t(`status.continue`)}
          {paymentStatus === 1 &&
            subscribe === false &&
            t(`status.once`, {
              remainingDay: getRemainingDay(nextBillingRedeemDate),
            })}
          {paymentStatus === 2 && (
            <>
              {t(`status.error`)}
              <Retry onClick={handleClickRetry}>
                <ArrowClockwiseIcon size={16} />
                <span>{t('button.retry')}</span>
              </Retry>
            </>
          )}
        </Value>
        {paymentStatus === 1 && subscribe === true && (
          <Button onClick={handleClickCancel}>
            <span>{t('button.cancel')}</span>
          </Button>
        )}
        {paymentStatus === 1 && (
          <>
            <Divider />
            <Category>{t('monthlyCharge')}</Category>
            <Value data-fill={true}>
              {t('chargeAmount', {
                price: formattedPrice({ price: billingAmount }),
              })}
            </Value>
          </>
        )}
        {paymentStatus === 1 && subscribe === true && (
          <>
            <Divider />
            <Category>{t('nextPaymentDate')}</Category>
            <Value data-fill={true}>
              {new Date(nextBillingRedeemDate).toLocaleDateString()}
            </Value>
          </>
        )}
      </CardInfo>
      <Toast
        message={t(`toast.${cancelStatus}`)}
        severity={toastType}
        in={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        timeout={1500}
      />
      <PaymentPolicy />
    </Base>
  );
};

export default PaymentInfo;

const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
  if (typeof error.status === undefined) return false;
  if (error.status === 'FETCH_ERROR') return true;
  if (error.status === 'PARSING_ERROR') return true;
  if (error.status === 'CUSTOM_ERROR') return true;
  if (typeof error.status === 'number') return true;
  return false;
};

const Base = styled.div`
  margin-top: 16px;
`;

const CardInfo = styled.div`
  max-width: 600px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-row-gap: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;

  @media (min-width: 520px) {
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 16px;
    margin-bottom: 120px;
  }
`;

const Category = styled.div`
  display: flex;
  align-items: center;
  grid-column-end: span 1;
  min-height: 40px;

  ${typography.body.large}
  color: var(--color-black);
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  grid-column-end: span 2;
  min-height: 40px;
  margin: 0 8px;

  ${typography.body.large}
  color: var(--color-black);

  @media (min-width: 520px) {
    grid-column-end: span 3;
    margin: 0 16px;
  }

  /* for payment status */
  &[data-status='0'] {
    color: var(--color-grey-700);
  }
  &[data-status='1'] {
    color: var(--color-success);
  }
  &[data-status='2'] {
    color: var(--color-danger);
  }

  /* for value text grid */
  &[data-fill='true'] {
    grid-column-end: span 3;

    @media (min-width: 520px) {
      grid-column-end: span 4;
    }
  }
`;

const Retry = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  padding: 4px;
  margin: 0;
  border: none;
  background: transparent;
  color: var(--color-blue);

  user-select: none;
  cursor: pointer;

  span {
    margin-left: 4px;
    ${typography.body.medium}
  }

  @media (min-width: 520px) {
    margin-left: 16px;
  }
`;

const Divider = styled.div`
  grid-area: 'divider';
  grid-column-end: span 5;
  height: 1px;
  background: var(--color-grey-200);
`;

const Button = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  grid-area: 'button';
  grid-column-end: span 1;
  min-height: 40px;
  max-width: 120px;
  padding: 8px 16px;
  border: 1px solid var(--color-grey-500);
  border-radius: 10px;
  background: var(--color-white);

  user-select: none;
  cursor: pointer;

  span {
    ${typography.body.medium}
    color: var(--color-black);
  }

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }

  @media (min-width: 520px) {
    padding: 10px 30px;
  }
`;
