import { MEMBERSHIP, membershipTabs, grades } from './constants';
import type {
  MembershipTabType,
  GradeType,
  GradeObjectType,
} from './constants';

export const isMembershipTabType = (s: any): s is MembershipTabType => {
  if (typeof s !== 'string') return false;
  return membershipTabs.indexOf(s as MembershipTabType) !== -1;
};

export const isGradeType = (s: any): s is GradeType => {
  if (typeof s !== 'string') return false;
  return grades.indexOf(s as GradeType) !== -1;
};

export const getGradeInfo = (grade: string | undefined): GradeObjectType => {
  if (grade === undefined) return MEMBERSHIP[0];

  const data = MEMBERSHIP.find((obj) => obj.grade === grade);
  if (data === undefined) return MEMBERSHIP[0];
  return data;
};

export const formattedPrice = ({
  price = 0,
  discount,
}: {
  price: number | string;
  discount?: number;
}) => {
  if (typeof price === 'string') price = parseFloat(price);

  if (isNaN(price)) return ' - ';

  if (discount !== undefined) {
    price = price * ((100 - discount) / 100);
  }

  return price
    .toString()
    .split('')
    .reverse()
    .reduce<string[]>((acc, item, idx, originArr) => {
      if ((idx + 1) % 3 === 0) {
        if (originArr.length === idx + 1) {
          acc.push(item);
        } else {
          acc.push(item, ',');
        }
      } else {
        acc.push(item);
      }

      return acc;
    }, [])
    .reverse()
    .join('');
};

export const formattedCardNumber = ({ number }: { number: string }) => {
  if (number.length > 16) return number;

  return number
    .split('')
    .reduce<string[]>((acc, item, idx, originArr) => {
      if ((idx + 1) % 4 === 0) {
        if (originArr.length === idx + 1) {
          acc.push(item);
        } else {
          acc.push(item, '-');
        }
      } else {
        acc.push(item);
      }

      return acc;
    }, [])
    .join('');
};

export const formattedDurationDate = ({ date }: { date: string }) => {
  return date.replace(/~/gi, ' ~ ');
};

export const getRemainingDay = (datetime: string): number => {
  const time = new Date(datetime).getTime();
  const now = Date.now();

  const deltaTime = (time - now) / 24 / 60 / 60 / 1000;

  return Math.ceil(deltaTime);
};
