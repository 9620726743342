import { createApi } from '@reduxjs/toolkit/query/react';
import { recordTransformer, camelCase } from 'lib/utils';

import queryFetcher from './queryFetcher';
import type { ObjectToCamel } from 'lib/utils';
import type { Code } from 'locales/utils';

interface RawUserInfo {
  uid: string;
  email: string;
  username: string;
  social_account_info: string;
  marketing_accept: boolean;
  cell_limit_num: number;
  page_limit_num: number;
  project_limit_num: number;
  disable_watermark: boolean;
  audio_download: boolean;
  use_billing_service: boolean;
  language: Code;
  nation?: string;
  allowed_duration: number;
  used_duration: number;
  billing_name: string;
  total_video_size_limit: number;
  synth_limit_num: number;
}

interface RawUpdateUserInfoRequest {
  nation?: string;
  language?: string;
  marketing_accept: boolean;
}

export type UserInfo = ObjectToCamel<RawUserInfo>;

export type UpdateUserInfoRequest = ObjectToCamel<RawUpdateUserInfoRequest>;

export const api = createApi({
  reducerPath: 'services/userInfo',
  baseQuery: queryFetcher('/v1'),
  tagTypes: ['UserInfo'],
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserInfo, void>({
      query: () => ({
        url: '/user/info',
        method: 'GET',
      }),
      providesTags: [{ type: 'UserInfo' }],
      transformResponse: (resp: RawUserInfo) => {
        const data = recordTransformer(resp, camelCase) as UserInfo;
        return data;
      },
    }),
    updateUserInfo: builder.mutation<void, UpdateUserInfoRequest>({
      query: ({ nation, language, marketingAccept }) => ({
        url: '/user/info',
        method: 'PUT',
        body: {
          nation,
          language,
          marketing_accept: marketingAccept,
        },
      }),
      async onQueryStarted(
        { nation, language, marketingAccept },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          api.util.updateQueryData('getUserInfo', undefined, (draft) => {
            const patch = {
              ...draft,
              nation: nation ?? draft.nation,
              language: language ?? draft.language,
              marketingAccept: marketingAccept ?? draft.marketingAccept,
            };
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    updatePassword: builder.mutation<
      void,
      {
        oldPassword: string;
        newPassword: string;
      }
    >({
      query: ({ oldPassword, newPassword }) => ({
        url: '/auth/update-password',
        method: 'POST',
        body: {
          old_password: oldPassword,
          new_password: newPassword,
        },
      }),
    }),
    requestWithdrawal: builder.mutation<void, string>({
      query: (reason) => ({
        url: '/user/delete',
        method: 'POST',
        body: {
          member_withdrawal_reason: reason,
        },
      }),
      invalidatesTags: [{ type: 'UserInfo' }],
    }),
    requestRedeemEventCode: builder.mutation<void, string>({
      query: (code) => ({
        url: '/event',
        method: 'POST',
        body: {
          event_code: code,
        },
      }),
      invalidatesTags: [{ type: 'UserInfo' }],
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useUpdateUserInfoMutation,
  useUpdatePasswordMutation,
  useRequestWithdrawalMutation,
  useRequestRedeemEventCodeMutation,
} = api;

export default api;
