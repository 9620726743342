import * as React from 'react';
import styled from '@emotion/styled';
import {
  useGetVideosQuery,
  useDeleteVideoMutation,
} from 'app/services/videoAssets';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  modifyPageData,
  selectCurrentPageKey,
  deleteVideoAsset,
} from 'features/editor/editorSlice';
import VideoListItemMenu from './VideoListItemMenu';
import VideoNameInput from './VideoNameInput';

interface VideoListItemProps {
  videoName: string;
  index: number;
}

const VideoListItem: React.FC<VideoListItemProps> = ({ videoName, index }) => {
  const { video } = useGetVideosQuery(undefined, {
    selectFromResult: ({ data }) => ({
      video: data?.find((el) => el.name === videoName),
    }),
  });

  const [isHover, setIsHover] = React.useState<boolean>(false);

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const toggleEdit = () => setIsEdit((prev) => !prev);

  const dispatch = useAppDispatch();

  const handleClickModify = () => {
    if (isEdit) return;
    setIsEdit(true);
  };

  const [deleteVideo] = useDeleteVideoMutation();

  const handleClickDelete = async () => {
    if (video === undefined) return;
    try {
      await deleteVideo({ name: video.name }).unwrap();
      dispatch(deleteVideoAsset({ video }));
    } catch (error) {
      console.error(error);
    }
  };

  const currentPageKey = useAppSelector(selectCurrentPageKey);

  const handleClickItem: React.MouseEventHandler<HTMLDivElement> = () => {
    if (video === undefined) return;
    if (currentPageKey === undefined) return;

    dispatch(
      modifyPageData({
        pageKey: currentPageKey,
        thumbnailUrl: video.thumbS3Path.url,
        thumbnailPath: video.thumbS3Path.path,
        videoUrl: video.videoS3Path.url,
        videoPath: video.videoS3Path.path,
      })
    );
  };

  if (video === undefined) return null;
  return (
    <ListItem>
      <AspectBox
        onClick={handleClickItem}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <Image src={video.thumbS3Path.url} />
        {isHover && (
          <VideoListItemMenu
            onClickModify={handleClickModify}
            onClickDelete={handleClickDelete}
          />
        )}
      </AspectBox>
      <VideoNameInput
        name={videoName}
        displayName={video.displayName}
        isEdit={isEdit}
        toggleEdit={toggleEdit}
      />
    </ListItem>
  );
};

export default VideoListItem;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

const AspectBox = styled.div`
  aspect-ratio: 16 / 9;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 10px;
`;
