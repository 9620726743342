import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';
import OutlineButton from './OutlineButton';

const ResetPasswordActivateSuccess: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation('auth', { keyPrefix: 'resetPasswordActive' });

  const goLogin = () => navigate('/auth/login', { replace: true });

  return (
    <Base>
      <Contents>
        <Title>{t('confirm.title')}</Title>
        <Description>{t('confirm.description')}</Description>
        <ButtonBox>
          <OutlineButton onClick={goLogin}>
            {t('confirm.goLogin')}
          </OutlineButton>
        </ButtonBox>
      </Contents>
    </Base>
  );
};

export default ResetPasswordActivateSuccess;

const Base = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Contents = styled.div`
  max-width: 440px;
  margin-top: 40px;
  margin-bottom: 40px;
  height: fit-content;
  padding: 24px;

  @media (min-width: 520px) {
    max-width: auto;
    margin-top: 60px;
  }
`;

const Title = styled.div`
  ${typography.title.large}
  color: var(--color-black);
  text-align: center;
`;

const Description = styled.div`
  margin-top: 16px;
  ${typography.body.large}
  color: var(--color-black);
`;

const ButtonBox = styled.div`
  margin-top: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;
