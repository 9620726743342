import * as React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { useSocialLoginMutation } from 'app/services/auth';
import { isFetchBaseQueryError } from 'components/auth/utils';
import { setCredentials } from './authSlice';
import InactiveUserDialog from './InactiveUserDialog';

interface GoogleAuthButtonProps {
  children?: React.ReactNode;
}

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({ children }) => {
  const [inactiveIsOpen, setInactiveIsOpen] = React.useState<boolean>(false);

  const toggleInactiveIsOpen = React.useCallback(() => {
    setInactiveIsOpen((prev) => !prev);
  }, []);

  const dispatch = useAppDispatch();

  const [requestSocialLogin, { data: socialLoginData, isSuccess, error }] =
    useSocialLoginMutation();

  React.useEffect(() => {
    if (!isSuccess) return;
    if (socialLoginData === undefined) return;

    dispatch(
      setCredentials({
        token: socialLoginData.access,
        loginTime: Date.now(),
      })
    );
  }, [dispatch, isSuccess, socialLoginData]);

  React.useEffect(() => {
    if (error === undefined) return;
    if (!isFetchBaseQueryError(error)) return;
    const { status } = error;

    if (status === 400) {
      // TODO: 400 에러에 대한 UX 없음
    }

    if (status === 403) {
      toggleInactiveIsOpen();
    }

    console.error(error);
  }, [error, toggleInactiveIsOpen]);

  return (
    <>
      <Box>
        <GoogleLogin
          onSuccess={(resp) => {
            if (resp.credential === undefined) return;

            requestSocialLogin({
              provider: 'google',
              token: resp.credential,
            });
          }}
          size={'large'}
          text={'continue_with'}
          theme={'filled_black'}
        />
      </Box>
      <InactiveUserDialog
        open={inactiveIsOpen}
        onClose={toggleInactiveIsOpen}
      />
    </>
  );
};

export default GoogleAuthButton;

const Box = styled.div`
  width: 100%;
  background-color: #202124;
  padding: 4px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: fit-content;
  }
`;
