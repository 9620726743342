type RawFetchResponse = {
  image: {
    tmp_path: string;
    url: string;
  };
  thumb: {
    tmp_path: string;
    url: string;
  };
  cells: {
    text: string;
    display_text: string;
    duration: number;
  }[];
  word_book: Record<string, string | null>;
}[];

type FetchResponse = {
  image: {
    tmpPath: string;
    url: string;
  };
  thumb: {
    tmpPath: string;
    url: string;
  };
  cells: {
    text: string;
    displayText: string;
    duration: number;
  }[];
  wordBook: Record<string, string | null>;
}[];

export default async function fetchExtractFileInfo(url: string) {
  const response = await fetch(url);
  if (!response.ok) throw new Error('fetch error file parsed data');
  const data: RawFetchResponse = await response.json();

  const ret: FetchResponse = data.map((el) => ({
    image: {
      tmpPath: el.image.tmp_path,
      url: el.image.url,
    },
    thumb: {
      tmpPath: el.thumb.tmp_path,
      url: el.thumb.url,
    },
    cells: el.cells.map((cell) => ({
      text: cell.text,
      displayText: cell.display_text,
      duration: cell.duration,
    })),
    wordBook: el.word_book,
  }));

  return ret;
}
