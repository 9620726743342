import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

interface CellTextInputProps {
  text: string;
  onChange: (s: string) => void;
  onKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
}

const CellTextInput: React.FC<CellTextInputProps> = ({
  text,
  onChange,
  onKeyUp,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    const { selectionStart, selectionEnd, value } = e.currentTarget;

    if (selectionStart === null || selectionEnd === null) return;

    const clipboardText = e.clipboardData.getData('text');

    const temp = [
      value.slice(0, selectionStart),
      clipboardText,
      value.slice(selectionEnd),
    ].join('');

    const newText = temp.length > 150 ? temp.slice(0, 150) : temp;
    onChange(newText);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    if (value.length > 150) return;

    onChange(value);
  };

  return (
    <Base>
      <Input
        placeholder={t('placeholder.cellText')}
        value={text}
        maxLength={150}
        onPaste={handlePaste}
        onChange={handleChange}
        onKeyUp={onKeyUp}
      />
      <TextLength isError={text.length > 150}>{text.length}/150</TextLength>
    </Base>
  );
};

export default CellTextInput;

const Base = styled.div`
  background: var(--color-white);
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: calc(100% - 54px);
  height: 40px;
  border: none;
  outline: none;
  padding: 8px;
  color: var(--color-black);
  background: transparent;

  &::placeholder {
    color: var(--color-grey-700);
  }
`;

const TextLength = styled.span<{ isError: boolean }>`
  margin-left: auto;
  margin-right: 4px;
  font-size: 12px;
  color: ${({ isError }) =>
    isError ? 'var(--color-danger)' : 'var(--color-grey-600)'};
  line-height: 1.4;
  user-select: none;
`;
