import * as React from 'react';
import { useTranslation } from 'react-i18next';
import constate from 'constate';
import type { ProjectMetadata } from 'app/services/projects';

type SortType = 'created' | 'modified' | 'name';

interface SortItem {
  key: SortType;
  text: string;
  handleClick: () => void;
}

function useProjectList() {
  const { t } = useTranslation('client', { keyPrefix: 'projectList' });

  const [sortType, setSortType] = React.useState<SortType>('created');

  const sortItems: SortItem[] = React.useMemo(
    () => [
      {
        key: 'modified',
        text: t('order.modify'),
        handleClick() {
          setSortType('modified');
        },
      },
      {
        key: 'created',
        text: t('order.create'),
        handleClick() {
          setSortType('created');
        },
      },
      {
        key: 'name',
        text: t('order.name'),
        handleClick() {
          setSortType('name');
        },
      },
    ],
    [t]
  );

  const sortText = React.useMemo(() => {
    const item = sortItems.find((el) => el.key === sortType);

    if (item === undefined) return '';
    return item.text;
  }, [sortItems, sortType]);

  const sortFn = React.useCallback(
    (prev: ProjectMetadata, next: ProjectMetadata) => {
      switch (sortType) {
        case 'created': {
          const prevDate = new Date(prev.createDate);
          const nextDate = new Date(next.createDate);

          return nextDate.getTime() - prevDate.getTime();
        }
        case 'modified': {
          const prevDate = new Date(prev.updateDate);
          const nextDate = new Date(next.updateDate);

          return nextDate.getTime() - prevDate.getTime();
        }
        case 'name': {
          return prev.projectName.localeCompare(next.projectName);
        }
        default: {
          return 0;
        }
      }
    },
    [sortType]
  );

  const [deleteProjectUuid, setDeleteProjectUuid] = React.useState<string>('');
  const [openDeleteProject, setOpenDeleteProject] =
    React.useState<boolean>(false);

  const openDeleteProjectDialog = (uuid: string) => {
    setDeleteProjectUuid(uuid);
    setOpenDeleteProject(true);
  };

  const closeDeleteProjectDialog = () => {
    setDeleteProjectUuid('');
    setOpenDeleteProject(false);
  };

  return {
    sortType,
    sortItems,
    sortText,
    sortFn,
    deleteProjectUuid,
    openDeleteProject,
    openDeleteProjectDialog,
    closeDeleteProjectDialog,
  };
}

export const [
  ProjectListProvider,
  useSortType,
  useSortItems,
  useSortText,
  useSortFn,
  useDeleteProjectUuid,
  useOpenDeleteProject,
  useOpenDeleteProjectDialog,
  useCloseDeleteProjectDialog,
] = constate(
  useProjectList,
  (state) => state.sortType,
  (state) => state.sortItems,
  (state) => state.sortText,
  (state) => state.sortFn,
  (state) => state.deleteProjectUuid,
  (state) => state.openDeleteProject,
  (state) => state.openDeleteProjectDialog,
  (state) => state.closeDeleteProjectDialog
);
