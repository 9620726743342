import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  ResetPasswordTemplate,
  ResetPassword,
  ResetPasswordConfirm,
  ResetPasswordActivate,
  ResetPasswordActivateSuccess,
  ResetPasswordActivateTokenError,
} from 'components/auth';

const ResetPasswordPage: React.FC = () => {
  return (
    <>
      <ResetPasswordTemplate>
        <Routes>
          <Route index element={<ResetPassword />} />
          <Route path={'confirm'} element={<ResetPasswordConfirm />} />
          <Route path={'activate'} element={<ResetPasswordActivate />} />
          <Route
            path={'activate/success'}
            element={<ResetPasswordActivateSuccess />}
          />
          <Route
            path={'activate/token-error'}
            element={<ResetPasswordActivateTokenError />}
          />
          <Route
            path={'*'}
            element={<Navigate to={'/auth/reset-password'} replace />}
          />
        </Routes>
      </ResetPasswordTemplate>
    </>
  );
};

export default ResetPasswordPage;
