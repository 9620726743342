import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { typography } from 'styles';
import GradeCardOptions from './GradeCardOptions';
import ProductUseButton from './ProductUseButton';
import { getGradeInfo, formattedPrice } from './utils';
import type { GradeType } from './constants';

interface GradeCardProps {
  cardGrade: GradeType;
}

const GradeCard: React.FC<GradeCardProps> = ({ cardGrade }) => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.productInfo.card',
  });

  const { data } = useGetUserInfoQuery();

  const userMembership = getGradeInfo(data?.billingName);
  const { grade: userGrade } = userMembership;

  const cardMembership = getGradeInfo(cardGrade);
  const { grade, discount, price, options } = cardMembership;
  const isUsed = cardGrade === userGrade;

  const getCurrentPrice = () => {
    return t('price.value', {
      price: formattedPrice({ price, discount: discount?.value }),
    });
  };

  const currentPrice = getCurrentPrice();

  return (
    <Base data-used={isUsed}>
      <TitleBox>
        {discount !== undefined && (
          <Discount data-color={discount.color}>
            {t('discount', { discount: discount.value })}
          </Discount>
        )}
        <Title>{grade}</Title>
        <Price>{currentPrice}</Price>
        {discount !== undefined && (
          <BasePrice data-color={discount.color}>
            {t('price.value', { price: formattedPrice({ price }) })}
          </BasePrice>
        )}
      </TitleBox>
      <GradeCardOptions
        download={options.download}
        project={options.project}
        voice={options.voice}
        stv={options.stv}
        removeWatermark={options.removeWatermark}
        commercialUse={options.commercialUse}
        customizing={options.customizing}
        video={options.video}
      />
      <ProductUseButton cardGrade={cardGrade} isUsed={isUsed} />
    </Base>
  );
};

export default GradeCard;

const Base = styled.div`
  width: 100%;
  height: 100%;
  min-height: 492px;
  padding: 16px;

  background-color: var(--color-white);
  border: 1px solid var(--color-grey-100);
  border-radius: 10px;
  box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08);

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover:not([data-used='true']) {
    padding: 15px;
    border: 2px solid var(--color-pink);
    box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
      0px 16px 32px rgba(22, 34, 51, 0.16);
  }

  &:active:not([data-used='true']) {
    padding: 15px;
    border: 2px solid var(--color-pink);
    box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
      0px 16px 32px rgba(22, 34, 51, 0.16);
  }
`;

const TitleBox = styled.div`
  flex: none;
  width: 100%;
  min-height: 146px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Discount = styled.div`
  width: fit-content;
  padding: 8px 16px;
  border-radius: 10px;
  ${typography.label.large}

  &[data-color='pink'] {
    color: var(--color-pink);
    background: var(--color-pink-50);
  }
  &[data-color='orange'] {
    color: var(--color-orange);
    background: var(--color-orange-50);
  }
`;

const Title = styled.div`
  ${typography.body.large}
  margin-top: 10px;
  color: var(--color-black);
`;

const Price = styled.div`
  margin-top: 8px;
  ${typography.headline.large}
  color: var(--color-black);
`;

const BasePrice = styled.div`
  margin-top: 8px;
  ${typography.headline.small}
  text-decoration: line-through;

  &[data-color='pink'] {
    color: var(--color-pink);
  }
  &[data-color='orange'] {
    color: var(--color-orange);
  }
`;
