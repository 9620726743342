import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ActivityIcon } from 'assets/svg';
import { Toast } from 'components/common';
import { typography } from 'styles';

const LastSection: React.FC = () => {
  const { t } = useTranslation('main', { keyPrefix: 'last' });
  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  return (
    <Base>
      <Box>
        <Title>{t('title')}</Title>
        <RegisterAnchor to={'/auth/register'}>
          <ActivityIcon size={24} color={'currentColor'} weight={'bold'} />
          <span>{t('register')}</span>
        </RegisterAnchor>
        <Toast
          in={openToast}
          onClose={toggleOpenToast}
          timeout={1500}
          message={'Coming soon'}
          severity={'normal'}
        />
      </Box>
    </Base>
  );
};

export default LastSection;

const Base = styled.section`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  background: var(--color-gradient);

  @media (min-width: 820px) {
    padding-top: 56px;
    padding-bottom: 56px;
  }
`;

const Box = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);

  @media (min-width: 1440px) {
    width: 1120px;
  }
`;

const Title = styled.span`
  margin: 0;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  text-align: center;
  white-space: pre;
  ${typography.title.large}

  @media (min-width: 820px) {
    ${typography.display.medium}
  }
`;

const RegisterAnchor = styled(Link)`
  --hover-gradient: linear-gradient(
    0deg,
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    0deg,
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  text-decoration: none;
  border-radius: 10px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  background: var(--color-white);
  box-shadow: 0 4px 16px rgba(51, 51, 51, 0.16);
  color: var(--color-pink);
  border: 1px solid #ededed;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }

  span {
    margin-left: 8px;
  }

  @media (min-width: 820px) {
    width: 200px;
  }
`;
