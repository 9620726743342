import * as React from 'react';
import styled from '@emotion/styled';

interface BackdropProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const Backdrop = React.forwardRef<HTMLDivElement, BackdropProps>(
  (props, ref) => <Base {...props} ref={ref} />
);

Backdrop.displayName = 'Backdrop';

export default Backdrop;

const Base = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(51, 51, 51, 0.8);
  -webkit-tap-highlight-color: transparent;
`;
