import * as React from 'react';
import { Provider } from 'react-redux';
import { AppRouter } from 'pages';
import { GlobalStyle } from './styles';
import store from './store';

const AppV2: React.FC = () => {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <AppRouter />
    </Provider>
  );
};

export default AppV2;
