import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import { PlusIcon } from 'assets/svg';
import { createNewCell, selectPageSTV } from 'features/editor/editorSlice';
import { sort, voiceModelCompare } from 'features/editor/utils';

interface NewCellProps {
  pageKey: string;
}

const NewCell: React.FC<NewCellProps> = ({ pageKey }) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const dispatch = useAppDispatch();

  const { model } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      model: sort(data, voiceModelCompare)?.[0],
    }),
  });

  const stv = useAppSelector(selectPageSTV(pageKey));

  const handleClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
    e.stopPropagation();
    if (model === undefined) return;

    if (stv) {
      dispatch(
        createNewCell({
          pageKey,
          modelName: stv.audioModelName,
          isLast: true,
        })
      );
    } else {
      dispatch(
        createNewCell({
          pageKey,
          modelName: model.name,
          isLast: true,
        })
      );
    }
  };

  return (
    <Base onClick={handleClick}>
      <Box>
        <PlusIcon size={16} weight={'bold'} />
        <span>{t('text.newCell')}</span>
      </Box>
    </Base>
  );
};

export default NewCell;

const Base = styled.li`
  margin-top: 8px;
  width: calc(100% - 24px);

  @media (min-width: 1024px) {
    width: calc(100% - 16px);
  }
`;

const Box = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px dashed var(--color-grey-200);
  border-radius: 10px;

  font-size: 12px;
  line-height: 140%;
  color: var(--color-grey-500);

  cursor: pointer;
  margin-left: auto;

  width: calc(100% - 20px);

  @media (min-width: 1024px) {
    width: calc(100% - 48px);
  }
`;
