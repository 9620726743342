import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import {
  authPersistMiddleware,
  authPreparePersistStore,
} from './authPersistEnhance';
import { editorAutosaveMiddleware } from './editorAutosaveEnhance';
import { authSlientLoginMiddleware } from './authSlientLoginEnhance';
import { sentryEnhancer } from './sentryEnhance';
import rootReducer from './rootReducer';
import services from './services';
import listeners from './listeners';
import type { TypedUseSelectorHook } from 'react-redux';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'editor/fetchMergeMlModelText/fulfilled',
          'editor/fetchMergeText/fulfilled',
          'editor/fetchSubtitleTXT/fulfilled',
          'editor/fetchSubtitleSRT/fulfilled',
        ],
      },
    })
      .prepend(listeners.middleware)
      .concat(
        ...services.middlewares,
        authPersistMiddleware,
        authSlientLoginMiddleware,
        editorAutosaveMiddleware
      ),
  devTools: process.env.NODE_ENV === 'development',
  preloadedState: authPreparePersistStore,
  enhancers: (defaultEnhancers) => [...defaultEnhancers, sentryEnhancer],
});

setupListeners(store.dispatch);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
