import * as React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import {
  selectPageContent,
  selectCurrentPageKey,
} from 'features/editor/editorSlice';
import PageCard from './PageCard';

interface SortableItemProps {
  pageKey: string;
  overlay?: boolean;
}

const SortableItem: React.FC<SortableItemProps> = ({ pageKey, overlay }) => {
  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({ id: pageKey });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const content = useAppSelector(selectPageContent(pageKey));
  const currentPageKey = useAppSelector(selectCurrentPageKey);

  const isSelected = currentPageKey === pageKey;

  if (content === undefined) return null;
  return (
    <Base
      ref={setNodeRef}
      style={style}
      className={overlay ? 'overlay' : undefined}
      {...attributes}
    >
      <PageCard
        pageKey={pageKey}
        num={content.num + 1}
        isSelected={isSelected}
        listeners={listeners}
        thumbnailUrl={content.thumbnailUrl}
        videoUrl={content.videoUrl}
      />
    </Base>
  );
};

export default SortableItem;

const Base = styled.div`
  width: 100%;
  outline: none;
  opacity: 1;

  &.overlay {
    opacity: 0.32;
  }
`;
