import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectCurrentPageKey,
  selectCurrentCells,
  fetchMergeText,
  selectTimelineMaxTime,
  setInitializeTimelineCells,
  selectTimelineChanged,
} from 'features/editor/editorSlice';
import AudioManager from 'lib/AudioManager';
import type { AppDispatch } from 'app/store';
import type { CellEntity } from 'features/editor/editorSlice/types';

const useInitializeTimelineCells = (isPadding: boolean, src: string) => {
  const currentPageKey = useAppSelector(selectCurrentPageKey);
  const cells = useAppSelector(selectCurrentCells);

  const timelineChanged = useAppSelector(selectTimelineChanged);

  const maxTime = useAppSelector(selectTimelineMaxTime);

  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);

  const [isProgressing, setIsProgressing] = React.useState<boolean>(false);

  const srcRef = React.useRef<string | undefined>(undefined);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!isPadding) return;
    if (srcRef.current === src && timelineChanged === false) return;
    if (currentPageKey === undefined) return;
    if (maxTime === 0) return;
    if (cells === undefined) return;
    if (cells.length === 0) return;
    if (isProgressing) return;

    setIsProgressing(true);
    setIsInitialized(false);
    (async () => {
      const initializedCells = await initializeCells(cells, maxTime, dispatch);

      dispatch(
        setInitializeTimelineCells({
          pageKey: currentPageKey,
          cells: initializedCells,
        })
      );
      srcRef.current = src;
      setIsProgressing(false);
      setIsInitialized(true);
    })();
  }, [
    cells,
    currentPageKey,
    dispatch,
    isPadding,
    isProgressing,
    maxTime,
    src,
    timelineChanged,
  ]);

  return isInitialized;
};

export default useInitializeTimelineCells;

const calcAudioDuration = (
  cell: CellEntity,
  dispatch: AppDispatch
): Promise<number> => {
  return new Promise(async (resolve, reject) => {
    if (cell.text === '') {
      resolve(0);
      return;
    }
    if (cell.mlModelName === '') {
      reject();
      return;
    }

    try {
      const blob = await dispatch(
        fetchMergeText({
          text: cell.text,
          name: cell.mlModelName,
          volume: cell.volume,
          speed: cell.speed,
        })
      ).unwrap();

      const url = AudioManager.createObjectUrl(blob);

      const audioEl = new Audio();
      audioEl.addEventListener(
        'loadedmetadata',
        () => {
          resolve(Math.ceil(audioEl.duration * 1000));
          audioEl.remove();
          AudioManager.revokeObjectUrl(audioEl.src);
        },
        { once: true }
      );
      audioEl.src = url;
    } catch (error) {
      reject(error);
    }
  });
};

const initializeCells = async (
  cells: CellEntity[],
  maxTime: number,
  dispatch: AppDispatch
) => {
  const ret: CellEntity[] = [];
  for (let index = 0; index < cells.length; index++) {
    const cell = { ...cells[index] };
    const prevEntity = ret[index - 1];

    // set paddingCell cell
    if (prevEntity === undefined) {
      cell.startTime = 0;
      cell.audioDuration = 0;
      ret.push(cell);
      continue;
    }

    if (
      prevEntity.startTime === undefined ||
      prevEntity.audioDuration === undefined
    ) {
      console.error('Timeline initialization error');
      break;
    }

    // set audioDuration
    cell.audioDuration = await calcAudioDuration(cell, dispatch);

    if (cell.audioDuration === undefined) {
      console.error('Timeline calcAudioDuration error');
      break;
    }

    cell.startTime =
      prevEntity.startTime + prevEntity.audioDuration + prevEntity.duration;

    const endTime = cell.startTime + cell.audioDuration + cell.duration;

    // TODO: 유저에게 비디오 시간이 넘는 셀이 삭제되는 것에 대한 알림 필요
    if (endTime > maxTime) {
      const lastCellEndTime = cell.startTime + cell.audioDuration;
      if (lastCellEndTime <= maxTime) {
        cell.duration = 0;
        ret.push(cell);
      }
      break;
    }
    ret.push(cell);
  }

  return ret;
};
