interface FetchParameters {
  uuid: string;
  token: string;
  videoHash: string;
  signal?: AbortSignal;
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/projects`;

export default async function fetchProjectSubtitleSRT({
  uuid,
  token,
  videoHash,
  signal,
}: FetchParameters) {
  const response = await fetch(
    `${URL}/${uuid}/subtitle?video_hash=${videoHash}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      signal,
    }
  );

  if (!response.ok) {
    throw new Error(JSON.stringify(await response.text()));
  }

  const data: {
    status: number;
    download_url: string;
  } = await response.json();

  return { downloadUrl: data.download_url };
}
