import * as React from 'react';
import styled from '@emotion/styled';
import SortableContents from './SortableContents';
import NewPageCard from './NewPageCard';

const PageCardList: React.FC = () => {
  return (
    <Base>
      <SortableContents />
      <NewPageCard />
    </Base>
  );
};

export default PageCardList;

const Base = styled.div`
  width: 250px;
  height: 100%;
  border-right: 1px solid var(--color-grey-100);
  overflow-y: auto;
  padding-bottom: 20px;
`;
