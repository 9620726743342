import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ArrowArcLeftIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { undo, selectUndoable } from 'features/editor/editorSlice';

const UndoButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const undoable = useAppSelector(selectUndoable);

  const handleClick = () => {
    dispatch(undo());
  };

  return (
    <IconButton size={32} disabled={!undoable} onClick={handleClick}>
      <ArrowArcLeftIcon size={24} weight={'bold'} />
    </IconButton>
  );
};

export default UndoButton;
