import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { formattedTime } from 'lib/utils';
import { useResizeObserver } from 'lib/hooks';
import {
  CheckIcon,
  PlayCircleIcon,
  StopCircleIcon,
  XIcon,
  DotsThreeOutlineVerticalIcon,
} from 'assets/svg';

type Owner = 'SERVER' | 'USER';

interface MusicAssetListItemProps {
  isSelect: boolean;
  name: string;
  displayName: string;
  src: string;
  duration: number;
  owner: Owner;
  currentAudioKey: string;
  onClick?: () => void;
  onDeleteAudio: (params: { name: string }) => void;
  onModifyAudioName: (params: { name: string; displayName: string }) => void;
  onTogglePlay: (params: {
    url: string;
    name: string;
    displayName: string;
  }) => void;
}

const MusicAssetListItem: React.FC<MusicAssetListItemProps> = ({
  isSelect,
  name,
  displayName,
  src,
  duration,
  owner,
  currentAudioKey,
  onClick,
  onDeleteAudio,
  onModifyAudioName,
  onTogglePlay,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'toolbar' });

  const [isHover, setIsHover] = React.useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    setOpenOption(false);
  };

  const handleClickListen: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onTogglePlay({ url: src, name, displayName });
  };

  const { ref } = useResizeObserver();

  const toggleOpenOption = () => setOpenOption((prev) => !prev);

  const handleClickOption: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (!isHover) setIsHover(true); // for mobile
    toggleOpenOption();
  };

  const [openOption, setOpenOption] = React.useState<boolean>(false);
  const [editDisplayNameText, setEditDisplayNameText] =
    React.useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleClickModifyMusicName: React.MouseEventHandler<
    HTMLLIElement
  > = async (e) => {
    e.stopPropagation();
    await setEditDisplayNameText(true);
    await inputRef.current?.focus();
    setOpenOption(false);
  };

  const handleClickDeleteMusic: React.MouseEventHandler<HTMLLIElement> = (
    e
  ) => {
    e.stopPropagation();
    if (name === undefined) return;
    toggleOpenOption();
    onDeleteAudio({ name });
  };

  const changeInputValue = (value: string) => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
  };

  const handleChangeDisplayNameText: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    e.stopPropagation();
    const { value } = e.target;
    changeInputValue(value);
  };

  const handleBlurDisplayNameText: React.FocusEventHandler<HTMLInputElement> = (
    e
  ) => {
    e.stopPropagation();
    if (inputRef.current && e.relatedTarget !== inputRef.current) {
      const newName = inputRef.current?.value;
      onModifyAudioName({ name, displayName: newName });
      setEditDisplayNameText(false);
    }
  };

  const handleSubmitModifyDisplayNameText: React.MouseEventHandler<
    HTMLFormElement
  > = (e) => {
    e.preventDefault();
    if (inputRef.current) {
      const newName = inputRef.current?.value;
      changeInputValue(newName);
      onModifyAudioName({ name, displayName: newName });
      setEditDisplayNameText(false);
    }
  };

  return (
    <ListItem
      onClick={onClick}
      isSelect={isSelect}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={ref}
    >
      {isSelect && isHover ? (
        <XIcon size={32} weight={'bold'} />
      ) : (
        <CheckIcon size={32} weight={'bold'} />
      )}
      <ListItemInfo>
        <DisplayNameForm onSubmit={handleSubmitModifyDisplayNameText}>
          <DisplayNameText
            ref={inputRef}
            defaultValue={displayName}
            onChange={handleChangeDisplayNameText}
            onBlur={handleBlurDisplayNameText}
            disabled={!editDisplayNameText}
          />
        </DisplayNameForm>
        <PlayTimeText>{formattedTime(Math.ceil(duration / 1000))}</PlayTimeText>
      </ListItemInfo>
      <ListItemControls>
        {owner === 'USER' && (
          <OptionBox>
            <OptionButton onClick={handleClickOption}>
              <DotsThreeOutlineVerticalIcon
                size={16}
                color={'var(--color-grey-700)'}
                weight={'fill'}
              />
            </OptionButton>

            {isHover && openOption && (
              <OptionDropdown>
                <OptionItem onClick={handleClickModifyMusicName}>
                  {t('music.button.modify')}
                </OptionItem>
                <OptionItem
                  onClick={handleClickDeleteMusic}
                  color='var(--color-danger)'
                >
                  {t('music.button.remove')}
                </OptionItem>
              </OptionDropdown>
            )}
          </OptionBox>
        )}
        <MusicListenButton onClick={handleClickListen}>
          {name !== currentAudioKey ? (
            <>
              <PlayCircleIcon size={16} weight={'fill'} />
              <span>{t('music.button.play')}</span>
            </>
          ) : (
            <>
              <StopCircleIcon size={16} weight={'fill'} />
              <span>{t('music.button.stop')}</span>
            </>
          )}
        </MusicListenButton>
      </ListItemControls>
    </ListItem>
  );
};

export default MusicAssetListItem;

const ListItem = styled.li<{ isSelect: boolean }>`
  width: 100%;
  height: 80px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isSelect }) =>
    isSelect ? 'var(--color-pink)' : 'var(--color-grey-300)'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  user-select: none;

  & > svg {
    flex: 0 auto;
    color: ${({ isSelect }) =>
      isSelect ? 'var(--color-pink)' : 'var(--color-grey-500)'};
    cursor: pointer;
  }

  &:hover {
    padding: 13px 15px;
    border-width: 2px;
    border-color: ${({ isSelect }) =>
      isSelect ? 'var(--color-pink)' : 'var(--color-grey-500)'};
  }
`;

const ListItemInfo = styled.div`
  flex: 1 auto;
  height: 100%;
  max-width: 230px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DisplayNameForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
`;

const DisplayNameText = styled.input`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: 0;
  border: none;
  background: transparent;

  &:focus {
    border-bottom: 1px solid var(--color-black);
  }
`;

const PlayTimeText = styled.span`
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-grey-700);
`;

const ListItemControls = styled.div`
  flex: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;

const MusicListenButton = styled.button`
  width: 72px;
  height: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: transparent;
  color: var(--color-grey-700);
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  svg {
    fill: currentColor;
  }
  span {
    margin-left: 4px;
  }
`;

const OptionBox = styled.div`
  height: 16px;
  display: flex;
  position: relative;
  justify-content: flex-end;
`;

const OptionButton = styled.button`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: content-box;
  position: absolute;
  right: -8px;
  top: -8px;
  outline: none;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
`;

const OptionDropdown = styled.ul`
  padding: 8px 0;
  min-width: 70px;
  position: absolute;
  z-index: 10;
  top: 22px;
  right: 0px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-100);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
`;

const OptionItem = styled.li<{ color?: string }>`
  padding: 0 8px;
  min-width: 100%;
  width: max-content;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.color || 'var(--color-black)'};
  list-style: none;
  cursor: pointer;

  &:hover,
  &:active {
    background: rgba(51, 51, 51, 0.08);
  }
`;
