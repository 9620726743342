import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { LogoIcon, HunetLogoIcon } from 'assets/svg';

const Header: React.FC = () => {
  return (
    <Base>
      <Box>
        <LogoAnchor to={'/'}>
          {process.env.ONAIR_PLATFORM_TYPE === 'hunet' && <HunetLogoIcon />}
          <LogoIcon type={'black'} />
        </LogoAnchor>
      </Box>
    </Base>
  );
};

export default Header;

const Base = styled.header`
  width: 100%;
  height: 64px;
  background: var(--color-white);
  position: fixed;
  top: 0;
  z-index: var(--header-index);

  @media (min-width: 1024px) {
    border-bottom: 1px solid var(--color-grey-100);
    position: static;
  }
`;

const Box = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
`;

const LogoAnchor = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 28px;

  svg + svg {
    margin-left: 8px;
  }
`;
