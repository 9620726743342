import * as React from 'react';
import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToHorizontalAxis,
} from '@dnd-kit/modifiers';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  setSelectCellKey,
  selectCurrentCells,
  moveTimelineCell,
  setTimelineCurrentTime,
  selectTimelineContentWidth,
  selectTimelineMaxTime,
  selectTimelineTimeGap,
  selectTimelineRulerGap,
  TIMELINE_INDICATOR_MIN_POSITION,
} from 'features/editor/editorSlice';
import { useVideoPreviewControls } from 'features/editor/providers/videoPreview';
import TimelineCell from './TimelineCell';
import type { DragStartEvent, DragEndEvent } from '@dnd-kit/core';

interface TimelineCellsProps {
  getScrollLeft: () => number;
}

const TimelineCells: React.FC<TimelineCellsProps> = ({ getScrollLeft }) => {
  const width = useAppSelector(selectTimelineContentWidth);

  const cells = useAppSelector(selectCurrentCells);

  const maxTime = useAppSelector(selectTimelineMaxTime);
  const timeGap = useAppSelector(selectTimelineTimeGap);
  const rulerGap = useAppSelector(selectTimelineRulerGap);

  const dragCellkey = React.useRef<string>();

  const dispatch = useAppDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  const handleClickBase: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target !== e.currentTarget) return;
    const { left } = e.currentTarget.getBoundingClientRect();

    const positionX = e.clientX - left;

    let currentTime = (positionX * timeGap) / rulerGap;

    if (maxTime - currentTime < maxTime % 100) {
      currentTime = maxTime;
    } else {
      currentTime = Math.floor(currentTime / 100) * 100;
    }

    dispatch(setTimelineCurrentTime(currentTime));
    dispatch(setSelectCellKey());
  };

  const handleDragStart = (e: DragStartEvent) => {
    const { active } = e;
    dragCellkey.current = active.id;
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, delta } = e;

    if (cells === undefined) return;

    if (dragCellkey.current !== active.id) return;

    const target = cells.find((el) => el.key === active.id);
    if (target === undefined) return;

    const { startTime, audioDuration } = target;
    if (startTime === undefined) return;
    if (audioDuration === undefined) return;

    const scrollLeft = getScrollLeft();
    let deltaTime = ((delta.x - scrollLeft) / rulerGap) * timeGap;
    deltaTime = Math.floor(Math.ceil(deltaTime) / 100) * 100;
    if (deltaTime === 0) return;

    let draggedTime = startTime + deltaTime;
    if (draggedTime < 0) draggedTime = 0;
    if (draggedTime + audioDuration > maxTime)
      draggedTime = maxTime - audioDuration;

    dispatch(
      moveTimelineCell({
        cellKey: dragCellkey.current,
        from: startTime,
        to: draggedTime,
      })
    );
  };

  const videoPreviewControls = useVideoPreviewControls();

  const handleClickTimelineCell = (targetCellKey: string) => {
    if (cells === undefined) return;
    const target = cells.find((el) => el.key === targetCellKey);

    if (target === undefined) return;

    const currentTime = target.startTime;
    if (currentTime === undefined) return;

    dispatch(setSelectCellKey(targetCellKey));
    dispatch(setTimelineCurrentTime(currentTime));
    videoPreviewControls?.seek(currentTime / 1000);
  };

  return (
    <DndContext
      autoScroll={false}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={[restrictToParentElement, restrictToHorizontalAxis]}
    >
      <Base onClick={handleClickBase} style={{ width }}>
        {cells?.map((el, idx) => {
          if (el.audioDuration === undefined) return null;
          if (el.startTime === undefined) return null;
          return (
            <TimelineCell
              cellKey={el.key}
              text={el.text}
              name={el.mlModelName}
              audioDuration={el.audioDuration}
              duration={el.duration}
              startTime={el.startTime}
              index={idx}
              onClick={handleClickTimelineCell}
              key={el.key}
            />
          );
        })}
      </Base>
    </DndContext>
  );
};

export default TimelineCells;

const Base = styled.div`
  position: absolute;
  z-index: 5;
  top: 104px;
  left: ${TIMELINE_INDICATOR_MIN_POSITION}px;
  bottom: 0;
  display: flex;
  align-items: center;
  height: fit-content;
`;
