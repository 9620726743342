import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { useAppDispatch } from 'app/hooks';
import MembershipService from 'app/services/membership';
import MusicAssetsService from 'app/services/musicAssets';
import ProjectsService from 'app/services/projects';
import UserInfoService from 'app/services/userInfo';
import VideoModelService from 'app/services/videoModel';
import VoiceModelService from 'app/services/voiceModel';
import { LogoIcon, CheckCircleGradientIcon } from 'assets/svg';
import { clearCredentials } from 'features/auth/authSlice';

const WithdrawalDonePage: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'withdrawalDone' });

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(clearCredentials());

    if (window === undefined || window.localStorage === undefined) return;
    window.localStorage.removeItem('onair-store');
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(MembershipService.util.resetApiState());
    dispatch(MusicAssetsService.util.resetApiState());
    dispatch(ProjectsService.util.resetApiState());
    dispatch(UserInfoService.util.resetApiState());
    dispatch(VideoModelService.util.resetApiState());
    dispatch(VoiceModelService.util.resetApiState());
  }, [dispatch]);

  return (
    <>
      <Global styles={globalStyles} />
      <Header>
        <LogoAnchor to={'/'}>
          <LogoIcon type={'black'} />
        </LogoAnchor>
      </Header>
      <Main>
        <Content>
          <CheckCircleGradientIcon />
          <TitleText>{t('title')}</TitleText>
          <BodyText>{t('body')}</BodyText>
          <GoHomeButton to={'/'} replace>
            {t('button')}
          </GoHomeButton>
        </Content>
      </Main>
    </>
  );
};

export default WithdrawalDonePage;

const globalStyles = css`
  html,
  body,
  #root {
    height: 100%;
  }
`;

const Header = styled.header`
  height: 64px;
  background: var(--color-white);
  display: flex;
  align-items: center;
  padding: 0 24px;

  @media (min-width: 1024px) {
    border-bottom: 1px solid var(--color-grey-100);
  }
`;

const LogoAnchor = styled(Link)`
  width: 79px;
  height: 28px;
`;

const Main = styled.main`
  width: 100%;
  height: calc(100% - 64px);
`;

const Content = styled.div`
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const TitleText = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  color: var(--color-black);
`;

const BodyText = styled.span`
  font-size: 18px;
  line-height: 1.4;
  color: var(--color-black);
  white-space: pre;
  text-align: center;
`;

const GoHomeButton = styled(Link)`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  border: none;
  border-radius: 10px;
  height: 48px;
  width: 300px;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-white);
  background: var(--color-pink);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;
