import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/store';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useFloating } from '@floating-ui/react-dom';
import { useGetProjectsQuery } from 'app/services/projects';
import { DotsThreeOutlineVerticalIcon, LogoOverlayIcon } from 'assets/svg';
import { useSortType, useOpenDeleteProjectDialog } from 'providers/projectList';
import { useMatchMedia } from 'lib/hooks';
import { typography } from 'styles';
import {
  removeProcessing,
  selectProcessingList,
} from 'features/projectList/projectListSlice';
import { Toast } from 'components/common';
import ProjectNameInput from './ProjectNameInput';

export interface ProjectCardProps {
  uuid: string;
  disabled?: boolean;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ uuid, disabled }) => {
  const { t } = useTranslation('client', { keyPrefix: 'projectList' });

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom',
    strategy: 'fixed',
  });

  const { project } = useGetProjectsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      project: data?.find((el) => el.projectUuid === uuid),
    }),
  });

  const progress = project?.renderingStatus ? project?.renderingStatus / 5 : 0;

  const { progressingList } = useAppSelector(selectProcessingList);

  const isProgressing =
    progressingList.includes(uuid) || (progress > 0 && progress < 1);

  const sortType = useSortType();

  const [isHover, setIsHover] = React.useState<boolean>(false);

  const [openOption, setOpenOption] = React.useState<boolean>(false);
  const toggleOpenOption = () => setOpenOption((prev) => !prev);

  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => {
    setIsHover(false);
    setOpenOption(false);
  };

  const openDeleteProjectDialog = useOpenDeleteProjectDialog();

  const [editProjectName, setEditProjectName] = React.useState<boolean>(false);
  const toggleEditProjectName = () => setEditProjectName((prev) => !prev);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const isMatch = useMatchMedia('(min-width: 1024px)');

  const [isOpenToast, setIsOpenToast] = React.useState<boolean>(false);

  const handleClick = () => {
    if (project === undefined) return;
    if (disabled) {
      setIsOpenToast(true);
      return;
    }
    if (!isMatch && project.type === 'desktop') return;

    if (progressingList.includes(uuid) && progress === 1) {
      dispatch(removeProcessing({ project: uuid }));
    }

    navigate(`/client/editor#uuid=${uuid}`);
  };

  const handleClickOption: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    toggleOpenOption();
  };

  const handleClickModifyName: React.MouseEventHandler<HTMLLIElement> = (e) => {
    e.stopPropagation();
    if (project === undefined) return;

    toggleOpenOption();
    toggleEditProjectName();
  };

  const handleClickDeleteProject: React.MouseEventHandler<HTMLLIElement> = (
    e
  ) => {
    e.stopPropagation();
    if (project === undefined) return;

    toggleOpenOption();
    openDeleteProjectDialog(project.projectUuid);
  };

  if (project === undefined) return null;
  return (
    <Base>
      <ThumbnailBox
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {isProgressing && (
          <ProgressBox>
            <ProgressInfoBox>
              <span>
                {t('progress', {
                  percent: (progress * 100).toFixed(0),
                })}
              </span>
            </ProgressInfoBox>
            <ProgressIndicator>
              <Loading percent={`${(progress * 100).toFixed(0)}%`} />
            </ProgressIndicator>
          </ProgressBox>
        )}
        {project.projectThumbnail && (
          <ThumbnailImage
            src={project.projectThumbnail}
            width={328}
            height={184}
          />
        )}
        {isHover && (
          <OptionButton onClick={handleClickOption} ref={reference}>
            <DotsThreeOutlineVerticalIcon
              size={16}
              color={'var(--color-white)'}
              weight={'fill'}
            />
          </OptionButton>
        )}
        {isHover && openOption && (
          <OptionDropdown
            ref={floating}
            style={{
              position: strategy,
              top: y ?? '',
              left: x ?? '',
            }}
          >
            <ProjectNameModifyOption onClick={handleClickModifyName}>
              {t('modify')}
            </ProjectNameModifyOption>
            <ProjectDeleteOption onClick={handleClickDeleteProject}>
              {t('delete.name')}
            </ProjectDeleteOption>
          </OptionDropdown>
        )}
        <Overlay />
        {disabled ? (
          <NotificationOverlay>
            {t('placeholder.overLimit')}
          </NotificationOverlay>
        ) : (
          <>
            {!isMatch && project.type === 'desktop' && (
              <NotificationOverlay>
                {t('placeholder.onlyDesktop')}
              </NotificationOverlay>
            )}
          </>
        )}
      </ThumbnailBox>
      <ProjectNameInput
        uuid={project.projectUuid}
        name={project.projectName}
        isEdit={editProjectName}
        toggleEdit={toggleEditProjectName}
      />
      <StatusBox>
        {sortType === 'created' && (
          <DateText>
            {`${new Date(project.createDate).toLocaleDateString()} ${t(
              'text.created'
            )}`}
          </DateText>
        )}
        {sortType === 'name' && (
          <DateText>
            {`${new Date(project.createDate).toLocaleDateString()} ${t(
              'text.created'
            )}`}
          </DateText>
        )}
        {sortType === 'modified' && (
          <DateText>
            {`${new Date(project.updateDate).toLocaleDateString()} ${t(
              'text.modified'
            )}`}
          </DateText>
        )}
      </StatusBox>
      <Toast
        message={t('error.overLimit')}
        severity={'error'}
        in={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        timeout={1500}
      />
    </Base>
  );
};

export default ProjectCard;

const Base = styled.div`
  width: 330px;
  height: 240px;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
`;

const ThumbnailBox = styled.div`
  width: 330px;
  height: 186px;
  position: relative;
  border: 1px solid var(--color-grey-100);
  box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  user-select: none;
  overflow: hidden;

  &:hover,
  &:active {
    box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
      0px 16px 32px rgba(22, 34, 51, 0.16);
  }
`;

const ProgressBox = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 8px;
  user-select: none;
  background: linear-gradient(
    rgba(22, 34, 51, 0.08) 0%,
    rgba(22, 34, 51, 0.6) 100%
  );
`;

const ProgressInfoBox = styled.div`
  margin-bottom: 4px;

  span {
    ${typography.label.large}
    color: var(--color-white);
    text-shadow: 2px 2px 2px var(--color-black);
  }
`;

const wave = keyframes`
	0% {
		transform: translateX(-100%);
	}
	60% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(100%);
	}
`;

const ProgressIndicator = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    var(--color-pink);
`;

const Loading = styled.div<{ percent: string }>`
  width: ${({ percent }) => percent};
  height: 100%;
  border-radius: 10px;
  background: var(--color-pink);
  transition: width 1s ease-in-out;
  position: relative;
  overflow: hidden;

  &::after {
    animation: ${wave} 1.6s linear 0.5s infinite;
    background: linear-gradient(90deg, transparent, #c60036, transparent);
    content: '';
    position: absolute;
    transform: translateX(-100%);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const OptionButton = styled.button`
  background-color: rgba(51, 51, 51, 0.8);
  border-radius: 6px;
  padding: 4px;
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 8px;
  cursor: pointer;
`;

const OptionDropdown = styled.ul`
  list-style: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-100);
  border-radius: 10px;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  padding: 8px 0;
  width: 88px;
  height: 80px;
  z-index: 2;
  cursor: pointer;
`;

const ProjectNameModifyOption = styled.li`
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${typography.body.medium}

  color: var(--color-black);

  &:hover,
  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;
  }
`;

const ProjectDeleteOption = styled.li`
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${typography.body.medium}

  color: var(--color-danger);

  &:hover,
  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;
  }
`;

const Overlay = styled(LogoOverlayIcon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ThumbnailImage = styled.img`
  background-color: var(--color-white);
  border-radius: inherit;
`;

const StatusBox = styled.div`
  margin-top: 4px;
`;

const DateText = styled.span`
  ${typography.body.small}
  color: var(--color-grey-700);
`;

const NotificationOverlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: var(--color-white);
  ${typography.title.medium}
`;
