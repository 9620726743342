import {
  fetchProjectById,
  fetchUpdateProject,
  fetchCreateProject,
  fetchSubtitleSRT,
  fetchSubtitleTXT,
  fetchAudio,
  fetchVideo,
  setNetworkState,
  cancelUpdateTimer,
} from 'features/editor/editorSlice';
import type { AnyAction, Middleware } from '@reduxjs/toolkit';
import type { RootState } from './store';

type AsyncThunkPromise = ReturnType<ReturnType<typeof fetchUpdateProject>>;

interface AsyncThunkAbortError {
  name: 'AbortError';
  message: string;
}

const isAsyncThunkAbortError = (error: any): error is AsyncThunkAbortError => {
  if (
    error.hasOwnProperty('name') &&
    error.hasOwnProperty('message') &&
    error['name'] === 'AbortError'
  )
    return true;

  return false;
};

const REDUCER_PATH = 'editor';

const AUTOSAVE_INTERVAL = 30 * 1000;

let timeoutId: number | undefined;
let timeoutPromise: AsyncThunkPromise | undefined;

const savingAction = [fetchUpdateProject.pending];

const whitelistActions = [
  fetchProjectById.pending,
  fetchProjectById.fulfilled,
  fetchProjectById.rejected,
  fetchCreateProject.pending,
  fetchCreateProject.fulfilled,
  fetchCreateProject.rejected,
  fetchSubtitleSRT.pending,
  fetchSubtitleSRT.fulfilled,
  fetchSubtitleSRT.rejected,
  fetchSubtitleTXT.pending,
  fetchSubtitleTXT.fulfilled,
  fetchSubtitleTXT.rejected,
  fetchAudio.pending,
  fetchAudio.fulfilled,
  fetchAudio.rejected,
  fetchVideo.pending,
  fetchVideo.fulfilled,
  fetchVideo.rejected,
  setNetworkState,
];

export const editorAutosaveMiddleware: Middleware =
  (api) => (next) => (action: AnyAction) => {
    let result = next(action);

    if (action.type.indexOf(REDUCER_PATH) === -1) return result;

    if (action.type === cancelUpdateTimer.type) {
      try {
        if (timeoutId !== undefined) window.clearTimeout(timeoutId);
        if (timeoutPromise !== undefined) timeoutPromise.abort();
      } catch (error) {
        if (isAsyncThunkAbortError(error)) return;
        console.error(error);
      }
      return result;
    }

    if (savingAction.some((saving) => saving.type === action.type)) {
      if (timeoutId !== undefined) window.clearTimeout(timeoutId);
      return result;
    }

    if (whitelistActions.some((whitelist) => whitelist.type === action.type))
      return result;

    const state: RootState = api.getState();
    const reducerState = state[REDUCER_PATH];

    if (reducerState === undefined) return result;
    if (reducerState.present.data.uuid === undefined) return result;

    if (timeoutId !== undefined) window.clearTimeout(timeoutId);

    if (reducerState.present.data._modified) {
      timeoutId = window.setTimeout(() => {
        timeoutPromise = fetchUpdateProject()(api.dispatch, () => state, {});
      }, AUTOSAVE_INTERVAL);
    }

    return result;
  };
