import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';
import { useResizeObserver } from 'lib/hooks';
import { Slider } from 'components/editor';

const volumeToStep = (volume: number) => {
  if (volume <= 2) return 1;
  if (volume <= 4) return 2;
  if (volume <= 7) return 3;
  if (volume <= 15) return 4;
  if (volume <= 23) return 5;
  return 3;
};

const stepToVolume = (step: number) => {
  if (step === 1) return 2;
  if (step === 2) return 4;
  if (step === 3) return 7;
  if (step === 4) return 15;
  if (step === 5) return 23;
  return 7;
};

const clamp = (value: number | null, min: number, max: number) => {
  if (value === null) return min;

  return Math.min(Math.max(min, value), max);
};

interface AudioItemControllerProps {
  valueInit?: number;
  isButton?: boolean;
  onChangeValue: (value: number) => void;
}

const AudioItemController: React.FC<AudioItemControllerProps> = ({
  valueInit = 3,
  isButton = false,
  onChangeValue,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'audioItemController' });

  const stepRef = React.useRef<number>(volumeToStep(valueInit));

  const { ref: controlRef, width: controlWidth } = useResizeObserver();

  const handleChangeValue = (newValue: number) => {
    stepRef.current = newValue;
    const volume = stepToVolume(stepRef.current);
    onChangeValue(volume);
  };

  const handleClickMinus: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    stepRef.current = clamp(stepRef.current - 1, 1, 5);

    const volume = stepToVolume(stepRef.current);
    onChangeValue(volume);
  };

  const handleClickPlus: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    stepRef.current = clamp(stepRef.current + 1, 1, 5);

    const volume = stepToVolume(stepRef.current);
    onChangeValue(volume);
  };

  const stepType = () => {
    if (stepRef.current === 1) return 1;
    if (stepRef.current === 2) return 2;
    if (stepRef.current === 3) return 3;
    if (stepRef.current === 4) return 4;
    if (stepRef.current === 5) return 5;
    return 3;
  };

  return (
    <Base>
      <ControlBox ref={controlRef}>
        <TitleBox>
          <span>{t('title.default')}</span>
        </TitleBox>
        <SliderBox>
          <Slider
            width={controlWidth}
            value={stepRef.current}
            step={1}
            onChangeCommit={handleChangeValue}
            min={1}
            max={5}
          />
        </SliderBox>
        {isButton && (
          <ButtonBox>
            <ControlButton onClick={handleClickMinus}>
              <span>{t('button.minus')}</span>
            </ControlButton>
            <ControlValue>{t(`volumeStep.${stepType()}`)}</ControlValue>
            <ControlButton onClick={handleClickPlus}>
              <span>{t('button.plus')}</span>
            </ControlButton>
          </ButtonBox>
        )}
      </ControlBox>
    </Base>
  );
};

export default AudioItemController;

const Base = styled.div`
  width: 100%;
  padding: 16px 16px 24px;
`;

const ControlBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 16px;

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    color: var(--color-black);
  }
`;

const SliderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

const ControlValue = styled.div`
  display: flex;
  align-items: center;
  ${typography.title.medium}
  color: var(--color-grey-600);
  user-select: none;
`;

const ControlButton = styled.button`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid var(--color-grey-200);
  background: transparent;

  span {
    color: var(--color-grey-500);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    cursor: pointer;
    user-select: none;
  }

  &:hover {
    color: var(--color-black);
    background: rgba(51, 51, 51, 0.08);

    @media (hover: none) {
      color: var(--color-black);
      background: rgba(51, 51, 51, 0.16);
    }
  }

  &:active {
    color: var(--color-black);
    background: rgba(51, 51, 51, 0.16);
  }
`;
