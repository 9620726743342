import AuthService from 'app/services/auth';
import { setCredentials, clearCredentials } from 'features/auth/authSlice';
import type { AnyAction, Middleware } from '@reduxjs/toolkit';
import type { AuthState } from 'features/auth/authSlice';
import type { RootState } from './store';

const requestRefresh = AuthService.endpoints.refresh.initiate;

interface AsyncThunkAbortError {
  name: 'AbortError';
  message: string;
}

let timeoutId: number | undefined;
let timeoutPromiseAbort: (() => void) | undefined;

const isAsyncThunkAbortError = (error: any): error is AsyncThunkAbortError => {
  if (
    error.hasOwnProperty('name') &&
    error.hasOwnProperty('message') &&
    error['name'] === 'AbortError'
  )
    return true;

  return false;
};

interface ExistAuthState extends AuthState {
  token: string;
  loginTime: number;
  expiredTime: number;
}

const isAuthStateExist = (state: AuthState): state is ExistAuthState => {
  if (state.token === null) return false;
  if (state.loginTime === null) return false;
  if (state.expiredTime === null) return false;
  return true;
};

export const authSlientLoginMiddleware: Middleware =
  (api) => (next) => (action: AnyAction) => {
    let result = next(action);

    const state: RootState = api.getState();
    const authState = state.auth;

    if (isAuthStateExist(authState)) {
      if (timeoutId === undefined) {
        timeoutId = window.setTimeout(async () => {
          try {
            const { abort, unwrap } = requestRefresh()(
              api.dispatch,
              () => state,
              {}
            );
            timeoutPromiseAbort = abort;
            const { access: token } = await unwrap();
            api.dispatch(setCredentials({ token, loginTime: Date.now() }));
          } catch (error) {
            console.error(error);
          }
        }, authState.expiredTime - Date.now());
      }
    } else {
      if (action.type === clearCredentials.type) {
        if (timeoutId !== undefined) window.clearTimeout(timeoutId);
        if (timeoutPromiseAbort) {
          try {
            timeoutPromiseAbort();
          } catch (error) {
            if (!isAsyncThunkAbortError(error)) {
              console.error(error);
            }
          }
        }

        timeoutId = undefined;
        timeoutPromiseAbort = undefined;
      }
    }

    return result;
  };
