import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { download } from 'lib/utils';
import {
  useVideoDownloadProgress,
  useVideoDownloadParams,
  useVideoDownloadModalClose,
  useVideoDownloadRetry,
} from 'features/editor/providers';
import DownloadTemplate from './DownloadTemplate';
import PageCarousel from './PageCarousel';

const VideoDownload: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'downloadProgress' });

  const progress = useVideoDownloadProgress();

  const downloadParams = useVideoDownloadParams();

  const navigate = useNavigate();

  const modalClose = useVideoDownloadModalClose();

  const handleDownload = async () => {
    if (downloadParams === null) return;

    try {
      download(downloadParams.data, downloadParams.name);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRetry = useVideoDownloadRetry();

  return (
    <DownloadTemplate
      id={'video-download-modal'}
      title={t('start.video')}
      titleDone={t('done.video')}
      type={'video'}
      progress={progress}
      carousel={<PageCarousel />}
      onClose={() => navigate('/client', { replace: true })}
      onEnded={modalClose}
      onDownload={handleDownload}
      onRetry={handleRetry}
    />
  );
};

export default VideoDownload;
