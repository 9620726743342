import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@headlessui/react';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MinusIcon, PlusIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { Slider } from 'components/editor';
import {
  selectPageDuration,
  modifyPageData,
} from 'features/editor/editorSlice';
import { SLIDE_MIN_DURATION } from 'features/editor/constants';

interface PageDurationProps {
  pageKey: string;
}

const PageDuration: React.FC<PageDurationProps> = ({ pageKey }) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const _duration = useAppSelector(selectPageDuration(pageKey));
  const [duration, setDuration] = React.useState<number>(_duration ?? 0);

  React.useEffect(() => {
    if (_duration === undefined) return;
    setDuration(_duration);
  }, [_duration]);

  const dispatch = useAppDispatch();

  const handleClickMinus = () => {
    const newValue = Math.max(duration - 100, SLIDE_MIN_DURATION);

    setDuration(newValue);
    dispatch(
      modifyPageData({
        pageKey,
        duration: newValue,
      })
    );
  };

  const handleClickPlus = () => {
    const newValue = Math.min(duration + 100, 5000);

    setDuration(newValue);
    dispatch(
      modifyPageData({
        pageKey,
        duration: newValue,
      })
    );
  };

  const handleChange = (newValue: number) => {
    dispatch(
      modifyPageData({
        pageKey,
        duration: newValue,
      })
    );
  };

  if (_duration === undefined) return null;
  return (
    <Popover as={PopoverBase} onPointerDown={(e) => e.stopPropagation()}>
      <Popover.Button as={PopoverButton}>
        <span>{t('label.pageDuration')}</span>
        <Divider />
        <span>
          {t('text.durationValue', {
            duration: (duration / 1000).toFixed(1),
          })}
        </span>
      </Popover.Button>
      <Popover.Panel as={PopoverPanel}>
        <IconButton size={24} onClick={handleClickMinus}>
          <MinusIcon size={20} weight={'bold'} />
        </IconButton>
        <Slider
          width={120}
          value={duration}
          onChange={(newValue) => setDuration(newValue)}
          onChangeCommit={handleChange}
          min={SLIDE_MIN_DURATION}
          max={5000}
        />
        <IconButton size={24} onClick={handleClickPlus}>
          <PlusIcon size={20} weight={'bold'} />
        </IconButton>
      </Popover.Panel>
    </Popover>
  );
};

export default PageDuration;

const PopoverBase = styled.div`
  position: absolute;
  z-index: 1;
  top: 118px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PopoverButton = styled.div`
  min-height: 24px;
  padding: 2px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-grey-100);
  border-radius: 10px;
  background: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-grey-500);
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: var(--color-grey-300);

    @media (hover: none) {
      border-color: var(--color-grey-100);
    }
  }
`;

const Divider = styled.hr`
  border: none;
  width: 1px;
  height: 12px;
  background: var(--color-grey-100);
  margin: 0 4px;
`;

const PopoverPanel = styled.div`
  width: 204px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--color-white);

  box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
    0px 16px 32px rgba(22, 34, 51, 0.16);

  svg {
    color: var(--color-black);
  }
`;
