import ko from './ko-kr.json';
import en from './en-us.json';
import type { Resource } from 'i18next';

const resources: Resource = {
  ko,
  en,
};

export default resources;
