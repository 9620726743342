import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { removeExtension } from 'lib/utils';
import { Toast } from 'components/common';
import {
  MusicAsset,
  useDeleteInterPageSoundMutation,
  useGetInterPageSoundQuery,
  useModifyInterPageSoundMutation,
  useUploadInterPageSoundMutation,
} from 'app/services/musicAssets';
import { AUDIO_ITEM_MAX_VOLUME } from 'features/editor/constants';
import {
  setAudioToolState,
  selectCurrentAudioToolState,
  clearAudioToolState,
} from 'features/editor/editorSlice';
import MusicAssetList from './MusicAssetList';
import AudioItemController from './AudioItemController';

const InterPageSoundAudioSelect: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'ipsAudioSelect' });

  const { data: interPageSoundMusics } = useGetInterPageSoundQuery();

  const musicItems: MusicAsset[] = interPageSoundMusics
    ? [...interPageSoundMusics].sort((prev, next) => {
        if (prev.owner === next.owner)
          return prev.displayName.localeCompare(next.displayName);
        if (prev.owner === 'SERVER') return -1;
        return 1;
      })
    : [];

  const [uploadAudio] = useUploadInterPageSoundMutation();
  const [deleteAudio] = useDeleteInterPageSoundMutation();
  const [modifyAudioName] = useModifyInterPageSoundMutation();

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const handleChangeFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const el = e.target as HTMLInputElement;
    if (el === undefined) return;
    if (el.files === null) return;
    if (el.files.length === 0) return;

    const file = el.files[0];

    const displayName = removeExtension(file.name);

    if (file.size > 20 * 1024 * 1024) {
      toggleOpenToast();
      return;
    }

    uploadAudio({ displayName, file });
  };

  const dispatch = useAppDispatch();

  const [audio, state, controls, ref] = useAudio({
    src: '',
    onEnded() {
      currentAudioKey.current = '';
      if (ref.current === null) return;
      ref.current.src = '';
    },
  });

  const currentAudioKey = React.useRef<string>('');

  const { ips: currentIps, ipsVolume } = useAppSelector(
    selectCurrentAudioToolState
  );

  const handleTogglePlay = ({
    url,
    name,
    displayName,
  }: {
    url: string;
    name: string;
    displayName: string;
  }) => {
    const el = ref.current;
    if (el === null) return;

    if (state.paused) {
      currentAudioKey.current = name;
      el.src = url;
      controls.play();
    } else {
      if (currentAudioKey.current === name) {
        currentAudioKey.current = '';
        controls.pause();
        el.src = '';
        return;
      } else {
        currentAudioKey.current = name;
        el.src = url;
        controls.play();
      }
    }
  };

  const handleChangeValue = (value) => {
    const volume = value / AUDIO_ITEM_MAX_VOLUME;
    controls.volume(volume);
    dispatch(setAudioToolState({ ipsVolume: value }));
  };

  const handleClickMusicAssetList = ({
    name: ips,
    duration: ipsDuration,
  }: {
    name: string;
    duration?: number;
  }) => {
    if (ips === currentIps) {
      dispatch(clearAudioToolState('ips'));
      return;
    }
    dispatch(setAudioToolState({ ips, ipsDuration }));
  };

  return (
    <Base>
      <Title>{t('title.default')}</Title>
      {musicItems.length > 0 && (
        <>
          {audio}
          <MusicAssetList
            items={musicItems}
            selectedName={currentIps}
            currentAudioKey={currentAudioKey.current}
            onClick={handleClickMusicAssetList}
            onDeleteAudio={deleteAudio}
            onModifyAudioName={modifyAudioName}
            onTogglePlay={handleTogglePlay}
          />
          <Divider />
          <AudioItemController
            valueInit={ipsVolume}
            onChangeValue={handleChangeValue}
            isButton
          />
          <Divider />
        </>
      )}
      <UploadBox>
        <div>
          <UploadTitle>{t('title.upload')}</UploadTitle>
          <UploadSubtitle>{t('subtitle.upload')}</UploadSubtitle>
        </div>
        <UploadButton>
          {t('button.upload')}
          <input
            type={'file'}
            accept={'audio/*'}
            onChange={handleChangeFile}
            style={{ display: 'none' }}
          />
        </UploadButton>
      </UploadBox>
      <Toast
        in={openToast}
        onClose={toggleOpenToast}
        timeout={1500}
        severity={'error'}
        message={t('toast.upload')}
      />
    </Base>
  );
};

export default InterPageSoundAudioSelect;

const Base = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-grey-700);
  padding: 0 16px;
`;

const Divider = styled.div`
  height: 1px;
  background: var(--color-grey-200);
`;

const UploadBox = styled.div`
  margin-top: auto;
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0;
`;

const UploadTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
`;

const UploadSubtitle = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-700);
  margin-left: 8px;
`;

const UploadButton = styled.label`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  width: 100%;
  height: 48px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-grey-200);
  background: var(--color-grey-50);
  outline: none;
  border-radius: 10px;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-black);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-grey-50);

    @media (hover: none) {
      background: var(--color-grey-50);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-grey-50);
  }
`;
