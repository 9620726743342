import * as React from 'react';
import styled from '@emotion/styled';
import { XIcon } from 'assets/svg';
import { growIn, growOut } from 'assets/animation';

type Split<
  T,
  Seperator extends string
> = T extends `${infer F}${Seperator}${infer U}`
  ? [F, ...Split<U, Seperator>]
  : [T];

type ToastType = 'normal' | 'success' | 'error';

type Horizontal = 'left' | 'center' | 'right';

type Vertical = 'top' | 'bottom';

type ToastPosition = `${Vertical}-${Horizontal}`;

const setToastBoxClassname = (position: ToastPosition) => {
  const classes: string[] = [];

  const [vertical, horizontal] = position.split('-') as Split<
    ToastPosition,
    '-'
  >;
  if (vertical === 'top') classes.push('toast--top');
  if (vertical === 'bottom') classes.push('toast--bottom');
  if (horizontal === 'left') classes.push('toast--left');
  if (horizontal === 'center') classes.push('toast--center');
  if (horizontal === 'right') classes.push('toast--right');

  return classes.join(' ');
};

const setToastContentClassname = (
  severity: ToastType,
  show: boolean
): string => {
  const classes: string[] = [];

  if (severity === 'success') classes.push('toast--success');
  if (severity === 'error') classes.push('toast--error');
  if (show) classes.push('toast--enter');
  if (!show) classes.push('toast--exit');

  return classes.join(' ');
};

export interface ToastProps {
  message: string;
  timeout: number;
  in: boolean;
  severity?: ToastType;
  position?: ToastPosition;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({
  message,
  timeout,
  severity = 'normal',
  position = 'bottom-center',
  onClose,
  ...rest
}) => {
  const timer = React.useRef<number | null>(null);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const inAnimationLock = React.useRef<boolean>(false);

  const toggleClasses = () => {
    const el = ref.current;
    if (el === null) return;

    el.classList.toggle('toast--enter');
    el.classList.toggle('toast--exit');
  };

  const handleAnimationStart: React.AnimationEventHandler<HTMLDivElement> =
    () => {
      inAnimationLock.current = true;
    };

  const handleAnimationEnd: React.AnimationEventHandler<HTMLDivElement> = (
    e
  ) => {
    const el = ref.current;
    if (el === null) return;

    if (e.animationName === growIn.name) {
      timer.current = window.setTimeout(() => {
        toggleClasses();
      }, timeout);
      inAnimationLock.current = false;
      return;
    }

    if (e.animationName === growOut.name) {
      if (timer.current !== null) {
        window.clearTimeout(timer.current);
      }
      inAnimationLock.current = false;
      onClose();
      return;
    }
  };

  const handleClose = () => {
    if (inAnimationLock.current) return;

    const el = ref.current;
    if (el === null) return;

    if (timer.current !== null) {
      window.clearTimeout(timer.current);
    }

    toggleClasses();
  };

  if (rest.in === false) return null;
  return (
    <Base className={setToastBoxClassname(position)}>
      <Content
        className={setToastContentClassname(severity, rest.in)}
        onAnimationStart={handleAnimationStart}
        onAnimationEnd={handleAnimationEnd}
        ref={ref}
      >
        <Text>{message}</Text>
        <Button onClick={handleClose}>
          <XIcon size={20} weight={'bold'} color={'currentColor'} />
        </Button>
      </Content>
    </Base>
  );
};

export default Toast;

const Base = styled.div`
  z-index: var(--toast-index);
  position: fixed;

  &.toast--top {
    top: 64px;
  }

  &.toast--bottom {
    bottom: 64px;
  }

  &.toast--left {
    left: 64px;
  }

  &.toast-right {
    right: 64px;
  }

  &.toast--center {
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Content = styled.div`
  --toast-bg-color: var(--color-black);

  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(51, 51, 51, 0.24);
  background: var(--toast-bg-color);
  padding: 16px;
  width: calc(100vw - 32px);
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.toast--success {
    --toast-bg-color: var(--color-success);
  }

  &.toast--error {
    --toast-bg-color: var(--color-danger);
  }

  &.toast--enter {
    animation: ${growIn} ease-in-out 225ms;
  }

  &.toast--exit {
    animation: ${growOut} ease-in-out 225ms;
  }

  @media (min-width: 820px) {
    width: 640px;
  }
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-white);
  white-space: pre;
`;

const Button = styled.button`
  width: 20px;
  height: 20px;

  background: transparent;
  border: none;
  outline: none;
  padding: 0;

  color: var(--color-white);
`;
