import { combineReducers } from '@reduxjs/toolkit';
import auth from 'features/auth/authSlice';
import editor from 'features/editor/editorSlice';
import projectList from 'features/projectList/projectListSlice';
import services from 'app/services';

const rootReducer = combineReducers({
  auth,
  editor,
  projectList,
  ...services.reducers,
});

export default rootReducer;
