import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import videoModelService from 'app/services/videoModel';
import voiceModelService from 'app/services/voiceModel';
import { Tabs } from 'components/editor';
import {
  setCharacterToolIndex,
  selectCurrentCharacterIndex,
} from 'features/editor/editorSlice';
import VideoModelSelect from './VideoModelSelect';
import VoiceModelSelect from './VoiceModelSelect';

const CharacterTool: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'toolbar' });

  const dispatch = useAppDispatch();

  const index = useAppSelector(selectCurrentCharacterIndex);

  return (
    <Base>
      <Tabs
        items={[t('character.tts'), t('character.ttv')]}
        selectedIndex={index}
        onClick={(e) => {
          const el =
            e.target instanceof HTMLButtonElement ? e.target : undefined;

          if (el === undefined) return;

          if (el.dataset.idx === undefined) return;

          const idx = Number.parseInt(el.dataset.idx);
          if (Number.isNaN(idx)) return;
          if (idx === 0 || idx === 1) dispatch(setCharacterToolIndex(idx));
        }}
        onMouseOver={(e) => {
          const el =
            e.target instanceof HTMLButtonElement ? e.target : undefined;

          if (el === undefined) return;

          if (el.dataset.idx === undefined) return;
          const idx = Number.parseInt(el.dataset.idx);

          if (idx === 0) {
            dispatch(
              videoModelService.util.prefetch('getVideoModel', undefined, {
                ifOlderThan: 60,
              })
            );
            return;
          }

          if (idx === 1) {
            dispatch(
              voiceModelService.util.prefetch('getVoiceModel', undefined, {
                ifOlderThan: 60,
              })
            );
          }
        }}
      />
      {index === 0 && <VoiceModelSelect />}
      {index === 1 && <VideoModelSelect />}
    </Base>
  );
};

export default CharacterTool;

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-child {
    padding: 8px 16px 0px 16px;
  }
`;
