import { recordTransformer, camelCase } from 'lib/utils';

interface FetchParameters {
  uuid: string;
  token: string;
  signal?: AbortSignal;
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/projects/v2`;

export default async function fetchProject({
  uuid,
  token,
  signal,
}: FetchParameters) {
  const response = await fetch(`${URL}?uuid=${uuid}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal,
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(JSON.stringify(data));
  }

  return recordTransformer(data, camelCase);
}
