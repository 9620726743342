import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { XIcon } from 'assets/svg';
import { IconButton, ModalV2 } from 'components/common';
import {
  selectCurrentToolbarItem,
  setToolbarItem,
} from 'features/editor/editorSlice';
import {
  useToolbarCurrentState,
  useToolbarToggleState,
} from 'features/editor/providers';
import CharacterItem from './CharacterItem';
import UploadItem from './UploadItem';
import VideoItem from './VideoItem';
import AudioItem from './AudioItem';
import ClosedCaptionItem from './ClosedCaptionItem';
import EditorToolbarItems from './EditorToolbarItems';

const EditorToolbar: React.FC = () => {
  const currentToolbarItem = useAppSelector(selectCurrentToolbarItem);

  const dispatch = useAppDispatch();

  const openToolbar = useToolbarCurrentState();
  const toggleToolbar = useToolbarToggleState();

  React.useEffect(() => {
    dispatch(setToolbarItem('character'));
  }, [dispatch]);

  return (
    <ModalV2 open={openToolbar} onClose={toggleToolbar}>
      <Base>
        <ToolDrawer>
          {currentToolbarItem === 'character' && <CharacterItem />}
          {currentToolbarItem === 'upload' && <UploadItem />}
          {currentToolbarItem === 'video' && <VideoItem />}
          {currentToolbarItem === 'audio' && <AudioItem />}
          {currentToolbarItem === 'cc' && <ClosedCaptionItem />}
        </ToolDrawer>
        <Toolbar>
          <CloseBox>
            <CloseButton size={32} radius={6} onClick={toggleToolbar}>
              <XIcon size={32} weight={'bold'} color={'var(--color-black)'} />
            </CloseButton>
          </CloseBox>
          <EditorToolbarItems />
        </Toolbar>
      </Base>
    </ModalV2>
  );
};

export default EditorToolbar;

const Base = styled.div`
  max-width: 474px;
  width: 100%;
  height: 100%;
  background: var(--color-white);
  display: flex;
  margin-left: auto;
`;

const ToolDrawer = styled.div`
  width: calc(100% - 72px);
  height: 100%;
  border-left: 1px solid var(--color-grey-100);
`;

const Toolbar = styled.div`
  width: 72px;
  height: 100%;
  border-right: 1px solid var(--color-grey-100);
  background: var(--color-grey-50);
`;

const CloseBox = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled(IconButton)`
  background: var(--color-grey-50);
`;
