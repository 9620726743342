import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import { Toast } from 'components/common';
import { DragAndDropUpload } from 'components/editor';
import {
  fetchExtractFileAdd,
  overrideExtractFileText,
  appendExtractFileText,
  selectCurrentPageKey,
  setOpenPageLimitToast,
  setOpenCellLimitToast,
  selectCurrentPagePreview,
} from 'features/editor/editorSlice';
import {
  usePusherChannelId,
  useCommonModalOpen,
} from 'features/editor/providers';

const isUploadError = (error: any): boolean => {
  if (typeof error.message === 'undefined') return false;
  if (typeof error.message !== 'string') return false;
  if (error.message === 'default') return true;
  return false;
};

const isPageLimitError = (error: any): boolean => {
  if (typeof error.type === 'undefined') return false;
  if (typeof error.type !== 'string') return false;
  if (error.type === 'pageLimit') return true;
  return false;
};

const isCellLimitError = (error: any): boolean => {
  if (typeof error.message === 'undefined') return false;
  if (typeof error.message !== 'string') return false;
  if (error.message === 'cellLimit') return true;
  return false;
};

const ScriptUpload: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'scriptUpload' });

  const dispatch = useAppDispatch();

  const { initialMlModelName } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      initialMlModelName: data?.[0]?.name,
    }),
  });

  const channelId = usePusherChannelId();

  const selectedPageKey = useAppSelector(selectCurrentPageKey);
  const previewContent = useAppSelector(selectCurrentPagePreview);

  const openAddPositionModal = useCommonModalOpen();

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const toastMessage = React.useRef<string>('');

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (previewContent.type === 'video') {
      e.preventDefault();
      toastMessage.current = t('error.videoProject');
      toggleOpenToast();
    }
  };

  const handleChangeFile = async (newFile: File) => {
    if (channelId === undefined) return;

    if (previewContent.type === 'video') {
      toastMessage.current = t('error.videoProject');
      toggleOpenToast();
      return;
    }

    if (!newFile.name.endsWith('.txt') && !newFile.name.endsWith('.docx')) {
      toastMessage.current = t('error.extension');
      toggleOpenToast();
      return;
    }

    if (newFile.size > 20 * 1024 * 1024) {
      toastMessage.current = t('error.fileSize');
      toggleOpenToast();
      return;
    }

    // for mixpanel
    const file = { name: newFile.name, exp: '', size: newFile.size };
    try {
      if (newFile.name.indexOf('.') >= 0) {
        file.name = newFile.name.substring(0, newFile.name.lastIndexOf('.'));
        file.exp = newFile.name.substring(
          newFile.name.lastIndexOf('.') + 1,
          newFile.name.length
        );
      }
    } catch {}

    try {
      const { info, wordBook } = await dispatch(
        fetchExtractFileAdd({
          file: newFile,
          type: 'audio_only',
          channelId,
        })
      ).unwrap();

      openAddPositionModal({
        content: t('override.text'),
        submitText: t('override.yes'),
        secondSubmitText: t('override.no'),
        submit() {
          if (selectedPageKey === undefined) return;
          if (initialMlModelName === undefined) return;

          dispatch(
            overrideExtractFileText({
              startPageKey: selectedPageKey,
              info,
              wordBook,
              modelName: initialMlModelName,
            })
          );
        },
        secondSubmit() {
          if (selectedPageKey === undefined) return;
          if (initialMlModelName === undefined) return;

          dispatch(
            appendExtractFileText({
              startPageKey: selectedPageKey,
              info,
              wordBook,
              modelName: initialMlModelName,
            })
          );
        },
      });
    } catch (error) {
      if (isUploadError(error)) {
        toastMessage.current = t('error.default');
        toggleOpenToast();
        return;
      }

      if (isPageLimitError(error)) {
        dispatch(setOpenPageLimitToast(true));
        return;
      }

      if (isCellLimitError(error)) {
        dispatch(setOpenCellLimitToast(true));
        return;
      }

      console.error(error);
    }
  };

  return (
    <Base>
      <Title>{t('title')}</Title>
      <Subtitle>{t('subtitle')}</Subtitle>
      <DragAndDropZone onClick={handleClick}>
        <DragAndDropUpload
          accept={'.txt,.docx'}
          helperText={t('helperText')}
          onChangeFile={handleChangeFile}
        />
      </DragAndDropZone>
      <Toast
        in={openToast}
        message={toastMessage.current}
        onClose={toggleOpenToast}
        timeout={1500}
        severity={'error'}
      />
    </Base>
  );
};

export default ScriptUpload;

const Base = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  padding: 0 16px;
`;

const Subtitle = styled.span`
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-grey-700);
  margin-top: 8px;
  padding: 0 16px;
`;

const DragAndDropZone = styled.div`
  margin: 16px;
  width: calc(100% - 32px);
`;
