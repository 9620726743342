import * as React from 'react';
import constate from 'constate';
import { CommonModal } from 'features/editor/Modals';
import type { ChildrenProps } from './types';

type Callback = () => void;

interface CallbackRecord {
  submit?: Callback;
  secondSubmit?: Callback;
  cancel?: Callback;
}

interface Texts {
  content: string;
  submitText: string;
  secondSubmitText?: string;
  cancelText?: string;
}

type OpenModalParams = CallbackRecord & Partial<Texts>;

function useCommonModal() {
  const [open, setOpen] = React.useState<boolean>(false);

  const [texts, setTexts] = React.useState<Texts>({
    content: '',
    submitText: '',
  });

  const callback = React.useRef<CallbackRecord>({});

  const openModal = (params?: OpenModalParams) => {
    callback.current = {
      submit: params?.submit,
      secondSubmit: params?.secondSubmit,
      cancel: params?.cancel,
    };
    setTexts({
      content: params?.content ?? '',
      submitText: params?.submitText ?? '',
      secondSubmitText: params?.secondSubmitText,
      cancelText: params?.cancelText,
    });

    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    callback.current = {};
  };

  const handleClickSubmit = () => {
    callback.current?.submit?.();
    closeModal();
  };

  const handleClickSecondSubmit = () => {
    callback.current?.secondSubmit?.();
    closeModal();
  };

  const handleClickCancel = () => {
    callback.current?.cancel?.();
    closeModal();
  };

  return {
    open,
    texts,
    openModal,
    closeModal,
    handleClickSubmit,
    handleClickSecondSubmit,
    handleClickCancel,
  };
}

const [
  Provider,
  useCommonModalState,
  useCommonModalTexts,
  useCommonModalOpen,
  useCommonModalClose,
  useCommonModalSubmit,
  useCommonModalSecondSubmit,
  useCommonModalCancel,
] = constate(
  useCommonModal,
  (state) => state.open,
  (state) => state.texts,
  (state) => state.openModal,
  (state) => state.closeModal,
  (state) => state.handleClickSubmit,
  (state) => state.handleClickSecondSubmit,
  (state) => state.handleClickCancel
);

const Modal: React.FC<ChildrenProps> = ({ children }) => {
  const open = useCommonModalState();
  const texts = useCommonModalTexts();

  return (
    <>
      {open && (
        <CommonModal
          content={texts.content}
          submit={texts.submitText}
          secondSubmit={texts.secondSubmitText}
          cancel={texts.cancelText}
        />
      )}
      {children}
    </>
  );
};

export const CommonModalProvider = ({ children }: ChildrenProps) => (
  <Provider>
    <Modal>{children}</Modal>
  </Provider>
);

export {
  useCommonModalOpen,
  useCommonModalClose,
  useCommonModalSubmit,
  useCommonModalSecondSubmit,
  useCommonModalCancel,
};
