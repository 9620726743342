import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { XCircleIcon } from 'assets/svg';
import { typography } from 'styles';
import type { GradeType } from 'components/membership/constants';

interface PaymentsFailureProps {
  grade: GradeType;
}

const PaymentsFailure: React.FC<PaymentsFailureProps> = ({ grade }) => {
  const { t } = useTranslation('client', { keyPrefix: 'payment.failure' });

  const navigate = useNavigate();

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    navigate(`/client/payment?grade=${grade}`, { replace: true });
  };

  return (
    <Base>
      <XCircleIcon size={80} color={'var(--color-danger)'} />
      <Title>{t('title', { grade })}</Title>
      <Subtitle>{t('subTitle')}</Subtitle>
      <PinkButton onClick={onClick}>
        <span>{t('button')}</span>
      </PinkButton>
    </Base>
  );
};

export default PaymentsFailure;

const Base = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 120px;
`;

const Title = styled.span`
  ${typography.title.large}
`;

const Subtitle = styled.span`
  ${typography.body.large}
`;

const PinkButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  flex: none;
  width: 100%;
  max-width: 240px;
  padding: 16px 13px;
  margin-top: 16px;

  border: none;
  border-radius: 10px;
  background: var(--color-pink);
  cursor: pointer;

  span {
    ${typography.title.medium}
    color: var(--color-white);
  }

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;
