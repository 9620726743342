import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { PlayCircleIcon, PauseCircleIcon, TailSpinIcon } from 'assets/svg';
import {
  selectPageTextDatas,
  fetchMergeAudios,
} from 'features/editor/editorSlice';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
} from 'features/editor/providers';

interface PageTitleProps {
  isSelected: boolean;
  pageNum: number;
  pageKey: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  isSelected,
  pageNum,
  pageKey,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const dispatch = useAppDispatch();

  const prevMergeData = React.useRef<string>('');
  const textDatas = useAppSelector(selectPageTextDatas(pageKey));

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const handleClickPage = async () => {
    const el = ref.current;
    if (el === null) return;
    if (textDatas.length === 0) return;

    if (!state.paused) {
      editorAudioPause();
      return;
    }

    if (prevMergeData.current === JSON.stringify(textDatas)) {
      editorAudioPlay(controls);
      return;
    }

    try {
      setMergeLoading(true);
      prevMergeData.current = JSON.stringify(textDatas);
      const url = await dispatch(fetchMergeAudios(textDatas)).unwrap();
      el.src = url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Base isSelected={isSelected}>
      <Title>{t('label.pageTitle', { pageNum })}</Title>
      <Button onClick={handleClickPage}>
        {mergeLoading ? (
          <TailSpinIcon width={16} height={16} />
        ) : (
          <>
            {state.paused ? (
              <PlayCircleIcon
                size={16}
                color={'currentColor'}
                weight={'fill'}
              />
            ) : (
              <PauseCircleIcon
                size={16}
                color={'currentColor'}
                weight={'fill'}
              />
            )}
          </>
        )}
        <span>{state.paused ? t('text.play') : t('text.pause')}</span>
      </Button>
      <>{element}</>
    </Base>
  );
};

export default PageTitle;

const Base = styled.div<{ isSelected: boolean }>`
  --title-color: ${({ isSelected }) =>
    isSelected ? 'var(--color-pink-500)' : 'var(--color-grey-700)'};
  --title-bg-color: ${({ isSelected }) =>
    isSelected
      ? `linear-gradient(
			  0deg,
				rgba(255, 255, 255, 0.92),
				rgba(255, 255, 255, 0.92)
		  ), var(--color-pink-500)`
      : 'var(--color-grey-50)'};
  --button-color: ${({ isSelected }) =>
    isSelected ? 'var(--color-pink-700)' : 'var(--color-grey-700)'};

  padding: 12px 8px;
  width: 100%;
  background: var(--title-bg-color);
  z-index: 1;

  display: flex;
  align-items: center;

  @supports (position: sticky) or (position: -webkit-sticky) {
    position: sticky;
    top: -1px;
  }
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--title-color);
  margin-right: 16px;
`;

const Button = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;

  font-size: 12px;
  line-height: 1.4;
  color: var(--button-color);
  cursor: pointer;

  svg + span {
    margin-left: 4px;
  }
`;
