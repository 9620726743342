import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useResizeObserver } from 'lib/hooks';
import { hexToRgb } from 'lib/utils';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ToggleSwitch, Radio, Slider } from 'components/editor';
import {
  setClosedCaptionState,
  selectCurrentClosedCaptionState,
} from 'features/editor/editorSlice';

const makeBgColor = (color: string, opacity: number): string => {
  const { r, g, b } = hexToRgb(color);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const ClosedCaptionTool: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'cc' });

  const { use, fontSize, fontColor, backgroundColor, backgroundOpacity } =
    useAppSelector(selectCurrentClosedCaptionState);

  const dispatch = useAppDispatch();

  const setStyle = React.useCallback(
    (instance: HTMLSpanElement) => {
      if (!use) return;
      if (fontSize === undefined) return;
      if (fontColor === undefined) return;
      if (backgroundColor === undefined) return;
      if (backgroundOpacity === undefined) return;

      const containerEl = instance.parentElement;
      if (containerEl === null) return;

      const { width } = containerEl.getBoundingClientRect();

      const ratio = width / 1920;
      const py = 20 * ratio;
      const px = 25 * ratio;

      instance.style.cssText = `
        --cc-color: ${fontColor};
        --cc-bg-color: ${makeBgColor(backgroundColor, backgroundOpacity)};
        --cc-font-size: ${fontSize * ratio * 3}px;
        --py: ${py}px;
        --px: ${px}px;
      `;
    },
    [use, fontSize, fontColor, backgroundColor, backgroundOpacity]
  );

  const refCallback: React.RefCallback<HTMLSpanElement> = (instance) => {
    if (instance === null) return;

    setStyle(instance);
  };

  const { ref, width } = useResizeObserver();

  return (
    <Base ref={ref}>
      <TitleBox>
        <Title>{t('title.default')}</Title>
        <ToggleSwitch
          value={use}
          onChange={(newValue) => {
            dispatch(setClosedCaptionState({ use: newValue }));
          }}
        />
      </TitleBox>
      {use && (
        <>
          <PreviewBox>
            <PreviewImagePlaceholder>
              <ClosedCaptionText ref={refCallback}>
                {t('text.preview')}
              </ClosedCaptionText>
            </PreviewImagePlaceholder>
          </PreviewBox>
          <OptionBox>
            <OptionTitle>{t('title.size')}</OptionTitle>
            <OptionSliderBox>
              <RadioOption
                onClick={() => {
                  dispatch(
                    setClosedCaptionState({
                      fontColor: '#ffffff',
                      backgroundColor: '#000000',
                    })
                  );
                }}
              >
                <Radio
                  size={20}
                  checked={
                    fontColor === '#ffffff' && backgroundColor === '#000000'
                  }
                />
                <span>{t('button.bbwf')}</span>
              </RadioOption>
              <RadioOption
                onClick={() => {
                  dispatch(
                    setClosedCaptionState({
                      fontColor: '#000000',
                      backgroundColor: '#ffffff',
                    })
                  );
                }}
              >
                <Radio
                  size={20}
                  checked={
                    fontColor === '#000000' && backgroundColor === '#ffffff'
                  }
                />
                <span>{t('button.wbbf')}</span>
              </RadioOption>
            </OptionSliderBox>
          </OptionBox>
          <OptionBox>
            <OptionTitle>{t('title.opacity')}</OptionTitle>
            <OptionSliderBox>
              <Slider
                width={width - 32}
                min={0}
                max={1}
                value={backgroundOpacity}
                onChangeCommit={(newValue) => {
                  dispatch(
                    setClosedCaptionState({
                      backgroundOpacity: Math.round(newValue * 100) / 100,
                    })
                  );
                }}
              />
              <OptionSliderPivets>
                <span>0%</span>
                <span>100%</span>
              </OptionSliderPivets>
            </OptionSliderBox>
          </OptionBox>
          <OptionBox>
            <OptionTitle>{t('title.size')}</OptionTitle>
            <OptionSliderBox>
              <Slider
                width={width - 32}
                min={5}
                max={20}
                value={fontSize}
                onChangeCommit={(newValue) => {
                  dispatch(
                    setClosedCaptionState({ fontSize: Math.floor(newValue) })
                  );
                }}
              />
              <OptionSliderPivets>
                <span>{t('text.small')}</span>
                <span>{t('text.large')}</span>
              </OptionSliderPivets>
            </OptionSliderBox>
          </OptionBox>
        </>
      )}
    </Base>
  );
};

export default ClosedCaptionTool;

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleBox = styled.div`
  margin: 16px 16px 0px 16px;
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  margin-right: 8px;
`;

const PreviewBox = styled.div`
  margin: 24px 16px 32px;
  width: calc(100% - 32px);
`;

const PreviewImagePlaceholder = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  background-color: var(--color-grey-700);
  position: relative;
  padding-top: 56.25%;
`;

const ClosedCaptionText = styled.span`
  font-weight: 700;
  line-height: 1.4;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);

  color: var(--cc-color);
  background: var(--cc-bg-color);
  font-size: var(--cc-font-size);
  padding: var(--py) var(--px);
`;

const OptionBox = styled.div`
  border-top: 1px solid var(--color-grey-200);
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const OptionTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
`;

const OptionSliderBox = styled.div`
  margin-top: 16px;
`;

const OptionSliderPivets = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-grey-500);
  font-size: 14px;
  line-height: 1.4;
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-black);
  cursor: pointer;

  & > span {
    margin-left: 8px;
  }

  & + & {
    margin-top: 16px;
  }
`;
