import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

interface ProgressIndicatorProps {
  progress: number;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ progress }) => {
  return (
    <Base>
      <Loading
        style={
          {
            '--loading-progress': `${(progress * 100).toFixed(0)}%`,
          } as React.CSSProperties
        }
      />
    </Base>
  );
};

export default ProgressIndicator;

const wave = keyframes`
	0% {
		transform: translateX(-100%);
	}
	60% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(100%);
	}
`;

const Base = styled.div`
  width: 100%;
  max-width: 448px;
  height: 16px;
  border-radius: 10px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    var(--color-pink);

  display: block;
  position: relative;
`;

const Loading = styled.span`
  display: block;
  width: var(--loading-progress);
  height: 100%;
  border-radius: 10px;
  background: var(--color-pink);
  transition: width 1s ease-in-out;
  position: relative;
  overflow: hidden;

  &::after {
    animation: ${wave} 1.6s linear 0.5s infinite;
    background: linear-gradient(90deg, transparent, #c60036, transparent);
    content: '';
    position: absolute;
    transform: translateX(-100%);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
