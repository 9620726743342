import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useResizeObserver } from 'lib/hooks';

import { Slider } from 'components/editor';

type PositionType = 'left' | 'center' | 'right';

interface PositionSelectProp {
  sizeRatio: number;
  onChangeSize: (newName: number) => void;
  onClickPosition: (pos: PositionType) => void;
  onClickApplyAll: () => void;
}

const PositionSelect: React.FC<PositionSelectProp> = ({
  sizeRatio,
  onChangeSize,
  onClickPosition,
  onClickApplyAll,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'videoModelSelect' });

  const { ref: sliderBoxRef, width: sliderBoxWidth } = useResizeObserver();

  const [sliderValue, setSliderValue] = React.useState<number>(sizeRatio);

  React.useEffect(() => {
    setSliderValue(sizeRatio);
  }, [sizeRatio]);

  const handleChangeSize = (newValue: number) => {
    onChangeSize(newValue);
    setSliderValue(newValue);
  };

  return (
    <Base>
      <Divider />
      <SizeBox>
        <SizeTitle>{t('title.size')}</SizeTitle>
        <SliderBox ref={sliderBoxRef}>
          <Slider
            min={50}
            max={150}
            width={sliderBoxWidth}
            value={sliderValue}
            onChangeCommit={handleChangeSize}
          />
        </SliderBox>
      </SizeBox>
      <PositionBox>
        <PositionTitle>{t('title.position')}</PositionTitle>
        <PositionButtonBox>
          <PositionButton onClick={onClickPosition.bind(null, 'left')}>
            <span>👈🏻</span>
            <span>{t('button.left')}</span>
          </PositionButton>
          <PositionButton onClick={onClickPosition.bind(null, 'center')}>
            <span>✊🏻</span>
            <span>{t('button.center')}</span>
          </PositionButton>
          <PositionButton onClick={onClickPosition.bind(null, 'right')}>
            <span>👉🏻</span>
            <span>{t('button.right')}</span>
          </PositionButton>
        </PositionButtonBox>
      </PositionBox>
      <ApplyAllBox>
        <ApplyAllButton onClick={onClickApplyAll}>
          {t('button.applyAll')}
        </ApplyAllButton>
      </ApplyAllBox>
    </Base>
  );
};

export default PositionSelect;

const Base = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  height: 1px;
  background: var(--color-grey-200);
`;

const SizeBox = styled.div`
  width: 100%;
  padding: 16px;
`;

const SizeTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
`;

const SliderBox = styled.div`
  margin-top: 16px;
`;

const PositionBox = styled.div`
  width: 100%;
  padding: 16px;
`;

const PositionTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
`;

const PositionButtonBox = styled.div`
  display: flex;
  margin-top: 10px;

  button + button {
    margin-left: 16px;
  }
`;

const PositionButton = styled.button`
  width: 112px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-grey-200);
  border-radius: 10px;
  background: var(--color-grey-50);
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-black);
`;

const ApplyAllBox = styled.div`
  width: 100%;
  padding: 16px;
  margin-top: auto;
`;

const ApplyAllButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  outline: none;
  padding: 0 16px;
  border: none;

  width: 100%;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;

  color: var(--color-white);
  background: var(--color-pink);

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;
