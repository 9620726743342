import * as React from 'react';
import constate from 'constate';

interface AudioControl {
  play: () => Promise<void> | undefined;
  pause: () => void;
  seek: (time: number) => void;
  volume: (volume: number) => void;
  mute: () => void;
  unmute: () => void;
}

function useEditorAudioControls() {
  const list = React.useRef<Set<AudioControl>>(new Set());

  const register = React.useCallback((controls: AudioControl) => {
    list.current.add(controls);
  }, []);

  const pause = React.useCallback(() => {
    list.current.forEach((item) => {
      item.pause();
    });
  }, []);

  const play = React.useCallback(
    (controls: AudioControl) => {
      if (list.current.has(controls)) {
        pause();
        controls.play();
        return;
      }

      register(controls);
      pause();
      controls.play();
    },
    [register, pause]
  );

  return {
    register,
    pause,
    play,
  };
}

export const [
  EditorAudioControlsProvider,
  useEditorAudioControlsRegister,
  useEditorAudioControlsPause,
  useEditorAudioControlsPlay,
] = constate(
  useEditorAudioControls,
  (state) => state.register,
  (state) => state.pause,
  (state) => state.play
);
