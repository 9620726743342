import { createApi } from '@reduxjs/toolkit/query/react';
import queryFetcher from './queryFetcher';
import type { ObjectToCamel } from 'lib/utils';

interface RawImageAsset {
  name: string;
  display_name: string;
  download_url: string;
}

interface RawGetImageAssetsResponse {
  images: RawImageAsset[];
}

export type ImageAsset = ObjectToCamel<RawImageAsset>;

export const api = createApi({
  reducerPath: 'services/imageAssets',
  baseQuery: queryFetcher('/v1/video-maker'),
  tagTypes: ['ImageAsset'],
  endpoints: (builder) => ({
    getWatermark: builder.query<ImageAsset[], void>({
      query: () => ({
        url: '/image/watermark',
        method: 'GET',
      }),
      providesTags: [{ type: 'ImageAsset', id: 'Watermark' }],
      transformResponse: (resp: RawGetImageAssetsResponse) => {
        const images: ImageAsset[] = resp.images.map((image) => ({
          name: image.name,
          displayName: image.display_name,
          downloadUrl: image.download_url,
        }));

        return images;
      },
    }),
  }),
});

export const { useGetWatermarkQuery, useLazyGetWatermarkQuery } = api;

export default api;
