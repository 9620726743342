import * as React from 'react';
import styled from '@emotion/styled';
import Backdrop from './Backdrop';
import Portal from './Portal';

const modalStyles: React.CSSProperties = {
  opacity: 1,
  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
};

interface ModalProps {
  id: string;
  disabledBackdrop?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  id,
  disabledBackdrop = false,
  children,
  onClose,
}) => {
  const backdropRef = React.useRef<HTMLDivElement | null>(null);

  const handleBackdropClick: React.MouseEventHandler<HTMLElement> = (e) => {
    if (e.target !== e.currentTarget) return;

    if (onClose) {
      onClose();
    }
  };

  const handleContainerClick: React.MouseEventHandler<HTMLDivElement> = () => {
    const backdropEl = backdropRef.current;
    if (backdropEl === null) return;
    backdropEl.click();
  };

  const wrapChildrenClick: React.MouseEventHandler<HTMLElement> = (e) => {
    if (React.isValidElement(children)) {
      if (children.props.hasOwnProperty('onClick')) {
        children.props.onClick(e);
      }
    }

    e.stopPropagation();
  };

  return (
    <Portal id={id}>
      {!disabledBackdrop && (
        <Backdrop
          onClick={handleBackdropClick}
          style={modalStyles}
          ref={backdropRef}
        />
      )}
      {React.isValidElement(children) && (
        <ModalContainer
          id={`${id}-container`}
          onClick={handleContainerClick}
          tabIndex={-1}
        >
          {React.cloneElement(children, {
            role: 'dialog',
            onClick: wrapChildrenClick,
          })}
        </ModalContainer>
      )}
    </Portal>
  );
};

export default Modal;

const ModalContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
