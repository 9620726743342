import * as React from 'react';
import * as ReactDOM from 'react-dom';
import KakaotalkService from 'lib/KakaotalkService';
import GoogleAuthService from 'lib/GoogleAuthService';
import AppV2 from './app/AppV2';
import reportWebVitals from './reportWebVitals';

import 'locales';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (process.env.ONAIR_PLATFORM_TYPE === 'onair') {
  KakaotalkService.boot();
  GoogleAuthService.boot();
}

if (process.env.ONAIR_INTERNAL === 'N') {
  Sentry.init({
    dsn: process.env.ONAIR_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    autoSessionTracking: true,
    release: process.env.ONAIR_RELEASE_VERSION,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <AppV2 />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
