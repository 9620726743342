import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { RefreshErrorDialog } from 'features/auth';
import {
  fetchUpdateProject,
  selectNetworkState,
} from 'features/editor/editorSlice';

const AuthErrorAutosave: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleMountedAutosave = () => {
    dispatch(fetchUpdateProject());
  };

  const network = useAppSelector(selectNetworkState);

  return (
    <RefreshErrorDialog
      onMounted={handleMountedAutosave}
      saving={network === 'save'}
    />
  );
};

export default AuthErrorAutosave;
