import * as React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import { getHexLuminance } from 'lib/utils';
import { CaretDownIcon } from 'assets/svg';
import {
  selectCurrentPageKey,
  selectCurrentPageSTV,
  removePageStv,
} from 'features/editor/editorSlice';
import { useVoiceModelsModalOpen } from 'features/editor/providers';
import { sort, voiceModelCompare } from 'features/editor/utils';
import type { VoiceModel } from 'app/services/voiceModel';

const getModelProfileViewStyle = (baseColor: string) =>
  ({
    '--model-view-color':
      getHexLuminance(baseColor) > 0.5
        ? 'var(--color-black)'
        : 'var(--color-white)',
    '--model-view-bg-color': baseColor,
    '--model-view-border':
      baseColor === '#FFFFFF' ? '1px solid var(--color-grey-500)' : 'none',
  } as React.CSSProperties);

interface CellModelNameProps {
  modelName: string;
  onChange: (s: string) => void;
}

const CellModelName: React.FC<CellModelNameProps> = ({
  modelName,
  onChange,
}) => {
  const { model } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => {
      let ret: { model?: VoiceModel } = { model: undefined };
      if (data === undefined) return ret;

      ret.model = data.find((el) => el.name === modelName);

      if (ret.model === undefined) {
        ret.model = sort(data, voiceModelCompare)?.[0];
      }

      return ret;
    },
  });

  const currentPageKey = useAppSelector(selectCurrentPageKey);

  const dispatch = useAppDispatch();
  const stv = useAppSelector(selectCurrentPageSTV);

  const checkRemovePageStv = (name) => {
    if (currentPageKey === undefined) return;
    if (stv === undefined) return;
    const { audioModelName } = stv;
    if (audioModelName !== name) {
      dispatch(
        removePageStv({
          pageKey: currentPageKey,
        })
      );
    }
  };

  const openModal = useVoiceModelsModalOpen();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    if (currentPageKey === undefined) return;

    openModal({
      key: [currentPageKey, ''],
      name: modelName,
      type: 'cell',
      callback(name) {
        onChange(name);
        checkRemovePageStv(name);
      },
    });
  };

  return (
    <Base onClick={handleClick}>
      {model !== undefined && (
        <>
          <ModelProfileView
            style={getModelProfileViewStyle(model.defaultColor)}
          >
            {model.displayName[0].toUpperCase()}
          </ModelProfileView>
          <ModelName>{model.displayName}</ModelName>
          <CaretDownIcon size={12} weight={'fill'} />
        </>
      )}
    </Base>
  );
};

export default CellModelName;

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;
  width: 120px;
  height: 40px;
  background: var(--color-white);
  cursor: pointer;
`;

const ModelProfileView = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: var(--model-view-color);
  background-color: var(--model-view-bg-color);
  border: var(--model-view-border);
  font-size: 10px;
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModelName = styled.span`
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-black);
  width: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
