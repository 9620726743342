import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useResizeObserver } from 'lib/hooks';
import { selectPagePreview } from 'features/editor/editorSlice';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { LazyImage } from 'features/editor/common';
import BlankPageImg from 'assets/img/blank-page.jpeg';
import VideoBox from './VideoBox';
import CCBox from './CCBox';
import WatermarkImage from './WatermarkImage';
import VideoOverlay from './VideoOverlay';

interface PageCardPreviewProps {
  pageKey: string;
  isSelected: boolean;
  src?: string;
}

const PageCardPreview: React.FC<PageCardPreviewProps> = ({
  pageKey,
  isSelected,
  src,
}) => {
  const previewContent = useAppSelector(selectPagePreview(pageKey));

  const { ref, width } = useResizeObserver();

  const { data: userInfo } = useGetUserInfoQuery();

  return (
    <Base ref={ref} isSelected={isSelected}>
      {previewContent.type === 'placeholder' && (
        <Image src={BlankPageImg} alt={'slide image'} />
      )}
      {previewContent.type === 'image' && (
        <Image src={previewContent.url} alt={'slide image'} />
      )}
      {previewContent.type === 'video' && (
        <VideoBox src={previewContent.url} poster={previewContent.thumbnail} />
      )}
      <CCBox imageWidth={width} />
      {userInfo && !userInfo.disableWatermark && (
        <WatermarkImage imageWidth={width} />
      )}
      <VideoOverlay imageWidth={width} pageKey={pageKey} />
    </Base>
  );
};

export default PageCardPreview;

const Base = styled.div<{ isSelected: boolean }>`
  --preview-border-color: ${({ isSelected }) =>
    isSelected ? 'var(--color-pink)' : 'var(--color-grey-200)'};

  border: 2px solid var(--preview-border-color);
  border-radius: 10px;
  overflow: hidden;

  width: 100%;
  aspect-ratio: 16 / 9;

  position: relative;
`;

const Image = styled(LazyImage)`
  width: 100%;
  height: 100%;
`;
