import * as React from 'react';
import { useMedia } from 'react-use';
import styled from '@emotion/styled';
import SamsungImage from 'assets/img/main-samsung.png';
import MBCImage from 'assets/img/main-mbc.png';
import CJENMImage from 'assets/img/main-cjenm.png';
import CulturalHeritageImage from 'assets/img/main-cultural-heritage.png';
import KoreanBrailleImage from 'assets/img/main-korean-braille.png';
import TwipImage from 'assets/img/main-twip.png';
import SBSImage from 'assets/img/main-sbs.png';
import KyoboImage from 'assets/img/main-kyobo.png';

interface Partner {
  name: string;
  src: string;
}

export const partners: Partner[] = [
  {
    name: 'samsung',
    src: SamsungImage,
  },
  {
    name: 'mbc',
    src: MBCImage,
  },
  {
    name: 'cjenm',
    src: CJENMImage,
  },
  {
    name: 'cultural-heritage',
    src: CulturalHeritageImage,
  },
  {
    name: 'korean-braille',
    src: KoreanBrailleImage,
  },
  {
    name: 'twip',
    src: TwipImage,
  },
  {
    name: 'sbs',
    src: SBSImage,
  },
  {
    name: 'kyobo',
    src: KyoboImage,
  },
];

const PartnersBox: React.FC = () => {
  const isDesktop = useMedia('(min-width: 1440px)');
  const isTablet = useMedia('(min-width: 820px)');

  if (isDesktop) {
    return (
      <Base>
        {partners.map((partner) => (
          <Image
            src={partner.src}
            alt={partner.name}
            width={90}
            height={90}
            key={partner.name}
          />
        ))}
      </Base>
    );
  }

  if (isTablet) {
    return (
      <Base>
        <div>
          {partners.slice(0, 5).map((partner) => (
            <Image
              src={partner.src}
              alt={partner.name}
              width={90}
              height={90}
              key={partner.name}
            />
          ))}
        </div>
        <div>
          {partners.slice(5).map((partner) => (
            <Image
              src={partner.src}
              alt={partner.name}
              width={90}
              height={90}
              key={partner.name}
            />
          ))}
        </div>
      </Base>
    );
  }

  return (
    <Base>
      <div>
        {partners.slice(0, 3).map((partner) => (
          <Image
            src={partner.src}
            alt={partner.name}
            width={60}
            height={60}
            key={partner.name}
          />
        ))}
      </div>
      <div>
        {partners.slice(3, 6).map((partner) => (
          <Image
            src={partner.src}
            alt={partner.name}
            width={60}
            height={60}
            key={partner.name}
          />
        ))}
      </div>
      <div>
        {partners.slice(6).map((partner) => (
          <Image
            src={partner.src}
            alt={partner.name}
            width={60}
            height={60}
            key={partner.name}
          />
        ))}
      </div>
    </Base>
  );
};

export default PartnersBox;

const Base = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 1440px) {
    flex-direction: row;
  }

  & > div {
    flex: 1;

    img + img {
      margin-left: 8px;

      @media (min-width: 820px) {
        margin-left: 60px;
      }
    }
  }
`;

const Image = styled.img`
  object-fit: contain;
`;
