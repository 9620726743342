import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { hexToRgb } from 'lib/utils';
import {
  selectCCOptions,
  selectCurrentCellCC,
} from 'features/editor/editorSlice';

const makeBgColor = (color: string, opacity: number): string => {
  const { r, g, b } = hexToRgb(color);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

type ContentType = 'image' | 'video' | 'placeholder';

interface CCBoxProps {
  imageWidth: number;
  contentType: ContentType;
}

const CCBox: React.FC<CCBoxProps> = ({ imageWidth, contentType }) => {
  const options = useAppSelector(selectCCOptions);
  const text = useAppSelector(selectCurrentCellCC);

  const setStyle = React.useCallback(
    (instance: HTMLParagraphElement) => {
      if (options === undefined) return;

      const containerEl = instance.parentElement;
      if (containerEl === null) return;
      const previewEl = containerEl.parentElement;
      if (previewEl === null) return;

      const { fontSize, fontColor, backgroundColor, backgroundOpacity } =
        options;

      const ratio = imageWidth / 1920;
      const py = 20 * ratio;
      const px = 25 * ratio;

      instance.style.cssText = `
			  --cc-color: ${fontColor};
				--cc-bg-color: ${makeBgColor(backgroundColor, backgroundOpacity)};
			  --cc-font-size: ${fontSize * ratio * 3}px;
				--py: ${py}px;
				--px: ${px}px;
			`;
    },
    [options, imageWidth]
  );

  const refCallback: React.RefCallback<HTMLParagraphElement> = (instance) => {
    if (instance === null) return;

    setStyle(instance);
  };

  if (options?.use && text && text.length > 0) {
    return (
      <Base type={contentType}>
        <CCText ref={refCallback}>
          {text.split('\n').map((el, idx) => (
            <span key={`${el}-${idx}`}>{el}</span>
          ))}
        </CCText>
      </Base>
    );
  }

  return null;
};

export default CCBox;

const Base = styled.div<{ type: ContentType }>`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 4%;
  margin: 0 8%;
  ${({ type }) => type === 'video' && 'margin-bottom: 40px;'}
  text-align: center;
  z-index: 2;
`;

const CCText = styled.p`
  color: var(--cc-color);
  background: var(--cc-bg-color);
  font-size: var(--cc-font-size);
  padding: var(--py) var(--px);

  display: inline-block;

  span {
    display: block;
    min-height: calc(var(--cc-font-size) * 1.15);
  }
`;
