import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useLockBodyScroll } from 'react-use';

export interface PortalProps {
  id: string;
  children: React.ReactNode;
}

const Portal = ({ id, children }: PortalProps) => {
  const ref = React.useRef<HTMLDivElement>(document.createElement('div'));

  useLockBodyScroll();

  React.useEffect(() => {
    const el = ref.current;

    el.setAttribute('id', id);
    el.setAttribute('role', 'presentation');
    el.setAttribute('aria-hidden', 'true');

    el.style.cssText = `
      position: fixed;
      z-index: 10;
      inset: var(--popup-index);
      inset: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    `;

    document.body.appendChild(el);

    return () => {
      document.body.removeChild(el);
    };
  });

  return ReactDOM.createPortal(children, ref.current);
};

export default Portal;
