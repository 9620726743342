import * as React from 'react';
import styled from '@emotion/styled';
import { XIcon } from 'assets/svg';
import { Modal } from 'components/common';
import {
  useCommonModalSubmit,
  useCommonModalSecondSubmit,
  useCommonModalCancel,
} from 'features/editor/providers';

interface CommonProps {
  content: string;
  submit: string;
  secondSubmit?: string;
  cancel?: string;
}

const Common: React.FC<CommonProps> = ({
  content,
  submit,
  secondSubmit,
  cancel,
}) => {
  const handleClickSubmit = useCommonModalSubmit();
  const handleClickSecondSubmit = useCommonModalSecondSubmit();
  const handleClickCancel = useCommonModalCancel();

  return (
    <Modal id={'project-common-dialog'} onClose={handleClickCancel}>
      <Base>
        <Title>
          <CloseButton onClick={handleClickCancel}>
            <XIcon size={24} weight={'bold'} />
          </CloseButton>
        </Title>
        <Content>
          <span>{content}</span>
        </Content>
        <Actions>
          <SubmitButton onClick={handleClickSubmit}>{submit}</SubmitButton>
          {secondSubmit !== undefined && (
            <SubmitButton onClick={handleClickSecondSubmit}>
              {secondSubmit}
            </SubmitButton>
          )}
          {cancel !== undefined && (
            <CancelButton onClick={handleClickCancel}>{cancel}</CancelButton>
          )}
        </Actions>
      </Base>
    </Modal>
  );
};

export default Common;

const Base = styled.div`
  width: calc(100% - 32px);
  max-width: 440px;
  border-radius: 10px;
  background-color: var(--color-white);
`;

const Title = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  cursor: pointer;
`;

const Content = styled.div`
  width: 100%;
  padding: 16px 24px;

  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;

  color: var(--color-black);
`;

const Actions = styled.div`
  width: 100%;
  padding: 16px 24px 32px;
  display: flex;
  flex-direction: column;

  & button + button {
    margin-top: 16px;
  }
`;

const SubmitButton = styled.button`
  padding: 0;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  color: var(--color-black);
`;

const CancelButton = styled.button`
  margin-top: 16px;
  padding: 0;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  color: var(--color-black);
`;
