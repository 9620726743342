import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';
import BannerImg from 'assets/img/membership-persona-banner.png';

const MembershipGuide: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'videoModelSelect' });

  return (
    <Box>
      <GuideText>{t('membership')}</GuideText>
      {process.env.ONAIR_PLATFORM_TYPE === 'onair' && (
        <Link to={'/client/membership'}>
          <BannerBox>
            <img height={72} src={BannerImg} alt={t('membership')} />
          </BannerBox>
        </Link>
      )}
    </Box>
  );
};

export default MembershipGuide;

const Box = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-top: 16px;
  padding: 0 16px;
`;

const GuideText = styled.span`
  white-space: pre;
  ${typography.body.large}
`;

const BannerBox = styled.div`
  margin-top: 16px;
  background-color: #ff77a6;
  border-radius: 10px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
