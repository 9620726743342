import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { selectCurrentPageNum } from 'features/editor/editorSlice';
import PlayListenButton from './PlayListenButton';
import Timestamp from './Timestamp';
import Multiplier from './Multiplier';

const Title: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const currentPageNum = useAppSelector(selectCurrentPageNum);
  const pageNum = (currentPageNum ?? 0) + 1;

  return (
    <Base>
      <PageOptionBox>
        <Text>{t('label.pageTitle', { pageNum })}</Text>
        <PlayListenButton />
      </PageOptionBox>
      <TimelineOptionBox>
        <Timestamp />
        <Multiplier />
      </TimelineOptionBox>
    </Base>
  );
};

export default Title;

const Base = styled.div`
  padding: 12px 8px;
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.92),
      rgba(255, 255, 255, 0.92)
    ),
    var(--color-pink-500);
  z-index: 1;

  display: flex;
  align-items: flex-start;
`;

const PageOptionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
`;

const Text = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-pink-500);
  margin-right: 16px;
`;

const TimelineOptionBox = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;
