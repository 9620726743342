import { loadScript } from './utils';

const isInjectError = (error: unknown): boolean => {
  if (error instanceof Error) {
    if (error.message === 'script already injected') {
      return true;
    }
  }

  return false;
};

class GoogleAuthService {
  isInitialised = false;
  private isLoaded: boolean = false;
  private scriptSrc: string = 'https://apis.google.com/js/api.js';

  constructor() {
    if (process.env.ONAIR_PLATFORM_TYPE !== 'onair') return;
    this.init();
  }

  private init(): void {
    if (typeof window.gapi !== 'undefined') {
      console.log('Google SDK script include twice.');
    }

    const load = async (): Promise<void> => {
      if (this.isLoaded) return;

      try {
        await loadScript(this.scriptSrc);

        window.gapi.load('auth2', () => {
          this.isLoaded = true;

          window.gapi.auth2
            .init({
              client_id: process.env.ONAIR_GOOGLE_CLIENT_ID,
              ux_mode: 'redirect',
              redirect_uri: `${window.origin}/auth/login`,
            })
            .then(
              () => {},
              (error) => console.error(error)
            );
        });
      } catch (error) {
        if (!isInjectError(error)) console.error(error);
      }
    };

    if (document.readyState === 'complete') {
      load();
    } else {
      window.addEventListener('DOMContentLoaded', load, false);
      window.addEventListener('load', load, false);
    }
  }

  boot(): void {
    this.isInitialised = true;
  }

  signIn() {
    if (!this.isInitialised) return;
    if (!this.isLoaded) return;

    const authInstance = window.gapi.auth2.getAuthInstance();
    if (authInstance === null) return;

    authInstance
      .signIn({
        prompt: 'select_account',
      })
      .then(
        () => {},
        (error) => console.error(error)
      );
  }
}

export default new GoogleAuthService();
