import * as React from 'react';
import styled from '@emotion/styled';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';

interface TimelineCellTooltipProps {
  name: string;
  text: string;
  style: React.CSSProperties;
}

const TimelineCellTooltip = React.forwardRef<
  HTMLDivElement,
  TimelineCellTooltipProps
>(({ name, text, style }, ref) => {
  const { model } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      model: data?.find((el) => el.name === name),
    }),
  });

  return (
    <Base style={style} ref={ref}>
      <Title>{model?.displayName}</Title>
      <Content>{text}</Content>
    </Base>
  );
});

export default TimelineCellTooltip;

const Base = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  padding: 4px;
  background: var(--color-black);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
`;

const Title = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-white);
`;

const Content = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-300);
`;
