import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ActivityIcon } from 'assets/svg';
import { typography } from 'styles';

interface GoRegisterButtonProps {
  children?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

const GoRegisterButton: React.FC<GoRegisterButtonProps> = ({
  children,
  onClick,
}) => {
  return (
    <Register onClick={onClick} to={'/auth/register'}>
      <ActivityIcon size={24} color={'currentColor'} weight={'bold'} />
      <span>{children}</span>
    </Register>
  );
};

export default GoRegisterButton;

const Register = styled(Link)`
  --hover-gradient: linear-gradient(
    rgba(255, 255, 255, 0.16),
    rgba(255, 255, 255, 0.16)
  );
  --active-gradient: linear-gradient(
    rgba(255, 255, 255, 0.32),
    rgba(255, 255, 255, 0.32)
  );

  text-decoration: none;
  border-radius: 10px;
  background: var(--color-gradient);
  box-shadow: 0px 12px 24px rgba(172, 0, 0, 0.2);
  color: var(--color-white);
  ${typography.title.medium}
  width: 100%;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--hover-gradient), var(--color-gradient);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-gradient);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-gradient);
  }

  span {
    margin-left: 8px;
  }

  @media (min-width: 820px) {
    width: 256px;
    height: 56px;
    margin-left: 0;
    margin-right: 0;

    ${typography.title.large}
    color: var(--color-white);
  }
`;
