import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectUseCC,
  setSelectCellKey,
  selectCellText,
} from 'features/editor/editorSlice';
import CellTextInputBox from './CellTextInputBox';
import CellCCInputBox from './CellCCInputBox';

interface EditableCellProps {
  pageKey: string;
  cellKey: string;
  isSelected: boolean;
  num: number;
}

const EditableCell: React.FC<EditableCellProps> = ({
  pageKey,
  cellKey,
  isSelected,
  num,
}) => {
  const useCC = useAppSelector(selectUseCC);

  const dispatch = useAppDispatch();

  const defaultText = useAppSelector(selectCellText(cellKey));

  if (defaultText === undefined) return null;
  return (
    <Base
      onClick={() => {
        if (isSelected) return;

        dispatch(setSelectCellKey(cellKey));
      }}
      isSelected={isSelected}
    >
      <CellTextInputBox
        pageKey={pageKey}
        cellKey={cellKey}
        defaultValue={defaultText}
        num={num}
      />
      {isSelected && useCC && (
        <>
          <Divider />
          <CellCCInputBox pageKey={pageKey} cellKey={cellKey} />
        </>
      )}
    </Base>
  );
};

export default EditableCell;

const Base = styled.div<{ isSelected: boolean }>`
  margin-left: auto;
  width: calc(100% - 20px);
  overflow: hidden;

  ${({ isSelected }) =>
    isSelected
      ? `
				--cell-text-input-radius: 10px 10px 0 0;
				--cell-cc-input-radius: 0 0 10px 10px;
			`
      : `
		    --cell-text-input-radius: 10px 10px 10px 10px;
			`}

  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ isSelected }) =>
    isSelected ? 'var(--color-blue)' : 'var(--color-grey-200)'};
`;

const Divider = styled.hr`
  border: none;
  background: var(--color-grey-200);
  width: 100%;
  height: 1px;
  margin: 0;
  padding: 0;
`;
