/**
 * typography system base by material 3
 * https://m3.material.io/styles/typography/tokens
 */

import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';

const fontSizeUnit = 0.0625; // calc 16px base rem unit
const fontSizeUnitToRem = (p: number) => `${p * fontSizeUnit}rem`;

const letterSpacingUnitToRem = (p: number) => `${p / 16}rem`;

export interface TypographyGroup {
  large: SerializedStyles;
  medium: SerializedStyles;
  small: SerializedStyles;
}

export const display: TypographyGroup = {
  large: css`
    line-height: ${fontSizeUnitToRem(64)};
    font-size: ${fontSizeUnitToRem(57)};
    font-weight: 700;
    letter-spacing: 0;
  `,
  medium: css`
    line-height: ${fontSizeUnitToRem(52)};
    font-size: ${fontSizeUnitToRem(45)};
    font-weight: 700;
    letter-spacing: 0;
  `,
  small: css`
    line-height: ${fontSizeUnitToRem(44)};
    font-size: ${fontSizeUnitToRem(36)};
    font-weight: 700;
    letter-spacing: 0;
  `,
};

export const headline: TypographyGroup = {
  large: css`
    line-height: ${fontSizeUnitToRem(40)};
    font-size: ${fontSizeUnitToRem(32)};
    font-weight: 400;
    letter-spacing: 0;
  `,
  medium: css`
    line-height: ${fontSizeUnitToRem(36)};
    font-size: ${fontSizeUnitToRem(28)};
    font-weight: 400;
    letter-spacing: 0;
  `,
  small: css`
    line-height: ${fontSizeUnitToRem(32)};
    font-size: ${fontSizeUnitToRem(24)};
    font-weight: 400;
    letter-spacing: 0;
  `,
};

export const title: TypographyGroup = {
  large: css`
    line-height: ${fontSizeUnitToRem(28)};
    font-size: ${fontSizeUnitToRem(22)};
    font-weight: 700;
    letter-spacing: 0;
  `,
  medium: css`
    line-height: ${fontSizeUnitToRem(24)};
    font-size: ${fontSizeUnitToRem(16)};
    font-weight: 700;
    letter-spacing: ${letterSpacingUnitToRem(0.15)};
  `,
  small: css`
    line-height: ${fontSizeUnitToRem(20)};
    font-size: ${fontSizeUnitToRem(14)};
    font-weight: 700;
    letter-spacing: ${letterSpacingUnitToRem(0.1)};
  `,
};

export const label: TypographyGroup = {
  large: css`
    line-height: ${fontSizeUnitToRem(20)};
    font-size: ${fontSizeUnitToRem(14)};
    font-weight: 500;
    letter-spacing: ${letterSpacingUnitToRem(0.1)};
  `,
  medium: css`
    line-height: ${fontSizeUnitToRem(16)};
    font-size: ${fontSizeUnitToRem(12)};
    font-weight: 500;
    letter-spacing: ${letterSpacingUnitToRem(0.5)};
  `,
  small: css`
    line-height: ${fontSizeUnitToRem(6)};
    font-size: ${fontSizeUnitToRem(11)};
    font-weight: 500;
    letter-spacing: ${letterSpacingUnitToRem(0.5)};
  `,
};

export const body: TypographyGroup = {
  large: css`
    line-height: ${fontSizeUnitToRem(24)};
    font-size: ${fontSizeUnitToRem(16)};
    font-weight: 400;
    letter-spacing: ${letterSpacingUnitToRem(0.15)};
  `,
  medium: css`
    line-height: ${fontSizeUnitToRem(20)};
    font-size: ${fontSizeUnitToRem(14)};
    font-weight: 400;
    letter-spacing: ${letterSpacingUnitToRem(0.25)};
  `,
  small: css`
    line-height: ${fontSizeUnitToRem(16)};
    font-size: ${fontSizeUnitToRem(12)};
    font-weight: 400;
    letter-spacing: ${letterSpacingUnitToRem(0.4)};
  `,
};

export const typography = {
  display,
  headline,
  title,
  label,
  body,
};
