import {
  Routes,
  Route,
  Navigate,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import { RequireAuth } from 'features/auth';

import history from './history';

import LoginPage from './auth/LoginPage';
import RegisterPage from './auth/RegisterPage';
import ResetPasswordPage from './auth/ResetPasswordPage';
import MainPage from './MainPage';
import EditorPage from './client/EditorPage';
import ProjectListPage from './client/ProjectListPage';
import ProfilePage from './client/ProfilePage';
import MembershipPage from './client/MembershipPage';
import PaymentPage from './client/PaymentPage';
import WithdrawalDonePage from './client/WithdrawalDonePage';
import ErrorPage from './ErrorPage';

import EnglishMainPage from './EnglishMainPage';

const AppRouter = () => (
  <HistoryRouter history={history}>
    <Routes>
      <Route
        index
        element={
          process.env.ONAIR_PLATFORM_TYPE === 'onair' ? (
            <MainPage />
          ) : (
            <LoginPage />
          )
        }
      />
      <Route path={'en'} element={<EnglishMainPage />} />
      <Route path={'error'} element={<ErrorPage />} />
      <Route path={'auth/login'} element={<LoginPage />} />
      <Route path={'auth/register/*'} element={<RegisterPage />} />
      <Route path={'auth/reset-password/*'} element={<ResetPasswordPage />} />
      <Route
        path={'client'}
        element={
          <RequireAuth>
            <ProjectListPage />
          </RequireAuth>
        }
      />
      <Route
        path={'client/editor'}
        element={
          <RequireAuth forceRedirectExpired={false}>
            <EditorPage />
          </RequireAuth>
        }
      />
      <Route
        path={'client/profile/*'}
        element={
          <RequireAuth>
            <ProfilePage />
          </RequireAuth>
        }
      />
      <Route
        path={'client/membership'}
        element={
          <RequireAuth>
            <MembershipPage />
          </RequireAuth>
        }
      />
      <Route
        path={'client/payment/*'}
        element={
          <RequireAuth>
            <PaymentPage />
          </RequireAuth>
        }
      />
      <Route path={'client/withdrawal-done'} element={<WithdrawalDonePage />} />
      <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Routes>
  </HistoryRouter>
);

export { AppRouter };
