import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useGetVideosQuery } from 'app/services/videoAssets';
import { useGetUserInfoQuery } from 'app/services/userInfo';

const VideoItemInfo: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'video' });

  const { grade, maxSize } = useGetUserInfoQuery(undefined, {
    selectFromResult: ({ data }) => {
      if (data === undefined) {
        return {
          grade: 'Free',
          maxSize: 0,
        };
      }

      return {
        grade: data.billingName,
        maxSize:
          data.billingName === 'Enterprise'
            ? byteConverter('GB', data.totalVideoSizeLimit)
            : byteConverter('MB', data.totalVideoSizeLimit),
      };
    },
  });

  const { byte } = useGetVideosQuery(undefined, {
    selectFromResult: ({ data }) => {
      if (data === undefined) {
        return { byte: 0 };
      }

      return {
        byte: data.reduce<number>((acc, item) => {
          acc += item.size;
          return acc;
        }, 0),
      };
    },
  });

  const usage =
    grade === 'Enterprise'
      ? byteConverter('GB', byte)
      : byteConverter('MB', byte);

  return (
    <Base>
      <HelperText>{t('text.helper', { grade, maxSize })}</HelperText>
      <UsageText>{t('text.usage', { maxSize, usage })}</UsageText>
    </Base>
  );
};

export default VideoItemInfo;

const byteConverter = (dimen: 'MB' | 'GB', size: number) => {
  if (dimen === 'MB') {
    return `${Math.round((size / 1024 / 1024) * 100) / 100}MB`;
  }

  return `${Math.round((size / 1024 / 1024 / 1024) * 100) / 100}GB`;
};

const Base = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const HelperText = styled.span`
  display: block;
  color: var(--color-grey-700);
  font-size: 14px;
  line-height: 1.4;
`;

const UsageText = styled.span`
  display: block;
  color: var(--color-grey-500);
  font-size: 14px;
  line-height: 1.4;
`;
