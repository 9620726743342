import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { LogoOverlayIcon } from 'assets/svg';

interface TemplateItemProps {
  thumbUrl?: string;
  title: string;
  uuid: string;
}

const TemplateItem: React.FC<TemplateItemProps> = ({
  thumbUrl,
  title,
  uuid,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/client/editor#uuid=${uuid}`, {
      state: { type: 'template' },
    });
  };

  return (
    <Base onClick={handleClick}>
      <ImageBox>
        {thumbUrl ? (
          <Image width={212} height={120} src={thumbUrl} />
        ) : (
          <ImagePlaceholder />
        )}
        <Overlay width={64} height={64} />
      </ImageBox>
      <Title>{title}</Title>
    </Base>
  );
};

export default TemplateItem;

const Base = styled.div`
  flex: 0 0 auto;
  width: 212px;
  height: 150px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  & + & {
    margin-left: 24px;
  }
`;

const ImageBox = styled.div`
  width: 212px;
  height: 120px;
  position: relative;
`;

const Image = styled.img`
  border-radius: 10px;
  box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08);
`;

const ImagePlaceholder = styled.div`
  width: 212px;
  height: 120px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08);
  position: relative;
`;

const Overlay = styled(LogoOverlayIcon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-black);
  width: 212px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
