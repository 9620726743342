import * as React from 'react';
import { useMatchMedia } from 'lib/hooks';
import Desktop from './EditorHeader.Desktop';
import Tablet from './EditorHeader.Tablet';
import Mobile from './EditorHeader.Mobile';

const EditorHeader: React.FC = () => {
  const isDesktop = useMatchMedia('(min-width: 1440px)');
  const isTablet = useMatchMedia('(min-width: 1024px)');

  if (isDesktop) return <Desktop />;
  if (isTablet) return <Tablet />;
  return <Mobile />;
};

export default EditorHeader;
