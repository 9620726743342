import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from 'react-click-away-listener';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import {
  copyPage,
  fetchUploadTempImage,
  overrideImageFileSlide,
  removePage,
} from 'features/editor/editorSlice';

interface PageCardDropdownProps {
  pageKey: string;
  onClose: () => void;
}

const PageCardDropdownV2: React.FC<PageCardDropdownProps> = ({
  pageKey,
  onClose,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const dispatch = useAppDispatch();

  const handleClickCopy = () => {
    onClose();
    dispatch(copyPage({ pageKey }));
  };

  const ref = React.useRef<HTMLInputElement | null>(null);

  const handleClickChangeImage = async () => {
    const el = ref.current;
    if (el === null) return;

    const file = await new Promise<File>((resolve) => {
      el.addEventListener(
        'change',
        () => {
          if (el.files === null) return;
          if (el.files.length === 0) return;

          const f = el.files[0];
          resolve(f);
        },
        { once: true }
      );

      el.click();
    });

    const data = await dispatch(fetchUploadTempImage({ file })).unwrap();

    dispatch(
      overrideImageFileSlide({
        pageKey,
        ...data,
      })
    );
  };

  const handleClickRemovePage = () => {
    onClose();
    dispatch(removePage({ pageKey }));
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Base onClick={(e) => e.stopPropagation()}>
        <OptionItem onClick={handleClickCopy}>{t('text.copy')}</OptionItem>
        <OptionItem onClick={handleClickChangeImage}>
          {t('text.changeImage')}
          <input
            type={'file'}
            accept={'image/*'}
            style={{ display: 'none' }}
            onClick={(e) => e.stopPropagation()}
            ref={ref}
          />
        </OptionItem>
        <DeleteOptionItem onClick={handleClickRemovePage}>
          {t('text.delete')}
        </DeleteOptionItem>
      </Base>
    </ClickAwayListener>
  );
};

export default PageCardDropdownV2;

const Base = styled.div`
  list-style: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-100);
  border-radius: 10px;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  padding: 8px 0;

  width: 88px;
  height: 112px;
  position: absolute;
  top: 32px;
  right: -24px;
  margin: 0;
  z-index: 1;
`;

const OptionItem = styled.div`
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;

  color: var(--color-black);

  &:hover,
  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;
  }
`;

const DeleteOptionItem = styled.div`
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;

  color: var(--color-danger);

  &:hover,
  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;
  }
`;
