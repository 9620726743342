import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { DotsThreeOutlineVerticalIcon } from 'assets/svg';
import {
  selectCurrentPageKey,
  setSelectPageKey,
} from 'features/editor/editorSlice';
import PageCardPreview from './PageCardPreview';
import PageCardDropdown from './PageCardDropdown';
import PageCardDuration from './PageCardDuration';

interface PageCardProps {
  pageKey: string;
  num: number;
  thumbnailUrl?: string;
}

const PageCard: React.FC<PageCardProps> = ({ pageKey, num, thumbnailUrl }) => {
  const currentPageKey = useAppSelector(selectCurrentPageKey);
  const isSelected = currentPageKey === pageKey;

  const [openOption, setOpenOption] = React.useState<boolean>(false);
  const toggleOpenOption = () => setOpenOption((prev) => !prev);

  const handleClickOptionButton: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    toggleOpenOption();
  };

  const dispatch = useAppDispatch();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    dispatch(setSelectPageKey(pageKey));
  };

  return (
    <Base onClick={handleClick}>
      <HeaderBox>
        <Number isSelected={isSelected}>{num}</Number>
        <OptionButton onClick={handleClickOptionButton}>
          <DotsThreeOutlineVerticalIcon
            size={16}
            weight={'fill'}
            color={'currentColor'}
          />
        </OptionButton>
        {openOption && (
          <PageCardDropdown pageKey={pageKey} onClose={toggleOpenOption} />
        )}
      </HeaderBox>
      <PageCardPreview
        pageKey={pageKey}
        isSelected={isSelected}
        src={thumbnailUrl}
      />
      <PageCardDuration pageKey={pageKey} />
    </Base>
  );
};

export default PageCard;

const Base = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const Number = styled.span<{ isSelected: boolean }>`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: ${({ isSelected }) =>
    isSelected ? 'var(--color-pink)' : 'var(--color-grey-500)'};
`;

const OptionButton = styled.button`
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  border-radius: 4px;
  color: var(--color-black);

  &:hover:not([disabled]) {
    border: 1px solid var(--color-grey-600);

    @media (hover: none) {
      border: none;
    }
  }

  &:active:not([disabled]) {
    border: 1px solid var(--color-grey-600);
  }

  &:disabled {
    color: var(--color-grey-200);
  }
`;
