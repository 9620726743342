import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { selectCurrentCellKey } from 'features/editor/editorSlice';
import CellToolBox from './CellToolBox';
import EditableCell from './EditableCell';
import DurationButtonV2 from './DurationButtonV2';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';

interface CellProps {
  pageKey: string;
  cellKey: string;
  listeners: DraggableSyntheticListeners;
}

const Cell: React.FC<CellProps> = ({ pageKey, cellKey, listeners }) => {
  const currentCellKey = useAppSelector(selectCurrentCellKey);

  const [isHover, setIsHover] = React.useState<boolean>(false);
  const handleMouseEnter = () => {
    if (isHover) return;
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    if (!isHover) return;
    setIsHover(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <EditableCell
        pageKey={pageKey}
        cellKey={cellKey}
        isSelected={currentCellKey === cellKey}
        listeners={listeners}
      />
      <Box>
        <DurationButtonV2 pageKey={pageKey} cellKey={cellKey} />
        {(currentCellKey === cellKey || isHover) && (
          <CellToolBox cellKey={cellKey} pageKey={pageKey} />
        )}
      </Box>
    </div>
  );
};

export default Cell;

const Box = styled.div`
  margin-left: 48px;
  height: 40px;
  display: flex;
  align-items: center;

  & > div:first-of-type {
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;
