export default async function fetchUrlToBlob(url: string) {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    },
  });
  const data = await response.blob();
  return data;
}
