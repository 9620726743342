import * as React from 'react';
import styled from '@emotion/styled';
import { LoadingIcon } from 'assets/svg';
import { typography } from 'styles';

interface PinkButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const PinkButton: React.FC<PinkButtonProps> = ({
  loading,
  children,
  ...rest
}) => {
  return (
    <Base disabled={rest.disabled ?? loading ?? false} {...rest}>
      {loading ? <LoadingIcon /> : children}
    </Base>
  );
};

export default PinkButton;

const Base = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  width: 100%;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  ${typography.title.medium}
  border-radius: 10px;
  border: none;
  color: var(--color-white);
  background: var(--color-pink);

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }

  &:disabled {
    background: var(--active-gradient), var(--color-pink);
  }
`;
