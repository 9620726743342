import * as React from 'react';
import { PusherProvider, usePusherChannelId } from './pusher';
import {
  VoiceModelsModalProvider,
  useVoiceModelsModalState,
  useVoiceModelsModalSelectedName,
  useVoiceModelsModalSelectedType,
  useVoiceModelsModalOpen,
  useVoiceModelsModalClose,
  useVoiceModelsModalChangeName,
} from './voiceModelModal';
import {
  CommonModalProvider,
  useCommonModalOpen,
  useCommonModalClose,
  useCommonModalSubmit,
  useCommonModalSecondSubmit,
  useCommonModalCancel,
} from './commonModal';
import {
  AudioDownloadModalProvider,
  useAudioDownloadModalState,
  useAudioDownloadProgress,
  useAudioDownloadParams,
  useAudioDownloadModalOpen,
  useAudioDownloadModalClose,
  useAudioDownloadRetry,
} from './audioDownload';
import {
  VideoDownloadModalProvider,
  useVideoDownloadModalState,
  useVideoDownloadProgress,
  useVideoDownloadParams,
  useVideoDownloadModalOpen,
  useVideoDownloadModalClose,
  useVideoDownloadRetry,
  useVideoDownloadSetProgress,
} from './videoDownload';
import {
  EditorAudioControlsProvider,
  useEditorAudioControlsRegister,
  useEditorAudioControlsPause,
  useEditorAudioControlsPlay,
} from './editorAudioControls';
import {
  ToolbarOpenStateProvider,
  useToolbarCurrentState,
  useToolbarToggleState,
} from './toolbarOpenState';
import {
  ChromakeyDownloadModalProvider,
  useChromakeyDownloadProgress,
  useChromakeyDownloadParams,
  useChromakeyDownloadModalOpen,
  useChromakeyDownloadModalClose,
  useChromakeyDownloadRetry,
} from './chromakeyDownload';
import {
  PredictedDurationProvider,
  useOpenPredicted,
  usePredictedCallback,
} from './predictedDuration';

interface ChildrenProps {
  children?: React.ReactNode;
}

export const EditorProvider = ({ children }: ChildrenProps) => (
  <EditorAudioControlsProvider>
    <PusherProvider>
      <PredictedDurationProvider>
        <CommonModalProvider>
          <VoiceModelsModalProvider>
            <VideoDownloadModalProvider>
              <AudioDownloadModalProvider>
                <ChromakeyDownloadModalProvider>
                  <ToolbarOpenStateProvider>
                    {children}
                  </ToolbarOpenStateProvider>
                </ChromakeyDownloadModalProvider>
              </AudioDownloadModalProvider>
            </VideoDownloadModalProvider>
          </VoiceModelsModalProvider>
        </CommonModalProvider>
      </PredictedDurationProvider>
    </PusherProvider>
  </EditorAudioControlsProvider>
);

export {
  usePusherChannelId,
  useVoiceModelsModalState,
  useVoiceModelsModalSelectedName,
  useVoiceModelsModalSelectedType,
  useVoiceModelsModalOpen,
  useVoiceModelsModalClose,
  useVoiceModelsModalChangeName,
  useCommonModalOpen,
  useCommonModalClose,
  useCommonModalSubmit,
  useCommonModalSecondSubmit,
  useCommonModalCancel,
  useAudioDownloadModalState,
  useAudioDownloadProgress,
  useAudioDownloadParams,
  useAudioDownloadModalOpen,
  useAudioDownloadModalClose,
  useAudioDownloadRetry,
  useVideoDownloadModalState,
  useVideoDownloadProgress,
  useVideoDownloadParams,
  useVideoDownloadModalOpen,
  useVideoDownloadModalClose,
  useVideoDownloadRetry,
  useVideoDownloadSetProgress,
  useEditorAudioControlsRegister,
  useEditorAudioControlsPause,
  useEditorAudioControlsPlay,
  useToolbarCurrentState,
  useToolbarToggleState,
  useChromakeyDownloadProgress,
  useChromakeyDownloadParams,
  useChromakeyDownloadModalOpen,
  useChromakeyDownloadModalClose,
  useChromakeyDownloadRetry,
  useOpenPredicted,
  usePredictedCallback,
};
