import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { useGetPaymentInfoQuery } from 'app/services/membership';
import { typography } from 'styles';
import UsageDurationText from './UsageDurationText';
import NextBillingText from './NextBillingText';

const UsageCounter: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'common' });

  const { data: userInfo } = useGetUserInfoQuery();
  const { data: paymentInfo } = useGetPaymentInfoQuery();

  return (
    <Box>
      {userInfo !== undefined && paymentInfo !== undefined && (
        <UsageCounterBox>
          <Info>
            <Grade>{userInfo.billingName}</Grade>
            {process.env.ONAIR_PLATFORM_TYPE === 'onair' && (
              <Upgrade to={'/client/membership'}>
                {t('membership.upgrade')}
              </Upgrade>
            )}
          </Info>
          <ProgressBox>
            <ProgressBar
              style={getProgressBarStyle(
                userInfo.usedDuration,
                userInfo.allowedDuration
              )}
            />
          </ProgressBox>
          <UsageDurationText
            allowedDuration={userInfo.allowedDuration}
            usedDuration={userInfo.usedDuration}
          />
          {paymentInfo.nextBillingRedeemDate && (
            <NextBillingText datetime={paymentInfo.nextBillingRedeemDate} />
          )}
        </UsageCounterBox>
      )}
    </Box>
  );
};

export default UsageCounter;

const getProgressBarStyle = (
  used: number,
  allowed: number
): React.CSSProperties => {
  return {
    '--progress': `${Math.ceil((used / allowed) * 100)}%`,
  } as React.CSSProperties;
};

const Box = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    margin-top: auto;
    margin-bottom: 16px;
  }
`;

const UsageCounterBox = styled.div`
  width: calc(100% - 32px);
  background-color: var(--color-grey-50);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Grade = styled.span`
  ${typography.label.large}
`;

const Upgrade = styled(Link)`
  text-decoration: none;
  padding: 4px 8px;
  color: var(--color-pink-500);
  background-color: var(--color-pink-50);
  border-radius: 10px;
  ${typography.label.large}
`;

const ProgressBox = styled.div`
  width: 100%;
  height: 4px;
  margin: 8px 0;
  border-radius: 10px;
  background-color: var(--color-pink-50);
  position: relative;
`;

const ProgressBar = styled.div`
  height: 4px;
  border-radius: 10px;
  background-color: var(--color-pink-500);
  position: absolute;
  top: 0;
  left: 0;
  width: var(--progress);
  max-width: 100%;
`;
