import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ArrowArcRightIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { redo, selectRedoable } from 'features/editor/editorSlice';

const RedoButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const redoable = useAppSelector(selectRedoable);

  const handleClick = () => {
    dispatch(redo());
  };

  return (
    <IconButton size={32} disabled={!redoable} onClick={handleClick}>
      <ArrowArcRightIcon size={24} weight={'bold'} />
    </IconButton>
  );
};

export default RedoButton;
