import * as React from 'react';
import { useAppDispatch } from 'app/hooks';
import { useLoginMutation } from 'app/services/auth';
import { LoginForm } from 'components/auth';
import { setCredentials } from './authSlice';
import InactiveUserDialog from './InactiveUserDialog';
import OtherPlatformDialog from './OtherPlatformDialog';

interface InactiveError {
  status: 403;
  data: any;
}

const isInactiveError = (error: any): error is InactiveError => {
  if (error.hasOwnProperty('status')) {
    if (error.status === 403) return true;
    return false;
  }
  return false;
};

interface OtherPlatformError {
  status: 409;
  data: any;
}

const isOtherPlatformError = (error: any): error is OtherPlatformError => {
  if (error.hasOwnProperty('status')) {
    if (error.status === 409) return true;
    return false;
  }
  return false;
};

const Login: React.FC = () => {
  const [error, setError] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const [requestLogin] = useLoginMutation();

  const [inactiveIsOpen, setInactiveIsOpen] = React.useState<boolean>(false);
  const toggleInactiveIsOpen = () => setInactiveIsOpen((prev) => !prev);

  const [isOtherPlatformIsOpen, setIsOtherPlatformIsOpen] =
    React.useState<boolean>(false);
  const toggleIsOtherPlatformIsOpen = () =>
    setIsOtherPlatformIsOpen((prev) => !prev);

  return (
    <>
      <LoginForm
        onSubmit={async (data) => {
          try {
            const { access } = await requestLogin(data).unwrap();
            dispatch(
              setCredentials({
                token: access,
                loginTime: Date.now(),
              })
            );
          } catch (error) {
            if (isInactiveError(error)) {
              toggleInactiveIsOpen();
              return;
            }
            if (isOtherPlatformError(error)) {
              toggleIsOtherPlatformIsOpen();
              return;
            }
            console.error(error);
            setError(true);
          }
        }}
        error={error}
      />
      <InactiveUserDialog
        open={inactiveIsOpen}
        onClose={toggleInactiveIsOpen}
      />
      <OtherPlatformDialog
        open={isOtherPlatformIsOpen}
        onClose={toggleIsOtherPlatformIsOpen}
      />
    </>
  );
};

export default Login;
