import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  useGetUserInfoQuery,
  useUpdateUserInfoMutation,
} from 'app/services/userInfo';
import {
  CheckSquareIcon,
  SquareIcon,
  KakaoTalkIcon,
  GoogleIcon,
} from 'assets/svg';
import { DEV_PRIVATEWRAPPER } from 'lib/devUtils';
import { ContentsTitle } from 'components/client';
import { typography } from 'styles';
import LanguageSelectButton from './LanguageSelectButton';
import TextField from './TextField';
import EventAiExpo from './EventAiExpo';
import type { Code } from 'locales/utils';

const Profile: React.FC = () => {
  const { t, i18n } = useTranslation('client', { keyPrefix: 'userProfile' });

  const { data: userInfo } = useGetUserInfoQuery();

  const [updateUserInfo] = useUpdateUserInfoMutation();

  const navigate = useNavigate();

  const goResetPassword = () => navigate('/client/profile/update-password');

  const goWithdrawal = () => navigate('/client/profile/withdrawal');

  const handleChangeLocale = (key: Code): void => {
    if (userInfo === undefined) return;
    i18n.changeLanguage(key);
    updateUserInfo({
      language: key,
      marketingAccept: userInfo.marketingAccept,
    });
  };

  const handleClickMarketingAcceptBox = () => {
    if (userInfo === undefined) return;
    updateUserInfo({ marketingAccept: !userInfo.marketingAccept });
  };

  if (userInfo === undefined) return null;
  return (
    <Base>
      <ContentsTitle title={t('title.main')} />
      <ProfileBox>
        <TextField label={t('label.email')} value={userInfo.email} disabled />
        <MarketingAcceptBox>
          <Checkbox onClick={handleClickMarketingAcceptBox}>
            {userInfo.marketingAccept ? (
              <CheckSquareIcon
                size={24}
                color={'var(--color-pink)'}
                weight={'fill'}
              />
            ) : (
              <SquareIcon
                size={24}
                color={'var(--color-grey-500)'}
                weight={'bold'}
              />
            )}
          </Checkbox>
          <span>{t('button.marketingAccept')}</span>
        </MarketingAcceptBox>
        <PasswordBox>
          {userInfo.socialAccountInfo === 'vresss' && (
            <PasswordChangeButton onClick={goResetPassword}>
              {t('button.updatePassword')}
            </PasswordChangeButton>
          )}
          {userInfo.socialAccountInfo === 'google' && (
            <PasswordChangeSocialBox>
              <GoogleIconBox>
                <GoogleIcon width={24} height={24} />
              </GoogleIconBox>
              <span>{t('socialAccountInfo.google')}</span>
            </PasswordChangeSocialBox>
          )}
          {userInfo.socialAccountInfo === 'kakao' && (
            <PasswordChangeSocialBox>
              <KakaoIconBox>
                <KakaoTalkIcon width={24} height={24} />
              </KakaoIconBox>
              <span>{t('socialAccountInfo.kakao')}</span>
            </PasswordChangeSocialBox>
          )}
        </PasswordBox>
        <DEV_PRIVATEWRAPPER>
          <SelectButtonBox>
            <LanguageSelectButton
              code={userInfo.language}
              onChange={handleChangeLocale}
            />
          </SelectButtonBox>
        </DEV_PRIVATEWRAPPER>
        <EventAiExpo />
        <Divider />
        <div>
          <WithdrawalButton onClick={goWithdrawal}>
            {t('button.withdrawal')}
          </WithdrawalButton>
        </div>
      </ProfileBox>
    </Base>
  );
};

export default Profile;

const Base = styled.div`
  width: 100%;
`;

const ProfileBox = styled.div`
  max-width: 396px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PasswordBox = styled.div`
  width: 100%;
  margin-bottom: 12px;
`;

const PasswordChangeSocialBox = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;

  span {
    ${typography.body.large}
    color: var(--color-black);
  }
`;

const GoogleIconBox = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid var(--color-grey-500);
  background: var(--color-white);
`;

const KakaoIconBox = styled.div`
  --color-kakao: #fee500;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: var(--color-kakao);
`;

const PasswordChangeButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  text-decoration: none;
  border-radius: 10px;
  color: var(--color-black);
  border: 1px solid var(--color-grey-500);
  min-width: 120px;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  padding: 8px 12px;
  cursor: pointer;
  ${typography.body.large}

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;

const MarketingAcceptBox = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  ${typography.label.medium}
  color: var(--color-black);
  margin-bottom: 12px;
  span {
    margin-left: 4px;
    user-select: none;
  }
`;

const Checkbox = styled.button`
  --hover-background: rgba(51, 51, 51, 0.08);
  --active-background: rgba(51, 51, 51, 0.16);

  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: var(--hover-background);

    @media (hover: none) {
      background: var(--active-background);
    }
  }

  &:active {
    background: var(--active-background);
  }

  &[data-checked='true'] {
    --hover-background: rgba(255, 55, 110, 0.08);
    --active-background: rgba(255, 55, 110, 0.16);
  }
`;

const SelectButtonBox = styled.div`
  margin-bottom: 12px;
`;

const Divider = styled.div`
  height: 1px;
  margin-bottom: 12px;
  background: var(--color-grey-400);
`;

const WithdrawalButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  border: 1px solid var(--color-grey-100);
  border-radius: 10px;
  padding: 2px 8px;
  color: var(--color-grey-600);
  background: var(--color-white);
  cursor: pointer;
  ${typography.label.medium}

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
