import * as React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import {
  createTimelinePaddingCell,
  selectNetworkState,
  selectCurrentPageKey,
  selectCurrentCells,
} from 'features/editor/editorSlice';
import { sort, voiceModelCompare } from 'features/editor/utils';

const useBeforeCheckPadding = () => {
  const cells = useAppSelector(selectCurrentCells);

  const dispatch = useAppDispatch();

  const currentPageKey = useAppSelector(selectCurrentPageKey);
  const networkState = useAppSelector(selectNetworkState);

  const { model } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      model: sort(data, voiceModelCompare)?.[0],
    }),
  });

  const [isInitialized, setIsInitialized] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (model === undefined) return;
    if (currentPageKey === undefined) return;
    if (networkState === 'load') return;
    if (cells === undefined) return;

    if (cells.length > 0) {
      if (cells[0].text === '' && cells[0].displayText === '') {
        setIsInitialized(true);
        return;
      }

      dispatch(createTimelinePaddingCell({ name: cells[0].mlModelName }));
    } else {
      dispatch(createTimelinePaddingCell({ name: model.name }));
    }

    setIsInitialized(true);
  }, [model, currentPageKey, networkState, cells, dispatch]);

  React.useEffect(() => {
    return () => {
      setIsInitialized(false);
    };
  }, []);

  return isInitialized;
};

export default useBeforeCheckPadding;
