import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectCurrentToolbarItem,
  setToolbarItem,
} from 'features/editor/editorSlice';
import EditorToolbarItems from './EditorToolbarItems';
import ToolbarModal from './EditorToolbar.Tablet.Modal';

const EditorToolbar: React.FC = () => {
  const currentToolbarItem = useAppSelector(selectCurrentToolbarItem);

  const dispatch = useAppDispatch();

  const [openToolbar, setOpenToolbar] = React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch(setToolbarItem('none'));
  }, [dispatch]);

  return (
    <>
      <Toolbar>
        <EditorToolbarItems onClickItem={() => setOpenToolbar(true)} />
      </Toolbar>
      <ToolbarModal
        open={openToolbar && currentToolbarItem !== 'none'}
        onClose={() => setOpenToolbar(false)}
      />
    </>
  );
};

export default EditorToolbar;

const Toolbar = styled.div`
  width: 72px;
  height: 100%;
  border-right: 1px solid var(--color-grey-100);
  background: var(--color-grey-50);
`;
