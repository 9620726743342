import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { CaretDownIcon } from 'assets/svg';
import { selectCurrentPagePreview } from 'features/editor/editorSlice';
import AddCellButton from './AddCellButton';
import ChangeModelButton from './ChangeModelButton';
import ChangeToneButton from './ChangeToneButton';
import ChangeDurationButton from './ChangeDurationButton';
import RemoveButton from './RemoveButton';

interface ContentToolbarProps {
  isOpen: boolean;
  onClick: () => void;
}

const ContentToolbar: React.FC<ContentToolbarProps> = ({ isOpen, onClick }) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const ref = React.useRef<HTMLDivElement | null>(null);
  const isPadding = React.useRef<boolean>(true);

  React.useEffect(() => {
    const el = ref.current;
    if (el === null) return;

    const toolbarPadding = 16 * 2;
    const toolbarSpace = 56;
    const toolbarFoldButton = 56;
    const offset = toolbarPadding + toolbarSpace + toolbarFoldButton;

    if (el.offsetWidth + offset >= window.innerWidth) {
      isPadding.current = false;
    }

    let startX: number | undefined = undefined;
    let rAF: ReturnType<typeof window.requestAnimationFrame> | undefined =
      undefined;

    const handleScrub = (delta: number) => {
      if (rAF !== undefined) window.cancelAnimationFrame(rAF);

      rAF = window.requestAnimationFrame(() => {
        if (startX === undefined) return;
        const nextPosition = startX - delta;
        startX = delta;
        el.scrollLeft += nextPosition;
      });
    };

    const handleTouchStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
      startX = undefined;
    };

    const handleTouchMove = (e: TouchEvent) => {
      handleScrub(e.changedTouches[0].clientX);
    };

    const handleMouseDown = (e: MouseEvent) => {
      startX = e.clientX;
    };

    const handleMouseMove = (e: MouseEvent) => {
      handleScrub(e.clientX);
    };

    const handleMouseUp = () => {
      startX = undefined;
    };

    const bindEvent = () => {
      el.addEventListener('touchstart', handleTouchStart, { passive: true });
      el.addEventListener('touchend', handleTouchEnd, { passive: true });
      el.addEventListener('touchmove', handleTouchMove, { passive: true });

      el.addEventListener('mousedown', handleMouseDown, { passive: true });
      el.addEventListener('mousemove', handleMouseMove, { passive: true });
      el.addEventListener('mouseup', handleMouseUp, { passive: true });
    };

    const unbindEvent = () => {
      el.removeEventListener('touchstart', handleTouchStart);
      el.removeEventListener('touchend', handleTouchEnd);
      el.removeEventListener('touchmove', handleTouchMove);

      el.removeEventListener('mousedown', handleMouseDown);
      el.removeEventListener('mousemove', handleMouseMove);
      el.removeEventListener('mouseup', handleMouseUp);
    };

    bindEvent();

    return () => {
      unbindEvent();
    };
  }, []);

  const pagePreview = useAppSelector(selectCurrentPagePreview);

  return (
    <Toolbar>
      {isPadding.current && <Padding />}
      <ToolBox ref={ref}>
        {pagePreview.type !== 'video' && (
          <>
            <AddCellButton />
            <Divider />
          </>
        )}
        <ChangeModelButton />
        <Divider />
        <ChangeToneButton />
        <Divider />
        <ChangeDurationButton />
        <Divider />
        <RemoveButton />
      </ToolBox>
      <ButtonBox>
        <FoldButton onClick={onClick}>
          <CaretRotateIcon size={24} weight={'bold'} isRotate={isOpen} />
          {isOpen ? (
            <span>{t('toolbar.previewHide')}</span>
          ) : (
            <span>{t('toolbar.previewShow')}</span>
          )}
        </FoldButton>
      </ButtonBox>
    </Toolbar>
  );
};

export default ContentToolbar;

const Toolbar = styled.div`
  width: 100%;
  height: 56px;
  box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  z-index: 2;
  padding: 0 16px;
`;

const Padding = styled.div`
  width: 56px;
`;

const ToolBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: hidden;
  position: relative;
  margin: 0 auto;

  hr {
    flex: 0 0 1px;
  }
`;

const Divider = styled.hr`
  width: 1px;
  height: 24px;
  background-color: var(--color-grey-200);
  border: none;
  margin: 0;
`;

const ButtonBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    color: var(--color-grey-500);
  }
`;

const FoldButton = styled.div`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  width: 56px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  padding: 4px;
  border-radius: 10px;
  color: var(--color-grey-500);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;

const CaretRotateIcon = styled(CaretDownIcon)<{ isRotate: boolean }>`
  transition: transform 100ms;
  transform: ${(props) => (props.isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
