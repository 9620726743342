import * as React from 'react';
import { useMatchMedia } from 'lib/hooks';
import Desktop from './VoiceModels.ListItem.Desktop';
import Mobile from './VoiceModels.ListItem.Mobile';

export interface ListItemProps {
  isSelected: boolean;
  name: string;
  modelColor: string;
  displayName: string;
  tags: string[];
  language: string;
}

const ListItem: React.FC<ListItemProps> = (props) => {
  const isTablet = useMatchMedia('(min-width: 1024px)');

  if (isTablet) return <Desktop {...props} />;
  return <Mobile {...props} />;
};

export default ListItem;
