import * as React from 'react';

/**
 * @name useMatchMedia
 *
 * @param query window.matchMedia query string
 */

export default function useMatchMedia(query: string) {
  const [state, setState] = React.useState<boolean>(
    () => window.matchMedia(query).matches
  );
  const mounted: React.MutableRefObject<boolean> = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;

    const mediaQuery = window.matchMedia(query);

    const handleChange = () => {
      if (!mounted.current) return;

      setState(mediaQuery.matches);
    };

    mediaQuery.addListener(handleChange);
    setState(mediaQuery.matches);

    return () => {
      mounted.current = false;

      mediaQuery.removeListener(handleChange);
    };
  }, [query]);

  return state;
}
