import * as React from 'react';
import { useAppSelector } from 'app/hooks';
import { useGetVideoModelQuery } from 'app/services/videoModel';
import { selectCurrentPageSTV } from 'features/editor/editorSlice';
import EditableVideoOverlay from './EditableVideoOverlay';

interface VideoOverlayProps {
  boundaryKey?: string;
  imageWidth: number;
}

const VideoOverlay: React.FC<VideoOverlayProps> = ({
  boundaryKey = 'key',
  imageWidth,
}) => {
  const stv = useAppSelector(selectCurrentPageSTV);
  const { resourceUrl } = useGetVideoModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      resourceUrl: data?.reduce<string | undefined>((acc, item) => {
        if (item.name !== stv?.videoModelName) return acc;

        const resource = item.resources.find((el) => el.name === stv.resource);
        if (resource === undefined) return acc;

        return resource.editThumbUrl;
      }, undefined),
    }),
  });

  if (stv === undefined) return null;
  if (resourceUrl === undefined) return null;
  return (
    <EditableVideoOverlay
      boundaryKey={boundaryKey}
      imageWidth={imageWidth}
      imageHeight={(imageWidth / 16) * 9}
      ratio={imageWidth / 1920}
      src={resourceUrl}
      location={stv.location}
    />
  );
};

export default VideoOverlay;
