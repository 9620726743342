import * as React from 'react';
import copyToClipboard from 'copy-to-clipboard';
import i18n from 'locales';
import { useTranslation } from 'react-i18next';
import { Modal, Toast } from 'components/common';
import DownloadError from './DownloadError';
import DownloadProgress from './DownloadProgress';

function getClipboardText(type: 'audio' | 'video') {
  const output = i18n.t(`editor:downloadTemplate.${type}`);
  return i18n.t('editor:downloadTemplate.copyright', { output });
}

function setClipboardText(
  text: string,
  onSuccess?: () => void,
  onFailure?: () => void
) {
  if (window.navigator.clipboard !== undefined) {
    window.navigator.clipboard.writeText(text).then(
      () => {
        onSuccess?.();
      },
      (error) => {
        onFailure?.();
        console.error(error);
      }
    );
    return;
  }

  const isSuccess = copyToClipboard(text);

  if (isSuccess) {
    onSuccess?.();
  } else {
    onFailure?.();
  }
}

interface DownloadTemplateProps {
  id: string;
  title: string;
  titleDone: string;
  progress: number;
  type: 'audio' | 'video';
  carousel?: React.ReactNode;
  onClose: () => void;
  onEnded: () => void;
  onDownload: () => void;
  onRetry: () => void;
}

const DownloadTemplate: React.FC<DownloadTemplateProps> = ({
  id,
  title,
  titleDone,
  progress,
  type,
  carousel,
  onClose,
  onEnded,
  onDownload,
  onRetry,
}) => {
  const [openToast, setOpenToast] = React.useState<boolean>(false);

  const toastMessage = React.useRef<string>('');
  const toastSeverity = React.useRef<'success' | 'error'>('success');

  const { t } = useTranslation('editor', { keyPrefix: 'downloadTemplate' });

  const handleClickCopy = () => {
    const text = getClipboardText(type);
    setClipboardText(
      text,
      () => {
        toastMessage.current = t('toast.success');
        toastSeverity.current = 'success';
        setOpenToast(true);
      },
      () => {
        toastMessage.current = t('toast.error');
        toastSeverity.current = 'error';
        setOpenToast(true);
      }
    );
  };

  if (progress < 0) {
    return (
      <Modal id={id}>
        <DownloadError onEnded={onEnded} onRetry={onRetry} />
      </Modal>
    );
  }

  return (
    <>
      <Modal id={id}>
        <DownloadProgress
          title={title}
          titleDone={titleDone}
          progress={progress}
          type={type}
          carousel={carousel}
          onClose={onClose}
          onEnded={onEnded}
          onDownload={onDownload}
          onClickCopy={handleClickCopy}
        />
      </Modal>
      <Toast
        in={openToast}
        message={toastMessage.current}
        timeout={1000}
        severity={toastSeverity.current}
        onClose={() => setOpenToast(false)}
      />
    </>
  );
};

export default DownloadTemplate;
