import * as React from 'react';

const HunetLogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={71.774}
    height={24.848}
    viewBox={'0 0 71.774 24.848'}
    xmlSpace={'preserve'}
    {...props}
  >
    <g transform={'matrix(.35278 0 0 -.35278 -91.55 86.57)'}>
      <path
        d={
          'M0 0h-.002A4.079 4.079 0 0 1-4.08-4.08v-11.123c0-.963.781-1.742 1.742-1.742h2.334a4.081 4.081 0 0 1 4.082 4.082v8.783A4.079 4.079 0 0 1 0 0'
        }
        transform={'translate(442.414 245.274)'}
        fill={'#ed1c24'}
        fillOpacity={1}
        fillRule={'nonzero'}
        stroke={'none'}
      />
      <path
        d={
          'M0 0h-7.715a4.828 4.828 0 0 0-4.826 4.826l-.002 23.837H-.016a4.033 4.033 0 1 1 0 8.064H-18.74a1.871 1.871 0 0 1-1.869-1.871V5.086s.017-1.115.13-1.854c.786-6.351 6.2-11.267 12.764-11.267H0A4.018 4.018 0 1 1 0 0'
        }
        transform={'translate(458.945 185.172)'}
        fill={'#ed1c24'}
        fillOpacity={1}
        fillRule={'nonzero'}
        stroke={'none'}
      />
      <path
        d={
          'M0 0c1.807 2.215 3.959 3.291 7.314 3.291 3.317 0 5.793-1.016 7.571-3.109 1.711-2.016 2.982-4.989 3.078-8.834H-2.773C-2.674-5.047-1.74-2.137 0 0m21.068 5.145c-3.17 3.816-7.798 5.751-13.754 5.751-5.726 0-9.959-1.882-13.285-5.593-3.283-3.666-4.949-8.805-4.949-15.274v-5.367c0-6.518 1.66-11.504 5.076-15.248 3.405-3.731 7.832-5.457 13.865-5.457 3.719 0 6.961.546 9.631 1.623 1.696.685 3.561 1.586 4.877 2.357.016.01.412.27.76.611.047.045.293.334.293.334.539.67.832 1.491.832 2.342a3.794 3.794 0 0 1-3.789 3.791c-.543 0-1.08-.121-1.604-.367l-.392-.203a32.973 32.973 0 0 0-3.588-1.703c-1.971-.785-4.307-1.182-6.941-1.182-3.536 0-5.862.947-7.852 3.24-1.965 2.26-2.928 5.182-3.023 9.184h23.429c1.645.127 5.116.92 5.096 5.549v.336c0 6.395-1.576 11.535-4.682 15.276'
        }
        transform={'translate(406.585 213.18)'}
        fill={'#ed1c24'}
        fillOpacity={1}
        fillRule={'nonzero'}
        stroke={'none'}
      />
      <path
        d={
          'M0 0a19.606 19.606 0 0 1-11.535-3.729v20.848a4.078 4.078 0 0 1-8.158 0V-42.86a4.079 4.079 0 0 1 8.158 0V-19.691C-11.535-13.32-6.371-8.156 0-8.156c6.369 0 11.533-5.164 11.533-11.535V-42.86a4.079 4.079 0 0 1 8.158 0v23.169C19.691-8.816 10.875 0 0 0'
        }
        transform={'translate(279.203 224.198)'}
        fill={'#ed1c24'}
        fillOpacity={1}
        fillRule={'nonzero'}
        stroke={'none'}
      />
      <path
        d={
          'M0 0a4.079 4.079 0 0 1 4.08 4.078V27.247c0 6.371 5.164 11.535 11.533 11.535 6.371 0 11.535-5.164 11.535-11.535V4.078a4.08 4.08 0 0 1 8.159 0v23.169c0 10.875-8.817 19.691-19.694 19.691-10.875 0-19.691-8.816-19.691-19.691V4.078A4.078 4.078 0 0 1 0 0'
        }
        transform={'translate(354.357 177.137)'}
        fill={'#ed1c24'}
        fillOpacity={1}
        fillRule={'nonzero'}
        stroke={'none'}
      />
      <path
        d={
          'M0 0a4.079 4.079 0 0 1-4.078-4.08v-23.169c0-6.369-5.164-11.533-11.535-11.533s-11.535 5.164-11.535 11.533V-4.08a4.08 4.08 0 1 1-8.159 0v-23.169c0-10.875 8.819-19.691 19.694-19.691 10.875 0 19.693 8.816 19.693 19.691V-4.08A4.08 4.08 0 0 1 0 0'
        }
        transform={'translate(340.201 221.899)'}
        fill={'#ed1c24'}
        fillOpacity={1}
        fillRule={'nonzero'}
        stroke={'none'}
      />
    </g>
  </svg>
);

export default HunetLogoIcon;
