import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  DndContext,
  DragOverlay,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectPageKeys, movePage } from 'features/editor/editorSlice';
import SortableItem from './SortableItem';
import type { DragStartEvent, DragEndEvent } from '@dnd-kit/core';

const SortableContents: React.FC = () => {
  const pageKeys = useAppSelector(selectPageKeys);

  const dispatch = useAppDispatch();

  const [activeKey, setActiveKey] = React.useState<string | null>(null);

  const handleDragStart = (e: DragStartEvent) => {
    setActiveKey(e.active.id);
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    setActiveKey(null);
    if (over === null) return;
    if (active.id !== over.id) {
      const from = pageKeys.indexOf(active.id);
      const to = pageKeys.indexOf(over.id);

      dispatch(movePage({ from, to }));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={[restrictToParentElement]}
    >
      <SortableContext items={pageKeys} strategy={verticalListSortingStrategy}>
        <div>
          {pageKeys.map((pageKey) => (
            <SortableItem
              pageKey={pageKey}
              overlay={activeKey === pageKey}
              key={pageKey}
            />
          ))}
          <>
            {ReactDOM.createPortal(
              <DragOverlay>
                {activeKey && <SortableItem pageKey={activeKey} />}
              </DragOverlay>,
              document.body
            )}
          </>
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default SortableContents;
