import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { kmp } from 'lib/utils';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import { XIcon } from 'assets/svg';
import { ModalV2, IconButton } from 'components/common';
import {
  useVoiceModelsModalState,
  useVoiceModelsModalSelectedName,
  useVoiceModelsModalClose,
} from 'features/editor/providers';
import { voiceModelCompare, sort } from 'features/editor/utils';
import ListItem from './VoiceModels.ListItem';
import SearchBar from './SearchBar';

const VoiceModels: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'voiceModelSelect' });

  const { data: voiceModels } = useGetVoiceModelQuery();

  const [searchWord, setSearchWord] = React.useState<string>('');

  const selectedName = useVoiceModelsModalSelectedName();

  const openModal = useVoiceModelsModalState();
  const closeModal = useVoiceModelsModalClose();

  const handleChangeSearchWord: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setSearchWord(e.target.value);
  };

  return (
    <ModalV2 open={openModal} onClose={closeModal}>
      <Base>
        <TitleBox>
          <Title>{t('title.modal')}</Title>
          <IconButton size={24} radius={6} onClick={closeModal}>
            <XIcon size={20} weight={'bold'} />
          </IconButton>
        </TitleBox>
        <SearchBar
          value={searchWord}
          placeholder={t('placeholder')}
          onChange={handleChangeSearchWord}
        />
        <List>
          {sort(voiceModels, voiceModelCompare)
            ?.filter((model) => {
              if (searchWord === '') return true;
              if (
                kmp(model.displayName.toUpperCase(), searchWord.toUpperCase()) >
                -1
              )
                return true;
              return false;
            })
            .map((model) => (
              <ListItem
                isSelected={selectedName === model.name}
                name={model.name}
                modelColor={model.defaultColor}
                displayName={model.displayName}
                tags={model.tags}
                language={model.language}
                key={model.key}
              />
            ))}
        </List>
      </Base>
    </ModalV2>
  );
};

export default VoiceModels;

const Base = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: var(--color-white);
  border-radius: 10px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  max-width: 416px;

  @media (min-width: 520px) {
    max-height: calc(100% - 240px);
  }
`;

const TitleBox = styled.div`
  height: 64px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    color: var(--color-black);
  }
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-black);
  margin: 0 auto;
`;

const List = styled.ul`
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 16px;
  padding: 0 16px;
  height: calc(100% - 120px);
  overflow-y: auto;
`;
