import * as React from 'react';
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useResetPasswordMutation } from 'app/services/auth';
import { typography } from 'styles';
import OutlineButton from './OutlineButton';

const ResetPasswordConfirm: React.FC = () => {
  const { t } = useTranslation('auth', { keyPrefix: 'resetPassword' });

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const [requestResetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();

  const goLogin = () => navigate('/auth/login');

  const resendEmail = async () => {
    if (email === null) return;
    try {
      await requestResetPassword({ email }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  if (email === null) return <Navigate to={'/auth/reset-password'} replace />;

  return (
    <Base>
      <Contents>
        <Title>{t('confirm.title')}</Title>
        <Description>{t('confirm.description.send', { email })}</Description>
        <Description>{t('confirm.description.check')}</Description>
        <ButtonBox>
          <OutlineButton onClick={goLogin}>
            {t('confirm.button.goLogin')}
          </OutlineButton>
          <OutlineButton color={'black'} onClick={resendEmail}>
            {t('confirm.button.resendEmail')}
          </OutlineButton>
        </ButtonBox>
      </Contents>
    </Base>
  );
};

export default ResetPasswordConfirm;

const Base = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Contents = styled.div`
  max-width: 420px;
  margin: 40px 0;
  height: fit-content;

  @media (min-width: 520px) {
    max-width: auto;
    margin: 60px 0;
  }
`;

const Title = styled.div`
  ${typography.title.large}
  color: var(--color-black);
  text-align: center;
`;

const Description = styled.div`
  margin-top: 16px;
  ${typography.body.large}
  color: var(--color-black);
`;

const ButtonBox = styled.div`
  margin-top: 26px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;
