import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { PlusIcon, MinusIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { Slider } from 'components/editor';
import {
  selectTimelineMultiplierIndex,
  setTimelineMultiplierIndex,
} from 'features/editor/editorSlice';
import { TIMELINE_MULTIPLIERS } from 'features/editor/editorSlice';

const Multiplier: React.FC = () => {
  const index = useAppSelector(selectTimelineMultiplierIndex);

  const dispatch = useAppDispatch();

  const updateMultiplier = (value: number): number => {
    let updateValue: number = Math.floor(value);

    updateValue = Math.min(updateValue, TIMELINE_MULTIPLIERS.length - 1);
    updateValue = Math.max(updateValue, 0);

    if (index === updateValue) return -1;

    dispatch(setTimelineMultiplierIndex(updateValue));
    return updateValue;
  };

  const handleChangeCommit = (value: number) => {
    const updateValue = updateMultiplier(value);
    if (updateValue === -1) return;
  };

  const handleClickMinus = () => {
    const updateValue = updateMultiplier(index - 1);
    if (updateValue === -1) return;
  };

  const handleClickPlus = () => {
    const updateValue = updateMultiplier(index + 1);
    if (updateValue === -1) return;
  };

  return (
    <Base>
      <IconButton size={20} radius={4} onClick={handleClickMinus}>
        <MinusIcon size={16} color={'currentColor'} weight={'bold'} />
      </IconButton>
      <Slider
        min={0}
        max={4}
        value={index}
        width={90}
        onChangeCommit={handleChangeCommit}
      />
      <IconButton size={20} radius={4} onClick={handleClickPlus}>
        <PlusIcon size={16} color={'currentColor'} weight={'bold'} />
      </IconButton>
    </Base>
  );
};

export default Multiplier;

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  & div > span:first-of-type {
    background: linear-gradient(
      to right,
      var(--color-grey-700) var(--value-percent),
      var(--color-grey-300) var(--value-percent)
    );
  }

  button {
    color: var(--color-grey-700);
    background: transparent;
  }

  button:hover {
    background: transparent;
  }

  button:active {
    background: transparent;
  }

  margin-top: 8px;

  @media (min-width: 1024px) {
    margin-top: 0px;
    margin-left: 16px;
  }
`;
