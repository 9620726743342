import * as React from 'react';

const styles = {
  enableBackground: 'new 0 0 0 0',
} as React.CSSProperties;

const LoadingIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={30}
    viewBox='0 0 24 30'
    style={{
      ...styles,
      ...props.style,
    }}
    {...props}
  >
    <path fill='#FFF' opacity={0.2} d='M0 10h4v10H0z'>
      <animate
        attributeName='opacity'
        attributeType='XML'
        values='0.2; 1; .2'
        begin='0s'
        dur='0.6s'
        repeatCount='indefinite'
      />
      <animate
        attributeName='height'
        attributeType='XML'
        values='10; 20; 10'
        begin='0s'
        dur='0.6s'
        repeatCount='indefinite'
      />
      <animate
        attributeName='y'
        attributeType='XML'
        values='10; 5; 10'
        begin='0s'
        dur='0.6s'
        repeatCount='indefinite'
      />
    </path>
    <path fill='#FFF' opacity={0.2} d='M8 10h4v10H8z'>
      <animate
        attributeName='opacity'
        attributeType='XML'
        values='0.2; 1; .2'
        begin='0.15s'
        dur='0.6s'
        repeatCount='indefinite'
      />
      <animate
        attributeName='height'
        attributeType='XML'
        values='10; 20; 10'
        begin='0.15s'
        dur='0.6s'
        repeatCount='indefinite'
      />
      <animate
        attributeName='y'
        attributeType='XML'
        values='10; 5; 10'
        begin='0.15s'
        dur='0.6s'
        repeatCount='indefinite'
      />
    </path>
    <path fill='#FFF' opacity={0.2} d='M16 10h4v10h-4z'>
      <animate
        attributeName='opacity'
        attributeType='XML'
        values='0.2; 1; .2'
        begin='0.3s'
        dur='0.6s'
        repeatCount='indefinite'
      />
      <animate
        attributeName='height'
        attributeType='XML'
        values='10; 20; 10'
        begin='0.3s'
        dur='0.6s'
        repeatCount='indefinite'
      />
      <animate
        attributeName='y'
        attributeType='XML'
        values='10; 5; 10'
        begin='0.3s'
        dur='0.6s'
        repeatCount='indefinite'
      />
    </path>
  </svg>
);

export default LoadingIcon;
