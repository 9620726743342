import { createApi } from '@reduxjs/toolkit/query/react';
import { camelCase, recordTransformer } from 'lib/utils';
import queryFetcher from './queryFetcher';
import type { ObjectToCamel } from 'lib/utils';

interface RawMusicAsset {
  name: string;
  display_name: string;
  download_url: string;
  duration: number;
  size: number;
  owner: 'USER' | 'SERVER';
}

interface RawGetMusicAssetsResponse {
  music_names: RawMusicAsset[];
}

interface RawGetInterPageSoundResponse {
  inter_page_sound_names: RawMusicAsset[];
}

export type MusicAsset = ObjectToCamel<RawMusicAsset>;

interface GetMusicAssetsResponse {
  musicNames: MusicAsset[];
}

interface GetInterPageSoundResponse {
  interPageSoundNames: MusicAsset[];
}

interface UploadMusicAssetsRequest {
  displayName: string;
  file: File;
}

interface RawDeleteMusicAssetsResponse {
  display_name: string;
  name: string;
  owner: string;
  usage: string;
}

type DeleteMusicAssetsResponse = ObjectToCamel<RawDeleteMusicAssetsResponse>;

interface DeleteMusicAssetsRequest {
  name: string;
}

interface RawModifyMusicAssetsResponse {
  display_name: string;
  name: string;
}

type ModifyMusicAssetsResponse = ObjectToCamel<RawModifyMusicAssetsResponse>;

interface ModifyMusicAssetsRequest {
  name: string;
  displayName: string;
}

interface MusicAssetsResponse {
  message: string;
  details: any;
}

export const api = createApi({
  reducerPath: 'services/musicAssets',
  baseQuery: queryFetcher('/v1/video-maker'),
  tagTypes: ['MusicAsset'],
  endpoints: (builder) => ({
    getBackgroundMusic: builder.query<MusicAsset[], void>({
      query: () => ({
        url: '/audio/background',
        method: 'GET',
      }),
      providesTags: [{ type: 'MusicAsset', id: 'Background' }],
      transformResponse: (resp: RawGetMusicAssetsResponse) => {
        const data = recordTransformer(
          resp,
          camelCase
        ) as GetMusicAssetsResponse;

        return data.musicNames;
      },
    }),
    uploadBackgroundMusic: builder.mutation<
      MusicAssetsResponse,
      UploadMusicAssetsRequest
    >({
      query: ({ displayName, file }) => {
        const formData = new FormData();
        formData.append('display_name', displayName);
        formData.append('file', file);

        return {
          url: '/audio/background',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: 'MusicAsset', id: 'Background' }],
    }),
    deleteBackgroundMusic: builder.mutation<
      DeleteMusicAssetsResponse,
      DeleteMusicAssetsRequest
    >({
      query: ({ name }) => ({
        url: `/audio/background?name=${name}`,
        method: 'DELETE',
      }),
      transformResponse: (resp: RawDeleteMusicAssetsResponse) =>
        recordTransformer(resp, camelCase) as DeleteMusicAssetsResponse,
      async onQueryStarted({ name }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getBackgroundMusic', undefined, (draft) => {
            const patch = draft.filter((el) => el.name !== name);
            draft.splice(patch.length);
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    modifyBackgroundMusic: builder.mutation<
      ModifyMusicAssetsResponse,
      ModifyMusicAssetsRequest
    >({
      query: ({ name, displayName }) => ({
        url: '/audio/background',
        method: 'PUT',
        body: {
          name,
          display_name: displayName,
        },
      }),
      transformResponse: (resp: RawModifyMusicAssetsResponse) =>
        recordTransformer(resp, camelCase) as ModifyMusicAssetsResponse,
      async onQueryStarted(
        { name, displayName },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          api.util.updateQueryData('getBackgroundMusic', undefined, (draft) => {
            const patch = draft.reduce<MusicAsset[]>((acc, item) => {
              if (item.name === name) {
                acc.push({
                  ...item,
                  displayName,
                });
              } else {
                acc.push(item);
              }

              return acc;
            }, []);

            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    getIntroMusic: builder.query<MusicAsset[], void>({
      query: () => ({
        url: '/audio/intro',
        method: 'GET',
      }),
      providesTags: [{ type: 'MusicAsset', id: 'Intro' }],
      transformResponse: (resp: RawGetMusicAssetsResponse) => {
        const data = recordTransformer(
          resp,
          camelCase
        ) as GetMusicAssetsResponse;

        return data.musicNames;
      },
    }),
    uploadIntroMusic: builder.mutation<
      MusicAssetsResponse,
      UploadMusicAssetsRequest
    >({
      query: ({ displayName, file }) => {
        const formData = new FormData();
        formData.append('display_name', displayName);
        formData.append('file', file);

        return {
          url: '/audio/intro',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: 'MusicAsset', id: 'Intro' }],
    }),
    deleteIntroMusic: builder.mutation<
      DeleteMusicAssetsResponse,
      DeleteMusicAssetsRequest
    >({
      query: ({ name }) => ({
        url: `/audio/intro?name=${name}`,
        method: 'DELETE',
      }),
      transformResponse: (resp: RawDeleteMusicAssetsResponse) =>
        recordTransformer(resp, camelCase) as DeleteMusicAssetsResponse,
      async onQueryStarted({ name }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getIntroMusic', undefined, (draft) => {
            const patch = draft.filter((el) => el.name !== name);
            draft.splice(patch.length);
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    modifyIntroMusic: builder.mutation<
      ModifyMusicAssetsResponse,
      ModifyMusicAssetsRequest
    >({
      query: ({ name, displayName }) => ({
        url: '/audio/intro',
        method: 'PUT',
        body: {
          name,
          display_name: displayName,
        },
      }),
      transformResponse: (resp: RawModifyMusicAssetsResponse) =>
        recordTransformer(resp, camelCase) as ModifyMusicAssetsResponse,
      async onQueryStarted(
        { name, displayName },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          api.util.updateQueryData('getIntroMusic', undefined, (draft) => {
            const patch = draft.reduce<MusicAsset[]>((acc, item) => {
              if (item.name === name) {
                acc.push({
                  ...item,
                  displayName,
                });
              } else {
                acc.push(item);
              }

              return acc;
            }, []);

            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    getInterPageSound: builder.query<MusicAsset[], void>({
      query: () => ({
        url: '/inter-page-sound',
        method: 'GET',
      }),
      providesTags: [{ type: 'MusicAsset', id: 'InterPageSound' }],
      transformResponse: (resp: RawGetInterPageSoundResponse) => {
        const data = recordTransformer(
          resp,
          camelCase
        ) as GetInterPageSoundResponse;

        return data.interPageSoundNames;
      },
    }),
    uploadInterPageSound: builder.mutation<
      MusicAssetsResponse,
      UploadMusicAssetsRequest
    >({
      query: ({ displayName, file }) => {
        const formData = new FormData();
        formData.append('display_name', displayName);
        formData.append('file', file);

        return {
          url: '/inter-page-sound',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: 'MusicAsset', id: 'InterPageSound' }],
    }),
    deleteInterPageSound: builder.mutation<
      DeleteMusicAssetsResponse,
      DeleteMusicAssetsRequest
    >({
      query: ({ name }) => ({
        url: `/inter-page-sound?name=${name}`,
        method: 'DELETE',
      }),
      transformResponse: (resp: RawDeleteMusicAssetsResponse) =>
        recordTransformer(resp, camelCase) as DeleteMusicAssetsResponse,
      async onQueryStarted({ name }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getInterPageSound', undefined, (draft) => {
            const patch = draft.filter((el) => el.name !== name);
            draft.splice(patch.length);
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    modifyInterPageSound: builder.mutation<
      ModifyMusicAssetsResponse,
      ModifyMusicAssetsRequest
    >({
      query: ({ name, displayName }) => ({
        url: '/inter-page-sound',
        method: 'PUT',
        body: {
          name,
          display_name: displayName,
        },
      }),
      transformResponse: (resp: RawModifyMusicAssetsResponse) =>
        recordTransformer(resp, camelCase) as ModifyMusicAssetsResponse,
      async onQueryStarted(
        { name, displayName },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          api.util.updateQueryData('getInterPageSound', undefined, (draft) => {
            const patch = draft.reduce<MusicAsset[]>((acc, item) => {
              if (item.name === name) {
                acc.push({
                  ...item,
                  displayName,
                });
              } else {
                acc.push(item);
              }

              return acc;
            }, []);

            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetBackgroundMusicQuery,
  useUploadBackgroundMusicMutation,
  useDeleteBackgroundMusicMutation,
  useModifyBackgroundMusicMutation,
  useGetIntroMusicQuery,
  useUploadIntroMusicMutation,
  useDeleteIntroMusicMutation,
  useModifyIntroMusicMutation,
  useGetInterPageSoundQuery,
  useUploadInterPageSoundMutation,
  useDeleteInterPageSoundMutation,
  useModifyInterPageSoundMutation,
} = api;

export default api;
