import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Toast } from 'components/common';
import {
  fetchUpdateProject,
  fetchCreateProject,
  selectProjectIsExist,
  selectNetworkState,
  setInitializeCellData,
  setInitializeProjectName,
} from 'features/editor/editorSlice';

const SaveButton: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'saveButton' });

  const dispatch = useAppDispatch();

  const projectIsExist = useAppSelector(selectProjectIsExist);

  const toastMessage = React.useRef<string>('');
  const toastSeverity = React.useRef<'success' | 'error'>('success');

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const networkState = useAppSelector(selectNetworkState);

  const handleClick = async () => {
    if (networkState === 'save') return;

    try {
      if (projectIsExist) {
        await dispatch(fetchUpdateProject()).unwrap();
      } else {
        dispatch(setInitializeProjectName());
        dispatch(setInitializeCellData());
        await dispatch(fetchCreateProject()).unwrap();
      }

      toastMessage.current = t('success');
      toastSeverity.current = 'success';
      toggleOpenToast();
    } catch (error) {
      toastMessage.current = t('failure.default');
      toastSeverity.current = 'error';
      toggleOpenToast();
      console.error(error);
    }
  };

  return (
    <>
      <Button onClick={handleClick}>{t('text')}</Button>
      <Toast
        in={openToast}
        onClose={toggleOpenToast}
        timeout={2500}
        severity={toastSeverity.current}
        message={toastMessage.current}
      />
    </>
  );
};

export default SaveButton;

const Button = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  outline: none;
  padding: 0 16px;
  border: 1px solid var(--color-grey-300);

  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;

  color: var(--color-black);
  background: var(--color-white);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
