export type MembershipTabType = 'product' | 'payment' | 'purchase';
export const membershipTabs: MembershipTabType[] = [
  'product',
  'payment',
  'purchase',
];

export type GradeType = 'Free' | 'Standard' | 'Pro' | 'Enterprise';
export const grades: GradeType[] = ['Free', 'Standard', 'Pro', 'Enterprise'];

export interface GradeOptions {
  download?: number;
  project?: number;
  voice?: boolean;
  stv?: boolean;
  removeWatermark?: boolean;
  video?: {
    maxSize: string;
  };
  commercialUse?: boolean;
  customizing?: boolean;
}

export interface GradePermission {
  sourceNotify?: boolean;
  sourceRequire?: boolean;
  posting?: boolean;
  broadcast?: boolean;
  advertising?: boolean;
  commercial?: boolean;
  enterprise?: boolean;
}

export interface GradeObjectType {
  grade: GradeType;
  price: number | string;
  discount?: {
    value: number;
    color: 'orange' | 'pink';
  };
  options: GradeOptions;
  permission: GradePermission;
}

export const MEMBERSHIP: GradeObjectType[] = [
  {
    grade: 'Free',
    price: 0,
    options: {
      download: 0.25,
      project: 5,
      voice: true,
    },
    permission: {
      posting: true,
      sourceRequire: true,
      sourceNotify: true,
    },
  },
  {
    grade: 'Standard',
    price: 19800,
    discount: {
      value: 50,
      color: 'orange',
    },
    options: {
      download: 2,
      project: 5,
      voice: true,
      stv: true,
      video: {
        maxSize: '100MB',
      },
      removeWatermark: true,
    },
    permission: {
      posting: true,
      broadcast: true,
      commercial: false,
      enterprise: false,
    },
  },
  {
    grade: 'Pro',
    price: 99000,
    discount: {
      value: 50,
      color: 'pink',
    },
    options: {
      download: 10,
      project: 20,
      voice: true,
      stv: true,
      removeWatermark: true,
      video: {
        maxSize: '500MB',
      },
      commercialUse: true,
    },
    permission: {
      posting: true,
      broadcast: true,
      advertising: true,
      commercial: true,
      enterprise: false,
    },
  },
  {
    grade: 'Enterprise',
    price: 198000,
    options: {
      download: 20,
      project: 100,
      voice: true,
      stv: true,
      removeWatermark: true,
      video: {
        maxSize: '1GB',
      },
      commercialUse: true,
    },
    permission: {
      posting: true,
      broadcast: true,
      advertising: true,
      commercial: true,
      enterprise: true,
    },
  },
];
