import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import { PlusIcon } from 'assets/svg';
import {
  selectCurrentPage,
  selectPageCells,
  createNewCell,
} from 'features/editor/editorSlice';
import { sort, voiceModelCompare } from 'features/editor/utils';

const ToolbarAddCellButton: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const dispatch = useAppDispatch();

  const page = useAppSelector(selectCurrentPage);
  const cells = useAppSelector(selectPageCells(page?.key ?? ''));

  const { model } = useGetVoiceModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      model: sort(data, voiceModelCompare)?.[0],
    }),
  });

  const handleClick = () => {
    if (page === undefined) return;
    if (cells === undefined) return;
    if (model === undefined) return;

    if (page.stv) {
      dispatch(
        createNewCell({
          pageKey: page.key,
          modelName: page.stv.audioModelName,
        })
      );
    } else {
      dispatch(
        createNewCell({
          pageKey: page.key,
          modelName: model.name,
        })
      );
    }
  };

  return (
    <Button onClick={handleClick}>
      <PlusIcon size={16} weight={'bold'} />
      <span>{t('toolbar.addCell')}</span>
    </Button>
  );
};

export default ToolbarAddCellButton;

const Button = styled.button`
  outline: none;
  border: none;
  padding: 0 8px;
  height: 56px;
  background: var(--color-white);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 1.4;
  color: var(--color-grey-700);
  cursor: pointer;

  span {
    white-space: nowrap;
    min-width: 96px;
  }
`;
