import * as React from 'react';
import styled from '@emotion/styled';
import MusicAssetListItem from './MusicAssetListItem';
import type { MusicAsset } from 'app/services/musicAssets';

interface MusicAssetListProps {
  items: MusicAsset[];
  selectedName?: string;
  currentAudioKey: string;
  onClick?: ({ name, duration }: { name: string; duration?: number }) => void;
  onDeleteAudio: (params: { name: string }) => void;
  onModifyAudioName: (params: { name: string; displayName: string }) => void;
  onTogglePlay: (params: {
    url: string;
    name: string;
    displayName: string;
  }) => void;
}

const MusicAssetList: React.FC<MusicAssetListProps> = ({
  items,
  selectedName,
  currentAudioKey,
  onClick,
  onDeleteAudio,
  onModifyAudioName,
  onTogglePlay,
}) => {
  return (
    <List>
      {items.map((item) => (
        <MusicAssetListItem
          isSelect={item.name === selectedName}
          key={item.name}
          name={item.name}
          displayName={item.displayName}
          src={item.downloadUrl}
          duration={item.duration}
          owner={item.owner}
          currentAudioKey={currentAudioKey}
          onClick={() => {
            if (onClick === undefined) return;
            onClick({ name: item.name, duration: item.duration });
          }}
          onDeleteAudio={onDeleteAudio}
          onModifyAudioName={onModifyAudioName}
          onTogglePlay={onTogglePlay}
        />
      ))}
    </List>
  );
};

export default MusicAssetList;

const List = styled.ul`
  list-style: none;
  margin-block: 16px;
  padding: 0 16px;
  height: calc(100% - 164px);
  overflow-y: auto;

  & > li + li {
    margin-top: 16px;
  }
`;
