import * as React from 'react';
import { useMatchMedia } from 'lib/hooks';
import Mobile from './EditorFooter.Mobile';

const EditorFooter: React.FC = () => {
  const isTablet = useMatchMedia('(min-width: 1024px)');

  if (isTablet) return null;
  return <Mobile />;
};

export default EditorFooter;
