import * as React from 'react';
import styled from '@emotion/styled';
import LastUpdatedTime from './LastUpdatedTime';
import UndoButton from './UndoButton';
import RedoButton from './RedoButton';

const EditHistory: React.FC = () => {
  return (
    <Base>
      <LastUpdatedTime />
      <UndoButton />
      <RedoButton />
    </Base>
  );
};

export default EditHistory;

const Base = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: auto;

  button + button {
    margin-left: 8px;
  }

  margin-right: 24px;

  @media (min-width: 1024px) {
    margin-right: 0;
  }
`;
