import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { LogoIcon, HunetLogoIcon } from 'assets/svg';

const HeaderLogo: React.FC = () => {
  return (
    <Anchor to={'/'}>
      {process.env.ONAIR_PLATFORM_TYPE === 'onair' && (
        <LogoIcon type={'black'} />
      )}
      {process.env.ONAIR_PLATFORM_TYPE === 'hunet' && (
        <>
          <HunetLogoIcon />
          <LogoIcon type={'black'} />
        </>
      )}
    </Anchor>
  );
};

export default HeaderLogo;

const Anchor = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 28px;

  margin-right: auto;
  cursor: default;

  &:focus {
    outline: none;
  }

  svg + svg {
    margin-left: 8px;
  }

  @media (min-width: 820px) {
    margin-right: unset;
  }
`;
