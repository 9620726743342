import * as React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { useSocialLoginMutation } from 'app/services/auth';
import { KakaoTalkIcon } from 'assets/svg';
import KakaotalkService from 'lib/KakaotalkService';
import { isFetchBaseQueryError } from 'components/auth/utils';
import { typography } from 'styles';
import { setCredentials } from './authSlice';
import InactiveUserDialog from './InactiveUserDialog';

interface KakaoTalkAuthButtonProps {
  children?: React.ReactNode;
}

const KakaoTalkAuthButton: React.FC<KakaoTalkAuthButtonProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  const [requestSocialLogin, { data: socialLoginData, isSuccess, error }] =
    useSocialLoginMutation();

  const [inactiveIsOpen, setInactiveIsOpen] = React.useState<boolean>(false);

  const toggleInactiveIsOpen = React.useCallback(() => {
    setInactiveIsOpen((prev) => !prev);
  }, []);

  React.useEffect(() => {
    if (!isSuccess) return;
    if (socialLoginData === undefined) return;

    dispatch(
      setCredentials({
        token: socialLoginData.access,
        loginTime: Date.now(),
      })
    );
  }, [socialLoginData, dispatch, isSuccess]);

  React.useEffect(() => {
    if (error === undefined) return;
    if (!isFetchBaseQueryError(error)) return;
    const { status } = error;

    if (status === 400) {
      // TODO: 400 에러에 대한 UX 없음
    }

    if (status === 403) {
      toggleInactiveIsOpen();
    }
    console.error(error);
  }, [error, toggleInactiveIsOpen]);

  const successCallback = async (token: string) => {
    requestSocialLogin({
      provider: 'kakao',
      token,
    });
  };

  const signIn = () => KakaotalkService.signIn({ successCallback });

  return (
    <>
      <Base onClick={signIn}>
        <KakaoTalkIcon width={24} height={24} />
        <Text>{children}</Text>
      </Base>
      <InactiveUserDialog
        open={inactiveIsOpen}
        onClose={toggleInactiveIsOpen}
      />
    </>
  );
};

export default KakaoTalkAuthButton;

const Base = styled.button`
  --color-kakao: #fee500;
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  border: none;
  outline: none;
  width: 100%;
  height: 48px;
  background: var(--color-kakao);
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);

  &:hover {
    background: var(--hover-gradient), var(--color-kakao);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-kakao);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-kakao);
  }

  @media (min-width: 520px) {
    width: 392px;
  }
`;

const Text = styled.span`
  ${typography.title.medium}
  margin-left: 16px;
`;
