import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useResizeObserver } from 'lib/hooks';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import BlankPageImg from 'assets/img/blank-page.jpeg';
import { selectCurrentPagePreview } from 'features/editor/editorSlice';
import VideoBox from './VideoBox';
import CCBox from './CCBox';
import WatermarkImage from './WatermarkImage';
import VideoOverlay from './VideoOverlay';

const Preview = React.forwardRef<HTMLDivElement>((_, ref) => {
  const previewContent = useAppSelector(selectCurrentPagePreview);

  const { ref: contentRef, width: contentWidth } = useResizeObserver();

  const { data: userInfo } = useGetUserInfoQuery();

  return (
    <Base ref={ref}>
      {previewContent.type === 'placeholder' && (
        <Image src={BlankPageImg} ref={contentRef} />
      )}
      {previewContent.type === 'image' && (
        <Image src={previewContent.url} ref={contentRef} />
      )}
      {previewContent.type === 'video' && (
        <VideoBox
          src={previewContent.url}
          poster={previewContent.thumbnail}
          ref={contentRef}
        />
      )}
      <CCBox imageWidth={contentWidth} contentType={previewContent.type} />
      {userInfo && !userInfo.disableWatermark && (
        <WatermarkImage imageWidth={contentWidth} />
      )}
      <VideoOverlay imageWidth={contentWidth} />
    </Base>
  );
});

export default Preview;

const Base = styled.div`
  margin: 32px;
  aspect-ratio: 16 / 9;
  background-color: var(--color-white);
  border-radius: 10px;
  background-size: 100%;
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
`;
