import * as React from 'react';
import styled from '@emotion/styled';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { DotsSixVerticalIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import CellModelName from './CellModelName';
import CellPlayButton from './CellPlayButton';
import CellChangeToneButton from './CellChangeToneButton';
import CellDownloadButton from './CellDownloadButton';
import CellDeleteButton from './CellDeleteButton';

interface CellControlBoxProps {
  pageKey: string;
  cellKey: string;
}

const CellControlBox: React.FC<CellControlBoxProps> = ({
  pageKey,
  cellKey,
}) => {
  const { data } = useGetUserInfoQuery();

  return (
    <Base>
      <IconButton size={20} radius={4}>
        <DotsSixVerticalIcon size={20} color={'currentColor'} weight={'bold'} />
      </IconButton>
      <CellModelName pageKey={pageKey} cellKey={cellKey} />
      <ToolBox>
        <CellPlayButton cellKey={cellKey} />
        <CellChangeToneButton pageKey={pageKey} cellKey={cellKey} />
        {data?.audioDownload === true && (
          <CellDownloadButton cellKey={cellKey} />
        )}
        <CellDeleteButton cellKey={cellKey} />
      </ToolBox>
    </Base>
  );
};

export default CellControlBox;

const Base = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;

  button {
    color: var(--color-grey-700);
  }
`;

const ToolBox = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;
