import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export const isFetchBaseQueryError = (
  error: any
): error is FetchBaseQueryError => {
  if (typeof error.status === undefined) return false;
  if (error.status === 'FETCH_ERROR') return true;
  if (error.status === 'PARSING_ERROR') return true;
  if (error.status === 'CUSTOM_ERROR') return true;
  if (typeof error.status === 'number') return true;
  return false;
};
