interface FetchParameters {
  token: string;
  uuid: string;
  signal?: AbortSignal;
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/projects`;

type Status = -1 | 0 | 1 | 2 | 3 | 4 | 5;

export default async function fetchGetProjectVideo({
  token,
  uuid,
  signal,
}: FetchParameters): Promise<{ downloadUrl: string; status: Status }> {
  const response = await fetch(`${URL}/${uuid}/video/merge`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    signal,
  });

  if (!response.ok) {
    if (response.status === 409) {
      throw new Error('merge limit');
    }

    throw new Error(JSON.stringify(await response.text()));
  }

  const data = await response.json();

  if (
    data.hasOwnProperty('download_url') &&
    data.hasOwnProperty('rendering_status')
  ) {
    return {
      downloadUrl: data.download_url ?? '',
      status: data.rendering_status,
    };
  }

  return {
    downloadUrl: '',
    status: 0,
  };
}
