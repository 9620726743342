import * as React from 'react';
import constate from 'constate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { VoiceModelsModal } from 'features/editor/Modals';
import {
  modifyCellData,
  modifyManyMlModel,
  selectCurrentPageKey,
  modifyTimelineEditableCell,
  selectCurrentPagePreview,
} from 'features/editor/editorSlice';
import type { ChildrenProps } from './types';

type UpdateKey = [page: string, cell: string];

type ChangeType = 'cell' | 'page' | 'all';

type Callback = (name: string) => void;

function useVoiceModelsModal() {
  const [open, setOpen] = React.useState<boolean>(false);

  const [selectedName, setSelectedName] = React.useState<string>('');
  const [updateKey, setUpdateKey] = React.useState<UpdateKey | null>(null);
  const [selectedType, setSelectedType] = React.useState<ChangeType | null>(
    null
  );

  const callbackRef = React.useRef<Callback | null>(null);

  const openModal = ({
    key,
    name,
    type,
    callback,
  }: {
    key: UpdateKey;
    name?: string;
    type: ChangeType | null;
    callback?: (name: string) => void;
  }) => {
    setUpdateKey(key);
    setSelectedType(type);
    if (name) setSelectedName(name);
    if (callback) callbackRef.current = callback;

    setOpen(true);
  };

  const closeModal = () => {
    setUpdateKey(null);
    setOpen(false);
    setSelectedName('');
    setSelectedType(null);
  };

  const dispatch = useAppDispatch();

  const currentPageKey = useAppSelector(selectCurrentPageKey);

  const { type: previewType } = useAppSelector(selectCurrentPagePreview);

  const handleChangeName = React.useCallback(
    (mlModelName: string) => {
      if (updateKey === null) return;
      const [pageKey, cellKey] = updateKey;

      setSelectedName(mlModelName);

      switch (selectedType) {
        case 'cell': {
          if (pageKey !== '' && cellKey !== '') {
            dispatch(
              modifyCellData({
                pageKey,
                cellKey,
                mlModelName,
              })
            );
          }
          break;
        }
        case 'page': {
          if (currentPageKey) {
            dispatch(
              modifyManyMlModel({
                pageKey: currentPageKey,
                mlModelName,
              })
            );
          }
          break;
        }
        case 'all': {
          dispatch(modifyManyMlModel({ mlModelName }));
          break;
        }
        default: {
          break;
        }
      }

      if (previewType === 'video') {
        dispatch(
          modifyTimelineEditableCell({
            modelName: mlModelName,
          })
        );
      }

      if (callbackRef.current !== null) callbackRef.current(mlModelName);
      closeModal();
    },
    [updateKey, selectedType, currentPageKey, previewType, dispatch]
  );

  return {
    open,
    selectedName,
    selectedType,
    openModal,
    closeModal,
    handleChangeName,
  };
}

const [
  Provider,
  useVoiceModelsModalState,
  useVoiceModelsModalSelectedName,
  useVoiceModelsModalSelectedType,
  useVoiceModelsModalOpen,
  useVoiceModelsModalClose,
  useVoiceModelsModalChangeName,
] = constate(
  useVoiceModelsModal,
  (state) => state.open,
  (state) => state.selectedName,
  (state) => state.selectedType,
  (state) => state.openModal,
  (state) => state.closeModal,
  (state) => state.handleChangeName
);

const Modal: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <>
      <VoiceModelsModal />
      {children}
    </>
  );
};

export const VoiceModelsModalProvider = ({ children }: ChildrenProps) => (
  <Provider>
    <Modal>{children}</Modal>
  </Provider>
);

export {
  useVoiceModelsModalState,
  useVoiceModelsModalSelectedName,
  useVoiceModelsModalSelectedType,
  useVoiceModelsModalOpen,
  useVoiceModelsModalClose,
  useVoiceModelsModalChangeName,
};
