import * as React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import {
  useAudioDownloadModalOpen,
  useOpenPredicted,
} from 'features/editor/providers';
import {
  fetchGetAudioStatus,
  setOpenMergeLimitToast,
} from 'features/editor/editorSlice';
import type { DownloadButtonProps } from './DownloadButton';

const AudioWAV: React.FC<DownloadButtonProps> = ({ children }) => {
  const openModal = useAudioDownloadModalOpen();
  const openPredicted = useOpenPredicted();

  const dispatch = useAppDispatch();

  const predictedCallback = async () => {
    try {
      await openModal({ format: 'audio' });
    } catch (error) {
      throw error;
    }
  };

  const handleClick = async () => {
    try {
      const notDecrementUsed = await dispatch(
        fetchGetAudioStatus('audio')
      ).unwrap();

      if (notDecrementUsed) {
        await predictedCallback();
      } else {
        openPredicted(predictedCallback);
      }
    } catch (error) {
      if ((error as Error).message === 'merge limit') {
        dispatch(setOpenMergeLimitToast(true));
        return;
      }

      console.error(error);
    }
  };

  return <Base onClick={handleClick}>{children}</Base>;
};

export default AudioWAV;

const Base = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  outline: none;
  padding: 0 16px;
  border: 1px solid var(--color-grey-300);

  width: 100%;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;

  color: var(--color-black);
  background: var(--color-white);

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
