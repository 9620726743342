import * as React from 'react';
import constate from 'constate';

function useToolbarOpenState() {
  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  return {
    open,
    toggleOpen,
  };
}

export const [
  ToolbarOpenStateProvider,
  useToolbarCurrentState,
  useToolbarToggleState,
] = constate(
  useToolbarOpenState,
  (state) => state.open,
  (state) => state.toggleOpen
);
