import * as React from 'react';
import styled from '@emotion/styled';
import { typography } from 'styles';

interface UsageCardProps {
  imageUrl: string;
  title: string;
  user: {
    thumbnail: string;
    name: string;
  };
  tags: string[];
  href: string;
}

const UsageCard: React.FC<UsageCardProps> = ({
  imageUrl,
  title,
  user,
  tags,
  href,
}) => {
  return (
    <Base>
      <Anchor href={href} target={'_blank'} rel={'noreferrer'}>
        <Image src={imageUrl} alt={''} />
      </Anchor>
      <Content>
        <Title>{title}</Title>
        <User>
          <UserThumbnail src={user.thumbnail} width={24} height={24} />
          <Username>{user.name}</Username>
        </User>
        <Hashtags>{tags.map((el) => `#${el} `)}</Hashtags>
      </Content>
    </Base>
  );
};

export default UsageCard;

const Base = styled.div`
  width: 288px;
  height: 288px;

  @media (min-width: 820px) {
    width: 352px;
    height: 325px;
  }
`;

const Anchor = styled.a`
  text-decoration: none;
`;

const Image = styled.img`
  margin-bottom: 16px;
  object-fit: cover;

  width: 288px;
  height: 162px;

  @media (min-width: 820px) {
    width: 352px;
    height: 198px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.span`
  ${typography.title.medium}
  color: var(--color-white);
`;

const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const UserThumbnail = styled.img`
  margin-right: 8px;
  border-radius: 50%;
`;

const Username = styled.span`
  ${typography.body.medium}
  color: var(--color-white);
`;

const Hashtags = styled.span`
  ${typography.body.medium}
  color: var(--color-grey-500);
`;
