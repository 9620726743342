import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from 'react-click-away-listener';
import styled from '@emotion/styled';
import { CaretDownIcon } from 'assets/svg';
import { useSortText, useSortType, useSortItems } from 'providers/projectList';
import { typography } from 'styles';

interface SearchBoxProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const SearchBox: React.FC<SearchBoxProps> = ({ value, onChange }) => {
  const { t } = useTranslation('client', { keyPrefix: 'projectList' });

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const handleToggleOpen = () => setIsOpen((prev) => !prev);

  const sortText = useSortText();
  const sortType = useSortType();
  const sortItems = useSortItems();

  return (
    <Base>
      <Input
        placeholder={t('placeholder.search')}
        value={value}
        onChange={onChange}
      />
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <SelectBox onClick={handleToggleOpen} isOpen={isOpen}>
          <SelectText>{sortText}</SelectText>
          <CaretDownIcon size={24} weight={'bold'} />
          {isOpen && (
            <OptionBox>
              {sortItems.map(({ key, text, handleClick }) => (
                <OptionItem
                  onClick={handleClick}
                  isSelected={key === sortType}
                  key={key}
                >
                  {text}
                </OptionItem>
              ))}
            </OptionBox>
          )}
        </SelectBox>
      </ClickAwayListener>
    </Base>
  );
};

export default SearchBox;

const Base = styled.div`
  display: flex;
  height: 48px;
  width: 100%;

  gap: 8px;
  margin-bottom: 16px;

  @media (min-width: 520px) {
    width: 472px;
  }

  @media (min-width: 1024px) {
    gap: 24px;
    margin-bottom: 24px;
  }
`;

const Input = styled.input`
  height: 48px;
  outline: none;
  border-radius: 8px;
  color: var(--color-black);
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-grey-300);
  padding: 12px 15px;

  ${typography.body.large}

  &:hover {
    padding: 11px 14px;
    border-width: 2px;
  }

  &:focus {
    padding: 11px 14px;
    border-width: 2px;
    border-color: var(--color-blue);
  }

  &:disabled {
    background-color: var(--color-grey-100);
    color: var(--color-grey-700);
  }

  &::placeholder {
    color: var(--color-grey-600);
  }

  width: calc(100% - 126px);

  @media (min-width: 1024px) {
    width: 330px;
  }
`;

const SelectBox = styled.div<{ isOpen: boolean }>`
  width: 118px;
  height: 48px;
  border-radius: 8px;
  color: var(--color-grey-600);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-grey-300);
  position: relative;
  z-index: 1;

  svg {
    transition: transform 225ms;
  }

  ${(props) =>
    props.isOpen
      ? `
	  border-color: var(--color-pink);

		svg {
			transform: rotate(180deg);
		}
	`
      : `
		&:hover {
			padding-left: 15px;
			padding-right: 15px;
			border-width: 2px;
			background: linear-gradient(0, rgba(51, 51, 51, 0.08), rgba(51, 51, 51, 0.08)), var(--color-white);
		}
	`}
`;

const SelectText = styled.span`
  margin-right: 8px;
  ${typography.body.large}
`;

const OptionBox = styled.div`
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translateX(-50%);
  width: 118px;
  height: 112px;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-50);
  padding-top: 8px;
  padding-bottom: 8px;
`;

const OptionItem = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${typography.body.medium}
  color: var(--color-black);

  background: var(--color-white);

  &:hover {
    background: linear-gradient(rgba(51, 51, 51, 0.08), rgba(51, 51, 51, 0.08)),
      var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  ${(props) =>
    props.isSelected &&
    `
	  font-weight: 700;
		color: var(--color-pink);
	`}
`;
