import * as React from 'react';

/**
 * @name useInterval
 *
 * @param callback void function
 * @param delay millisecond | null
 */
export default function useInterval(
  callback: () => void,
  delay?: number | null
) {
  const savedCallback = React.useRef<() => void>(() => {});

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (delay !== null) {
      const interval = window.setInterval(
        () => savedCallback.current(),
        delay ?? 0
      );
      return () => window.clearInterval(interval);
    }

    return;
  }, [delay]);
}
