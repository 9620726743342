import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useDeleteProjectMutation } from 'app/services/projects';
import { XIcon } from 'assets/svg';
import { Modal } from 'components/common';
import {
  useDeleteProjectUuid,
  useOpenDeleteProject,
  useCloseDeleteProjectDialog,
} from 'providers/projectList';
import { typography } from 'styles';

const ProjectDeleteDialog: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'projectList' });

  const deleteProjectUuid = useDeleteProjectUuid();
  const openDeleteProject = useOpenDeleteProject();

  const closeDeleteProjectDialog = useCloseDeleteProjectDialog();

  const [deleteProject] = useDeleteProjectMutation();

  const handleClickDeleteProject = () => {
    if (deleteProjectUuid !== '') {
      deleteProject(deleteProjectUuid);
    }

    closeDeleteProjectDialog();
  };

  if (deleteProjectUuid === '') return null;
  if (!openDeleteProject) return null;

  return (
    <Modal id={'project-delete-dialog'}>
      <Base>
        <Title>
          <CloseButton onClick={closeDeleteProjectDialog}>
            <XIcon size={24} weight={'bold'} />
          </CloseButton>
        </Title>
        <Content>
          <span>{t('delete.body')}</span>
        </Content>
        <Actions>
          <SubmitButton onClick={handleClickDeleteProject}>
            {t('delete.submit')}
          </SubmitButton>
          <CancelButton onClick={closeDeleteProjectDialog}>
            {t('delete.cancel')}
          </CancelButton>
        </Actions>
      </Base>
    </Modal>
  );
};

export default ProjectDeleteDialog;

const Base = styled.div`
  width: 440px;
  border-radius: 10px;
  background-color: var(--color-white);
`;

const Title = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
`;

const Content = styled.div`
  width: 100%;
  padding: 16px 24px;

  ${typography.title.large}
  color: var(--color-black);
`;

const Actions = styled.div`
  width: 100%;
  padding: 16px 24px 32px;
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 10px;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  ${typography.body.large}
`;

const CancelButton = styled.button`
  margin-top: 16px;

  width: 100%;
  height: 48px;
  border-radius: 10px;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  ${typography.body.large}
`;
