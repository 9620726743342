import * as React from 'react';
import styled from '@emotion/styled';
import {
  useBeforeCheckPadding,
  useBeforeCheckVideo,
  useInitializeTimelineCells,
} from './hooks';
import Title from './Title';
import Timeline from './Timeline';
import EditableCell from './EditableCell';
import Loading from './Loading';
import DuplicateErrorToast from './DuplicateErrorToast';
import OverflowErrorToast from './OverflowErrorToast';
import PreviewVideoErrorToast from './PreviewVideoErrorToast';

const PageTimeline: React.FC = () => {
  const isCheckedPadding = useBeforeCheckPadding();
  const videoSrc = useBeforeCheckVideo();
  const isInitialized = useInitializeTimelineCells(isCheckedPadding, videoSrc);

  return (
    <Base>
      <Loading isInitialized={isInitialized} />
      <Title />
      <Timeline isInitialized={isInitialized} />
      <EditableCell />
      <DuplicateErrorToast />
      <OverflowErrorToast />
      <PreviewVideoErrorToast />
    </Base>
  );
};

export default PageTimeline;

const Base = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
