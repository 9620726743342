import * as React from 'react';
import { useAppSelector } from 'app/hooks';
import styled from '@emotion/styled';
import { TailSpinIcon } from 'assets/svg';
import { selectTimelineIsLoading } from 'features/editor/editorSlice';

interface LoadingProps {
  isInitialized: boolean;
}

const Loading: React.FC<LoadingProps> = ({ isInitialized }) => {
  const isLoading = useAppSelector(selectTimelineIsLoading);

  if (isInitialized && !isLoading) return null;

  return (
    <Base>
      <TailSpinIcon />
    </Base>
  );
};

export default Loading;

const Base = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.4);
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: unset;
    height: 25%;
  }
`;
