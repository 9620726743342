import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  PlayCircleIcon,
  StopCircleIcon,
  TailSpinIcon,
  CheckIcon,
} from 'assets/svg';
import { getHexLuminance } from 'lib/utils';
import {
  usePusherChannelId,
  useVoiceModelsModalSelectedType,
  useVoiceModelsModalChangeName,
  useVoiceModelsModalClose,
  useCommonModalOpen,
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
} from 'features/editor/providers';
import {
  fetchMergeMlModelText,
  selectCurrentPageSTV,
  selectProjectUseAnySTV,
} from 'features/editor/editorSlice';
import AudioManager from 'lib/AudioManager';
import { codeToMergeText } from 'locales/utils';
import type { ListItemProps } from './VoiceModels.ListItem';

const ListItem: React.FC<ListItemProps> = ({
  isSelected,
  name,
  modelColor,
  displayName,
  tags,
  language,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'voiceModelSelect' });

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const dispatch = useAppDispatch();

  const channelId = usePusherChannelId();

  const handleClickListen: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.stopPropagation();

    if (channelId === undefined) return;

    const el = ref.current;
    if (el === null) return;

    if (el.readyState >= 2) {
      if (state.paused) {
        editorAudioPlay(controls);
      } else {
        editorAudioPause();
        controls.seek(0);
      }
      return;
    }

    const previewText = codeToMergeText(language);
    if (previewText === undefined) {
      console.error('unknown language code');
      return;
    }

    try {
      setMergeLoading(true);
      const blob = await dispatch(
        fetchMergeMlModelText({
          name,
          channelId,
          text: previewText,
        })
      ).unwrap();

      const url = AudioManager.createObjectUrl(blob);
      el.src = url;
    } catch (error) {
      console.error(error);
    }
  };

  const changeName = useVoiceModelsModalChangeName();
  const closeVoiceModelModal = useVoiceModelsModalClose();

  const openModal = useCommonModalOpen();

  const openSTVRemoveModal = () => {
    openModal({
      content: t('stvRemove.body'),
      submitText: t('stvRemove.yes'),
      cancelText: t('stvRemove.no'),
      submit() {
        changeName(name);
      },
      cancel() {
        closeVoiceModelModal();
      },
    });
  };

  const modalSelectedType = useVoiceModelsModalSelectedType();

  const currentPageSTV = useAppSelector(selectCurrentPageSTV);
  const projectUseAnySTV = useAppSelector(selectProjectUseAnySTV);

  const handleClick = () => {
    if (modalSelectedType === 'cell') {
      if (currentPageSTV !== undefined) {
        openSTVRemoveModal();
      } else {
        changeName(name);
      }
      return;
    }

    if (modalSelectedType === 'page') {
      if (currentPageSTV !== undefined) {
        openSTVRemoveModal();
      } else {
        changeName(name);
      }
      return;
    }

    if (modalSelectedType === 'all') {
      if (projectUseAnySTV) {
        openSTVRemoveModal();
      } else {
        changeName(name);
      }
      return;
    }
  };

  return (
    <Base onClick={handleClick} isSelected={isSelected}>
      <CheckIcon size={32} weight={'bold'} />
      <SummaryBox>
        <ProfileBox>
          <ProfileView modelColor={modelColor}>
            {displayName[0].toUpperCase()}
          </ProfileView>
          <Name>{displayName}</Name>
          <ListenButton onClick={handleClickListen}>
            {mergeLoading ? (
              <TailSpinIcon width={16} height={16} />
            ) : (
              <>
                {state.paused ? (
                  <PlayCircleIcon size={16} weight={'fill'} />
                ) : (
                  <StopCircleIcon size={16} weight={'fill'} />
                )}
              </>
            )}
            <span>{state.paused ? t('button.play') : t('button.stop')}</span>
            {element}
          </ListenButton>
        </ProfileBox>
        <Hashbang>{tags.map((tag) => `#${tag} `).join('')}</Hashbang>
      </SummaryBox>
    </Base>
  );
};

export default ListItem;

const Base = styled.li<{ isSelected: boolean }>`
  margin-top: 16px;
  width: 100%;
  min-height: 80px;
  max-height: 100px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isSelected }) =>
    isSelected ? 'var(--color-pink)' : 'var(--color-grey-300)'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 16px;
  user-select: none;
  cursor: pointer;

  & > svg {
    color: ${({ isSelected }) =>
      isSelected ? 'var(--color-pink)' : 'var(--color-grey-500)'};
  }

  &:hover {
    padding: 15px;
    border-width: 2px;
  }
`;

const SummaryBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const ProfileBox = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileView = styled.span<{
  modelColor: string;
}>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: ${({ modelColor }) =>
    getHexLuminance(modelColor) > 0.5
      ? 'var(--color-black)'
      : 'var(--color-white)'};
  background-color: ${({ modelColor }) => modelColor};
  border: ${({ modelColor }) =>
    modelColor === '#FFFFFFF' ? '1px solid var(--color-grey-500)' : 'none'};
  font-size: 10px;
  font-weight: 700;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Name = styled.span`
  margin-left: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Hashbang = styled.div`
  margin-top: 4px;
  font-size: 14px;
  line-height: 1.4;
  width: 180px;
  color: var(--color-grey-500);
`;

const ListenButton = styled.button`
  width: 72px;
  height: 20px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  padding: 0;
  background: var(--color-white);
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-700);
  cursor: pointer;

  svg {
    fill: currentColor;
  }

  span {
    margin-left: 4px;
  }
`;
