import * as React from 'react';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

interface Size {
  width: number;
  height: number;
}

export default function useResizeObserver() {
  const [size, setSize] = React.useState<Size>({ width: 0, height: 0 });
  const resizeObserver = React.useRef<ResizeObserver | null>(null);

  const onResize = React.useCallback((entries: ResizeObserverEntry[]) => {
    const { width, height } = entries[0].contentRect;
    setSize({ width, height });
  }, []);

  const ref = React.useCallback(
    (node: HTMLElement | null) => {
      if (node === null) return;
      const ro = resizeObserver.current;

      if (ro) ro.disconnect();
      resizeObserver.current = new ResizeObserver(onResize);
      resizeObserver.current.observe(node);

      const _size: Size = {
        width: node.getBoundingClientRect().width,
        height: node.getBoundingClientRect().height,
      };

      setSize(_size);
    },
    [onResize]
  );

  React.useEffect(() => {
    return () => {
      resizeObserver.current?.disconnect();
    };
  }, []);

  return {
    ref,
    ...size,
  };
}
