import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { PlayCircleIcon } from 'assets/svg';
import { LazyImage } from 'features/editor/common';

interface PoseSelectItemProps {
  isSelected: boolean;
  editThumbUrl: string;
  onSelect: () => void;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const PoseSelectItem: React.FC<PoseSelectItemProps> = ({
  isSelected,
  editThumbUrl,
  onSelect,
  onClick,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'videoModelSelect' });

  return (
    <Base isSelected={isSelected}>
      <ImageBox onClick={onSelect}>
        <Image src={editThumbUrl} alt={'pose'} draggable={false} />
      </ImageBox>
      <ItemPreviewButton onClick={onClick}>
        <PlayCircleIcon size={20} color={'currentColor'} weight={'fill'} />
        <span>{t('button.previewVideo')}</span>
      </ItemPreviewButton>
    </Base>
  );
};

export default PoseSelectItem;

const Base = styled.div<{ isSelected: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected &&
    `
	  & > div:first-of-type {
			border: 2px solid var(--color-pink);
		}
	`}
`;

const ImageBox = styled.div`
  width: 100%;
  max-height: 225px;
  background: var(--color-grey-50);
  border-radius: 10px;
`;

const Image = styled(LazyImage)`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 /9;
  object-fit: contain;
`;

const ItemPreviewButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-700);

  margin-top: 4px;
  cursor: pointer;

  span {
    margin-left: 4px;
  }
`;
