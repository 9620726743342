import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { RootState } from 'app/store';

interface QueryFetcherOptions {
  noToken?: boolean;
  mode?: RequestMode;
  credentials?: RequestCredentials;
}

const queryFetcher = (url: string, options?: QueryFetcherOptions) =>
  fetchBaseQuery({
    baseUrl: `${process.env.ONAIR_API_BASEURI}${url}`,
    mode: options?.mode,
    credentials: options?.credentials,
    prepareHeaders: (headers, { getState }) => {
      if (options?.noToken) return headers;

      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

export default queryFetcher;
