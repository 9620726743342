import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Step1Image from 'assets/img/main-step1.png';
import Step2Image from 'assets/img/main-step2.png';
import Step3Image from 'assets/img/main-step3.png';
import { typography } from 'styles';

const items = [
  {
    idx: 0,
    src: Step1Image,
  },
  {
    idx: 1,
    src: Step2Image,
  },
  {
    idx: 2,
    src: Step3Image,
  },
] as const;

const StepCardBox: React.FC = () => {
  const { t } = useTranslation('main', { keyPrefix: 'steps' });

  return (
    <CardBox>
      {items.map((el) => (
        <Card key={el.idx}>
          <CardImage src={el.src} alt={t(`items.${el.idx}.title`)} />
          <CardContent>
            <CardTitleBox>
              <CardNumber>{el.idx + 1}</CardNumber>
              <CardTitleText>{t(`items.${el.idx}.title`)}</CardTitleText>
            </CardTitleBox>
            <CardBody>{t(`items.${el.idx}.body`)}</CardBody>
          </CardContent>
        </Card>
      ))}
    </CardBox>
  );
};

export default StepCardBox;

const CardBox = styled.div`
  margin-top: 40px;
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const Card = styled.div`
  width: 288px;
  height: 382px;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.16);
  background: var(--color-white);
  border: 1px solid var(--color-grey-100);

  @media (min-width: 820px) {
    width: 352px;
    height: 390px;
  }
`;

const CardImage = styled.img`
  width: 288px;
  height: 214px;

  @media (min-width: 820px) {
    width: 352px;
    height: 262px;
  }
`;

const CardContent = styled.div`
  padding: 16px;
`;

const CardTitleBox = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`;

const CardNumber = styled.span`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--color-pink);
  color: var(--color-white);
  ${typography.title.medium}
  text-align: center;
`;

const CardTitleText = styled.span`
  margin-left: 8px;
  color: var(--color-pink);
  ${typography.title.medium}
`;

const CardBody = styled.span`
  color: var(--color-grey-800);
  ${typography.body.large}
`;
