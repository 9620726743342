import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './resources';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    ns: ['main', 'footer', 'auth', 'client', 'editor'],
    returnEmptyString: false,
    interpolation: { escapeValue: false },
    detection: { order: ['cookie', 'navigator'] },
    debug: process.env.NODE_ENV === 'development',
  });

i18n.changeLanguage('ko');

export default i18n;
