import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Rnd } from 'react-rnd';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectCurrentPageSTV,
  modifyCurrentSTVLocation,
  selectCanEditSTV,
  selectCurrentToolbarItem,
  selectCurrentCharacterIndex,
} from 'features/editor/editorSlice';
import type { RndDragCallback, RndResizeCallback } from 'react-rnd';
import StvRemoveButton from './StvRemoveButton';

const MIN_STV_WIDTH = 480;
const MIN_STV_HEIGHT = 270;

interface EditableVideoOverlayProps {
  boundaryKey: string;
  imageWidth: number;
  imageHeight: number;
  ratio: number;
  src: string;
  location: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

const EditableVideoOverlay: React.FC<EditableVideoOverlayProps> = ({
  boundaryKey,
  imageWidth,
  imageHeight,
  ratio,
  src,
  location,
}) => {
  const stv = useAppSelector(selectCurrentPageSTV);

  const boundaryRef = React.useRef<HTMLDivElement | null>(null);
  const rndRef = React.useRef<Rnd | null>(null);

  React.useLayoutEffect(() => {
    const el = boundaryRef.current;
    if (el === null) return;

    const rndInstance = rndRef.current;
    if (rndInstance === null) return;

    const maxWidthRange = MIN_STV_WIDTH + location.width * 1.8 * ratio;
    const maxHeightRange = MIN_STV_HEIGHT + location.height * 1.8 * ratio;

    const boundaryTop = -(maxHeightRange - imageHeight) / 2;
    const boundaryLeft = -(maxWidthRange - imageWidth) / 2;

    el.style.cssText = `
		  width: ${maxWidthRange}px;
			height: ${maxHeightRange}px;
			top: ${boundaryTop}px;
			left: ${boundaryLeft}px;
		`;

    rndInstance.updatePosition({
      x: location.x * ratio,
      y: location.y * ratio,
    });
    rndInstance.updateSize({
      width: location.width * ratio,
      height: location.height * ratio,
    });
  }, [ratio, location, imageWidth, imageHeight]);

  const dispatch = useAppDispatch();

  const handleDragStop: RndDragCallback = (_, data) => {
    dispatch(
      modifyCurrentSTVLocation({
        ...location,
        x: data.x / ratio,
        y: data.y / ratio,
      })
    );
  };

  const handleResizeStop: RndResizeCallback = (
    _e,
    _dir,
    el,
    _delta,
    position
  ) => {
    dispatch(
      modifyCurrentSTVLocation({
        x: position.x / ratio,
        y: position.y / ratio,
        width: el.offsetWidth / ratio,
        height: el.offsetHeight / ratio,
      })
    );
  };

  const canEdit = useAppSelector(selectCanEditSTV);
  const toolbarItem = useAppSelector(selectCurrentToolbarItem);
  const characterIndex = useAppSelector(selectCurrentCharacterIndex);
  const isFocusEdit = toolbarItem === 'character' && characterIndex === 1;

  if (stv === undefined) return null;
  return (
    <>
      <Boundary
        className={`preview-boundary-${boundaryKey}`}
        ref={boundaryRef}
      />
      <Rnd
        default={{
          x: location.x * ratio,
          y: location.y * ratio,
          width: location.width * ratio,
          height: location.height * ratio,
        }}
        resizeHandleClasses={{
          bottom: 'h b',
          bottomLeft: 'h e bl',
          bottomRight: 'h e br',
          left: 'h l',
          right: 'h r',
          top: 'h t',
          topLeft: 'h e tl',
          topRight: 'h e tr',
        }}
        resizeHandleComponent={{
          bottom: <span />,
          bottomLeft: <span />,
          bottomRight: <span />,
          left: <span />,
          right: <span />,
          top: <span />,
          topLeft: <span />,
          topRight: <span />,
        }}
        onDragStop={handleDragStop}
        onResizeStop={handleResizeStop}
        bounds={`.preview-boundary-${boundaryKey}`}
        lockAspectRatio
        enableResizing={canEdit}
        disableDragging={!canEdit}
        ref={rndRef}
        css={css`
          ${editableRnd}
          ${isFocusEdit && 'z-index: 3;'}
        `}
      >
        {canEdit && <StvRemoveButton />}
        <Image src={src} />
      </Rnd>
    </>
  );
};

export default EditableVideoOverlay;

const editableRnd = css`
  user-select: none !important;

  .h {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .e {
    width: 8px !important;
    height: 8px !important;

    span {
      width: 75%;
      height: 75%;
      background: var(--color-pink);
      border-radius: 50%;
    }
  }

  .b {
    height: 4px !important;
    bottom: -2px !important;

    span {
      width: 30%;
      height: 2px;
      background: var(--color-pink);
      border-radius: 4px;
    }
  }

  .bl {
    bottom: -4px !important;
    left: -4px !important;
  }

  .br {
    bottom: -4px !important;
    right: -4px !important;
  }

  .l {
    width: 4px !important;
    left: -2px !important;

    span {
      width: 2px;
      height: 25%;
      background: var(--color-pink);
      border-radius: 4px;
    }
  }

  .r {
    width: 4px !important;
    right: -2px !important;

    span {
      width: 2px;
      height: 25%;
      background: var(--color-pink);
      border-radius: 4px;
    }
  }

  .t {
    top: -2px !important;
    height: 4px !important;

    span {
      width: 30%;
      height: 2px;
      background: var(--color-pink);
      border-radius: 4px;
    }
  }

  .tl {
    top: -4px !important;
    left: -4px !important;
  }

  .tr {
    top: -4px !important;
    right: -4px !important;
  }
`;

const Boundary = styled.div`
  position: absolute;
  visibility: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;
