import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectCurrentPagePreview,
  setTimelineMaxTime,
  setPreviewVideoErrorToast,
} from 'features/editor/editorSlice';

const useBeforeCheckVideo = () => {
  const [videoSrc, setVideoSrc] = React.useState<string>('');

  const onceError = React.useRef<boolean>(false);

  const pagePreview = useAppSelector(selectCurrentPagePreview);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (pagePreview.type !== 'video') return;
    if (videoSrc === pagePreview.url) return;

    const video = document.createElement('video');
    video.addEventListener(
      'loadedmetadata',
      () => {
        dispatch(setTimelineMaxTime(Math.ceil(video.duration * 1000)));
        setVideoSrc(pagePreview.url);
        video.remove();
      },
      { once: true }
    );
    video.onerror = () => {
      if (onceError.current) return;
      onceError.current = true;
      dispatch(setPreviewVideoErrorToast(true));
    };
    video.src = pagePreview.url;
  }, [pagePreview, videoSrc, dispatch]);

  return videoSrc;
};

export default useBeforeCheckVideo;
