import * as React from 'react';

const CommaIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={17}
    viewBox='0 0 16 17'
    fill='none'
    {...props}
  >
    <path
      d='M8.016 2.25a5.179 5.179 0 00-5.183 5.183 5.179 5.179 0 005.183 5.183h.305v2.134c2.988-1.402 4.878-4.573 4.878-7.317A5.179 5.179 0 008.016 2.25z'
      fill='currentColor'
    />
  </svg>
);

export default CommaIcon;
