import * as React from 'react';
import styled from '@emotion/styled';
import { Listbox } from '@headlessui/react';
import { CaretDownIcon } from 'assets/svg';
import { LOCALE_CODES, codeToLang } from 'locales/utils';
import { typography } from 'styles';
import type { Code } from 'locales/utils';

interface SelectListBoxProps {
  code: Code;
  onChange: (c: Code) => void;
}

const LanguageSelectButton: React.FC<SelectListBoxProps> = ({
  code,
  onChange,
}) => {
  return (
    <Listbox as={ListboxBase} value={code} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Button as={ListboxButton} isOpen={open}>
            <span>{codeToLang(code)}</span>
            <CaretDownIcon size={24} color={'currentColor'} weight={'bold'} />
          </Listbox.Button>
          <Listbox.Options as={ListboxOptions}>
            {LOCALE_CODES.map((code) => (
              <Listbox.Option as={React.Fragment} value={code} key={code}>
                {({ selected }) => (
                  <ListboxOption isSelected={selected}>
                    <span>{codeToLang(code)}</span>
                  </ListboxOption>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};

export default LanguageSelectButton;

const ListboxBase = styled.div`
  position: relative;
  width: 120px;
`;

const ListboxButton = styled.button<{ isOpen: boolean }>`
  --padding: 8px 12px;
  --border-width: 1px;
  --active-padding: 7px 11px;
  --active-border-width: 2px;
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
  height: 100%;
  padding: var(--padding);
  border-radius: 10px;
  border: var(--border-width) solid var(--color-grey-300);
  color: var(--color-grey-600);
  background: var(--color-white);
  text-decoration: none;
  cursor: pointer;

  span {
    flex: 1 auto;
    ${typography.body.large}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  svg {
    flex: 0 0 auto;
    transition: transform 225ms;
  }

  &:hover {
    padding: var(--active-padding);
    border: var(--active-border-width) solid var(--color-grey-300);
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      padding: var(--active-padding);
      border: var(--active-border-width) solid var(--color-grey-300);
      background: var(--hover-gradient), var(--color-white);
    }
  }

  ${(props) =>
    props.isOpen &&
    `
        padding: var(--active-padding);
        border: var(--active-border-width) solid var(--color-blue);
        background: var(--color-white);
        svg {
          transform: rotate(180deg);
        }
        &:hover {
          border: var(--active-border-width) solid var(--color-blue);
          background: var(--color-white);
          @media (hover: none) {
            border: var(--active-border-width) solid var(--color-blue);
            background: var(--color-white);
          }
        }
      `}
`;

const ListboxOptions = styled.ul`
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 8px 0px 0px 0px;
  padding: 8px 0px;
  border: 2px solid var(--color-blue);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  background: var(--color-white);
  outline: none;
  list-style: none;
  cursor: pointer;
`;

const ListboxOption = styled.li<{ isSelected: boolean }>`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );
  display: flex;
  justify-content: center;
  padding: 6px 12px;
  color: var(--color-black);
  background: var(--color-white);

  span {
    ${typography.body.medium}
    font-weight: ${(props) => (props.isSelected ? 700 : 400)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--hover-gradient), var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
