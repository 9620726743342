import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useResizeObserver } from 'lib/hooks';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import BlankPageImg from 'assets/img/blank-page.jpeg';
import { selectCurrentPagePreview } from 'features/editor/editorSlice';
import WatermarkImage from 'features/editor/EditorContent/Preview/WatermarkImage';
import VideoOverlay from 'features/editor/EditorContent/Preview/VideoOverlay';

const PositionPreview = React.forwardRef<HTMLDivElement>((_, ref) => {
  const previewContent = useAppSelector(selectCurrentPagePreview);

  const { ref: contentRef, width: contentWidth } = useResizeObserver();

  const { data: userInfo } = useGetUserInfoQuery();

  return (
    <Base ref={ref} data-type={previewContent.type}>
      {previewContent.type === 'placeholder' && (
        <Image src={BlankPageImg} alt={'blank'} ref={contentRef} />
      )}
      {previewContent.type === 'image' && (
        <Image src={previewContent.url} alt={'slide image'} ref={contentRef} />
      )}
      {previewContent.type === 'video' && (
        <Image
          src={previewContent.thumbnail}
          alt={'video thumbnail'}
          ref={contentRef}
        />
      )}
      {userInfo && !userInfo.disableWatermark && (
        <WatermarkImage imageWidth={contentWidth} />
      )}
      <VideoOverlay imageWidth={contentWidth} boundaryKey={'toolbar'} />
    </Base>
  );
});

export default PositionPreview;

const Base = styled.div`
  flex: none;
  aspect-ratio: 16 / 9;
  background-color: var(--color-white);
  border-radius: 10px;
  background-size: 100%;
  position: relative;
  margin: 16px;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-grey-200);
  pointer-events: none;
`;
