import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const TERMS_OF_USER =
  'https://onairstudio.zendesk.com/hc/ko/sections/4411962940569-%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80';
const PRIVACY_POLICY =
  'https://onairstudio.zendesk.com/hc/ko/sections/4411971433625-%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4-%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8';

const InfoFooter: React.FC = () => {
  const { t } = useTranslation('footer');

  return (
    <Base>
      <FirstBox>
        <AnchorBox>
          <Anchor
            href={'https://lionrocket.ai'}
            rel={'noreferrer'}
            target={'_blank'}
          >
            {t('link.company')}
          </Anchor>
          <Anchor href={getMailtoHref()}>{t('link.contactUs')}</Anchor>
          <Anchor href={PRIVACY_POLICY}>{t('link.privacyPolicy')}</Anchor>
          <Anchor href={TERMS_OF_USER}>{t('link.termsOfUser')}</Anchor>
        </AnchorBox>
        <CopyrightText>Copyright © 2020 LionRocket</CopyrightText>
      </FirstBox>
      <SecondBox>
        <CompanyBox>
          <CompanyName>{t('text.company')}</CompanyName>
          <InfoBox>
            <span>{t('text.ceo')}</span>
            <span>{t('text.bizRegistNum')}</span>
            <span>{t('text.address')}</span>
          </InfoBox>
        </CompanyBox>
        <SNSBox>
          <Anchor
            href={'https://pf.kakao.com/_CPlms'}
            rel={'noreferrer'}
            target={'_blank'}
          >
            <KakaoChannelIcon />
          </Anchor>
          <Anchor
            href={'https://www.youtube.com/channel/UCG1gI3o5brrpst_X3nTCkYA'}
            rel={'noreferrer'}
            target={'_blank'}
          >
            <YoutubeIcon />
          </Anchor>
          <Anchor
            href={'https://www.facebook.com/officialonairstudio'}
            rel={'noreferrer'}
            target={'_blank'}
          >
            <FacebookIcon />
          </Anchor>
          <Anchor
            href={'https://www.instagram.com/onairstudio.ai'}
            rel={'noreferrer'}
            target={'_blank'}
          >
            <InstagramIcon />
          </Anchor>
          <Anchor
            href={'https://blog.naver.com/lionrocket'}
            rel={'noreferrer'}
            target={'_blank'}
          >
            <NaverBlogIcon />
          </Anchor>
        </SNSBox>
      </SecondBox>
    </Base>
  );
};

export default InfoFooter;

const getMailtoHref = (): string => {
  if (process.env.ONAIR_PLATFORM_TYPE === 'hunet') {
    return 'mailto:edusol@hunet.co.kr';
  }

  return 'mailto:contact@onairstudio.ai';
};

const Base = styled.footer`
  background: var(--color-black);
  width: 100%;
  height: 421px;

  @media (min-width: 1052px) {
    height: 180px;
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  color: var(--color-grey-300);
`;

const FirstBox = styled.div`
  position: relative;
  width: calc(100% - 32px);
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1052px) {
    flex-direction: row;
  }

  @media (min-width: 1152px) {
    width: 1120px;
  }
`;

const AnchorBox = styled.div`
  color: var(--color-grey-300);
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 24px;
  margin-right: auto;

  @media (min-width: 1052px) {
    flex-direction: row;
    gap: 24px;
    margin-top: 0;
  }
`;

const CopyrightText = styled.span`
  color: var(--color-grey-300);
  font-size: 14px;
  line-height: 1.4;
  margin-top: 24px;
  margin-right: auto;

  @media (min-width: 1052px) {
    margin-top: 0;
    margin-right: 0;
    margin-left: auto;
  }
`;

const SecondBox = styled.div`
  width: calc(100% - 32px);
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1052px) {
    flex-direction: row;
  }

  @media (min-width: 1152px) {
    width: 1120px;
  }
`;

const CompanyBox = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-grey-300);
  margin-right: auto;
  margin-bottom: 16px;

  @media (min-width: 1052px) {
    margin-bottom: 40px;
  }
`;

const CompanyName = styled.strong`
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 8px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  line-height: 1.4;
  gap: 4px;

  @media (min-width: 1052px) {
    flex-direction: row;
    gap: 16px;
  }
`;

const SNSBox = styled.div`
  display: flex;
  gap: 16px;
  margin-right: auto;
  margin-bottom: 24px;

  @media (min-width: 1052px) {
    margin-right: 0;
    margin-left: auto;
  }
`;

type IconComponent = React.FC<React.SVGProps<SVGSVGElement>>;

const KakaoChannelIcon: IconComponent = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    fill='none'
    {...props}
  >
    <path
      d='M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z'
      fill='#DADADA'
    />
    <path
      d='M21.771 17.918h-1.13V15.37c0-.586-.341-.902-.866-.902-.587 0-.963.36-.963 1.086v2.364h-1.13v-6.232h1.13v2.344c.271-.41.71-.612 1.304-.612.482 0 .876.157 1.182.481.315.324.473.762.473 1.331v2.688zm-8.12-1.064c.833 0 1.546-.498 1.76-1.269h1.232c-.3 1.483-1.455 2.455-2.992 2.455-1.787 0-3.203-1.332-3.203-3.187 0-1.854 1.416-3.187 3.203-3.187 1.552 0 2.715.992 3.001 2.5h-1.229c-.186-.82-.924-1.33-1.772-1.33-1.201 0-2 .856-2 2.017 0 1.162.908 2.001 2 2.001zM16.5 7C11.804 7 8 10.52 8 14.862c0 3.207 1.343 4.738 3.183 6.134l.008.004v3.763c0 .178.203.28.345.173l3.224-2.393.07.03c.54.099 1.098.152 1.67.152 4.695 0 8.5-3.52 8.5-7.863C25 10.52 21.195 7 16.5 7z'
      fill='#333'
    />
  </svg>
);

const YoutubeIcon: IconComponent = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm6.668 10.5a2.167 2.167 0 011.509 1.549c.356 1.366.356 4.218.356 4.218s0 2.85-.356 4.218a2.167 2.167 0 01-1.51 1.549C21.338 22.4 16 22.4 16 22.4s-5.337 0-6.668-.366a2.167 2.167 0 01-1.509-1.55c-.356-1.366-.356-4.217-.356-4.217s0-2.852.356-4.218a2.167 2.167 0 011.51-1.55c1.33-.366 6.667-.366 6.667-.366s5.337 0 6.668.367z'
      fill='#D2D2D2'
    />
    <path d='M14.4 19.2v-5.333l4.267 2.666L14.4 19.2z' fill='#D2D2D2' />
  </svg>
);

const FacebookIcon: IconComponent = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm1.668 16.703v8.705h-3.602v-8.705h-1.8v-3h1.8v-1.8C14.066 9.454 15.082 8 17.969 8h2.403v3H18.87c-1.124 0-1.198.42-1.198 1.202l-.004 1.501h2.721l-.318 3h-2.403z'
      fill='#D2D2D2'
    />
  </svg>
);

const InstagramIcon: IconComponent = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm-3.518 7.518c.91-.041 1.201-.051 3.519-.051h-.003c2.318 0 2.608.01 3.518.051.909.042 1.53.186 2.073.397a4.17 4.17 0 011.511.984c.475.474.767.95.985 1.511.21.543.354 1.163.397 2.071.04.91.051 1.201.051 3.519s-.01 2.608-.051 3.518c-.043.908-.187 1.528-.397 2.071a4.188 4.188 0 01-.985 1.512c-.474.474-.95.767-1.51.984-.543.212-1.164.355-2.072.397-.91.041-1.2.051-3.518.051-2.318 0-2.608-.01-3.518-.051-.909-.042-1.53-.185-2.072-.397a4.177 4.177 0 01-1.511-.985 4.181 4.181 0 01-.984-1.511c-.211-.543-.355-1.163-.397-2.071-.041-.91-.051-1.2-.051-3.518 0-2.318.01-2.608.051-3.519.041-.908.185-1.528.397-2.07a4.19 4.19 0 01.984-1.512c.475-.475.95-.766 1.512-.984.543-.211 1.163-.355 2.071-.397z'
      fill='#D2D2D2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.235 9.004h.766c2.278 0 2.548.009 3.448.05.832.038 1.284.177 1.584.293.399.155.682.34.981.639.299.298.483.583.639.981.116.3.256.752.293 1.584.041.9.05 1.17.05 3.447 0 2.278-.009 2.548-.05 3.447-.038.832-.177 1.284-.293 1.584a2.64 2.64 0 01-.639.98 2.64 2.64 0 01-.98.639c-.301.117-.753.256-1.585.294-.9.04-1.17.05-3.448.05-2.279 0-2.549-.01-3.449-.05-.832-.039-1.283-.178-1.584-.294a2.643 2.643 0 01-.981-.639 2.645 2.645 0 01-.639-.98c-.117-.3-.256-.752-.294-1.584-.04-.9-.049-1.17-.049-3.449s.008-2.548.05-3.447c.037-.832.176-1.284.293-1.585.155-.398.34-.682.639-.98.298-.3.583-.484.981-.64.3-.117.752-.255 1.585-.294.787-.035 1.092-.046 2.682-.048v.002zm5.32 1.417a1.024 1.024 0 100 2.049 1.024 1.024 0 000-2.049zM11.619 16a4.382 4.382 0 118.765 0 4.382 4.382 0 01-8.764 0z'
      fill='#D2D2D2'
    />
    <path
      d='M16 13.156a2.845 2.845 0 110 5.689 2.845 2.845 0 010-5.69z'
      fill='#D2D2D2'
    />
  </svg>
);

const NaverBlogIcon: IconComponent = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    fill='none'
    {...props}
  >
    <path
      d='M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z'
      fill='#D2D2D2'
    />
    <path
      d='M17.157 14.683a.747.747 0 00-.762.79.778.778 0 101.557 0 .753.753 0 00-.795-.79z'
      fill='#333'
    />
    <path
      d='M24.85 9.15A3.912 3.912 0 0022.072 8H9.928A3.925 3.925 0 006 11.928v5.845A3.925 3.925 0 009.928 21.7h4.48l1.293 2.63a.316.316 0 00.598 0l1.294-2.635h4.479A3.923 3.923 0 0026 17.772v-5.844a3.91 3.91 0 00-1.15-2.778zm-14.443 8.213a1.208 1.208 0 01-1.032-.5h-.022v.383H8.132v-5.15h1.243v1.96a1.382 1.382 0 011.054-.456 1.765 1.765 0 011.703 1.873 1.787 1.787 0 01-1.725 1.89zm3.904-.12h-1.197v-2.967c0-.551-.096-1.03-.575-1.102v-1.198c1.054 0 1.772.762 1.772 2.06v3.207zm2.875.096a1.887 1.887 0 110-3.76 1.92 1.92 0 012.06 1.892 1.889 1.889 0 01-2.06 1.87v-.002zm6.61-.407c0 1.485-.742 2.228-2.035 2.228h-.24v-1.124h.24c.646 0 .838-.407.838-1.006v-.168a1.416 1.416 0 01-1.078.48 1.696 1.696 0 01-1.677-1.797 1.799 1.799 0 011.749-1.916 1.12 1.12 0 011.006.479h.024v-.383l1.173.024v3.183z'
      fill='#333'
    />
    <path
      d='M21.88 14.659a.754.754 0 00-.79.79.742.742 0 00.79.79.721.721 0 00.762-.79.734.734 0 00-.762-.79zM10.12 16.263a.79.79 0 100-1.58.79.79 0 000 1.58z'
      fill='#333'
    />
  </svg>
);
