import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { PencilIcon } from 'assets/svg';
import { Toast } from 'components/common';
import { selectProjectName, setProjectName } from 'features/editor/editorSlice';

const ProjectNameInput: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'projectNameInput' });

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const toggleEdit = () => setIsEdit((prev) => !prev);

  const ref = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    const el = ref.current;
    if (el === null) return;
    if (!isEdit) return;

    el.focus();
  }, [isEdit]);

  const name = useAppSelector(selectProjectName);

  const dispatch = useAppDispatch();

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const handleChange = (newValue: string) => {
    const el = ref.current;
    if (el === null) return;

    if (newValue.indexOf('.') > -1) {
      toggleOpenToast();
      el.value = newValue;
      return;
    }

    dispatch(setProjectName(newValue));
  };

  const handleBlur = () => {
    toggleEdit();

    const el = ref.current;
    if (el === null) return;
    if (el.value === name) return;
    handleChange(el.value);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const el = ref.current;
    if (el === null) return;

    if (e.key === 'Enter') {
      el.blur();
      return;
    }

    if (e.key === 'Escape') {
      toggleEdit();
      el.value = name;
      return;
    }
  };

  return (
    <Base onClick={toggleEdit}>
      <Input
        placeholder={t('placeholder')}
        defaultValue={name}
        disabled={!isEdit}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        ref={ref}
        key={name}
      />
      {!isEdit && <PencilIcon size={20} weight={'fill'} />}
      <Toast
        in={openToast}
        timeout={1500}
        onClose={toggleOpenToast}
        message={t('toastError')}
        severity={'error'}
      />
    </Base>
  );
};

export default ProjectNameInput;

const Base = styled.div`
  display: flex;
  align-items: center;
  cursor: text;
  width: 180px;
  margin-bottom: 8px;

  svg {
    fill: var(--color-grey-500);
  }
`;

const Input = styled.input`
  flex: 1;
  outline: none;
  padding: 0;
  border-width: 1px;
  border-style: none none solid none;
  border-color: transparent;
  background: transparent;
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-black);
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    border-color: var(--color-grey-600);
  }

  &:disabled {
    opacity: 1;
    color: var(--color-black);
    -webkit-text-fill-color: var(--color-black);
  }

  &::placeholder {
    color: var(--color-grey-700);
  }
`;
