import { createReducer, isAnyOf, nanoid } from '@reduxjs/toolkit';
import { undoable } from 'lib/reduxUtils';
import {
  SLIDE_MIN_DURATION,
  VOICE_DEFAULT_SPEED,
  VOICE_DEFAULT_VOLUME,
} from 'features/editor/constants';
import {
  setToolbarItem,
  setCharacterToolIndex,
  setUploadToolIndex,
  setAudioToolIndex,
  setAudioToolState,
  clearAudioToolState,
  setClosedCaptionState,
  setSelectPageKey,
  setSelectCellKey,
  setInputSelection,
  setNetworkState,
  setProjectName,
  setVideoHash,
  setPageLimit,
  setOpenPageLimitToast,
  setCellLimit,
  setOpenCellLimitToast,
  setOpenMergeLimitToast,
  setInitializeProjectName,
  setInitializeCellData,
  modifyCellData,
  moveCell,
  createCell,
  removeCell,
  createLastPage,
  modifyManyMlModel,
  modifyManyDuration,
  modifyManyVoiceOption,
  clearPage,
  clearData,
  modifyPageData,
  modifyManyPageData,
  movePage,
  copyPage,
  modifyCurrentSTVLocation,
  splitCell,
  mergeCell,
  removePage,
  createNewCell,
  createNewCellByModel,
  overrideExtractFileSlide,
  appendExtractFileSlide,
  overrideImageFileSlide,
  appendImageFileSlide,
  overrideExtractFileText,
  appendExtractFileText,
  undo,
  redo,
  clearHistory,
  setChannelId,
  fetchProjectById,
  fetchTemplateById,
  fetchUpdateProject,
  fetchCreateProject,
  fetchSubtitleSRT,
  fetchSubtitleTXT,
  fetchAudio,
  fetchVideo,
  setVideoPagePaddingCell,
  fetchTimelineCells,
  removeTimelineCell,
  createTimelineCell,
  createTimelinePaddingCell,
  editTimelineCell,
  newMoveTimelineCell,
  moveTimelineCell,
  removePageStv,
  setTimelineIsLoading,
  setTimelineChange,
  setTimelineRulerWidth,
  setTimelineIndicatorPosition,
  setTimelineCurrentTime,
  setTimelineMaxTime,
  setTimelineMultiplierIndex,
  setTimelineDuplicateToast,
  setTimelineOverflowToast,
  setPreviewVideoErrorToast,
  modifyTimelineEditableCell,
  setInitializeTimelineCells,
  deleteVideoAsset,
} from './actions';
import {
  isFullWordbook,
  createCellsByExtractFile,
  isUpdateAfterModified,
  setDataStateFromUpdated,
  setPageEntitiesFromUpdated,
  setEmptyProjectName,
  getEnableTimelineSection,
} from './utils';
import { INITIAL_PAGE_KEY } from './constants';
import type { Draft, Reducer, AnyAction } from '@reduxjs/toolkit';
import type { StateOfHistory } from 'lib/reduxUtils';
import type {
  CellEntity,
  DataState,
  PageEntity,
  UIState,
  TimelineState,
  TimelineSection,
  ToastState,
} from './types';

const uiInitialState: UIState = {
  toolbarItem: 'character',
  characterToolIndex: 0,
  uploadToolIndex: 0,
  audioToolIndex: 0,
  audioToolState: {},
  closedCaptionState: {
    use: false,
    fontColor: '#ffffff',
    fontSize: 12,
    backgroundColor: '#000000',
    backgroundOpacity: 0.5,
  },
  selectedPageKey: INITIAL_PAGE_KEY,
};

const dataInitialState: DataState = {
  name: '',
  pages: [
    {
      key: INITIAL_PAGE_KEY,
      num: 0,
      duration: SLIDE_MIN_DURATION,
      cells: [],
      isTempImage: true,
    },
  ],
  rs: 0, // rendering status
  pageLimit: undefined,
  cellLimit: undefined,
  _origin: '',
  _modified: false,
};

const timelineInitialState: TimelineState = {
  currentTime: 0,
  maxTime: 0,
  multiplierIndex: 2,
  rulerWidth: 0,
  indicatorPosition: 0,
  editableCell: {
    text: '',
    displayText: '',
    volume: VOICE_DEFAULT_VOLUME,
    speed: VOICE_DEFAULT_SPEED,
    modelName: '',
  },
  isLoading: false,
  isChanged: false,
};

const toastInitialState: ToastState = {
  pageLimit: false,
  cellLimit: false,
  mergeLimit: false,
  timelineDuplicate: false,
  timelineOverflow: false,
  previewVideoError: false,
};

const initialState = {
  ui: uiInitialState,
  data: dataInitialState,
  timeline: timelineInitialState,
  network: 'idle',
  channelId: '',
  loadingScreen: false,
  toast: toastInitialState,
};

const reducers = createReducer(initialState, (builder) => {
  builder
    // UI Reducer
    .addCase(setToolbarItem, (state, { payload }) => {
      state.ui.toolbarItem = payload;
    })
    .addCase(setCharacterToolIndex, (state, { payload }) => {
      state.ui.characterToolIndex = payload;
    })
    .addCase(setUploadToolIndex, (state, { payload }) => {
      state.ui.uploadToolIndex = payload;
    })
    .addCase(setAudioToolIndex, (state, { payload }) => {
      state.ui.audioToolIndex = payload;
    })
    .addCase(setAudioToolState, (state, { payload }) => {
      state.data._modified = true;
      state.ui.audioToolState = {
        ...state.ui.audioToolState,
        ...payload,
      };
    })
    .addCase(clearAudioToolState, (state, { payload }) => {
      state.data._modified = true;
      if (payload === undefined) {
        state.ui.audioToolState = {};
      } else {
        delete state.ui.audioToolState[payload];
      }
    })
    .addCase(setClosedCaptionState, (state, { payload }) => {
      state.data._modified = true;
      state.ui.closedCaptionState = {
        ...state.ui.closedCaptionState,
        ...payload,
      };
    })
    .addCase(setSelectPageKey, (state, { payload }) => {
      state.ui.selectedPageKey = payload;
      const currentPage = state.data.pages.find((el) => el.key === payload);
      if (currentPage !== undefined) {
        if (currentPage.videoPath && currentPage.videoUrl) {
          state.ui.selectedCellKey = undefined;
          return;
        }

        if (currentPage.cells?.length > 0) {
          state.ui.selectedCellKey = currentPage.cells[0].key;
        } else {
          state.ui.selectedCellKey = undefined;
        }
      }
    })
    .addCase(setSelectCellKey, (state, { payload }) => {
      state.ui.selectedCellKey = payload;
    })
    .addCase(setInputSelection, (state, { payload }) => {
      state.ui.inputSelection = payload;
    })
    .addCase(setNetworkState, (state, { payload }) => {
      if (payload === 'load') {
        state.loadingScreen = true;
      } else if (payload === 'idle') {
        state.loadingScreen = false;
      }

      state.network = payload;
    })
    .addCase(setChannelId, (state, { payload }) => {
      state.channelId = payload;
    })
    .addCase(setVideoHash, (state, { payload }) => {
      state.data.videoHash = payload;
    })
    .addCase(setPageLimit, (state, { payload }) => {
      state.data.pageLimit = payload;
    })
    .addCase(setOpenPageLimitToast, (state, { payload }) => {
      state.toast.pageLimit = payload;
    })
    .addCase(setCellLimit, (state, { payload }) => {
      state.data.cellLimit = payload;
    })
    .addCase(setOpenCellLimitToast, (state, { payload }) => {
      state.toast.cellLimit = payload;
    })
    .addCase(setOpenMergeLimitToast, (state, { payload }) => {
      state.toast.mergeLimit = payload;
    })
    // Data Reducer
    .addCase(setProjectName, (state, { payload }) => {
      state.data.name = payload;
    })
    .addCase(setInitializeProjectName, (state) => {
      state.data.name = setEmptyProjectName(state.data.name);
    })
    .addCase(setInitializeCellData, (state) =>
      state.data.pages
        .flatMap((el) => el.cells)
        .forEach((cell) => {
          cell.displayText =
            cell.displayText === '' ? cell.text : cell.displayText;
        })
    )
    .addCase(modifyCellData, (state, { payload }) => {
      const { pageKey, cellKey, ...modifiedData } = payload;

      const page = state.data.pages.find((el) => el.key === pageKey);
      if (page === undefined) return;

      const cell = page.cells.find((el) => el.key === cellKey);
      if (cell === undefined) return;
      if (cell.volume === undefined) cell.volume = VOICE_DEFAULT_VOLUME;
      if (cell.speed === undefined) cell.speed = VOICE_DEFAULT_SPEED;

      Object.keys(cell).forEach((key) => {
        const data = modifiedData[key];

        if (data !== undefined) {
          if (typeof data === 'number' && key !== 'speed') {
            cell[key] = Math.round(data);
          } else {
            cell[key] = data;
          }
        }
      });
    })
    .addCase(moveCell, (state, { payload }) => {
      const { pageKey, from, to } = payload;
      const currentPage = state.data.pages.find((el) => el.key === pageKey);
      if (currentPage === undefined) return;

      const newEntities = currentPage.cells.slice();

      newEntities.splice(
        to < 0 ? newEntities.length + to : to,
        0,
        newEntities.splice(from, 1)[0]
      );

      currentPage.cells = newEntities;
    })
    .addCase(createCell, (state, { payload }) => {
      const allCellLength = state.data.pages.flatMap((el) => el.cells).length;

      if ((state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <= allCellLength) {
        state.toast.cellLimit = true;
        return;
      }

      const { pageKey, index, mlModelName, ...newCell } = payload;

      const page = state.data.pages.find((page) => page.key === pageKey);
      if (page === undefined) return;

      const len = page?.cells.length;
      if (len < index) return;

      const prevCell = page.cells[index - 1];

      const newMlModelName = mlModelName ?? prevCell?.mlModelName;

      if (newMlModelName === undefined) return;

      page.cells = [
        ...page.cells.slice(0, index),
        {
          key: nanoid(),
          text: '',
          displayText: '',
          duration: 300,
          mlModelName: newMlModelName,
          ...newCell,
          speed: newCell.speed ?? VOICE_DEFAULT_SPEED,
          volume: newCell.volume ?? VOICE_DEFAULT_VOLUME,
        },
        ...page.cells.slice(index),
      ];
    })
    .addCase(removeCell, (state, { payload }) => {
      const { page, cell, cellIndex } = state.data.pages.reduce<{
        page?: Draft<PageEntity>;
        cell?: Draft<CellEntity>;
        cellIndex?: number;
      }>((acc, item) => {
        const cellIdx = item.cells.findIndex((el) => el.key === payload);
        if (cellIdx === -1) return acc;

        acc.page = item;
        acc.cell = item.cells[cellIdx];
        acc.cellIndex = cellIdx;
        return acc;
      }, {});

      if (page === undefined) return;
      if (cell === undefined) return;
      if (cellIndex === undefined) return;

      if (state.ui.selectedCellKey === payload) {
        const cellSize = page.cells.length;
        if (cellSize > 1) {
          const nextSelectedCellKey =
            cellSize - 1 === cellIndex
              ? page.cells[cellIndex - 1].key
              : page.cells[cellIndex + 1].key;
          state.ui.selectedCellKey = nextSelectedCellKey;
        }
      }

      page.cells = page.cells.filter((el) => el.key !== payload);
    })
    .addCase(createLastPage, (state) => {
      if (
        (state.data.pageLimit ?? Number.MAX_SAFE_INTEGER) <=
        state.data.pages.length
      ) {
        state.toast.pageLimit = true;
        return;
      }

      const newPagekey = nanoid();

      state.data.pages.push({
        key: newPagekey,
        num: state.data.pages.length,
        cells: [],
        duration: SLIDE_MIN_DURATION,
        isTempImage: false,
      });

      state.ui.selectedPageKey = newPagekey;
      state.ui.selectedCellKey = undefined;
    })
    .addCase(modifyManyMlModel, (state, { payload }) => {
      const { mlModelName, pageKey } = payload;

      if (pageKey === undefined) {
        state.data.pages.forEach((page) => {
          if (page.stv?.audioModelName !== mlModelName) {
            page.stv = undefined;
          }

          page.cells.forEach((cell) => {
            cell.mlModelName = mlModelName;
          });
        });
        return;
      }

      const page = state.data.pages.find((page) => page.key === pageKey);
      if (page === undefined) return;

      if (page.stv?.audioModelName !== mlModelName) {
        page.stv = undefined;
      }

      page.cells = page.cells.map((cell) => ({
        ...cell,
        mlModelName,
      }));
    })
    .addCase(modifyManyDuration, (state, { payload }) => {
      const { duration, pageKey } = payload;

      if (pageKey === undefined) {
        state.data.pages.forEach((page) => {
          if (page.videoPath === undefined && page.videoUrl === undefined) {
            page.cells.forEach((cell) => {
              cell.duration = Math.round(duration);
            });
          }
        });
        return;
      }

      const page = state.data.pages.find((page) => page.key === pageKey);
      if (page === undefined) return;

      page.cells = page.cells.map((cell) => ({
        ...cell,
        duration,
      }));
    })
    .addCase(modifyManyVoiceOption, (state, { payload }) => {
      const { volume, speed, pageKey } = payload;

      if (pageKey === undefined) {
        state.data.pages.forEach((page) => {
          page.cells.forEach((cell) => {
            if (volume !== undefined) cell.volume = volume;
            if (speed !== undefined) cell.speed = speed;
          });
        });
        return;
      }

      const page = state.data.pages.find((page) => page.key === pageKey);
      if (page === undefined) return;

      page.cells.forEach((cell) => {
        if (volume !== undefined) cell.volume = volume;
        if (speed !== undefined) cell.speed = speed;
      });
    })
    .addCase(clearPage, (state, { payload }) => {
      if (payload.pageKey) {
        const index = state.data.pages.findIndex(
          (page) => page.key === payload.pageKey
        );
        if (index !== -1) {
          state.data.pages = [
            ...state.data.pages.slice(0, index),
            ...state.data.pages.slice(index + 1),
          ].map((el, idx) => ({ ...el, num: idx }));
          return;
        }
      }

      state.data.pages = [
        {
          key: nanoid(),
          num: 0,
          duration: 0,
          cells: [],
          isTempImage: false,
        },
      ];
    })
    .addCase(clearData, (state) => {
      state.data = dataInitialState;
    })
    .addCase(modifyPageData, (state, { payload }) => {
      const { pageKey, ...modifiedPageData } = payload;

      const page = state.data.pages.find((el) => el.key === pageKey);
      if (page === undefined) return;

      Object.keys(modifiedPageData).forEach((key) => {
        if (key === 'imageUrl') {
          page.videoUrl = undefined;
          page.videoPath = undefined;
        }

        if (key === 'videoUrl') {
          page.imageUrl = undefined;
          page.imagePath = undefined;
        }

        page[key] = modifiedPageData[key];
      });
    })
    .addCase(setInitializeTimelineCells, (state, { payload }) => {
      const { pageKey, cells } = payload;

      const page = state.data.pages.find((el) => el.key === pageKey);
      if (page === undefined) return;

      state.timeline.isChanged = false;
      page.cells = cells;
    })
    .addCase(modifyManyPageData, (state, { payload }) => {
      const { pageKeys, ...modifiedPageData } = payload;

      if (pageKeys === undefined) {
        state.data.pages.forEach((page) => {
          Object.keys(modifiedPageData).forEach((key) => {
            const data = modifiedPageData[key];

            if (data !== undefined) page[key] = data;
          });
        });
        return;
      }

      state.data.pages.forEach((page) => {
        if (pageKeys.includes(page.key)) {
          Object.keys(modifiedPageData).forEach((key) => {
            const data = modifiedPageData[key];

            if (data !== undefined) page[key] = data;
          });
        }
      });
    })
    .addCase(movePage, (state, { payload }) => {
      const { from, to } = payload;
      const newEntities = state.data.pages.slice();

      newEntities.splice(
        to < 0 ? newEntities.length + to : to,
        0,
        newEntities.splice(from, 1)[0]
      );

      state.data.pages = newEntities.map((el, idx) => ({ ...el, num: idx }));
    })
    .addCase(copyPage, (state, { payload }) => {
      const pageIndex = state.data.pages.findIndex(
        (el) => el.key === payload.pageKey
      );
      if (pageIndex === -1) return;

      const allCellLength = state.data.pages.flatMap((el) => el.cells).length;

      if ((state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <= allCellLength) {
        state.toast.cellLimit = true;
        return;
      }

      const page = state.data.pages[pageIndex];

      if (
        (state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <=
        allCellLength + page.cells.length
      ) {
        state.toast.cellLimit = true;
        return;
      }

      state.data.pages = [
        ...state.data.pages.slice(0, pageIndex),
        {
          key: nanoid(),
          duration: page.duration,
          imagePath: page.imagePath,
          imageUrl: page.imageUrl,
          thumbnailPath: page.thumbnailPath,
          thumbnailUrl: page.thumbnailUrl,
          videoPath: page.videoPath,
          videoUrl: page.videoUrl,
          isTempImage: true,
          stv: page.stv,
          num: page.num,
          cells: page.cells.map((el) => ({
            ...el,
            key: nanoid(),
            uuid: undefined,
          })),
        },
        ...state.data.pages.slice(pageIndex),
      ].map((el, idx) => ({ ...el, num: idx }));
    })
    .addCase(modifyCurrentSTVLocation, (state, { payload }) => {
      const selectedPageKey = state.ui.selectedPageKey;

      const page = state.data.pages.find((el) => el.key === selectedPageKey);
      const location = page?.stv?.location;

      if (location === undefined) return;

      Object.keys(payload).forEach((key) => {
        if (page?.stv === undefined) return;
        const data = payload[key];

        if (data !== undefined) location[key] = data;
      });
    })
    .addCase(splitCell, (state, { payload }) => {
      const allCellLength = state.data.pages.flatMap((el) => el.cells).length;

      if ((state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <= allCellLength) {
        state.toast.cellLimit = true;
        return;
      }

      const { cellKey, len } = payload;

      const { page, cell, cellIndex } = state.data.pages.reduce<{
        page?: PageEntity;
        cell?: CellEntity;
        cellIndex?: number;
      }>((acc, item) => {
        const idx = item.cells.findIndex((el) => el.key === cellKey);
        if (idx === -1) return acc;

        acc.page = item;
        acc.cell = item.cells[idx];
        acc.cellIndex = idx;
        return acc;
      }, {});

      if (page === undefined) return;
      if (cell === undefined) return;
      if (cellIndex === undefined) return;

      const currentCell: CellEntity = {
        ...cell,
        key: nanoid(),
        text: cell.text.slice(0, len),
        displayText: cell.displayText.slice(0, len),
      };

      const newCell: CellEntity = {
        key: nanoid(),
        mlModelName: cell.mlModelName,
        text: cell.text.slice(len),
        displayText: cell.displayText.slice(len),
        duration: cell.duration,
        speed: VOICE_DEFAULT_SPEED,
        volume: VOICE_DEFAULT_VOLUME,
      };

      state.ui.selectedCellKey = newCell.key;
      page.cells = [
        ...page.cells.slice(0, cellIndex),
        currentCell,
        newCell,
        ...page.cells.slice(cellIndex + 1),
      ];
      state.ui.inputSelection = 0;
    })
    .addCase(mergeCell, (state, { payload }) => {
      const { cellKey } = payload;

      const { page, cell, cellIndex } = state.data.pages.reduce<{
        page?: PageEntity;
        cell?: CellEntity;
        cellIndex?: number;
      }>((acc, item) => {
        const idx = item.cells.findIndex((el) => el.key === cellKey);
        if (idx === -1) return acc;

        acc.page = item;
        acc.cell = item.cells[idx];
        acc.cellIndex = idx;
        return acc;
      }, {});

      if (page === undefined) return;
      if (cell === undefined) return;
      if (cellIndex === undefined) return;
      if (cellIndex === 0) return;
      if (page.cells.length === 1) return;

      const updateCell = page.cells[cellIndex - 1];

      const inputSelection = updateCell.text.length + 1;
      const newText = `${updateCell.text} ${cell.text}`;
      const newDisplayText = `${updateCell.displayText} ${cell.displayText}`;
      const newKey = nanoid();

      page.cells = page.cells.filter((el) => el.key !== cell.key);

      updateCell.key = newKey;
      updateCell.text = newText;
      updateCell.displayText = newDisplayText;

      state.ui.inputSelection = inputSelection;
      state.ui.selectedCellKey = newKey;
    })
    .addCase(removePage, (state, { payload }) => {
      const { pageKey } = payload;
      const pageSize = state.data.pages.length;
      const pageIndex = state.data.pages.findIndex((el) => el.key === pageKey);

      if (pageIndex === -1) return;
      if (pageSize === 1) {
        const page = state.data.pages[pageIndex];
        state.data.pages[pageIndex] = {
          key: page.key,
          num: page.num,
          duration: SLIDE_MIN_DURATION,
          cells: [],
          isTempImage: true,
        };
        return;
      }

      if (state.ui.selectedPageKey === pageKey) {
        const nextSelectedPageKey =
          pageSize - 1 === pageIndex
            ? state.data.pages[pageIndex - 1].key
            : state.data.pages[pageIndex + 1].key;
        state.ui.selectedPageKey = nextSelectedPageKey;
      }

      state.data.pages = state.data.pages
        .filter((el) => el.key !== pageKey)
        .map((el, idx) => ({ ...el, num: idx }));
    })
    .addCase(createNewCell, (state, { payload }) => {
      const allCellLength = state.data.pages.flatMap((el) => el.cells).length;

      if ((state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <= allCellLength) {
        state.toast.cellLimit = true;
        return;
      }

      const { pageKey, modelName, isLast } = payload;

      const page = state.data.pages.find((el) => el.key === pageKey);
      if (page === undefined) return;

      const selectedCellKey = state.ui.selectedCellKey;

      const newCellkey = nanoid();

      let newCell: CellEntity = {
        key: newCellkey,
        text: '',
        displayText: '',
        duration: 300,
        mlModelName: modelName,
        speed: VOICE_DEFAULT_SPEED,
        volume: VOICE_DEFAULT_VOLUME,
      };

      const selectedCellIndex = page.cells.findIndex(
        (el) => el.key === selectedCellKey
      );

      if (page.stv !== undefined && page.stv.audioModelName !== modelName) {
        page.stv = undefined;
        page.cells.forEach((cell) => {
          cell.mlModelName = modelName;
        });
      }

      if (page.key !== undefined) {
        state.ui.selectedPageKey = page.key;
      }

      state.ui.selectedCellKey = newCellkey;

      if (page.cells.length === 0) {
        page.cells.push(newCell);
        return;
      }

      if (isLast || selectedCellIndex === -1) {
        const lastCell = page.cells[page.cells.length - 1];
        newCell.mlModelName = lastCell.mlModelName;
        page.cells.push(newCell);
        return;
      }

      const prevCell = page.cells[selectedCellIndex];
      newCell.mlModelName = prevCell.mlModelName;

      page.cells = [
        ...page.cells.slice(0, selectedCellIndex + 1),
        newCell,
        ...page.cells.slice(selectedCellIndex + 1),
      ];
    })
    .addCase(createNewCellByModel, (state, { payload }) => {
      const allCellLength = state.data.pages.flatMap((el) => el.cells).length;

      if ((state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <= allCellLength) {
        state.toast.cellLimit = true;
        return;
      }

      const { pageKey, modelName } = payload;

      const page = state.data.pages.find((el) => el.key === pageKey);
      if (page === undefined) return;

      const selectedCellKey = state.ui.selectedCellKey;

      let newCell: CellEntity = {
        key: nanoid(),
        text: '',
        displayText: '',
        duration: 300,
        mlModelName: modelName,
        speed: VOICE_DEFAULT_SPEED,
        volume: VOICE_DEFAULT_VOLUME,
      };

      const selectedCellIndex = page.cells.findIndex(
        (el) => el.key === selectedCellKey
      );

      if (page.stv !== undefined && page.stv.audioModelName !== modelName) {
        page.stv = undefined;
        page.cells.forEach((cell) => {
          cell.mlModelName = modelName;
        });
      }

      if (selectedCellIndex === -1) {
        page.cells.push(newCell);
        return;
      }

      page.cells = [
        ...page.cells.slice(0, selectedCellIndex + 1),
        newCell,
        ...page.cells.slice(selectedCellIndex + 1),
      ];
    })
    .addCase(setVideoPagePaddingCell, (state, { payload }) => {
      const { modelName } = payload;

      if (state.ui.selectedPageKey === undefined) return;

      const currentPage = state.data.pages.find(
        (el) => el.key === state.ui.selectedPageKey
      );
      if (currentPage === undefined) return;

      const paddingCell: CellEntity = {
        key: nanoid(),
        text: '',
        displayText: '',
        duration: 0,
        mlModelName: modelName,
        speed: VOICE_DEFAULT_SPEED,
        volume: VOICE_DEFAULT_VOLUME,
      };

      currentPage.cells = [paddingCell, ...currentPage.cells];
      state.data._modified = true;
    })
    .addCase(overrideExtractFileSlide, (state, { payload }) => {
      const { startPageKey, info, wordBook, modelName } = payload;

      if ((state.data.pageLimit ?? Number.MAX_SAFE_INTEGER) <= info.length) {
        state.toast.pageLimit = true;
        return;
      }

      const allCellLength = info.flatMap((el) => el.cells).length;

      if ((state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <= allCellLength) {
        state.toast.cellLimit = true;
        return;
      }

      let startIndex = state.data.pages.findIndex(
        (el) => el.key === startPageKey
      );
      if (startIndex === -1) return;

      if (!isFullWordbook(wordBook)) return;

      const mlModelName =
        state.data.pages[startIndex].cells?.[0]?.mlModelName ?? modelName;

      info.forEach((item) => {
        if (state.data.pages[startIndex] === undefined) {
          state.data.pages.push({
            key: nanoid(),
            num: startIndex,
            duration: SLIDE_MIN_DURATION,
            thumbnailUrl: item.thumb.url,
            thumbnailPath: item.thumb.tmpPath,
            imageUrl: item.image.url,
            imagePath: item.image.tmpPath,
            isTempImage: true,
            cells: createCellsByExtractFile(item.cells, wordBook, mlModelName),
          });
        } else {
          state.data.pages[startIndex].thumbnailUrl = item.thumb.url;
          state.data.pages[startIndex].thumbnailPath = item.thumb.tmpPath;
          state.data.pages[startIndex].imageUrl = item.image.url;
          state.data.pages[startIndex].imagePath = item.image.tmpPath;
          state.data.pages[startIndex].cells = createCellsByExtractFile(
            item.cells,
            wordBook,
            mlModelName
          );
          state.data.pages[startIndex].videoUrl = undefined;
          state.data.pages[startIndex].videoPath = undefined;
        }

        startIndex++;
      });
    })
    .addCase(appendExtractFileSlide, (state, { payload }) => {
      const { startPageKey, info, wordBook, modelName } = payload;

      if ((state.data.pageLimit ?? Number.MAX_SAFE_INTEGER) <= info.length) {
        state.toast.pageLimit = true;
        return;
      }

      if (
        (state.data.pageLimit ?? Number.MAX_SAFE_INTEGER) <=
        state.data.pages.length + info.length
      ) {
        state.toast.pageLimit = true;
        return;
      }

      const allCellLength = state.data.pages.flatMap((el) => el.cells).length;
      const allInfoCellLength = info.flatMap((el) => el.cells).length;

      if (
        (state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <=
        allCellLength + allInfoCellLength
      ) {
        state.toast.cellLimit = true;
        return;
      }

      let startIndex = state.data.pages.findIndex(
        (el) => el.key === startPageKey
      );
      if (startIndex === -1) return;

      if (!isFullWordbook(wordBook)) return;

      const mlModelName =
        state.data.pages[startIndex].cells?.[0]?.mlModelName ?? modelName;

      state.data.pages = [
        ...state.data.pages.slice(0, startIndex + 1),
        ...info.map((item, idx) => ({
          key: nanoid(),
          num: startIndex + idx + 1,
          duration: SLIDE_MIN_DURATION,
          thumbnailUrl: item.thumb.url,
          thumbnailPath: item.thumb.tmpPath,
          imageUrl: item.image.url,
          imagePath: item.image.tmpPath,
          isTempImage: true,
          cells: createCellsByExtractFile(item.cells, wordBook, mlModelName),
        })),
        ...state.data.pages.slice(startIndex + 1),
      ].map((el, idx) => ({ ...el, num: idx }));
    })
    .addCase(overrideImageFileSlide, (state, { payload }) => {
      const { pageKey, imageUrl, imagePath, thumbnailUrl, thumbnailPath } =
        payload;

      const page = state.data.pages.find((el) => el.key === pageKey);
      if (page === undefined) return;

      const isVideoProject =
        page.videoUrl !== undefined && page.videoPath !== undefined;

      page.imageUrl = imageUrl;
      page.imagePath = imagePath;
      page.thumbnailUrl = thumbnailUrl;
      page.thumbnailPath = thumbnailPath;
      page.videoUrl = undefined;
      page.videoPath = undefined;
      page.isTempImage = true;

      if (isVideoProject) page.cells.shift();
    })
    .addCase(appendImageFileSlide, (state, { payload }) => {
      const { pageKey, imageUrl, imagePath, thumbnailUrl, thumbnailPath } =
        payload;

      if (
        (state.data.pageLimit ?? Number.MAX_SAFE_INTEGER) <=
        state.data.pages.length
      ) {
        state.toast.pageLimit = true;
        return;
      }

      const pageIndex = state.data.pages.findIndex((el) => el.key === pageKey);
      if (pageIndex === -1) return;

      state.data.pages = [
        ...state.data.pages.slice(0, pageIndex + 1),
        {
          key: nanoid(),
          imageUrl: imageUrl,
          imagePath: imagePath,
          thumbnailUrl: thumbnailUrl,
          thumbnailPath: thumbnailPath,
          isTempImage: true,
          duration: SLIDE_MIN_DURATION,
          num: pageIndex + 1,
          cells: [],
        },
        ...state.data.pages.slice(pageIndex + 1),
      ].map((el, idx) => ({ ...el, num: idx }));
    })
    .addCase(overrideExtractFileText, (state, { payload }) => {
      const { startPageKey, info, wordBook, modelName } = payload;

      const allCellLength = info.flatMap((el) => el.cells).length;

      if ((state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <= allCellLength) {
        state.toast.cellLimit = true;
        return;
      }

      let startIndex = state.data.pages.findIndex(
        (el) => el.key === startPageKey
      );
      if (startIndex === -1) return;

      if (!isFullWordbook(wordBook)) return;

      const mlModelName =
        state.data.pages[startIndex].cells?.[0]?.mlModelName ?? modelName;

      const item = info[0];

      state.data.pages[startIndex].cells = createCellsByExtractFile(
        item.cells,
        wordBook,
        mlModelName
      );
    })
    .addCase(appendExtractFileText, (state, { payload }) => {
      const { startPageKey, info, wordBook, modelName } = payload;

      const allCellLength = state.data.pages.flatMap((el) => el.cells).length;
      const allInfoCellLength = info.flatMap((el) => el.cells).length;

      if ((state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <= allCellLength) {
        state.toast.cellLimit = true;
        return;
      }

      if (
        (state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <=
        allCellLength + allInfoCellLength
      ) {
        state.toast.cellLimit = true;
        return;
      }

      let startIndex = state.data.pages.findIndex(
        (el) => el.key === startPageKey
      );
      if (startIndex === -1) return;

      if (!isFullWordbook(wordBook)) return;

      const mlModelName =
        state.data.pages[startIndex].cells?.[0]?.mlModelName ?? modelName;

      const item = info[0];

      state.data.pages[startIndex].cells.push(
        ...createCellsByExtractFile(item.cells, wordBook, mlModelName)
      );
    })
    .addCase(fetchProjectById.pending, (state) => {
      if (state.network === 'idle') state.network = 'load';
      state.loadingScreen = true;
    })
    .addCase(fetchProjectById.fulfilled, (state, { payload }) => {
      if (state.network === 'load') state.network = 'idle';
      state.loadingScreen = false;

      const { pages, music, cc, watermarkType, ...rest } = payload;

      if (pages.length > 0) {
        state.ui.selectedPageKey = pages[0].key;
      }

      if (music) state.ui.audioToolState = music;

      if (cc) {
        state.ui.closedCaptionState = {
          ...state.ui.closedCaptionState,
          ...cc,
        };
      }

      if (watermarkType) {
        state.ui.watermarkType = payload.watermarkType;
      }

      state.data = {
        ...rest,
        pages,
        pageLimit: state.data.pageLimit,
        cellLimit: state.data.cellLimit,
      };
    })
    .addCase(fetchProjectById.rejected, (state) => {
      if (state.network === 'load') state.network = 'idle';
      state.loadingScreen = false;
    })
    .addCase(fetchTemplateById.pending, (state) => {
      if (state.network === 'idle') state.network = 'load';
      state.loadingScreen = true;
    })
    .addCase(fetchTemplateById.fulfilled, (state, { payload }) => {
      if (state.network === 'load') state.network = 'idle';
      state.loadingScreen = false;

      if (payload.pages.length > 0) {
        state.ui.selectedPageKey = payload.pages[0].key;
      }

      if (payload.music) state.ui.audioToolState = payload.music;

      if (payload.cc) {
        state.ui.closedCaptionState = {
          ...state.ui.closedCaptionState,
          ...payload.cc,
        };
      }

      delete payload.updateDate;
      delete payload.uuid;
      state.data = {
        ...payload,
        pageLimit: state.data.pageLimit,
        cellLimit: state.data.cellLimit,
      };
    })
    .addCase(fetchTemplateById.rejected, (state) => {
      if (state.network === 'load') state.network = 'idle';
      state.loadingScreen = false;
    })
    .addCase(fetchUpdateProject.fulfilled, (state, { payload }) => {
      state.network = 'idle';

      if (state.data.uuid !== payload.uuid) return;

      if (!isUpdateAfterModified(state.data, payload)) {
        state.data = setDataStateFromUpdated(state.data, payload);
        return;
      }

      state.data._modified = true;
      state.data._origin = payload._origin;
      state.data.name = payload.name;
      state.data.uuid = payload.uuid;
      state.data.updateDate = payload.updateDate;

      state.data.pages = setPageEntitiesFromUpdated(
        state.data.pages,
        payload.pages
      );
    })
    .addCase(fetchUpdateProject.rejected, (state) => {
      state.network = 'idle';
    })
    .addCase(fetchCreateProject.fulfilled, (state, { payload }) => {
      state.network = 'idle';

      if (!isUpdateAfterModified(state.data, payload)) {
        state.data = setDataStateFromUpdated(state.data, payload);
        return;
      }

      state.data._modified = true;
      state.data._origin = payload._origin;
      state.data.name = payload.name;
      state.data.uuid = payload.uuid;
      state.data.updateDate = payload.updateDate;

      state.data.pages = setPageEntitiesFromUpdated(
        state.data.pages,
        payload.pages
      );
    })
    .addCase(fetchCreateProject.rejected, (state) => {
      state.network = 'idle';
    })
    .addCase(createTimelineCell, (state, { payload }) => {
      const { selectedPageKey: pageKey } = state.ui;

      const page = state.data.pages.find((page) => page.key === pageKey);
      if (page === undefined) return;

      const { cells } = page;

      if ((state.data.cellLimit ?? Number.MAX_SAFE_INTEGER) <= cells.length) {
        state.toast.cellLimit = true;
        return;
      }

      const { startTime, audioDuration } = payload;
      const endTime = startTime + audioDuration;

      const { maxTime, editableCell } = state.timeline;

      const enableTimelineSection = getEnableTimelineSection({
        cells,
        maxTime,
      });

      let sectionIndex = enableTimelineSection.findIndex((enable) => {
        return enable.start <= startTime && enable.end >= endTime;
      });

      if (sectionIndex === -1) {
        if (maxTime < endTime) {
          state.toast.timelineOverflow = true;
          return;
        }
        sectionIndex = enableTimelineSection.findIndex(
          (enable) => enable.start <= startTime && enable.end >= startTime
        );
      }

      if (sectionIndex === -1) return;

      const section: TimelineSection = enableTimelineSection[sectionIndex];

      const isTail = enableTimelineSection.length === sectionIndex + 1;
      const isPush = endTime - section.end >= 0;

      const prevCell = {
        ...page.cells[section.cellIndex],
        duration: startTime - section.start,
      };

      let cellDuration = 0;
      if (!isTail) {
        cellDuration = isPush ? section.end - startTime : section.end - endTime;
      }

      const newCell: CellEntity = {
        key: nanoid(),
        text: editableCell.text,
        displayText: editableCell.displayText,
        mlModelName: editableCell.modelName,
        speed: editableCell.speed,
        volume: editableCell.volume,
        duration: cellDuration,
        startTime,
        audioDuration,
      };

      let nextCells: CellEntity[] = [
        ...page.cells.slice(section.cellIndex + 1),
      ];

      if (isPush) {
        if (newCell.audioDuration === undefined) return;

        const pushDuration = newCell.audioDuration + newCell.duration;

        nextCells = nextCells.reduce<CellEntity[]>((acc, item) => {
          if (item.startTime === undefined) return acc;
          if (item.audioDuration === undefined) return acc;
          if (item.startTime + item.audioDuration <= maxTime) {
            acc.push({
              ...item,
              startTime: item.startTime + pushDuration,
            });
            return acc;
          }
          return acc;
        }, []);
      }

      page.cells = [
        ...page.cells.slice(0, section.cellIndex),
        prevCell,
        newCell,
        ...nextCells,
      ];
    })
    .addCase(createTimelinePaddingCell, (state, { payload }) => {
      const currentPageKey = state.ui.selectedPageKey;
      const currentPage = state.data.pages.find(
        (el) => el.key === currentPageKey
      );
      if (currentPage === undefined) return;

      currentPage.cells = [
        {
          key: nanoid(),
          text: '',
          displayText: '',
          duration: 0,
          mlModelName: payload.name,
          speed: VOICE_DEFAULT_SPEED,
          volume: VOICE_DEFAULT_VOLUME,
        },
        ...currentPage.cells,
      ];
    })
    .addCase(editTimelineCell, (state, { payload }) => {
      const { selectedPageKey: pageKey, selectedCellKey: cellKey } = state.ui;

      if (cellKey === undefined) return undefined;

      const page = state.data.pages.find((page) => page.key === pageKey);
      if (page === undefined) return;

      const { startTime, audioDuration } = payload;

      const endTime = startTime + audioDuration;

      const { maxTime, editableCell } = state.timeline;

      const enableTimelineSection = getEnableTimelineSection({
        cells: page.cells,
        maxTime,
        ignoreKey: cellKey,
      });

      let sectionIndex = enableTimelineSection.findIndex((enable) => {
        return enable.start <= startTime && enable.end >= endTime;
      });

      if (sectionIndex === -1) {
        if (maxTime < endTime) {
          state.toast.timelineOverflow = true;
          return;
        }

        sectionIndex = enableTimelineSection.findIndex(
          (enable) => enable.start <= startTime && enable.end >= startTime
        );
      }

      if (sectionIndex === -1) return;

      const section: TimelineSection = enableTimelineSection[sectionIndex];

      const isTail = enableTimelineSection.length === sectionIndex + 1;
      const isPush = endTime - section.end >= 0;

      const editCell: CellEntity = page.cells[section.cellIndex + 1];

      const cellDuration = isTail ? 0 : editCell.duration;

      const newCell: CellEntity = {
        key: cellKey,
        text: editableCell.text,
        displayText: editableCell.displayText,
        mlModelName: editableCell.modelName,
        speed: editableCell.speed,
        volume: editableCell.volume,
        duration: cellDuration,
        startTime,
        audioDuration,
      };

      let nextCells: CellEntity[] = [
        ...page.cells.slice(section.cellIndex + 2),
      ];

      if (isPush) {
        if (newCell.audioDuration === undefined) return;
        if (editCell.audioDuration === undefined) return;

        const pushDuration = newCell.audioDuration - editCell.audioDuration;

        nextCells = nextCells.reduce<CellEntity[]>((acc, item) => {
          if (item.startTime === undefined) return acc;
          if (item.audioDuration === undefined) return acc;
          if (item.startTime + item.audioDuration <= maxTime) {
            acc.push({
              ...item,
              startTime: item.startTime + pushDuration,
            });
            return acc;
          }
          return acc;
        }, []);
      }

      page.cells = [
        ...page.cells.slice(0, section.cellIndex + 1),
        newCell,
        ...nextCells,
      ];
    })
    .addCase(newMoveTimelineCell, (state, { payload }) => {
      // redux state check
      const { selectedPageKey: pageKey, selectedCellKey: cellKey } = state.ui;
      if (cellKey === undefined) return undefined;

      const page = state.data.pages.find((page) => page.key === pageKey);
      if (page === undefined) return;

      const cellIndex = page.cells.findIndex((el) => el.key === cellKey);
      if (cellIndex === -1) return;

      const currentCell = page.cells[cellIndex];

      const audioDuration = currentCell.audioDuration;
      if (audioDuration === undefined) return;

      // payload check
      const { maxTime } = state.timeline;
      if (payload.to < 0) payload.to = 0;
      if (payload.to + audioDuration > maxTime)
        payload.to = maxTime - audioDuration;

      const { from, to } = payload;
      const enableTimelineSection = getEnableTimelineSection({
        cells: page.cells,
        maxTime,
        ignoreKey: cellKey,
      });

      // for moveStartPrevCell
      const startSectionIndex = enableTimelineSection.findIndex((enable) => {
        return enable.start <= from && enable.end >= from + audioDuration;
      });
      if (startSectionIndex === -1) return;
      const startSection: TimelineSection =
        enableTimelineSection[startSectionIndex];
      const startSectionCell = page.cells[startSection.cellIndex];
      if (startSectionCell === undefined) return;

      // for moveEndPrevCell
      const endSectionIndex = enableTimelineSection.findIndex(
        (enable) => enable.start <= to && enable.end >= to + audioDuration
      );
      if (endSectionIndex === -1) {
        state.toast.timelineDuplicate = true;
        return;
      }
      const endSection: TimelineSection =
        enableTimelineSection[endSectionIndex];
      const endSectionCell = page.cells[endSection.cellIndex];
      if (endSectionCell === undefined) return;

      // Finally
      const isTail = (sectionIndex: number) => {
        const isEndEqualMax =
          enableTimelineSection[sectionIndex].end === maxTime;
        const isLastSection = enableTimelineSection.length === sectionIndex + 1;
        return isEndEqualMax && isLastSection;
      };

      // moveStartPrevCell
      if (startSection.cellIndex !== endSection.cellIndex) {
        page.cells[startSection.cellIndex] = {
          ...startSectionCell,
          duration: isTail(startSectionIndex)
            ? 0
            : startSection.end - startSection.start,
        };
      }

      //  moveEndPrevCell
      page.cells[endSection.cellIndex] = {
        ...endSectionCell,
        duration: to - endSection.start,
      };

      // movedCell
      page.cells.splice(startSection.cellIndex + 1, 1);

      const movedIndex =
        startSection.cellIndex >= endSection.cellIndex
          ? endSection.cellIndex + 1
          : endSection.cellIndex;

      const movedCell: CellEntity = {
        ...currentCell,
        duration: isTail(endSectionIndex)
          ? 0
          : endSection.end - (to + audioDuration),
        startTime: to,
      };

      page.cells = [
        ...page.cells.slice(0, movedIndex),
        movedCell,
        ...page.cells.slice(movedIndex),
      ];

      state.timeline.currentTime = to;
    })
    .addCase(removeTimelineCell, (state, { payload }) => {
      const { key, length } = payload;
      const { page, cell, cellIndex } = state.data.pages.reduce<{
        page?: Draft<PageEntity>;
        cell?: Draft<CellEntity>;
        cellIndex?: number;
      }>((acc, item) => {
        const cellIdx = item.cells.findIndex((el) => el.key === key);
        if (cellIdx === -1) return acc;

        acc.page = item;
        acc.cell = item.cells[cellIdx];
        acc.cellIndex = cellIdx;
        return acc;
      }, {});

      if (page === undefined) return;
      if (cell === undefined) return;
      if (cellIndex === undefined) return;

      if (state.ui.selectedCellKey === key) {
        state.ui.selectedCellKey = undefined;
      }

      page.cells[cellIndex - 1].duration =
        page.cells[cellIndex - 1].duration + length;
      page.cells = page.cells.filter((el) => el.key !== key);

      if (page.cells.length > 1) {
        page.cells[page.cells.length - 1].duration = 0;
      }
    })
    .addCase(moveTimelineCell, (state, { payload }) => {
      // redux state check
      const { selectedPageKey: pageKey } = state.ui;
      const { maxTime } = state.timeline;

      const { cellKey, from, to } = payload;
      if (cellKey === undefined) return undefined;

      const page = state.data.pages.find((page) => page.key === pageKey);
      if (page === undefined) return;

      const cellIndex = page.cells.findIndex((el) => el.key === cellKey);
      if (cellIndex === -1) return;

      const currentCell = page.cells[cellIndex];

      const audioDuration = currentCell.audioDuration;
      if (audioDuration === undefined) return;

      const enableTimelineSection = getEnableTimelineSection({
        cells: page.cells,
        maxTime,
        ignoreKey: cellKey,
      });

      // for moveStartPrevCell
      const startSectionIndex = enableTimelineSection.findIndex((enable) => {
        return enable.start <= from && enable.end >= from + audioDuration;
      });
      if (startSectionIndex === -1) return;
      const startSection: TimelineSection =
        enableTimelineSection[startSectionIndex];
      const startSectionCell = page.cells[startSection.cellIndex];
      if (startSectionCell === undefined) return;

      // for moveEndPrevCell
      const endSectionIndex = enableTimelineSection.findIndex(
        (enable) => enable.start <= to && enable.end >= to + audioDuration
      );
      if (endSectionIndex === -1) {
        state.toast.timelineDuplicate = true;
        return;
      }
      const endSection: TimelineSection =
        enableTimelineSection[endSectionIndex];
      const endSectionCell = page.cells[endSection.cellIndex];
      if (endSectionCell === undefined) return;

      // Finally
      const isTail = (sectionIndex: number) => {
        const isEndEqualMax =
          enableTimelineSection[sectionIndex].end === maxTime;
        const isLastSection = enableTimelineSection.length === sectionIndex + 1;
        return isEndEqualMax && isLastSection;
      };

      // moveStartPrevCell
      if (startSection.cellIndex !== endSection.cellIndex) {
        page.cells[startSection.cellIndex] = {
          ...startSectionCell,
          duration: isTail(startSectionIndex)
            ? 0
            : startSection.end - startSection.start,
        };
      }

      //  moveEndPrevCell
      page.cells[endSection.cellIndex] = {
        ...endSectionCell,
        duration: to - endSection.start,
      };

      // movedCell
      page.cells.splice(startSection.cellIndex + 1, 1);

      const movedIndex =
        startSection.cellIndex >= endSection.cellIndex
          ? endSection.cellIndex + 1
          : endSection.cellIndex;

      const movedCell: CellEntity = {
        ...currentCell,
        duration: isTail(endSectionIndex)
          ? 0
          : endSection.end - (to + audioDuration),
        startTime: to,
      };

      page.cells = [
        ...page.cells.slice(0, movedIndex),
        movedCell,
        ...page.cells.slice(movedIndex),
      ];
    })
    .addCase(setTimelineIsLoading, (state, { payload }) => {
      state.timeline.isLoading = payload.isLoading;
    })
    .addCase(setTimelineChange, (state, { payload }) => {
      state.timeline.isChanged = payload.isChanged;
    })
    .addCase(removePageStv, (state, { payload }) => {
      const { pageKey } = payload;

      const page = state.data.pages.find((el) => el.key === pageKey);
      if (page === undefined) return;
      page.stv = undefined;
    })
    .addCase(setTimelineRulerWidth, (state, { payload }) => {
      state.timeline.rulerWidth = payload;
    })
    .addCase(setTimelineIndicatorPosition, (state, { payload }) => {
      state.timeline.indicatorPosition = payload;
    })
    .addCase(setTimelineMaxTime, (state, { payload }) => {
      state.timeline.maxTime = payload;
    })
    .addCase(setTimelineCurrentTime, (state, { payload }) => {
      state.timeline.currentTime = payload;
    })
    .addCase(setTimelineMultiplierIndex, (state, { payload }) => {
      state.timeline.multiplierIndex = payload;
    })
    .addCase(modifyTimelineEditableCell, (state, { payload }) => {
      const { editableCell } = state.timeline;

      state.timeline.editableCell = {
        ...editableCell,
        ...payload,
      };
    })
    .addCase(setTimelineDuplicateToast, (state, { payload }) => {
      state.toast.timelineDuplicate = payload;
    })
    .addCase(setTimelineOverflowToast, (state, { payload }) => {
      state.toast.timelineOverflow = payload;
    })
    .addCase(setPreviewVideoErrorToast, (state, { payload }) => {
      state.toast.previewVideoError = payload;
    })
    .addCase(deleteVideoAsset, (state, { payload }) => {
      const { video } = payload;

      const pages = state.data.pages.map((page) => {
        const { videoPath } = page;

        if (videoPath === undefined) return page;

        if (video.videoS3Path.path.indexOf(videoPath) !== -1) {
          const isVideoProject =
            page.videoUrl !== undefined && page.videoPath !== undefined;

          page.imageUrl = undefined;
          page.imagePath = undefined;
          page.thumbnailUrl = undefined;
          page.thumbnailPath = undefined;
          page.videoUrl = undefined;
          page.videoPath = undefined;
          page.isTempImage = false;

          if (isVideoProject) page.cells.shift();

          return page;
        }

        return page;
      });

      state.data.pages = pages;
    })
    .addMatcher(
      isAnyOf(
        setProjectName,
        modifyCellData,
        createCell,
        removeCell,
        createLastPage,
        modifyManyMlModel,
        modifyManyDuration,
        modifyManyVoiceOption,
        clearPage,
        modifyPageData,
        modifyManyPageData,
        copyPage,
        modifyCurrentSTVLocation,
        splitCell,
        mergeCell,
        removePage,
        createNewCell,
        overrideExtractFileSlide,
        appendExtractFileSlide,
        overrideImageFileSlide,
        appendImageFileSlide,
        overrideExtractFileText,
        appendExtractFileText,
        createTimelineCell,
        createTimelinePaddingCell,
        editTimelineCell,
        newMoveTimelineCell,
        removeTimelineCell,
        moveTimelineCell
      ),
      (state) => {
        if (!state.data._modified) state.data._modified = true;
      }
    )
    .addMatcher(
      isAnyOf(modifyPageData, modifyManyPageData),
      (state, { payload }) => {
        if (payload.stv === undefined) return;

        const { audioModelName } = payload.stv;

        if (payload.hasOwnProperty('pageKey')) {
          const page = state.data.pages.find(
            (el) => el.key === payload['pageKey']
          );
          if (page === undefined) return;

          page.cells.forEach((cell) => {
            cell.mlModelName = audioModelName;
          });
          return;
        }

        if (payload.hasOwnProperty('pageKeys')) {
          state.data.pages.forEach((page) => {
            if (payload['pageKeys'].includes(page.key)) {
              page.cells.forEach((cell) => {
                cell.mlModelName = audioModelName;
              });
            }
          });
          return;
        }

        state.data.pages.forEach((page) => {
          page.cells.forEach((cell) => {
            cell.mlModelName = audioModelName;
          });
        });
      }
    )
    .addMatcher(isAnyOf(modifyCellData), (state, { payload }) => {
      const page = state.data.pages.find((el) => el.key === payload.pageKey);
      if (page === undefined) return;
      if (page.stv === undefined) return;

      const { audioModelName } = page.stv;
      const { mlModelName } = payload;
      if (mlModelName === undefined) return;

      if (audioModelName !== mlModelName) {
        page.cells.forEach((cell) => {
          cell.mlModelName = mlModelName;
        });
        page.stv = undefined;
      }
    });
});

export const editorReducer = undoable(reducers, {
  undoAction: undo,
  redoAction: redo,
  clearAction: clearHistory,
  blacklistActions: [
    fetchProjectById.pending,
    fetchProjectById.fulfilled,
    fetchProjectById.rejected,
    fetchTemplateById.pending,
    fetchTemplateById.fulfilled,
    fetchTemplateById.rejected,
    fetchUpdateProject.pending,
    fetchUpdateProject.fulfilled,
    fetchUpdateProject.rejected,
    fetchCreateProject.pending,
    fetchCreateProject.fulfilled,
    fetchCreateProject.rejected,
    fetchSubtitleSRT.pending,
    fetchSubtitleSRT.fulfilled,
    fetchSubtitleSRT.rejected,
    fetchSubtitleTXT.pending,
    fetchSubtitleTXT.fulfilled,
    fetchSubtitleTXT.rejected,
    fetchAudio.pending,
    fetchAudio.fulfilled,
    fetchAudio.rejected,
    fetchVideo.pending,
    fetchVideo.fulfilled,
    fetchVideo.rejected,
    fetchTimelineCells.pending,
    fetchTimelineCells.fulfilled,
    fetchTimelineCells.rejected,
    setNetworkState,
    setInputSelection,
    setChannelId,
    setVideoHash,
    setPageLimit,
    setOpenPageLimitToast,
    setCellLimit,
    setOpenCellLimitToast,
    setInitializeProjectName,
    setInitializeCellData,
    setInitializeTimelineCells,
    setVideoPagePaddingCell,
    createTimelinePaddingCell,
    setTimelineIsLoading,
  ],
}) as EditorReducer;

export type EditorState = StateOfHistory<ReturnType<typeof reducers>>;

export type EditorReducer = Reducer<EditorState, AnyAction>;
