import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useRegisterEmailMutation } from 'app/services/auth';
import { emailValidator } from 'lib/utils';
import { typography } from 'styles';
import PinkButton from './PinkButton';
import AuthTextField from './AuthTextField';
import { isFetchBaseQueryError } from './utils';

interface RequestRegisterEmailErrorData {
  message: string;
  details?: any;
}
const isErrorData = (data: any): data is RequestRegisterEmailErrorData => {
  if (data === undefined) return false;
  if (typeof data.message !== 'string') return false;
  return true;
};

const RegisterVerification: React.FC = () => {
  const { t } = useTranslation('auth', { keyPrefix: 'emailRegister' });

  const navigate = useNavigate();

  const [email, setEmail] = React.useState<string>('');

  const [isError, setIsError] = React.useState<boolean>(false);
  const [helperText, setHelperText] = React.useState<string>('');

  const [requestRegisterEmail, { isSuccess, isLoading, error }] =
    useRegisterEmailMutation();

  const handleSubmitSendEmail: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    if (isLoading) return;
    if (email === undefined) return;

    if (emailValidator(email)) {
      setIsError(true);
      setHelperText(t('error.invalidate'));
      return;
    }

    try {
      await requestRegisterEmail({ email }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (email === undefined) return;
    if (isSuccess) {
      navigate('/auth/register/verification/success', {
        state: { email: email },
      });
    }
  }, [email, isSuccess, navigate]);

  React.useEffect(() => {
    if (error === undefined) return;
    if (!isFetchBaseQueryError(error)) return;

    const { status } = error;

    if (status === 400) {
      if (!isErrorData(error.data)) return;
      const { message } = error.data;

      if (message === 'that email is already occupied!') {
        setHelperText(t('error.duplicate'));
      } else {
        setHelperText(message);
      }
      setIsError(true);
    }
  }, [error, t]);

  const handleChangeAuthTextField: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    const el = e.target instanceof HTMLInputElement ? e.target : undefined;
    if (el === undefined) return;
    setEmail(e.target.value);
  };

  return (
    <Base>
      <Title>{t('title')}</Title>
      <Subtitle>{t('subtitle')}</Subtitle>
      <Form onSubmit={handleSubmitSendEmail}>
        <AuthTextField
          label={t('placeholder.email')}
          name={'email'}
          value={email}
          onChange={handleChangeAuthTextField}
          error={isError}
          helperText={helperText}
          required
        />
        <PinkButton type={'submit'} loading={isLoading}>
          {t('button.next')}
        </PinkButton>
      </Form>
    </Base>
  );
};

export default RegisterVerification;

const Base = styled.div`
  width: 100%;
`;

const Title = styled.p`
  ${typography.display.small}
  text-align: center;
  margin: 0;
`;

const Subtitle = styled.span`
  width: 100%;
  display: inline-block;
  ${typography.body.large}
  margin: 0;
  padding-top: 16px;
  padding-bottom: 32px;
  text-align: center;
  white-space: pre;
`;

const Form = styled.form`
  width: 100%;
  min-height: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    margin-bottom: 12px;
  }
`;
