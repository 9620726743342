import * as React from 'react';
import {
  useBeforeCheckPadding,
  useBeforeCheckVideo,
  useInitializeTimelineCells,
} from './hooks';
import Title from './Title';
import Timeline from './Timeline';
import DuplicateErrorToast from './DuplicateErrorToast';

const PageTimeline: React.FC = () => {
  const isCheckedPadding = useBeforeCheckPadding();
  const videoSrc = useBeforeCheckVideo();
  const isInitialized = useInitializeTimelineCells(isCheckedPadding, videoSrc);

  return (
    <>
      <Title />
      <Timeline isInitialized={isInitialized} />
      <DuplicateErrorToast />
    </>
  );
};

export default PageTimeline;
