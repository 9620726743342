import * as React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { useMatchMedia } from 'lib/hooks';
import Header from './Header';
import Navigation from './Navigation';

interface ClientTemplateProps {
  children?: React.ReactNode;
}

const ClientTemplate: React.FC<ClientTemplateProps> = ({ children }) => {
  const match = useMatchMedia('(min-width: 1024px)');

  return (
    <>
      <Global styles={globalStyles} />
      <Header />
      <Main>
        {match && <Navigation />}
        <Contents>{children}</Contents>
      </Main>
    </>
  );
};

export default ClientTemplate;

const globalStyles = css`
  html,
  body,
  #root {
    height: 100%;
  }
`;

const Main = styled.main`
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  margin-top: 64px;
  overflow-y: auto;

  @media (min-width: 1024px) {
    margin-top: 0px;
    padding: 32px 24px;
  }
`;
