import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Toast } from 'components/common';
import { typography } from 'styles';
import StepCardBox from './StepCardBox';
import GoRegisterButton from './GoRegisterButton';

const StepSection: React.FC = () => {
  const { t } = useTranslation('main', { keyPrefix: 'steps' });

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  return (
    <Base>
      <Box>
        <TextBox>{t('title')}</TextBox>
        <StepCardBox />
        <RegisterBox>
          <GoRegisterButton
            onClick={(e) => {
            }}
          >
            {t('register')}
          </GoRegisterButton>
          <Toast
            in={openToast}
            onClose={toggleOpenToast}
            timeout={1500}
            message={'Coming soon'}
            severity={'normal'}
          />
        </RegisterBox>
      </Box>
    </Base>
  );
};

export default StepSection;

const Base = styled.section`
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  background: var(--color-grey-50);

  @media (min-width: 820px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;

const Box = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1152px) {
    width: 1120px;
  }
`;

const TextBox = styled.span`
  margin: 0;
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: pre;
  ${typography.title.large}

  @media (min-width: 820px) {
    ${typography.display.medium}
  }
`;

const RegisterBox = styled.div`
  display: none;

  @media (min-width: 820px) {
    display: block;
  }
`;
