import { recordTransformer, camelCase } from 'lib/utils';
import {
  VOICE_DEFAULT_SPEED,
  VOICE_DEFAULT_VOLUME,
} from 'features/editor/constants';

interface FetchParameters {
  channelId: string;
  eventId: string;
  name: string;
  text: string;
  volume?: number;
  speed?: number;
  token: string;
  signal?: AbortSignal;
}

interface FetchResponse {
  channelId: string;
  eventId: string;
  downloadUrls: {
    text: string;
    downloadUrl: string;
  }[];
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/ml-models`;

export default async function fetchMergeMlModel({
  channelId,
  eventId,
  name,
  text,
  volume = VOICE_DEFAULT_VOLUME,
  speed = VOICE_DEFAULT_SPEED,
  token,
  signal,
}: FetchParameters) {
  const response = await fetch(`${URL}/${name}/audio-pusher`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      channel_id: channelId,
      event_id: eventId,
      text_data: [{ text, volume, speed }],
    }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(JSON.stringify(data));
  }

  return recordTransformer(data, camelCase) as FetchResponse;
}
