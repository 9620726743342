import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { selectCurrentPagePreview } from 'features/editor/editorSlice';
import { throttle } from 'lib/utils';
import Toolbar from './Toolbar';
import PageList from './PageList.Mobile';
import PageCardList from './PageCardList.Mobile';
import PageTimeline from './PageTimeline';

const Content: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  const previewContent = useAppSelector(selectCurrentPagePreview);

  const [springHeight, setSpringHeight] = React.useState<number>(0);

  React.useLayoutEffect(() => {
    const listener = throttle(() => {
      const contentHeight = (window.innerHeight - 64) / 3;

      setSpringHeight(contentHeight);
    });

    listener(undefined);

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const handleMixpanelEvent = () => {
    toggleIsOpen();
  };

  return (
    <>
      <PreviewBox style={{ height: isOpen ? springHeight : 0 }}>
        <PageCardList />
      </PreviewBox>
      <Toolbar
        isOpen={isOpen}
        // onClick={() => toggleIsOpen()}
        onClick={handleMixpanelEvent}
      />
      {previewContent.type === 'video' ? <PageTimeline /> : <PageList />}
    </>
  );
};

export default Content;

const PreviewBox = styled.div`
  background: var(--color-grey-100);
  width: 100%;
  overflow: hidden;
  transition: height 400ms ease-in-out;
`;
