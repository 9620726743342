import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { removeAuthPersistStore } from 'app/authPersistEnhance';
import authService from 'app/services/auth';
import projectsService from 'app/services/projects';
import membershipService from 'app/services/membership';
import musicAssetsService from 'app/services/musicAssets';
import userInfoService from 'app/services/userInfo';
import videoModelService from 'app/services/videoModel';
import voiceModelService from 'app/services/voiceModel';
import watermarkService from 'app/services/watermark';
import { useAppDispatch } from 'app/hooks';
import { SignOutIcon } from 'assets/svg';
import { clearCredentials } from 'features/auth/authSlice';
import { typography } from 'styles';

interface SignOutProps {
  children?: React.ReactNode;
}

const SignOut: React.FC<SignOutProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [requestSignOut] = authService.useSignOutMutation();

  const handleClick = async () => {
    navigate('/auth/login', { replace: true });
    await requestSignOut().unwrap();
    removeAuthPersistStore();
    dispatch(clearCredentials());
    dispatch(authService.util.resetApiState());
    dispatch(projectsService.util.resetApiState());
    dispatch(membershipService.util.resetApiState());
    dispatch(musicAssetsService.util.resetApiState());
    dispatch(userInfoService.util.resetApiState());
    dispatch(videoModelService.util.resetApiState());
    dispatch(voiceModelService.util.resetApiState());
    dispatch(watermarkService.util.resetApiState());
  };

  return (
    <Button onClick={handleClick}>
      <SignOutIcon size={20} weight={'bold'} fill={'currentColor'} />
      <span>{children}</span>
    </Button>
  );
};

export default SignOut;

const Button = styled.button`
  --hover-gradient: linear-gradient(
    0deg,
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    0deg,
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  padding: 6px 8px;
  border-radius: 10px;
  outline: none;
  border: none;
  background: var(--color-white);
  color: var(--color-grey-700);
  ${typography.label.large}
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  span {
    margin-left: 8px;
  }

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
