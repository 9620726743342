import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { selectPageCards } from 'features/editor/editorSlice';
import { useToolbarCurrentState } from 'features/editor/providers';
import ProjectNameInput from './ProjectNameInput';
import PageCard from './PageCard';
import NewPageCard from './NewPageCard';

const PageCardList: React.FC = () => {
  const pageCards = useAppSelector(selectPageCards);

  const toolbarOpen = useToolbarCurrentState();

  return (
    <Base style={{ overflow: toolbarOpen ? 'hidden' : 'auto' }}>
      <ProjectNameInput />
      {pageCards.map((page) => (
        <PageCard
          pageKey={page.key}
          num={page.num + 1}
          thumbnailUrl={page.thumbnailUrl}
          key={page.key}
        />
      ))}
      <NewPageCard />
    </Base>
  );
};

export default PageCardList;

const Base = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px 24px;
`;
