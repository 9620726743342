import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { XIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import {
  selectCurrentToolbarItem,
  setToolbarItem,
} from 'features/editor/editorSlice';
import { ModalV2 } from 'components/common';
import CharacterItem from './CharacterItem';
import UploadItem from './UploadItem';
import VideoItem from './VideoItem';
import AudioItem from './AudioItem';
import ClosedCaptionItem from './ClosedCaptionItem';
import EditorToolbarItems from './EditorToolbarItems';

interface EditorToolbarModalProps {
  open: boolean;
  onClose: () => void;
}

const EditorToolbarModal: React.FC<EditorToolbarModalProps> = ({
  open,
  onClose,
}) => {
  const currentToolbarItem = useAppSelector(selectCurrentToolbarItem);

  const dispatch = useAppDispatch();

  const handleClickToolbarItem = (type) => {
    if (currentToolbarItem === type) return;
    dispatch(setToolbarItem(type));
  };

  return (
    <ModalV2 open={open}>
      <Base>
        <ToolDrawer>
          {currentToolbarItem === 'character' && <CharacterItem />}
          {currentToolbarItem === 'upload' && <UploadItem />}
          {currentToolbarItem === 'video' && <VideoItem />}
          {currentToolbarItem === 'audio' && <AudioItem />}
          {currentToolbarItem === 'cc' && <ClosedCaptionItem />}
        </ToolDrawer>
        <Toolbar>
          <CloseBox>
            <CloseButton
              size={32}
              radius={6}
              onClick={() => {
                onClose();
                handleClickToolbarItem('none');
              }}
            >
              <XIcon size={32} weight={'bold'} color={'var(--color-black)'} />
            </CloseButton>
          </CloseBox>
          <EditorToolbarItems />
        </Toolbar>
      </Base>
    </ModalV2>
  );
};

export default EditorToolbarModal;

const Base = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  background: var(--color-white);
  display: flex;
`;

const ToolDrawer = styled.div`
  width: 401px;
  height: 100%;
  border-left: 1px solid var(--color-grey-100);
  background: var(--color-white);
`;

const Toolbar = styled.div`
  width: 72px;
  height: 100%;
  border-right: 1px solid var(--color-grey-100);
  background: var(--color-grey-50);
`;

const CloseBox = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled(IconButton)`
  background: var(--color-grey-50);
`;
