import * as React from 'react';
import styled from '@emotion/styled';
import { Navigation } from 'components/editor';
import { EditorContent, EditorToolbar } from 'features/editor';
import CommonToasts from './CommonToasts';

const EditMain: React.FC = () => {
  return (
    <Main>
      <Navigation />
      <EditorContent />
      <EditorToolbar />
      <CommonToasts />
    </Main>
  );
};

export default EditMain;

const Main = styled.main`
  width: 100%;
  min-width: 1024px;
  height: calc(100vh - 64px);
  display: flex;
`;
