import * as React from 'react';
import styled from '@emotion/styled';
import GoBackButton from './GoBackButton';
import FullListenPlayer from './FullListenPlayer.Tablet';
import EditHistory from './EditHistory';
import SaveButton from './SaveButton';
import DownloadButton from './DownloadButton';

const EditorHeader: React.FC = () => {
  return (
    <Header>
      <GoBackButton />
      <FullListenPlayer />
      <EditHistory />
      <ButtonBox>
        <SaveButton />
        <DownloadButton />
      </ButtonBox>
    </Header>
  );
};

export default EditorHeader;

const Header = styled.header`
  min-width: 1024px;
  height: 64px;
  padding: 12px 20px;
  background: var(--color-white);
  border-bottom: 1px solid var(--color-grey-100);
  display: flex;
  align-items: center;
  position: relative;
  z-index: var(--header-index);
`;

const ButtonBox = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
