import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ClickAwayListener from 'react-click-away-listener';
import styled from '@emotion/styled';
import { useMatchMedia } from 'lib/hooks';
import projectsService from 'app/services/projects';
import userInfoService from 'app/services/userInfo';
import { useAppDispatch } from 'app/hooks';
import {
  FolderIcon,
  CreditCardIcon,
  MegaphoneIcon,
  BookIcon,
  QuestionIcon,
  UserCircleIcon,
  CircleWavyQuestionIcon,
  ChatCircleIcon,
} from 'assets/svg';
import { typography } from 'styles';

const NOTICE_URL = 'https://onairstudio.zendesk.com/hc/ko';
const USAGE_URL =
  'https://lionrocket0813.notion.site/9a179bd749384c2899cb538245b9278d';
const FAQ_URL =
  'https://onairstudio.zendesk.com/hc/ko/categories/4411545995673-%EC%9E%90%EC%A3%BC-%EB%AC%BB%EB%8A%94-%EC%A7%88%EB%AC%B8';

const Navigation: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'common' });

  const dispatch = useAppDispatch();

  const match = useMatchMedia('(min-width: 1024px)');

  const [openQuestion, setOpenQuestion] = React.useState<boolean>(false);
  const toggleOpenQuestion = () => setOpenQuestion((prev) => !prev);

  if (!match) return null;
  return (
    <Base>
      <RouterAnchorBox>
        <RouterAnchor
          to={'/client'}
          onMouseOver={() => {
            dispatch(
              projectsService.util.prefetch('getProjects', undefined, {
                ifOlderThan: 60,
              })
            );
          }}
        >
          <FolderIcon size={24} weight={'fill'} />
          <span>{t('myProject')}</span>
        </RouterAnchor>
        {process.env.ONAIR_PLATFORM_TYPE === 'onair' && (
          <RouterAnchor to={'/client/membership'}>
            <CreditCardIcon size={24} weight={'fill'} />
            <span>{t('membership.title')}</span>
          </RouterAnchor>
        )}
      </RouterAnchorBox>
      <OuterLinkBox>
        <Anchor href={NOTICE_URL} target={'_blank'} rel={'noreferrer'}>
          <MegaphoneIcon size={24} weight={'bold'} />
          <span>{t('notice')}</span>
        </Anchor>
        <Anchor href={USAGE_URL} target={'_blank'} rel={'noreferrer'}>
          <BookIcon size={24} weight={'bold'} />
          <span>{t('usage')}</span>
        </Anchor>
        <Question onClick={() => toggleOpenQuestion()}>
          <QuestionIcon size={24} weight={'bold'} />
          <span>{t('question')}</span>
        </Question>
        {openQuestion && (
          <ClickAwayListener onClickAway={toggleOpenQuestion}>
            <QuestionDropdown onClick={toggleOpenQuestion}>
              <QuestionDropdownAnchor
                href={FAQ_URL}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <CircleWavyQuestionIcon size={20} weight={'bold'} />
                <span>{t('faq')}</span>
              </QuestionDropdownAnchor>
              {process.env.ONAIR_PLATFORM_TYPE === 'onair' && (
                <QuestionDropdownItem>
                  <ChatCircleIcon size={20} weight={'bold'} />
                  <span>{t('csquestion')}</span>
                </QuestionDropdownItem>
              )}
            </QuestionDropdown>
          </ClickAwayListener>
        )}
      </OuterLinkBox>
      <ProfileBox
        onMouseOver={() => {
          dispatch(
            userInfoService.util.prefetch('getUserInfo', undefined, {
              ifOlderThan: 60,
            })
          );
        }}
      >
        <RouterAnchor to={'/client/profile'}>
          <UserCircleIcon size={24} color={'currentColor'} weight={'bold'} />
          <span>{t('profile')}</span>
        </RouterAnchor>
      </ProfileBox>
    </Base>
  );
};

export default Navigation;

const Base = styled.div`
  width: 72px;
  height: 100%;
  border-right: 1px solid var(--color-grey-100);
  display: flex;
  flex-direction: column;
`;

const RouterAnchorBox = styled.div`
  width: 100%;
`;

const RouterAnchor = styled(Link)`
  text-decoration: none;
  color: var(--color-grey-700);
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: color 225ms;

  span {
    margin-top: 4px;
    ${typography.body.small}
  }

  &:hover {
    color: var(--color-grey-900);
  }

  &:active {
    color: var(--color-black);
  }
`;

const OuterLinkBox = styled.div`
  margin-top: auto;
  width: 100%;
`;

const Anchor = styled.a`
  text-decoration: none;
  color: var(--color-grey-700);
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  transition: color 225ms;

  span {
    margin-top: 4px;
    ${typography.body.small}
  }

  &:hover {
    color: var(--color-grey-900);
  }

  &:active {
    color: var(--color-black);
  }
`;

const Question = Anchor.withComponent('div');

const ProfileBox = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
`;

const QuestionDropdown = styled.div`
  width: 125px;
  height: fit-content;
  position: fixed;
  left: 72px;
  bottom: 46px;
  border: 1px solid #f9f9f9;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: var(--color-white);
`;

const QuestionDropdownItem = styled.div`
  width: 100%;
  height: 32px;
  background: var(--color-white);
  ${typography.body.small}
  color: var(--color-black);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px;

  span {
    margin-left: 8px;
  }

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;
  }
`;

const QuestionDropdownAnchor = QuestionDropdownItem.withComponent('a');
