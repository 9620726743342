import * as React from 'react';
import styled from '@emotion/styled';

interface BottomSheetProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const BottomSheet = React.forwardRef<HTMLDivElement, BottomSheetProps>(
  (props, ref) => {
    return <Base {...props} ref={ref} />;
  }
);

export default BottomSheet;

const Base = styled.div`
  position: fixed;
  bottom: 80px;
  width: 100%;
  max-height: 50vh;
  background: var(--color-white);
  border-radius: 16px 16px 0 0;
  border-top: 1px solid var(--color-grey-400);
  box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.08);
  z-index: 2;
  padding: 16px;
`;
