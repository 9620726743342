import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { CaretRightIcon } from 'assets/svg';
import {
  selectCurrentToolbarItem,
  setToolbarItem,
} from 'features/editor/editorSlice';
import CharacterItem from './CharacterItem';
import UploadItem from './UploadItem';
import VideoItem from './VideoItem';
import AudioItem from './AudioItem';
import ClosedCaptionItem from './ClosedCaptionItem';
import EditorToolbarItems from './EditorToolbarItems';

const EditorToolbar: React.FC = () => {
  const currentToolbarItem = useAppSelector(selectCurrentToolbarItem);

  const dispatch = useAppDispatch();

  const [isHover, setIsHover] = React.useState<boolean>(false);

  const handleClickCollapse: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    dispatch(setToolbarItem('none'));
  };

  React.useEffect(() => {
    dispatch(setToolbarItem('character'));
  }, [dispatch]);

  return (
    <>
      <ToolDrawer
        isCollapse={currentToolbarItem === 'none'}
        onMouseEnter={(e) => {
          e.stopPropagation();
          setIsHover(true);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          setIsHover(false);
        }}
      >
        {currentToolbarItem === 'character' && <CharacterItem />}
        {currentToolbarItem === 'upload' && <UploadItem />}
        {currentToolbarItem === 'video' && <VideoItem />}
        {currentToolbarItem === 'audio' && <AudioItem />}
        {currentToolbarItem === 'cc' && <ClosedCaptionItem />}
        {isHover && currentToolbarItem !== 'none' && (
          <CollapseButton onClick={handleClickCollapse}>
            <CaretRightIcon size={16} weight={'bold'} color={'currentColor'} />
          </CollapseButton>
        )}
      </ToolDrawer>
      <Toolbar>
        <EditorToolbarItems />
      </Toolbar>
    </>
  );
};

export default EditorToolbar;

const ToolDrawer = styled.div<{ isCollapse }>`
  position: relative;
  width: ${({ isCollapse }) => (isCollapse ? '0px' : '401px')};
  height: 100%;
  border-left: 1px solid var(--color-grey-100);
  transition: width 225ms;
`;

const Toolbar = styled.div`
  width: 72px;
  height: 100%;
  border-right: 1px solid var(--color-grey-100);
  background: var(--color-grey-50);
`;

const CollapseButton = styled.button`
  position: absolute;
  z-index: 1;
  top: 14px;
  left: -14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1px solid var(--color-grey-50);
  border-radius: 40px;
  padding: 0;

  color: var(--color-grey-500);
  background: var(--color-white);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08);

  &:hover {
    color: var(--color-white);
    background: var(--color-pink);
  }
`;
