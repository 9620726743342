import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { loadTossPayments } from '@tosspayments/sdk';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import {
  ArrowLeftIcon,
  CreditCardIcon,
  CheckSquareIcon,
  SquareIcon,
} from 'assets/svg';
import { getGradeInfo, formattedPrice } from 'components/membership/utils';
import { typography } from 'styles';
import type { GradeType } from 'components/membership/constants';

interface PaymentsRequestProps {
  grade: GradeType;
}

const PaymentsRequest: React.FC<PaymentsRequestProps> = ({ grade }) => {
  const { t } = useTranslation('client', { keyPrefix: 'payment.request' });

  const [agreement, setAgreement] = React.useState<boolean>(false);
  const toggleAgreement = () => setAgreement((prev) => !prev);

  const { data: userInfo } = useGetUserInfoQuery();

  const { price, discount } = getGradeInfo(grade);

  const handleClickPayments = async () => {
    if (!agreement) return;
    if (userInfo === undefined) return;
    if (userInfo.email === '') return;
    if (userInfo.socialAccountInfo === '') return;

    try {
      const tossPayments = await loadTossPayments(
        process.env.ONAIR_TOSSPAYMENTS_KEY
      );

      const customerKey = btoa(
        `${userInfo.email}:${userInfo.socialAccountInfo}`
      );

      tossPayments.requestBillingAuth('카드', {
        customerKey,
        successUrl: `${window.location.origin}/client/payment/new/success?grade=${grade}`,
        failUrl: `${window.location.origin}/client/payment/new/failure?grade=${grade}`,
        customerEmail: userInfo.email,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Base>
      <TitleBox>
        <GoMembershipButton to={'/client/membership'} replace>
          <ArrowLeftIcon size={24} weight={'bold'} />
        </GoMembershipButton>
        <Title>{t('title')}</Title>
      </TitleBox>
      <BuyItemBox>
        <NameBox>
          <span>{t('productName', { grade })}</span>
        </NameBox>
        <Divider />
        <PriceBox>
          <PriceText>{t('totalPrice')}</PriceText>
          <AddedTaxText>{t('valueAddedTax')}</AddedTaxText>
          <PriceValue>
            {t('price', {
              price: formattedPrice({ price, discount: discount?.value }),
            })}
          </PriceValue>
        </PriceBox>
      </BuyItemBox>
      <PaymentBox>
        <Title>{t('methodName')}</Title>
        <PaymentTypeBox>
          <PaymentType>
            <CreditCardIcon
              size={24}
              color={'var(--color-pink)'}
              weight={'bold'}
            />
            <span>{t('credit')}</span>
          </PaymentType>
        </PaymentTypeBox>
        <Divider />
        <AgreementBox onClick={toggleAgreement}>
          <Checkbox>
            {agreement ? (
              <CheckSquareIcon
                size={24}
                color={'var(--color-pink)'}
                weight={'fill'}
              />
            ) : (
              <SquareIcon
                size={24}
                weight={'bold'}
                color={'var(--color-grey-500)'}
              />
            )}
          </Checkbox>
          <span>{t('privacyPolicy')}</span>
        </AgreementBox>
        <PaymentButton disabled={!agreement} onClick={handleClickPayments}>
          <span>{t('button')}</span>
        </PaymentButton>
      </PaymentBox>
    </Base>
  );
};

export default PaymentsRequest;

const Base = styled.div`
  max-width: 600px;
  width: 100%;
`;

const TitleBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;

  @media (min-width: 520px) {
    margin-bottom: 8px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 12px;
  }
`;

const GoMembershipButton = styled(Link)`
  text-decoration: none;
  background: none;
  border: none;
  padding: 4px;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  color: var(--color-black);
`;

const Title = styled.span`
  ${typography.title.large}
  color: var(--color-black);
`;

const BuyItemBox = styled.div`
  margin-top: 32px;
`;

const NameBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 8px;
  padding: 16px 0;

  span {
    ${typography.headline.small}
    color: var(--color-black);
  }
`;

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: var(--color-black);
  margin: 0;
  padding: 0;
`;

const PriceBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 8px;
  flex-wrap: wrap;
  padding: 16px 0;
`;

const PriceText = styled.span`
  ${typography.title.large}
  color: var(--color-black);
`;

const AddedTaxText = styled.span`
  ${typography.label.large}
  color: var(--color-grey-700);
  margin-right: auto;
  margin-left: 16px;
`;

const PriceValue = styled.span`
  ${typography.title.large}
  color: var(--color-black);
`;

const PaymentBox = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const PaymentTypeBox = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;

const PaymentButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  flex: none;
  width: 100%;
  max-width: 330px;
  padding: 16px 13px;
  margin-top: 16px;

  border: none;
  border-radius: 10px;
  background: var(--color-pink);
  cursor: pointer;

  span {
    ${typography.title.medium}
    color: var(--color-white);
  }

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }

  &:disabled {
    background: var(--color-grey-300);
  }
`;

const PaymentType = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 16px 28px;
  border: 2px solid var(--color-pink);
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    ${typography.body.large}
    color: var(--color-black);
  }

  svg {
    fill: var(--color-pink);
    margin-right: 4px;
  }
`;

const AgreementBox = styled.div`
  margin-top: 24px;
  margin-bottom: 36px;

  width: 100%;
  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none;

  span {
    ${typography.label.large}
    color: var(--color-black);
    margin-left: 4px;
  }
`;

const Checkbox = styled.div`
  --hover-background: rgba(51, 51, 51, 0.08);
  --active-background: rgba(51, 51, 51, 0.16);

  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--hover-background);

    @media (hover: none) {
      background: var(--active-background);
    }
  }

  &:active {
    background: var(--active-background);
  }

  &[data-checked='true'] {
    --hover-background: rgba(255, 55, 110, 0.08);
    --active-background: rgba(255, 55, 110, 0.16);
  }
`;
