import * as React from 'react';
import styled from '@emotion/styled';
import { typography } from 'styles';

interface ContentsTitleProps {
  title: string;
}

const ContentsTitle: React.FC<ContentsTitleProps> = ({ title }) => {
  return (
    <Base>
      <Title>{title}</Title>
    </Base>
  );
};

export default ContentsTitle;

const Base = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;

  @media (min-width: 520px) {
    margin-bottom: 8px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 12px;
  }
`;

const Title = styled.span`
  ${typography.title.large}
`;
