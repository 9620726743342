import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { selectCurrentCellKey } from 'features/editor/editorSlice';
import CellControlBox from './CellControlBox';
import EditableCell from './EditableCell';
import DurationButtonV2 from './DurationButtonV2';

interface CellProps {
  pageKey: string;
  cellKey: string;
  num: number;
}

const Cell: React.FC<CellProps> = ({ pageKey, cellKey, num }) => {
  const currentCellKey = useAppSelector(selectCurrentCellKey);

  return (
    <Base>
      <CellControlBox pageKey={pageKey} cellKey={cellKey} />
      <EditableCell
        pageKey={pageKey}
        cellKey={cellKey}
        isSelected={currentCellKey === cellKey}
        num={num}
      />
      <DurationButtonV2 pageKey={pageKey} cellKey={cellKey} />
    </Base>
  );
};

export default Cell;

const Base = styled.li`
  width: calc(100% - 24px);

  & + & {
    margin-top: 8px;
  }
`;
