import * as React from 'react';
import styled from '@emotion/styled';
import { formattedTime, throttle } from 'lib/utils';
import { PlayIcon, PauseIcon, TailSpinIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { Slider } from 'components/editor';
import useVideoPreview from './useVideoPreview';

const CONTROLS_X_PADDING = 16;
const CONTROL_BUTTON_WIDTH = 24;
const CONTROLS_GAP = 16;
const TIMESTAMP_MAX_WIDTH = 40;
const TIMELINE_GAP = 16;

const getSliderWidth = (boxWidth: number): number =>
  boxWidth -
  CONTROLS_X_PADDING * 2 -
  CONTROL_BUTTON_WIDTH -
  CONTROLS_GAP -
  TIMESTAMP_MAX_WIDTH * 2 -
  TIMELINE_GAP * 2;

interface VideoBoxProps {
  src: string;
  poster: string;
}

const VideoBox = React.forwardRef<HTMLElement, VideoBoxProps>(
  ({ src, poster }, ref) => {
    const [
      videoEl,
      audioEl,
      previewPlay,
      previewPause,
      previewSeek,
      isMerging,
      state,
      isLoading,
    ] = useVideoPreview(src, poster, ref);

    const controlRef = React.useRef<HTMLDivElement | null>(null);

    const [sliderWidth, setSliderWidth] = React.useState<number>(0);

    React.useEffect(() => {
      const el = controlRef.current;
      if (el === null) return;

      const listener = throttle(() => {
        const { width } = el.getBoundingClientRect();

        setSliderWidth(getSliderWidth(width));
      });

      listener(undefined);

      window.addEventListener('resize', listener);

      return () => {
        window.removeEventListener('resize', listener);
      };
    }, []);

    const handleClickControl: React.MouseEventHandler<
      HTMLButtonElement
    > = () => {
      if (state.paused) {
        previewPlay();
      } else {
        previewPause();
      }
    };

    const handleChangeCommitSlider = (newValue: number) => {
      previewSeek(newValue);
    };

    return (
      <Base>
        {videoEl}
        {audioEl}
        <Controls ref={controlRef}>
          {isMerging ? (
            <TailSpinIcon width={24} height={24} />
          ) : (
            <ControlButton
              size={CONTROL_BUTTON_WIDTH}
              radius={12}
              onClick={handleClickControl}
            >
              {state.paused ? (
                <PlayIcon size={12} color={'currentColor'} weight={'fill'} />
              ) : (
                <PauseIcon size={12} color={'currentColor'} weight={'fill'} />
              )}
            </ControlButton>
          )}
          <Timeline>
            <Timestamp>{formattedTime(state.time)}</Timestamp>
            <Slider
              min={0}
              max={state.duration}
              value={state.time}
              width={sliderWidth}
              onChangeCommit={handleChangeCommitSlider}
            />
            <Timestamp>{formattedTime(state.duration)}</Timestamp>
          </Timeline>
        </Controls>
        {isLoading && (
          <Loading>
            <TailSpinIcon />
          </Loading>
        )}
      </Base>
    );
  }
);

export default VideoBox;

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Controls = styled.div`
  width: 100%;
  height: 40px;
  background: var(--color-black);
  padding: 8px ${CONTROLS_X_PADDING}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
`;

const ControlButton = styled(IconButton)`
  color: var(--color-black);
`;

const Timeline = styled.div`
  display: flex;
  align-items: center;
  gap: ${TIMELINE_GAP}px;
`;

const Timestamp = styled.span`
  max-width: ${TIMESTAMP_MAX_WIDTH}px;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-white);
`;

const Loading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 6;
  border-radius: 10px;
  background: rgba(51, 51, 51, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: unset;
    height: 30%;
  }
`;
