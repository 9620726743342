import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { CloudArrowUpIcon } from 'assets/svg';
import { removeExtension } from 'lib/utils';
import { Toast } from 'components/common';
import { fetchUploadVideoItem } from 'features/editor/editorSlice';

const NewVideoListItem: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'video' });

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const dispatch = useAppDispatch();

  const handleUpload = (file: File) => {
    const displayName = removeExtension(file.name);

    dispatch(fetchUploadVideoItem({ file, displayName }));
  };

  const handleChangeInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const el = e.target as HTMLInputElement;
    if (el === undefined) return;
    if (el.files === null) return;
    if (el.files.length === 0) return;

    const file = el.files[0];

    if (file.size > 50 * 1024 * 1024) {
      toggleOpenToast();
      return;
    }

    try {
      handleUpload(file);
      el.value = '';
    } catch (error) {
      el.value = '';
      console.error(error);
    }
  };

  return (
    <>
      <ListItem>
        <AspectBox>
          <Box>
            <CloudArrowUpIcon size={32} weight={'bold'} />
            <HelperText>{t('text.limit')}</HelperText>
          </Box>
        </AspectBox>
        <HelperText>{t('text.upload')}</HelperText>
        <input
          type={'file'}
          accept={'.mp4'}
          style={{ display: 'none' }}
          onChange={handleChangeInput}
        />
      </ListItem>
      <Toast
        in={openToast}
        message={t('toast.upload')}
        onClose={toggleOpenToast}
        timeout={1500}
        severity={'error'}
      />
    </>
  );
};

export default NewVideoListItem;

const ListItem = styled.label`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: fit-content;
`;

const AspectBox = styled.div`
  aspect-ratio: 16 / 9;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
`;

const Box = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border: 2px dashed var(--color-grey-500);
  border-radius: 10px;
  color: var(--color-grey-500);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HelperText = styled.span`
  font-size: 12px;
  line-height: 1.4;
  color: var(--color-grey-500);
`;
