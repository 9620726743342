import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { download } from 'lib/utils';
import {
  useAudioDownloadProgress,
  useAudioDownloadParams,
  useAudioDownloadModalClose,
  useAudioDownloadRetry,
} from 'features/editor/providers';
import DownloadTemplate from './DownloadTemplate';

const AudioDownload: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'downloadProgress' });

  const progress = useAudioDownloadProgress();

  const downloadParams = useAudioDownloadParams();

  const navigate = useNavigate();

  const modalClose = useAudioDownloadModalClose();

  const handleDownload = async () => {
    if (downloadParams === null) return;

    try {
      download(downloadParams.data, downloadParams.name);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRetry = useAudioDownloadRetry();

  return (
    <DownloadTemplate
      id={'audio-download-modal'}
      title={t('start.audio')}
      titleDone={t('done.audio')}
      type={'audio'}
      progress={progress}
      onClose={() => navigate('/client', { replace: true })}
      onEnded={modalClose}
      onDownload={handleDownload}
      onRetry={handleRetry}
    />
  );
};

export default AudioDownload;
