import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  modifyCellData,
  splitCell,
  mergeCell,
  selectInputSelection,
  setInputSelection,
} from 'features/editor/editorSlice';

interface CellTextInputBoxProps {
  pageKey: string;
  cellKey: string;
  defaultValue: string;
  num: number;
}

const CellTextInputBox: React.FC<CellTextInputBoxProps> = ({
  pageKey,
  cellKey,
  defaultValue,
  num,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const dispatch = useAppDispatch();

  const ref = React.useRef<HTMLInputElement | null>(null);

  const inputSelection = useAppSelector(selectInputSelection(cellKey));

  const [text, setText] = React.useState<string>(defaultValue);

  const timeout = React.useRef<number | null>(null);

  const [length, setLength] = React.useState<number>(defaultValue.length);

  const modifyText = (newValue: string) => {
    setText(newValue);
    setLength(newValue.length);
  };

  React.useEffect(() => {
    if (inputSelection === undefined) return;

    const el = ref.current;
    if (el === null) return;

    el.focus();
    el.setSelectionRange(inputSelection, inputSelection);
    dispatch(setInputSelection(undefined));
  }, [inputSelection, dispatch]);

  const handleKeydown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Escape') e.currentTarget.blur();

    const { selectionStart, selectionEnd, value } = e.currentTarget;
    if (selectionStart !== selectionEnd) return;

    if (e.key === 'Enter') {
      if (selectionEnd === null) return;

      if (timeout.current) {
        window.clearTimeout(timeout.current);
        timeout.current = null;
      }

      dispatch(
        modifyCellData({
          pageKey,
          cellKey,
          text: value,
        })
      );
      dispatch(splitCell({ cellKey, len: selectionEnd }));
      return;
    }

    if (e.key === 'Backspace') {
      if (selectionStart === 0) {
        if (num === 0) return;
        dispatch(mergeCell({ cellKey }));
        return;
      }
    }
  };

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    const { selectionStart, selectionEnd } = e.currentTarget;

    if (selectionStart === null || selectionEnd === null) return;

    const clipboardText = e.clipboardData.getData('text');

    const temp = [
      text.slice(0, selectionStart),
      clipboardText,
      text.slice(selectionEnd),
    ].join('');

    const newText = temp.length > 150 ? temp.slice(0, 150) : temp;

    dispatch(
      modifyCellData({
        pageKey,
        cellKey,
        text: newText,
      })
    );
    modifyText(newText);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;

    if (value.length > 150) return;

    modifyText(value);

    if (timeout.current) {
      window.clearTimeout(timeout.current);
      timeout.current = null;
    }

    timeout.current = window.setTimeout(() => {
      dispatch(
        modifyCellData({
          pageKey,
          cellKey,
          text: value,
        })
      );
    }, 500);
  };

  React.useEffect(() => {
    const el = ref.current;
    if (el === null) return;

    let startX: number | undefined = undefined;
    let rAF: ReturnType<typeof window.requestAnimationFrame> | undefined =
      undefined;

    const handleScrub = (delta: number) => {
      if (rAF !== undefined) window.cancelAnimationFrame(rAF);

      rAF = window.requestAnimationFrame(() => {
        if (startX === undefined) return;
        const nextPosition = startX - delta;
        startX = delta;
        el.scrollLeft += nextPosition;
      });
    };

    const handleTouchStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
      startX = undefined;
    };

    const handleTouchMove = (e: TouchEvent) => {
      handleScrub(e.changedTouches[0].clientX);
    };

    const bindEvent = () => {
      el.addEventListener('touchstart', handleTouchStart, { passive: true });
      el.addEventListener('touchend', handleTouchEnd, { passive: true });
      el.addEventListener('touchmove', handleTouchMove, { passive: true });
    };

    const unbindEvent = () => {
      el.removeEventListener('touchstart', handleTouchStart);
      el.removeEventListener('touchend', handleTouchEnd);
      el.removeEventListener('touchmove', handleTouchMove);
    };

    bindEvent();

    return () => {
      unbindEvent();
    };
  }, []);

  return (
    <InputBox>
      <Input
        placeholder={t('placeholder.cellText')}
        value={text}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        onPaste={handlePaste}
        maxLength={150}
        ref={ref}
      />
      <TextLength isError={length > 150}>{length}/150</TextLength>
    </InputBox>
  );
};

export default CellTextInputBox;

const InputBox = styled.div`
  border-radius: var(--cell-text-input-radius);
  background: var(--color-white);
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: calc(100% - 54px);
  height: 40px;
  border: none;
  outline: none;
  padding: 8px;
  color: var(--color-black);
  background: transparent;

  &::placeholder {
    color: var(--color-grey-700);
  }
`;

const TextLength = styled.span<{ isError: boolean }>`
  // position: absolute;
  // right: 8px;
  // bottom: 4px;
  margin-left: auto;
  margin-right: 4px;
  font-size: 12px;
  color: ${({ isError }) =>
    isError ? 'var(--color-danger)' : 'var(--color-grey-600)'};
  line-height: 1.4;
  user-select: none;
`;
