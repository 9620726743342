import * as React from 'react';
import styled from '@emotion/styled';
import PaymentPolicy from './PaymentPolicy';
import ProductInfoCards from './ProductInfoCards';

const ProductInfo: React.FC = () => {
  return (
    <Base>
      <ProductInfoCards />
      <PaymentPolicy />
    </Base>
  );
};

export default ProductInfo;

const Base = styled.div`
  margin-top: 16px;
`;
