import { createSlice, createSelector } from '@reduxjs/toolkit';
import ProjectsService from 'app/services/projects';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

export interface ProjectListState {
  progressingList: string[];
}

type ProjectListName = 'projectList';
const name: ProjectListName = 'projectList';

const initialState: ProjectListState = {
  progressingList: [],
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    removeProcessing(state, { payload }: PayloadAction<{ project: string }>) {
      state.progressingList = state.progressingList.filter(
        (data) => data !== payload.project
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ProjectsService.endpoints.getProjects.matchFulfilled,
      (state, { payload }) => {
        if (payload === undefined) return;
        if (payload.length < 1) return;
        payload.forEach((meta) => {
          if (typeof meta.renderingStatus !== 'number') return;
          if (meta.projectUuid === undefined) return;
          if (meta.renderingStatus > 0 && meta.renderingStatus < 5) {
            state.progressingList.push(meta.projectUuid);
          }
          return;
        });
      }
    );
  },
});

export const { removeProcessing } = slice.actions;

export default slice.reducer;

export const selectProcessingList = createSelector(
  (state: RootState) => state.projectList,
  (projectList) => ({ progressingList: projectList.progressingList })
);
