import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import videoModelService from 'app/services/videoModel';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import {
  UserSquareIcon,
  FilePlusIcon,
  MonitorPlayIcon,
  MusicNotesSimpleIcon,
  ClosedCaptioningIcon,
} from 'assets/svg';
import {
  selectCurrentToolbarItem,
  setToolbarItem,
} from 'features/editor/editorSlice';

interface EditorToolbarLinkProps {
  onClickItem?: () => void;
}

const EditorToolbarLink: React.FC<EditorToolbarLinkProps> = ({
  onClickItem,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'toolbar' });

  const currentToolbarItem = useAppSelector(selectCurrentToolbarItem);

  const dispatch = useAppDispatch();

  const handleClickToolbarItem = (type) => {
    if (currentToolbarItem === type) return;
    dispatch(setToolbarItem(type));
    if (onClickItem !== undefined) onClickItem();
  };

  const { isFree } = useGetUserInfoQuery(undefined, {
    selectFromResult: ({ data }) => ({
      isFree:
        data?.billingName === undefined
          ? true
          : data.billingName === 'Free'
          ? true
          : false,
    }),
  });

  return (
    <>
      <ToolbarItemButton
        onClick={() => handleClickToolbarItem('character')}
        onMouseOver={() => {
          dispatch(
            videoModelService.util.prefetch('getVideoModel', undefined, {
              ifOlderThan: 60,
            })
          );
        }}
        isSelected={currentToolbarItem === 'character'}
      >
        <UserSquareIcon size={24} weight={'bold'} />
        <span>{t('character.text')}</span>
      </ToolbarItemButton>
      <ToolbarItemButton
        onClick={() => handleClickToolbarItem('upload')}
        isSelected={currentToolbarItem === 'upload'}
      >
        <FilePlusIcon size={24} weight={'bold'} />
        <span>{t('upload.text')}</span>
      </ToolbarItemButton>
      {!isFree && (
        <ToolbarItemButton
          onClick={() => {
            handleClickToolbarItem('video');
          }}
          isSelected={currentToolbarItem === 'video'}
        >
          <MonitorPlayIcon size={24} weight={'bold'} />
          <span>{t('video.text')}</span>
        </ToolbarItemButton>
      )}
      <ToolbarItemButton
        onClick={() => handleClickToolbarItem('audio')}
        isSelected={currentToolbarItem === 'audio'}
      >
        <MusicNotesSimpleIcon size={24} weight={'bold'} />
        <span>{t('music.text')}</span>
      </ToolbarItemButton>
      <ToolbarItemButton
        onClick={() => handleClickToolbarItem('cc')}
        isSelected={currentToolbarItem === 'cc'}
      >
        <ClosedCaptioningIcon size={24} weight={'bold'} />
        <span>{t('cc.text')}</span>
      </ToolbarItemButton>
    </>
  );
};

export default EditorToolbarLink;

const ToolbarItemButton = styled.button<{ isSelected: boolean }>`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );

  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-grey-50);
  border: none;
  padding: 0;

  color: var(--color-grey-700);

  &:hover {
    background: var(--hover-gradient) var(--color-grey-50);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    color: var(--color-pink);
    background: var(--color-white);
    font-weight: 700;
  }

  span {
    font-size: 12px;
    line-height: 1.4;
    margin-top: 4px;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    color: var(--color-pink);
    background: var(--color-white);
    font-weight: 700;
  `}
`;
