import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useGetVideoModelQuery } from 'app/services/videoModel';
import { selectPageSTV } from 'features/editor/editorSlice';

interface VideoOverlayProps {
  pageKey: string;
  imageWidth: number;
}

const VideoOverlay: React.FC<VideoOverlayProps> = ({ pageKey, imageWidth }) => {
  const stv = useAppSelector(selectPageSTV(pageKey));
  const { resourceUrl } = useGetVideoModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      resourceUrl: data?.reduce<string | undefined>((acc, item) => {
        if (item.name !== stv?.videoModelName) return acc;

        const resource = item.resources.find((el) => el.name === stv.resource);
        if (resource === undefined) return acc;

        return resource.editThumbUrl;
      }, undefined),
    }),
  });

  const setStyle = React.useCallback(
    (instance: HTMLImageElement) => {
      if (stv?.location === undefined) return;

      const ratio = imageWidth / 1920;

      const { x, y, width, height } = stv.location;

      instance.style.cssText = `
			  width: ${width * ratio}px;
				height: ${height * ratio}px;
			  top: ${y * ratio}px;
				left: ${x * ratio}px;
			`;
    },
    [stv, imageWidth]
  );

  const refCallback: React.RefCallback<HTMLImageElement> = (instance) => {
    if (instance === null) return;

    setStyle(instance);
  };

  if (stv === undefined) return null;
  if (resourceUrl === undefined) return null;
  return <Image ref={refCallback} src={resourceUrl} />;
};

export default VideoOverlay;

const Image = styled.img`
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  position: absolute;
`;
