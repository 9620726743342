import * as React from 'react';
import { ClientTemplate } from 'components/client';
import { ProjectList } from 'components/projectList';
import { ProjectListProvider } from 'providers/projectList';

const ProjectListPage: React.FC = () => {
  return (
    <ClientTemplate>
      <ProjectListProvider>
        <ProjectList />
      </ProjectListProvider>
    </ClientTemplate>
  );
};

export default ProjectListPage;
