import { createApi } from '@reduxjs/toolkit/query/react';
import { nanoid } from '@reduxjs/toolkit';
import queryFetcher from './queryFetcher';
import { camelCase, recordTransformer } from 'lib/utils';

interface RawVideoModelResource {
  name: string;
  width: number;
  height: number;
  thumb_url: string;
  edit_thumb_url: string;
  display_name?: string;
}

interface RawVideoModel {
  audio_ml_model_name: string;
  display_name: string;
  name: string;
  profile_thumb: string;
  language: string;
  is_best: boolean;
  is_new: boolean;
  create_date: string;
  resources: RawVideoModelResource[];
}

interface RawGetVideoModelResponse {
  models: RawVideoModel[];
}

export interface VideoModelResource {
  name: string;
  width: number;
  height: number;
  thumbUrl: string;
  editThumbUrl: string;
  displayName?: string;
}

export interface VideoModel {
  key: string;
  audioMlModelName: string;
  displayName: string;
  name: string;
  profileThumb: string;
  language: string;
  isBest: boolean;
  isNew: boolean;
  createDate: string;
  resources: VideoModelResource[];
}

interface GetVideoModelResponse {
  models: VideoModel[];
}

export const api = createApi({
  reducerPath: 'services/videoModel',
  baseQuery: queryFetcher('/api/video'),
  endpoints: (builder) => ({
    getVideoModel: builder.query<VideoModel[], void>({
      query: () => ({
        url: '/video-ml-models',
        method: 'GET',
      }),
      transformResponse: (resp: RawGetVideoModelResponse) => {
        const data = recordTransformer(
          resp,
          camelCase
        ) as GetVideoModelResponse;

        return data.models.map((model) => ({ ...model, key: nanoid() }));
      },
    }),
  }),
});

export const { useGetVideoModelQuery } = api;

export default api;
