import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { formattedTime } from 'lib/utils';
import { PauseCircleIcon, PlayCircleIcon, TailSpinIcon } from 'assets/svg';
import { Slider } from 'components/editor';
import {
  selectAllTextDatas,
  fetchMergeAudios,
} from 'features/editor/editorSlice';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
} from 'features/editor/providers';

const FullListenPlayer: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'fullListenPlayer' });

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const dispatch = useAppDispatch();

  const textDatas = useAppSelector(selectAllTextDatas);
  const prevMergeData = React.useRef<string>('');

  const handleClickPlay = async () => {
    const el = ref.current;
    if (el === null) return;
    if (textDatas.length === 0) return;

    if (!state.paused) {
      editorAudioPause();
      return;
    }

    if (prevMergeData.current === JSON.stringify(textDatas)) {
      editorAudioPlay(controls);
      return;
    }

    try {
      setMergeLoading(true);
      prevMergeData.current = JSON.stringify(textDatas);
      const url = await dispatch(fetchMergeAudios(textDatas)).unwrap();
      el.src = url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Base>
      <PlayerBox>
        <PlayButton onClick={handleClickPlay}>
          {mergeLoading ? (
            <TailSpinIcon width={20} height={20} />
          ) : (
            <>
              {state.paused ? (
                <PlayCircleIcon size={20} weight={'fill'} />
              ) : (
                <PauseCircleIcon size={20} weight={'fill'} />
              )}
            </>
          )}
          <span>{state.paused ? t('play') : t('pause')}</span>
        </PlayButton>
        <Divider />
        <Timeline>
          <Timestamp>{formattedTime(state.time)}</Timestamp>
          <Slider
            width={176}
            min={0}
            value={state.time}
            onChangeCommit={(newValue) => controls.seek(newValue)}
            max={state.duration}
          />
          <Timestamp>{formattedTime(state.duration)}</Timestamp>
        </Timeline>
        {element}
      </PlayerBox>
    </Base>
  );
};

export default FullListenPlayer;

const Base = styled.div`
  --header-left: 322px; // Navigation: 72px, PageCardList: 250px
  --header-right: 473px; // ToolDrawer: 401px, Toolbar: 72px,

  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - (var(--header-left) + var(--header-right)));
  margin-left: var(--header-left);
  margin-right: var(--header-right);
`;

const PlayerBox = styled.div`
  width: 400px;
  height: 40px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  height: 24px;

  outline: none;
  border: none;
  padding: 0;
  background: var(--color-white);

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  cursor: pointer;

  span {
    margin-left: 4px;
  }
`;

const Divider = styled.hr`
  width: 1px;
  height: 20px;
  background-color: var(--color-grey-100);
  margin: 0 8px;
  border: none;
`;

const Timeline = styled.div`
  flex: 1;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Timestamp = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-500);
`;
