interface RGB {
  r: number;
  g: number;
  b: number;
}

export default function hexToRgb(hex: string): RGB {
  let c: any;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    const ret = [(c >> 16) & 255, (c >> 8) & 255, c & 255];
    return {
      r: ret[0],
      g: ret[1],
      b: ret[2],
    };
  }
  throw new Error('Bad Hex Color');
}
