import * as React from 'react';
import { Dialog } from '@headlessui/react';
import styled from '@emotion/styled';

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  open,
  onClose = () => {},
  children,
}) => {
  const refDiv = React.useRef<HTMLDivElement | null>(null);

  return (
    <Dialog open={open} onClose={onClose} as={DialogBase} initialFocus={refDiv}>
      <Dialog.Overlay as={DialogOverlay} />
      <div ref={refDiv}></div>
      {children}
    </Dialog>
  );
};

export default Modal;

const DialogBase = styled.div`
  position: fixed;
  z-index: var(--popup-index);
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogOverlay = styled.div`
  position: fixed;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(51, 51, 51, 0.8);
  -webkit-tap-highlight-color: transparent;
`;
