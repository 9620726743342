import { recordTransformer, camelCase } from 'lib/utils';

interface FetchParameters {
  token: string;
  body: string;
  signal?: AbortSignal;
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/projects/v2`;

export default async function fetchInitialProject({
  token,
  body,
  signal,
}: FetchParameters) {
  const response = await fetch(URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body,
    signal,
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  const data = await response.json();

  return recordTransformer(data, camelCase);
}
