import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { GradientAddIcon } from 'assets/svg';

const NewProjectCard: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'home' });

  const navigate = useNavigate();

  return (
    <Base onClick={() => navigate('/client/editor')}>
      <GradientAddIcon />
      <Text>{t('text.createProject')}</Text>
    </Base>
  );
};

export default NewProjectCard;

const Base = styled.div`
  width: 330px;
  height: 186px;
  background: var(--color-white);
  border: 1px solid var(--color-grey-100);
  box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  margin-top: 8px;
`;
