import * as React from 'react';
import styled from '@emotion/styled';
import { useMatchMedia } from 'lib/hooks';
import { useAppSelector } from 'app/hooks';
import {
  selectCurrentPageKey,
  selectPagesContent,
} from 'features/editor/editorSlice';
import { useToolbarCurrentState } from 'features/editor/providers';
import Page from './Page';

const PageList: React.FC = () => {
  const pages = useAppSelector(selectPagesContent);
  const currentPageKey = useAppSelector(selectCurrentPageKey);

  const match = useMatchMedia('(min-width: 1024px)');
  const toolbarOpen = useToolbarCurrentState();

  return (
    <Base style={{ overflow: !match && toolbarOpen ? 'hidden' : 'auto' }}>
      {pages.map((page) => (
        <Page
          pageKey={page.key}
          isSelected={page.key === currentPageKey}
          key={page.key}
        />
      ))}
    </Base>
  );
};

export default PageList;

const Base = styled.div`
  z-index: 1;
  flex: 1;
  background-color: var(--color-white);
  overflow-y: auto;
`;
