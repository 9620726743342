import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { selectLoginInfo } from 'features/auth/authSlice';
import type { Location } from 'react-router-dom';

interface LocationHasFromState extends Location {
  state: {
    from: Location;
  };
}

const hasReturnFrom = (
  location: Location
): location is LocationHasFromState => {
  if (typeof location.state === 'undefined') return false;
  if (location.state === null) return false;
  if (typeof (location.state as any).from === 'undefined') return false;
  return true;
};

const useLoginAfterNavigate = () => {
  const loginInfo = useAppSelector(selectLoginInfo);

  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (loginInfo.token === null || loginInfo.expiredTime === null) return;

    if (hasReturnFrom(location)) {
      navigate(location);
    } else {
      navigate('/client');
    }
  }, [loginInfo, location, navigate]);
};

export default useLoginAfterNavigate;
