import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { PlusIcon } from 'assets/svg';
import { createLastPage } from 'features/editor/editorSlice';

const NewPageCard: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(createLastPage());
  };

  return (
    <Base onClick={handleClick}>
      <Content>
        <PreviewBox>
          <PlusIcon size={24} weight={'bold'} />
          <span>{t('text.newPage')}</span>
        </PreviewBox>
      </Content>
    </Base>
  );
};

export default NewPageCard;

const Base = styled.div`
  --preview-box-border-color: var(--color-grey-200);
  --preview-box-border-width: 2px;
  --preview-box-color: var(--color-grey-500);

  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;

  &:active {
    --page-number-color: var(--color-pink);
    --preview-box-border-color: var(--color-pink);
    --preview-box-color: var(--color-pink);

    background: linear-gradient(
        rgba(255, 255, 255, 0.92),
        rgba(255, 255, 255, 0.92)
      )
      var(--color-pink);
  }
`;

const Content = styled.div`
  width: 100%;
  height: 90px;
  display: flex;

  @media (min-width: 1024px) {
    padding-right: 36px;
  }
`;

const PreviewBox = styled.div`
  width: 160px;
  height: 100%;
  border-width: var(--preview-box-border-width);
  border-style: solid;
  border-color: var(--preview-box-border-color);
  border-radius: 10px;
  background: var(--color-white);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 1.4;
  color: var(--preview-box-color);

  span {
    margin-top: 8px;
  }

  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1024px) {
    margin-right: 0;
  }
`;
