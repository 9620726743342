import * as React from 'react';
import styled from '@emotion/styled';
import { ModalV2 } from 'components/common';

interface IncompleteProps {
  open: boolean;
  title: string;
  description: string;
  buttonContinue: string;
  onClickContinue: () => void;
  buttonCancel: string;
  onClickCancel: () => void;
  onClose: () => void;
}

const Incomplete: React.FC<IncompleteProps> = ({
  open,
  onClose,
  title,
  description,
  buttonContinue,
  onClickContinue,
  buttonCancel,
  onClickCancel,
}) => {
  return (
    <ModalV2 open={open} onClose={onClose}>
      <Base>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Button marginTop={'26px'} onClick={onClickContinue}>
          {buttonContinue}
        </Button>
        <Button onClick={onClickCancel}>{buttonCancel}</Button>
      </Base>
    </ModalV2>
  );
};

export default Incomplete;

const Base = styled.div`
  max-height: calc(100% - 32px);
  max-width: 440px;
  height: fit-content;
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  box-shadow: 0px 4px 24px rgba(51, 51, 51, 0.12);
  border-radius: 10px;
  padding: 24px;
`;

const Title = styled.div`
  color: var(--color-black);
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  white-space: pre;
  word-break: keep-all;
`;

const Description = styled.div`
  margin-top: 16px;
  color: var(--color-black);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  white-space: pre;
  word-break: keep-all;
`;

const Button = styled.button<{ marginTop?: string }>`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  padding: 0;
  margin: 0;
  margin-top: ${({ marginTop }) => marginTop ?? '16px'};

  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-black);
  cursor: pointer;

  &:hover {
    background: rgba(51, 51, 51, 0.08);

    @media (hover: none) {
      background: rgba(51, 51, 51, 0.16);
    }
  }

  &:active {
    background: rgba(51, 51, 51, 0.16);
  }

  @media (min-width: 520px) {
    width: 392px;
  }
`;
