import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useGetTemplatesQuery } from 'app/services/templates';
import ContentsTitle from './ContentsTitle';
import TemplateItem from './TemplateItem';

const TemplateList: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'home' });

  const { data: templates } = useGetTemplatesQuery();

  return (
    <Base>
      <ContentsTitle title={t('title.template')} />
      <List>
        {templates &&
          [...templates].map((template) => (
            <TemplateItem
              thumbUrl={template.projectThumbnail}
              title={template.projectName}
              uuid={template.projectUuid}
              key={template.projectUuid}
            />
          ))}
      </List>
    </Base>
  );
};

export default TemplateList;

const Base = styled.div`
  flex: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const List = styled.div`
  width: 100%;
  height: 174px;
  padding-bottom: 24px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
`;
