import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { TailSpinIcon, PlayCircleIcon, StopCircleIcon } from 'assets/svg';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
  usePusherChannelId,
} from 'features/editor/providers';
import { LazyImage } from 'features/editor/common';
import { fetchMergeMlModelText } from 'features/editor/editorSlice';
import AudioManager from 'lib/AudioManager';
import { codeToMergeText } from 'locales/utils';

interface ModelSelectItemProps {
  profile: string;
  displayName: string;
  audioModelName: string;
  isSelected: boolean;
  language: string;
  onSelect: () => void;
}

const ModelSelectItem: React.FC<ModelSelectItemProps> = ({
  profile,
  displayName,
  audioModelName,
  isSelected,
  language,
  onSelect,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'videoModelSelect' });

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const dispatch = useAppDispatch();

  const channelId = usePusherChannelId();

  const handleClickPlay: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.stopPropagation();

    const el = ref.current;
    if (el === null) return;
    if (channelId === undefined) return;

    if (el.readyState >= 2) {
      if (state.paused) {
        editorAudioPlay(controls);
      } else {
        editorAudioPause();
        controls.seek(0);
      }
      return;
    }

    const previewText = codeToMergeText(language);
    if (previewText === undefined) {
      console.error('unknown language code');
      return;
    }

    try {
      setMergeLoading(true);
      const blob = await dispatch(
        fetchMergeMlModelText({
          name: audioModelName,
          channelId,
          text: previewText,
        })
      ).unwrap();

      const url = AudioManager.createObjectUrl(blob);
      el.src = url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Base isSelected={isSelected}>
      <ImageBox onClick={onSelect}>
        <Image
          width={76}
          height={76}
          src={profile}
          alt={displayName}
          draggable={false}
        />
      </ImageBox>
      <DisplayName>{displayName}</DisplayName>
      <ListenButton onClick={handleClickPlay}>
        {mergeLoading ? (
          <TailSpinIcon width={20} height={20} />
        ) : (
          <>
            {state.paused ? (
              <PlayCircleIcon
                size={20}
                color={'currentColor'}
                weight={'fill'}
              />
            ) : (
              <StopCircleIcon
                size={20}
                color={'currentColor'}
                weight={'fill'}
              />
            )}
          </>
        )}
        <span>{state.paused ? t('button.play') : t('button.stop')}</span>
      </ListenButton>
      {element}
    </Base>
  );
};

export default ModelSelectItem;

const Base = styled.div<{ isSelected: boolean }>`
  width: 80px;
  height: 132px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;

  ${({ isSelected }) =>
    isSelected &&
    `
	  & > div:first-of-type {
			border: 2px solid var(--color-pink);
		}
	`}
`;

const ImageBox = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--color-grey-50);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Image = styled(LazyImage)`
  object-fit: cover;
  border-radius: 50%;
`;

const DisplayName = styled.span`
  width: 80px;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-black);
  margin-bottom: 4px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ListenButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-700);
  cursor: pointer;

  span {
    margin-left: 4px;
  }
`;
