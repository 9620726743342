import * as React from 'react';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { formattedTime } from 'lib/utils';
import { useResizeObserver } from 'lib/hooks';
import {
  PauseCircleIcon,
  PlayCircleIcon,
  TailSpinIcon,
  GradientAddIcon,
} from 'assets/svg';
import { IconButton } from 'components/common';
import { Slider } from 'components/editor';
import {
  selectAllTextDatas,
  fetchMergeAudios,
} from 'features/editor/editorSlice';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
  useToolbarToggleState,
} from 'features/editor/providers';
import DownloadButton from './DownloadButton';
import SaveButton from './SaveButton';

const EditorFooter: React.FC = () => {
  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const dispatch = useAppDispatch();

  const textDatas = useAppSelector(selectAllTextDatas);
  const prevMergeData = React.useRef<string>('');

  const handleClickPlay = async () => {
    const el = ref.current;
    if (el === null) return;
    if (textDatas.length === 0) return;

    if (!state.paused) {
      editorAudioPause();
      return;
    }

    if (prevMergeData.current === JSON.stringify(textDatas)) {
      editorAudioPlay(controls);
      return;
    }

    try {
      setMergeLoading(true);
      prevMergeData.current = JSON.stringify(textDatas);
      const url = await dispatch(fetchMergeAudios(textDatas)).unwrap();
      el.src = url;
    } catch (error) {
      console.error(error);
    }
  };

  const toggleToolbar = useToolbarToggleState();

  const { ref: footerRef, width } = useResizeObserver();

  return (
    <Base ref={footerRef}>
      <Timeline>
        <Timestamp>{formattedTime(state.time)}</Timestamp>
        <Slider
          width={width - 80 - 32 - 12}
          min={0}
          value={state.time}
          onChangeCommit={(newValue) => controls.seek(newValue)}
          max={state.duration}
        />
        <Timestamp>{formattedTime(state.duration)}</Timestamp>
      </Timeline>
      <ControlBox>
        <IconButton size={32} radius={6} onClick={toggleToolbar}>
          <GradientAddIcon width={32} height={32} />
        </IconButton>
        <IconButton size={32} radius={6} onClick={handleClickPlay}>
          {mergeLoading ? (
            <TailSpinIcon width={32} height={32} />
          ) : (
            <>
              {state.paused ? (
                <PlayCircleIcon
                  size={32}
                  weight={'fill'}
                  color={'var(--color-grey-700)'}
                />
              ) : (
                <PauseCircleIcon
                  size={32}
                  weight={'fill'}
                  color={'var(--color-grey-700)'}
                />
              )}
            </>
          )}
          {element}
        </IconButton>
        <SaveButton />
        <DownloadButton />
      </ControlBox>
    </Base>
  );
};

export default EditorFooter;

const Base = styled.footer`
  width: 100%;
  height: 80px;
  background: var(--color-white);
  box-shadow: 0px -1px 1px var(--color-grey-100);
  border-top: 1px solid var(--color-grey-100);
  padding: 8px 16px;
  z-index: var(--footer-index);
`;

const Timeline = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Timestamp = styled.span`
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-grey-500);
`;

const ControlBox = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
