import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useGetVideoModelQuery } from 'app/services/videoModel';
import { kmp } from 'lib/utils';
import {
  modifyPageData,
  modifyManyPageData,
  selectCurrentPageKey,
  selectCurrentPageSTV,
} from 'features/editor/editorSlice';
import {
  PREVIEW_ORIGIN_WIDTH,
  PREVIEW_ORIGIN_HEIGHT,
} from 'features/editor/constants';
import ModelSelect from './ModelSelect';
import PoseSelect from './PoseSelect';
import PositionSelect from './PositionSelect';
import SearchBar from '../SearchBar';
import type { VideoModel, VideoModelResource } from 'app/services/videoModel';
import type { StvLocation } from 'features/editor/editorSlice/types';

const getSearchedModels = (
  models: VideoModel[] | undefined,
  searchWord: string
) => {
  if (models === undefined) return [];
  if (searchWord === '') return models;

  const searchedModels = models.filter((model) => {
    if (kmp(model.displayName.toUpperCase(), searchWord.toUpperCase()) > -1)
      return true;
    return false;
  });

  return searchedModels;
};

const getSizeRatio = ({
  currentWidth,
  originWidth,
}: {
  currentWidth?: number;
  originWidth?: number;
}): number => {
  if (currentWidth === undefined) return 100;
  if (originWidth === undefined) return 100;

  const ratio = Math.floor((currentWidth / originWidth) * 100);
  const result = Math.min(Math.max(50, ratio), 150);
  return result;
};

type PositionType = 'left' | 'center' | 'right';

const VideoModelSelect: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'videoModelSelect' });

  const dispatch = useAppDispatch();

  const pageKey = useAppSelector(selectCurrentPageKey);
  const currentSTV = useAppSelector(selectCurrentPageSTV);

  const [searchWord, setSearchWord] = React.useState<string>('');

  const [modelKey, setModelKey] = React.useState<string | undefined>(
    currentSTV?.videoModelName
  );
  const [poseKey, setPoseKey] = React.useState<string | undefined>(
    currentSTV?.resource
  );
  const [position, setPosition] = React.useState<StvLocation | undefined>(
    currentSTV?.location
  );

  const { models, initModel, initPoseInfo } = useGetVideoModelQuery(undefined, {
    selectFromResult: ({ data }) => ({
      models: getSearchedModels(data, searchWord),
      initModel: getSearchedModels(data, searchWord)?.find(
        (el) => modelKey !== undefined && el.name === modelKey
      ),
      initPoseInfo: getSearchedModels(data, searchWord)
        ?.find((el) => modelKey !== undefined && el.name === modelKey)
        ?.resources?.find((el) => poseKey !== undefined && el.name === poseKey),
    }),
  });

  const model = React.useRef<VideoModel | undefined>(initModel);
  const poseInfo = React.useRef<VideoModelResource | undefined>(initPoseInfo);

  const initSizeRatio = getSizeRatio({
    currentWidth: position?.width,
    originWidth: poseInfo.current?.width,
  });
  const [sizeRatio, setSizeRatio] = React.useState<number>(initSizeRatio);

  React.useEffect(() => {
    if (currentSTV === undefined) return;
    if (pageKey === undefined) return;

    const originModel = models.find(
      (el) => el.name === currentSTV.videoModelName
    );
    model.current = originModel;
    setModelKey(currentSTV.videoModelName);
    if (originModel === undefined) return;

    const originPoseInfo = originModel?.resources.find(
      (el) => el.name === currentSTV.resource
    );
    poseInfo.current = originPoseInfo;
    setPoseKey(currentSTV.resource);
    if (originPoseInfo === undefined) return;

    setPosition(currentSTV.location);

    const ratio = getSizeRatio({
      currentWidth: currentSTV.location.width,
      originWidth: originPoseInfo.width,
    });
    setSizeRatio(ratio);
  }, [currentSTV, models, pageKey]);

  const handleChangeSearchWord: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (e.target === null) return;

    setSearchWord(e.target.value);
  };

  const handleChangeModel = (key: string) => {
    const selectedModel = models.find((el) => el.name === key);

    if (selectedModel === undefined) return;

    model.current = selectedModel;
    setModelKey(key);
    setPoseKey(undefined);
    setPosition(undefined);
  };

  const handleChangePose = (key: string) => {
    if (modelKey === undefined) return;
    if (model.current === undefined) return;

    const selectedPose = model.current.resources.find((el) => el.name === key);
    if (selectedPose === undefined) return;
    poseInfo.current = selectedPose;

    setPoseKey(key);
    setSizeRatio(100);

    const newPosition = {
      x: (PREVIEW_ORIGIN_WIDTH - selectedPose.width) / 2,
      y: PREVIEW_ORIGIN_HEIGHT - selectedPose.height,
      width: selectedPose.width,
      height: selectedPose.height,
    };
    setPosition(newPosition);

    dispatch(
      modifyPageData({
        pageKey,
        stv: {
          videoModelName: model.current.name,
          audioModelName: model.current.audioMlModelName,
          resource: key,
          location: newPosition,
        },
      })
    );
  };

  const handleChangeSize = (sizeRatio: number) => {
    if (poseKey === undefined) return;
    if (position === undefined) return;
    if (model.current === undefined) return;
    if (poseInfo.current === undefined) return;

    const newWidth = (poseInfo.current.width * sizeRatio) / 100;
    const newHeight = (poseInfo.current.height * sizeRatio) / 100;

    const deltaX = (position.width - newWidth) / 2;
    const deltaY = (position.height - newHeight) / 2;

    const newPosition = {
      x: position.x + deltaX,
      y: position.y + deltaY,
      width: newWidth,
      height: newHeight,
    };

    setPosition(newPosition);

    dispatch(
      modifyPageData({
        pageKey,
        stv: {
          videoModelName: model.current.name,
          audioModelName: model.current.audioMlModelName,
          resource: poseKey,
          location: newPosition,
        },
      })
    );
  };

  const handleClickPosition = (pos: PositionType) => {
    if (position === undefined) return;
    if (poseKey === undefined) return;
    if (model.current === undefined) return;

    let x = 0;
    let y = PREVIEW_ORIGIN_HEIGHT - position.height;

    if (pos === 'center') {
      x = (PREVIEW_ORIGIN_WIDTH - position.width) / 2;
    }

    if (pos === 'right') {
      x = PREVIEW_ORIGIN_WIDTH - position.width;
    }

    const newPosition = {
      x,
      y,
      width: position.width,
      height: position.height,
    };
    setPosition(newPosition);

    dispatch(
      modifyPageData({
        pageKey,
        stv: {
          videoModelName: model.current.name,
          audioModelName: model.current.audioMlModelName,
          resource: poseKey,
          location: newPosition,
        },
      })
    );
  };

  const handleClickApplyAll = () => {
    if (poseKey === undefined) return;
    if (position === undefined) return;
    if (model.current === undefined) return;

    const stv = {
      videoModelName: model.current.name,
      audioModelName: model.current.audioMlModelName,
      resource: poseKey,
      location: position,
    };

    dispatch(modifyManyPageData({ stv }));
  };

  return (
    <Base>
      <SearchBar
        value={searchWord}
        placeholder={t('placeholder')}
        onChange={handleChangeSearchWord}
      />
      <ModelSelect
        searchModelName={searchWord}
        selectedModelName={modelKey}
        onSelectModel={handleChangeModel}
      />
      {modelKey !== undefined && (
        <PoseSelect
          selectedModelName={modelKey}
          selectedPoseName={poseKey}
          onSelectPose={handleChangePose}
        />
      )}
      {modelKey !== undefined && poseKey !== undefined && (
        <PositionSelect
          sizeRatio={sizeRatio}
          onChangeSize={handleChangeSize}
          onClickPosition={handleClickPosition}
          onClickApplyAll={handleClickApplyAll}
        />
      )}
    </Base>
  );
};

export default VideoModelSelect;

const Base = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-top: 16px;
  overflow-y: auto;
`;
