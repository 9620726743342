import * as React from 'react';
import styled from '@emotion/styled';

interface StandardValueProps {
  value: number;
  min: number;
  max: number;
  step?: number;
}

const getStandardValue = ({ value, min, max, step }: StandardValueProps) => {
  const betweenValue: number = Math.min(Math.max(value, min), max);

  if (step === undefined) return betweenValue;

  try {
    const truncValue = Math.trunc(betweenValue / step) * step;
    const isRound = betweenValue - truncValue >= step / 2;

    const standardValue = isRound ? truncValue + step : truncValue;
    const fixedPoint = Number(step).toString().split('.')[1]?.length ?? 0;

    return Number(standardValue.toFixed(fixedPoint));
  } catch {
    return betweenValue;
  }
};

const clamp = (value: number | null, min: number, max: number) => {
  if (value === null) return min;

  return Math.min(Math.max(min, value), max);
};

interface SliderProps {
  width: number;
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  onChange?: (newValue: number) => void;
  onChangeCommit?: (newValue: number) => void;
}

const Slider: React.FC<SliderProps> = ({
  width,
  min = 0,
  max = 100,
  value = 0,
  step,
  onChange,
  onChangeCommit,
}) => {
  const valueRef = React.useRef<number>(0);
  const rAF = React.useRef<number>(0);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const trackRef = React.useRef<HTMLSpanElement | null>(null);
  const thumbRef = React.useRef<HTMLSpanElement | null>(null);
  const isSliding = React.useRef<boolean>(false);

  React.useEffect(() => {
    const el = ref.current;
    if (el === null) return;
    const trackEl = trackRef.current;
    if (trackEl === null) return;
    const thumbEl = thumbRef.current;
    if (thumbEl === null) return;

    if (isSliding.current) return;

    valueRef.current = value;

    const percent = (value - min) / (max - min);

    trackEl.style.setProperty(
      '--value-percent',
      Number.isNaN(percent) ? '0%' : `${(percent * 100).toFixed(2)}%`
    );
    thumbEl.style.setProperty(
      '--left-pos',
      Number.isNaN(width * percent - 12) ? '0px' : `${width * percent - 12}px`
    );
  }, [max, min, value, width]);

  React.useEffect(() => {
    const el = ref.current;
    if (el === null) return;
    const trackEl = trackRef.current;
    if (trackEl === null) return;
    const thumbEl = thumbRef.current;
    if (thumbEl === null) return;

    el.style.width = `${width}px`;

    const doc = el.ownerDocument || window.document;

    const handleScrub = (x: number) => {
      window.cancelAnimationFrame(rAF.current);

      rAF.current = window.requestAnimationFrame(() => {
        const rect = el.getBoundingClientRect();

        let percent = (x - rect.left) / width;
        let value = min + (max - min) * percent;

        if (typeof step === 'number') {
          percent = getStandardValue({
            value: percent,
            min: 0,
            max: 1,
            step: 1 / Math.trunc((max - min) / step),
          });
          value = getStandardValue({ value, min, max, step });
        } else {
          percent = clamp(percent, 0, 1);
          value = clamp(value, min, max);
        }

        trackEl.style.setProperty(
          '--value-percent',
          `${(percent * 100).toFixed(2)}%`
        );
        thumbEl.style.setProperty('--left-pos', `${width * percent - 12}px`);

        valueRef.current = value;
        if (onChange) onChange(value);
      });
    };

    const handleScrubStart = () => {
      if (isSliding.current) return;

      isSliding.current = true;
      bindEvents();
    };

    const handleScrubStop = () => {
      if (!isSliding.current) return;

      isSliding.current = false;
      unbindEvents();

      onChangeCommit?.(valueRef.current);
    };

    const handleMouseMove = (e: MouseEvent) => {
      e.stopPropagation();
      handleScrub(e.clientX);
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.stopPropagation();
      handleScrubStart();
      handleMouseMove(e);
    };

    const handleTouchMove = (e: TouchEvent) => {
      e.stopPropagation();
      handleScrub(e.changedTouches[0].clientX);
    };

    const handleTouchStart = (e: TouchEvent) => {
      e.stopPropagation();
      handleScrubStart();
      handleTouchMove(e);
    };

    const bindEvents = () => {
      doc.addEventListener('mousemove', handleMouseMove, { passive: true });
      doc.addEventListener('mouseup', handleScrubStop, { passive: true });

      doc.addEventListener('touchmove', handleTouchMove, { passive: true });
      doc.addEventListener('touchend', handleScrubStop, { passive: true });
    };

    const unbindEvents = () => {
      doc.removeEventListener('mousemove', handleMouseMove);
      doc.removeEventListener('mouseup', handleScrubStop);

      doc.removeEventListener('touchmove', handleTouchMove);
      doc.removeEventListener('touchend', handleScrubStop);
    };

    el.addEventListener('mousedown', handleMouseDown, { passive: true });
    el.addEventListener('touchstart', handleTouchStart, { passive: true });

    return () => {
      el.removeEventListener('mousedown', handleMouseDown);
      el.removeEventListener('touchstart', handleTouchStart);
    };
  }, [max, min, width, onChange, onChangeCommit, step]);

  return (
    <Base ref={ref}>
      <Track ref={trackRef} />
      <Thumb ref={thumbRef} />
    </Base>
  );
};

export default Slider;

const Base = styled.div`
  height: 24px;
  position: relative;
  user-select: none;
`;

const Track = styled.span`
  --value-percent: 0%;

  position: absolute;
  width: inherit;
  height: 4px;
  background: linear-gradient(
    to right,
    var(--color-pink) var(--value-percent),
    var(--color-grey-300) var(--value-percent)
  );
  border-radius: 2px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

const Thumb = styled.span`
  --left-pos: 0px;

  position: absolute;
  width: 24px;
  height: 24px;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-200);
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translate(var(--left-pos), -50%);
  cursor: grab;
`;
