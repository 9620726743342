import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  DndContext,
  DragOverlay,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { moveCell, selectPageCellKeys } from 'features/editor/editorSlice';
import SortableItem from './SortableItem';
import type { DragStartEvent, DragEndEvent } from '@dnd-kit/core';

console.log(React.version);

interface SortableContentsProps {
  pageKey: string;
}

const SortableContents: React.FC<SortableContentsProps> = ({ pageKey }) => {
  const cellKeys = useAppSelector(selectPageCellKeys(pageKey));

  const dispatch = useAppDispatch();

  const [activeKey, setActiveKey] = React.useState<string | null>(null);

  const handleDragStart = (e: DragStartEvent) => {
    setActiveKey(e.active.id);
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    setActiveKey(null);
    if (over === null) return;
    if (active.id !== over.id) {
      const from = cellKeys.indexOf(active.id);
      const to = cellKeys.indexOf(over.id);

      dispatch(moveCell({ pageKey, from, to }));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={[restrictToParentElement]}
    >
      <SortableContext items={cellKeys} strategy={verticalListSortingStrategy}>
        <>
          {cellKeys.map((cellKey) => (
            <SortableItem
              cellKey={cellKey}
              pageKey={pageKey}
              overlay={activeKey === cellKey}
              key={cellKey}
            />
          ))}
          {ReactDOM.createPortal(
            <DragOverlay>
              {activeKey && (
                <SortableItem cellKey={activeKey} pageKey={pageKey} />
              )}
            </DragOverlay>,
            document.body
          )}
        </>
      </SortableContext>
    </DndContext>
  );
};

export default SortableContents;
