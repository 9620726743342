import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useInterval } from 'lib/hooks';
import { selectPageThumbnails } from 'features/editor/editorSlice';

const PageCarousel: React.FC = () => {
  const thumbnails = useAppSelector(selectPageThumbnails);

  const [index, setIndex] = React.useState<number>(0);

  useInterval(() => {
    if (index === thumbnails.length - 1) {
      setIndex(0);
    } else {
      setIndex((prev) => prev + 1);
    }
  }, 2000);

  if (thumbnails[index] === undefined) return null;
  return <Image src={thumbnails[index]} alt={`${index + 1} slide thumbnail`} />;
};

export default PageCarousel;

const Image = styled.img`
  border-radius: 10px;
  width: 100%;
  max-width: 448px;
  max-height: 256px;
`;
