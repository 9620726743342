import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { Dialog } from '@headlessui/react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { useGetVideosQuery } from 'app/services/videoAssets';
import { XIcon } from 'assets/svg';
import { Toast } from 'components/common';
import {
  selectPagesContent,
  selectCurrentAudioToolState,
} from 'features/editor/editorSlice';
import { isOpenPredictedDurationAtom } from 'features/editor/atoms';
import { usePredictedCallback } from 'features/editor/providers';
import { typography } from 'styles';

const PredictedDuration: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'predictedDuration' });

  const [isOpen, setIsOpen] = useAtom(isOpenPredictedDurationAtom);

  const callback = usePredictedCallback();

  const contents = useAppSelector(selectPagesContent);
  const { data: videoAssets } = useGetVideosQuery();

  const { introDuration, ipsDuration } = useAppSelector(
    selectCurrentAudioToolState
  );

  const getDuration = () => {
    let duration = contents.reduce<number>((acc, item) => {
      acc += item.duration;

      if (item.isVideo && videoAssets !== undefined) {
        const videoAsset = videoAssets.find(
          (asset) =>
            asset.videoS3Path.path === item.videoPath &&
            asset.videoS3Path.url === item.videoUrl
        );

        if (videoAsset === undefined) return acc;
        acc += videoAsset.duration;

        return acc;
      } else {
        acc += item.cells.reduce<number>((_acc, _item) => {
          _acc += _item.duration;
          _acc += _item.text.length * 120;
          return _acc;
        }, 0);
      }

      return acc;
    }, 0);

    if (introDuration !== undefined) {
      duration += introDuration;
    }

    if (ipsDuration !== undefined) {
      duration += ipsDuration * (contents.length - 1);
    }

    return duration;
  };

  const { remainDuration } = useGetUserInfoQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data }) => ({
      remainDuration: data ? data.allowedDuration - data.usedDuration : 0,
    }),
  });

  const [isOpenToast, setIsOpenToast] = React.useState<boolean>(false);

  const handleClickYes: React.MouseEventHandler<HTMLButtonElement> = () => {
    setIsOpen(false);

    const duration = getDuration();
    if (remainDuration >= duration) {
      callback?.();
    } else {
      setIsOpenToast(true);
    }
  };

  const getTimeString = () => {
    const duration = getDuration();
    const { hours, minutes, seconds } = getHMS(duration);

    const ret: string[] = [];

    if (hours > 0) {
      ret.push(t('time.hour', { time: hours }));
    }

    if (minutes > 0) {
      ret.push(t('time.minute', { time: minutes }));
    }

    if (seconds > 0) {
      ret.push(t('time.second', { time: seconds }));
    }

    return ret.join(' ');
  };

  const focusRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Dialog
        as={DialogBase}
        open={isOpen}
        onClose={setIsOpen}
        initialFocus={focusRef}
      >
        <Dialog.Overlay as={DialogOverlay} />
        <Box>
          <Title>
            <CloseButton onClick={() => setIsOpen(false)}>
              <XIcon size={24} weight={'bold'} color={'currentColor'} />
            </CloseButton>
          </Title>
          <Content ref={focusRef}>
            <TitleText>{t('title')}</TitleText>
            <BodyText>{t('body', { time: getTimeString() })}</BodyText>
            <ButtonBox>
              <PinkButton onClick={handleClickYes}>
                {t('button.yes')}
              </PinkButton>
              <OutlineButton onClick={() => setIsOpen(false)}>
                {t('button.no')}
              </OutlineButton>
            </ButtonBox>
          </Content>
        </Box>
      </Dialog>
      <Toast
        in={isOpenToast}
        timeout={1500}
        severity={'error'}
        message={t('toast')}
        onClose={() => setIsOpenToast(false)}
      />
    </>
  );
};

export default PredictedDuration;

const getHMS = (
  ms: number
): {
  hours: number;
  minutes: number;
  seconds: number;
} => {
  const hours = Math.floor((ms / (60 * 60 * 1000)) % 24);
  const minutes = Math.floor((ms / (60 * 1000)) % 60);
  const seconds = Math.floor((ms / 1000) % 60);

  return {
    hours,
    minutes,
    seconds,
  };
};

const DialogBase = styled.div`
  position: fixed;
  z-index: var(--popup-index);
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DialogOverlay = styled.div`
  position: fixed;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: rgba(51, 51, 51, 0.8);
  -webkit-tap-highlight-color: transparent;
`;

const Box = styled.div`
  width: calc(100% - 32px);
  max-width: 440px;
  padding: 24px;
  background-color: var(--color-white);
  box-shadow: 0px 4px 24px rgba(51, 51, 51, 0.12);
  border-radius: 10px;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  outline: none;
  border: none;
  padding: 0;
  color: var(--color-black);
  display: inline-flex;
  background: transparent;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitleText = styled.span`
  ${typography.title.large}
  margin-bottom: 16px;
`;

const BodyText = styled.span`
  ${typography.body.large}
  word-break: keep-all;
  text-align: center;
  margin-bottom: 16px;
`;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button + button {
    margin-left: 16px;
  }
`;

const PinkButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  flex: 1;
  outline: none;
  padding: 0;
  border: none;

  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${typography.label.large}

  color: var(--color-white);
  background: var(--color-pink);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;

const OutlineButton = styled.button`
  flex: 1;
  padding: 0;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  color: var(--color-black);
  word-break: keep-all;
  cursor: pointer;

  ${typography.label.large}
`;
