import * as Sentry from '@sentry/react';
import { setCredentials } from 'features/auth/authSlice';

export const sentryEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (setCredentials.match(action)) {
      return null;
    }

    return action;
  },
});
