import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { KakaoTalkAuthButton, GoogleAuthButton } from 'features/auth';
import { typography } from 'styles';
import AuthTextField from './AuthTextField';
import OutlineButton from './OutlineButton';

interface LoginData {
  email: string;
  password: string;
}

interface LoginFormProps {
  onSubmit: (data: LoginData) => Promise<void>;
  error: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, error }) => {
  const { t } = useTranslation('auth', { keyPrefix: 'login' });

  const { register, handleSubmit } = useForm<LoginData>();

  return (
    <Content>
      <Title>{t('title')}</Title>
      {process.env.ONAIR_PLATFORM_TYPE === 'onair' && (
        <>
          <SocialBox>
            <ButtonBox>
              <KakaoTalkAuthButton>{t('button.kakao')}</KakaoTalkAuthButton>
            </ButtonBox>
            <ButtonBox>
              <GoogleAuthButton>{t('button.google')}</GoogleAuthButton>
            </ButtonBox>
          </SocialBox>
          <Divider />
        </>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AuthTextField
          label={t('placeholder.email')}
          autoComplete={'email'}
          {...register('email', { required: true })}
          error={error}
          required
        />
        <AuthTextField
          label={t('placeholder.password')}
          autoComplete={'current-password'}
          type={'password'}
          {...register('password', { required: true })}
          error={error}
          required
        />
        <AnchorBox>
          <Anchor to={'/auth/reset-password'}>{t('resetPassword')}</Anchor>
          {process.env.ONAIR_PLATFORM_TYPE !== 'onair' && (
            <Anchor to={'/auth/register'}>회원가입</Anchor>
          )}
        </AnchorBox>
        <OutlineButton type={'submit'}>{t('button.onair')}</OutlineButton>
      </Form>
    </Content>
  );
};

export default LoginForm;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.p`
  ${typography.display.small}
  text-align: center;
  margin: 0;
`;

const SocialBox = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonBox = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 16px;
`;

const Divider = styled.hr`
  width: calc(100% - 32px);
  height: 1px;
  background: #e7e7e7;
  border: none;
  margin-block: 24px;

  @media (min-width: 520px) {
    width: 392px;
  }
`;

const Form = styled.form`
  margin-top: 24px;
`;

const Anchor = styled(Link)`
  ${typography.label.medium}
  color: var(--color-grey-600);
  text-decoration: underline;
`;

const AnchorBox = styled.div`
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 520px) {
    width: 392px;
  }
`;
