import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { Toast } from 'components/common';
import {
  selectPreviewVideoErrorToast,
  setPreviewVideoErrorToast,
} from 'features/editor/editorSlice';

const PreviewVideoErrorToast: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'timeline' });

  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(selectPreviewVideoErrorToast);

  return (
    <Toast
      in={isOpen}
      onClose={() => dispatch(setPreviewVideoErrorToast(false))}
      timeout={5000}
      severity={'error'}
      message={t('toast.previewVideo')}
    />
  );
};

export default PreviewVideoErrorToast;
