import * as React from 'react';
import styled from '@emotion/styled';

interface ToggleSwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ value, onChange }) => {
  return (
    <Base on={value} onClick={() => onChange(!value)}>
      <Text>ON</Text>
      <Text>OFF</Text>
      <Thumb on={value} />
    </Base>
  );
};

export default ToggleSwitch;

const Base = styled.div<{ on: boolean }>`
  width: 48px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ on }) =>
    on ? 'var(--color-success)' : 'var(--color-grey-500)'};
  position: relative;
  display: flex;
  align-items: center;
  transition: background-color 225ms;
  cursor: pointer;
`;

const Text = styled.span`
  flex: 1;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-white);
  user-select: none;
  text-align: center;
`;

const Thumb = styled.span<{ on: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-white);
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 150ms;
  transform: translateX(${({ on }) => (on ? '24px' : '0px')});
`;
