import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';
import { getGradeInfo } from './utils';
import SourceNotifyPermission from './SourceNotifyPermission';
import type { GradeType } from './constants';

interface PermissionCardProps {
  cardGrade: GradeType;
}

const PermissionCard: React.FC<PermissionCardProps> = ({ cardGrade }) => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.productInfo.card',
  });

  const cardMembership = getGradeInfo(cardGrade);

  const { grade, permission } = cardMembership;

  return (
    <Base data-used={false}>
      <TitleBox>
        <Title>{grade}</Title>
      </TitleBox>
      <OptionsBox>
        {permission.posting && (
          <OptionsItem>
            <OptionsText>
              {t('permission.posting', { project: permission.posting })}
            </OptionsText>
          </OptionsItem>
        )}
        {permission.sourceNotify && <SourceNotifyPermission />}
        {permission.sourceRequire && (
          <OptionsItem>
            <OptionsText color={'var(--color-danger)'}>
              {t('permission.sourceRequire')}
            </OptionsText>
          </OptionsItem>
        )}
        {permission.broadcast && (
          <OptionsItem>
            <OptionsText>{t('permission.broadcast')}</OptionsText>
          </OptionsItem>
        )}
        {permission.advertising && (
          <OptionsItem>
            <OptionsText>{t('permission.advertising')}</OptionsText>
          </OptionsItem>
        )}
        {permission.commercial !== undefined && (
          <OptionsItem>
            <OptionsText>
              {permission.commercial === true ? (
                t('permission.commercial.true')
              ) : (
                <Trans
                  t={t}
                  i18nKey={'permission.commercial.false'}
                  components={{ bold: <strong /> }}
                />
              )}
            </OptionsText>
          </OptionsItem>
        )}
        {permission.enterprise !== undefined && (
          <OptionsItem>
            <OptionsText>
              {permission.enterprise === true ? (
                t('permission.enterprise.true')
              ) : (
                <Trans
                  t={t}
                  i18nKey={'permission.enterprise.false'}
                  components={{ bold: <strong /> }}
                ></Trans>
              )}
            </OptionsText>
          </OptionsItem>
        )}
      </OptionsBox>
    </Base>
  );
};

export default PermissionCard;

const Base = styled.div`
  width: 100%;
  height: 100%;
  min-height: 212px;

  background-color: var(--color-white);
  border: 1px solid var(--color-grey-100);
  border-radius: 10px;
  box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleBox = styled.div`
  flex: none;
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  padding: 8px;
  background-color: var(--color-grey-100);
`;

const Title = styled.div`
  ${typography.title.medium}
  color: var(--color-black);
`;

const OptionsBox = styled.div`
  flex: 1 0 auto;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 16px;
  padding: 24px;
`;

const OptionsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
`;

const OptionsText = styled.span<{ color?: string }>`
  ${typography.body.large}
  color: ${({ color }) => (color !== undefined ? color : 'var(--color-black)')};
  display: inline-block;
  align-items: center;
  justify-content: center;
  white-space: pre;
  text-align: center;
`;
