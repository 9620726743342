import * as React from 'react';
import styled from '@emotion/styled';
import { Toast } from 'components/common';
import { typography } from 'styles';
import GoRegisterButton from './GoRegisterButton';
import type { FeatureContentProps } from './FeatureContent';

const FeatureContent: React.FC<FeatureContentProps> = ({
  tag,
  title,
  body,
  register,
  stepNumber,
  reverse,
}) => {
  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  return (
    <>
      <Step>
        <StepNumber>{stepNumber}</StepNumber>
        <StepLine reverse={reverse} />
      </Step>
      <Summary reverse={reverse}>
        <FeatureTag>{tag}</FeatureTag>
        <StepTitle>{title}</StepTitle>
        <StepBody>{body}</StepBody>
        <GoRegisterButton
          onClick={(e) => {
          }}
        >
          {register}
        </GoRegisterButton>
        <Toast
          in={openToast}
          onClose={toggleOpenToast}
          timeout={1500}
          message={'Coming soon'}
          severity={'normal'}
        />
      </Summary>
    </>
  );
};

export default FeatureContent;

const Summary = styled.div<{ reverse: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1;
  -webkit-order: ${({ reverse }) => (reverse ? 0 : 2)};
  order: ${({ reverse }) => (reverse ? 0 : 2)};
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  -webkit-order: 1;
  order: 1;
`;

const StepLine = styled.hr<{ reverse: boolean }>`
  margin: 0;
  padding: 0;
  height: 2px;
  border: none;
  background: var(--color-black);
  flex: 1;
  height: 2px;
  margin: 0 4px;
  -webkit-order: ${({ reverse }) => (reverse ? 0 : 2)};
  order: ${({ reverse }) => (reverse ? 0 : 2)};
`;

const StepNumber = styled.span`
  font-size: 80px;
  font-weight: 700;
  font-style: italic;
  line-height: 1.5;
  color: var(--color-black);
  -webkit-order: 1;
  order: 1;
`;

const FeatureTag = styled.span`
  ${typography.title.large}
  color: var(--color-pink);
  padding: 2px 16px;
  border: 2px solid var(--color-pink);
  border-radius: 20px;
`;

const StepTitle = styled.span`
  ${typography.display.medium}
  word-break: keep-all;
  color: var(--color-black);
`;

const StepBody = styled.span`
  ${typography.headline.small}
  color: var(--color-grey-800);
  white-space: pre;
`;
