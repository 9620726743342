import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { kmp } from 'lib/utils';
import { useGetVoiceModelQuery } from 'app/services/voiceModel';
import { voiceModelCompare, sort } from 'features/editor/utils';
import ModelItem from './VoiceModelItem';
import SearchBar from './SearchBar';

const VoiceModelSelect: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'voiceModelSelect' });

  const [searchWord, setSearchWord] = React.useState<string>('');

  const { data: voiceModels } = useGetVoiceModelQuery();

  const handleChangeSearchWord: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setSearchWord(e.target.value);
  };

  return (
    <Base>
      <Title>{t('title.default')}</Title>
      <SearchBar
        value={searchWord}
        placeholder={t('placeholder')}
        onChange={handleChangeSearchWord}
      />
      <ModelList>
        {sort(voiceModels, voiceModelCompare)
          ?.filter((model) => {
            if (searchWord === '') return true;
            if (
              kmp(model.displayName.toUpperCase(), searchWord.toUpperCase()) >
              -1
            )
              return true;
            return false;
          })
          .map((model) => (
            <ModelItem
              name={model.name}
              modelColor={model.defaultColor}
              displayName={model.displayName}
              tags={model.tags}
              isBest={model.isBest}
              isNew={model.isNew}
              language={model.language}
              key={model.key}
            />
          ))}
      </ModelList>
    </Base>
  );
};

export default VoiceModelSelect;

const Base = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 16px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  padding: 0 16px;
`;

const ModelList = styled.ul`
  list-style: none;
  padding: 16px;
  margin: 0;
  height: calc(100% - 120px);
  overflow-y: auto;
  transform: translateZ(1px);
`;
