import * as React from 'react';
import styled from '@emotion/styled';

interface ListItemProps {
  wordKey: string;
  defaultValue: string;
}

const ListItem = React.forwardRef<HTMLInputElement, ListItemProps>(
  ({ wordKey, defaultValue }, ref) => {
    return (
      <Base>
        <Label>
          <LabelText>{wordKey}</LabelText>
          <Input defaultValue={defaultValue} ref={ref} />
        </Label>
      </Base>
    );
  }
);

export default ListItem;

const Base = styled.li`
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: 1px solid var(--color-grey-300);
  background: var(--color-white);

  & + & {
    margin-top: 16px;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 4px 16px;
`;

const LabelText = styled.span`
  font-size: 12px;
  line-height: 1.4;
  color: var(--color-grey-600);
`;

const Input = styled.input`
  font-size: 16px;
  line-height: 1.4;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  color: var(--color-black);
`;
