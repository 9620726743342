import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Listbox } from '@headlessui/react';
import ClickAwayListener from 'react-click-away-listener';
import { CaretDownIcon } from 'assets/svg';
import { Toast } from 'components/common';
import { DEV_PRIVATEWRAPPER } from 'lib/devUtils';
import { LOCALE_CODES, codeToLang } from 'locales/utils';
import { typography } from 'styles';
import HeaderLogo from './HeaderLogo';

const TTS_URL = 'https://feature.onairstudio.ai/tts';
const VIDEO_URL = 'https://feature.onairstudio.ai/video';
const STV_URL = 'https://feature.onairstudio.ai/human';

const anchorElementGuard = (el: HTMLElement): el is HTMLAnchorElement => {
  if (el.tagName === 'A') return true;
  return false;
};

const Header: React.FC = () => {
  const { t, i18n } = useTranslation('main', { keyPrefix: 'header' });

  const [openProduct, setOpenProduct] = React.useState<boolean>(false);
  const toggleOpenProduct = () => setOpenProduct((prev) => !prev);

  const handleClickReviewAnchor: React.MouseEventHandler<HTMLAnchorElement> = (
    e
  ) => {
    e.preventDefault();
    const targetEl = e.target as HTMLElement;
    if (!anchorElementGuard(targetEl)) return;

    const element = document.getElementById(targetEl.hash.slice(1));
    if (element === null) return;

    window.scrollTo({
      top: element.offsetTop - 64,
      behavior: 'smooth',
    });
  };

  const [isOpenLocale, setIsOpenLocale] = React.useState<boolean>(false);

  const handleMouseEnter: React.MouseEventHandler<HTMLElement> = () =>
    setIsOpenLocale(true);

  const handleMouseLeave: React.MouseEventHandler<HTMLElement> = () =>
    setIsOpenLocale(false);

  const handleClickListboxOption: React.MouseEventHandler<HTMLElement> = () => {
    setIsOpenLocale(false);
  };

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  return (
    <Box>
      <HeaderLogo />
      <AnchorBox>
        <ProductMenu onClick={toggleOpenProduct}>
          {t('product.name')}
          {openProduct && (
            <ClickAwayListener onClickAway={toggleOpenProduct}>
              <ProductMenuItems>
                <ProductMenuItem
                  href={TTS_URL}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  {t('product.items.tts')}
                </ProductMenuItem>
                <ProductMenuItem
                  href={VIDEO_URL}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  {t('product.items.video')}
                </ProductMenuItem>
                <ProductMenuItem
                  href={STV_URL}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  {t('product.items.stv')}
                </ProductMenuItem>
              </ProductMenuItems>
            </ClickAwayListener>
          )}
        </ProductMenu>
        <Anchor href={'#usage'} onClick={handleClickReviewAnchor}>
          {t('usage')}
        </Anchor>
        <Anchor
          href={'https://lionrocket.ai'}
          rel={'noreferrer'}
          target={'_blank'}
        >
          {t('company')}
        </Anchor>
      </AnchorBox>
      <AuthBox>
        <RegisterAnchor to={'/auth/register'}>
          {t('register')}
        </RegisterAnchor>
        <RouterAnchor to={'/auth/login'}>
          {t('login')}
        </RouterAnchor>
        <Toast
          in={openToast}
          onClose={toggleOpenToast}
          timeout={1500}
          message={'Coming soon'}
          severity={'normal'}
        />
      </AuthBox>
      <DEV_PRIVATEWRAPPER>
        <Listbox
          as={ListboxBase}
          value={i18n.language}
          onChange={i18n.changeLanguage}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Listbox.Button as={ListboxButton} isOpen={isOpenLocale}>
            <span>{codeToLang(i18n.language)}</span>
            <CaretDownIcon size={16} weight={'fill'} color={'currentColor'} />
          </Listbox.Button>
          {isOpenLocale && (
            <Listbox.Options static as={ListboxOptions}>
              {LOCALE_CODES.map((code) => (
                <Listbox.Option as={React.Fragment} value={code} key={code}>
                  {({ selected }) => (
                    <ListboxOption
                      isSelected={selected}
                      onClick={handleClickListboxOption}
                    >
                      {codeToLang(code)}
                    </ListboxOption>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          )}
        </Listbox>
      </DEV_PRIVATEWRAPPER>
    </Box>
  );
};

export default Header;

const Box = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 32px);

  @media (min-width: 1152px) {
    width: 1120px;
  }
`;

const AnchorBox = styled.div`
  flex: 1 auto;
  margin-left: 40px;
  user-select: none;
`;

const RouterAnchor = styled(Link)`
  text-decoration: none;
  color: var(--color-pink-500);
  border-radius: 10px;
  ${typography.title.medium}
  background: transparent;
  padding: 8px 16px;
`;

const Anchor = styled.a`
  text-decoration: none;
  color: var(--color-black);
  border-radius: 10px;
  ${typography.body.large}
  background: transparent;
  padding: 8px 16px;

  &:hover {
    background: rgba(51, 51, 51, 0.08);

    @media (hover: none) {
      background: rgba(51, 51, 51, 0.16);
    }
  }

  &:active {
    background: rgba(51, 51, 51, 0.16);
  }
`;

const ProductMenu = styled.div`
  color: var(--color-black);
  border-radius: 10px;
  ${typography.body.large}
  background: transparent;
  padding: 8px 16px;
  display: inline;

  position: relative;
  cursor: pointer;

  &:hover {
    background: rgba(51, 51, 51, 0.08);

    @media (hover: none) {
      background: rgba(51, 51, 51, 0.16);
    }
  }

  &:active {
    background: rgba(51, 51, 51, 0.16);
  }
`;

const ProductMenuItems = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(51, 51, 51, 0.16);
  background: var(--color-white);
  border: 1px solid var(--color-grey-200);
  padding: 10px 0;
  cursor: default;
`;

const ProductMenuItem = styled.a`
  text-decoration: none;
  width: 100%;
  height: 36px;
  color: var(--color-black);
  ${typography.body.large}
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  &:hover {
    background: rgba(51, 51, 51, 0.08);

    @media (hover: none) {
      background: rgba(51, 51, 51, 0.16);
    }
  }

  &:active {
    background: rgba(51, 51, 51, 0.16);
  }
`;

const AuthBox = styled.div`
  display: flex;
  gap: 23px;
`;

const RegisterAnchor = styled(Link)`
  --hover-gradient: linear-gradient(
    rgba(255, 255, 255, 0.16),
    rgba(255, 255, 255, 0.16)
  );
  --active-gradient: linear-gradient(
    rgba(255, 255, 255, 0.32),
    rgba(255, 255, 255, 0.32)
  );

  text-decoration: none;
  border-radius: 10px;
  background: var(--color-gradient);
  box-shadow: 0px 12px 24px rgba(172, 0, 0, 0.2);
  color: var(--color-white);
  ${typography.title.medium}
  padding: 8px 16px;

  &:hover {
    background: var(--hover-gradient), var(--color-gradient);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-gradient);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-gradient);
  }
`;

const ListboxBase = styled.div`
  cursor: pointer;
  outline: 0;
  border: none;
  width: 88px;
`;

const ListboxButton = styled.button<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  border: 0;
  padding: 0;
  color: ${(props) =>
    props.isOpen ? 'var(--color-pink)' : 'var(--color-black)'};
  background: transparent;
  margin: 0 auto;
  cursor: pointer;
  span {
    ${typography.label.large}
  }
  svg {
    margin-top: 2px;
    margin-left: 2px;
    transform: ${(props) => props.isOpen && 'rotate(180deg)'};
  }
`;

const ListboxOptions = styled.ul`
  position: absolute;
  z-index: 2;
  margin: 0;
  padding: 8px 0;
  list-style: none;
  border: 1px solid var(--color-grey-100);
  box-sizing: border-box;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  background: var(--color-white);
  outline: 0;
  cursor: pointer;
`;

const ListboxOption = styled.li<{ isSelected: boolean }>`
  padding: 6px 21.5px;
  ${(props) =>
    props.isSelected ? typography.label.large : typography.body.medium}
  color: var(--color-black);
  background: var(--color-white);
  &:hover {
    background: rgba(51, 51, 51, 0.08);

    @media (hover: none) {
      background: rgba(51, 51, 51, 0.16);
    }
  }

  &:active {
    background: rgba(51, 51, 51, 0.16);
  }
`;
