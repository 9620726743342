import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useModifyProjectNameMutation } from 'app/services/projects';
import { Toast } from 'components/common';
import { typography } from 'styles';

interface ProjectNameInputProps {
  uuid: string;
  name: string;
  isEdit: boolean;
  toggleEdit: () => void;
}

const ProjectNameInput: React.FC<ProjectNameInputProps> = ({
  uuid,
  name,
  isEdit,
  toggleEdit,
}) => {
  const { t } = useTranslation('client', { keyPrefix: 'projectList' });

  const [modifyProjectName] = useModifyProjectNameMutation();

  const ref = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    const el = ref.current;
    if (el === null) return;
    if (!isEdit) return;

    el.focus();
  }, [isEdit]);

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const resetName = () => {
    const el = ref.current;
    if (el === null) return;

    el.value = name;
  };

  const handleChange = (newValue: string) => {
    if (newValue.indexOf('.') > -1) {
      toggleOpenToast();
      resetName();
      return;
    }

    modifyProjectName({ uuid, name: newValue });
  };

  const handleBlur = () => {
    toggleEdit();

    const el = ref.current;
    if (el === null) return;

    if (el.value === name) return;
    handleChange(el.value);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const el = ref.current;
    if (el === null) return;

    if (e.key === 'Enter') {
      el.blur();
      return;
    }

    if (e.key === 'Escape') {
      toggleEdit();

      resetName();
      return;
    }
  };

  return (
    <Base>
      <Input
        placeholder={t('placeholder.name')}
        defaultValue={name}
        disabled={!isEdit}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        ref={ref}
      />
      <Toast
        in={openToast}
        timeout={1500}
        onClose={toggleOpenToast}
        message={t('error.name')}
        severity={'error'}
      />
    </Base>
  );
};

export default ProjectNameInput;

const Base = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: text;
`;

const Input = styled.input`
  width: 330px;
  outline: none;
  padding: 0;
  border-width: 1px;
  border-style: none none solid none;
  border-color: var(--color-white);
  background-color: var(--color-white);
  ${typography.body.large}
  color: var(--color-black);
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    border-color: var(--color-grey-300);
    width: 100%;
    margin-right: 0;
  }

  &:disabled {
    opacity: 1;
    color: var(--color-black);
    -webkit-text-fill-color: var(--color-black);
  }

  &::placeholder {
    color: var(--color-grey-700);
  }
`;
