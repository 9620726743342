import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from 'react-click-away-listener';
import styled from '@emotion/styled';
import { SlidersIcon } from 'assets/svg';
import { ChangeToneModal } from 'features/editor/Modals';

const ToolbarChangeToneButton: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const [openDropdown, setOpenDropdown] = React.useState<boolean>(false);

  const [modifyType, setModifyType] = React.useState<
    'all' | 'page' | undefined
  >(undefined);

  const toggleOpenDropdown = () => setOpenDropdown((prev) => !prev);

  const handleDropDownClose = () => {
    setOpenDropdown(false);
  };

  const handleClose = () => {
    setModifyType(undefined);
  };

  const handleClickDropdownItem: React.MouseEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.stopPropagation();
    const target = e.currentTarget;
    if (target === undefined) return;
    const type = target.dataset.type;
    if (type !== 'all' && type !== 'page') return;
    setModifyType(type);
  };

  const baseRef = React.useRef<HTMLButtonElement | null>(null);
  const [targetEl, setTargetEl] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const baseEl = baseRef.current;
    if (baseEl === null) return;
    if (targetEl === null) return;

    const baseRect = baseEl.getBoundingClientRect();

    targetEl.style.cssText = `
      top: ${baseRect.bottom}px;
      left: ${baseRect.left + (baseRect.width - targetEl.offsetWidth) / 2}px;
    `;
  }, [targetEl]);

  return (
    <>
      <Button onClick={toggleOpenDropdown} ref={baseRef}>
        <SlidersIcon size={16} weight={'fill'} color={'currentColor'} />
        <span>{t('toolbar.changeTone')}</span>
      </Button>
      {openDropdown && (
        <ClickAwayListener onClickAway={handleDropDownClose}>
          <Dropdown ref={setTargetEl}>
            <DropdownItem data-type={'page'} onClick={handleClickDropdownItem}>
              <span>{t('toolbar.changeTonePage')}</span>
            </DropdownItem>
            <DropdownItem data-type={'all'} onClick={handleClickDropdownItem}>
              <span>{t('toolbar.changeToneAll')}</span>
            </DropdownItem>
          </Dropdown>
        </ClickAwayListener>
      )}
      <ChangeToneModal
        open={modifyType === 'all'}
        onClose={handleClose}
        modifyType={'all'}
      />
      <ChangeToneModal
        open={modifyType === 'page'}
        onClose={handleClose}
        modifyType={'page'}
      />
    </>
  );
};

export default ToolbarChangeToneButton;

const Button = styled.button`
  outline: none;
  border: none;
  padding: 0 8px;
  height: 56px;
  background: var(--color-white);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 1.4;
  color: var(--color-grey-700);
  cursor: pointer;

  span {
    white-space: nowrap;
    min-width: 96px;
  }
`;

const Dropdown = styled.div`
  position: fixed;
  height: fit-content;
  border-radius: 10px;
  background: var(--color-white);
  border: 1px solid var(--color-grey-50);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DropdownItem = styled.div`
  width: 100%;
  height: 32px;
  background: var(--color-white);
  color: var(--color-black);
  line-height: 1.4;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;

  &:hover {
    background: linear-gradient(rgba(51, 51, 51, 0.08), rgba(51, 51, 51, 0.08)),
      var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }
`;
