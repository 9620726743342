import * as React from 'react';
import { useAudio } from 'react-use';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AudioManager from 'lib/AudioManager';
import { TailSpinIcon, PlayCircleIcon, StopCircleIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import {
  selectTimelineEditableCell,
  fetchMergeMlModelText,
  selectChannelId,
} from 'features/editor/editorSlice';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
} from 'features/editor/providers';

const CellPlayButton: React.FC = () => {
  const {
    text,
    modelName: name,
    volume,
    speed,
  } = useAppSelector(selectTimelineEditableCell);

  const dispatch = useAppDispatch();

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const prevKey = React.useRef<string | null>(null);

  const channelId = useAppSelector(selectChannelId);

  const handleClickPlay: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    if (text.trim().length === 0) return;
    if (channelId === undefined) return;

    const el = ref.current;
    if (el === null) return;

    const key = `${name}-${text}-${volume}-${speed}`;

    if (prevKey.current === key) {
      if (state.paused) {
        editorAudioPlay(controls);
      } else {
        editorAudioPause();
      }
      return;
    }

    try {
      setMergeLoading(true);
      const blob = await dispatch(
        fetchMergeMlModelText({ channelId, text, volume, speed, name })
      ).unwrap();

      prevKey.current = key;
      const url = AudioManager.createObjectUrl(blob);
      el.src = url;
    } catch (error) {
      setMergeLoading(false);
      console.error(error);
    }
  };

  return (
    <IconButton size={20} radius={4} onClick={handleClickPlay}>
      {mergeLoading ? (
        <TailSpinIcon width={20} height={20} />
      ) : (
        <>
          {state.paused ? (
            <PlayCircleIcon size={20} color={'currentColor'} weight={'fill'} />
          ) : (
            <StopCircleIcon size={20} color={'currentColor'} weight={'fill'} />
          )}
        </>
      )}
      <>{element}</>
    </IconButton>
  );
};

export default CellPlayButton;
