type UNICODE_TUPLE = [start: number, end: number];

const KOREAN_UNICODE: UNICODE_TUPLE = [0xac00, 0xd7af];
const ENGLISH_LOwER_UNICODE: UNICODE_TUPLE = [0x0061, 0x007a];
const ENGLISH_UPPER_UNICODE: UNICODE_TUPLE = [0x0041, 0x005a];

const collatorCompare = new Intl.Collator(['ko', 'en']).compare;

const isInRange = (code: number, range: UNICODE_TUPLE): boolean => {
  if (code >= range[0] && code <= range[1]) {
    return true;
  }
  return false;
};

const isKorean = (code: number) => isInRange(code, KOREAN_UNICODE);
const isEnglishLower = (code: number) => isInRange(code, ENGLISH_LOwER_UNICODE);
const isEnglishUpper = (code: number) => isInRange(code, ENGLISH_UPPER_UNICODE);

const isInRule = (str: string) => {
  const chCode = str.charCodeAt(0);
  if (isKorean(chCode)) return true;
  if (isEnglishUpper(chCode)) return true;
  if (isEnglishLower(chCode)) return true;
  return false;
};

const compareStr = (s1: string, s2: string) => {
  if (isInRule(s1)) {
    if (isInRule(s2)) {
      return collatorCompare(s1, s2);
    }

    return -1;
  }

  if (isInRule(s2)) return 1;
  return s1.localeCompare(s2);
};

const compareDate = (d1?: Date, d2?: Date) => {
  if (d1 === undefined && d2 === undefined) return 0;
  if (d1 === undefined) return 1;
  if (d2 === undefined) return -1;

  if (d1 === d2) return 0;
  if (d1 > d2) return -1;
  if (d1 < d2) return 1;

  return 0;
};

interface SortModel {
  isBest: boolean;
  isNew: boolean;
  str: string;
  create?: Date;
}

export const modelCompare = <T extends SortModel>(prev: T, next: T) => {
  // isNew > isBest > Date > string

  if (prev.isNew && next.isNew) {
    const orderDate = compareDate(prev.create, next.create);
    const orderStr = compareStr(prev.str, next.str);
    return orderDate !== 0 ? orderDate : orderStr;
  }
  if (prev.isNew && !next.isNew) return -1;
  if (next.isNew) return 1;

  if (prev.isBest && next.isBest) {
    const orderDate = compareDate(prev.create, next.create);
    const orderStr = compareStr(prev.str, next.str);
    return orderDate !== 0 ? orderDate : orderStr;
  }
  if (prev.isBest && !next.isBest) return -1;
  if (next.isBest) return 1;

  const orderDate = compareDate(prev.create, next.create);
  return orderDate !== 0 ? orderDate : prev.str.localeCompare(next.str);
};

interface VoiceModelLike {
  isBest: boolean;
  isNew: boolean;
  displayName: string;
  createDate: string;
}

export const voiceModelCompare = <T extends VoiceModelLike>(
  prev: T,
  next: T
) => {
  return modelCompare(
    {
      isBest: prev.isBest,
      isNew: prev.isNew,
      str: prev.displayName,
      create: new Date(prev.createDate),
    },
    {
      isBest: next.isBest,
      isNew: next.isNew,
      str: next.displayName,
      create: new Date(next.createDate),
    }
  );
};

interface VideoModelLike {
  isBest?: boolean;
  isNew?: boolean;
  displayName: string;
  createDate?: string;
}

export const videoModelCompare = <T extends VideoModelLike>(
  prev: T,
  next: T
) => {
  return modelCompare(
    {
      isBest: prev.isBest ?? false,
      isNew: prev.isNew ?? false,
      str: prev.displayName,
      create: prev.createDate ? new Date(prev.createDate) : undefined,
    },
    {
      isBest: next.isBest ?? false,
      isNew: next.isNew ?? false,
      str: next.displayName,
      create: next.createDate ? new Date(next.createDate) : undefined,
    }
  );
};

export const sort = <T>(
  arr: T[] | undefined,
  compare: (prev: T, next: T) => number
) => {
  if (arr === undefined) return undefined;

  const temp = [...arr].sort(compare);

  return temp;
};

export class ProjectUpdateError extends Error {
  constructor(payload: string, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ProjectUpdateError);
    }

    this.name = 'ProjectUpdateError';
    this.message = payload;
  }
}
