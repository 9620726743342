import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { XIcon, ArrowLeftIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import ProgressIndicator from './ProgressIndicator';

interface DownloadProgressProps {
  title: string;
  titleDone: string;
  progress: number;
  type: 'audio' | 'video';
  carousel?: React.ReactNode;
  onClose: () => void;
  onEnded: () => void;
  onDownload: () => void;
  onClickCopy: () => void;
}

const DownloadProgress: React.FC<DownloadProgressProps> = ({
  title,
  titleDone,
  progress,
  type,
  carousel,
  onClose,
  onEnded,
  onDownload,
  onClickCopy,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'downloadTemplate' });

  const downloadComplete = progress === 1;

  const handleClickClose = () => {
    if (!downloadComplete) {
      onClose();
    }
    onEnded();
  };

  const handleClickDownload = () => {
    onDownload();
    onEnded();
  };

  const { data } = useGetUserInfoQuery();

  return (
    <Base>
      <TitleBox alignRight={downloadComplete}>
        <IconButton size={24} radius={6} onClick={handleClickClose}>
          {downloadComplete ? (
            <XIcon size={24} weight={'bold'} />
          ) : (
            <ArrowLeftIcon size={24} weight={'bold'} />
          )}
        </IconButton>
        {!downloadComplete && <HeadText>{t('head.text')}</HeadText>}
      </TitleBox>
      <Title>{downloadComplete ? titleDone : title}</Title>
      {carousel && <ImageCarouselBox>{carousel}</ImageCarouselBox>}
      <ProgressBox>
        <ProgressInfoBox>
          <span>
            {t('body.progress', {
              percent: (progress * 100).toFixed(0),
            })}
          </span>
        </ProgressInfoBox>
        <ProgressIndicator progress={progress} />
      </ProgressBox>
      <DownloadBox
        style={{ visibility: progress === 1 ? 'visible' : 'hidden' }}
      >
        {data?.useBillingService !== true && (
          <DownloadHelperText>
            {t('body.download', { output: t(type) })}
          </DownloadHelperText>
        )}
        <DownloadButtonBox>
          {data?.useBillingService !== true && (
            <CopyButton onClick={onClickCopy}>
              {t('button.copyright')}
            </CopyButton>
          )}
          <DownloadButton onClick={handleClickDownload}>
            {t('button.download')}
          </DownloadButton>
        </DownloadButtonBox>
      </DownloadBox>
    </Base>
  );
};

export default DownloadProgress;

const Base = styled.div`
  min-height: 344px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-white);
  border-radius: 10px;
  width: calc(100% - 32px);
  max-width: 684px;
`;

const TitleBox = styled.div<{ alignRight: boolean }>`
  padding: 24px 20px;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'flex-start'};

  button {
    color: var(--color-black);
  }
`;

const HeadText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  margin-left: 13px;
`;

const Title = styled.span`
  margin: 16px 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  color: var(--color-black);
`;

const ImageCarouselBox = styled.div`
  width: calc(100% - 32px);
  max-width: 448px;
  max-height: 256px;
`;

const ProgressBox = styled.div`
  width: calc(100% - 32px);
  max-width: 448px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 32px;
`;

const ProgressInfoBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  span:first-of-type {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.4;
    color: var(--color-pink);
  }

  span:last-of-type {
    font-size: 14px;
    line-height: 1.4;
    color: var(--color-grey-700);
  }
`;

const DownloadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  width: calc(100% - 32px);
  max-width: 448px;
`;

const DownloadHelperText = styled.span`
  width: 100%;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-black);
  margin-bottom: 8px;
`;

const DownloadButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DownloadButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  outline: none;
  padding: 0 16px;
  border: none;

  flex: 1;

  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;

  position: relative;
  z-index: 1;

  color: var(--color-white);
  background: var(--color-pink);

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;

const CopyButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  outline: none;
  padding: 0 16px;
  border: 1px solid var(--color-black);

  flex: 1;

  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;

  color: var(--color-black);
  background: var(--color-white);

  &:hover {
    background: var(--hover-gradient), var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-white);
  }
`;
