import * as React from 'react';
import styled from '@emotion/styled';
import CellPlayButton from './CellPlayButton';

const CellControlBox: React.FC = () => {
  return (
    <ControlBox>
      <CellPlayButton />
    </ControlBox>
  );
};

export default CellControlBox;

const ControlBox = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 4px;

  button {
    color: var(--color-grey-700);
  }
`;
