import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import { ArrowLeftIcon, ArrowRightIcon } from 'assets/svg';
import Carousel1Image from 'assets/img/main-carousel-1.png';
// import Carousel2Image from 'assets/img/main-carousel-2.png';
import Carousel3Image from 'assets/img/main-carousel-3.png';
import CarouselSlideCard from './CarouselSlideCard';
import type { KeenSliderPlugin } from 'keen-slider/react';

const ResizePlugin: KeenSliderPlugin = (slider) => {
  const observer = new ResizeObserver(() => {
    slider.update();
  });

  slider.on('created', () => {
    observer.observe(slider.container);
  });

  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

const AutoSwitchPlugin: KeenSliderPlugin = (slider) => {
  let timeout: number;
  let mouseOver: boolean = false;

  const clearNextTimeout = () => window.clearTimeout(timeout);
  const nextTimeout = () => {
    window.clearTimeout(timeout);
    if (mouseOver) return;
    timeout = window.setTimeout(() => slider.next(), 2000);
  };

  slider.on('created', () => {
    slider.container.addEventListener('mouseover', () => {
      mouseOver = true;
      clearNextTimeout();
    });
    slider.container.addEventListener('mouseout', () => {
      mouseOver = false;
      nextTimeout();
    });
    nextTimeout();
  });

  slider.on('dragStarted', clearNextTimeout);
  slider.on('animationEnded', nextTimeout);
  slider.on('updated', nextTimeout);
};

const slides = [
  {
    imageUrl: Carousel1Image,
    internal: true,
    href: '/auth/register',
    index: 0,
  },
  /*
  {
    imageUrl: Carousel2Image,
    internal: false,
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSeUVZYRUfMxLSAr_7e6gxbaVATK3qv-2xzLbBapueKDfayU2Q/viewform',
    index: 1,
  },
  */
  {
    imageUrl: Carousel3Image,
    internal: false,
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSc1XYRJ7zChbzz5N0FbSVpo0kOeJDsLlb2hGdiGZOpUvXizXQ/viewform',
    index: 2,
  },
] as const;

const CarouselSection: React.FC = () => {
  const { t } = useTranslation('main', { keyPrefix: 'carousel' });

  const [currentSlide, setCurrentSlide] = React.useState<number>(0);

  const ref = React.useRef<HTMLElement | null>(null);

  const [refCallback, slider] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: true,
      slideChanged(instance) {
        setCurrentSlide(instance.track.details.rel);
      },
    },
    [ResizePlugin, AutoSwitchPlugin]
  );

  return (
    <Base className={`slide-background slide-index-${currentSlide}`} ref={ref}>
      <SliderMoveButton onClick={() => slider.current?.prev()}>
        <ArrowLeftIcon size={24} weight={'bold'} />
      </SliderMoveButton>
      <Slider>
        <div ref={refCallback} className={'keen-slider'}>
          {slides.map((el) => (
            <CarouselSlideCard
              index={el.index}
              title={t(`${el.index}.title`)}
              body={t(`${el.index}.body`)}
              imageUrl={el.imageUrl}
              internal={el.internal}
              href={el.href}
              key={el.index}
            />
          ))}
        </div>
        <SliderDotBox>
          <Dots>
            {Array(slides.length)
              .fill(0)
              .map((_, idx) => idx)
              .map((el) => (
                <Dot
                  onClick={() => {
                    slider.current?.moveToIdx(el);
                  }}
                  isSelected={currentSlide === el}
                  key={el}
                />
              ))}
          </Dots>
        </SliderDotBox>
      </Slider>
      <SliderMoveButton onClick={() => slider.current?.next()}>
        <ArrowRightIcon size={24} weight={'bold'} />
      </SliderMoveButton>
    </Base>
  );
};

export default CarouselSection;

const Base = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 1;

  &.slide-background::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
  }

  &.slide-index-0::before {
    background: linear-gradient(92.83deg, #92d2f7 0%, #4066df 100%);
  }

  &.slide-index-1::before {
    background: linear-gradient(92.83deg, #ffd6a3 0%, #fe3fa7 100%);
  }

  &.slide-index-2::before {
    background: linear-gradient(92.83deg, #9700de 0%, #6dd6c3 100%);
  }

  width: 100%;

  @media (min-width: 820px) {
    justify-content: space-around;
  }

  @media (min-width: 1440px) {
    justify-content: center;
    min-width: 1440px;
    width: 100%;
    height: 320px;
  }
`;

const SliderMoveButton = styled.button`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: var(--color-white);

  border: none;
  outline: none;
  padding: 0;

  filter: drop-shadow(0px 4px 16px rgba(51, 51, 51, 0.16));

  color: var(--color-black);
  cursor: pointer;

  &:disabled {
    color: var(--color-grey-200);
  }

  display: none;

  @media (min-width: 960px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Slider = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .keen-slider {
    height: 360px;
    margin-bottom: auto;
  }

  @media (min-width: 820px) {
    width: 720px;

    .keen-slider {
      width: 720px;
      height: 280px;
      margin-bottom: auto;
    }
  }

  @media (min-width: 1440px) {
    margin-left: 144px;
    margin-right: 144px;
  }
`;

const SliderDotBox = styled.div`
  margin-top: 8px;
  height: 32px;
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
`;

const Dot = styled.button<{ isSelected: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;

  border: none;
  outline: none;
  padding: 0;

  background: ${({ isSelected }) =>
    isSelected ? 'var(--color-pink)' : 'var(--color-white)'};
`;
