import * as React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoginTemplate } from 'components/auth';
import { Login, useLoginAfterNavigate } from 'features/auth';

const LoginPage: React.FC = () => {
  useLoginAfterNavigate();

  return (
    <GoogleOAuthProvider clientId={process.env.ONAIR_GOOGLE_CLIENT_ID}>
      <LoginTemplate>
        <Login />
      </LoginTemplate>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;
