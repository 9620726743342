import * as React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import {
  useVideoDownloadModalOpen,
  useOpenPredicted,
} from 'features/editor/providers';
import {
  fetchGetVideoStatus,
  setOpenMergeLimitToast,
} from 'features/editor/editorSlice';
import type { DownloadButtonProps } from './DownloadButton';

const VideoHQ: React.FC<DownloadButtonProps> = ({ children }) => {
  const openModal = useVideoDownloadModalOpen();
  const openPredicted = useOpenPredicted();

  const dispatch = useAppDispatch();

  const predictedCallback = async () => {
    try {
      await openModal('HQ');
    } catch (error) {
      throw error;
    }
  };

  const handleClick = async () => {
    try {
      const notDecrementUsed = await dispatch(fetchGetVideoStatus()).unwrap();

      if (notDecrementUsed) {
        await predictedCallback();
      } else {
        openPredicted(predictedCallback);
      }
    } catch (error) {
      if ((error as Error).message === 'merge limit') {
        dispatch(setOpenMergeLimitToast(true));
        return;
      }

      console.error(error);
    }
  };

  return <Base onClick={handleClick}>{children}</Base>;
};

export default VideoHQ;

const Base = styled.li`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: var(--color-black);
  background: var(--color-white);

  &:hover {
    background: linear-gradient(rgba(51, 51, 51, 0.08), rgba(51, 51, 51, 0.08))
      var(--color-white);

    @media (hover: none) {
      background: var(--color-white);
    }
  }
`;
