import * as React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';
import Cell from './Cell';

interface SortableItemProps {
  cellKey: string;
  pageKey: string;
  overlay?: boolean;
}

const SortableItem: React.FC<SortableItemProps> = ({
  cellKey,
  pageKey,
  overlay,
}) => {
  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({ id: cellKey });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Base
      ref={setNodeRef}
      style={style}
      className={overlay ? 'overlay' : ''}
      {...attributes}
    >
      <Cell pageKey={pageKey} cellKey={cellKey} listeners={listeners} />
    </Base>
  );
};

export default SortableItem;

const Base = styled.div`
  outline: none;
  opacity: 1;

  margin-top: 16px;
  width: calc(100% - 16px);

  & + & {
    margin-top: 8px;
  }

  &.overlay {
    opacity: 0.32;
  }
`;
