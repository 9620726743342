import * as React from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { useGetVideoModelQuery } from 'app/services/videoModel';
import { useGetVideosQuery } from 'app/services/videoAssets';
import { useHistoryPrompt } from 'lib/hooks';
import { TailSpinIcon } from 'assets/svg';
import { ModalV2 } from 'components/common';
import {
  fetchProjectById,
  fetchTemplateById,
  clearHistory,
  clearData,
  setPageLimit,
  setCellLimit,
  cancelUpdateTimer,
  selectProjectUuid,
  selectIsLoadingScreen,
} from 'features/editor/editorSlice';
import { selectRefreshError } from 'features/auth/authSlice';
import { PredictedDuration } from 'features/editor/Modals';
import {
  EditorProvider,
  useVideoDownloadSetProgress,
} from 'features/editor/providers';

const isTemplateProject = (state: any): boolean => {
  if (state?.type === 'template') return true;
  return false;
};

interface ProjectLoaderProps {
  children?: React.ReactNode;
}

const ProjectLoader: React.FC<ProjectLoaderProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { data: userInfo } = useGetUserInfoQuery();
  const { data: videoModels } = useGetVideoModelQuery();

  const setDownloadProgress = useVideoDownloadSetProgress();

  const [searchParams] = useSearchParams(location.hash.slice(1));

  React.useEffect(() => {
    if (userInfo === undefined) return;

    dispatch(setPageLimit(userInfo.pageLimitNum ?? undefined));
    dispatch(setCellLimit(userInfo.cellLimitNum ?? undefined));
  }, [userInfo, dispatch]);

  const [isMounted, setIsMounted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (videoModels === undefined) return;

    const uuid = searchParams.get('uuid');
    if (uuid === null) return;

    if (isMounted) return;
    setIsMounted(true);

    if (isTemplateProject(location.state)) {
      (async () => {
        try {
          await dispatch(fetchTemplateById({ uuid, videoModels })).unwrap();
        } catch (error) {
          console.error(error);
        }
      })();
    } else {
      (async () => {
        try {
          const resp = await dispatch(
            fetchProjectById({ uuid, videoModels })
          ).unwrap();
          setDownloadProgress(resp.rs);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [
    dispatch,
    searchParams,
    setDownloadProgress,
    videoModels,
    location,
    isMounted,
  ]);

  const { data: videoAssets } = useGetVideosQuery();

  const uuid = useAppSelector(selectProjectUuid);
  const prevUuid = React.useRef<string | undefined>(uuid);

  React.useEffect(() => {
    if (uuid === undefined) return;
    if (prevUuid.current === uuid) return;

    prevUuid.current = uuid;
    window.history.replaceState(null, '', `#uuid=${uuid}`);
  }, [dispatch, uuid, videoAssets]);

  React.useEffect(() => {
    return () => {
      dispatch(clearHistory());
      dispatch(clearData());
      dispatch(cancelUpdateTimer());
    };
  }, [dispatch]);

  const authRefreshError = useAppSelector(selectRefreshError);

  useHistoryPrompt(
    authRefreshError ? false : true,
    '페이지를 나가시겠습니까?\n변경된 내용이 저장되지 않을 수 있습니다.'
  );

  const isOpenLoadingScreen = useAppSelector(selectIsLoadingScreen);

  return (
    <>
      {children}
      <ModalV2 open={isOpenLoadingScreen}>
        <TailSpinIcon width={144} height={144} />
      </ModalV2>
      <PredictedDuration />
    </>
  );
};

export default function Loader({ children }: { children: React.ReactNode }) {
  return (
    <EditorProvider>
      <ProjectLoader>{children}</ProjectLoader>
    </EditorProvider>
  );
}
