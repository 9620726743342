import * as React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { useRequestPaymentMutation } from 'app/services/membership';
import { CheckCircleGradientIcon } from 'assets/svg';
import { typography } from 'styles';
import type { GradeType } from 'components/membership/constants';

interface PaymentsSuccessProps {
  grade: GradeType;
}

const PaymentsSuccess: React.FC<PaymentsSuccessProps> = ({ grade }) => {
  const { t } = useTranslation('client', { keyPrefix: 'payment.success' });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const customerKey = searchParams.get('customerKey');
  const authKey = searchParams.get('authKey');

  const [requestPayment] = useRequestPaymentMutation();

  const { refetch: refetchUserInfo } = useGetUserInfoQuery();

  React.useEffect(() => {
    if (customerKey === null) return;
    if (authKey === null) return;

    (async () => {
      try {
        await requestPayment({
          customerKey,
          authKey,
          billingType: grade,
        }).unwrap();

        refetchUserInfo();
      } catch (error) {
        console.error(error);
        navigate(`/client/payment/new/failure?grade=${grade}`, {
          replace: true,
        });
      }
    })();
  }, [customerKey, authKey, navigate, requestPayment, grade, refetchUserInfo]);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    navigate('/client', { replace: true });
  };

  return (
    <Base>
      <CheckCircleGradientIcon width={80} height={80} />
      <Title>{t('title', { grade })}</Title>
      <Subtitle>{t('subTitle')}</Subtitle>
      <PinkButton onClick={onClick}>
        <span>{t('button')}</span>
      </PinkButton>
    </Base>
  );
};

export default PaymentsSuccess;

const Base = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 120px;
`;

const Title = styled.span`
  ${typography.title.large}
`;

const Subtitle = styled.span`
  ${typography.body.large}
`;

const PinkButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  flex: none;
  width: 100%;
  max-width: 240px;
  padding: 16px 13px;
  margin-top: 16px;

  border: none;
  border-radius: 10px;
  background: var(--color-pink);
  cursor: pointer;

  span {
    ${typography.title.medium}
    color: var(--color-white);
  }

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;
