import * as React from 'react';
import styled from '@emotion/styled';
import { EyeIcon, EyeSlashIcon } from 'assets/svg';
import { AutofillInput } from 'components/common';
import { typography } from 'styles';

export interface EventAiExpoTextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  helperText?: string;
}

const EventAiExpoTextField = React.forwardRef<
  HTMLInputElement,
  EventAiExpoTextFieldProps
>(({ label, error, helperText, type, ...props }, ref) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    ref,
    () => inputRef.current,
    [inputRef]
  );

  const [focused, setFocused] = React.useState<boolean>(false);
  const [autofilled, setAutofilled] = React.useState<boolean>(false);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const handleToggleShowPassword = () => setShowPassword((prev) => !prev);

  const [_value, _setValue] = React.useState<string>(() => {
    if (typeof props.defaultValue === 'string' && props.defaultValue !== '') {
      return props.defaultValue;
    }
    if (typeof props.value === 'string' && props.value !== '') {
      return props.value;
    }
    return '';
  });

  React.useEffect(() => {
    const el = inputRef.current;
    if (el === null) return;

    const changeHandler = (e) => {
      _setValue(e.target.value);
    };

    el.addEventListener('change', changeHandler);

    return () => {
      el.removeEventListener('change', changeHandler);
    };
  }, []);

  return (
    <Base>
      <TextField
        className={error ? 'textfield--error' : ''}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {label && (
          <Label
            className={
              _value !== '' || focused || autofilled ? 'label-shrink' : ''
            }
          >
            {label}
          </Label>
        )}
        {type === 'password' ? (
          <Input
            onAutoFill={(filled) => setAutofilled(filled)}
            type={showPassword ? 'text' : 'password'}
            {...props}
            ref={inputRef}
          />
        ) : (
          <Input
            onAutoFill={(filled) => setAutofilled(filled)}
            type={type}
            {...props}
            ref={inputRef}
          />
        )}
        {type === 'password' && (
          <PasswordIconBox
            className={'icon-box'}
            onClick={handleToggleShowPassword}
          >
            {showPassword ? (
              <EyeIcon size={24} color={'currentColor'} weight={'fill'} />
            ) : (
              <EyeSlashIcon size={24} color={'currentColor'} weight={'fill'} />
            )}
          </PasswordIconBox>
        )}
      </TextField>
      {helperText && (
        <HelperText className={error ? 'error' : ''}>{helperText}</HelperText>
      )}
    </Base>
  );
});

export default EventAiExpoTextField;

const Base = styled.div`
  width: 100%;
  height: 56px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 520px) {
    width: 392px;
  }
`;

const TextField = styled.div`
  width: 100%;
  height: 48px;
  position: relative;
  border-radius: 10px;
  display: flex;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-grey-300);

  &:hover {
    border-width: 2px;

    label {
      top: 11px;
      left: 15px;
    }

    .icon-box {
      width: 46px;
      height: 46px;
    }

    input {
      padding: 19px 15px 3px 15px;

      &[type='password'] {
        padding: 19px 47px 3px 15px;
      }
    }
  }

  &:focus-within {
    border-width: 2px;
    border-color: var(--color-blue);

    .icon-box {
      width: 46px;
      height: 46px;
    }

    label {
      color: var(--color-blue);
    }

    input {
      padding: 19px 15px 4px 15px;

      &[type='password'] {
        padding: 19px 47px 4px 15px;
      }
    }
  }

  &.textfield--error {
    border-width: 2px;
    border-color: var(--color-danger);

    label {
      top: 11px;
      left: 15px;
      color: var(--color-danger);
    }

    input {
      padding: 19px 15px 4px 15px;

      &[type='password'] {
        padding: 19px 47px 4px 15px;
      }
    }
  }
`;

const Label = styled.label`
  position: absolute;
  top: 12px;
  left: 16px;
  ${typography.body.large}
  color: var(--color-grey-600);
  user-select: none;
  pointer-event: none;
  transition: transform 225ms, scale 225ms, color 225ms;

  &.label-shrink {
    top: 12px;
    left: 16px;
    transform: translateY(-8px);
    ${typography.label.medium}
  }
`;

const Input = styled(AutofillInput)`
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background: transparent;
  padding: 20px 16px 4px 16px;
  ${typography.body.large}
  flex: 1;
  border-radius: 10px;

  &[type='password'] {
    padding: 20px 48px 4px 16px;
  }

  &:disabled {
    background: var(--color-grey-100);
    color: var(--color-grey-700);
  }
`;

const PasswordIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: var(--color-grey-300);
  position: absolute;
  top: 0;
  right: 0;
`;

const HelperText = styled.span`
  ${typography.label.medium}
  color: var(--color-black);
  margin-left: 17px;

  &.error {
    color: var(--color-danger);
  }
`;
