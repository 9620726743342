import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAudio } from 'react-use';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AudioManager from 'lib/AudioManager';
import { TailSpinIcon, PlayCircleIcon, PauseCircleIcon } from 'assets/svg';
import {
  fetchMergeMlModelText,
  selectPageMergeData,
} from 'features/editor/editorSlice';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
  usePusherChannelId,
} from 'features/editor/providers';

interface MergeNode {
  type: 'audio-node' | 'padding-node';
  text: string;
  volume: number;
  speed: number;
  name: string;
  padding: number;
}

interface VoiceOptionType {
  volume: number;
  speed: number;
}

interface ChangeTonePlayButtonProps {
  audioManagerRef: React.MutableRefObject<AudioManager>;
  voiceOption: VoiceOptionType;
  pageKey: string;
}

const isEmptyNodes = (nodes: MergeNode[]): boolean => {
  return nodes.reduce<boolean>((acc, { type, padding }) => {
    if (type === 'audio-node') return false;
    if (padding !== 0) return false;
    return acc;
  }, true);
};

const ChangeTonePlayButton: React.FC<ChangeTonePlayButtonProps> = ({
  audioManagerRef,
  voiceOption,
  pageKey,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'changeTone' });

  const { volume, speed } = voiceOption;

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const dispatch = useAppDispatch();

  const mergeNodes = useAppSelector(selectPageMergeData(pageKey));

  const prevKey = React.useRef<string | null>(null);

  const channelId = usePusherChannelId();

  const fetchMergeNode = (item: MergeNode) => {
    return new Promise<{ buffer?: AudioBuffer; padding: number }>(
      async (resolve, reject) => {
        if (channelId === undefined) {
          reject();
          return;
        }

        if (item.type === 'padding-node') {
          resolve({
            padding: item.padding,
          });
          return;
        }

        try {
          const blob = await dispatch(
            fetchMergeMlModelText({
              channelId,
              text: item.text,
              name: item.name,
              volume: volume,
              speed: speed,
            })
          ).unwrap();
          const buffer = await audioManagerRef.current.toAudioBuffer(blob);
          resolve({
            buffer,
            padding: item.padding,
          });
        } catch (error) {
          reject(error);
        }
      }
    );
  };

  const audioPlay: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation();
    const el = ref.current;
    if (el === null) return;
    if (mergeNodes === undefined) return;
    if (mergeNodes.length === 0) return;

    const firstVoiceNode = mergeNodes.find(
      (item) => item.type === 'audio-node'
    );
    if (firstVoiceNode === undefined) return;

    const previewMergeData: MergeNode[] = [];
    previewMergeData.push(firstVoiceNode);

    if (isEmptyNodes(previewMergeData)) return;

    const key = `${volume}-${speed}`;

    if (prevKey.current === key) {
      if (state.paused) {
        editorAudioPlay(controls);
      } else {
        editorAudioPause();
      }
      return;
    }

    setMergeLoading(true);
    prevKey.current = key;

    const nodes = await Promise.all<{
      buffer?: AudioBuffer;
      padding: number;
    }>(previewMergeData.map((item) => fetchMergeNode(item)));

    const buffer = audioManagerRef.current.processNodes(nodes);
    const url = audioManagerRef.current.export(buffer);
    el.src = url;
  };

  return (
    <PlayButton onClick={audioPlay}>
      {mergeLoading ? (
        <TailSpinIcon width={16} height={16} />
      ) : (
        <>
          {state.paused ? (
            <PlayCircleIcon size={16} weight={'fill'} />
          ) : (
            <PauseCircleIcon size={16} weight={'fill'} />
          )}
        </>
      )}
      <span>{state.paused ? t('button.play') : t('button.pause')}</span>
      {element}
    </PlayButton>
  );
};

export default ChangeTonePlayButton;

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  font-size: 12px;
  line-height: 1.4;
  color: var(--color-grey-700);
  cursor: pointer;
  user-select: none;

  span {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
  }
`;
