import * as React from 'react';
import styled from '@emotion/styled';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import CellChangeToneButton from './CellChangeToneButton';
import CellDownloadButton from './CellDownloadButton';
import CellDeleteButton from './CellDeleteButton';

interface CellToolBoxProps {
  pageKey: string;
  cellKey: string;
}

const CellToolBox: React.FC<CellToolBoxProps> = ({ pageKey, cellKey }) => {
  const { data } = useGetUserInfoQuery();

  return (
    <Base>
      <CellChangeToneButton pageKey={pageKey} cellKey={cellKey} />
      {data?.audioDownload === true && <CellDownloadButton cellKey={cellKey} />}
      <CellDeleteButton cellKey={cellKey} />
    </Base>
  );
};

export default CellToolBox;

const Base = styled.div`
  background: var(--color-white);
  border: 1px solid var(--color-grey-200);
  border-radius: 10px;
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 4px;
  padding: 8px;

  button {
    color: var(--color-grey-700);
  }
`;
