import { PERSIST_KEY } from './persistConstants';

const removePersistStore = (key: string): boolean => {
  if (window === undefined || window.localStorage === undefined) return true;

  const item = window.localStorage.getItem(PERSIST_KEY);

  if (item === undefined || item === null) return true;

  try {
    const parsedData = JSON.parse(item);

    if (parsedData.hasOwnProperty(key)) {
      delete parsedData[key];

      window.localStorage.setItem(PERSIST_KEY, JSON.stringify(parsedData));
    }

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export default removePersistStore;
