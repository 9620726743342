import { PERSIST_KEY } from './persistConstants';

const preparePersistStore = (validator?: (data: any) => boolean) => {
  if (window === undefined || window.localStorage === undefined) {
    return undefined;
  }

  const item = window.localStorage.getItem(PERSIST_KEY);

  if (item === undefined || item === null) return undefined;

  try {
    const parsedData = JSON.parse(item);

    const isValid = validator ? validator(parsedData) : false;

    return isValid ? parsedData : undefined;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export default preparePersistStore;
