import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { useUpdatePasswordMutation } from 'app/services/userInfo';
import { ArrowLeftIcon } from 'assets/svg';
import { AuthTextField, ValidateCheck } from 'components/auth';
import { Toast } from 'components/common';
import { passwordPattern } from 'lib/utils';
import { typography } from 'styles';
import type { SubmitHandler } from 'react-hook-form';

interface UpdatePasswordData {
  oldPassword: string;
  newPassword: string;
}

const UpdatePassword: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'userProfile' });

  const { register, handleSubmit, watch } = useForm<UpdatePasswordData>();

  const [requestUpdatePassword] = useUpdatePasswordMutation();

  const [showToast, setShowToast] = React.useState<boolean>(false);

  const onSubmit: SubmitHandler<UpdatePasswordData> = async (data) => {
    try {
      await requestUpdatePassword(data).unwrap();
      setShowToast(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Base>
      <TitleBox>
        <GoProfileButton to={'/client/profile'} replace>
          <ArrowLeftIcon size={24} weight={'bold'} />
        </GoProfileButton>
        <Title>{t('title.updatePassword')}</Title>
      </TitleBox>
      <FormBox onSubmit={handleSubmit(onSubmit)}>
        <AuthTextField
          label={t('label.currentPassword')}
          helperText={t('helperText.checkPassword')}
          type={'password'}
          {...register('oldPassword', {
            required: true,
            maxLength: 20,
            pattern: passwordPattern,
          })}
        />
        <AuthTextPadding />
        <AuthTextField
          label={t('label.newPassword')}
          type={'password'}
          {...register('newPassword', {
            required: true,
            minLength: 8,
            maxLength: 20,
            pattern: passwordPattern,
          })}
        />
        <ValidateCheck value={watch('newPassword') ?? ''} />
        <ButtonBox>
          <CancelButton to={'/client/profile'}>
            {t('button.cancel')}
          </CancelButton>
          <SubmitButton type={'submit'}>{t('button.save')}</SubmitButton>
        </ButtonBox>
      </FormBox>
      <Toast
        severity={'success'}
        message={t('toast.updatePasswordSuccess')}
        in={showToast}
        timeout={2000}
        onClose={() => setShowToast(false)}
      />
    </Base>
  );
};

export default UpdatePassword;

const Base = styled.div`
  width: 100%;
`;

const TitleBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;

  @media (min-width: 520px) {
    margin-bottom: 8px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 12px;
  }
`;

const GoProfileButton = styled(Link)`
  text-decoration: none;
  background: none;
  border: none;
  padding: 4px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  color: var(--color-black);
`;

const Title = styled.span`
  ${typography.title.large}
`;

const FormBox = styled.form`
  width: 100%;

  & > div {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: 520px) {
    width: 400px;
  }
`;

const AuthTextPadding = styled.div`
  height: 20px;
`;

const ButtonBox = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`;

const CancelButton = styled(Link)`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  flex: 1;
  display: inline-flex;
  text-decoration: none;
  border: none;
  border-radius: 10px;
  height: 48px;
  width: 100%;
  align-items: center;
  justify-content: center;

  ${typography.title.medium}
  color: var(--color-grey-700);
  background: var(--color-grey-200);

  &:hover {
    background: var(--hover-gradient), var(--color-grey-200);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-grey-200);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-grey-200);
  }
`;

const SubmitButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  flex: 2;
  border: none;
  border-radius: 10px;
  height: 48px;
  width: 100%;

  ${typography.title.medium}
  color: var(--color-white);
  background: var(--color-pink);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;
