import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import UsageCard from './UsageCard';
import Usage1Image from 'assets/img/main-usage-1.jpeg';
import UsageUserThumb1Image from 'assets/img/main-usage-userthumb-1.jpeg';
import Usage2Image from 'assets/img/main-usage-2.jpeg';
import UsageUserThumb2Image from 'assets/img/main-usage-userthumb-2.jpeg';
import Usage3Image from 'assets/img/main-usage-3.jpeg';
import UsageUserThumb3Image from 'assets/img/main-usage-userthumb-3.jpeg';
import { typography } from 'styles';

const usages = [
  {
    imageUrl: Usage1Image,
    user: {
      thumbnail: UsageUserThumb1Image,
    },
    href: 'https://www.youtube.com/watch?v=570_klUt1Oc',
    index: 0,
  },
  {
    imageUrl: Usage2Image,
    user: {
      thumbnail: UsageUserThumb2Image,
    },
    href: 'https://www.youtube.com/watch?v=oIPxflh2c5s',
    index: 1,
  },
  {
    imageUrl: Usage3Image,
    user: {
      thumbnail: UsageUserThumb3Image,
    },
    href: 'https://www.youtube.com/watch?v=koOPjcjTL1E',
    index: 2,
  },
] as const;

const UsageSection: React.FC = () => {
  const { t } = useTranslation('main', { keyPrefix: 'usage' });

  return (
    <Base id={'usage'}>
      <Container>
        <Title>{t('title')}</Title>
        <Cards>
          {usages.map((el) => (
            <UsageCard
              imageUrl={el.imageUrl}
              title={t(`items.${el.index}.title`)}
              user={{
                thumbnail: el.user.thumbnail,
                name: t(`items.${el.index}.user`),
              }}
              tags={Object.values(
                t(`items.${el.index}.tags`, { returnObjects: true })
              )}
              href={el.href}
              key={el.index}
            />
          ))}
        </Cards>
      </Container>
    </Base>
  );
};

export default UsageSection;

const Base = styled.section`
  background: #1e3e72;

  @media (min-width: 1440px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 64px 0;
  margin: 0 auto;

  width: calc(100% - 32px);

  @media (min-width: 820px) {
    width: 100%;
    max-width: 1440px;
  }
`;

const Title = styled.span`
  color: var(--color-white);
  ${typography.title.large}

  @media (min-width: 820px) {
    ${typography.display.medium}
  }
`;

const Cards = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;
