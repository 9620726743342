import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGetProjectsQuery } from 'app/services/projects';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import { GradientAddIcon } from 'assets/svg';
import { IconButton, Toast } from 'components/common';
import ContentsTitle from './ContentsTitle';
import ProjectCard from './ProjectCard';
import NewProjectCard from './NewProjectCard';

const RecentlyProjectList: React.FC = () => {
  const { t } = useTranslation('client');

  const { data: projects } = useGetProjectsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { data: userInfo } = useGetUserInfoQuery();

  const navigate = useNavigate();

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const handleClickGoEditor = () => {
    if (projects === undefined) return;
    if (userInfo === undefined) return;

    if (projects.length < userInfo.projectLimitNum) {
      navigate('/client/editor');
      return;
    }

    toggleOpenToast();
  };

  return (
    <Base>
      <ContentsTitle title={t('home.title.project')} />
      {projects ? (
        <List>
          {[...projects]
            .sort((prev, next) => {
              const prevDate = new Date(prev.updateDate);
              const nextDate = new Date(next.updateDate);

              return nextDate.getTime() - prevDate.getTime();
            })
            .map((project) => (
              <ProjectCard
                uuid={project.projectUuid}
                key={project.projectUuid}
              />
            ))}
        </List>
      ) : (
        <NewProjectCard />
      )}
      <Toast
        in={openToast}
        onClose={toggleOpenToast}
        timeout={1500}
        message={t('common.createProjectOver')}
        severity={'error'}
      />
      <GoEditorButton size={48} radius={10} onClick={handleClickGoEditor}>
        <GradientAddIcon width={32} height={32} />
      </GoEditorButton>
    </Base>
  );
};

export default RecentlyProjectList;

const Base = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: auto;

  overflow-y: none;
  @media (min-width: 1024px) {
    overflow-y: hidden;
  }
`;

const List = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 330px);
  grid-template-rows: repeat(auto-fit, 240px);
  list-style: none;

  @media (min-width: 1024px) {
    grid-row-gap: 32px;
    overflow-y: auto;
  }

  @media (min-width: 520px) {
    justify-content: flex-start;
    grid-row-gap: 28px;
  }
`;

const GoEditorButton = styled(IconButton)`
  position: fixed;
  right: 16px;
  bottom: 16px;
  border: 1px solid var(--color-grey-50);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);

  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
`;
