import * as React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

interface RadioProps {
  size: number;
  checked?: boolean;
  onClick?: () => void;
}

const Radio: React.FC<RadioProps> = ({ size, checked, onClick }) => {
  return (
    <Base onClick={onClick} size={size} checked={checked}>
      {checked && <Circle />}
    </Base>
  );
};

export default Radio;

const Base = styled.div<{
  size: number;
  checked?: boolean;
}>`
  --radio-color: ${({ checked }) =>
    checked ? 'var(--color-pink)' : 'var(--color-grey-500)'};

  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: var(--color-white);

  border-width: 2px;
  border-style: solid;
  border-color: var(--radio-color);
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 225ms;

  &:hover {
    ${({ checked }) =>
      checked ||
      `
	  	background-color: var(--color-grey-100);

			@media (hover: none) {
				background-color: var(--color-white);
			}
		`}
  }
`;

const createIn = keyframes`
  from {
		width: 0px;
		height: 0px;
	}

	to {
		width: calc(100% - 4px);
		height: calc(100% - 4px);
	}
`;

const Circle = styled.span`
  border-radius: 50%;
  background-color: var(--radio-color);

  animation: ${createIn} 225ms ease-in forwards;
`;
