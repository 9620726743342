import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { DotsThreeOutlineVerticalIcon } from 'assets/svg';
import {
  copyPage,
  fetchUploadTempImage,
  overrideImageFileSlide,
  removePage,
} from 'features/editor/editorSlice';

interface PageOptionsProps {
  pageKey: string;
  open: boolean;
}

const PageOptions: React.FC<PageOptionsProps> = ({ pageKey, open }) => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const dispatch = useAppDispatch();

  const handleClickCopy = () => {
    dispatch(copyPage({ pageKey }));
  };

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleClickChangeImage = async () => {
    const el = inputRef.current;
    if (el === null) return;

    const file = await new Promise<File>((resolve) => {
      el.addEventListener(
        'change',
        () => {
          if (el.files === null) return;
          if (el.files.length === 0) return;

          const f = el.files[0];
          resolve(f);
        },
        { once: true }
      );

      el.click();
    });

    const data = await dispatch(fetchUploadTempImage({ file })).unwrap();

    dispatch(
      overrideImageFileSlide({
        pageKey,
        ...data,
      })
    );
  };

  const handleClickRemovePage = (e) => {
    e.stopPropagation();
    dispatch(removePage({ pageKey }));
  };

  if (!open) return null;
  return (
    <Menu as={MenuBase} onPointerDown={(e) => e.stopPropagation()}>
      <Menu.Button as={MenuButton}>
        <DotsThreeOutlineVerticalIcon
          size={16}
          weight={'fill'}
          color={'currentColor'}
        />
      </Menu.Button>
      <Menu.Items as={MenuItems}>
        <Menu.Item
          as={MenuItem}
          onClick={handleClickCopy}
          style={
            {
              '--item-color': 'var(--color-black)',
            } as React.CSSProperties
          }
        >
          {t('text.copy')}
        </Menu.Item>
        <Menu.Item
          as={MenuItem}
          onClick={handleClickChangeImage}
          style={
            {
              '--item-color': 'var(--color-black)',
            } as React.CSSProperties
          }
        >
          {t('text.changeImage')}
          <input
            type={'file'}
            accept={'image/*'}
            style={{ display: 'none' }}
            onClick={(e) => e.stopPropagation()}
            ref={inputRef}
          />
        </Menu.Item>
        <Menu.Item
          as={MenuItem}
          onClick={handleClickRemovePage}
          style={
            {
              '--item-color': 'var(--color-danger)',
            } as React.CSSProperties
          }
        >
          {t('text.delete')}
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default PageOptions;

const MenuBase = styled.div`
  position: absolute;
  top: 24px;
  left: 79%;
  transform: translateX(-50%);

  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MenuButton = styled.button`
  color: var(--color-white);
  background-color: rgba(51, 51, 51, 0.8);
  border-radius: 6px;
  padding: 4px;
  width: 24px;
  height: 24px;
  outline: none;
  border: none;
  cursor: pointer;
`;

const MenuItems = styled.div`
  background: var(--color-white);
  border: 1px solid var(--color-grey-100);
  border-radius: 10px;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  padding: 8px 0;

  width: 88px;
  height: 112px;
  margin-top: 4px;
  cursor: pointer;
`;

const MenuItem = styled.div`
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;

  color: var(--item-color);

  &:hover,
  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;
  }
`;
