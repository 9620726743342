import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { SquareIcon, CheckSquareIcon } from 'assets/svg';
import { passwordPattern } from 'lib/utils';
import { typography } from 'styles';
import AuthTextField from './AuthTextField';
import ValidateCheck from './ValidateCheck';
import PinkButton from './PinkButton';

const TERMS_OF_USER =
  'https://onairstudio.zendesk.com/hc/ko/sections/4411962940569-%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80';
const PRIVACY_POLICY =
  'https://onairstudio.zendesk.com/hc/ko/sections/4411971433625-%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4-%EC%B2%98%EB%A6%AC%EB%B0%A9%EC%B9%A8';

interface RegisterData {
  uid: string;
  token: string;
  email: string;
  password: string;
  name: string;
  marketingAccept: boolean;
}

interface RegisterFormProps {
  onSubmit: (data: RegisterData) => Promise<void>;
  loading: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ onSubmit, loading }) => {
  const { t } = useTranslation('auth', { keyPrefix: 'emailRegister' });

  const location = useLocation();
  const search = new URLSearchParams(location.hash);
  const email = search.get('email') ?? '';
  const token = search.get('token') ?? '';
  const uid = search.get('uid') ?? '';

  const { register, handleSubmit, watch, setValue } = useForm<RegisterData>({
    defaultValues: {
      email,
      token,
      uid,
      password: '',
      marketingAccept: true,
    },
  });

  const marketingAccept = watch('marketingAccept');
  const handleToggleMarketing = () =>
    setValue('marketingAccept', !marketingAccept);

  return (
    <Content>
      <Title>{t('title')}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AuthTextField
          label={t('placeholder.email')}
          defaultValue={email}
          disabled
          {...register('email', {
            required: true,
          })}
        />
        <AuthTextField
          label={t('placeholder.name')}
          {...register('name', {
            required: true,
          })}
        />
        <AuthTextField
          label={t('placeholder.password')}
          type={'password'}
          autoComplete={'new-password'}
          {...register('password', {
            required: true,
            minLength: 8,
            maxLength: 20,
            pattern: passwordPattern,
          })}
        />
        <ValidateCheck value={watch('password')} />
        <MarketingAcceptBox onClick={handleToggleMarketing}>
          <Checkbox type={'button'}>
            {marketingAccept ? (
              <CheckSquareIcon
                size={20}
                color={'var(--color-pink)'}
                weight={'fill'}
              />
            ) : (
              <SquareIcon size={20} weight={'bold'} />
            )}
          </Checkbox>
          <span>{t('agreement.news')}</span>
        </MarketingAcceptBox>
        <Agreement>
          <Trans
            t={t}
            i18nKey={'agreement.email'}
            components={[
              <Anchor href={TERMS_OF_USER} />,
              <Anchor href={PRIVACY_POLICY} />,
            ]}
          />
        </Agreement>
        <PinkButton type={'submit'} loading={loading}>
          {t('button.register')}
        </PinkButton>
      </Form>
    </Content>
  );
};

export default RegisterForm;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.p`
  ${typography.display.small}
  text-align: center;
  margin: 0;
`;

const Form = styled.form`
  margin-top: 24px;
`;

const MarketingAcceptBox = styled.div`
  width: calc(100% - 32px);
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  ${typography.label.medium}
  color: var(--color-black);
  margin-top: 16px;

  @media (min-width: 520px) {
    width: 392px;
  }

  span {
    margin-left: 4px;
    user-select: none;
  }
`;

const Checkbox = styled.button`
  --hover-background: rgba(51, 51, 51, 0.08);
  --active-background: rgba(51, 51, 51, 0.16);

  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--hover-background);

    @media (hover: none) {
      background: var(--active-background);
    }
  }

  &:active {
    background: var(--active-background);
  }

  &[data-checked='true'] {
    --hover-background: rgba(255, 55, 110, 0.08);
    --active-background: rgba(255, 55, 110, 0.16);
  }
`;

const Agreement = styled.p`
  width: calc(100% - 32px);
  ${typography.label.medium}
  color: var(--color-grey-500);
  margin: 16px auto 24px auto;
  white-space: pre;

  @media (min-width: 520px) {
    width: 392px;
  }
`;

const Anchor = styled.a`
  text-decoration: underline;
  color: var(--color-black);
`;
