import { recordTransformer, camelCase } from 'lib/utils';

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/projects/audio/merge`;

interface FetchParameters {
  channelId: string;
  eventId: string;
  token: string;
  pages: {
    duration: number;
    textData: {
      mlModel: string;
      text: string;
      volume: number;
      speed: number;
      duration: number;
    }[];
  }[];
  signal?: AbortSignal;
}

interface FetchResponse {
  downloadUrl: string | null;
}

export default async function fetchMergeAudio({
  channelId,
  eventId,
  token,
  pages,
  signal,
}: FetchParameters) {
  const response = await fetch(URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      channel_id: channelId,
      event_id: eventId,
      pages: pages.map((page) => ({
        duration: page.duration,
        text_data: page.textData.map((el) => ({
          ml_model: el.mlModel,
          text: el.text,
          volume: el.volume,
          speed: el.speed,
          duration: el.duration,
        })),
      })),
    }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(JSON.stringify(data));
  }

  return recordTransformer(data, camelCase) as FetchResponse;
}
