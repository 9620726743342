import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { selectCurrentPagePreview } from 'features/editor/editorSlice';
import { throttle } from 'lib/utils';
import ContentContainer from './ContentContainer';
import Toolbar from './Toolbar';
import PageList from './PageList.Desktop';
import PageCardList from './PageCardList.Desktop';
import PageTimeline from './PageTimeline';
import Preview from './Preview';

const Content: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const toggleIsOpen = () => setIsOpen((prev) => !prev);

  const previewContent = useAppSelector(selectCurrentPagePreview);

  const ref = React.useRef<HTMLDivElement | null>(null);
  const [springHeight, setSpringHeight] = React.useState<number>(0);

  const setPreviewAspectRatio = React.useCallback(
    (height: number) => {
      const el = ref.current;
      if (el === null) return;

      el.style.width = `${(height / 9) * 16}px`;

      if (previewContent.type === 'video') {
        el.style.height = `${height + 40}px`;
      } else {
        el.style.height = `${height}px`;
      }
    },
    [previewContent]
  );

  React.useLayoutEffect(() => {
    const listener = throttle(() => {
      const tabWidth = window.innerWidth >= 1440 ? 401 : 0;
      const contentWidth = window.innerWidth - 72 - 250 - tabWidth - 72;
      const contentHeight = window.innerHeight - 64;

      const previewWidth = contentWidth - 64;
      let previewHeight = (previewWidth / 16) * 9;

      if (previewHeight > contentHeight * 0.36 - 64) {
        previewHeight = contentHeight * 0.36 - 64;
      }

      setPreviewAspectRatio(previewHeight);

      if (previewContent.type === 'video') {
        setSpringHeight(previewHeight + 64 + 40);
      } else {
        setSpringHeight(previewHeight + 64);
      }
    });

    listener(undefined);

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [setPreviewAspectRatio, previewContent]);

  const handleMixpanelEvent = () => {
    toggleIsOpen();
  };

  return (
    <>
      <PageCardList />
      <ContentContainer>
        <PreviewBox style={{ height: isOpen ? springHeight : 0 }}>
          <Preview ref={ref} />
        </PreviewBox>
        <Toolbar
          isOpen={isOpen}
          // onClick={() => toggleIsOpen()}
          onClick={handleMixpanelEvent}
        />
        {previewContent.type === 'video' ? <PageTimeline /> : <PageList />}
      </ContentContainer>
    </>
  );
};

export default Content;

const PreviewBox = styled.div`
  background: var(--color-grey-100);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: height 400ms ease-in-out;
`;
