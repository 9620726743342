import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Tabs } from 'components/editor';
import {
  setAudioToolIndex,
  selectCurrentAudioIndex,
} from 'features/editor/editorSlice';
import BackgroundAudioSelect from './BackgroundAudioSelect';
import IntroAudioSelect from './IntroAudioSelect';
import InterPageSoundAudioSelect from './InterPageSoundAudioSelect';

const AudioTool: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'toolbar' });

  const dispatch = useAppDispatch();

  const index = useAppSelector(selectCurrentAudioIndex);

  return (
    <Base>
      <Tabs
        items={[t('music.background'), t('music.intro'), t('music.ips')]}
        selectedIndex={index}
        onClick={(e) => {
          const el =
            e.target instanceof HTMLButtonElement ? e.target : undefined;

          if (el === undefined) return;

          if (el.dataset.idx === undefined) return;

          const idx = Number.parseInt(el.dataset.idx);
          if (Number.isNaN(idx)) return;
          if (idx === 0 || idx === 1 || idx === 2)
            dispatch(setAudioToolIndex(idx));
        }}
      />
      {index === 0 && <BackgroundAudioSelect />}
      {index === 1 && <IntroAudioSelect />}
      {index === 2 && <InterPageSoundAudioSelect />}
    </Base>
  );
};

export default AudioTool;

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-child {
    padding: 8px 16px 0px 16px;
  }
`;
