import * as React from 'react';
import { ClientTemplate } from 'components/client';
import { Membership } from 'components/membership';

const MembershipPage: React.FC = () => {
  return (
    <ClientTemplate>
      <Membership />
    </ClientTemplate>
  );
};

export default MembershipPage;
