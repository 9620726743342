import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector } from 'app/hooks';
import { selectPageCells } from 'features/editor/editorSlice';
import Cell from './Cell';
import NewCell from './NewCell';

interface CellListProps {
  pageKey: string;
}

const CellList: React.FC<CellListProps> = ({ pageKey }) => {
  const cells = useAppSelector(selectPageCells(pageKey));

  if (cells === undefined) return null;
  return (
    <Base>
      {cells.map((cell, idx) => (
        <Cell pageKey={pageKey} cellKey={cell.key} num={idx} key={cell.key} />
      ))}
      <NewCell pageKey={pageKey} />
    </Base>
  );
};

export default CellList;

const Base = styled.ul`
  list-style: none;
  padding: 0;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
