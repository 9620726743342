import { nanoid } from '@reduxjs/toolkit';
import i18n from 'locales';
import {
  SLIDE_MIN_DURATION,
  SLIDE_MAX_DURATION,
  VOICE_DEFAULT_VOLUME,
  VOICE_DEFAULT_SPEED,
  AUDIO_ITEM_DEFAULT_VOLUME,
} from 'features/editor/constants';
import type { VideoModel } from 'app/services/videoModel';
import type {
  Project,
  FetchProjectByIdData,
  PageEntity,
  CellEntity,
  ClosedCaptionState,
  StvLocation,
  STVOptions,
  DataState,
  UIState,
  AudioToolState,
  S3Path,
  TimelineSection,
} from './types';

const makeType = (prefix: string, type: string) => `${prefix}/${type}`;

const EDITOR_ACTION_PREFIX = 'editor';
export const editorActionTypeCreator = (type: string) =>
  makeType(EDITOR_ACTION_PREFIX, type);

export const createSTVOptions = (
  location: StvLocation,
  resource: string | null,
  videoModelName: string | null,
  audioModelName: string | null,
  videoModels?: VideoModel[]
): STVOptions | undefined => {
  if (typeof resource !== 'string') return undefined;
  if (resource === '') return undefined;
  if (typeof videoModelName !== 'string') return undefined;
  if (videoModelName === '') return undefined;

  let tempAudioModelName = audioModelName ?? '';

  if (typeof audioModelName !== 'string' || audioModelName === '') {
    const model = videoModels?.find((el) => el.name === videoModelName);
    if (model === undefined) return undefined;

    tempAudioModelName = model.audioMlModelName;
  }
  return {
    location,
    resource,
    videoModelName,
    audioModelName: tempAudioModelName,
  };
};

export const serializeProjectToDataState = (
  item: Project,
  videoModels?: VideoModel[]
): FetchProjectByIdData => {
  const pages: PageEntity[] = item.pageSet.map((page) => {
    let cells: CellEntity[] = page.cells.map((cell) => ({
      key: nanoid(),
      uuid: cell.cellUuid,
      text: cell.text,
      duration: cell.duration,
      volume: cell.volume || VOICE_DEFAULT_VOLUME,
      speed: cell.speed || VOICE_DEFAULT_SPEED,
      mlModelName: cell.mlModelName,
      displayText: cell.displayText,
    }));

    const stv = createSTVOptions(
      page.stvVideoLocation,
      page.stvVideoResourceName,
      page.defaultVideoMlModel,
      page.cells?.[0]?.mlModelName ?? null,
      videoModels
    );

    return {
      key: nanoid(),
      num: page.pageNum,
      duration: setSafeSlideDuration(page.intervalToNextPage),
      uuid: page.pageUuid,
      thumbnailUrl: page.thumbS3Path?.url,
      thumbnailPath: page.thumbS3Path?.path,
      imageUrl: page.imageS3Path?.url,
      imagePath: page.imageS3Path?.path,
      videoUrl: page.videoS3Path?.url,
      videoPath: page.videoS3Path?.path,
      isTempImage: false,
      cells,
      stv,
    };
  });

  const _origin = JSON.stringify(item, undefined, 0);

  return {
    uuid: item.projectUuid,
    name: item.projectName,
    updateDate: item.updateDate,
    music: {
      background: item.bgMusic ?? undefined,
      intro: item.introMusic ?? undefined,
      ips: item.interPageSound ?? undefined,
      backgroundVolume: item.bgMusicVolume ?? AUDIO_ITEM_DEFAULT_VOLUME,
      introVolume: item.introMusicVolume ?? AUDIO_ITEM_DEFAULT_VOLUME,
      ipsVolume: item.interPageSoundVolume ?? AUDIO_ITEM_DEFAULT_VOLUME,
    },
    cc: {
      use: item.useSubtitle,
      fontSize: item.subtitleFontSize,
      fontColor: item.subtitleFontColor,
      backgroundColor: item.subtitleFontBgColor,
      backgroundOpacity: item.subtitleFontBgOpacity,
    },
    watermarkType: item.watermarkType ?? undefined,
    pages,
    videoHash: item.videoHash,
    rs: item.renderingStatus,
    type: item.type,
    _origin,
    _modified: false,
  };
};

export const serializeCellEntityToCell = (item: CellEntity) => {
  const temp = {
    text: item.text,
    duration: item.duration,
    volume: item.volume || VOICE_DEFAULT_VOLUME,
    speed: item.speed || VOICE_DEFAULT_SPEED,
    ml_model_name: item.mlModelName,
    display_text: item.displayText,
  };

  if (typeof item.uuid === 'undefined' || item.uuid === undefined) {
    return temp;
  }

  if (item.uuid === null) return temp;

  const cell_uuid = item.uuid;
  return {
    ...temp,
    cell_uuid,
  };
};

export const serializePageEntityToPage = (item: PageEntity) => {
  let temp = {
    page_num: item.num,
    interval_to_next_page: setSafeSlideDuration(item.duration),
    cells: item.cells.map(serializeCellEntityToCell),
    default_video_ml_model: item.stv?.videoModelName ?? null,
    stv_video_resource_name: item.stv?.resource ?? null,
    stv_video_location: item.stv?.location ?? null,
  };

  temp['image_s3_path'] = setS3Path(item.imagePath, item.imageUrl);
  temp['thumb_s3_path'] = setS3Path(item.thumbnailPath, item.thumbnailUrl);
  temp['video_s3_path'] = setS3Path(item.videoPath, item.videoUrl);

  return item.uuid === undefined ? temp : { ...temp, page_uuid: item.uuid };
};

export const serializeStateToProjectString = (
  item: DataState & UIState
): string => {
  let data = {};

  if (item.uuid) data['project_uuid'] = item.uuid;

  data = addProjectName(data, item.name);
  data = addPageSet(data, item.pages);
  data = addCCOptions(data, item.closedCaptionState);
  data = addAudioOptions(data, item.audioToolState);
  data = addProjectType(data, item.pages);

  return JSON.stringify(data, undefined, 0);
};

const addProjectName = (data: Record<string, any>, name?: string) => ({
  ...data,
  project_name:
    name === undefined || name === ''
      ? i18n.t(`editor:emptyProject.name`)
      : name,
});

const addPageSet = (data: Record<string, any>, pages: PageEntity[]) => ({
  ...data,
  page_set: pages.map(serializePageEntityToPage),
});

const addCCOptions = (
  data: Record<string, any>,
  ccState: ClosedCaptionState
) => ({
  ...data,
  use_subtitle: ccState.use,
  subtitle_font_bg_color: ccState.backgroundColor,
  subtitle_font_bg_opacity: ccState.backgroundOpacity,
  subtitle_font_color: ccState.fontColor,
  subtitle_font_size: ccState.fontSize,
});

const addAudioOptions = (
  data: Record<string, any>,
  audioState: AudioToolState
) => ({
  ...data,
  bg_music: audioState.background ?? null,
  intro_music: audioState.intro ?? null,
  inter_page_sound: audioState.ips ?? null,
  bg_music_volume: audioState.backgroundVolume ?? AUDIO_ITEM_DEFAULT_VOLUME,
  intro_music_volume: audioState.introVolume ?? AUDIO_ITEM_DEFAULT_VOLUME,
  inter_page_sound_volume: audioState.ipsVolume ?? AUDIO_ITEM_DEFAULT_VOLUME,
});

const addProjectType = (data: Record<string, any>, pages: PageEntity[]) => {
  for (let i = 0; i < pages.length; i++) {
    if (pages[i].videoPath && pages[i].videoUrl) {
      return { ...data, type: 'desktop' };
    }
  }

  return { ...data, type: 'all' };
};

export const isFullWordbook = (
  wordbook: Record<string, string | null>
): wordbook is Record<string, string> => {
  const keys = Object.keys(wordbook);

  if (keys.length === 0) return true;

  keys.forEach((key) => {
    if (wordbook[key] === undefined) return false;
    if (wordbook[key] === null) return false;
  });

  return true;
};

export const createCellsByExtractFile = (
  cells: {
    text: string;
    displayText: string;
    duration: number;
  }[],
  wordbook: Record<string, string>,
  mlModelName: string
): CellEntity[] =>
  cells.map((cell) => {
    let tempText = cell.text;

    Object.keys(wordbook).forEach((key) => {
      tempText.replaceAll(key, wordbook[key]);
    });

    return {
      key: nanoid(),
      text: tempText,
      displayText: cell.displayText,
      duration: cell.duration,
      speed: VOICE_DEFAULT_SPEED,
      volume: VOICE_DEFAULT_VOLUME,
      mlModelName,
    };
  });

export const isAvailableCC = (
  options: ClosedCaptionState
): options is Required<ClosedCaptionState> => {
  if (!options.use) return false;
  if (options.backgroundColor === undefined) return false;
  if (options.backgroundOpacity === undefined) return false;
  if (options.fontColor === undefined) return false;
  if (options.fontSize === undefined) return false;
  return true;
};

export const isUpdateAfterModified = (
  data: DataState,
  payload: FetchProjectByIdData
): boolean => {
  if (data.name !== payload.name) return true;
  if (data.pages.length !== payload.pages.length) return true;
  if (
    data.pages.flatMap((el) => el.cells).length !==
    payload.pages.flatMap((el) => el.cells).length
  )
    return true;

  for (let pageIndex = 0; pageIndex < data.pages.length; pageIndex++) {
    const page = data.pages[pageIndex];
    const updatedPage = payload.pages[pageIndex];

    if (updatedPage === undefined) return true;
    if (page.isTempImage) return true;
    if ((page?.uuid ?? 1) !== (updatedPage?.uuid ?? 2)) return true;
    if (page.duration !== updatedPage.duration) return true;
    if (page.cells.length !== updatedPage.cells.length) return true;

    if (page.stv) {
      if (updatedPage.stv === undefined) return true;
      if (page.stv.videoModelName !== updatedPage.stv.videoModelName)
        return true;
      if (page.stv.resource !== updatedPage.stv.resource) return true;
      if (page.stv.audioModelName !== updatedPage.stv.audioModelName)
        return true;
      if (page.stv.location.x !== updatedPage.stv.location.x) return true;
      if (page.stv.location.y !== updatedPage.stv.location.y) return true;
      if (page.stv.location.width !== updatedPage.stv.location.width)
        return true;
      if (page.stv.location.height !== updatedPage.stv.location.height)
        return true;
    }

    for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
      const cell = page.cells[cellIndex];
      const updatedCell = updatedPage.cells[cellIndex];

      if (updatedCell === undefined) return true;
      if ((cell?.uuid ?? 1) !== (updatedCell.uuid ?? 2)) return true;
      if (cell.duration !== updatedCell.duration) return true;
      if (cell.text.trim() !== updatedCell.text.trim()) return true;
      if (cell.displayText.trim() !== updatedCell.displayText.trim())
        return true;
      if (cell.mlModelName !== updatedCell.mlModelName) return true;
    }
  }

  return false;
};

export const setDataStateFromUpdated = (
  origin: DataState,
  updated: DataState
): DataState => {
  return {
    ...updated,
    pages: setPageEntitiesFromUpdated(origin.pages, updated.pages),
  };
};

export const setPageEntitiesFromUpdated = (
  origin: PageEntity[],
  updated: PageEntity[]
): PageEntity[] => {
  return origin.map((originPage, originIdx) => {
    const updatedPage = updated[originIdx];
    if (updatedPage === undefined) return originPage;

    let updatedCellIdx = 0;

    const cells = originPage.cells.reduce<CellEntity[]>((acc, item) => {
      const updatedCell = updatedPage.cells[updatedCellIdx];

      if (updatedCell === undefined) {
        const { uuid: _, ...rest } = item;
        acc.push(rest);
        return acc;
      }

      acc.push({
        ...item,
        displayText:
          item.displayText === '' ? updatedCell.displayText : item.displayText,
        uuid: updatedCell.uuid,
      });
      updatedCellIdx++;
      return acc;
    }, []);

    return {
      ...originPage,
      uuid: updatedPage.uuid,
      ...(originPage.isTempImage && {
        thumbnailPath: updatedPage.thumbnailPath,
        thumbnailUrl: updatedPage.thumbnailUrl,
        imagePath: updatedPage.imagePath,
        imageUrl: updatedPage.imageUrl,
      }),
      isTempImage: false,
      cells,
    };
  });
};

/**
 * @name setEmptyProjectName
 * @description 프로젝트 이름이 비어있을 경우 placeholder 값을 넣어줌 /
 */
export const setEmptyProjectName = (name?: string) =>
  name === undefined || name === '' ? i18n.t(`editor:emptyProject.name`) : name;

/**
 * @name validateSlideDuration
 * @param duration number
 * @return -1 | 0 | 1;
 * @description
 * 슬라이드 간격의 최소(1초) 및 최대(5초)값 검증
 * 최소값보다 작은 경우 -1, 큰 경우 1, 범위 내부인 경우 0을 반환
 */
export const validateSlideDuration = (duration: number): -1 | 0 | 1 => {
  if (duration < SLIDE_MIN_DURATION) return -1;
  if (duration > SLIDE_MAX_DURATION) return 1;
  return 0;
};

const setSafeSlideDuration = (currentDuration: number): number => {
  switch (validateSlideDuration(currentDuration)) {
    case -1: {
      return SLIDE_MIN_DURATION;
    }
    case 0: {
      return currentDuration;
    }
    case 1: {
      return SLIDE_MAX_DURATION;
    }
    default: {
      return currentDuration;
    }
  }
};

const setS3Path = (path?: string, url?: string): S3Path | null => {
  if (path === undefined) return null;
  if (url === undefined) return null;
  return { path, url };
};

export const isS3Path = (obj: Partial<S3Path>): obj is S3Path => {
  if (obj.path === undefined) return false;
  if (obj.path === '') return false;
  if (obj.url === undefined) return false;
  if (obj.url === '') return false;
  return true;
};

const appendZero = (num: number) => (num < 10 ? `0${num}` : num);

export const formattedTime = (ms: number): string => {
  let temp = ms;

  const minutes = Math.floor(ms / 60 / 1000);
  temp -= minutes * 60 * 1000;
  const seconds = Math.floor(temp / 1000);
  temp -= seconds * 1000;
  const millis = Math.floor(temp / 10);

  return `${appendZero(minutes)}:${appendZero(seconds)}.${appendZero(millis)}`;
};

export type TimelineMultiplier = 1 | 2 | 3 | 4 | 5;

export const isTimelineMultiplier = (v: number): v is TimelineMultiplier => {
  if (v < 1 || v > 5) return false;
  if (!Number.isInteger(v)) return false;
  return true;
};

export const getSafetyRoundupMaxTime = (
  maxTime: number,
  multiplier: number
) => {
  let ret = maxTime;

  const remain = maxTime % (multiplier * 1000);

  if (remain !== 0) {
    ret = ret - remain + multiplier * 1000;
  }

  return ret;
};

export const getTrackCount = (maxTime: number, multiplier: number) => {
  const guideMaxTime = getSafetyRoundupMaxTime(maxTime, multiplier);

  return guideMaxTime / multiplier / 1000;
};

export const getTimeGap = (multiplier: number) => multiplier * 1000;

export const getEnableTimelineSection = ({
  cells,
  maxTime,
  ignoreKey,
}: {
  cells: CellEntity[];
  maxTime: number;
  ignoreKey?: string;
}): TimelineSection[] => {
  let section: TimelineSection[] = [];

  for (let i = 0; i < cells.length; i++) {
    let cell: CellEntity = cells[i];
    let nextCell: CellEntity = cells[i + 1];

    if (ignoreKey) {
      if (cell.key === ignoreKey) continue;
      if (nextCell && nextCell.key === ignoreKey) nextCell = cells[i + 2];
    }

    if (cell.startTime === undefined || cell.audioDuration === undefined) {
      console.error('Cell data initialization error');
      break;
    }

    const start = cell.startTime + cell.audioDuration;
    const end = nextCell?.startTime ?? maxTime;

    if (end > start) section.push({ cellIndex: i, start, end });
  }

  return section;
};

export const getBlobDuration = async (blob) => {
  const tempVideoEl = document.createElement('video');

  const duration = new Promise<number | undefined>((resolve, reject) => {
    tempVideoEl.addEventListener('loadedmetadata', () => {
      // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (tempVideoEl.duration === Infinity) {
        tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
        tempVideoEl.ontimeupdate = () => {
          tempVideoEl.ontimeupdate = null;
          resolve(tempVideoEl.duration * 1000);
          tempVideoEl.currentTime = 0;
        };
      }
      // Normal behavior
      else resolve(tempVideoEl.duration * 1000);
    });
    tempVideoEl.onerror = () => resolve(undefined);
  });

  tempVideoEl.src = URL.createObjectURL(blob);

  return duration;
};
