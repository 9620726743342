import * as React from 'react';
import { useAudio } from 'react-use';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AudioManager from 'lib/AudioManager';
import { TailSpinIcon, PlayCircleIcon, StopCircleIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import {
  selectCellText,
  selectCellVolume,
  selectCellSpeed,
  selectCellModelName,
  fetchMergeMlModelText,
} from 'features/editor/editorSlice';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
  usePusherChannelId,
} from 'features/editor/providers';

interface CellPlayButtonProps {
  cellKey: string;
}

const CellPlayButton: React.FC<CellPlayButtonProps> = ({ cellKey }) => {
  const text = useAppSelector(selectCellText(cellKey));
  const volume = useAppSelector(selectCellVolume(cellKey));
  const speed = useAppSelector(selectCellSpeed(cellKey));
  const name = useAppSelector(selectCellModelName(cellKey));

  const dispatch = useAppDispatch();

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const prevKey = React.useRef<string | null>(null);

  const channelId = usePusherChannelId();

  const handleClickPlay = async () => {
    if (text === undefined) return;
    if (text.trim().length === 0) return;
    if (name === undefined) return;
    if (channelId === undefined) return;

    const el = ref.current;
    if (el === null) return;

    const key = `${name}-${text}-${volume}-${speed}`;

    if (prevKey.current === key) {
      if (state.paused) {
        editorAudioPlay(controls);
      } else {
        editorAudioPause();
      }
      return;
    }

    try {
      setMergeLoading(true);
      const blob = await dispatch(
        fetchMergeMlModelText({ channelId, text, volume, speed, name })
      ).unwrap();

      prevKey.current = key;
      const url = AudioManager.createObjectUrl(blob);
      el.src = url;
    } catch (error) {
      setMergeLoading(false);
      console.error(error);
    }
  };

  if (text === undefined) return null;
  if (name === undefined) return null;
  return (
    <IconButton size={20} radius={4} onClick={handleClickPlay}>
      {mergeLoading ? (
        <TailSpinIcon width={20} height={20} />
      ) : (
        <>
          {state.paused ? (
            <PlayCircleIcon size={20} color={'currentColor'} weight={'fill'} />
          ) : (
            <StopCircleIcon size={20} color={'currentColor'} weight={'fill'} />
          )}
        </>
      )}
      <>{element}</>
    </IconButton>
  );
};

export default CellPlayButton;
