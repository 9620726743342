import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useFloating } from '@floating-ui/react-dom';
import styled from '@emotion/styled';
import { typography } from 'styles';
import { QuestionIcon } from 'assets/svg';
import { useMatchMedia } from 'lib/hooks';

const SourceNotifyPermission: React.FC = () => {
  const { t } = useTranslation('client', {
    keyPrefix: 'membership.productInfo.card',
  });

  const [isOpenTooltip, setIsOpenTooltip] = React.useState<boolean>(false);

  const isMatch = useMatchMedia('(hover: none)');

  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'top',
  });

  return (
    <Box>
      <Text
        ref={reference}
        onClick={() => {
          if (!isMatch) return;
          setIsOpenTooltip((prev) => !prev);
        }}
        onMouseEnter={() => {
          if (isMatch) return;
          setIsOpenTooltip(true);
        }}
        onMouseLeave={() => {
          setIsOpenTooltip(false);
        }}
      >
        <span>{t('permission.sourceNotify')}</span>
        <QuestionIcon size={20} weight={'fill'} color={'currentColor'} />
      </Text>
      {isOpenTooltip && (
        <TooltipBox
          ref={floating}
          style={{
            position: strategy,
            top: y ?? '',
            left: x ?? '',
          }}
        >
          <Trans
            t={t}
            i18nKey={'tooltip.sourceNotify'}
            components={[<strong />]}
          />
        </TooltipBox>
      )}
    </Box>
  );
};

export default SourceNotifyPermission;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  position: relative;
`;

const Text = styled.span`
  ${typography.body.large}
  color: var(--color-danger);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: help;
`;

const TooltipBox = styled.div`
  background: var(--color-black);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  padding: 4px;
  color: var(--color-white);
  ${typography.body.medium}

  &.strong {
    font-weight: 700;
  }
`;
