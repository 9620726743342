import * as React from 'react';
import styled from '@emotion/styled';
import { useAppDispatch } from 'app/hooks';
import { DotsSixVerticalIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { setSelectPageKey } from 'features/editor/editorSlice';
import { LazyImage } from 'features/editor/common';
import BlankPageImg from 'assets/img/blank-page.jpeg';
import PageOptions from './PageOptions';
import PageDuration from './PageDuration';
import VideoTime from './VideoTime';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';

interface PageCardProps {
  pageKey: string;
  num: number;
  isSelected: boolean;
  listeners: DraggableSyntheticListeners;
  thumbnailUrl?: string;
  videoUrl?: string;
}

const PageCard: React.FC<PageCardProps> = ({
  pageKey,
  num,
  isSelected,
  listeners,
  thumbnailUrl,
  videoUrl,
}) => {
  const [isHover, setIsHover] = React.useState<boolean>(false);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  const dispatch = useAppDispatch();

  const handleClickContent = () => {
    dispatch(setSelectPageKey(pageKey));
  };

  const handlePreviewImageError: React.ReactEventHandler<HTMLImageElement> = (
    e
  ) => {
    const el = e.currentTarget;
    el.src = BlankPageImg;
  };

  return (
    <Base isSelected={isSelected}>
      <Content
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClickContent}
      >
        <Box>
          <Number isSelected={isSelected}>{num}</Number>
          <IconButton size={20} radius={4} {...listeners}>
            <DotsSixVerticalIcon
              size={20}
              color={'currentColor'}
              weight={'bold'}
            />
          </IconButton>
        </Box>
        <PreviewBox isSelected={isSelected}>
          {thumbnailUrl && (
            <PreviewImage
              src={thumbnailUrl}
              alt={`page #${num}`}
              onError={handlePreviewImageError}
            />
          )}
          <PageOptions pageKey={pageKey} open={isHover} />
          <VideoTime url={videoUrl} />
        </PreviewBox>
      </Content>
      <PageDuration pageKey={pageKey} />
    </Base>
  );
};

export default PageCard;

const Base = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 36px;
  position: relative;

  ${({ isSelected }) =>
    isSelected &&
    `
    background: linear-gradient(
        rgba(255, 255, 255, 0.92),
        rgba(255, 255, 255, 0.92)
      )
      var(--color-pink);
  `}
`;

const Content = styled.div`
  height: 90px;
  display: flex;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & button {
    border: 1px solid var(--color-grey-100);
    cursor: grab;
  }
`;

const Number = styled.span<{ isSelected: boolean }>`
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: ${({ isSelected }) =>
    isSelected ? 'var(--color-pink)' : 'var(--color-grey-500)'};
`;

const PreviewBox = styled.div<{ isSelected: boolean }>`
  margin-left: 4px;
  width: 160px;
  height: 100%;
  border: solid 2px
    ${({ isSelected }) =>
      isSelected ? 'var(--color-pink)' : 'var(--color-grey-200)'};
  background: var(--color-white);
  border-radius: 10px;
`;

const PreviewImage = styled(LazyImage)`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
