export type CamelToSnakeCase<T extends string> =
  T extends `${infer U}${infer V}`
    ? `${U extends Uppercase<U>
        ? '_'
        : ''}${Lowercase<U>}${CamelToSnakeCase<V>}`
    : '';

export type SnakeToCamelCase<T extends string> =
  T extends `${infer U}_${infer V}`
    ? `${U}${Capitalize<SnakeToCamelCase<V>>}`
    : T;

export type ObjectToCamel<T> = {
  [K in keyof T as SnakeToCamelCase<string & K>]: T[K] extends Record<
    string,
    any
  >
    ? KeysToCamelCase<T[K]>
    : T[K];
};

export type KeysToCamelCase<T> = {
  [K in keyof T as SnakeToCamelCase<string & K>]: T[K] extends Array<any>
    ? KeysToCamelCase<T[K][number]>[]
    : ObjectToCamel<T[K]>;
};

const isObject = (value: unknown): value is object =>
  value !== null && (typeof value === 'object' || typeof value === 'function');

type AnyRecord = Record<string, any>;

export default function recordTransformer<T extends object>(
  data: T,
  cb: (arg: string) => string
) {
  const recursive = (obj: object): AnyRecord => {
    const keys = data ? Object.keys(obj) : [];

    return keys.reduce<AnyRecord>((acc, item) => {
      const name = item;
      const value = obj[name];

      return {
        ...acc,
        [cb(name)]: Array.isArray(value)
          ? value.map((x) => (isObject(x) ? recursive(x) : x))
          : isObject(value)
          ? recursive(value)
          : value,
      };
    }, {});
  };

  return recursive(data);
}
