class AudioStorage {
  private storage: Record<string, Blob> = {};

  /**
   * @param key // {mlModelName}:{mergeText} style
   */
  set(key: string, data: Blob): void {
    this.storage[key] = data;

    if (process.env.NODE_ENV === 'development') {
      console.log(this.storage);
    }
  }

  get(key: string): Blob | undefined {
    return this.storage[key];
  }

  makeKey(name: string, text: string, volume: number, speed: number): string {
    return `${name}-${text}-${volume}-${speed}`;
  }
}

export default new AudioStorage();
