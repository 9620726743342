import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { typography } from 'styles';

interface NavigateProps {
  index: number;
  internal: boolean;
  href: string;
  children?: React.ReactNode;
}

const Navigate: React.FC<NavigateProps> = ({
  index,
  internal,
  href,
  children,
}) => {
  return internal ? (
    <RouterAnchor to={href}>{children}</RouterAnchor>
  ) : (
    <Anchor href={href} target={'_blank'} rel={'noreferrer'}>
      {children}
    </Anchor>
  );
};

interface CarouselSlideCardProps extends NavigateProps {
  index: number;
  title: string;
  body: string;
  imageUrl: string;
  imageAlt?: string;
}

const CarouselSlideCard: React.FC<CarouselSlideCardProps> = ({
  index,
  title,
  body,
  imageUrl,
  imageAlt = '',
  internal,
  href,
}) => {
  return (
    <div className={'keen-slider__slide'}>
      <Base>
        <Navigate internal={internal} href={href} index={index}>
          <Content>
            <Title>{title}</Title>
            <Body>{body}</Body>
          </Content>
          <Image src={imageUrl} alt={imageAlt} />
        </Navigate>
      </Base>
    </div>
  );
};

export default CarouselSlideCard;

const Base = styled.div`
  display: flex;
  height: 100%;
  margin-right: auto;
  margin-left: auto;

  position: relative;

  width: 320px;
  padding-top: 32px;

  @media (min-width: 820px) {
    width: 100%;
    padding-top: 16px;
    padding-top: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--color-white);

  position: absolute;
  top: 32px;
  text-align: center;

  @media (min-width: 820px) {
    margin: auto auto auto 0;
    top: unset;
    text-align: left;
    left: 16px;
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 820px) {
    flex-direction: row;
  }
`;

const RouterAnchor = Anchor.withComponent(Link);

const Title = styled.span`
  white-space: pre;
  ${typography.title.large}

  @media (min-width: 820px) {
    ${typography.display.medium}
  }
`;

const Body = styled.span`
  margin-top: 8px;
  white-space: pre;
  ${typography.body.large}

  @media (min-width: 820px) {
    ${typography.headline.small}
  }
`;

const Image = styled.img`
  width: 288px;
  height: 230px;

  position: absolute;
  bottom: 0;

  @media (min-width: 820px) {
    bottom: unset;
    right: 16px;
    width: 339px;
    height: 270px;
  }
`;
