import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { LogoIcon, HunetLogoIcon } from 'assets/svg';

interface ContainerProps {
  children?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ children }) => {
  return (
    <Base>
      <LogoBox>
        <LogoAnchor to={'/'}>
          {process.env.ONAIR_PLATFORM_TYPE === 'hunet' && <HunetLogoIcon />}
          <LogoIcon type={'black'} />
        </LogoAnchor>
      </LogoBox>
      {children}
    </Base>
  );
};

export default Container;

const Base = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  background: var(--color-white);

  @media (min-width: 520px) {
    width: 456px;
    height: fit-content;
    padding: 32px;
    border-radius: 12px;
    box-shadow: 0px 4px 24px rgba(51, 51, 51, 0.12);
  }
`;

const LogoBox = styled.div`
  width: 100%;
  padding-top: 40px;
  padding-bottom: 16px;

  @media (min-width: 520px) {
    padding-top: 0;
    padding-bottom: 24px;
  }
`;

const LogoAnchor = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin-left: auto;
  margin-right: auto;
  height: 28px;
  cursor: default;

  svg + svg {
    margin-left: 8px;
  }
`;
