import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { download } from 'lib/utils';
import {
  useChromakeyDownloadProgress,
  useChromakeyDownloadParams,
  useChromakeyDownloadModalClose,
  useChromakeyDownloadRetry,
} from 'features/editor/providers';
import DownloadTemplate from './DownloadTemplate';

const ChromakeyDownload: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'downloadProgress' });

  const progress = useChromakeyDownloadProgress();

  const downloadParams = useChromakeyDownloadParams();

  const navigate = useNavigate();

  const modalClose = useChromakeyDownloadModalClose();

  const handleDownload = async () => {
    if (downloadParams === null) return;

    try {
      download(downloadParams.data, downloadParams.name);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRetry = useChromakeyDownloadRetry();

  return (
    <DownloadTemplate
      id={'chroma-download-modal'}
      title={t('start.chromakey')}
      titleDone={t('start.chromakey')}
      type={'video'}
      progress={progress}
      onClose={() => navigate('/client', { replace: true })}
      onEnded={modalClose}
      onDownload={handleDownload}
      onRetry={handleRetry}
    />
  );
};

export default ChromakeyDownload;
