import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectTimelineEditableDisplayText,
  modifyTimelineEditableCell,
} from 'features/editor/editorSlice';

const MAX_LINE = 10;
const MAX_LENGTH = 300;

const CellCCInput: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'content' });

  const dispatch = useAppDispatch();

  const displayText = useAppSelector(selectTimelineEditableDisplayText);

  const getLimitedText = (text: string | undefined) => {
    if (text === undefined) return '';
    const lines = text.replace(/\r\n/g, '\n').split('\n');
    return lines.slice(0, MAX_LINE).join('\n').slice(0, MAX_LENGTH);
  };

  const handleChangeTextArea: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    if (e.target === null) return;
    const text = getLimitedText(e.target.value);

    dispatch(modifyTimelineEditableCell({ displayText: text }));
  };

  const ref = React.useRef<HTMLTextAreaElement | null>(null);

  const handleKeydownTextArea: React.KeyboardEventHandler<
    HTMLTextAreaElement
  > = (e) => {
    if (e.target === null) return;

    const el = ref.current;
    if (el === null) return;

    const lines = el.value.replace(/\r\n/g, '\n').split('\n');
    if (lines.length >= MAX_LINE && e.key === 'Enter') {
      e.preventDefault();
    }

    dispatch(modifyTimelineEditableCell({ displayText: lines.join() }));
  };

  return (
    <Base>
      <TextareaAutosize
        cacheMeasurements
        placeholder={t('placeholder.cellText')}
        maxLength={MAX_LENGTH}
        value={displayText}
        onChange={handleChangeTextArea}
        onKeyDown={handleKeydownTextArea}
        ref={ref}
      />
      <LimitBox>
        <TextLength isError={displayText.length > MAX_LENGTH}>
          {displayText.length}/{MAX_LENGTH}
        </TextLength>
      </LimitBox>
    </Base>
  );
};

export default CellCCInput;

const Base = styled.div`
  background: var(--color-white);
  display: flex;
  align-items: center;
  padding: 8px 4px 8px 8px;
  overflow: hidden;

  & textarea {
    width: 100%;
    padding: 0;
    padding-right: 8px;
    margin: 0;
    border: none;
    outline: none;
    color: var(--color-black);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    resize: none;
    overflow: hidden;

    &::placeholder {
      color: var(--color-grey-700);
    }
  }
`;

const LimitBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 4px 0;
`;

const TextLength = styled.span<{ isError: boolean | undefined }>`
  display: inline-flex;
  margin-left: auto;
  font-size: 12px;
  color: ${({ isError }) =>
    isError ? 'var(--color-danger)' : 'var(--color-grey-600)'};
  line-height: 1.4;
  user-select: none;
`;
