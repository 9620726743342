export default function throttle<T extends unknown>(cb: (args: T) => void) {
  let rAF: number | undefined;

  return (args: T) => {
    if (rAF) window.cancelAnimationFrame(rAF);

    rAF = window.requestAnimationFrame(() => {
      cb(args);
    });
  };
}
