import * as React from 'react';
import styled from '@emotion/styled';
import FeatureContent from './FeatureContent';
import type { FeatureContentProps } from './FeatureContent';

interface FeatureBoxProps extends FeatureContentProps {
  imageUrl?: string;
}

const FeatureBox: React.FC<FeatureBoxProps> = ({
  tag,
  title,
  body,
  register,
  stepNumber,
  imageUrl,
  reverse = false,
}) => {
  return (
    <Base>
      <StepBox>
        <FeatureContent
          tag={tag}
          title={title}
          body={body}
          register={register}
          stepNumber={stepNumber}
          reverse={reverse}
        />
      </StepBox>
      <ImageBox>
        <Image src={imageUrl} />
      </ImageBox>
    </Base>
  );
};

export default FeatureBox;

const Base = styled.div`
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-right: 16px;

  & + & {
    margin-top: 40px;
  }

  @media (min-width: 1120px) {
    width: 1120px;
  }

  @media (min-width: 1440px) {
    & + & {
      margin-top: 80px;
    }
  }
`;

const StepBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const ImageBox = styled.div`
  margin-top: 40px;
  width: 100%;
`;

const Image = styled.img`
  width: 100%;

  box-shadow: 0px 4px 12px rgba(51, 51, 51, 0.16);
  border-radius: 16px;
  border: 1px solid var(--color-grey-200);

  @media (min-width: 1120px) {
    width: 1120px;
    height: 613px;
  }
`;
