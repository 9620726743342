import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { removeAuthPersistStore } from 'app/authPersistEnhance';
import { useRequestWithdrawalMutation } from 'app/services/userInfo';
import { ArrowLeftIcon, CheckSquareIcon, SquareIcon } from 'assets/svg';
import { typography } from 'styles';

type ReasonType = 'noUse' | 'badFeature' | 'badService' | 'useOther' | 'etc';

const reasons: ReasonType[] = [
  'noUse',
  'badFeature',
  'badService',
  'useOther',
  'etc',
];

const Withdrawal: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'userProfile' });

  const [selectedReason, setSelectedReason] = React.useState<ReasonType | null>(
    null
  );

  const [agreement, setAgreement] = React.useState<boolean>(false);
  const toggleAgreement = () => setAgreement((prev) => !prev);

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

  const [requestWithdrawal] = useRequestWithdrawalMutation();

  const navigate = useNavigate();

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();
    if (selectedReason === null) return;
    if (!agreement) return;

    const textareaValue = textareaRef.current?.value ?? '';

    const reason: string =
      selectedReason === 'etc'
        ? textareaValue
        : t(`withdrawalReasons.${selectedReason}`);

    try {
      await requestWithdrawal(reason).unwrap();

      removeAuthPersistStore();
      navigate('/client/withdrawal-done', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Base>
      <TitleBox>
        <GoProfileButton to={'/client/profile'} replace>
          <ArrowLeftIcon size={24} weight={'bold'} />
        </GoProfileButton>
        <Title>{t('title.withdrawal')}</Title>
      </TitleBox>
      <FormBox onSubmit={handleSubmit}>
        <SelectBox>
          {reasons.map((reason) => (
            <SelectItem
              isSelected={reason === selectedReason}
              onClick={() => {
                setSelectedReason(reason);
              }}
              key={reason}
            >
              {t(`withdrawalReasons.${reason}`)}
            </SelectItem>
          ))}
          {selectedReason === 'etc' && (
            <ReasonTextArea
              placeholder={t('placeholder.withdrawal')}
              disabled={selectedReason !== 'etc'}
              autoFocus
              ref={textareaRef}
            />
          )}
        </SelectBox>
        {selectedReason && (
          <AgreementBox onClick={toggleAgreement}>
            <Checkbox type={'button'}>
              {agreement ? (
                <CheckSquareIcon
                  size={24}
                  color={'var(--color-pink)'}
                  weight={'fill'}
                />
              ) : (
                <SquareIcon
                  size={24}
                  weight={'bold'}
                  color={'var(--color-grey-500)'}
                />
              )}
            </Checkbox>
            <span>{t('button.withdrawalAgree')}</span>
          </AgreementBox>
        )}
        {agreement && (
          <ButtonBox>
            <CancelButton to={'/client/profile'}>
              {t('button.cancel')}
            </CancelButton>
            <SubmitButton type={'submit'}>
              {t('button.withdrawalSubmit')}
            </SubmitButton>
          </ButtonBox>
        )}
      </FormBox>
    </Base>
  );
};

export default Withdrawal;

const Base = styled.div`
  width: 100%;
`;

const TitleBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;

  @media (min-width: 520px) {
    margin-bottom: 8px;
  }

  @media (min-width: 1024px) {
    margin-bottom: 12px;
  }
`;

const GoProfileButton = styled(Link)`
  text-decoration: none;
  background: none;
  border: none;
  padding: 4px;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  color: var(--color-black);
`;

const Title = styled.span`
  ${typography.title.large}
`;

const SelectBox = styled.ul`
  list-style: none;
  margin: 0 0 24px 0;
  padding: 0;
`;

const SelectItem = styled.li<{ isSelected: boolean }>`
  width: fit-content;
  background: var(--color-white);
  color: ${(props) =>
    props.isSelected ? 'var(--color-pink)' : 'var(--color-grey-600)'};
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) =>
    props.isSelected ? 'var(--color-pink)' : 'var(--color-grey-300)'};
  border-radius: 18px;
  padding: 5px 15px;
  transition: color 225ms, border-color 225ms;
  margin-bottom: 12px;
  cursor: pointer;
  ${typography.body.large}

  &:last-of-type {
    margin-bottom: 16px;
  }
`;

const ReasonTextArea = styled.textarea`
  width: 100%;
  height: 125px;
  border: 1px solid var(--color-grey-300);
  background-color: var(--color-white);
  color: var(--color-black);
  padding: 12px;
  border-radius: 10px;
  resize: none;
  outline: none;
  transition: color 225ms, background-color 225ms;
  ${typography.body.large}

  &::placeholder {
    color: var(--color-grey-600);
  }

  &:focus {
  }

  &:disabled {
    color: var(--color-grey-700);
    background-color: var(--color-grey-100);
  }
`;

const AgreementBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.4;
  ${typography.label.medium}
  color: var(--color-black);
  margin-bottom: 24px;

  span {
    margin-left: 4px;
    user-select: none;
  }
`;

const Checkbox = styled.button`
  --hover-background: rgba(51, 51, 51, 0.08);
  --active-background: rgba(51, 51, 51, 0.16);

  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: var(--hover-background);

    @media (hover: none) {
      background: var(--active-background);
    }
  }

  &:active {
    background: var(--active-background);
  }

  &[data-checked='true'] {
    --hover-background: rgba(255, 55, 110, 0.08);
    --active-background: rgba(255, 55, 110, 0.16);
  }
`;

const FormBox = styled.form`
  width: 100%;
  max-width: fit-content;

  & > div {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`;

const CancelButton = styled(Link)`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  display: inline-flex;
  text-decoration: none;
  border: none;
  border-radius: 10px;
  height: 48px;
  width: 96px;
  align-items: center;
  justify-content: center;

  ${typography.title.medium}
  color: var(--color-grey-700);
  background: var(--color-grey-200);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-grey-200);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-grey-200);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-grey-200);
  }
`;

const SubmitButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  border: none;
  border-radius: 10px;
  height: 48px;
  width: 287px;

  ${typography.title.medium}
  color: var(--color-white);
  background: var(--color-pink);
  cursor: pointer;

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;
