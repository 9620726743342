import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { alphabetToKorean } from 'lib/utils';
import { XIcon } from 'assets/svg';
import { Modal, Toast } from 'components/common';
import { IconButton } from 'components/common';
import WordbookItem from './SlideUpload.WordbookItem';

const HELPER_TEXT = '예) pronounciation → 프로넌시에이션';

type Data = Record<string, string | null>;

interface WordbookProps {
  datas: Data;
  onSubmit: (newDatas: Data) => void;
}

const Wordbook: React.FC<WordbookProps> = ({ datas, onSubmit }) => {
  const { t } = useTranslation('editor', { keyPrefix: 'slideUpload' });

  const refs = React.useRef<Map<string, HTMLInputElement>>(new Map());

  const [openToast, setOpenToast] = React.useState<boolean>(false);

  const handleClickSubmit = () => {
    const map = refs.current;

    let ret: Record<string, string> = {};

    map.forEach((el, key) => {
      const value = el.value;

      if (value === '') {
        setOpenToast(true);
        return;
      }

      if (/[a-zA-Z]/g.test(value)) {
        setOpenToast(true);
        return;
      }

      ret[key] = value;
    });

    onSubmit(ret);
  };

  return (
    <>
      <Modal id={'wordbook-process-modal'}>
        <Base>
          <TitleBox>
            <Title>{t('title.wordbook')}</Title>
            <IconButton size={24} radius={6}>
              <XIcon size={20} weight={'bold'} />
            </IconButton>
          </TitleBox>
          <SubtitleBox>
            <Subtitle>{t('subtitle.wordbook')}</Subtitle>
            <HelperText>{HELPER_TEXT}</HelperText>
          </SubtitleBox>
          <Divider />
          <List>
            {Object.keys(datas).map((key) => (
              <WordbookItem
                wordKey={key}
                defaultValue={datas[key] || alphabetToKorean(key)}
                ref={(el) => {
                  if (el === null) return;

                  refs.current.set(key, el);
                }}
                key={key}
              />
            ))}
          </List>
          <SubmitBox>
            <SubmitButton onClick={handleClickSubmit}>
              {t('button.wordbook')}
            </SubmitButton>
          </SubmitBox>
        </Base>
      </Modal>
      <Toast
        timeout={1000}
        in={openToast}
        onClose={() => setOpenToast(false)}
        message={t('toast.wordbook')}
        severity={'error'}
      />
    </>
  );
};

export default Wordbook;

const Base = styled.div`
  width: 416px;
  min-height: 440px;
  max-height: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border-radius: 10px;
`;

const TitleBox = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;

  button {
    color: var(--color-black);
  }
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
  margin-right: auto;
`;

const SubtitleBox = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;

  font-size: 16px;
  line-height: 1.4;
`;

const Subtitle = styled.span`
  white-space: pre;
  color: var(--color-black);
`;

const HelperText = styled.span`
  margin-top: 8px;
  color: var(--color-grey-700);
`;

const Divider = styled.hr`
  margin: 16px 24px;
  border: none;
  width: calc(100% - 48px);
  height: 1px;
  background: var(--color-grey-100);
`;

const List = styled.ul`
  flex: 1;
  margin: 0;
  padding: 0 24px;
  overflow-y: auto;
  list-style: none;
`;

const SubmitBox = styled.div`
  margin: 12px 24px;
`;

const SubmitButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  outline: none;
  padding: 0 16px;
  border: none;

  width: 100%;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;

  position: relative;
  z-index: 1;

  color: var(--color-white);
  background: var(--color-pink);

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }
`;
