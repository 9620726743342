import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AudioManager from 'lib/AudioManager';
import { download } from 'lib/utils';
import { TailSpinIcon, DownloadSimpleIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import {
  selectCellText,
  selectCellVolume,
  selectCellSpeed,
  selectCellModelName,
  fetchMergeMlModelText,
} from 'features/editor/editorSlice';
import { usePusherChannelId } from 'features/editor/providers';

interface CellDownloadButtonProps {
  cellKey: string;
}

const CellDownloadButton: React.FC<CellDownloadButtonProps> = ({ cellKey }) => {
  const text = useAppSelector(selectCellText(cellKey));
  const volume = useAppSelector(selectCellVolume(cellKey));
  const speed = useAppSelector(selectCellSpeed(cellKey));
  const name = useAppSelector(selectCellModelName(cellKey));

  const dispatch = useAppDispatch();

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const channelId = usePusherChannelId();

  const handleClickDownload = async () => {
    if (text === undefined) return;
    if (text.trim().length === 0) return;
    if (name === undefined) return;
    if (channelId === undefined) return;

    try {
      setMergeLoading(true);
      const blob = await dispatch(
        fetchMergeMlModelText({ channelId, text, volume, speed, name })
      ).unwrap();
      setMergeLoading(false);

      const url = AudioManager.createObjectUrl(blob);
      download(url, `${text}.wav`);
    } catch (error) {
      setMergeLoading(false);
      console.error(error);
    }
  };
  return (
    <IconButton size={20} radius={4} onClick={handleClickDownload}>
      {mergeLoading ? (
        <TailSpinIcon width={20} height={20} />
      ) : (
        <DownloadSimpleIcon size={20} weight={'bold'} />
      )}
    </IconButton>
  );
};

export default CellDownloadButton;
