const appendZero = (num: number) => (num < 10 ? `0${num}` : num);

export default function formattedTime(
  time: number | undefined,
  remaining = false,
  formatType: 'timestamp' | 'time' = 'timestamp'
) {
  const date = new Date(0, 0, 0, 0, 0, time, 0);

  const hour = appendZero(date.getHours());
  const minute = appendZero(date.getMinutes());
  const second = appendZero(date.getSeconds());

  const minus = remaining ? '-' : '';

  if (formatType === 'timestamp') {
    return hour > 0
      ? `${minus}${hour}:${minute}:${second}`
      : `${minus}${minute}:${second}`;
  }

  return hour > 0
    ? `${minus} ${hour}시간 ${minute}분 ${second}초`
    : minute > 0
    ? `${minus} ${minute}분 ${second}초`
    : `${minus} ${second}초`;
}
