interface FetchParameters {
  token: string;
  file: File;
  signal?: AbortSignal;
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/upload`;

export default async function fetchUploadImage({
  token,
  file,
  signal,
}: FetchParameters) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('type', 'image');

  const response = await fetch(URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
    signal,
  });
  const data = await response.json();

  return {
    imageUrl: data.image.url,
    imagePath: data.image.tmp_path,
    thumbnailUrl: data.thumb.url,
    thumbnailPath: data.thumb.tmp_path,
  };
}
