import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useResetPasswordMutation } from 'app/services/auth';
import { ModalV2 } from 'components/common';
import { typography } from 'styles';
import PinkButton from './PinkButton';
import OutlineButton from './OutlineButton';
import { isFetchBaseQueryError } from './utils';

interface RequestResetPasswordErrorData {
  message: string;
}

const isErrorData = (data: any): data is RequestResetPasswordErrorData => {
  if (data === undefined) return false;
  if (typeof data.message !== 'string') return false;
  return true;
};

interface ResetPasswordForm {
  email: string;
}

const ResetPassword: React.FC = () => {
  const { t } = useTranslation('auth', { keyPrefix: 'resetPassword' });

  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<ResetPasswordForm>();

  const email = React.useRef<string | undefined>(undefined);
  const errorMessage = React.useRef<string>('');

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [requestResetPassword, { isSuccess, isLoading, error }] =
    useResetPasswordMutation();

  React.useEffect(() => {
    if (email === undefined) return;
    if (isSuccess) {
      navigate(`/auth/reset-password/confirm?email=${email.current}`);
    }
  }, [isSuccess, navigate]);

  React.useEffect(() => {
    if (error === undefined) return;
    if (!isFetchBaseQueryError(error)) return;

    const { status } = error;

    if (status === 400) {
      if (!isErrorData(error.data)) return;
      const { message } = error.data;

      if (message === 'Not found user.') {
        errorMessage.current = t('dialog.description.notFound');
      } else if (message === 'User is social account.') {
        errorMessage.current = t('dialog.description.isSocialAccount');
      } else {
        errorMessage.current = t('dialog.description.default');
      }

      setIsOpen(true);
    }
  }, [error, t]);

  const onSubmit = async (data: ResetPasswordForm) => {
    if (isLoading) return;
    try {
      await requestResetPassword(data).unwrap();
      email.current = data.email;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Base>
      <Contents>
        <Title>{t('request.title')}</Title>
        <Description>{t('request.description.email')}</Description>
        <Description>{t('request.description.social')}</Description>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            placeholder={t('request.placeholder')}
            type={'email'}
            {...register('email', { required: true })}
            required
          />
          <PinkButton type={'submit'} loading={isLoading}>
            {t('request.button')}
          </PinkButton>
        </Form>
      </Contents>
      <ModalV2 open={isOpen} onClose={() => setIsOpen(false)}>
        <ErrorModal>
          <Content>
            <ErrorTitle>{t('dialog.title')}</ErrorTitle>
            <ErrorMessage>{errorMessage.current}</ErrorMessage>
          </Content>
          <Actions>
            <OutlineButton onClick={() => setIsOpen(false)}>
              {t('dialog.button')}
            </OutlineButton>
          </Actions>
        </ErrorModal>
      </ModalV2>
    </Base>
  );
};

export default ResetPassword;

const Base = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Contents = styled.div`
  max-width: 420px;
  margin: 40px 0;
  height: fit-content;

  @media (min-width: 520px) {
    max-width: auto;
    margin: 60px 0;
  }
`;

const Title = styled.div`
  ${typography.title.large}
  color: var(--color-black);
  text-align: center;
`;

const Description = styled.div`
  margin-top: 16px;
  ${typography.body.large}
  color: var(--color-black);
`;

const Form = styled.form`
  margin-top: 26px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  button {
    margin-top: 16px;
  }
`;

const Input = styled.input`
  height: 48px;
  width: 100%;
  padding: 12px 15px;
  ${typography.body.large}
  color: var(--color-black);
  border: 1px solid var(--color-grey-300);
  border-radius: 8px;
  outline: none;

  &:hover {
    padding: 11px 14px;
    border: 2px solid var(--color-grey-300);
  }

  &:focus {
    padding: 11px 14px;
    border: 2px solid var(--color-blue);
  }

  &:disabled {
    background-color: var(--color-grey-100);
    color: var(--color-grey-700);
  }

  &::placeholder {
    color: var(--color-grey-600);
  }
`;

const ErrorModal = styled.div`
  width: 440px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-radius: 10px;
  background: var(--color-white);
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ErrorTitle = styled.span`
  ${typography.title.large}
  color: var(--color-black);
`;

const ErrorMessage = styled.span`
  ${typography.body.large}
  color: var(--color-black);
  word-break: keep-all;
`;

const Actions = styled.div`
  margin-top: 26px;
  width: 100%;
`;
