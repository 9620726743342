import * as React from 'react';
import { useMatchMedia } from 'lib/hooks';
import Desktop from './VoiceModelItem.Desktop';
import Mobile from './VoiceModelItem.Mobile';

export interface VoiceModelItemProps {
  name: string;
  modelColor: string;
  displayName: string;
  tags: string[];
  isBest: boolean;
  isNew: boolean;
  language: string;
}

const VoiceModelItem: React.FC<VoiceModelItemProps> = (props) => {
  const isMatch = useMatchMedia('(min-width: 474px)');

  if (isMatch) return <Desktop {...props} />;
  return <Mobile {...props} />;
};

export default VoiceModelItem;
