import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Tabs } from 'components/editor';
import {
  setUploadToolIndex,
  selectCurrentUploadIndex,
} from 'features/editor/editorSlice';
import SlideUpload from './SlideUpload';
import ScriptUpload from './ScriptUpload';

const UploadTool: React.FC = () => {
  const { t } = useTranslation('editor', { keyPrefix: 'toolbar' });

  const dispatch = useAppDispatch();
  const index = useAppSelector(selectCurrentUploadIndex);

  return (
    <Base>
      <Tabs
        items={[t('upload.slide'), t('upload.script')]}
        selectedIndex={index}
        onClick={(e) => {
          const el =
            e.target instanceof HTMLButtonElement ? e.target : undefined;

          if (el === undefined) return;
          if (el.dataset.idx === undefined) return;

          const idx = Number.parseInt(el.dataset.idx);
          if (Number.isNaN(idx)) return;
          if (idx === 0 || idx === 1) dispatch(setUploadToolIndex(idx));
        }}
      />
      {index === 0 && <SlideUpload />}
      {index === 1 && <ScriptUpload />}
    </Base>
  );
};

export default UploadTool;

const Base = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div:first-child {
    padding: 8px 16px 0px 16px;
  }
`;
