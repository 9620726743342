import * as React from 'react';
import { useGetUserInfoQuery } from 'app/services/userInfo';
import constate from 'constate';
import { useAppDispatch } from 'app/hooks';
import { setChannelId } from 'features/editor/editorSlice';
import type { ChildrenProps } from './types';

const useChannelId = () => {
  const { data: userInfo } = useGetUserInfoQuery();

  const channelId = React.useMemo(
    () => userInfo && btoa(`${userInfo.email}:${userInfo.socialAccountInfo}`),
    [userInfo]
  );

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (channelId === undefined) return;

    dispatch(setChannelId(channelId));
  }, [channelId, dispatch]);

  return {
    channelId,
  };
};

const [ChannelIdProvider, usePusherChannelId] = constate(
  useChannelId,
  (state) => state.channelId
);

export const PusherProvider = ({ children }: ChildrenProps) => (
  <ChannelIdProvider>{children}</ChannelIdProvider>
);

export { usePusherChannelId };
