import * as React from 'react';
import { useSearchParams, useNavigate, Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useResetPasswordActiveMutation } from 'app/services/auth';
import { AuthTextField, ValidateCheck } from 'components/auth';
import { passwordValidator } from 'lib/utils';
import { typography } from 'styles';
import PinkButton from './PinkButton';
import { isFetchBaseQueryError } from './utils';

interface RequestResetPasswordActiveErrorData {
  message: string;
  details?: {
    email: string;
  };
}

const isErrorData = (
  data: any
): data is RequestResetPasswordActiveErrorData => {
  if (data === undefined) return false;
  if (typeof data.message !== 'string') return false;
  return true;
};

interface ResetPasswordActivateForm {
  newPassword: string;
  passwordCheck: string;
}

const ResetPasswordActivate: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const uuid = searchParams.get('uid');

  const { t } = useTranslation('auth', { keyPrefix: 'resetPasswordActive' });

  const { register, handleSubmit, watch } =
    useForm<ResetPasswordActivateForm>();

  const watchNewPassword = watch('newPassword', '');
  const watchPasswordCheck = watch('passwordCheck', '');

  const [requestResetPasswordActive, { isSuccess, isLoading, error }] =
    useResetPasswordActiveMutation();

  React.useEffect(() => {
    if (isSuccess) {
      navigate('/auth/reset-password/activate/success', { replace: true });
    }
  }, [isSuccess, navigate]);

  React.useEffect(() => {
    if (error === undefined) return;
    if (!isFetchBaseQueryError(error)) return;
    const { status } = error;

    // TODO: 기타 에러에 대한 페이지 따로 없음
    if (status === 400) {
      console.error(error);
    }

    if (status === 401) {
      if (!isErrorData(error.data)) return;
      const { details } = error.data;

      navigate('/auth/reset-password/activate/token-error', {
        state: { email: details?.email },
      });
    }
  }, [error, navigate]);

  const onSubmit = async (data: ResetPasswordActivateForm) => {
    if (uuid === null || token === null) return;

    try {
      await requestResetPasswordActive({
        uuid,
        token,
        newPassword: data.newPassword,
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  if (uuid === null || token === null)
    return <Navigate to={'/auth/reset-password'} replace />;

  return (
    <Base>
      <Contents>
        <Title>{t('change.title')}</Title>
        <Description>{t('change.description')}</Description>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <AuthTextField
            type={'password'}
            label={t('change.placeholder.new')}
            error={passwordValidator(watchPasswordCheck)}
            {...register('newPassword', {
              required: true,
              maxLength: 20,
              validate: (value) => !passwordValidator(value),
            })}
          />
          <AuthTextField
            type={'password'}
            label={t('change.placeholder.check')}
            {...register('passwordCheck', {
              required: true,
              minLength: 8,
              maxLength: 20,
              validate: (value) => value === watchNewPassword,
            })}
          />
          <ValidateCheck value={watch('newPassword') ?? ''} />
          <PinkButton type={'submit'} loading={isLoading}>
            {t('change.button')}
          </PinkButton>
        </Form>
      </Contents>
    </Base>
  );
};

export default ResetPasswordActivate;

const Base = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Contents = styled.div`
  max-width: 440px;
  margin-top: 40px;
  margin-bottom: 40px;
  height: fit-content;
  padding: 24px;

  @media (min-width: 520px) {
    max-width: auto;
    margin-top: 60px;
  }
`;

const Title = styled.div`
  ${typography.title.large}
  color: var(--color-black);
  text-align: center;
`;

const Description = styled.div`
  margin-top: 16px;
  ${typography.body.large}
  color: var(--color-black);
`;

const Form = styled.form`
  margin-top: 26px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  button {
    margin-top: 16px;
  }
`;
