import * as React from 'react';
import { useAudio } from 'react-use';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AudioManager from 'lib/AudioManager';
import { TailSpinIcon, PlayCircleIcon, PauseCircleIcon } from 'assets/svg';
import {
  selectCellText,
  selectCellModelName,
  fetchMergeMlModelText,
} from 'features/editor/editorSlice';
import {
  useEditorAudioControlsPlay,
  useEditorAudioControlsPause,
  usePusherChannelId,
} from 'features/editor/providers';

interface VoiceOptionType {
  volume: number;
  speed: number;
}

interface ChangeTonePlayButtonProps {
  audioManagerRef: React.MutableRefObject<AudioManager>;
  voiceOption: VoiceOptionType;
  cellKey: string;
}

const ChangeTonePlayButton: React.FC<ChangeTonePlayButtonProps> = ({
  voiceOption,
  cellKey,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'changeTone' });

  const { volume, speed } = voiceOption;

  const text = useAppSelector(selectCellText(cellKey));
  const name = useAppSelector(selectCellModelName(cellKey));

  const dispatch = useAppDispatch();

  const [mergeLoading, setMergeLoading] = React.useState<boolean>(false);

  const editorAudioPlay = useEditorAudioControlsPlay();
  const editorAudioPause = useEditorAudioControlsPause();

  const [element, state, controls, ref] = useAudio({
    src: '',
    onLoadedMetadata() {
      editorAudioPlay(controls);
      setMergeLoading(false);
    },
  });

  const prevKey = React.useRef<string | null>(null);

  const channelId = usePusherChannelId();

  const audioPlay = async () => {
    if (text === undefined) return;
    if (text.trim().length === 0) return;
    if (name === undefined) return;
    if (channelId === undefined) return;

    const el = ref.current;
    if (el === null) return;

    const key = `${name}-${text}-${volume}-${speed}`;

    if (prevKey.current === key) {
      if (state.paused) {
        editorAudioPlay(controls);
      } else {
        editorAudioPause();
      }
      return;
    }

    try {
      setMergeLoading(true);
      const blob = await dispatch(
        fetchMergeMlModelText({ channelId, text, volume, speed, name })
      ).unwrap();

      prevKey.current = key;
      const url = AudioManager.createObjectUrl(blob);
      el.src = url;
    } catch (error) {
      setMergeLoading(false);
      console.error(error);
    }
  };

  return (
    <PlayButton onClick={audioPlay}>
      {mergeLoading ? (
        <TailSpinIcon width={16} height={16} />
      ) : (
        <>
          {state.paused ? (
            <PlayCircleIcon size={16} weight={'fill'} />
          ) : (
            <PauseCircleIcon size={16} weight={'fill'} />
          )}
        </>
      )}
      <span>{state.paused ? t('button.play') : t('button.pause')}</span>
      {element}
    </PlayButton>
  );
};

export default ChangeTonePlayButton;

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  font-size: 12px;
  line-height: 1.4;
  color: var(--color-grey-700);
  cursor: pointer;
  user-select: none;

  span {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
  }
`;
