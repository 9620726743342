import * as React from 'react';
import { useMatchMedia } from 'lib/hooks';
import Desktop from './FeatureContent.Desktop';
import Mobile from './FeatureContent.Mobile';

export interface FeatureContentProps {
  tag?: React.ReactNode;
  title?: React.ReactNode;
  body?: React.ReactNode;
  register?: React.ReactNode;
  stepNumber?: string;
  reverse: boolean;
}

const FeatureContent: React.FC<FeatureContentProps> = (props) => {
  const isTablet = useMatchMedia('(min-width: 820px)');

  if (isTablet) return <Desktop {...props} />;
  return <Mobile {...props} />;
};

export default FeatureContent;
