const appendZero = (num: number) => (num < 10 ? `0${num}` : num);

export const formattedTime = (ms: number): string => {
  let temp = ms;

  const minutes = Math.floor(ms / 60 / 1000);
  temp -= minutes * 60 * 1000;
  const seconds = Math.floor(temp / 1000);
  temp -= seconds * 1000;
  const millis = Math.floor(temp / 10);

  return `${appendZero(minutes)}:${appendZero(seconds)}.${appendZero(millis)}`;
};

export type TimelineMultiplier = 1 | 2 | 3 | 4 | 5;

export const isTimelineMultiplier = (v: number): v is TimelineMultiplier => {
  if (v < 1 || v > 5) return false;
  if (!Number.isInteger(v)) return false;
  return true;
};

export const getSafetyRoundupMaxTime = (
  maxTime: number,
  multiplier: number
) => {
  let ret = maxTime;

  const remain = maxTime % (multiplier * 1000);

  if (remain !== 0) {
    ret = ret - remain + multiplier * 1000;
  }

  return ret;
};

export const getTrackCount = (maxTime: number, multiplier: number) => {
  const guideMaxTime = getSafetyRoundupMaxTime(maxTime, multiplier);

  return guideMaxTime / multiplier / 1000;
};

export const getTimeGap = (multiplier: number) => multiplier * 1000;
