import getAgent from '@egjs/agent';

function fileSave(blob: Blob, options: { fileName: string }) {
  const a = document.createElement('a');
  a.download = options.fileName || 'Untitled';
  a.href = URL.createObjectURL(blob);
  a.addEventListener('click', () => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
}

function openLink(href: string) {
  const a = document.createElement('a');
  a.href = href;
  a.click();
}

async function getBlob(url: string) {
  const headers = new Headers();
  headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');

  const resp = await fetch(url, { headers });
  const blob = await resp.blob();
  return blob;
}

export default async function download(data: Blob | string, fileName: string) {
  const agent = getAgent();

  if (data instanceof Blob) {
    fileSave(data, { fileName });
    return;
  }

  if (agent.browser.webview) {
    openLink(data);
    return;
  }

  if (
    agent.os.name === 'ios' &&
    agent.isMobile &&
    agent.browser.name !== 'safari'
  ) {
    openLink(data);
    return;
  }

  try {
    const blob = await getBlob(data);
    fileSave(blob, { fileName });
  } catch (error) {
    openLink(data);
  }
}
