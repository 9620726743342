import * as React from 'react';
import { useAppDispatch } from 'app/hooks';
import { TrashSimpleIcon } from 'assets/svg';
import { IconButton } from 'components/common';
import { removeCell } from 'features/editor/editorSlice';

interface CellDeleteButtonProps {
  cellKey: string;
}

const CellDeleteButton: React.FC<CellDeleteButtonProps> = ({ cellKey }) => {
  const dispatch = useAppDispatch();

  const handleClickRemove = () => {
    dispatch(removeCell(cellKey));
  };

  return (
    <IconButton size={20} radius={4} onClick={handleClickRemove}>
      <TrashSimpleIcon size={20} color={'currentColor'} weight={'fill'} />
    </IconButton>
  );
};

export default CellDeleteButton;
