import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ClickAwayListener from 'react-click-away-listener';
import styled from '@emotion/styled';
import projectsService from 'app/services/projects';
import userInfoService from 'app/services/userInfo';
import { useAppDispatch } from 'app/hooks';
import {
  GradientAddIcon,
  FolderIcon,
  CreditCardIcon,
  MegaphoneIcon,
  BookIcon,
  QuestionIcon,
  UserCircleIcon,
  CircleWavyQuestionIcon,
//  ChatCircleIcon,
} from 'assets/svg';
import { Toast } from 'components/common';
import { typography } from 'styles';
import UsageCounter from './UsageCounter';

const NOTICE_URL = 'https://onairstudio.zendesk.com/hc/ko';
const USAGE_URL =
  'https://lionrocket0813.notion.site/9a179bd749384c2899cb538245b9278d';
const FAQ_URL =
  'https://onairstudio.zendesk.com/hc/ko/categories/4411545995673-%EC%9E%90%EC%A3%BC-%EB%AC%BB%EB%8A%94-%EC%A7%88%EB%AC%B8';

const Navigation: React.FC = () => {
  const { t } = useTranslation('client', { keyPrefix: 'common' });

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const { data: projects } = projectsService.useGetProjectsQuery();
  const { data: userInfo } = userInfoService.useGetUserInfoQuery();

  const [openToast, setOpenToast] = React.useState<boolean>(false);
  const toggleOpenToast = () => setOpenToast((prev) => !prev);

  const handleClickGoEditor = () => {
    if (projects === undefined) return;
    if (userInfo === undefined) return;

    if (projects.length < userInfo.projectLimitNum) {
      navigate('/client/editor');
      return;
    }

    toggleOpenToast();
  };

  const [openQuestion, setOpenQuestion] = React.useState<boolean>(false);
  const toggleOpenQuestion = () => setOpenQuestion((prev) => !prev);

  return (
    <Base>
      <ProjectEditorBox>
        <GoEditorButton onClick={handleClickGoEditor}>
          <GradientAddIcon width={32} height={32} />
          <span>{t('createProject')}</span>
        </GoEditorButton>
        <Toast
          in={openToast}
          onClose={toggleOpenToast}
          timeout={1500}
          message={t('createProjectOver')}
          severity={'error'}
        />
      </ProjectEditorBox>
      <RouterAnchorBox>
        <RouterAnchor
          to={'/client'}
          isActive={location.pathname === '/client'}
          onMouseOver={() => {
            dispatch(
              projectsService.util.prefetch('getProjects', undefined, {
                ifOlderThan: 60,
              })
            );
          }}
        >
          <FolderIcon size={24} weight={'fill'} />
          <span>{t('myProject')}</span>
        </RouterAnchor>
        {process.env.ONAIR_PLATFORM_TYPE === 'onair' && (
          <RouterAnchor
            to={'/client/membership'}
            isActive={location.pathname === '/client/membership'}
          >
            <CreditCardIcon size={24} weight={'fill'} />
            <span>{t('membership.title')}</span>
          </RouterAnchor>
        )}
      </RouterAnchorBox>
      <UsageCounter />
      <OuterLinkBox>
        <Anchor href={NOTICE_URL} target={'_blank'} rel={'noreferrer'}>
          <MegaphoneIcon size={24} weight={'bold'} />
          <span>{t('notice')}</span>
        </Anchor>
        <Anchor href={USAGE_URL} target={'_blank'} rel={'noreferrer'}>
          <BookIcon size={24} weight={'bold'} />
          <span>{t('usage')}</span>
        </Anchor>
        <Question onClick={toggleOpenQuestion}>
          <QuestionIcon size={24} weight={'bold'} />
          <span>{t('question')}</span>
        </Question>
        {openQuestion && (
          <ClickAwayListener onClickAway={toggleOpenQuestion}>
            <QuestionDropdown onClick={toggleOpenQuestion}>
              <QuestionDropdownAnchor
                href={FAQ_URL}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <CircleWavyQuestionIcon size={20} weight={'bold'} />
                <span>{t('faq')}</span>
              </QuestionDropdownAnchor>
            </QuestionDropdown>
          </ClickAwayListener>
        )}
      </OuterLinkBox>
      <ProfileBox
        onMouseOver={() => {
          dispatch(
            userInfoService.util.prefetch('getUserInfo', undefined, {
              ifOlderThan: 60,
            })
          );
        }}
      >
        <RouterAnchor
          to={'/client/profile'}
          isActive={
            location.pathname === '/client/profile' ||
            location.pathname === '/client/profile/update-password' ||
            location.pathname === '/client/profile/withdrawal'
          }
        >
          <UserCircleIcon size={24} color={'currentColor'} weight={'bold'} />
          <span>{t('profile')}</span>
        </RouterAnchor>
      </ProfileBox>
    </Base>
  );
};

export default Navigation;

const Base = styled.div`
  flex: none;
  width: 236px;
  height: 100%;
  border-right: 1px solid var(--color-grey-100);
  display: flex;
  flex-direction: column;
`;

const ProjectEditorBox = styled.div`
  width: 100%;
  height: 96px;
  padding: 24px 16px;
`;

const GoEditorButton = styled.button`
  text-decoration: none;
  color: var(--color-black);
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-50);
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  width: 172px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 8px;
  transition: box-shadow 225ms;
  cursor: pointer;

  span {
    margin-left: 8px;
    ${typography.title.medium}
  }

  &:hover {
    box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
      0px 16px 32px rgba(22, 34, 51, 0.16);
  }

  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.16),
        rgba(51, 51, 51, 0.16)
      ),
      var(--color-white);
    box-shadow: 0px 4px 12px -4px rgba(22, 34, 51, 0.12),
      0px 16px 32px rgba(22, 34, 51, 0.16);
    border-color: var(--color-grey-300);
  }
`;

const RouterAnchorBox = styled.div`
  width: 100%;
`;

const RouterAnchor = styled(Link)<{ isActive: boolean }>`
  text-decoration: none;
  color: ${({ isActive }) =>
    isActive ? 'var(--color-pink)' : 'var(--color-grey-700)'};
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  transition: color 225ms;
  cursor: default;

  span {
    margin-left: 8px;
    ${({ isActive }) =>
      isActive ? typography.title.medium : typography.body.large}
  }

  &:hover {
    color: ${({ isActive }) => !isActive && 'var(--color-grey-900)'};
  }

  &:active {
    color: ${({ isActive }) => !isActive && 'var(--color-black)'};
  }
`;

const OuterLinkBox = styled.div`
  width: 100%;
`;

const Anchor = styled.a`
  text-decoration: none;
  color: var(--color-grey-700);
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  user-select: none;
  transition: color 225ms;
  cursor: default;

  span {
    margin-left: 8px;
    ${typography.body.large}
  }

  &:hover {
    color: var(--color-grey-900);
  }

  &:active {
    color: var(--color-black);
  }
`;

const Question = Anchor.withComponent('div');

const ProfileBox = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
`;

const QuestionDropdown = styled.div`
  width: 125px;
  height: fit-content;
  position: fixed;
  left: 205px;
  bottom: 46px;
  border: 1px solid #f9f9f9;
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: 10px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: var(--color-white);
`;

const QuestionDropdownItem = styled.div`
  width: 100%;
  height: 32px;
  background: var(--color-white);
  ${typography.body.medium}
  color: var(--color-black);
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: default;

  span {
    margin-left: 8px;
  }

  &:hover {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;

    @media (hover: none) {
      background: var(--color-white);
    }
  }

  &:active {
    background: linear-gradient(
        0deg,
        rgba(51, 51, 51, 0.08),
        rgba(51, 51, 51, 0.08)
      ),
      #ffffff;
  }
`;

const QuestionDropdownAnchor = QuestionDropdownItem.withComponent('a');
