import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AudioManager from 'lib/AudioManager';
import { useResizeObserver } from 'lib/hooks';
import styled from '@emotion/styled';
import { typography } from 'styles';
import { XIcon, ArrowClockwiseIcon } from 'assets/svg';
import {
  VOICE_DEFAULT_VOLUME,
  VOICE_DEFAULT_SPEED,
  VOICE_MIN_SPEED,
  VOICE_MAX_SPEED,
} from 'features/editor/constants';
import {
  modifyCellData,
  selectCellVolume,
  selectCellSpeed,
  modifyManyVoiceOption,
  selectCurrentPageKey,
  selectCurrentPagePreview,
  setTimelineChange,
} from 'features/editor/editorSlice';
import { ModalV2, IconButton } from 'components/common';
import { Slider } from 'components/editor';
import AllTonePlayButton from './ChangeTonePlayButton.All';
import PageTonePlayButton from './ChangeTonePlayButton.Page';
import CellTonePlayButton from './ChangeTonePlayButton.Cell';

interface StandardValueProps {
  value: number;
  min: number;
  max: number;
  step?: number;
}

const volumeToStep = (volume: number) => {
  if (volume <= 2) return 1;
  if (volume <= 4) return 2;
  if (volume <= 7) return 3;
  if (volume <= 15) return 4;
  if (volume <= 23) return 5;
  return 3;
};

const stepToVolume = (step: number) => {
  if (step === 1) return 2;
  if (step === 2) return 4;
  if (step === 3) return 7;
  if (step === 4) return 15;
  if (step === 5) return 23;
  return 7;
};

const getStandardValue = ({ value, min, max, step }: StandardValueProps) => {
  const betweenValue: number = Math.min(Math.max(value, min), max);

  if (step === undefined) return betweenValue;

  try {
    const truncValue = Math.trunc(betweenValue / step) * step;
    const isRound = betweenValue - truncValue >= step / 2;

    const standardValue = isRound ? truncValue + step : truncValue;
    const fixedPoint = Number(step).toString().split('.')[1]?.length ?? 0;

    return Number(standardValue.toFixed(fixedPoint));
  } catch {
    return betweenValue;
  }
};

type ModifyType = 'all' | 'page' | 'cell';
interface ChangeToneProps {
  open: boolean;
  onClose: () => void;
  modifyType: ModifyType;
  pageKey?: string;
  cellKey?: string;
}

const ChangeTone: React.FC<ChangeToneProps> = ({
  open = false,
  onClose,
  modifyType,
  pageKey,
  cellKey,
}) => {
  const { t } = useTranslation('editor', { keyPrefix: 'changeTone' });

  const initVolume =
    useAppSelector(selectCellVolume(cellKey ?? '')) ?? VOICE_DEFAULT_VOLUME;
  const initSpeed =
    useAppSelector(selectCellSpeed(cellKey ?? '')) ?? VOICE_DEFAULT_SPEED;

  const currentPageKey = useAppSelector(selectCurrentPageKey);

  const previewContent = useAppSelector(selectCurrentPagePreview);

  const [voiceVolume, setVoiceVolume] = React.useState<number>(initVolume);
  const [voiceSpeed, setVoiceSpeed] = React.useState<number>(initSpeed);

  const { ref: controlRef, width: controlWidth } = useResizeObserver();

  const handleChangeVolume = (value: number) => {
    const volume = stepToVolume(value);
    setVoiceVolume(volume);
    toneKey.current = `${volume}-${voiceSpeed}`;
  };

  const handleChangeSpeed = (value: number) => {
    setVoiceSpeed(value);
    toneKey.current = `${voiceVolume}-${value}`;
  };

  const handleClickControlButton: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    const button = e.currentTarget;
    if (button === undefined) return;
    const type = button.dataset.type;
    if (type !== 'volume' && type !== 'speed') return;
    const unit = button.dataset.unit;
    if (unit === undefined) return;
    if (Number.isNaN(unit)) return;

    if (type === 'volume') {
      const step = volumeToStep(voiceVolume);
      const newValue = getStandardValue({
        value: step + Number(unit),
        min: 1,
        max: 5,
        step: Math.abs(Number(unit)),
      });
      const volume = stepToVolume(newValue);
      setVoiceVolume(volume);
      toneKey.current = `${volume}-${voiceSpeed}`;
    }

    if (type === 'speed') {
      const newValue = getStandardValue({
        value: voiceSpeed + Number(unit),
        min: VOICE_MIN_SPEED,
        max: VOICE_MAX_SPEED,
        step: Math.abs(Number(unit)),
      });
      setVoiceSpeed(newValue);
      toneKey.current = `${voiceVolume}-${newValue}`;
    }
  };

  const toneKey = React.useRef<string | null>(null);

  const audioManagerRef = React.useRef<AudioManager>(new AudioManager());

  const dispatch = useAppDispatch();

  const handleClickResetVoice: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    setVoiceVolume(VOICE_DEFAULT_VOLUME);
    setVoiceSpeed(VOICE_DEFAULT_SPEED);
  };

  const handleClickUpdateVoice: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();

    const voicePayload: { volume: number; speed: number } = {
      volume: voiceVolume,
      speed: voiceSpeed,
    };

    if (modifyType === 'all') {
      dispatch(
        modifyManyVoiceOption({
          pageKey: undefined,
          ...voicePayload,
        })
      );
    }

    if (modifyType === 'page') {
      dispatch(
        modifyManyVoiceOption({
          pageKey: currentPageKey,
          ...voicePayload,
        })
      );
    }

    if (modifyType === 'cell') {
      if (pageKey === undefined) return;
      if (cellKey === undefined) return;
      dispatch(
        modifyCellData({
          pageKey,
          cellKey,
          ...voicePayload,
        })
      );
    }

    if (previewContent.type === 'video') {
      dispatch(setTimelineChange({ isChanged: true }));
    }

    onClose();
  };

  return (
    <ModalV2 open={open} onClose={onClose}>
      <Base>
        <TitleBox>
          <Title>
            <span>{t(`title.${modifyType}`)}</span>
          </Title>
          <IconButton size={24} radius={6} onClick={() => onClose()}>
            <XIcon size={20} weight={'bold'} color={`var(--color-black)`} />
          </IconButton>
        </TitleBox>
        <ControlBox ref={controlRef}>
          <ControlHeader>
            <span>{t('volume.title')}</span>
            {modifyType === 'all' && (
              <AllTonePlayButton
                audioManagerRef={audioManagerRef}
                voiceOption={{ volume: voiceVolume, speed: voiceSpeed }}
              />
            )}
            {modifyType === 'page' && currentPageKey !== undefined && (
              <PageTonePlayButton
                audioManagerRef={audioManagerRef}
                voiceOption={{ volume: voiceVolume, speed: voiceSpeed }}
                pageKey={currentPageKey}
              />
            )}
            {modifyType === 'cell' && cellKey !== undefined && (
              <CellTonePlayButton
                audioManagerRef={audioManagerRef}
                voiceOption={{ volume: voiceVolume, speed: voiceSpeed }}
                cellKey={cellKey}
              />
            )}
          </ControlHeader>
          <ControlBody>
            <Slider
              width={controlWidth}
              value={volumeToStep(voiceVolume)}
              step={1}
              onChangeCommit={handleChangeVolume}
              min={1}
              max={5}
            />
          </ControlBody>
          <ControlFooter>
            <ControlButton
              data-type={'volume'}
              data-unit={-1}
              onClick={handleClickControlButton}
            >
              {t('volume.minus')}
            </ControlButton>
            <ControlValue>
              {t(`volumeStep.${volumeToStep(voiceVolume)}`)}
            </ControlValue>
            <ControlButton
              data-type={'volume'}
              data-unit={1}
              onClick={handleClickControlButton}
            >
              {t('volume.plus')}
            </ControlButton>
          </ControlFooter>
        </ControlBox>
        <Divider />
        <ControlBox>
          <ControlHeader>
            <span>{t('speed.title')}</span>
            {modifyType === 'all' && (
              <AllTonePlayButton
                audioManagerRef={audioManagerRef}
                voiceOption={{ volume: voiceVolume, speed: voiceSpeed }}
              />
            )}
            {modifyType === 'page' && currentPageKey !== undefined && (
              <PageTonePlayButton
                audioManagerRef={audioManagerRef}
                voiceOption={{ volume: voiceVolume, speed: voiceSpeed }}
                pageKey={currentPageKey}
              />
            )}
            {modifyType === 'cell' && cellKey !== undefined && (
              <CellTonePlayButton
                audioManagerRef={audioManagerRef}
                voiceOption={{ volume: voiceVolume, speed: voiceSpeed }}
                cellKey={cellKey}
              />
            )}
          </ControlHeader>
          <ControlBody>
            <Slider
              width={controlWidth}
              value={voiceSpeed}
              step={0.1}
              onChangeCommit={handleChangeSpeed}
              min={VOICE_MIN_SPEED}
              max={VOICE_MAX_SPEED}
            />
          </ControlBody>
          <ControlFooter>
            <ControlButton
              data-type={'speed'}
              data-unit={-0.1}
              onClick={handleClickControlButton}
            >
              {t('speed.minus')}
            </ControlButton>
            <ControlValue>{voiceSpeed}</ControlValue>
            <ControlButton
              data-type={'speed'}
              data-unit={0.1}
              onClick={handleClickControlButton}
            >
              {t('speed.plus')}
            </ControlButton>
          </ControlFooter>
        </ControlBox>
        <UpdateBox>
          <ResetButton onClick={handleClickResetVoice}>
            <ArrowClockwiseIcon
              size={16}
              weight={'bold'}
              color={'currentColor'}
            />
            <span>{t('button.reset')}</span>
          </ResetButton>
          <PinkButton onClick={handleClickUpdateVoice}>
            <span>{t(`apply.${modifyType}`)}</span>
          </PinkButton>
        </UpdateBox>
      </Base>
    </ModalV2>
  );
};

export default ChangeTone;

const Base = styled.div`
  width: calc(100% - 32px);
  max-height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  box-shadow: 0px 4px 24px rgba(51, 51, 51, 0.12);
  border-radius: 10px;
  padding: 24px 12px;

  @media (min-width: 520px) {
    width: 456px;
    padding: 24px;
  }
`;

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin-left: 24px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--color-grey-100);
  margin: 24px 0px;
`;

const ControlBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ControlHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 16px;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    color: var(--color-black);
  }
`;

const ControlBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ControlFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

const ControlValue = styled.div`
  display: flex;
  align-items: center;
  ${typography.title.medium}
  color: var(--color-grey-600);
  user-select: none;
`;

const ControlButton = styled.button`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid var(--color-grey-200);
  background: transparent;
  color: var(--color-grey-500);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--color-black);
    background: rgba(51, 51, 51, 0.08);

    @media (hover: none) {
      color: var(--color-black);
      background: rgba(51, 51, 51, 0.16);
    }
  }

  &:active {
    color: var(--color-black);
    background: rgba(51, 51, 51, 0.16);
  }
`;

const UpdateBox = styled.div`
  display: flex;
  gap: 18px;
  margin-top: 24px;
`;

const ResetButton = styled.button`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  padding: 0 13px;
  color: var(--color-black);
  background: transparent;
  outline: none;
  border: none;
  border-radius: 10px;

  cursor: pointer;
  user-select: none;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
  }

  &:hover {
    background: rgba(51, 51, 51, 0.08);

    @media (hover: none) {
      background: rgba(51, 51, 51, 0.16);
    }
  }

  &:active {
    background: rgba(51, 51, 51, 0.16);
  }
`;

const PinkButton = styled.button`
  --hover-gradient: linear-gradient(
    rgba(51, 51, 51, 0.08),
    rgba(51, 51, 51, 0.08)
  );
  --active-gradient: linear-gradient(
    rgba(51, 51, 51, 0.16),
    rgba(51, 51, 51, 0.16)
  );

  flex: 1 auto;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 10px;
  border: none;
  color: var(--color-white);
  background: var(--color-pink);

  cursor: pointer;
  user-select: none;

  span {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 700;
  }

  &:hover {
    background: var(--hover-gradient), var(--color-pink);

    @media (hover: none) {
      background: var(--active-gradient), var(--color-pink);
    }
  }

  &:active {
    background: var(--active-gradient), var(--color-pink);
  }

  &:disabled {
    background: var(--active-gradient), var(--color-pink);
  }

  @media (min-width: 520px) {
    min-width: 300px;
  }
`;
