import * as React from 'react';
import styled from '@emotion/styled';
import { useModifyVideoMutation } from 'app/services/videoAssets';

interface VideoNameInputProps {
  name: string;
  displayName: string;
  isEdit: boolean;
  toggleEdit: () => void;
}

const VideoNameInput: React.FC<VideoNameInputProps> = ({
  name,
  displayName,
  isEdit,
  toggleEdit,
}) => {
  const [modifyVideoName] = useModifyVideoMutation();

  const ref = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    const el = ref.current;
    if (el === null) return;
    if (!isEdit) return;

    // TODO: 왜 setTimeout없이 동작하지 않는지 확인 필요
    setTimeout(() => {
      el.focus();
    }, 100);
  }, [isEdit]);

  const resetName = () => {
    const el = ref.current;
    if (el === null) return;

    el.value = displayName;
  };

  const handleChange = (newValue: string) => {
    modifyVideoName({ name, displayName: newValue });
  };

  const handleBlur = () => {
    if (!isEdit) return;

    toggleEdit();

    const el = ref.current;
    if (el === null) return;
    if (el.value === displayName) return;

    handleChange(el.value);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const el = ref.current;
    if (el === null) return;

    if (e.key === 'Enter') {
      el.blur();
      return;
    }

    if (e.key === 'Escape') {
      toggleEdit();
      resetName();
      return;
    }
  };

  return (
    <Base onClick={(e) => e.stopPropagation()}>
      <Input
        defaultValue={displayName}
        disabled={!isEdit}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        ref={ref}
      />
    </Base>
  );
};

export default VideoNameInput;

const Base = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  cursor: text;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  font-size: 12px;
  line-height: 1.4;
  color: var(--color-black);
  border: none;
  outline: none;
  padding: 0;
  background: var(--color-white);
  border-width: 1px;
  border-style: none none solid none;
  border-color: var(--color-black);
  overflow: hidden;
  text-overflow: ellipsis;

  &:disabled {
    border-color: var(--color-white);
  }
`;
