import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { typography } from 'styles';
import { isMembershipTabType } from './utils';
import { membershipTabs } from './constants';
import type { MembershipTabType } from './constants';

interface MembershipTabsProps {
  tab: MembershipTabType;
}

const MembershipTabs: React.FC<MembershipTabsProps> = ({ tab: currentTab }) => {
  const { t } = useTranslation('client', { keyPrefix: 'membership.tabs' });

  const navigate = useNavigate();

  const handleClickTab: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const el =
      e.currentTarget instanceof HTMLButtonElement
        ? e.currentTarget
        : undefined;
    if (el === undefined) return;

    const dataTab = el.dataset.tab;
    if (!isMembershipTabType(dataTab)) return;
    if (dataTab === currentTab) return;

    navigate(`/client/membership?tab=${dataTab}`);
  };

  return (
    <Base>
      <TabList>
        {membershipTabs.map((tab) => (
          <Tab
            onClick={handleClickTab}
            data-active={tab === currentTab}
            data-tab={tab}
            key={tab}
          >
            <span>{t(tab)}</span>
          </Tab>
        ))}
      </TabList>
      <TabIndicator idx={membershipTabs.indexOf(currentTab)} />
    </Base>
  );
};

export default MembershipTabs;

const Base = styled.div`
  position: relative;
  border-bottom: 1px solid var(--color-white);
`;

const TabList = styled.div`
  display: flex;
`;

const Tab = styled.button`
  background: var(--color-white);

  width: 120px;
  height: 50px;
  border: none;
  border-bottom: 2px solid transparent;
  transition: border-color 100ms;

  &:hover {
    border-bottom: 2px solid var(--color-grey-500);

    @media (hover: none) {
      border-bottom: 2px solid transparent;
    }
  }

  span {
    ${typography.body.large}
    color: var(--color-grey-500);
    transition: font-weight 225ms;
  }

  &[data-active='true'] span {
    font-weight: 700;
    color: var(--color-black);
  }
`;

const TabIndicator = styled.span<{ idx: number }>`
  position: absolute;
  width: 120px;
  height: 2px;
  background-color: var(--color-black);
  transition: left 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  bottom: 0px;
  ${(props) => `left: ${props.idx * 120}px`};
`;
