import { createApi } from '@reduxjs/toolkit/query/react';
import { recordTransformer, camelCase } from 'lib/utils';
import queryFetcher from './queryFetcher';
import type { ObjectToCamel } from 'lib/utils';

type ProjectType = 'template';

interface RawProjectMetadata {
  project_uuid: string;
  project_name: string;
  create_date: string;
  update_date: string;
  predicted_loading_date: string;
  loading_start_date: string;
  loading_status: number;
  rendering_status: number;
  project_thumbnail?: string;
  type: ProjectType;
}

interface RawGetProjectsResponse {
  project_metadata: RawProjectMetadata[];
}

interface GetProjectResponse {
  projectMetadata: ProjectMetadata[];
}

export type ProjectMetadata = ObjectToCamel<RawProjectMetadata>;

export const api = createApi({
  reducerPath: 'services/templates',
  baseQuery: queryFetcher('/v1/video-maker/projects?type=template'),
  endpoints: (builder) => ({
    getTemplates: builder.query<ProjectMetadata[], void>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      transformResponse: (resp: RawGetProjectsResponse) => {
        const data = recordTransformer(resp, camelCase) as GetProjectResponse;

        return data.projectMetadata;
      },
    }),
  }),
});

export const { useGetTemplatesQuery } = api;

export default api;
