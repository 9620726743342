interface FetchParameters {
  file: File;
  type: 'video' | 'audio_only';
  channelId: string;
  eventId: string;
  token: string;
  signal?: AbortSignal;
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/file`;

export default async function fetchExtractFile({
  file,
  type,
  channelId,
  eventId,
  token,
  signal,
}: FetchParameters) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  formData.append('channel_id', channelId);
  formData.append('event_id', eventId);

  const response = await fetch(URL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
    signal,
  });

  return response.ok;
}
