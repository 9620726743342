import { ProjectUpdateError } from 'features/editor/utils';

interface FetchParameters {
  token: string;
  channelId: string;
  eventId: string;
  body: string;
  signal?: AbortSignal;
}

const URL = `${process.env.ONAIR_API_BASEURI}/v1/video-maker/projects/v2/edit`;

export default async function fetchModifyProject({
  token,
  channelId,
  eventId,
  body,
  signal,
}: FetchParameters) {
  const response = await fetch(URL, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      channel_id: channelId,
      event_id: eventId,
      ...JSON.parse(body),
    }),
    signal,
  });

  if (response.ok) return;

  throw new ProjectUpdateError(
    JSON.stringify({
      channel_id: channelId,
      event_id: eventId,
      ...JSON.parse(body),
    }),
    await response.text()
  );
}
