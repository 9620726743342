import * as React from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { useVideoPreviewControls } from 'features/editor/providers/videoPreview';
import {
  selectTimelineMaxTime,
  selectTimelineRulerGap,
  selectTimelineTimeGap,
  selectTimelineCellsWidth,
  setSelectCellKey,
  setTimelineCurrentTime,
  TIMELINE_INDICATOR_MIN_POSITION,
} from 'features/editor/editorSlice';

const EditableBoundary: React.FC = () => {
  const width = useAppSelector(selectTimelineCellsWidth);

  const maxTime = useAppSelector(selectTimelineMaxTime);
  const timeGap = useAppSelector(selectTimelineTimeGap);
  const rulerGap = useAppSelector(selectTimelineRulerGap);

  const dispatch = useAppDispatch();

  const videoPreviewControls = useVideoPreviewControls();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const { left } = e.currentTarget.getBoundingClientRect();

    const positionX = e.clientX - left;

    let currentTime = (positionX * timeGap) / rulerGap;

    if (maxTime - currentTime < maxTime % 100) {
      currentTime = maxTime;
    } else {
      currentTime = Math.floor(currentTime / 100) * 100;
    }

    dispatch(setTimelineCurrentTime(currentTime));
    dispatch(setSelectCellKey());
    videoPreviewControls?.seek(currentTime / 1000);
  };

  return <Base style={{ width }} onClick={handleClick} />;
};

export default EditableBoundary;

const Base = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: ${TIMELINE_INDICATOR_MIN_POSITION}px;
  height: 100%;
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.1);
`;
