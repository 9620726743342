import { createApi } from '@reduxjs/toolkit/query/react';
import { camelCase, recordTransformer } from 'lib/utils';
import queryFetcher from './queryFetcher';
import type { ObjectToCamel } from 'lib/utils';

interface RawVideoAsset {
  name: string;
  display_name: string;
  video_s3_path: {
    path: string;
    url: string;
  };
  thumb_s3_path: {
    path: string;
    url: string;
  };
  duration: number;
  size: number;
  owner: 'USER' | 'SERVER';
}

interface RawGetVideoAssetsResponse {
  video_names: RawVideoAsset[];
}

export type VideoAsset = ObjectToCamel<RawVideoAsset>;

interface GetVideoAssetsResponse {
  videoNames: VideoAsset[];
}

interface UploadVideoAssetsRequest {
  displayName: string;
  file: File;
}

interface DeleteVideoAssetsRequest {
  name: string;
}

interface ModifyVideoAssetsRequest {
  name: string;
  displayName: string;
}

interface VideoAssetsResponse {
  message: string;
  details: any;
}

export const api = createApi({
  reducerPath: 'services/videoAssets',
  baseQuery: queryFetcher('/v1/video-maker'),
  tagTypes: ['VideoAsset'],
  endpoints: (builder) => ({
    getVideos: builder.query<VideoAsset[], void>({
      query: () => ({
        url: '/video',
        method: 'GET',
      }),
      providesTags: [{ type: 'VideoAsset' }],
      transformResponse: (resp: RawGetVideoAssetsResponse) => {
        const data = recordTransformer(
          resp,
          camelCase
        ) as GetVideoAssetsResponse;

        return data.videoNames;
      },
    }),
    uploadVideo: builder.mutation<
      VideoAssetsResponse,
      UploadVideoAssetsRequest
    >({
      query: ({ displayName, file }) => {
        const formData = new FormData();
        formData.append('display_name', displayName);
        formData.append('file', file);

        return {
          url: '/video',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: 'VideoAsset' }],
    }),
    deleteVideo: builder.mutation<void, DeleteVideoAssetsRequest>({
      query: ({ name }) => ({
        url: `/video?name=${name}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ name }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getVideos', undefined, (draft) => {
            const patch = draft.filter((el) => el.name !== name);
            draft.splice(patch.length);
            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    modifyVideo: builder.mutation<
      VideoAssetsResponse,
      ModifyVideoAssetsRequest
    >({
      query: ({ name, displayName }) => ({
        url: '/video',
        method: 'PUT',
        body: {
          name,
          display_name: displayName,
        },
      }),
      async onQueryStarted(
        { name, displayName },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          api.util.updateQueryData('getVideos', undefined, (draft) => {
            const patch = draft.reduce<VideoAsset[]>((acc, item) => {
              if (item.name === name) {
                acc.push({ ...item, displayName });
              } else {
                acc.push(item);
              }

              return acc;
            }, []);

            Object.assign(draft, patch);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetVideosQuery,
  useUploadVideoMutation,
  useDeleteVideoMutation,
  useModifyVideoMutation,
} = api;

export default api;
